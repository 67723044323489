import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const list = (): any =>
  Request('region', 'GET')
    .then(ErrorHandler)
    .then((regions: any): any => regions);

const item = (id: number): any =>
  Request(`region/${id}`, 'GET')
    .then(ErrorHandler)
    .then((region: any): any => region);

const remove = (id: number): any =>
  Request(`region/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((region: any): any => region);

const create = (data: any): any =>
  Request('region', 'POST', data)
    .then(ErrorHandler)
    .then((region: any): any => region);

const update = (data: any, id: number): any =>
  Request(`region/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((region: any): any => region);

const RegionService = {
  list,
  item,
  create,
  update,
  remove,
};

export default RegionService;
