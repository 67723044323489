import React, { useState, useEffect, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import DialogForm from './DialogForm';
import styles from './styles';
import {
  AssetManagerType,
  ExportType,
  UserType,
  MutateAssetManagerType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  getAssetManagersList: () => void;
  removeAssetManager: (id: number) => void;
  createAssetManager: (obj: MutateAssetManagerType) => void;
  updateAssetManager: (obj: MutateAssetManagerType, id: number) => void;
  assetManagers: { content: Array<AssetManagerType> };
  openDialog: () => void;
  closeDialog: () => void;
  deleted: boolean;
  success: boolean;
  error: any;
  loading: boolean;
  getUserItem: (id: number) => Promise<UserType>;
  exportAssetManagersTable: () => Promise<ExportType>;
}

const AssetManager = (props: Props): ReactElement => {
  const {
    getAssetManagersList,
    openDialog,
    closeDialog,
    removeAssetManager,
    exportAssetManagersTable,
    assetManagers,
    updateAssetManager,
    createAssetManager,
    loading,
    success,
    error,
    deleted,
    getUserItem,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<AssetManagerType | undefined>(
    undefined
  );
  const [model, setModel] = useState<AssetManagerType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    getAssetManagersList();
  }, [getAssetManagersList]);

  const openAdd = (): void => {
    openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const openEdit = (event: any, row: AssetManagerType): void => {
    openDialog();
    setOpen(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: AssetManagerType): void => {
    openDialog();
    setOpenDelete(true);
    setSelectedRow(row);
  };

  const handleClose = (): void => {
    closeDialog();
    setOpen(false);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) removeAssetManager(selectedRow.id);
  };

  const exportTable = (): void => {
    exportAssetManagersTable().then((res: ExportType): void => {
      const csvData = new Blob([res.export], {
        type: 'text/csv;charset=utf-8;',
      });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', res.fileName || 'AssetManagers.csv');
      tempLink.click();
    });
  };

  const columns = [
    {
      title: 'Name',
      name: 'name',
      searchable: true,
      sortable: true,
      type: 'string',
      sortField: 'name',
    },
    {
      title: 'Manager Name',
      name: 'managerName',
      searchable: true,
      sortable: true,
      type: 'string',
      sortField: 'managerName',
    },
  ];

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          hasEditAndDelete
          onEdit={openEdit}
          onDelete={handleDeleteOpen}
          onAdd={openAdd}
          columns={columns}
          noMargin
          hasExportButton
          hasHighlightBackground
          onExport={exportTable}
          rows={assetManagers && assetManagers.content}
          entity="Asset Manager"
          entities="Asset Managers"
        />
      </div>
      <DialogForm
        model={model}
        open={open}
        onClose={handleClose}
        update={updateAssetManager}
        create={createAssetManager}
        loading={loading}
        success={success}
        error={error}
        getUserItem={getUserItem}
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {isRemoving && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Asset Manager removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error: You can not delete this Asset Manager. It is in use by an
              Account. Change the Asset Manager in the Account first, then
              delete the Asset Manager.
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};

AssetManager.defaultProps = {
  error: undefined,
  deleted: false,
  success: false,
};

export default AssetManager;
