import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const getClientsOverview = (year: number, month: number): any =>
  Request('overview/clients', 'POST', { year, month })
    .then(ErrorHandler)
    .then((clientsOverview: any): any => clientsOverview);

const getSecuritiesOverview = (year: number, month: number): any =>
  Request('overview/securities', 'POST', { year, month })
    .then(ErrorHandler)
    .then((securitiesOverview: any): any => securitiesOverview);

const getAssetManagersOverview = (year: number, month: number): any =>
  Request('overview/assetmanager', 'POST', { year, month })
    .then(ErrorHandler)
    .then((assetManagersOverview: any): any => assetManagersOverview);

const OverviewService = {
  getClientsOverview,
  getSecuritiesOverview,
  getAssetManagersOverview,
};

export default OverviewService;
