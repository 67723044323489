import ReportService from '../services/ReportService';
import * as types from '../constants/Report';
import {
  ReportSettingType,
  ReportTemplateType,
  ReportingTabTemplateType,
} from '../Types';

const getReportClientList = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.REPORTS_CLIENT_LIST_REQUEST });
  ReportService.getReportClientList(id)
    .then((reports: Array<ReportTemplateType>): void => {
      dispatch({ type: types.REPORTS_CLIENT_LIST_SUCCESS, reports });
    })
    .catch((error: any): void => {
      dispatch({ type: types.REPORTS_CLIENT_LIST_FAILURE, error });
    });
};

const getReportItem = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.REPORT_ITEM_REQUEST });
  ReportService.getReportItem(id)
    .then((report: ReportTemplateType): void => {
      dispatch({ type: types.REPORT_ITEM_SUCCESS, report });
    })
    .catch((error: any): void => {
      dispatch({ type: types.REPORT_ITEM_FAILURE, error });
    });
};

const updateReport = (data: any, id: number): any => (dispatch: any): void => {
  dispatch({ type: types.REPORT_UPDATE_REQUEST });
  ReportService.updateReport(data, id)
    .then((report: any): void => {
      dispatch({ type: types.REPORT_UPDATE_SUCCESS, report });
      dispatch(getReportClientList(data.refClientId));
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.REPORT_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.REPORT_UPDATE_FAILURE, error });
      }
    });
};

const createReport = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.REPORT_CREATE_REQUEST });
  ReportService.createReport(data)
    .then((report: any): void => {
      dispatch({ type: types.REPORT_CREATE_SUCCESS, report });
      dispatch(getReportClientList(data.refClientId));
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.REPORT_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.REPORT_CREATE_FAILURE, error });
      }
    });
};

const removeReport = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.REPORT_REMOVE_REQUEST });
  ReportService.removeReport(id)
    .then((report: any): void => {
      dispatch({ type: types.REPORT_REMOVE_SUCCESS, report, id });
    })
    .catch((error: any): void => {
      error.then((e: any): void => {
        if (typeof error.then === 'function') {
          error.then((e: any): void =>
            dispatch({ type: types.REPORT_REMOVE_FAILURE, error: e })
          );
        } else {
          dispatch({ type: types.REPORT_REMOVE_FAILURE, error });
        }
      });
    });
};

const getReportTemplateClientList = (id: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.REPORT_TEMPLATE_LIST_REQUEST });
  ReportService.getReportTemplateClientList(id)
    .then((reportTemplates: Array<ReportingTabTemplateType>): void => {
      dispatch({ type: types.REPORT_TEMPLATE_LIST_SUCCESS, reportTemplates });
    })
    .catch((error: any): void => {
      dispatch({ type: types.REPORT_TEMPLATE_LIST_FAILURE, error });
    });
};

const createReportTemplate = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.REPORT_TEMPLATE_CREATE_REQUEST });
  ReportService.createReportTemplate(data)
    .then((reportTemplate: any): void => {
      dispatch({
        type: types.REPORT_TEMPLATE_CREATE_SUCCESS,
        reportTemplate,
      });
    })
    .catch((error: any): void => {
      dispatch({ type: types.REPORT_TEMPLATE_CREATE_FAILURE, error });
    });
};

const removeReportTemplate = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.REPORT_TEMPLATE_REMOVE_REQUEST });
  ReportService.removeReportTemplate(id)
    .then((reportTemplate: any): void => {
      dispatch({
        type: types.REPORT_TEMPLATE_REMOVE_SUCCESS,
        reportTemplate,
        id,
      });
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.REPORT_TEMPLATE_REMOVE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.REPORT_TEMPLATE_REMOVE_FAILURE, error });
      }
    });
};

const updateReportTemplate = (
  data: any,
  clientId: number,
  dataId: number
): any => (dispatch: any): void => {
  dispatch({ type: types.REPORT_TEMPLATE_UPDATE_REQUEST });
  ReportService.updateReportTemplate(data, dataId)
    .then((report: any): void => {
      dispatch({ type: types.REPORT_TEMPLATE_UPDATE_SUCCESS, report });
      dispatch(getReportTemplateClientList(clientId));
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.REPORT_TEMPLATE_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.REPORT_TEMPLATE_UPDATE_FAILURE, error });
      }
    });
};

const updateReportingSettings = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.REPORT_SETTING_UPDATE_REQUEST });
  return ReportService.updateReportingSettings(data)
    .then((reportingSettings: any): any => {
      dispatch({
        type: types.REPORT_SETTING_UPDATE_SUCCESS,
        reportingSettings,
      });
      return Promise.resolve(reportingSettings);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.REPORT_SETTING_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.REPORT_SETTING_UPDATE_FAILURE, error });
      }
      return Promise.reject();
    });
};

const getReportingSettings = (): any => (dispatch: any): any => {
  dispatch({ type: types.REPORT_SETTING_REQUEST });
  return ReportService.getReportingSettings()
    .then((reportingSettings: ReportSettingType): any => {
      dispatch({ type: types.REPORT_SETTING_SUCCESS, reportingSettings });
      return Promise.resolve(reportingSettings);
    })
    .catch((error: any): any => {
      dispatch({ type: types.REPORT_SETTING_FAILURE, error });
      return Promise.reject();
    });
};

const ReportActions = {
  getReportClientList,
  getReportItem,
  createReport,
  updateReport,
  removeReport,
  getReportTemplateClientList,
  createReportTemplate,
  removeReportTemplate,
  updateReportTemplate,
  updateReportingSettings,
  getReportingSettings,
};

export default ReportActions;
