import * as types from '../constants/Currency';

const Currency = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.CURRENCIES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CURRENCIES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: action.currencies,
      };
    case types.CURRENCIES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.CURRENCY_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CURRENCY_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.currency.reference,
      };
    case types.CURRENCY_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.CURRENCY_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CURRENCY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.currency.reference,
      };
    case types.CURRENCY_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.CURRENCY_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CURRENCY_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        currency: action.currency,
      };
    case types.CURRENCY_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.CURRENCY_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CURRENCY_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        reference: 'deleted',
        currencies: {
          ...state.currencies,
          content: state.currencies.content.filter(
            (ac: any): any => ac.id !== action.id
          ),
        },
      };
    case types.CURRENCY_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default Currency;
