import React, { useState, useEffect, ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Dates from '../../constants/Date';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import { EnvironmentType, UserType, MutateEnvironmentType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  onClose: () => void;
  model: EnvironmentType;
  open: boolean;
  update: (id: number, obj: MutateEnvironmentType) => void;
  create: (obj: MutateEnvironmentType) => void;
  success: boolean;
  loading: boolean;
  error: any;
  getUserItem: (id: number) => Promise<UserType>;
}

const DialogForm = (props: Props): ReactElement => {
  const {
    model,
    getUserItem,
    onClose,
    update,
    create,
    open,
    error,
    loading,
    success,
  } = props;
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [globalBenchmarks, setGlobalBenchmarks] = useState<boolean>(false);
  const [simulationTab, setSimulationTab] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [modifyDate, setModifyDate] = useState<string>('');
  const [relatedUsername, setRelatedUserName] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (model && open) {
      setName(model.name);
      setType(model.type);
      setUrl(model.url);
      setSimulationTab(model.simulationTab);
      setGlobalBenchmarks(model.globalBenchmarks);
      const { lastModifiedBy, lastModifiedDate, createdDate } = model;
      if (lastModifiedBy && lastModifiedDate !== undefined) {
        getUserItem(lastModifiedBy).then((user: UserType): void => {
          if (lastModifiedDate === createdDate) {
            const date = new Date(lastModifiedDate);
            const monthObj = Dates.months.find(
              each => each.valueNumber === date.getMonth() + 1
            );
            const month = monthObj && monthObj.value;
            const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
            setIsUpdated(false);
            setRelatedUserName(user.name);
            setModifyDate(newModifyDate);
          } else {
            const date = new Date(lastModifiedDate);
            const monthObj = Dates.months.find(
              each => each.valueNumber === date.getMonth() + 1
            );
            const month = monthObj && monthObj.value;
            const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
            setIsUpdated(true);
            setRelatedUserName(user.name);
            setModifyDate(newModifyDate);
          }
        });
      } else {
        setIsUpdated(false);
        setModifyDate('');
        setRelatedUserName('');
      }
    }
  }, [open, model, getUserItem]);

  const resetFields = (): void => {
    setName('');
    setType('');
    setUrl('');
    setGlobalBenchmarks(false);
    setSimulationTab(false);
    setShowError(false);
    setShowNotification(false);
    setIsUpdated(false);
    setModifyDate('');
    setRelatedUserName('');
  };

  const handleClose = (): void => {
    resetFields();
    onClose();
  };

  const canSubmit = (): boolean => {
    if (name === '') return false;
    return true;
  };

  const submit = (): void => {
    if (canSubmit()) {
      if (model?.id) {
        update(model.id, { name, type, globalBenchmarks, url, simulationTab });
      } else {
        create({ name, globalBenchmarks, type, url, simulationTab });
      }
      setShowNotification(true);
    } else {
      setShowError(true);
    }
  };

  const retry = (): void => {
    setShowNotification(false);
  };

  let actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions = [
      {
        label: 'Back',
        onClick: retry,
      },
      {
        label: 'Cancel',
        onClick: handleClose,
        type: 'secondary',
      },
    ];
  }

  const types = ['PRODUCTION', 'DEMO', 'TEST', 'MASTER'];
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      actions={actions}
      title={model && model.id ? 'Edit Environment' : 'New Environment'}
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Environment {model && model.id ? 'updated' : 'added'} successfully.
          </Typography>
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error: {(error && error.messages && error.messages[0]) || 'Unknown'}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div className={classes.formContainerForm}>
          <TextField
            error={showError && name === ''}
            id="outlined-name"
            label="Name"
            value={name}
            onChange={(e: any): void => setName(e.target.value)}
            margin="normal"
            variant="outlined"
            helperText={showError && name === '' && 'Required'}
            className={classes.fullField}
          />
          <TextField
            id="outlined-select-type"
            select
            error={showError && type === ''}
            label="Type"
            value={type || ''}
            onChange={(e: any): void => setType(e.target.value)}
            margin="normal"
            variant="outlined"
            helperText={showError && type === '' && 'Required'}
            className={classes.fullField}
            disabled={type === 'MASTER'}
          >
            {types
              .filter((option: string): any =>
                option === 'MASTER' ? type === 'MASTER' : true
              )
              .map(
                (option: string): ReactElement => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                )
              )}
          </TextField>
          <TextField
            id="outlined-url"
            label="URL"
            value={url}
            onChange={(e: any): void => setUrl(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={globalBenchmarks}
                onChange={(e: any): void =>
                  setGlobalBenchmarks(e.target.checked)
                }
                value="checkedA"
                color="primary"
                classes={{ root: classes.checkboxroot }}
              />
            }
            label="Global Benchmarks"
            className={classes.checkbox}
            classes={{
              label: classes.labelCheckbox,
            }}
            style={{ marginTop: '-2px' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={simulationTab}
                onChange={(e: any): void => setSimulationTab(e.target.checked)}
                value="checkedA"
                color="primary"
                classes={{ root: classes.checkboxroot }}
              />
            }
            label="Simulation Tab"
            className={classes.checkbox}
            classes={{
              label: classes.labelCheckbox,
            }}
          />
          {relatedUsername !== '' && (
            <Typography variant="subtitle2" className={classes.info}>
              {isUpdated
                ? `Updated by: ${relatedUsername} on ${modifyDate}`
                : `Created by: ${relatedUsername} on ${modifyDate}`}
            </Typography>
          )}
        </div>
      )}
    </Dialog>
  );
};

DialogForm.propTypes = {};

DialogForm.defaultProps = {
  model: undefined,
  error: undefined,
  success: false,
  loading: false,
};

export default DialogForm;
