import createStyles from '@material-ui/core/styles/createStyles';

const styles = (): any =>
  createStyles({
    formContainer: {
      width: '100%',
      height: '100%',
      margin: '10px 30px',
    },
    fullField: {
      margin: '5px 5px',
      width: '98%',
    },
    progress: {
      margin: 'auto',
      position: 'absolute',
      width: '100%',
      top: 0,
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      minHeight: 100,
      position: 'relative',
    },
    text: {
      margin: '35px',
    },
    info: {
      color: 'rgb(0,0,0,0.5)',
      marginLeft: 5,
      fontSize: '10px',
      fontWeight: 350,
    },
  });

export default styles;
