import React, { useState, ReactElement, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import ReportIcon from '@material-ui/icons/Description';
import LinearProgress from '@material-ui/core/LinearProgress';
import Regccy from '../Client/components/Reg-ccy/Regccy';
import AssetTable from '../Client/components/AssetTable/AssetTable';
import Assets from '../Client/components/Assets/Assets';
import Charting from '../Client/components/Charting/Charting';
import Monitor from '../Client/components/Monitor/Monitor';
import PerformanceComponent from '../Client/components/Performance/Performance';
import History from '../Client/components/History/History';
import Allocation from '../Client/components/Allocation/Allocation';
import DataTable from '../../containers/DataTable';
import Dropdown from '../../components/Dropdown';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import { getCurrentMonth, getCurrentYear } from '../../helpers/utils';
import Dates from '../../constants/Date';
import DialogForm from './DialogForm';
import GearLoader from '../../containers/Client/Loaders/infinity.gif';
import logo from './images/logo.png';
import FirstPage from './images/FirstPage.png';
import LastPage from './images/LastPage.png';
import EditDialogForm from './EditDialogForm';
import {
  ReportTemplateType,
  ReportingTabTemplateType,
  ClientType,
  UserType,
  ReportSettingType,
  AssetAllocationType,
  AssetsType,
  BenchmarkType,
  ChartingType,
  MonitorType,
  HistoryType,
  AssetTableType,
  PerformanceType,
  RegccyType,
  MutateReportingTemplateType,
  UpdateReportingTabTemplateType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

const borderCssObject = {
  borderWidth: 2,
  borderColor: 'rgb(25, 118, 210)',
  logoWidth: 120,
};

const textCssObject = {
  firstPageFirstLineFontColor: '#FFF',
  firstPageFirstLineFontStyle: 'bold',
  firstPageFirstLineFontSize: 34,
  firstPageFirstLineAlign: 'left',
  firstPageSecondLineFontColor: '#FFF',
  firstPageSecondLineFontStyle: 'bold',
  firstPageSecondLineFontSize: 20,
  firstPageSecondLineAlign: 'right',
  firstPageThirdLineFontColor: '#FFF',
  firstPageThirdLineFontStyle: 'bold',
  firstPageThirdLineFontSize: 20,
  firstPageThirdLineAlign: 'right',
  firstPageCardBackgroundColor: 'rgb(0, 204, 102)',
  pageTitleFontSize: 20,
  pageTitleFontColor: '#000',
  pageTitleFontStyle: 'bold',
  pageSubtitleFontSize: 14,
  pageSubtitleFontColor: '#000',
  pageSubtitleFontStyle: 'bold',
  pageNumberFontSize: 16,
  pageNumberBackgoundColor: 'rgb(25, 118, 210)',
  contactFontSize: 32,
  contactFontStyle: 'normal',
  contactFontColor: 'rgb(25, 118, 210)',
  contactDetailFontSize: 28,
  contactDetailFontStyle: 'normal',
  disclaimerFontSize: 12,
  disclaimerFontColor: '#000',
  disclaimerFontStyle: 'normal',
};

const defaultDisclaimer = 'Disclaimer';

const firstPageTextObj = {
  firstLine: 'Monthly Report',
  secondLine: '{client_name}',
  thirdLine: '{month} {year}',
};

const lastPagetextObj = {
  city: 'CITY',
  phoneNumber: 'Company Phone Number',
  address1: 'Company Address',
  address2: '',
};

interface Props {
  openDialog: () => void;
  closeDialog: () => void;
  selectedClient: ClientType;
  getReportClientList: (id: number) => void;
  createReport: (obj: MutateReportingTemplateType) => void;
  updateReport: (obj: MutateReportingTemplateType, id: number) => void;
  removeReport: (id: number) => void;
  getReportTemplateClientList: (id: number) => void;
  removeReportTemplate: (id: number) => void;
  reports: Array<ReportTemplateType>;
  reportTemplates: Array<ReportingTabTemplateType>;
  success: boolean;
  error: any;
  loading: boolean;
  getUserItem: (id: number) => Promise<UserType>;
  deleted: boolean;
  updateReportTemplate: (
    obj: UpdateReportingTabTemplateType,
    id: number,
    dataId: number
  ) => void;
  getReportingSettings: () => void;
  reportingSettings: ReportSettingType;
  getAllocation?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>
  ) => Promise<AssetAllocationType>;
  getAssets?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>
  ) => Promise<AssetsType>;
  getTable?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>
  ) => Promise<AssetTableType>;
  getList?: () => Promise<{ content: Array<BenchmarkType> }>;
  charting?: (obj: {
    id: number;
    startmonth: number;
    endmonth: number;
    startyear: number;
    endyear: number;
    riskFree: number;
    month: number;
    year: number;
    enabledRows: Array<number>;
    disabledColumns: Array<number>;
    benchmarkIds: Array<number>;
  }) => Promise<ChartingType>;
  getHistory?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>
  ) => Promise<HistoryType>;
  monitor?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>,
    filteredRows: Array<number>
  ) => Promise<MonitorType>;
  getPerformanceData?: (
    id: number,
    sharp: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>
  ) => Promise<PerformanceType>;
  getRegccy?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>
  ) => Promise<RegccyType>;
}

const Reports = (props: Props): ReactElement => {
  const {
    selectedClient,
    getReportClientList,
    getReportTemplateClientList,
    getReportingSettings,
    openDialog,
    closeDialog,
    removeReport,
    reportingSettings,
    getAllocation,
    getAssets,
    getTable,
    getList,
    charting,
    getHistory,
    monitor,
    getPerformanceData,
    getRegccy,
    reportTemplates,
    reports,
    createReport,
    updateReport,
    loading,
    success,
    error,
    getUserItem,
    deleted,
    removeReportTemplate,
    updateReportTemplate,
  } = props;

  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<number>(
    getCurrentMonth() === 0 ? 12 : getCurrentMonth()
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    getCurrentMonth() === 0 ? getCurrentYear() - 1 : getCurrentYear()
  );
  const [selectedRow, setSelectedRow] = useState<
    ReportTemplateType | undefined
  >(undefined);
  const [step, setStep] = useState<number>(0);
  const [components, setComponents] = useState<Array<any>>([]);
  const [imgUrls, setImgUrls] = useState<Array<any>>([]);
  const [index, setIndex] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [model, setModel] = useState<ReportTemplateType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openAllReportTemplates, setOpenAllReportTemplates] = useState<boolean>(
    false
  );
  const [componentNode, setComponentNode] = useState<any>(null);
  const [componentName, setComponentName] = useState<string>('');
  const [componentId, setComponentId] = useState<number>(0);
  const classes = useStyles();

  const getClientReports = (): void => {
    if (selectedClient) {
      getReportClientList(selectedClient.id);
      getReportTemplateClientList(selectedClient.id);
      getReportingSettings();
    }
  };

  useEffect(() => {
    getClientReports();
  }, []);

  const openAdd = (): void => {
    openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const openEdit = (event: any, row: ReportTemplateType): void => {
    openDialog();
    setOpen(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: ReportTemplateType): void => {
    openDialog();
    setOpenDelete(true);
    setSelectedRow(row);
  };

  const handleClose = (): void => {
    closeDialog();
    setOpen(false);
  };

  const handleOpenCreate = (event: any, row: ReportTemplateType): void => {
    setOpenCreate(true);
    setModel(row);
  };

  const closeCreate = (): void => {
    setSelectedMonth(getCurrentMonth() === 0 ? 12 : getCurrentMonth());
    setSelectedYear(
      getCurrentMonth() === 0 ? getCurrentYear() - 1 : getCurrentYear()
    );
    setOpenCreate(false);
    setModel(undefined);
    setStep(0);
    setComponents([]);
    setIndex(0);
    setImgUrls([]);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) removeReport(selectedRow.id);
  };

  const createPDF = (imageUrls: Array<any>): void => {
    const monthObj = Dates.months.find(
      each => each.valueNumber === selectedMonth
    );
    let settingFirstPageText: any;
    let settingTextCss: any;
    let settingBorderCss: any;
    let settingLastPageText: any;
    let settingLogo = '';
    if (reportingSettings && reportingSettings.borderCss)
      settingBorderCss = JSON.parse(reportingSettings.borderCss);

    if (reportingSettings && reportingSettings.textCss)
      settingTextCss = JSON.parse(reportingSettings.textCss);
    if (reportingSettings?.lastPageText)
      settingLastPageText = JSON.parse(reportingSettings.lastPageText);
    if (reportingSettings?.logo) settingLogo = reportingSettings?.logo;
    if (reportingSettings?.firstPageText)
      settingFirstPageText = JSON.parse(reportingSettings.firstPageText);

    const getSizePromise = new Promise(resolve => {
      const sizes: Array<any> = [];
      if (imageUrls.length === 0) resolve([]);
      imageUrls.forEach((eachImageUrl: any, idx: number): void => {
        const img = new Image();
        img.src = eachImageUrl.url;
        // eslint-disable-next-line
        img.onload = function() {
          sizes.push({
            id: eachImageUrl.id,
            width: img.width,
            height: img.height,
          });
          if (idx === imageUrls.length - 1) {
            resolve(sizes);
          }
        };
      });
    });

    const logoPromise = new Promise(resolve => {
      const logoImage = new Image();
      logoImage.src = settingLogo || logo;
      // eslint-disable-next-line
      logoImage.onload = function() {
        const logoWidth = logoImage.width;
        const logoHeight = logoImage.height;
        resolve({ logoWidth, logoHeight });
      };
    });

    logoPromise.then((logoSize: any) => {
      getSizePromise.then((sizes: any): void => {
        // eslint-disable-next-line
        const reportPDF = new jsPDF({
          orientation: 'l',
          format: 'a4',
          unit: 'px',
          compress: true,
        });
        const pageHeight = reportPDF.internal.pageSize.height;
        const pageWidth = reportPDF.internal.pageSize.width;
        const settingLogoWidth =
          settingBorderCss?.logoWidth || borderCssObject?.logoWidth;
        const logoWidth =
          logoSize?.logoWidth < settingLogoWidth
            ? logoSize?.logoWidth
            : settingLogoWidth;
        const logoHeight =
          logoWidth / logoSize?.logoWidth < 1
            ? logoSize?.logoHeight * (logoWidth / logoSize?.logoWidth)
            : logoSize?.logoHeight;

        const titleFontSize =
          settingTextCss?.pageTitleFontSize || textCssObject?.pageTitleFontSize;
        const titleFontColor =
          settingTextCss?.pageTitleFontColor ||
          textCssObject?.pageTitleFontColor;
        const titleFontStyle =
          settingTextCss.pageTitleFontStyle || textCssObject.pageTitleFontStyle;
        const subtitleFontSize =
          settingTextCss?.pageSubtitleFontSize ||
          textCssObject?.pageSubtitleFontSize;
        const subtitleFontColor =
          settingTextCss?.pageSubtitleFontColor ||
          textCssObject?.pageSubtitleFontColor;
        const subtitleFontStyle =
          settingTextCss?.pageSubtitleFontStyle ||
          textCssObject?.pageSubtitleFontStyle;

        if (model?.hasCover) {
          reportPDF.setLineWidth(
            settingBorderCss && settingBorderCss.borderWidth
              ? settingBorderCss.borderWidth
              : borderCssObject.borderWidth
          );
          reportPDF.setDrawColor(
            settingBorderCss && settingBorderCss.borderColor
              ? settingBorderCss.borderColor
              : borderCssObject.borderColor
          );
          reportPDF.rect(10, 10, pageWidth - 20, pageHeight - 20);
          reportPDF.addImage(
            settingLogo || logo,
            'JPEG',
            pageWidth - (logoWidth + 12),
            12,
            logoWidth,
            logoHeight
          );
          reportPDF.setDrawColor(0, 204, 102);
          reportPDF.setFillColor(
            settingTextCss?.firstPageCardBackgroundColor ||
              textCssObject?.firstPageCardBackgroundColor
          );
          reportPDF.rect(250, 170, 310, 130, 'F');
          const firstLineText = (
            settingFirstPageText?.firstLine || firstPageTextObj.firstLine
          )
            .replace('{month}', monthObj?.fullLabel || '')
            .replace('{year}', `${selectedYear}` || '')
            .replace('{client_name}', selectedClient?.name || '');
          const secondLineText = (
            settingFirstPageText?.secondLine || firstPageTextObj.secondLine
          )
            .replace('{month}', monthObj?.fullLabel || '')
            .replace('{year}', `${selectedYear}` || '')
            .replace('{client_name}', selectedClient?.name || '');
          const thirdLineText = (
            settingFirstPageText?.thirdLine || firstPageTextObj.thirdLine
          )
            .replace('{month}', monthObj?.fullLabel || '')
            .replace('{year}', `${selectedYear}` || '')
            .replace('{client_name}', selectedClient?.name || '');
          const firstLineFontColor =
            settingTextCss?.firstPageFirstLineFontColor ||
            textCssObject?.firstPageFirstLineFontColor;
          const firstLineFontStyle =
            settingTextCss?.firstPageFirstLineFontStyle ||
            textCssObject?.firstPageFirstLineFontStyle;
          const firstLineFontSize =
            settingTextCss?.firstPageFirstLineFontSize ||
            textCssObject?.firstPageFirstLineFontSize;
          const firstLineAlign =
            settingTextCss?.firstPageFirstLineAlign ||
            textCssObject?.firstPageFirstLineAlign;
          const secondLineFontColor =
            settingTextCss?.firstPageSecondLineFontColor ||
            textCssObject?.firstPageSecondLineFontColor;
          const secondLineFontStyle =
            settingTextCss?.firstPageSecondLineFontStyle ||
            textCssObject?.firstPageSecondLineFontStyle;
          const secondLineFontSize =
            settingTextCss?.firstPageSecondLineFontSize ||
            textCssObject?.firstPageSecondLineFontSize;
          const secondLineAlign =
            settingTextCss?.firstPageSecondLineAlign ||
            textCssObject?.firstPageSecondLineAlign;
          const thirdLineFontColor =
            settingTextCss?.firstPageThirdLineFontColor ||
            textCssObject?.firstPageThirdLineFontColor;
          const thirdLineFontStyle =
            settingTextCss?.firstPageThirdLineFontStyle ||
            textCssObject?.firstPageThirdLineFontStyle;
          const thirdLineFontSize =
            settingTextCss?.firstPageThirdLineFontSize ||
            textCssObject?.firstPageThirdLineFontSize;
          const thirdLineAlign =
            settingTextCss?.firstPageThirdLineAlign ||
            textCssObject?.firstPageThirdLineAlign;
          reportPDF.setFontStyle(firstLineFontStyle);
          reportPDF.setFontSize(firstLineFontSize);
          reportPDF.setTextColor(firstLineFontColor);
          reportPDF.text(
            firstLineText,
            firstLineAlign === 'left' ? 265 : 545,
            205,
            {
              lineHeightFactor: 1.5,
              align: firstLineAlign,
            }
          );
          reportPDF.setFontStyle(secondLineFontStyle);
          reportPDF.setFontSize(secondLineFontSize);
          reportPDF.setTextColor(secondLineFontColor);
          reportPDF.text(
            secondLineText,
            secondLineAlign === 'left' ? 265 : 545,
            245,
            {
              lineHeightFactor: 2.15,
              align: secondLineAlign,
            }
          );
          reportPDF.setFontStyle(thirdLineFontStyle);
          reportPDF.setFontSize(thirdLineFontSize);
          reportPDF.setTextColor(thirdLineFontColor);
          reportPDF.text(
            thirdLineText,
            thirdLineAlign === 'left' ? 265 : 545,
            280,
            {
              lineHeightFactor: 2.15,
              align: thirdLineAlign,
            }
          );
          // reportPDF.setFontSize(20);
          // reportPDF.text(`${monthObj?.fullLabel} ${selectedYear}`, 415, 270);
        }
        imageUrls.forEach((each: any, idx: number): void => {
          if (idx === 0 && model?.hasCover) reportPDF.addPage('a4', 'l');
          if (idx !== 0) reportPDF.addPage('a4', 'l');
          const foundSize = sizes.find(
            (eachSize: any): any => eachSize.id === each.id
          );
          const foundTemplate = model?.templates.find(
            (eachTemplate: any) => eachTemplate?.id === each.id
          );
          const templateTitle =
            foundTemplate?.title ||
            foundTemplate?.refReportingTabTemplate?.title;
          const templateSubtitle =
            foundTemplate?.subtitle ||
            foundTemplate?.refReportingTabTemplate?.subtitle;
          const { height } = foundSize;
          const { width } = foundSize;
          const widthRatio = width / 590;
          const heightBasedOnWidthRatio = height / widthRatio;
          let finalWidth;
          let finalHeight;
          if (heightBasedOnWidthRatio < 400) {
            finalWidth = 590;
            finalHeight = heightBasedOnWidthRatio;
          } else {
            const heightRatio = height / 400;
            const widthBasedOnRatio = width / heightRatio;
            finalWidth = widthBasedOnRatio;
            finalHeight = 400;
          }
          reportPDF.setLineWidth(
            settingBorderCss && settingBorderCss.borderWidth
              ? settingBorderCss.borderWidth
              : borderCssObject.borderWidth
          );
          reportPDF.setDrawColor(
            settingBorderCss && settingBorderCss.borderColor
              ? settingBorderCss.borderColor
              : borderCssObject.borderColor
          );
          reportPDF.rect(10, 10, pageWidth - 20, pageHeight - 20);
          reportPDF.setFillColor(
            settingTextCss && settingTextCss.pageNumberBackgoundColor
              ? settingTextCss.pageNumberBackgoundColor
              : textCssObject.pageNumberBackgoundColor
          );
          reportPDF.rect(pageWidth - 30, pageHeight - 30, 16, 16, 'F');
          reportPDF.setFontSize(
            settingTextCss && settingTextCss.pageNumberFontSize
              ? settingTextCss.pageNumberFontSize
              : textCssObject.pageNumberFontSize
          );
          reportPDF.setTextColor('#FFF');
          reportPDF.text(
            `${model?.hasCover ? idx + 2 : idx + 1}`,
            pageWidth - 22,
            pageHeight - 22,
            {
              align: 'center',
              baseline: 'middle',
            }
          );
          reportPDF.addImage(
            settingLogo || logo,
            'JPEG',
            pageWidth - (logoWidth + 12),
            12,
            logoWidth,
            logoHeight
          );
          reportPDF.setTextColor(titleFontColor);
          reportPDF.setFontSize(titleFontSize);
          reportPDF.setFontStyle(titleFontStyle);
          reportPDF.text(templateTitle, 24, 34);
          reportPDF.setTextColor(subtitleFontColor);
          reportPDF.setFontSize(subtitleFontSize);
          reportPDF.setFontStyle(subtitleFontStyle);
          reportPDF.text(templateSubtitle, 24, 48);
          if (each.name === 'VALUES') {
            reportPDF.addImage(
              each.url,
              'JPEG',
              20,
              60,
              finalWidth,
              finalHeight
            );
          }
          if (each.name === 'ASSETS') {
            reportPDF.addImage(
              each.url,
              'JPEG',
              10,
              50,
              finalWidth,
              finalHeight
            );
          }
          if (each.name === 'HISTORY') {
            reportPDF.addImage(
              each.url,
              'JPEG',
              10,
              60,
              finalWidth,
              finalHeight
            );
          }
          if (each.name === 'REGCCY') {
            reportPDF.addImage(
              each.url,
              'JPEG',
              10,
              50,
              finalWidth,
              finalHeight
            );
          }
          if (each.name === 'MONITOR') {
            reportPDF.addImage(
              each.url,
              'JPEG',
              10,
              40,
              finalWidth,
              finalHeight
            );
          }
          if (each.name === 'ASEETALLOCATION') {
            reportPDF.addImage(
              each.url,
              'JPEG',
              20,
              60,
              finalWidth,
              finalHeight === 400 ? 390 : finalHeight
            );
          }
          if (each.name === 'CHARTING') {
            reportPDF.addImage(
              each.url,
              'JPEG',
              10,
              40,
              finalWidth,
              finalHeight
            );
          }
          if (each.name === 'PERFORM') {
            reportPDF.addImage(
              each.url,
              'JPEG',
              10,
              40,
              finalWidth,
              finalHeight === 400 ? 390 : finalHeight
            );
          }
        });
        if (model?.hasDisclaimer) {
          const currentCity =
            settingLastPageText && settingLastPageText.city
              ? settingLastPageText.city
              : lastPagetextObj.city;
          const currentPhoneNumber =
            settingLastPageText && settingLastPageText.phoneNumber
              ? settingLastPageText.phoneNumber
              : lastPagetextObj.phoneNumber;
          const currentAddress =
            settingLastPageText && settingLastPageText.address1
              ? settingLastPageText.address1
              : lastPagetextObj.address1;
          const currentAddress2 =
            settingLastPageText && settingLastPageText.address2
              ? settingLastPageText.address2
              : lastPagetextObj.address2;
          const addressFontSize =
            settingTextCss && settingTextCss.contactDetailFontSize
              ? settingTextCss.contactDetailFontSize
              : textCssObject.contactDetailFontSize;
          const addressFontStyle =
            settingTextCss && settingTextCss.contactDetailFontStyle
              ? settingTextCss.contactDetailFontStyle
              : textCssObject.contactDetailFontStyle;
          if (model?.hasCover || (!model?.hasCover && imageUrls.length !== 0))
            reportPDF.addPage('a4', 'l');
          reportPDF.setLineWidth(
            settingBorderCss && settingBorderCss.borderWidth
              ? settingBorderCss.borderWidth
              : borderCssObject.borderWidth
          );
          reportPDF.setDrawColor(
            settingBorderCss && settingBorderCss.borderColor
              ? settingBorderCss.borderColor
              : borderCssObject.borderColor
          );
          reportPDF.rect(10, 10, 610, 425);
          reportPDF.setFontSize(
            settingTextCss && settingTextCss.contactFontSize
              ? settingTextCss.contactFontSize
              : textCssObject.contactFontSize
          );
          reportPDF.setFontStyle(
            settingTextCss && settingTextCss.contactFontStyle
              ? settingTextCss.contactFontStyle
              : textCssObject?.contactFontStyle
          );
          reportPDF.setTextColor(
            settingTextCss && settingTextCss.contactFontColor
              ? settingTextCss.contactFontColor
              : textCssObject.contactFontColor
          );
          reportPDF.text('Contact', 22, 40);
          reportPDF.setFontStyle(addressFontStyle);
          reportPDF.setFontSize(addressFontSize);
          reportPDF.text(currentCity, 310, 150, {
            align: 'center',
          });
          reportPDF.text(currentPhoneNumber, 310, 150 + addressFontSize, {
            align: 'center',
          });
          reportPDF.text(currentAddress, 310, 230, {
            align: 'center',
          });
          reportPDF.text(currentAddress2, 310, 230 + addressFontSize, {
            align: 'center',
          });

          reportPDF.setTextColor(
            settingTextCss && settingTextCss.disclaimerFontColor
              ? settingTextCss.disclaimerFontColor
              : textCssObject.disclaimerFontColor
          );
          reportPDF.setFontSize(
            settingTextCss && settingTextCss.disclaimerFontSize
              ? settingTextCss.disclaimerFontSize
              : textCssObject.disclaimerFontSize
          );
          reportPDF.setFontStyle(
            settingTextCss && settingTextCss.disclaimerFontStyle
              ? settingTextCss.disclaimerFontStyle
              : textCssObject.disclaimerFontStyle
          );
          reportPDF.text(
            reportingSettings && reportingSettings.disclaimer
              ? reportingSettings.disclaimer
              : defaultDisclaimer,
            25,
            350,
            { maxWidth: 580 }
          );
        }
        reportPDF.save('report.pdf');
        closeCreate();
      });
    });
  };

  const goToNextComponent = (
    newComponents: Array<any>,
    newIndex: number,
    newNode: any,
    name: string,
    id: number,
    propImgUrls: Array<any>
  ): void => {
    domtoimage
      .toPng(newNode.current)
      .then((dataUrl: string): void => {
        const newImageUrls = propImgUrls.concat({
          url: dataUrl,
          name,
          id,
        });
        if (newIndex < newComponents.length) {
          setIndex(newIndex + 1);
          setImgUrls(newImageUrls);
        } else {
          setIndex(newIndex + 1);
          setTimeout(
            () => createPDF(newImageUrls),
            model?.hasDisclaimer ? 1500 : 0
          );
        }
      })
      .catch((e: any): void => {
        // eslint-disable-next-line
        console.error("oops, something went wrong!", e);
        closeCreate();
      });
  };

  const getNode = (node: any, name: string, id: number): void => {
    setComponentName(name);
    setComponentNode(node);

    setTimeout(() => setComponentId(id), 100);
  };

  useEffect(() => {
    if (componentNode)
      goToNextComponent(
        components,
        index,
        componentNode,
        componentName,
        componentId,
        imgUrls
      );
  }, [componentId]);

  const handleCreateReport = (): void => {
    const newComponents = model
      ? model.templates
          .sort((firstTemplate: any, secondTemplate: any) => {
            if (firstTemplate?.orderno && secondTemplate?.orderno) {
              return firstTemplate?.orderno - secondTemplate?.orderno;
            }
            return 0;
          })
          .map((each: any): any => {
            const { refReportingTabTemplate } = each;
            if (refReportingTabTemplate.tabName === 'ASSETS') {
              return (
                <Assets
                  year={selectedYear}
                  month={selectedMonth}
                  selectedDate={{ year: selectedYear, month: selectedMonth }}
                  clientId={selectedClient.id}
                  readOnly
                  selectedRows={refReportingTabTemplate.enabledRows}
                  selectedColumns={refReportingTabTemplate.disabledColumns}
                  selectedChart={
                    JSON.parse(refReportingTabTemplate.extraData).assetsChart
                  }
                  getNode={getNode}
                  cardId={each.id}
                  getAssets={getAssets}
                  rootClass={classes.test}
                  root1Class={classes.test}
                />
              );
            }
            if (refReportingTabTemplate.tabName === 'VALUES') {
              return (
                <AssetTable
                  year={selectedYear}
                  month={selectedMonth}
                  selectedDate={{ year: selectedYear, month: selectedMonth }}
                  clientId={selectedClient.id}
                  readOnly
                  selectedRows={refReportingTabTemplate.enabledRows}
                  selectedColumns={refReportingTabTemplate.disabledColumns}
                  expandedRows={[]}
                  getNode={getNode}
                  cardId={each.id}
                  getTable={getTable}
                  rootClass={classes.test}
                  root1Class={classes.test}
                />
              );
            }
            if (refReportingTabTemplate.tabName === 'REGCCY') {
              return (
                <Regccy
                  year={selectedYear}
                  month={selectedMonth}
                  selectedDate={{ year: selectedYear, month: selectedMonth }}
                  clientId={selectedClient.id}
                  readOnly
                  selectedRows={refReportingTabTemplate.enabledRows}
                  selectedColumns={refReportingTabTemplate.disabledColumns}
                  selectedChart={
                    JSON.parse(refReportingTabTemplate.extraData).regccyChart
                  }
                  getNode={getNode}
                  cardId={each.id}
                  getRegccy={getRegccy}
                  rootClass={classes.test}
                  root1Class={classes.test}
                />
              );
            }
            if (refReportingTabTemplate.tabName === 'MONITOR') {
              return (
                <Monitor
                  year={selectedYear}
                  month={selectedMonth}
                  selectedDate={{ year: selectedYear, month: selectedMonth }}
                  clientId={selectedClient.id}
                  selectedShowingRows={
                    JSON.parse(refReportingTabTemplate.extraData)
                      .monitorShowingRows
                  }
                  readOnly
                  selectedRows={refReportingTabTemplate.enabledRows}
                  selectedColumns={refReportingTabTemplate.disabledColumns}
                  getNode={getNode}
                  cardId={each.id}
                  selectedChart={
                    JSON.parse(refReportingTabTemplate.extraData).monitorChart
                  }
                  monitor={monitor}
                  getList={getList}
                  rootClass={classes.test}
                  root1Class={classes.test}
                />
              );
            }
            if (refReportingTabTemplate.tabName === 'CHARTING') {
              return (
                <Charting
                  year={selectedYear}
                  month={selectedMonth}
                  selectedDate={{ year: selectedYear, month: selectedMonth }}
                  clientId={selectedClient.id}
                  selectedRows={refReportingTabTemplate.enabledRows}
                  selectedColumns={refReportingTabTemplate.disabledColumns}
                  readOnly
                  getNode={getNode}
                  cardId={each.id}
                  selectedShowingRows={
                    JSON.parse(refReportingTabTemplate.extraData)
                      .chartingShowingRows
                  }
                  selectedRiskFree={
                    JSON.parse(refReportingTabTemplate.extraData)
                      .chartingRiskFree
                  }
                  selectedStartMonth={
                    JSON.parse(refReportingTabTemplate.extraData)
                      .chartingStartMonth
                  }
                  selectedStartYear={
                    JSON.parse(refReportingTabTemplate.extraData)
                      .chartingStartYear
                  }
                  selectedEndMonth={
                    JSON.parse(refReportingTabTemplate.extraData)
                      .chartingEndMonth
                  }
                  selectedEndYear={
                    JSON.parse(refReportingTabTemplate.extraData)
                      .chartingEndYear
                  }
                  getList={getList}
                  charting={charting}
                  rootClass={classes.test}
                  root1Class={classes.test}
                />
              );
            }
            if (refReportingTabTemplate.tabName === 'PERFORM') {
              return (
                <PerformanceComponent
                  year={selectedYear}
                  month={selectedMonth}
                  selectedDate={{ year: selectedYear, month: selectedMonth }}
                  clientId={selectedClient.id}
                  selectedRows={refReportingTabTemplate.enabledRows}
                  selectedColumns={refReportingTabTemplate.disabledColumns}
                  readOnly
                  selectedSharp={
                    JSON.parse(refReportingTabTemplate.extraData).performSharp
                  }
                  getNode={getNode}
                  cardId={each.id}
                  getPerformanceData={getPerformanceData}
                  rootClass={classes.test}
                  root1Class={classes.test}
                />
              );
            }
            if (refReportingTabTemplate.tabName === 'HISTORY') {
              return (
                <History
                  year={selectedYear}
                  month={selectedMonth}
                  selectedDate={{ year: selectedYear, month: selectedMonth }}
                  clientId={selectedClient.id}
                  selectedRows={refReportingTabTemplate.enabledRows}
                  selectedColumns={refReportingTabTemplate.disabledColumns}
                  readOnly
                  getNode={getNode}
                  cardId={each.id}
                  getHistory={getHistory}
                  rootClass={classes.test}
                  root1Class={classes.test}
                />
              );
            }
            if (refReportingTabTemplate.tabName === 'ASEETALLOCATION') {
              return (
                <Allocation
                  year={selectedYear}
                  month={selectedMonth}
                  clientId={selectedClient.id}
                  selectedRows={refReportingTabTemplate.enabledRows}
                  selectedColumns={refReportingTabTemplate.disabledColumns}
                  readOnly
                  getNode={getNode}
                  cardId={each.id}
                  firstStatus={
                    JSON.parse(refReportingTabTemplate.extraData).firstStatus
                  }
                  secondStatus={
                    JSON.parse(refReportingTabTemplate.extraData).secondStatus
                  }
                  getAllocation={getAllocation}
                  rootClass={classes.test}
                  root1Class={classes.test}
                />
              );
            }
            return <div key="ggg" />;
          })
      : [];
    setComponents(newComponents);
    setStep(1);

    if (newComponents.length === 0) {
      if (model?.hasCover && model?.hasDisclaimer) {
        setTimeout(() => {
          setIndex(1);
          setTimeout(() => {
            createPDF([]);
          }, 1000);
        }, 1000);
      }
      if (model?.hasCover && !model?.hasDisclaimer) {
        setTimeout(() => {
          setIndex(1);
          createPDF([]);
        }, 1000);
      }
      if (!model?.hasCover && model?.hasDisclaimer) {
        setIndex(1);
        setTimeout(() => {
          createPDF([]);
        }, 1);
      }
    } else {
      setTimeout(() => setIndex(1), model?.hasCover ? 1000 : 0);
    }
  };

  const selectMonth = (value: string): void => {
    const month = Dates.months.find(m => m.value === value);
    if (month) setSelectedMonth(month?.valueNumber);
  };

  const selectYear = (value: string): void => {
    const year = Dates.years.find(y => y.value === value);
    if (year) setSelectedYear(year?.valueNumber);
  };

  const handleOpenAllReportTemplates = (): void => {
    openDialog();
    setOpenAllReportTemplates(true);
  };

  const handleCloseAllReportTemplates = (): void => {
    closeDialog();
    setOpenAllReportTemplates(false);
  };

  const columns = [
    {
      name: 'name',
      title: 'Name',
      style: { width: 250 },
      searchable: true,
    },
  ];

  const actions = [
    {
      label: 'cancel',
      onClick: closeCreate,
      type: 'secondary',
    },
    {
      label: 'Create',
      onClick: handleCreateReport,
      disabled: step !== 0,
    },
  ];

  const details = (): any => [
    {
      icon: <ReportIcon fontSize="small" />,
      title: 'Generate',
      onClick: handleOpenCreate,
    },
  ];

  const monthInitValue = Dates.months.find(
    m => m.valueNumber === selectedMonth
  );
  const yearInitValue = Dates.years.find(y => y.valueNumber === selectedYear);

  const extraButtons = [
    {
      label: 'All Report Templates',
      handleClick: handleOpenAllReportTemplates,
    },
  ];

  return (
    <div className={classes.root}>
      <div style={{ height: '100%' }}>
        <div style={{ height: '100% ' }}>
          <div style={{ height: '100%' }}>
            <DataTable
              hasAddButton
              onAdd={openAdd}
              columns={columns}
              rows={reports || []}
              entity="Report"
              entities={
                selectedClient ? `Reports of ${selectedClient.name}` : 'Reports'
              }
              noMargin
              extraButtons={extraButtons}
              hasHighlightBackground
              details={details}
              onEdit={openEdit}
              onDelete={handleDeleteOpen}
              hasEditAndDelete
            />
          </div>
        </div>
        <DialogForm
          model={model}
          open={open}
          onClose={handleClose}
          update={updateReport}
          create={createReport}
          loading={loading}
          deleted={deleted}
          success={success}
          error={error}
          getUserItem={getUserItem}
          reportTemplates={reportTemplates}
          clientId={selectedClient && selectedClient.id}
          removeReportTemplate={removeReportTemplate}
        />
        <EditDialogForm
          open={openAllReportTemplates}
          onClose={handleCloseAllReportTemplates}
          update={updateReportTemplate}
          loading={loading}
          deleted={deleted}
          success={success}
          error={error}
          reportTemplates={reportTemplates}
          clientId={selectedClient && selectedClient.id}
          removeReportTemplate={removeReportTemplate}
        />
        <Dialog
          open={openCreate}
          onClose={closeCreate}
          title={step === 0 ? 'Select Date' : 'Generating Report'}
          paperClass={
            step === 0 ? classes.dateSelectPaper : classes.secondReportDialog
          }
          contentClassName={classes.reportContent}
          actions={actions}
        >
          <div style={{ height: '100%', width: '100%' }}>
            {step === 0 && (
              <div className={classes.dateSelectContainer}>
                <div>
                  <Dropdown
                    onSelect={(value: string): void => selectMonth(value)}
                    suggestions={Dates.months}
                    initValue={monthInitValue}
                    id="drop-down-month"
                  />
                </div>
                <div>
                  <Dropdown
                    onSelect={(value: string): void => selectYear(value)}
                    suggestions={Dates.years}
                    initValue={yearInitValue}
                    id="drop-down-year"
                  />
                </div>
              </div>
            )}
            {step === 1 && (
              <div
                style={{ overflow: 'hidden', width: '100%', height: '100%' }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    fontSize: 30,
                    fontWeight: 800,
                  }}
                >
                  {' '}
                  <img src={GearLoader} alt="" style={{ width: 120 }} />
                </div>
                {index === 0 && model?.hasCover && (
                  <div
                    style={{ display: 'flex', width: '100%', height: '100%' }}
                  >
                    <img
                      src={FirstPage}
                      alt=""
                      height="100%"
                      style={{ margin: 'auto' }}
                    />
                  </div>
                )}
                {index > 0 &&
                  components.length > 0 &&
                  index < components.length + 1 && (
                    <div style={{ opacity: 0.5 }}>{components[index - 1]}</div>
                  )}
                {index === components.length + 1 && model?.hasDisclaimer && (
                  <div
                    style={{ display: 'flex', width: '100%', height: '100%' }}
                  >
                    <img
                      src={LastPage}
                      alt=""
                      height="100%"
                      style={{ margin: 'auto' }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </Dialog>
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          title="Warning"
          actions={
            isRemoving
              ? [
                  {
                    label: 'OK',
                    onClick: handleDeleteClose,
                  },
                ]
              : [
                  {
                    label: 'cancel',
                    onClick: handleDeleteClose,
                    type: 'secondary',
                  },
                  {
                    label: 'Delete',
                    onClick: remove,
                  },
                ]
          }
        >
          {!isRemoving && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                Are you sure?
              </Typography>
            </div>
          )}
          {isRemoving && loading && (
            <div className={classes.container}>
              <LinearProgress className={classes.progress} />
            </div>
          )}
          {isRemoving && !loading && deleted && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                Report removed successfully.
              </Typography>
            </div>
          )}
          {isRemoving && !loading && !deleted && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                Error: {error && error.messages && error.messages[0]}
              </Typography>
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default Reports;
