import FileService from '../services/FileService';
import * as types from '../constants/File';

const upload = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.FILE_UPLOAD_REQUEST });
  return FileService.upload(data)
    .then((response: any): any => {
      dispatch({ type: types.FILE_UPLOAD_SUCCESS, response });
      return Promise.resolve(response);
    })
    .catch((error: any): void => {
      dispatch({ type: types.FILE_UPLOAD_FAILURE, error });
    });
};

const getFile = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.FILE_GET_FILE_REQUEST });
  return FileService.getFile(id)
    .then((response: any): any => {
      dispatch({ type: types.FILE_GET_FILE_SUCCESS, response });
      return Promise.resolve(response);
    })
    .catch((error: any): void => {
      dispatch({ type: types.FILE_GET_FILE_FAILURE, error });
    });
};

const FileActions = {
  upload,
  getFile,
};

export default FileActions;
