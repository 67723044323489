export const EXPORT_CLIENTS_TABLE_REQUEST = 'EXPORT_CLIENTS_TABLE_REQUEST';
export const EXPORT_CLIENTS_TABLE_SUCCESS = 'EXPORT_CLIENTS_TABLE_SUCCESS';
export const EXPORT_CLIENTS_TABLE_FAILURE = 'EXPORT_CLIENTS_TABLE_FAILURE';
export const EXPORT_ASSET_CLASS_SETS_TABLE_REQUEST =
  'EXPORT_ASSET_CLASS_SETS_TABLE_REQUEST';
export const EXPORT_ASSET_CLASS_SETS_TABLE_SUCCESS =
  'EXPORT_ASSET_CLASS_SETS_TABLE_SUCCESS';
export const EXPORT_ASSET_CLASS_SETS_TABLE_FAILURE =
  'EXPORT_ASSET_CLASS_SETS_TABLE_FAILURE';
export const EXPORT_ASSET_CLASSES_TABLE_REQUEST =
  'EXPORT_ASSET_CLASSES_TABLE_REQUEST';
export const EXPORT_ASSET_CLASSES_TABLE_SUCCESS =
  'EXPORT_ASSET_CLASSES_TABLE_SUCCESS';
export const EXPORT_ASSET_CLASSES_TABLE_FAILURE =
  'EXPORT_ASSET_CLASSES_TABLE_FAILURE';
export const EXPORT_RISK_CLASSES_TABLE_REQUEST =
  'EXPORT_RISK_CLASSES_TABLE_REQUEST';
export const EXPORT_RISK_CLASSES_TABLE_SUCCESS =
  'EXPORT_RISK_CLASSES_TABLE_SUCCESS';
export const EXPORT_RISK_CLASSES_TABLE_FAILURE =
  'EXPORT_RISK_CLASSES_TABLE_FAILURE';
export const EXPORT_ASSET_MANAGERS_TABLE_REQUEST =
  'EXPORT_ASSET_MANAGERS_TABLE_REQUEST';
export const EXPORT_ASSET_MANAGERS_TABLE_SUCCESS =
  'EXPORT_ASSET_MANAGERS_TABLE_SUCCESS';
export const EXPORT_ASSET_MANAGERS_TABLE_FAILURE =
  'EXPORT_ASSET_MANAGERS_TABLE_FAILURE';
export const EXPORT_ACCOUNT_CODES_TABLE_REQUEST =
  'EXPORT_ACCOUNT_CODES_TABLE_REQUEST';
export const EXPORT_ACCOUNT_CODES_TABLE_SUCCESS =
  'EXPORT_ACCOUNT_CODES_TABLE_SUCCESS';
export const EXPORT_ACCOUNT_CODES_TABLE_FAILURE =
  'EXPORT_ACCOUNT_CODES_TABLE_FAILURE';
export const EXPORT_ACCOUNT_TYPES_TABLE_REQUEST =
  'EXPORT_ACCOUNT_TYPES_TABLE_REQUEST';
export const EXPORT_ACCOUNT_TYPES_TABLE_SUCCESS =
  'EXPORT_ACCOUNT_TYPES_TABLE_SUCCESS';
export const EXPORT_ACCOUNT_TYPES_TABLE_FAILURE =
  'EXPORT_ACCOUNT_TYPES_TABLE_FAILURE';
export const EXPORT_REGIONS_TABLE_REQUEST = 'EXPORT_REGIONS_TABLE_REQUEST';
export const EXPORT_REGIONS_TABLE_SUCCESS = 'EXPORT_REGIONS_TABLE_SUCCESS';
export const EXPORT_REGIONS_TABLE_FAILURE = 'EXPORT_REGIONS_TABLE_FAILURE';
export const EXPORT_CURRENCIES_TABLE_REQUEST =
  'EXPORT_CURRENCIES_TABLE_REQUEST';
export const EXPORT_CURRENCIES_TABLE_SUCCESS =
  'EXPORT_CURRENCIES_TABLE_SUCCESS';
export const EXPORT_CURRENCIES_TABLE_FAILURE =
  'EXPORT_CURRENCIES_TABLE_FAILURE';
export const EXPORT_SECURITIES_TABLE_REQUEST =
  'EXPORT_SECURITIES_TABLE_REQUEST';
export const EXPORT_SECURITIES_TABLE_SUCCESS =
  'EXPORT_SECURITIES_TABLE_SUCCESS';
export const EXPORT_SECURITIES_TABLE_FAILURE =
  'EXPORT_SECURITIES_TABLE_FAILURE';
export const EXPORT_BENCHMARKS_TABLE_REQUEST =
  'EXPORT_BENCHMARKS_TABLE_REQUEST';
export const EXPORT_BENCHMARKS_TABLE_SUCCESS =
  'EXPORT_BENCHMARKS_TABLE_SUCCESS';
export const EXPORT_BENCHMARKS_TABLE_FAILURE =
  'EXPORT_BENCHMARKS_TABLE_FAILURE';
export const EXPORT_BENCHMARK_VALUE_TABLE_REQUEST =
  'EXPORT_BENCHMARK_VALUE_TABLE_REQUEST';
export const EXPORT_BENCHMARK_VALUE_TABLE_SUCCESS =
  'EXPORT_BENCHMARK_VALUE_TABLE_SUCCESS';
export const EXPORT_BENCHMARK_VALUE_TABLE_FAILURE =
  'EXPORT_BENCHMARK_VALUE_TABLE_FAILURE';
export const EXPORT_ACCOUNTS_TABLE_REQUEST = 'EXPORT_ACCOUNTS_TABLE_REQUEST';
export const EXPORT_ACCOUNTS_TABLE_SUCCESS = 'EXPORT_ACCOUNTS_TABLE_SUCCESS';
export const EXPORT_ACCOUNTS_TABLE_FAILURE = 'EXPORT_ACCOUNTS_TABLE_FAILURE';
export const EXPORT_CLIENT_TYPES_TABLE_REQUEST =
  'EXPORT_CLIENT_TYPES_TABLE_REQUEST';
export const EXPORT_CLIENT_TYPES_TABLE_SUCCESS =
  'EXPORT_CLIENT_TYPES_TABLE_SUCCESS';
export const EXPORT_CLIENT_TYPES_TABLE_FAILURE =
  'EXPORT_CLIENT_TYPES_TABLE_FAILURE';
