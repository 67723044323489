export const ACCOUNT_LIST_REQUEST = 'ACCOUNT_LIST_REQUEST';
export const ACCOUNT_LIST_SUCCESS = 'ACCOUNT_LIST_SUCCESS';
export const ACCOUNT_LIST_FAILURE = 'ACCOUNT_LIST_FAILURE';
export const ACCOUNT_CREATE_SUCCESS = 'ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_CREATE_REQUEST = 'ACCOUNT_CREATE_REQUEST';
export const ACCOUNT_CREATE_FAILURE = 'ACCOUNT_CREATE_FAILURE';
export const ACCOUNT_UPDATE_REQUEST = 'ACCOUNT_UPDATE_REQUEST';
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_FAILURE = 'ACCOUNT_UPDATE_FAILURE';
export const ACCOUNT_ITEM_REQUEST = 'ACCOUNT_ITEM_REQUEST';
export const ACCOUNT_ITEM_SUCCESS = 'ACCOUNT_ITEM_SUCCESS';
export const ACCOUNT_ITEM_FAILURE = 'ACCOUNT_ITEM_FAILURE';
export const ACCOUNT_REMOVE_REQUEST = 'ACCOUNT_REMOVE_REQUEST';
export const ACCOUNT_REMOVE_SUCCESS = 'ACCOUNT_REMOVE_SUCCESS';
export const ACCOUNT_REMOVE_FAILURE = 'ACCOUNT_REMOVE_FAILURE';
export const ACCOUNT_FORM_DATA_REQUEST = 'ACCOUNT_FORM_DATA_REQUEST';
export const ACCOUNT_FORM_DATA_SUCCESS = 'ACCOUNT_FORM_DATA_SUCCESS';
export const ACCOUNT_FORM_DATA_FAILURE = 'ACCOUNT_FORM_DATA_FAILURE';
export const ACCOUNT_SECURITIES_LIST_REQUEST =
  'ACCOUNT_SECURITIES_LIST_REQUEST';
export const ACCOUNT_SECURITIES_LIST_SUCCESS =
  'ACCOUNT_SECURITIES_LIST_SUCCESS';
export const ACCOUNT_SECURITIES_LIST_FAILURE =
  'ACCOUNT_SECURITIES_LIST_FAILURE';
export const ALL_ACCOUNT_SECURITIES_LIST_REQUEST =
  'ALL_ACCOUNT_SECURITIES_LIST_REQUEST';
export const ALL_ACCOUNT_SECURITIES_LIST_SUCCESS =
  'ALL_ACCOUNT_SECURITIES_LIST_SUCCESS';
export const ALL_ACCOUNT_SECURITIES_LIST_FAILURE =
  'ALL_ACCOUNT_SECURITIES_LIST_FAILURE';
export const SECURITY_START_DATE_REQUEST = 'SECURITY_START_DATE_REQUEST';
export const SECURITY_START_DATE_SUCCESS = 'SECURITY_START_DATE_SUCCESS';
export const SECURITY_START_DATE_FAILURE = 'SECURITY_START_DATE_FAILURE';
export const ACCOUNT_START_DATE_REQUEST = 'ACCOUNT_START_DATE_REQUEST';
export const ACCOUNT_START_DATE_SUCCESS = 'ACCOUNT_START_DATE_SUCCESS';
export const ACCOUNT_START_DATE_FAILURE = 'ACCOUNT_START_DATE_FAILURE';
export const ACCOUNT_VALUES_LIST_REQUEST = 'ACCOUNT_VALUES_LIST_REQUEST';
export const ACCOUNT_VALUES_LIST_SUCCESS = 'ACCOUNT_VALUES_LIST_SUCCESS';
export const ACCOUNT_VALUES_LIST_FAILURE = 'ACCOUNT_VALUES_LIST_FAILURE';
export const ACCOUNT_SAVE_VALUES_REQUEST = 'ACCOUNT_SAVE_VALUES_REQUEST';
export const ACCOUNT_SAVE_VALUES_SUCCESS = 'ACCOUNT_SAVE_VALUES_SUCCESS';
export const ACCOUNT_SAVE_VALUES_FAILURE = 'ACCOUNT_SAVE_VALUES_FAILURE';
export const ACCOUNT_SAVE_SECURITY_LIFETIME_REQUEST =
  'ACCOUNT_SAVE_SECURITY_LIFETIME_REQUEST';
export const ACCOUNT_SAVE_SECURITY_LIFETIME_SUCCESS =
  'ACCOUNT_SAVE_SECURITY_LIFETIME_SUCCESS';
export const ACCOUNT_SAVE_SECURITY_LIFETIME_FAILURE =
  'ACCOUNT_SAVE_SECURITY_LIFETIME_FAILURE';
export const ACCOUNT_SECURITY_LIFETIME_REQUEST =
  'ACCOUNT_SECURITY_LIFETIME_REQUEST';
export const ACCOUNT_SECURITY_LIFETIME_SUCCESS =
  'ACCOUNT_SECURITY_LIFETIME_SUCCESS';
export const ACCOUNT_SECURITY_LIFETIME_FAILURE =
  'ACCOUNT_SECURITY_LIFETIME_FAILURE';
export const ACCOUNT_ATTACH_SECURITY_REQUEST =
  'ACCOUNT_ATTACH_SECURITY_REQUEST';
export const ACCOUNT_ATTACH_SECURITY_SUCCESS =
  'ACCOUNT_ATTACH_SECURITY_SUCCESS';
export const ACCOUNT_ATTACH_SECURITY_FAILURE =
  'ACCOUNT_ATTACH_SECURITY_FAILURE';
export const ACCOUNT_SECURITY_REMOVE_REQUEST =
  'ACCOUNT_SECURITY_REMOVE_REQUEST';
export const ACCOUNT_SECURITY_REMOVE_SUCCESS =
  'ACCOUNT_SECURITY_REMOVE_SUCCESS';
export const ACCOUNT_SECURITY_REMOVE_FAILURE =
  'ACCOUNT_SECURITY_REMOVE_FAILURE';
export const ACCOUNT_REMOVE_SECURITY_LIFETIME_REQUEST =
  'ACCOUNT_REMOVE_SECURITY_LIFETIME_REQUEST';
export const ACCOUNT_REMOVE_SECURITY_LIFETIME_SUCCESS =
  'ACCOUNT_REMOVE_SECURITY_LIFETIME_SUCCESS';
export const ACCOUNT_REMOVE_SECURITY_LIFETIME_FAILURE =
  'ACCOUNT_REMOVE_SECURITY_LIFETIME_FAILURE';
export const ACCOUNT_CHECK_TO_REMOVE_REQUEST =
  'ACCOUNT_CHECK_TO_REMOVE_REQUEST';
export const ACCOUNT_CHECK_TO_REMOVE_SUCCESS =
  'ACCOUNT_CHECK_TO_REMOVE_SUCCESS';
export const ACCOUNT_CHECK_TO_REMOVE_FAILURE =
  'ACCOUNT_CHECK_TO_REMOVE_FAILURE';
export const ACCOUNT_SECURITY_CHECK_TO_REMOVE_REQUEST =
  'ACCOUNT_SECURITY_CHECK_TO_REMOVE_REQUEST';
export const ACCOUNT_SECURITY_CHECK_TO_REMOVE_SUCCESS =
  'ACCOUNT_SECURITY_CHECK_TO_REMOVE_SUCCESS';
export const ACCOUNT_SECURITY_CHECK_TO_REMOVE_FAILURE =
  'ACCOUNT_SECURITY_CHECK_TO_REMOVE_FAILURE';
