import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    formContainer: {
      width: '100%',
      margin: '10px 30px',
    },
    fullField: {
      margin: '5px 5px',
      width: '98%',
    },
    progress: {
      margin: 'auto',
      width: '100%',
      position: 'absolute',
      zIndex: 3000,
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      minHeight: 100,
    },
    text: {
      margin: '35px',
    },
    info: {
      color: 'rgb(0,0,0,0.5)',
      marginLeft: 5,
      fontSize: '10px',
      fontWeight: 350,
    },
    success: {
      backgroundColor: theme.palette.common.green,
    },
    error: {
      backgroundColor: theme.palette.common.red,
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: '10px',
      marginBottom: '4px',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    listpaper: {
      minHeight: 500,
    },
    dialogTitle: {
      paddingLeft: 24,
    },
  });

export default styles;
