import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DatePickerActions from '../../actions/DatePickerActions';
import ClientActions from '../../actions/ClientActions';
import Dropdown from '../Dropdown';
import Dates from '../../constants/Date';
import { ClientType } from '../../Types';

const styles = (theme: any): any =>
  createStyles({
    pickerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    button: { width: 100, paddingTop: 7, margin: '0 5px' },
    iconButton: { height: 42, padding: 4 },
    yearPickerContainer: {
      width: 100,
      display: 'inline-block',
      margin: '0px 5px',
    },
    yearPicker: { fontSize: '12px', color: '#039be5' },
    accountButton: {
      display: 'inline',
      marginLeft: '10px',
      padding: '0px 20px',
      width: '95px',
      [theme.breakpoints.down('md')]: {
        width: '80px',
      },
    },
  });

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  setStart?: () => void;
  noButton?: boolean;
  noArrowButton?: boolean;
  minDate?: { year: number; month: number };
  setYear?: (year: number) => void;
  setMonth?: (month: number) => void;
  year?: number;
  month?: number;
  setDate?: (date: string) => void;
  setDateToClient?: (id: number, obj: { year: number; month: number }) => void;
  client?: ClientType;
  textRootClass?: any;
  inputClass?: any;
}

const DatePicker = (props: Props): ReactElement => {
  const {
    setYear,
    setMonth,
    year,
    month,
    setDate,
    setDateToClient,
    setStart,
    client,
    noButton,
    noArrowButton,
    minDate,
    textRootClass,
    inputClass,
    ...dropdownProps
  } = props;
  const classes = useStyles();
  const monthInitValue = Dates.months.find(m => m.valueNumber === month);
  const yearInitValue = Dates.years.find(y => y.valueNumber === year);

  const afterChange = (y: number, m: number): void => {
    if (setYear) setYear(y);
    if (setMonth) setMonth(m);
    if (client && setDateToClient && setDate) {
      setDateToClient(client.id, { year: y, month: m });
      setDate(`${y}-${m}`);
    }
  };

  const setNextMonth = (): void => {
    let y = year || 1;
    const m = month ? (month % 12) + 1 : 1;
    if (month === 12) {
      y = year ? year + 1 : 1;
    }
    afterChange(y, m);
  };

  const setPrevMonth = (): void => {
    let y = year || 1;
    let m = month ? month - 1 : 1;
    if (month === 1) {
      y = year ? year - 1 : 1;
      m = 12;
    }
    afterChange(y, m);
  };

  const setToday = (): void => {
    const y = new Date().getFullYear();
    const m = new Date().getMonth();
    if (m === 0) {
      afterChange(y - 1, 12);
    } else {
      afterChange(y, m);
    }
  };

  if (!year || !month) {
    setToday();
  }

  return (
    <div className={classes.pickerContainer}>
      {!noButton && (
        <Button
          variant="outlined"
          className={classes.accountButton}
          classes={{
            label: classes.buttonLabel,
          }}
          onClick={(): void => {
            if (setStart) setStart();
          }}
          id="button-start"
          color="primary"
        >
          Start
        </Button>
      )}
      {!noButton && (
        <Button
          id="button-today"
          variant="outlined"
          className={classes.accountButton}
          classes={{
            label: classes.buttonLabel,
          }}
          onClick={(): void => setToday()}
          color="primary"
        >
          Today
        </Button>
      )}
      {!noArrowButton && (
        <IconButton
          className={classes.iconButton}
          style={{ marginLeft: 10 }}
          onClick={(): void => setPrevMonth()}
          classes={{
            root: classes.iconButtonRoot,
          }}
          color="primary"
          id="icon-button-prev"
        >
          <LeftIcon />
        </IconButton>
      )}
      <div>
        <Dropdown
          onSelect={(value: any): void => {
            const foundMonthObj = Dates.months.find(m => m.value === value);
            const foundMonth = foundMonthObj ? foundMonthObj.valueNumber : 0;
            afterChange(year || 1, foundMonth);
          }}
          suggestions={Dates.months.filter(
            m =>
              !minDate ||
              (yearInitValue && yearInitValue.valueNumber !== minDate.year) ||
              m.valueNumber >= minDate.month
          )}
          initValue={
            monthInitValue ||
            Dates.months.find(m => m.valueNumber === new Date().getMonth() + 1)
          }
          id="drop-down-month"
          textRootClass={textRootClass}
          inputClass={inputClass}
          {...dropdownProps}
        />
      </div>
      <div>
        <Dropdown
          onSelect={(value: any): void => {
            afterChange(Number(value), month || 1);
          }}
          suggestions={Dates.years.filter(
            y => !minDate || y.valueNumber >= minDate.year
          )}
          initValue={
            yearInitValue ||
            Dates.years.find(y => y.valueNumber === new Date().getFullYear())
          }
          id="drop-down-year"
          textRootClass={textRootClass}
          inputClass={inputClass}
          {...dropdownProps}
        />
      </div>
      {!noArrowButton && (
        <IconButton
          className={classes.iconButton}
          onClick={(): void => setNextMonth()}
          classes={{
            root: classes.iconButtonRoot,
          }}
          id="icon-button-next"
          color="primary"
        >
          <RightIcon />
        </IconButton>
      )}
    </div>
  );
};

DatePicker.defaultProps = {};

export default compose(
  connect(
    (state: any): any => ({
      year: state.DatePicker.year,
      month: state.DatePicker.month,
      client: state.Client.selectedClient,
    }),
    dispatch =>
      bindActionCreators({ ...DatePickerActions, ...ClientActions }, dispatch)
  )
)(DatePicker);
