import React, { useState, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import AutoComplete from '../../components/AutoComplete';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import {
  UserType,
  UserRoleType,
  ClientType,
  UpdateUserType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface UserClientType extends ClientType {
  startDateTable: string;
  endDateTable: string;
}

interface Props {
  onClose: () => void;
  open: boolean;
  clients: Array<ClientType>;
  update: (obj: UpdateUserType, id: number) => Promise<any>;
  loading: boolean;
  success: boolean;
  error: any;
  user: UserType;
  getUser: (id: number) => Promise<any>;
}

const DialogForm = (props: Props): ReactElement => {
  const {
    onClose,
    update,
    user,
    getUser,
    loading,
    success,
    error,
    clients,
    open,
  } = props;

  const [client, setClient] = useState<ClientType | undefined>(undefined);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const classes = useStyles();

  const handleClose = (): void => {
    setClient(undefined);
    setShowNotification(false);
    onClose();
  };

  const canSubmit = (): boolean => {
    if (client === undefined) return false;
    return true;
  };

  const submit = (): void => {
    if (!client) return;
    const editedClient = {
      ...client,
      refAssetClassSet: {
        createdBy: client.refAssetClassSet.createdBy,
        createdDate: client.refAssetClassSet.createdDate,
        id: client.refAssetClassSet.id,
        lastModifiedBy: client.refAssetClassSet.lastModifiedBy,
        lastModifiedDate: client.refAssetClassSet.lastModifiedDate,
        name: client.refAssetClassSet.name,
        useCorrelation: client.refAssetClassSet.useCorrelation,
      },
    };
    if (!client) return;
    if (canSubmit()) {
      const userClients =
        (user.clients &&
          user.clients.map((each: ClientType): any => ({
            ...each,
            refAssetClassSet: {
              createdBy: each.refAssetClassSet.createdBy,
              createdDate: each.refAssetClassSet.createdDate,
              id: each.refAssetClassSet.id,
              lastModifiedBy: each.refAssetClassSet.lastModifiedBy,
              lastModifiedDate: each.refAssetClassSet.lastModifiedDate,
              name: each.refAssetClassSet.name,
              useCorrelation: each.refAssetClassSet.useCorrelation,
            },
          }))) ||
        [];
      const newClients = userClients.concat(editedClient);
      update(
        {
          clients: newClients,
          roles: user.roles,
          username: user.username,
          password: user.password,
          name: user.name,
          email: user.email,
          accessToAllClients: user.accessToAllClients,
        },
        user.id
      ).then(() => getUser(user.id));
      setShowNotification(true);
    }
  };

  const retry = (): void => {
    setShowNotification(false);
  };

  let actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions = [
      {
        label: 'Back',
        onClick: retry,
      },
      {
        label: 'Cancel',
        onClick: handleClose,
        type: 'secondary',
      },
    ];
  }
  return (
    <Dialog
      paperClass={!showNotification && classes.addClientDialog}
      contentClassName={classes.dialogContent}
      open={open}
      onClose={handleClose}
      actions={actions}
      title="Add Client"
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Client added successfully.
          </Typography>
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error: {(error && error.messages && error.messages[0]) || 'Unknown'}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div className={classes.formContainer}>
          <AutoComplete
            onSelect={(value: ClientType): void => setClient(value)}
            selected={client && { label: client.name, value: client }}
            suggestions={clients.map((c: any): any => ({
              label: c.name,
              value: c,
            }))}
            onMenuOpen={clients}
            placeholder="Search for a client ..."
            propsClasses={{
              root: classes.autoCompRoot,
              input: classes.autoInput,
              valueContainer: classes.autoCompText,
              placeholder: classes.autoCompPlace,
              singleValue: classes.autoCompSingleValue,
              paper: classes.autoCompSuggestions,
            }}
          />
        </div>
      )}
    </Dialog>
  );
};

DialogForm.defaultProps = {
  model: undefined,
  user: undefined,
  success: false,
  error: undefined,
};

export default DialogForm;
