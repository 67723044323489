import React, { useState, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import AutoComplete from '../../components/AutoComplete';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import { SecurityType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface AccountSecurityCreateObj {
  refAccountId: number;
  refSecurityId: number;
}

interface Props {
  onClose: () => void;
  open: boolean;
  securities: Array<SecurityType>;
  attachSecurity: (
    obj: AccountSecurityCreateObj,
    id: number,
    dateobj: any
  ) => void;
  accountId: number;
  loading: boolean;
  success: boolean;
  error: any;
  dateData: { refAccountId: number; year: number; month: number };
}

const AddDialogForm = (props: Props): ReactElement => {
  const {
    onClose,
    attachSecurity,
    accountId,
    dateData,
    open,
    securities,
    loading,
    success,
    error,
  } = props;

  const [security, setSecurity] = useState<SecurityType | undefined>(undefined);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const classes = useStyles();

  const handleClose = (): void => {
    setSecurity(undefined);
    setShowNotification(false);
    onClose();
  };

  const canSubmit = (): boolean => {
    if (security === undefined) return false;
    return true;
  };

  const submit = (): void => {
    if (!security) return;
    if (canSubmit()) {
      attachSecurity(
        { refAccountId: accountId, refSecurityId: security.id },
        accountId,
        dateData
      );
      setShowNotification(true);
    }
  };

  const retry = (): void => {
    setShowNotification(false);
  };

  const actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions.push({
      label: 'Retry',
      onClick: retry,
    });
  }

  return (
    <Dialog
      paperClass={!showNotification ? classes.addSecurityDialog : ''}
      contentClassName={classes.dialogContent}
      open={open}
      onClose={handleClose}
      actions={actions}
      title="Add Security"
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Security added successfully.
          </Typography>
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error: {(error && error.messages && error.messages[0]) || 'Unknown'}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div
          className={classes.formContainer}
          style={{ flexDirection: 'column', paddingRight: 50 }}
        >
          <AutoComplete
            onSelect={(value: SecurityType): void => setSecurity(value)}
            selected={security && { label: security.name, value: security }}
            suggestions={securities.map((c: SecurityType): any => ({
              label: c.name,
              value: c,
            }))}
            onMenuOpen={securities}
            placeholder="Search for a security ..."
            propsClasses={{
              root: classes.autoCompRoot,
              input: classes.autoInput,
              valueContainer: classes.autoCompText,
              placeholder: classes.autoCompPlace,
              singleValue: classes.autoCompSingleValue,
              paper: classes.autoCompSuggestions,
            }}
          />
          {security && (
            <div style={{ marginLeft: 20, marginTop: 20 }}>
              <Typography variant="subtitle2" className={classes.securityInfo}>
                {`Display: ${security.display}`}
              </Typography>

              <Typography variant="subtitle2" className={classes.securityInfo}>
                {`ISIN: ${security.isin}`}
              </Typography>
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
};

AddDialogForm.defaultProps = {
  model: undefined,
  dateData: undefined,
  success: false,
  error: undefined,
};

export default AddDialogForm;
