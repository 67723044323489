import React, { ReactElement, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Drawer from '../../components/Drawer';
import Login from '../Login';
import MainToolbar from '../MainToolbar';
import Footer from '../Footer';
import { handleAccess } from '../../helpers/utils';

const drawerWidth = 300;

const styles = (theme: any): any =>
  createStyles({
    appContainer: {
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      zIndex: 1200,
    },
    appContainer2: {
      width: '100%',
      height: 'calc(100% - 64px)',
    },
    appContent: {
      width: '100%',
      marginTop: 64,
      height: '100%',
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      position: 'relative',
      // height: 'calc(100% - 40px)',
      width: 'calc(100% - 70px)',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    contentShift: {
      position: 'relative',
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    children: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
    },
    loading: {
      position: 'absolute',
      width: '100%',
    },
  });

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  loggedIn?: boolean;
  open?: boolean;
  loading?: boolean;
  children?: any;
  location?: any;
  history?: any;
  profile?: any;
  currentEnvironment?: any;
}

const AppWrapper = (props: Props): ReactElement => {
  const {
    location,
    profile,
    currentEnvironment,
    history,
    loggedIn,
    children,
    open,
    loading,
    ...others
  } = props;

  const [showError, setShowError] = useState<boolean>(false);
  const classes = useStyles();

  const denyAccess = (lastpath: string): void => {
    if (location.pathname === lastpath) {
      history.push('/');
      setShowError(true);
    } else {
      history.push(lastpath);
      setShowError(true);
    }
  };

  useEffect(() => {
    if (profile) {
      const hasAccess = handleAccess(
        location.pathname,
        profile,
        currentEnvironment
      );
      if (!hasAccess) {
        denyAccess(location.pathname);
      }
    }
  }, []);

  useEffect(() => {
    if (profile) {
      const hasAccess = handleAccess(
        location.pathname,
        profile,
        currentEnvironment
      );
      if (!hasAccess) {
        denyAccess(location.pathname);
      }
    }
  }, [location.pathname, profile]);

  const ErrorSnackBar = (): ReactElement => (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={showError}
      autoHideDuration={5000}
      onClose={(event: any, reason: any): void => {
        if (reason === 'clickaway') {
          setShowError(false);
          return;
        }
        setShowError(false);
      }}
    >
      <SnackbarContent
        aria-describedby="client-snackbar"
        message={
          <div>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={(): void => setShowError(false)}
            >
              <CloseIcon />
            </IconButton>
            <span style={{ marginRight: 20 }}>
              This User Can not Access to Requested Route!
            </span>
          </div>
        }
        style={{
          backgroundColor: 'white',
          color: 'black',
          fontWeight: 'bold',
        }}
      />
    </Snackbar>
  );

  return (
    <div className={classes.appContainer}>
      {!loggedIn && <Login />}
      {loggedIn && (
        <div className={classes.appContainer2}>
          <MainToolbar {...others} history={history} />
          <ErrorSnackBar />
          <div className={classes.appContent}>
            <Drawer {...others} history={history} />
            <div
              className={classNames(classes.content, {
                [classes.contentShift]: open,
              })}
            >
              {loading &&
                window.location.href.indexOf('selected-client') === -1 && (
                  <LinearProgress className={classes.loading} />
                )}
              <div className={classes.children}>{children}</div>
              {false && <Footer />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: any): any {
  return {
    open: state.Drawer.open,
    loading:
      (state.AssetTable.loadingValue ||
        state.Client.loading ||
        state.Currency.loading ||
        state.AssetClassSet.loading ||
        state.RiskClass.loading ||
        state.AssetManager.loading ||
        state.Security.loading ||
        state.AccountType.loading ||
        state.AccountCode.loading ||
        state.Region.loading ||
        state.Overview.loading ||
        state.Report.loading ||
        state.ClientType.loading ||
        state.Correlation.loading ||
        state.Report.loading) &&
      !state.Dialog.open,
  };
}

AppWrapper.propTypes = {};

AppWrapper.defaultProps = {
  loading: false,
  open: false,
  profile: undefined,
  currentEnvironment: undefined,
};

export default connect(mapStateToProps)(AppWrapper);
