import * as types from '../constants/Tour';

const disbaleHomeTour = (): any => (dispatch: any): void => {
  dispatch({ type: types.DISABLE_HOME_TOUR });
};

const disbaleUserMenuTour = (): any => (dispatch: any): void => {
  dispatch({ type: types.DISABLE_USER_MENU_TOUR });
};

const TourActions = {
  disbaleHomeTour,
  disbaleUserMenuTour,
};

export default TourActions;
