import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const list = (): any =>
  Request('currency', 'GET')
    .then(ErrorHandler)
    .then((currencies: any): any => currencies);

const item = (id: number): any =>
  Request(`currency/${id}`, 'GET')
    .then(ErrorHandler)
    .then((currency: any): any => currency);

const remove = (id: number): any =>
  Request(`currency/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((currency: any): any => currency);

const create = (data: any): any =>
  Request('currency', 'POST', data)
    .then(ErrorHandler)
    .then((currency: any): any => currency);

const update = (data: any, id: number): any =>
  Request(`currency/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((currency: any): any => currency);

const CurrencyService = {
  list,
  item,
  create,
  update,
  remove,
};

export default CurrencyService;
