import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import ClientActions from '../../../../actions/ClientActions';
import BenchmarkActions from '../../../../actions/BenchmarkActions';
import Charting from './Charting';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ ...ClientActions, ...BenchmarkActions }, dispatch);

const getClientId = (state: any): number =>
  state.Client.selectedClient && state.Client.selectedClient.id;
const getClientData = (state: any): any => state.Client.clientData;
const getCurrentDate = (state: any): any => state.Client.currentDate;

const getFoundClientData = createSelector(
  [getClientData, getClientId],
  (data, id) => data && data.find((each: any): any => each.id === id)
);

const getCurrentDateData = createSelector(
  [getFoundClientData, getCurrentDate],
  (data, date) =>
    data &&
    data.dates &&
    data.dates.find((eachDate: any): any => eachDate.date === date)
);

const getChartingOfCurrentDate = createSelector(
  [getCurrentDateData],
  data => data && data.charting
);

const getSelectedShowingRows = createSelector(
  [getFoundClientData],
  data => data && data.monitorShowingRows
);

const getSelectedStartYear = createSelector(
  [getFoundClientData],
  data => data && data.chartingStartYear
);

const getSelectedStartMonth = createSelector(
  [getFoundClientData],
  data => data && data.chartingStartMonth
);

const getSelectedEndYear = createSelector(
  [getFoundClientData],
  data => data && data.chartingEndYear
);

const getSelectedEndMonth = createSelector(
  [getFoundClientData],
  data => data && data.chartingEndMonth
);

const getSelectedRows = createSelector(
  [getFoundClientData],
  data => data && data.selectedRows
);

const getSelectedColumns = createSelector(
  [getFoundClientData],
  data => data && data.selectedColumns
);

const getSelectedDate = createSelector(
  [getFoundClientData],
  data => data && data.selectedDate
);

const getSelectedRiskFree = createSelector(
  [getFoundClientData],
  data => data && data.chartingRiskFree
);

const mapStateToProps = (state: any): any => ({
  loading: state.Client.loading,
  client: state.Client.selectedClient,
  chartingData: getChartingOfCurrentDate(state),
  selectedRows: getSelectedRows(state),
  selectedColumns: getSelectedColumns(state),
  selectedShowingRows: getSelectedShowingRows(state),
  selectedStartMonth: getSelectedStartMonth(state),
  selectedStartYear: getSelectedStartYear(state),
  selectedEndYear: getSelectedEndYear(state),
  selectedEndMonth: getSelectedEndMonth(state),
  selectedDate: getSelectedDate(state),
  selectedRiskFree: getSelectedRiskFree(state),
  selectionChanged: state.Client.selectionChanged,
});

export default connect(mapStateToProps, mapDispatchToProps)(Charting);
