import * as types from '../constants/AccountCode';

const AccountCode = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.ACCOUNT_CODES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_CODES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        accountCodes: action.accountCodes,
      };
    case types.ACCOUNT_CODES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ACCOUNT_CODE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_CODE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.ACCOUNT_CODE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.ACCOUNT_CODE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_CODE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.ACCOUNT_CODE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.ACCOUNT_CODE_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_CODE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        accountCode: action.accountCode,
      };
    case types.ACCOUNT_CODE_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ACCOUNT_CODE_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_CODE_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        reference: 'deleted',
        accountCodes: {
          ...state.accountCodes,
          content: state.accountCodes.content.filter(
            (ac: any): any => ac.id !== action.id
          ),
        },
      };
    case types.ACCOUNT_CODE_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default AccountCode;
