import OverviewService from '../services/OverviewService';
import * as types from '../constants/Overview';
import {
  AssetManagerOverviewType,
  ClientOverviewType,
  SecurityOverviewType,
} from '../Types';

const getClientsOverview = (year: number, month: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.GET_CLIENTS_OVERVIEW_REQUEST });
  OverviewService.getClientsOverview(year, month)
    .then((clientsOverview: ClientOverviewType): void => {
      dispatch({ type: types.GET_CLIENTS_OVERVIEW_SUCCESS, clientsOverview });
    })
    .catch((error: any): void => {
      dispatch({ type: types.GET_CLIENTS_OVERVIEW_FAILURE, error });
    });
};

const getSecuritiesOverview = (year: number, month: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.GET_SECURITIES_OVERVIEW_REQUEST });
  OverviewService.getSecuritiesOverview(year, month)
    .then((securitiesOverview: SecurityOverviewType): void => {
      dispatch({
        type: types.GET_SECURITIES_OVERVIEW_SUCCESS,
        securitiesOverview,
      });
    })
    .catch((error: any): void => {
      dispatch({ type: types.GET_SECURITIES_OVERVIEW_FAILURE, error });
    });
};

const getAssetManagersOverview = (year: number, month: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.GET_ASSET_MANAGERS_OVERVIEW_REQUEST });
  OverviewService.getAssetManagersOverview(year, month)
    .then((assetManagersOverview: AssetManagerOverviewType): void => {
      dispatch({
        type: types.GET_ASSET_MANAGERS_OVERVIEW_SUCCESS,
        assetManagersOverview,
      });
    })
    .catch((error: any): void => {
      dispatch({ type: types.GET_ASSET_MANAGERS_OVERVIEW_FAILURE, error });
    });
};

const OverviewActions = {
  getClientsOverview,
  getSecuritiesOverview,
  getAssetManagersOverview,
};

export default OverviewActions;
