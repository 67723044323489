import React, { useState, useEffect, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import LensIcon from '@material-ui/icons/Lens';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import DialogForm from './DialogForm';
import styles from './styles';
import {
  RiskClassType,
  ExportType,
  UserType,
  MutateRiskClassType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  assetClassSetId: number;
  riskClasses?: { content: Array<RiskClassType> };
  removeRiskClass?: (id: number) => void;
  getRiskClassesList?: (id: number) => void;
  createRiskClass?: (obj: MutateRiskClassType, id: number) => void;
  updateRiskClass?: (
    obj: MutateRiskClassType,
    id: number,
    dataId: number
  ) => void;
  openDialog?: () => void;
  closeDialog?: () => void;
  loading?: boolean;
  success?: boolean;
  deleted?: boolean;
  error?: any;
  getUserItem?: (id: number) => Promise<UserType>;
  exportRiskClassesTable?: (id: number) => Promise<ExportType>;
}

const RiskClass = (props: Props): ReactElement => {
  const {
    getRiskClassesList,
    assetClassSetId,
    openDialog,
    closeDialog,
    removeRiskClass,
    exportRiskClassesTable,
    riskClasses,
    updateRiskClass,
    createRiskClass,
    loading,
    success,
    error,
    deleted,
    getUserItem,
    ...others
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(undefined);
  const [model, setModel] = useState<any>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (getRiskClassesList) getRiskClassesList(assetClassSetId);
  }, [getRiskClassesList, assetClassSetId]);

  const openAdd = (): void => {
    if (openDialog) openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const openEdit = (event: any, row: any): void => {
    if (openDialog) openDialog();
    setOpen(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    if (closeDialog) closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: any): void => {
    if (openDialog) openDialog();
    setOpenDelete(true);
    setSelectedRow(row);
  };

  const handleClose = (): void => {
    if (closeDialog) closeDialog();
    setOpen(false);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (removeRiskClass) removeRiskClass(selectedRow.id);
  };

  const exportTable = (): void => {
    if (exportRiskClassesTable)
      exportRiskClassesTable(assetClassSetId).then((res: ExportType): void => {
        const csvData = new Blob([res.export], {
          type: 'text/csv;charset=utf-8;',
        });
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', res.fileName || 'RiskClasses.csv');
        tempLink.click();
      });
  };

  const columns = [
    {
      title: '',
      name: 'colorIcon',
      style: { width: 5 },
      rowStyle: { width: 5 },
    },
    {
      title: 'Name',
      name: 'name',
      sortable: true,
      type: 'string',
      sortField: 'name',
    },
    {
      title: 'Rank No.',
      name: 'rankno',
      sortable: true,
      type: 'number',
      sortField: 'rankno',
    },
  ];

  const newRiskClasses =
    (riskClasses &&
      riskClasses.content.map((each: RiskClassType): any => ({
        ...each,
        colorIcon: <LensIcon style={{ color: each.color, marginBottom: -8 }} />,
      }))) ||
    [];

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          hasEditAndDelete
          noMargin
          onEdit={openEdit}
          onDelete={handleDeleteOpen}
          onAdd={openAdd}
          columns={columns}
          rows={newRiskClasses}
          entity="Risk Class"
          entities="Risk Classes"
          hasExportButton
          onExport={exportTable}
          {...others}
        />
      </div>
      <DialogForm
        model={model}
        open={open}
        assetClassSetId={assetClassSetId}
        onClose={handleClose}
        update={updateRiskClass}
        create={createRiskClass}
        loading={loading}
        success={success}
        error={error}
        getUserItem={getUserItem}
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {isRemoving && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Risk Class removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error:{' '}
              {error &&
                error.messages &&
                Array.isArray(error.messages) &&
                error.messages[0]}
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};

RiskClass.defaultProps = {
  error: undefined,
  deleted: false,
  success: false,
  assetClassSet: undefined,
  riskClasses: undefined,
};

export default RiskClass;
