import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Dialog from '../../containers/Dialog';
import DataTable from '../../containers/DataTable';
import SelectionProfileActions from '../../actions/SelectionProfileActions';
import { SelectionProfileType } from '../../Types';
import styles from './styles';

const useStyles = makeStyles<typeof styles>(styles);

interface SelectionProfileProps {
  open: boolean;
  onClose: (hasRemovedSelection?: boolean) => void;
  clientId: number;
  handleSelectProfile: (
    obj: SelectionProfileType,
    hasRemovedSelection?: boolean
  ) => void;
}

const SelectionProfile = (props: SelectionProfileProps): any => {
  const { open, onClose, clientId, handleSelectProfile } = props;
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [removedSuccessfully, setRemovedSuccessfully] = useState<boolean>(
    false
  );
  const [hasRemovedSelection, setHasRemovedSelection] = useState<boolean>(
    false
  );
  const {
    removeSelectionProfile,
    getSelectionProfileList,
  }: any = SelectionProfileActions;
  const dispatch = useDispatch();
  const { selectionProfileList, error, loading } = useSelector(
    (state: any): any => ({
      selectionProfileList: state.SelectionProfile.selectionProfileList,
      error: state.SelectionProfile.error,
      loading: state.SelectionProfile.loading,
    })
  );

  const classes = useStyles();

  useEffect(() => {
    dispatch(getSelectionProfileList(clientId));
  }, []);

  const columns = [
    {
      title: 'Name',
      name: 'name',
      type: 'string',
    },
  ];

  const remove = (event: any, row: SelectionProfileType): void => {
    dispatch(removeSelectionProfile(row?.id))
      .then(() => {
        setOpenNotification(true);
        setRemovedSuccessfully(true);
        setHasRemovedSelection(true);
      })
      .catch(() => {
        setOpenNotification(true);
        setRemovedSuccessfully(false);
      });
  };

  const handleOnRowClick = (event: any, row: SelectionProfileType): void => {
    handleSelectProfile(row, hasRemovedSelection);
  };

  return (
    <Dialog
      open={open}
      onClose={(): void => onClose(hasRemovedSelection)}
      title="Selection List"
      paperClass={classes.listpaper}
      titleClassName={classes.dialogTitle}
    >
      <div className={classes.container}>
        {loading && <LinearProgress className={classes.progress} />}
        <DataTable
          rows={selectionProfileList}
          columns={columns}
          hasEditAndDelete
          onDelete={remove}
          onClickRow={handleOnRowClick}
          noMargin
          hasToolbar={false}
          notShowFooter
          hasDetails
          entities="Selection"
          entity="Selection"
          inDialog
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openNotification && !loading}
          autoHideDuration={5000}
          onClose={(): void => setOpenNotification(false)}
        >
          <div>
            {removedSuccessfully && (
              <SnackbarContent
                className={classnames(classes.success)}
                aria-describedby="client-snackbar"
                message={
                  <span id="client-snackbar" className={classes.message}>
                    <CheckCircleIcon
                      className={classnames(classes.icon, classes.iconVariant)}
                    />
                    Selection removed successfully.
                  </span>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={(): void => setOpenNotification(false)}
                  >
                    <CloseIcon className={classes.icon} />
                  </IconButton>,
                ]}
              />
            )}
            {!removedSuccessfully && (
              <SnackbarContent
                className={classnames(classes.error)}
                aria-describedby="client-snackbar"
                message={
                  <span id="client-snackbar" className={classes.message}>
                    <ErrorIcon
                      className={classnames(classes.icon, classes.iconVariant)}
                    />
                    Error:{' '}
                    {(error && error.messages && error.messages[0]) ||
                      'Unknown'}
                  </span>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={(): void => setOpenNotification(false)}
                  >
                    <CloseIcon className={classes.icon} />
                  </IconButton>,
                ]}
              />
            )}
          </div>
        </Snackbar>
      </div>
    </Dialog>
  );
};

export default SelectionProfile;
