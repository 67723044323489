import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssetManagerActions from '../../actions/AssetManagerActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import ExportActions from '../../actions/ExportActions';
import AssetManager from './AssetManager';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...AssetManagerActions,
      ...DialogActions,
      ...UserActions,
      ...ExportActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  assetManagers: state.AssetManager.assetManagers,
  deleted: state.AssetManager.deleted,
  assetManager: state.AssetManager.assetManager,
  success: state.AssetManager.success,
  error: state.AssetManager.error,
  loading: state.AssetManager.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetManager);
