import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const charting = (data: any): any =>
  Request(`assettable/charting`, 'POST', {
    riskFree: data.riskFree,
    startmonth: data.startmonth,
    endmonth: data.endmonth,
    startyear: data.startyear,
    endyear: data.endyear,
    id: data.id,
    month: data.month,
    year: data.year,
    disabledColumns: data.disabledColumns,
    enabledRows: data.enabledRows,
    benchmarkIds: data.benchmarkIds,
  })
    .then(ErrorHandler)
    .then((chartingData: any): any => chartingData);

const getChartingRow = (data: any): any =>
  Request(`assettable/charting/benchmark`, 'POST', {
    riskFree: data.riskFree,
    startmonth: data.startmonth,
    endmonth: data.endmonth,
    startyear: data.startyear,
    endyear: data.endyear,
    month: data.month,
    year: data.year,
    benchmarkId: data.benchmarkId,
  })
    .then(ErrorHandler)
    .then((chartingRow: any): any => chartingRow);

const ChartingService = {
  charting,
  getChartingRow,
};

export default ChartingService;
