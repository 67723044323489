import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BenchmarkActions from '../../actions/BenchmarkActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import ExportActions from '../../actions/ExportActions';
import Benchmark from './Benchmark';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    { ...DialogActions, ...BenchmarkActions, ...UserActions, ...ExportActions },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  benchmarks: state.Benchmark.benchmarks,
  deleted: state.Benchmark.deleted,
  benchmark: state.Benchmark.benchmark,
  success: state.Benchmark.success,
  error: state.Benchmark.error,
  loading: state.Benchmark.loading,
  currentEnvironment: state.Environment.currentEnvironment,
});

export default connect(mapStateToProps, mapDispatchToProps)(Benchmark);
