import React, {
  useState,
  useEffect,
  ReactElement,
  useRef,
  useLayoutEffect,
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Pie } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import DataTable from '../ClientDataTable';
import { setColor } from '../../../../helpers/utils';
import styles from './styles';
import { AssetsType } from '../../../../Types';
// eslint-disable-next-line
const labels = require("chartjs-plugin-labels");

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  assets?: AssetsType;
  year: number;
  month: number;
  getAssets?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>
  ) => Promise<AssetsType>;
  clientId: number;
  readOnly?: boolean;
  parentReference: any;
  getNode?: (ref: any, name: string, id: number) => void;
  cardId?: number;
  selectedDate?: any;
  selectedChart?: string;
  selectedColumns?: Array<number>;
  selectedRows?: Array<number>;
  setAssetsChart?: (id: number, chart: string) => void;
  showNotification: () => void;
  rootClass?: any;
  root1Class?: any;
}

const Assets = (props: Props): ReactElement => {
  const {
    selectedDate,
    clientId,
    readOnly,
    cardId,
    month,
    year,
    getAssets,
    selectedRows,
    selectedColumns,
    showNotification,
    getNode,
    setAssetsChart,
    selectedChart,
    parentReference,
    assets,
    root1Class,
    rootClass,
  } = props;

  const [assetClassSetPortions, setAssetClassSetPortions] = useState<any>([]);
  const [assetManagerPortions, setAssetManagerPortions] = useState<any>([]);
  const [hovered, setHovered] = useState<string>('');
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {});

  const setAssets = (newAssets?: AssetsType): void => {
    if (newAssets) {
      const assetClassSetPortionsColors = setColor(
        newAssets.assetClassSetPortions.length
      );
      const assetManagerPortionsColors = setColor(
        newAssets.assetManagerPortions.length
      );
      const newAssetClassSetPortions = newAssets.assetClassSetPortions.map(
        (each: any, index: number): any => ({
          ...each,
          color: assetClassSetPortionsColors[index],
        })
      );
      const newAssetManagerPortions = newAssets.assetManagerPortions.map(
        (each: any, index: number): any => ({
          ...each,
          color: assetManagerPortionsColors[index],
        })
      );
      setAssetClassSetPortions(newAssetClassSetPortions);
      setAssetManagerPortions(newAssetManagerPortions);
      if (readOnly)
        setTimeout(() => {
          if (getNode && cardId) getNode(containerRef, 'ASSETS', cardId);
        }, 2000);
    }
  };

  useEffect(() => {
    if (!readOnly) setAssets(assets);
  }, []);

  const getAssetsData = (): void => {
    if (getAssets && selectedRows && selectedColumns)
      getAssets(clientId, year, month, selectedRows, selectedColumns)
        .then((response: AssetsType): void => {
          setAssets(response);
        })
        .catch((): any => showNotification && showNotification());
  };

  useEffect(() => {
    getAssetsData();
  }, [
    month,
    year,
    clientId,
    selectedRows,
    selectedColumns,
    cardId,
    selectedDate,
  ]);

  const showChart = (title: string): void => {
    if (title === 'Asset Manager' && setAssetsChart) {
      setAssetsChart(clientId, 'assetManagerPortions');
    } else if (title === 'Asset Allocation' && setAssetsChart) {
      setAssetsChart(clientId, 'assetClassSetPortions');
    }
  };

  const showTooltip = (event: any): any => {
    const { dataPoints } = event;
    if (Array.isArray(dataPoints)) {
      if (
        selectedChart === 'assetClassSetPortions' &&
        assetClassSetPortions.length > 0
      ) {
        setHovered(assetClassSetPortions[dataPoints[0].index].name);
      } else if (
        selectedChart === 'assetManagerPortions' &&
        assetManagerPortions.length > 0
      ) {
        setHovered(assetManagerPortions[dataPoints[0].index].name);
      }
    } else {
      setHovered('');
    }
    return event;
  };

  const cancelHover = (): void => {
    setHovered('');
  };

  const columns = [
    { title: 'Name', name: 'name' },
    { title: 'Value', name: 'value' },
    { title: 'Percentage', name: 'percentage' },
  ];

  const isAssetManagerData =
    assetManagerPortions &&
    assetManagerPortions.length &&
    assetManagerPortions
      .map((r: any): number => r.value)
      .reduce((total: number, each: number): number => total + each) !== 0;

  const isAssetClassSetData =
    assetClassSetPortions &&
    assetClassSetPortions.length &&
    assetClassSetPortions
      .map((r: any): number => r.value)
      .reduce((total: number, each: number): number => total + each) !== 0;

  return (
    <div className={classNames(classes.root1, root1Class)}>
      <div
        className={classNames(classes.root, rootClass)}
        ref={readOnly ? containerRef : parentReference}
      >
        <div className={classes.table}>
          <DataTable
            title="Asset Allocation"
            rows={assetClassSetPortions}
            columns={columns}
            onClickButton={showChart}
            isSelected={selectedChart === 'assetClassSetPortions'}
            hovered={hovered}
            hasTotalRow
            hasToolbarButton={!readOnly}
            ButtonLabel="Show Chart"
            hasColor
            type2
          />
        </div>
        <div className={classes.chart}>
          {selectedChart === 'assetClassSetPortions' && (
            <div className={classes.midColumn}>
              <Toolbar className={classes.toolbar}>
                <div className={classes.titleContainer}>
                  <Typography
                    className={classNames(classes.title)}
                    id="tableTitle"
                    variant="h3"
                  >
                    Asset Allocation Chart
                  </Typography>
                </div>
              </Toolbar>
              <div
                className={classes.chartContainer}
                onMouseLeave={cancelHover}
              >
                <Pie
                  data={{
                    datasets: [
                      {
                        data: isAssetClassSetData
                          ? assetClassSetPortions.map(
                              (each: any): any => each.value
                            )
                          : [1],
                        backgroundColor: isAssetClassSetData
                          ? assetClassSetPortions.map((each: any): string => {
                              if (each.name === hovered)
                                return `${each.color}99`;
                              return each.color;
                            })
                          : ['#D3D3D3'],
                      },
                    ],
                    labels: isAssetClassSetData
                      ? assetClassSetPortions.map(
                          (each: any): string => each.name
                        )
                      : ['No Data'],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                      display: false,
                    },
                    tooltips: {
                      custom: (event: any): any => showTooltip(event),
                      enabled: false,
                    },
                    plugins: {
                      labels: {
                        render: isAssetClassSetData ? 'percentage' : 'label',
                        fontColor: 'white',
                        precision: 0,
                        fontSize: 11,
                        position: 'border',
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
          {selectedChart === 'assetManagerPortions' && (
            <div className={classes.midColumn}>
              <Toolbar className={classes.toolbar}>
                <div className={classes.titleContainer}>
                  <Typography
                    className={classNames(classes.title)}
                    id="tableTitle"
                    variant="h3"
                  >
                    Asset Manager Chart
                  </Typography>
                </div>
              </Toolbar>
              <div
                className={classes.chartContainer}
                onMouseLeave={cancelHover}
              >
                <Pie
                  data={{
                    datasets: [
                      {
                        data: isAssetManagerData
                          ? assetManagerPortions.map(
                              (each: any): any => each.value
                            )
                          : [1],
                        backgroundColor: isAssetManagerData
                          ? assetManagerPortions.map((each: any): string => {
                              if (each.name === hovered)
                                return `${each.color}99`;
                              return each.color;
                            })
                          : ['#D3D3D3'],
                      },
                    ],
                    labels: isAssetManagerData
                      ? assetManagerPortions.map(
                          (each: any): string => each.name
                        )
                      : ['No Data'],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                      display: false,
                    },
                    tooltips: {
                      custom: (event: any): any => showTooltip(event),
                      enabled: false,
                    },
                    plugins: {
                      labels: {
                        render: isAssetManagerData ? 'percentage' : 'label',
                        fontColor: 'white',
                        precision: 0,
                        fontSize: 11,
                        position: 'border',
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className={classes.table}>
          <DataTable
            title="Asset Manager"
            rows={assetManagerPortions}
            columns={columns}
            onClickButton={showChart}
            isSelected={selectedChart === 'assetManagerPortions'}
            hovered={hovered}
            hasTotalRow
            hasToolbarButton={!readOnly}
            ButtonLabel="Show Chart"
            hasColor
            type2
          />
        </div>
      </div>
    </div>
  );
};

Assets.defaultProps = {
  assets: undefined,
  readOnly: false,
  isLeftChart: false,
  tableEnabledRows: [],
  tableDisabledColumns: [],
  reportObject: undefined,
  setReportJSON: undefined,
  parentReference: undefined,
  getNode: undefined,
  cardId: undefined,
  selectedChart: undefined,
  selectedDate: undefined,
  selectedColumns: [],
  selectedRows: [],
  showNotification: undefined,
};

export default Assets;
