import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const assetTable = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>
): any =>
  Request(`assettable/`, 'POST', {
    year,
    month,
    id: clientId,
    disabledColumns,
    enabledRows,
  })
    .then(ErrorHandler)
    .then((aTable: any): any => aTable);

const getStartDate = (clientId: number): any =>
  Request(`assettable/${clientId}/startdate`, 'GET')
    .then(ErrorHandler)
    .then((date: any): any => date);

const AssetTableService = {
  assetTable,
  getStartDate,
};

export default AssetTableService;
