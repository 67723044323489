import React, {
  useState,
  useEffect,
  useRef,
  ReactElement,
  useLayoutEffect,
} from 'react';
import classnames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RestartIcon from '@material-ui/icons/RefreshOutlined';
import TableValues from '../../../../containers/ValuesTable';
import CheckMark from '../AssetTable/custom-icons/check-mark1.svg';
import CheckedMark from '../AssetTable/custom-icons/check-mark-single1.svg';
import CheckedAll from '../AssetTable/custom-icons/check-mark-all4.svg';
import Liquid from '../AssetTable/custom-icons/liquid2.svg';
import Fixed from '../AssetTable/custom-icons/fixed2.svg';
import None from '../AssetTable/custom-icons/none2.svg';
import History from '../AssetTable/custom-icons/history.svg';
import HistoryDisabled from '../AssetTable/custom-icons/history-disabled.svg';
import styles from './styles';
import {
  AssetAllocationType,
  AssetAllocationColumnType,
  AssetAllocationRowType,
  AssetAllocationRowSecurityType,
  AssetAllocationRowValueType,
  UserProfileType,
  MutateAllocationValueType,
  MutateAllocationWeightType,
} from '../../../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface AllocationWightCreateObj {
  year: number;
  month: number;
  value: number;
  refAssetClassId: number;
  type: string;
}

interface AssetAllocaionValueCreateObj {
  year: number;
  month: number;
  value: number;
  refAssetClassId: number;
  refAccountId: number;
}

interface AccountCodeFilterObj {
  name: string;
  selected: boolean;
}

interface Props {
  selectedRows?: Array<number>;
  selectedColumns?: Array<number>;
  month: number;
  year: number;
  clientId: number;
  showNotification?: () => void;
  getAllocation?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>
  ) => Promise<AssetAllocationType>;
  expandedRows?: Array<number>;
  readOnly?: boolean;
  getNode?: (ref: any, name: string, id: number) => void;
  cardId?: number;
  setSelectedColumns?: (id: number, columns: Array<number>) => void;
  setSelectedRows?: (id: number, rows: Array<number>) => void;
  setSelectedHistory?: (id: number, rows: Array<number>) => void;
  profile?: UserProfileType;
  createAllocationWeightValue?: (
    obj: MutateAllocationWeightType
  ) => Promise<any>;
  updateAllocationWeightValue?: (
    obj: MutateAllocationWeightType
  ) => Promise<any>;
  setExpandedRows?: (id: number, rows: Array<any>) => void;
  createAssetAllocationValue?: (obj: MutateAllocationValueType) => Promise<any>;
  updateAssetAllocationValue?: (obj: MutateAllocationValueType) => Promise<any>;
  setActiveHistory?: (id: number, active: boolean) => void;
  historyActive?: boolean;
  parentReference?: any;
  removeAllocationWeightValue?: (
    obj: MutateAllocationWeightType
  ) => Promise<any>;
  removeAssetAllocationValue?: (obj: MutateAllocationValueType) => Promise<any>;
  allocationTable?: AssetAllocationType;
  firstStatus?: string;
  secondStatus?: string;
  setAllocationFirstStatus?: (id: number, status: string) => void;
  setAllocationSecondStatus?: (id: number, status: string) => void;
  rootClass?: any;
  root1Class?: any;
}

const Allocation = (props: Props): ReactElement => {
  const {
    selectedRows,
    selectedColumns,
    month,
    year,
    clientId,
    showNotification,
    getAllocation,
    getNode,
    cardId,
    readOnly,
    expandedRows,
    setSelectedColumns,
    setSelectedRows,
    setSelectedHistory,
    profile,
    setExpandedRows,
    createAllocationWeightValue,
    updateAllocationWeightValue,
    createAssetAllocationValue,
    updateAssetAllocationValue,
    setActiveHistory,
    historyActive,
    parentReference,
    allocationTable,
    removeAllocationWeightValue,
    removeAssetAllocationValue,
    firstStatus,
    secondStatus,
    setAllocationFirstStatus,
    setAllocationSecondStatus,
    rootClass,
    root1Class,
  } = props;
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [tableRows, setTableRows] = useState<any>([]);
  const [allocationData, setAllocationData] = useState<
    AssetAllocationType | undefined
  >(undefined);
  const [weightRows, setWeightRows] = useState<any>([]);
  const [riskRows, setRiskRows] = useState<any>([]);
  const [riskColumns, setRiskColumns] = useState<any>([]);
  const [selectedAccountCodes, setSelectedAccountCodes] = useState<any>([]);
  const [fixedLiquidButton, setFixedLiquidButton] = useState<string>('none');
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {});

  const tableStatuses = ['Ass.Man.', 'Total'];
  const secondTableStatuses = ['Actual', 'Input'];

  const role =
    profile && profile.roles && profile.roles[0] && profile.roles[0].name;

  const handleCheckbox = (value: boolean, name: string): void => {
    const detailColumns = tableColumns.filter(
      (col: any): any =>
        col.id === 'accountCode' ||
        col.id === 'accountNumber' ||
        col.id === 'accountType'
    );

    let newAccountCodes: any[] = [];
    if (name === 'Select All') {
      newAccountCodes = selectedAccountCodes.map(
        (each: AccountCodeFilterObj): AccountCodeFilterObj => ({
          ...each,
          selected: value,
        })
      );
    } else {
      newAccountCodes = selectedAccountCodes.map(
        (each: AccountCodeFilterObj): AccountCodeFilterObj => {
          if (each.name === name) return { ...each, selected: !each.selected };
          return each;
        }
      );
    }
    if (fixedLiquidButton === 'liquid') {
      const newRows = tableRows.map((r: any): any => {
        if (r.assetType !== 'FIXED') return { ...r, enabled: false };
        if (r.assetType === 'FIXED') {
          if (
            newAccountCodes.find(
              (each: any): any => each.name === r.accountCode
            ) &&
            newAccountCodes.find(
              (each: any): any => each.name === r.accountCode
            ).selected
          ) {
            return { ...r, enabled: true };
          }
          return { ...r, enabled: false };
        }
        return r;
      });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setSelectedAccountCodes(newAccountCodes);
      // eslint-disable-next-line
      setColumns(detailColumns, newAccountCodes,allocationData);
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    } else if (fixedLiquidButton === 'fixed') {
      const newRows = tableRows.map((r: any): any => {
        if (r.assetType !== 'FIXED') return { ...r, enabled: false };
        if (r.assetType === 'FIXED') {
          if (
            newAccountCodes.find(
              (each: any): any => each.name === r.accountCode
            ) &&
            newAccountCodes.find(
              (each: any): any => each.name === r.accountCode
            ).selected
          ) {
            return { ...r, enabled: true };
          }
          return { ...r, enabled: false };
        }
        return r;
      });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setSelectedAccountCodes(newAccountCodes);
      // eslint-disable-next-line
      setColumns(detailColumns, newAccountCodes,allocationData);
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    } else {
      const newRows = tableRows
        .filter((r: any): any => !r.isSecurity)
        .map((r: any): any => {
          if (
            newAccountCodes.find(
              (each: any): any => each.name === r.accountCode
            ) &&
            newAccountCodes.find(
              (each: any): any => each.name === r.accountCode
            ).selected
          )
            return { ...r, enabled: true };
          return { ...r, enabled: false };
        });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setSelectedAccountCodes(newAccountCodes);
      // eslint-disable-next-line
      setColumns(detailColumns, newAccountCodes,allocationData);
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    }
  };

  const setColumns = (
    detailColumns: any,
    accountCodes: Array<AccountCodeFilterObj>,
    allocation?: AssetAllocationType
  ): void => {
    const { columns } = allocation || { columns: [] };
    const totalRow: { [key: string]: any } = { assetManager: 'Total' };
    let newColumns: any[] = [];
    if (allocation) {
      newColumns = [
        {
          title: 'Asset Manager',
          name: 'assetManager',
          type: 'header',
          columnId: 'assetManager-Asset Manager',
        },
        {
          title: 'Account Code',
          name: 'accountCode',
          columnId: 'accountCode-Account Code',
          type: 'readOnly',
          hidden: !(
            detailColumns &&
            detailColumns.find(
              (col: any): any =>
                col.id === 'accountCode' && col.hidden === false
            )
          ),
          id: 'accountCode',
          hasDetails: true,
          detailsTitle: 'Select Code',
          details: accountCodes.map((each: AccountCodeFilterObj): any => ({
            name: each.name,
            onClick: (checked: boolean, name: string): any =>
              handleCheckbox(checked, name),
            notCheckedIcon: (
              <CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />
            ),
            checkedIcon: <CheckBoxIcon style={{ fontSize: 20 }} />,
            selected: each.selected,
          })),
          style: { textAlign: 'Left', paddingLeft: '6px' },
        },
        {
          title: 'Account Type',
          name: 'accountType',
          columnId: 'accountType-Account Type',
          type: 'readOnly',
          hidden: !(
            detailColumns &&
            detailColumns.find(
              (col: any): any =>
                col.id === 'accountType' && col.hidden === false
            )
          ),
          id: 'accountType',
          style: { textAlign: 'Left', paddingLeft: '6px' },
        },
        {
          title: 'Account Number',
          name: 'accountNumber',
          columnId: 'accountNumber-Account Number',
          type: 'readOnly',
          hidden: !(
            detailColumns &&
            detailColumns.find(
              (col: any): any =>
                col.id === 'accountNumber' && col.hidden === false
            )
          ),
          id: 'accountNumber',
          style: { textAlign: 'Left', paddingLeft: '6px' },
        },
      ];
      columns.forEach((col: AssetAllocationColumnType): void => {
        newColumns.push({
          title: col.columnHeading,
          name: `${col.id}`,
          columnId: `${col.id}-${col.columnHeading}`,
          id: col.id,
          description: col.description,
          style: col.disabled ? { color: 'rgb(0, 0 , 0, 0.4)' } : {},
          disabled: col.disabled,
          canDisable: true,
          hoveredComponent: (
            <div className={classes.popLabel}>
              <strong>Description:</strong> {col.description}
            </div>
          ),
        });
        totalRow[col.id] = col.total;
        totalRow[`${col.id}.disabled`] = col.disabled;
        totalRow[`${col.id}.disabled.style`] = col.disabled
          ? { color: 'rgb(0, 0 , 0, 0.4)' }
          : {};
      });

      newColumns.push(
        {
          title: 'Total %',
          name: `totalPercentage`,
          columnId: `totalPercentage-TotalPercentage`,
        },
        { title: 'Total Input', name: 'total', columnId: 'total-Total' }
      );
      totalRow.total = allocation && allocation.total;
    }
    setTableColumns(newColumns);
  };

  const setRows = (allocation?: AssetAllocationType): void => {
    const { rows } = allocation || { rows: [] };
    const newRows: any[] = [];
    const accountCodes: Array<AccountCodeFilterObj> = [];
    rows.forEach((r: AssetAllocationRowType, idx: number): void => {
      const newRow: { [key: string]: any } = {
        id: `${r.assetManager}-${idx}`,
        assetManager: r.assetManager,
        assetManager1: r.assetManager,
        accountCode: r.accountCode,
        accountType: r.accountType,
        accountNumber: r.number,
        assetType: r.assetType,
        accountId: r.accountId,
        total: r.total,
        'total.notEditable': true,
        'totalPercentage.notEditable': true,
        type: r.type,
        enabled: r.enabled,
        rangePosition: r.rangePosition,
        hasSecurity: !!r.securities,
        securities: r.securities,
        details: r.securities,
        hasDetails: !!r.securities && r.securities.length > 0,
        isExpanded: !!(
          expandedRows &&
          expandedRows.find(
            (or: any): any => or.id === `${r.assetManager}-${idx}`
          )
        ),
        hidden: false,
        hiddenRow: false,
        totalAssetManagerDesired: r.totalAssetManagerDesired,
        totalAssetManagerPercentage: r.totalAssetManagerPercentage,
        totalTotalDesired: r.totalTotalDesired,
        totalTotalPercentage: r.totalTotalPercentage,
        hasSecurityWeights: r.hasSecurityWeights,
        hasTotalWithNoValue:
          r.securities &&
          r.securities.find(
            (rs: AssetAllocationRowSecurityType): any => !rs.hasSecurityWeights
          ),
        values: r.values,
        PopperDetails: (
          <Paper
            style={{
              padding: '5px',
              fontSize: '12px',
            }}
          >
            <div className={classes.popLabel}>
              <strong>Asset Manager:</strong> {r.assetManager}
            </div>
            <div className={classes.popLabel}>
              <strong>Account Code:</strong> {r.accountCode}
            </div>
            <div className={classes.popLabel}>
              <strong>Account Type:</strong> {r.accountType}
            </div>
            <div className={classes.popLabel}>
              <strong>Account Number:</strong> {r.accountNumber}
            </div>
          </Paper>
        ),
      };

      if (r.rangePosition !== 0 && !historyActive) {
        newRow.hiddenRow = true;
      }

      if (
        !accountCodes.find(
          (each: AccountCodeFilterObj): any => each.name === newRow.accountCode
        )
      ) {
        accountCodes.push({
          name: newRow.accountCode,
          selected: selectedAccountCodes.find(
            (each: AccountCodeFilterObj): any =>
              each.name === newRow.accountCode
          )
            ? selectedAccountCodes.find(
                (each: AccountCodeFilterObj): any =>
                  each.name === newRow.accountCode
              ).selected
            : true,
        });
      }

      r.values.forEach((value: AssetAllocationRowValueType): void => {
        newRow[value.refAssetClassId] = value.value;
        newRow[`${value.refAssetClassId}.hasExtraOption`] =
          value.desiredStatus === 'NEW';
        newRow[`${value.refAssetClassId}.refAssetAccountValueId`] =
          value.refAssetAccountValueId || '';
        if (
          !(
            (value.desiredWeight === 0 &&
              value.desiredStatus === 'HISTORICAL') ||
            !value.desiredWeight
          )
        ) {
          newRow[`${value.refAssetClassId}.isEditable`] = true;
          newRow[`${value.refAssetClassId}.editable`] = true;
        }
        if (value.comment) {
          newRow[`${value.refAssetClassId}.detail`] = value.comment;
          newRow[`${value.refAssetClassId}.hasDetail`] = true;
        }
        if (!value.desiredWeight) {
          newRow[`${value.refAssetClassId}.style`] = {
            backgroundColor: '#FFCCCC',
            backgroundClip: 'padding-box',
          };
        }
      });
      newRow['CASH.editable'] = true;
      if (r.extraValues && r.extraValues.length > 0) {
        newRow.hasExtraValues = true;
        newRow[`${r.extraValues[0].type}`] = r.extraValues[0].value;
      }
      if (r.rangePosition !== 0) {
        const transparency = r.rangePosition > 0 ? 0.3 : 0.6;
        newRow.style = {
          backgroundColor: `rgb(192,192,192,${transparency})`,
        };
        newRow.delayStyle = true;
      }
      newRows.push(newRow);
      if (newRow.hasSecurity && r.securities) {
        r.securities.forEach((rs: AssetAllocationRowSecurityType): void => {
          const newSecurityRow: { [key: string]: any } = {
            id: `${r.assetManager}-${idx}-${rs.name}`,
            assetManager: rs.name,
            total: rs.total,
            hidden: !(
              expandedRows &&
              expandedRows.find(
                (or: any): any => or.id === `${r.assetManager}-${idx}`
              )
            ),
            status: rs.status,
            source: newRow.assetManager1,
            sourceId: `${r.assetManager}-${idx}`,
            isSecurity: true,
            isDetail: true,
            refAccountSecurityId: rs.refAccountSecurityId,
            refAssetSecurityValueId: rs.refAssetSecurityValueId,
            refSecurityId: rs.refSecurityId,
            accountId: r.accountId,
            rowType: 'readOnly',
            hasTotalWithNoValue: !rs.hasSecurityWeights,
            style: {
              backgroundColor: 'rgb(255,255,51, 0.3)',
            },
            'total.hasExtraOption': rs.total && rs.status === 'NEW',
            values: rs.values,
            totalTotalPercentage: rs.totalTotalPercentage,
            totalAssetManagerPercentage: rs.totalAssetManagerPercentage,
          };
          if (rs.comment) {
            newSecurityRow[`total.detail`] = rs.comment;
            newSecurityRow[`total.hasDetail`] = true;
          }
          newSecurityRow[`CASH.style`] = {
            background: '#e9f8fe',
            backgroundClip: 'padding-box',
          };
          if (rs.values) {
            rs.values.forEach((value: any): void => {
              newSecurityRow[value.refAssetClassId] = value.value;
              newRow[`${value.refAssetClassId}.refAssetAccountValueId`] =
                value.refAssetAccountValueId || '';
              if (value.comment) {
                newSecurityRow[`${value.refAssetClassId}.detail`] =
                  value.comment;
                newSecurityRow[`${value.refAssetClassId}.hasDetail`] = true;
              }
            });
          }
          newRows.push(newSecurityRow);
        });
      }
    });
    accountCodes.push({
      name: 'Select All',
      selected: !accountCodes.find(
        (each: AccountCodeFilterObj): any => !each.selected
      ),
    });
    const detailColumns = tableColumns.filter(
      (col: any): any =>
        col.id === 'accountCode' ||
        col.id === 'accountNumber' ||
        col.id === 'accountType'
    );
    setSelectedAccountCodes(accountCodes);
    setTableRows(newRows);
    setColumns(detailColumns, accountCodes, allocation);
    if (readOnly)
      setTimeout(() => {
        if (getNode && cardId) getNode(containerRef, 'ASEETALLOCATION', cardId);
      }, 3000);
  };

  const setSecondRows = (allocation?: AssetAllocationType): void => {
    const { weightTable }: { weightTable: Array<any> } = allocation || {
      weightTable: [],
    };
    if (weightTable) {
      const newWeightRows: Array<any> = weightTable.map(
        (each: any, idx: number): any => {
          const newRow: { [key: string]: any } = {
            total: each.total,
            cells: each.cells,
            assetManager: each.name,
            type: each.rowType,
            id: idx,
            rowType: each.rowType === 'CurrentWeight' ? 'readOnly' : '',
            'totalPercentage.style': {
              backgroundColor:
                each.rowType !== 'CurrentWeight' &&
                each.total > 100 &&
                '#FFCCCC',
              backgroundClip: 'padding-box',
            },
          };
          const { cells } = each;
          cells.forEach((eachCell: any): void => {
            newRow[eachCell.refAssetClassId] = eachCell.value;
            newRow[`${eachCell.refAssetClassId}.isEditable`] =
              eachCell.status === 'NEW';
            newRow[`${eachCell.refAssetClassId}.editable`] =
              eachCell.status === 'NEW';
            newRow[`${eachCell.refAssetClassId}.hasExtraOption`] =
              eachCell.status === 'NEW';
          });
          return newRow;
        }
      );
      setWeightRows(newWeightRows);
    }
  };

  const setRiskTable = (allocation: AssetAllocationType): void => {
    const { riskClasses, risksTable, columns } = allocation;
    const counts: Array<any> = [];
    let count = 1;
    columns.forEach((eachColumn, idx) => {
      if (idx !== 0 && idx !== columns.length - 1) {
        if (eachColumn.riskClassId === columns[idx - 1].riskClassId) {
          count += 1;
        } else {
          counts.push({ count, riskClassId: columns[idx - 1].riskClassId });
          count = 1;
        }
      } else if (idx === columns.length - 1) {
        if (eachColumn.riskClassId === columns[idx - 1].riskClassId) {
          counts.push({
            count: count + 1,
            riskClassId: eachColumn.riskClassId,
          });
        } else {
          counts.push({ count: 1, riskClassId: eachColumn.riskClassId });
        }
      }
    });
    const newColumns: Array<any> = [
      {
        title: 'Risk Class',
        name: 'riskClass',
        type: 'header',
        alignLeftHeader: true,
        columnId: 'riskClass-Risk Class',
      },
    ];
    counts.forEach((each, idx) => {
      const width = each.count * 65 + (each.count - 1) * 8;
      const foundRiskClass = riskClasses.find(
        eachRiskClass => eachRiskClass.id === each.riskClassId
      );
      newColumns.push({
        title: foundRiskClass ? foundRiskClass.name : '',
        name: `${each.riskClassId}`,
        columnId: `${each.riskClassId}-${foundRiskClass?.name}-${idx}`,
        id: `${each.riskClassId}-${idx}`,
        style: { minWidth: width, maxWidth: width, textAlign: 'center' },
      });
    });

    newColumns.push({
      title: 'Total',
      name: `total`,
      columnId: `total-Total`,
      id: 'total',
    });
    newColumns.push({
      name: 'empty',
      title: '',
      id: 'empty',
      columnId: 'empty',
    });

    const newRiskRows = risksTable.map((each: any, idx: number): any => {
      const newRow = {
        ...each,
        riskClass: each.name,
        id: idx,
        rowType: 'readOnly',
        'riskClass.style': {
          color: 'rgb(0,0,0, 0.7)',
          fontWeight: 500,
          display: 'inherital',
        },
      };
      const { cells } = each;
      cells.forEach((eachCell: any): void => {
        const foundRiskClass = riskClasses.find(
          (eachR: any): any => eachR.id === eachCell.refRiskClassId
        );
        const color = foundRiskClass && foundRiskClass.color;
        newRow[eachCell.refRiskClassId] = eachCell.value;
        newRow[`${eachCell.refRiskClassId}.style`] = {
          backgroundColor: color,
          backgroundClip: 'padding-box',
        };
      });
      return newRow;
    });
    setRiskColumns(newColumns);
    setRiskRows(newRiskRows);
  };

  useEffect(() => {
    if (!readOnly) {
      setRows(allocationTable);
      setAllocationData(allocationTable);
      setSecondRows(allocationTable);
    }
  }, []);

  const getAllocationTable = (): void => {
    if (getAllocation)
      getAllocation(
        clientId,
        year,
        month,
        selectedRows || [],
        selectedColumns || []
      )
        .then((allocation: AssetAllocationType): void => {
          setRows(allocation);
          setAllocationData(allocation);
          setSecondRows(allocation);
          setRiskTable(allocation);
        })
        .catch(() => showNotification && showNotification());
  };

  useEffect(() => {
    getAllocationTable();
  }, [
    month,
    year,
    selectedColumns,
    selectedRows,
    clientId,
    getAllocation,
    historyActive,
    cardId,
  ]);

  const handleCheckColumn = (data: string, value: boolean): void => {
    if (data === 'all') {
      const newColumns = tableColumns.map((col: any): any => {
        if (col.canDisable) return { ...col, disabled: !value };
        return col;
      });
      const disabledColumns = newColumns
        .filter((col: any): any => col.disabled === true)
        .map((col: any): number => col.id);
      setTableColumns(newColumns);
      setFixedLiquidButton('none');
      if (setSelectedColumns) setSelectedColumns(clientId, disabledColumns);
    } else {
      const newColumns = tableColumns.map((col: any): any => {
        if (col.id === data) return { ...col, disabled: !value };
        return col;
      });
      const disabledColumns = newColumns
        .filter((col: any): any => col.disabled === true)
        .map((col: any): number => col.id);
      setTableColumns(newColumns);
      setFixedLiquidButton('none');
      if (setSelectedColumns) setSelectedColumns(clientId, disabledColumns);
    }
  };

  const handleCheckRow = (data: string, value: boolean): void => {
    if (data === 'all') {
      const newRows = tableRows.map((r: any): any => {
        if (r.accountId) return { ...r, enabled: value };
        return r;
      });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setFixedLiquidButton('none');
      if (!value && setSelectedHistory) {
        setSelectedHistory(clientId, []);
      }
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    } else {
      const newRows = tableRows.map((r: any): any => {
        if (r.id === data) return { ...r, enabled: value };
        return r;
      });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setFixedLiquidButton('none');
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    }
  };

  const formatNumber = (number: number): string => {
    const newNumber = Number(number).toFixed(2);
    if (newNumber !== 'NaN') {
      return newNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return '';
  };

  const formatCellMethod = (column: any, row: any): any => {
    const opacity = row.enabled === false || column.disabled === true ? 0.5 : 1;
    const { values } = row;
    if (column.name === 'totalPercentage') {
      if (firstStatus === 'Ass.Man.') {
        return row.totalAssetManagerPercentage
          ? `${formatNumber(row.totalAssetManagerPercentage)}%`
          : '';
      }
      return row.totalTotalPercentage
        ? `${formatNumber(row.totalTotalPercentage)}%`
        : '';
    }
    if (column.name === 'total') {
      if (firstStatus === 'Ass.Man.') {
        return row.totalAssetManagerDesired
          ? `${formatNumber(row.totalAssetManagerDesired)}%`
          : '';
      }
      return row.totalTotalDesired
        ? `${formatNumber(row.totalTotalDesired)}%`
        : '';
    }
    const foundValue = values.find(
      (each: any): any => each.refAssetClassId === column.id
    );
    if (!foundValue) return '';
    if (firstStatus === 'Ass.Man.') {
      if (secondStatus === 'Actual') {
        return (
          <span
            style={{
              fontWeight: foundValue.status === 'NEW' ? 800 : 'inherit',
              opacity,
              color:
                foundValue.assetManagerWeight < 0 ? 'rgb(255, 0,0)' : 'inherit',
            }}
          >
            {`${formatNumber(foundValue.assetManagerWeight)}%`}
          </span>
        );
      }
      if (!foundValue.desiredWeight) {
        return '';
      }
      return (
        <span
          style={{
            fontWeight: foundValue.desiredStatus === 'NEW' ? 800 : 'inherit',
            color: foundValue.desiredWeight < 0 ? 'rgb(255, 0,0)' : 'inherit',
            opacity,
          }}
        >
          {`${formatNumber(foundValue.desiredWeight)}%`}
        </span>
      );
    }

    if (secondStatus === 'Actual') {
      if (!foundValue.totalWeight) {
        return '';
      }
      return (
        <span
          style={{
            fontWeight: foundValue.status === 'NEW' ? 800 : 'inherit',
            opacity,
          }}
        >{`${formatNumber(foundValue.totalWeight)}%`}</span>
      );
    }
    if (!foundValue.desiredTotalWeight) {
      return '';
    }
    return (
      <span
        style={{
          fontWeight: foundValue.desiredStatus === 'NEW' ? 800 : 'inherit',
          color:
            foundValue.desiredTotalWeight < 0 ? 'rgb(255, 0,0)' : 'inherit',
          opacity,
        }}
      >{`${formatNumber(foundValue.desiredTotalWeight)}%`}</span>
    );
  };

  const getCellValue = (column: any, row: any): string => {
    const { values } = row;
    const foundValue = values.find(
      (each: any): any => each.refAssetClassId === column.id
    );
    if (!foundValue) return '';

    return formatNumber(foundValue.desiredWeight);
  };

  const TableStatusComponent = (
    <div style={{ maxWidth: '68px', display: 'flex' }}>
      <TextField
        id="outlined-select-status"
        select
        value={firstStatus}
        onChange={(e: any): void => {
          if (setAllocationFirstStatus)
            setAllocationFirstStatus(clientId, e.target.value);
        }}
        variant="outlined"
        InputProps={{
          classes: {
            root: classes.selectRoot,
            input: classes.input,
            notchedOutline: classes.borderClass,
          },
        }}
        SelectProps={{
          classes: {
            iconOutlined: classes.dropIcon,
          },
        }}
      >
        {tableStatuses.map(
          (option: string): ReactElement => (
            <MenuItem
              key={option}
              value={option}
              style={{
                fontSize: '12px',
                height: '14px',
                padding: '2px 4px',
                color: '#039be5',
              }}
            >
              {option}
            </MenuItem>
          )
        )}
      </TextField>
    </div>
  );

  const SecondTableStatusComponent = (
    <div style={{ maxWidth: '68px', display: 'flex' }}>
      <TextField
        id="outlined-select-second-status"
        select
        value={secondStatus}
        onChange={(e: any): void => {
          if (setAllocationSecondStatus)
            setAllocationSecondStatus(clientId, e.target.value);
        }}
        variant="outlined"
        InputProps={{
          classes: {
            root: classes.selectRoot,
            input: classes.input2,
            notchedOutline: classes.borderClass,
          },
        }}
        SelectProps={{
          classes: {
            iconOutlined: classes.dropIcon,
          },
        }}
      >
        {secondTableStatuses.map(
          (option: string): ReactElement => (
            <MenuItem
              key={option}
              value={option}
              style={{
                fontSize: '12px',
                height: '14px',
                padding: '2px 4px',
                color: '#039be5',
              }}
            >
              {option}
            </MenuItem>
          )
        )}
      </TextField>
    </div>
  );

  const handleLiquidAccounts = (): void => {
    if (fixedLiquidButton === 'none') {
      const newRows = tableRows.map((r: any): any => {
        if (r.assetType !== 'LIQUID') return { ...r, enabled: false };
        if (r.assetType === 'LIQUID') {
          if (
            selectedAccountCodes.find(
              (each: AccountCodeFilterObj): any => each.name === r.accountCode
            ) &&
            selectedAccountCodes.find(
              (each: AccountCodeFilterObj): any => each.name === r.accountCode
            ).selected
          ) {
            return { ...r, enabled: true };
          }
          return { ...r, enabled: false };
        }
        return r;
      });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setFixedLiquidButton('liquid');
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    } else if (fixedLiquidButton === 'liquid') {
      const newRows = tableRows.map((r: any): any => {
        if (r.assetType !== 'FIXED') return { ...r, enabled: false };
        if (r.assetType === 'FIXED') {
          if (
            selectedAccountCodes.find(
              (each: any): any => each.name === r.accountCode
            ) &&
            selectedAccountCodes.find(
              (each: any): any => each.name === r.accountCode
            ).selected
          ) {
            return { ...r, enabled: true };
          }
          return { ...r, enabled: false };
        }
        return r;
      });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setFixedLiquidButton('fixed');
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    } else {
      const newRows = tableRows.map((r: any): any => {
        if (
          selectedAccountCodes.find(
            (each: any): any => each.name === r.accountCode
          ) &&
          selectedAccountCodes.find(
            (each: any): any => each.name === r.accountCode
          ).selected
        ) {
          return { ...r, enabled: true };
        }
        return { ...r, enabled: false };
      });
      const enabledRows = newRows
        .filter((r: any): any => r.enabled === true)
        .map((r: any): number => r.accountId);
      setTableRows(newRows);
      setFixedLiquidButton('none');
      if (setSelectedRows) setSelectedRows(clientId, enabledRows);
    }
  };

  const expand = (event: any, row: any): void => {
    const newRows = tableRows.map((r: any): any => {
      if (r.isSecurity && r.sourceId === row.id) {
        return { ...r, hidden: false };
      }
      if (r.id === row.id) {
        return { ...r, isExpanded: true };
      }
      return r;
    });
    setTableRows(newRows);
    if (setExpandedRows)
      setExpandedRows(
        clientId,
        newRows.filter((each: any): any => each.isExpanded === true)
      );
  };

  const unexpand = (event: any, row: any): void => {
    const newRows = tableRows.map((r: any): any => {
      if (r.isSecurity && r.sourceId === row.id) {
        return { ...r, hidden: true };
      }
      if (r.id === row.id) {
        return { ...r, isExpanded: false };
      }
      return r;
    });
    setTableRows(newRows);
    if (setExpandedRows)
      setExpandedRows(
        clientId,
        newRows.filter((each: any): any => each.isExpanded === true)
      );
  };

  const resolve = (path: string, obj: any): any =>
    path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj);

  const formatRiskCellMethod = (column: any, row: any): string => {
    const value = formatNumber(resolve(column.name, row));
    if (resolve(column.name, row) === 0 || resolve(column.name, row) === null)
      return '';
    if (column.type === 'header' || column.name === 'empty') return value;
    return `${value}%`;
  };

  const formatWeightCellMethod = (column: any, row: any): any => {
    if (column.name === 'totalPercentage') {
      return `${formatNumber(row.total)}%`;
    }
    const { cells } = row;
    const foundValue = cells.find(
      (each: any): any => each.refAssetClassId === Number(column.name)
    );
    if (!foundValue || foundValue.value === 0 || foundValue.value === null)
      return '';

    return (
      <span
        style={{ fontWeight: foundValue.status === 'NEW' ? 800 : 'inherit' }}
      >
        {`${formatNumber(foundValue.value)}%`}
      </span>
    );
  };

  const submit = (row: any, name: string, value: string): void => {
    if (role === 'VIEWER') return;
    const { values } = row;
    const foundValue = values.find(
      (each: any): any => each.refAssetClassId === Number(name)
    );
    const isNew = !foundValue || foundValue.desiredStatus === 'HISTORICAL';
    const data = {
      year,
      month,
      value: Number(value),
      refAssetClassId: Number(name),
      refAccountId: row.accountId,
    };
    if (isNew && createAssetAllocationValue) {
      createAssetAllocationValue(data).then(() => getAllocationTable());
    } else if (updateAssetAllocationValue)
      updateAssetAllocationValue(data).then(() => getAllocationTable());
  };

  const submitWeight = (row: any, name: string, value: string): void => {
    if (role === 'VIEWER') return;
    const { cells } = row;
    const foundValue = cells.find(
      (each: any): any => each.refAssetClassId === Number(name)
    );
    const isNew = !foundValue || foundValue.status !== 'NEW';
    const data = {
      year,
      month,
      value: Number(value),
      refAssetClassId: Number(name),
      type: row.type,
      refClientId: clientId,
    };
    if (isNew && createAllocationWeightValue) {
      createAllocationWeightValue(data).then(() => getAllocationTable());
    } else if (updateAllocationWeightValue)
      updateAllocationWeightValue(data).then(() => getAllocationTable());
  };

  const handleHistoryButton = (): void => {
    if (setActiveHistory) setActiveHistory(clientId, !historyActive);
  };

  const toggleAccountDetails = (): void => {
    const accountCodeColumn = tableColumns.find(
      (col: any): any => col.name === 'accountCode'
    );
    const accountNumberColumn = tableColumns.find(
      (col: any): any => col.name === 'accountNumber'
    );
    const accountTypeColumn = tableColumns.find(
      (col: any): any => col.name === 'accountType'
    );
    const hidden =
      accountCodeColumn.hidden &&
      accountNumberColumn.hidden &&
      accountTypeColumn.hidden;
    const newColumns = tableColumns.map((col: any): any => {
      if (
        col.name === 'accountCode' ||
        col.name === 'accountNumber' ||
        col.name === 'accountType'
      ) {
        return { ...col, hidden: !hidden };
      }
      return col;
    });
    setTableColumns(newColumns);
  };

  const deleteAllocationValue = (event: any, row: any, col: any): void => {
    const { values } = row;
    const foundValue = values.find(
      (each: any): any => each.refAssetClassId === Number(col.name)
    );
    const data = {
      year,
      month,
      value: Number(foundValue.desiredWeight),
      refAssetClassId: Number(col.name),
      refAccountId: row.accountId,
    };

    if (removeAssetAllocationValue)
      removeAssetAllocationValue(data).then(() => getAllocationTable());
  };

  const deleteWeightValue = (event: any, row: any, col: any): void => {
    const { cells } = row;
    const foundValue = cells.find(
      (each: any): any => each.refAssetClassId === Number(col.name)
    );
    const data = {
      year,
      month,
      value: Number(foundValue.value),
      refAssetClassId: Number(col.name),
      type: row.type,
      refClientId: clientId,
    };

    if (removeAllocationWeightValue)
      removeAllocationWeightValue(data).then(() => getAllocationTable());
  };

  let statusIcon = None;
  if (fixedLiquidButton === 'liquid') statusIcon = Liquid;
  if (fixedLiquidButton === 'fixed') statusIcon = Fixed;

  let secondStatusIcon = History;
  if (!historyActive) secondStatusIcon = HistoryDisabled;

  return (
    <div className={classnames(classes.root1, root1Class)}>
      <div
        className={classnames(classes.root, rootClass)}
        ref={readOnly ? containerRef : parentReference}
      >
        <div className={classes.topElement} />
        <TableValues
          hasColumnCheckbox
          hasRowCheckbox
          moveBetweenCells
          onHoverColumnHeading
          hasColumnSelection
          hasChangeTableStatusButton
          selectOnDoubleClick={
            firstStatus === 'Ass.Man.' && secondStatus === 'Input'
          }
          showPopperForHeaderCell
          selectOnEnter={firstStatus === 'Ass.Man.' && secondStatus === 'Input'}
          hasExpandColumns
          onExpandColumns={toggleAccountDetails}
          expandColumnsIcon={None}
          expandColumnsTitle="View Account Code-Type-Number"
          handleCheckRows={handleCheckRow}
          handleCheckColumns={handleCheckColumn}
          onChangeTableStatus={handleLiquidAccounts}
          changeTableStatusIcon={statusIcon}
          changeStatusTableTitle="Select All-Liquid-Fixed"
          hasSecondChangeTableStatusButton
          onSecondChangeTableStatus={handleHistoryButton}
          secondChangeTableStatusIcon={secondStatusIcon}
          secondChangeStatusTableTitle="Show Historic / Future AM"
          columns={tableColumns}
          rows={tableRows}
          checkboxBlankInputIcon={CheckMark}
          checkedAllIcon={CheckedAll}
          checkedIcon={CheckedMark}
          tableStatusComponent={TableStatusComponent}
          secondTableStatusComponent={SecondTableStatusComponent}
          getCellValue={getCellValue}
          formatCellMethod={formatCellMethod}
          onShrink={unexpand}
          onExpand={expand}
          onSubmit={submit}
          showEditOnHover={
            firstStatus === 'Ass.Man.' && secondStatus === 'Input'
          }
          editOptions={[]}
          extraOption={{
            icon: (
              <RestartIcon
                style={{
                  fontSize: '14px',
                  color: '#1976d2',
                }}
              />
            ),
            onClick: (event: any, row: any, col: any): void =>
              deleteAllocationValue(event, row, col),
            text: 'Reset',
          }}
        />

        <TableValues
          columns={tableColumns}
          rows={weightRows}
          showHeader={false}
          placeCellBeforeRow
          selectOnDoubleClick
          moveBetweenCells
          onSubmit={submitWeight}
          formatCellMethod={formatWeightCellMethod}
          selectOnEnter
          showEditOnHover
          editOptions={[]}
          extraOption={{
            icon: (
              <RestartIcon
                style={{
                  fontSize: '14px',
                  color: '#1976d2',
                }}
              />
            ),
            onClick: (event: any, row: any, col: any): void =>
              deleteWeightValue(event, row, col),
            text: 'Reset',
          }}
        />

        <TableValues
          columns={riskColumns}
          rows={riskRows}
          placeCellBeforeRow
          formatCellMethod={formatRiskCellMethod}
        />
      </div>
    </div>
  );
};

export default Allocation;
