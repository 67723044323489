import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    appBar: {
      position: 'relative',
      minHeight: '48px',
      background: theme.palette.primary.main,
    },
    paper: {
      width: 620,

      borderRadius: theme.spacing(2),
      position: 'relative',
    },
    content: {
      display: 'flex',
      background: theme.palette.common.white,
      height: '100%',
      overflow: 'auto',
    },
    form: {
      padding: 10,
    },
    topPadding: {
      padding: '24px 0px 0px 0px',
    },
    none: {
      padding: 0,
    },
    full: {
      padding: 24,
    },
    contentContainer: {
      display: 'flex',
      flexFlow: 'column',
      overflow: 'hidden',
      flex: 1,
      height: '100%',
    },
    title: {
      height: 40,
      padding: `${theme.spacing(2)}px ${theme.spacing(8.75)}px ${theme.spacing(
        1.5
      )}px`,
    },
    backdrop: {},
    icon: {
      marginLeft: theme.spacing(1),
    },
    actionContainer: {
      margin: '8px 30px 8px 27px',
      padding: 0,
      justifyContent: 'space-between',
    },
    actionBar: {
      justifyContent: 'flex-end',
    },
    extraAction: {
      justifyContent: 'flex-start',
    },
    primaryActionButton: {
      borderRadius: theme.spacing(2.5),
      minWidth: '120px',
      fontSize: '12px',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      margin: `0px ${theme.spacing(1)}px`,
      boxShadow: '0px 0px 1px 0px #181818',
      fontWeight: 'bold',
    },
    actionButton: {
      borderRadius: theme.spacing(2.5),
      minWidth: '120px',
      fontSize: '12px',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      margin: `0px ${theme.spacing(1)}px`,
      fontWeight: 'bold',
    },
    titleText: {
      lineHeight: '50px',
      fontSize: '20px',
      fontWeight: 600,
    },
  });

export default styles;
