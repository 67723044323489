import * as types from '../constants/Report';

const Report = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.REPORTS_CLIENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPORTS_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: action.reports,
      };
    case types.REPORTS_CLIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.REPORT_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPORT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.report.reference,
      };
    case types.REPORT_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.REPORT_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.report.reference,
      };
    case types.REPORT_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.REPORT_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPORT_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        report: action.report,
      };
    case types.REPORT_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.REPORT_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPORT_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        report: action.report,
        reports: state.reports.filter((r: any): any => r.id !== action.id),
      };
    case types.REPORT_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    case types.REPORT_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPORT_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        reportTemplates: action.reportTemplates,
      };
    case types.REPORT_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.REPORT_TEMPLATE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPORT_TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.reportTemplate.reference,
      };
    case types.REPORT_TEMPLATE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.REPORT_TEMPLATE_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPORT_TEMPLATE_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        reportTemplate: action.reportTemplate,
        reportTemplates: state.reportTemplates.filter(
          (rt: any): any => rt.id !== action.id
        ),
      };
    case types.REPORT_TEMPLATE_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    case types.REPORT_TEMPLATE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPORT_TEMPLATE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.report.reference,
      };
    case types.REPORT_TEMPLATE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.REPORT_SETTING_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPORT_SETTING_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.reportingSettings.reference,
      };
    case types.REPORT_SETTING_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.REPORT_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPORT_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        reportingSettings: action.reportingSettings,
      };
    case types.REPORT_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default Report;
