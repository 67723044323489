import createStyles from '@material-ui/core/styles/createStyles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = (theme: any): any =>
  createStyles({
    tabs: {
      float: 'right',
    },
    container: {
      display: 'flex',
      width: '100%',
    },
    tab: {},
    label: {},
    accountButton: {
      height: '48px',
      marginTop: '8px',
      width: '48px',
    },
    appBar: {
      background: 'linear-gradient(to right, #039be5, #1976d2)',
      height: 64,
    },
    component: {
      width: '60px',
    },
    appTitle: {
      fontSize: 20,
      width: 200,
      color: 'white',
    },
    menuButton: {
      marginLeft: -12,
      width: 60,
      height: 60,
      color: theme.palette.common.white,
    },
    appLogo: {
      margin: '9px 0 0 -2px',
      width: 200,
      cursor: 'pointer',
    },
    search: {
      borderRadius: 5,
      width: 500,
      margin: '12px 20px',
      height: 35,
      position: 'relative',
      backgroundColor: fade('#FFF', 0.15),
      '&:hover': {
        backgroundColor: fade('#FFF', 0.25),
      },
    },
    searchIcon: {
      position: 'absolute',
      top: 7,
      left: 7,
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      lineHeight: '25px',
      paddingLeft: 40,
      width: '100%',
    },
    formRoot: {
      margin: 0,
      fontSize: '1rem',
      marginRight: 30,
    },
    formRoot2: {
      margin: 0,
      fontSize: '1rem',
    },
    rightContainer: {
      position: 'absolute',
      right: 15,
      display: 'flex',
    },
    profileData: {
      color: theme.palette.common.white,
      fontWeight: 600,
      marginTop: 22,
      marginRight: 5,
    },
    avatar: { marginBottom: 2 },
    iconButtonLabel: {
      height: '24px',
    },
  });

export default styles;
