import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const list = (): any =>
  Request('security', 'GET')
    .then(ErrorHandler)
    .then((securities: any): any => securities);

const item = (id: number): any =>
  Request(`security/${id}`, 'GET')
    .then(ErrorHandler)
    .then((security: any): any => security);

const values = (id: number, year: number, month: number): any =>
  Request(`security/${id}/values`, 'GET', null, {
    year,
    month,
  })
    .then(ErrorHandler)
    .then((securityValues: any): any => securityValues);

const saveValues = (data: any): any =>
  Request('security/values', 'POST', data)
    .then(ErrorHandler)
    .then((securityValues: any): any => securityValues);

const remove = (id: number): any =>
  Request(`security/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((security: any): any => security);

const create = (data: any): any =>
  Request('security', 'POST', data)
    .then(ErrorHandler)
    .then((security: any): any => security);

const update = (data: any, id:number): any =>
  Request(`security/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((security: any): any => security);

const getSecurityStartDate = (id: number): any =>
  Request(`security/${id}/startdate`, 'GET')
    .then(ErrorHandler)
    .then((date: any): any => date);

const SecurityService = {
  list,
  item,
  values,
  create,
  update,
  remove,
  saveValues,
  getSecurityStartDate,
};

export default SecurityService;
