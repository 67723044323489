import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const list = (assetClassSetId: number): any =>
  Request('riskclass', 'GET', null, { assetclasssetid: assetClassSetId })
    .then(ErrorHandler)
    .then((riskClasses: any): any => riskClasses);

const item = (id: number): any =>
  Request(`riskclass/${id}`, 'GET')
    .then(ErrorHandler)
    .then((riskClass: any): any => riskClass);

const remove = (id: number): any =>
  Request(`riskclass/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((riskClass: any): any => riskClass);

const create = (data: any): any =>
  Request('riskclass', 'POST', data)
    .then(ErrorHandler)
    .then((riskClass: any): any => riskClass);

const update = (data: any, id:number): any =>
  Request(`riskclass/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((riskClass: any): any => riskClass);

const RiskClassService = {
  list,
  create,
  update,
  item,
  remove,
};

export default RiskClassService;
