import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    formContainer: {
      width: '100%',
      // height: '100%',
      margin: '10px 30px',
    },
    halfField: {
      width: '48%',
      margin: '5px 5px',
    },
    fieldWithIconContainer: {
      display: 'inline-block',
      width: '48%',
      margin: '5px 5px',
    },
    fullField: { margin: '5px 5px', width: '98%' },
    fieldWithIcon: {
      marginTop: '0px',
      marginLeft: '16px',
      width: 'calc(100% - 40px)',
    },
    icon: {
      marginTop: '5px',
      fontSize: 24,
      opacity: 0.7,
    },
    dropDown: {
      width: '48%',
      marginRight: '5px',
      marginTop: 0,
    },
    label: {
      margin: '5px 0px',
      fontWeight: 600,
      fontSize: 14,
      opacity: 0.8,
    },
    errorText: {
      color: theme.palette.common.red,
    },
    progress: {
      margin: 'auto',
      width: '100%',
      position: 'absolute',
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      minHeight: 100,
    },
    text: {
      margin: '35px',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    title: {
      display: 'inline-block',
      paddingTop: '2px',
    },
    tableCount: {
      height: 26,
      minWidth: 26,
      textAlign: 'center',
      borderRadius: '50%',
      lineHeight: '28px',
      fontSize: 13,
      marginLeft: 12,
      marginTop: 4,
      color: '#1976d2',
      backgroundColor: '#0000000d',
      display: 'inline-block',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    toolbar: {
      justifyContent: 'space-between',
      marginTop: '30px',
      marginBottom: '20px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    addButton: {},
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
      backgroundColor: '#eff0f1',
    },
    invisRoot: {
      minWidth: '20px',
      maxWidth: '20px',
      width: '20px',
    },
    tab: {
      display: 'flex',
    },
    info: {
      color: 'rgb(0,0,0,0.5)',
      marginLeft: 5,
      fontSize: '10px',
      fontWeight: 350,
    },
    duplicateDateContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '16px 0px',
    },
  });

export default styles;
