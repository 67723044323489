import RiskClassService from '../services/RiskClassService';
import * as types from '../constants/RiskClass';
import { RiskClassType } from '../Types';

const getRiskClassesList = (assetClassSetId: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.RISK_CLASSES_LIST_REQUEST });
  RiskClassService.list(assetClassSetId)
    .then((riskClasses: { content: Array<RiskClassType> }): void => {
      dispatch({ type: types.RISK_CLASSES_LIST_SUCCESS, riskClasses });
    })
    .catch((error: any): void => {
      dispatch({ type: types.RISK_CLASSES_LIST_FAILURE, error });
    });
};

const getRiskClassItem = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.RISK_CLASS_ITEM_REQUEST });
  RiskClassService.item(id)
    .then((riskClass: RiskClassType): void => {
      dispatch({ type: types.RISK_CLASS_ITEM_SUCCESS, riskClass });
    })
    .catch((error: any): void => {
      dispatch({ type: types.RISK_CLASS_ITEM_FAILURE, error });
    });
};

const createRiskClass = (data: any, id: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.RISK_CLASS_CREATE_REQUEST });
  RiskClassService.create(data)
    .then((riskClass: any): void => {
      dispatch({ type: types.RISK_CLASS_CREATE_SUCCESS, riskClass });
      dispatch(getRiskClassesList(id));
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.RISK_CLASS_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.RISK_CLASS_CREATE_FAILURE, error });
      }
    });
};

const updateRiskClass = (data: any, id: number, dataId: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.RISK_CLASS_UPDATE_REQUEST });
  RiskClassService.update(data, dataId)
    .then((riskClass: any): void => {
      dispatch({ type: types.RISK_CLASS_UPDATE_SUCCESS, riskClass });
      dispatch(getRiskClassesList(id));
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.RISK_CLASS_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.RISK_CLASS_UPDATE_FAILURE, error });
      }
    });
};

const removeRiskClass = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.RISK_CLASS_REMOVE_REQUEST });
  RiskClassService.remove(id)
    .then((riskClass: any): void => {
      dispatch({ type: types.RISK_CLASS_REMOVE_SUCCESS, riskClass, id });
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.RISK_CLASS_REMOVE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.RISK_CLASS_REMOVE_FAILURE, error });
      }
    });
};

const RiskClassActions = {
  getRiskClassesList,
  getRiskClassItem,
  createRiskClass,
  updateRiskClass,
  removeRiskClass,
};

export default RiskClassActions;
