import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import throttle from './throttle';
import RootReducer from '../reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose; //eslint-disable-line
  }
}

const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === `development`,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, RootReducer);
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const Store = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(thunkMiddleware, loggerMiddleware, throttle))
);

export default Store;
