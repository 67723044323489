import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize, fade } from '@material-ui/core/styles/colorManipulator';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        width: 150,
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: 250,
      },
      [theme.breakpoints.between('md', 'lg')]: {
        width: 400,
      },
      [theme.breakpoints.up('lg')]: {
        width: 500,
      },
      height: 35,
      marginTop: 13,
      marginLeft: 10,
    },
    input: {
      display: 'flex',
      borderRadius: 5,
      [theme.breakpoints.down('sm')]: {
        width: 150,
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: 250,
      },
      [theme.breakpoints.between('md', 'lg')]: {
        width: 400,
      },
      [theme.breakpoints.up('lg')]: {
        width: 500,
      },
      height: 20,
      color: 'white',
      position: 'relative',
      backgroundColor: fade('#FFF', 0.15),
      '&:hover': {
        backgroundColor: fade('#FFF', 0.25),
      },
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: `${theme.spacing(1 / 2)}px ${theme.spacing(1 / 4)}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      ),
    },
    noOptionsMessage: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
      fontSize: 16,
      color: 'white',
      paddingLeft: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 16,
      top: 6,
      fontSize: 15,
      color: '#ffffff8f',
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      right: 0,
    },
    option: {
      height: 38,
      lineHeight: '38px',
      fontSize: '15px',
      padding: '0 14px',
    },
    optionText: {
      borderBottom: '1px solid #0000001f',
      padding: '0 2px',
      width: '100%',
      height: '100%',
    },
    divider: {
      height: theme.spacing(2),
    },
    test: {
      marginRight: '-16px',
    },
  });

const useStyles = makeStyles<typeof styles>(styles);

const NoOptionsMessage = (props: any): ReactElement => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

const inputComponent = ({ inputRef, ...props }: any): ReactElement => {
  return <div ref={inputRef} {...props} />;
};

const Control = (props: any): ReactElement => {
  const { selectProps, innerRef, children, innerProps } = props;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        disableUnderline: true,
        inputProps: {
          className: selectProps.classes.input,
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...selectProps.textFieldProps}
    />
  );
};

const Option = (props: any): ReactElement => {
  const {
    innerRef,
    isFocused,
    selectProps,
    isSelected,
    innerProps,
    children,
  } = props;
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      className={selectProps.classes.option}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      <div className={selectProps.classes.optionText}> {children} </div>
    </MenuItem>
  );
};

const Placeholder = (props: any): ReactElement => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

const SingleValue = (props: any): ReactElement => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      className={classNames(
        selectProps.classes.singleValue,
        selectProps.classes.test
      )}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

const ValueContainer = (props: any): ReactElement => {
  const { selectProps, children } = props;
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
};

const MultiValue = (props: any): ReactElement => {
  const { children, selectProps, isFocused, removeProps } = props;
  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: isFocused,
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  );
};

const Menu = (props: any): ReactElement => {
  const { selectProps, innerProps, children } = props;
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

interface Props {
  onSelect: any;
  onMenuOpen: any;
  suggestions: any;
  selected: any;
  customFields: any;
  placeholder: any;
  propsClasses?: any;
}

const IntegrationReactSelect = (props: Props): ReactElement => {
  const {
    customFields,
    suggestions,
    onSelect,
    selected: passedValue,
    onMenuOpen,
    placeholder,
    propsClasses,
  } = props;
  const [single, setSingle] = useState<any>(null);
  const classes = useStyles();

  const selectStyles = {
    input: (base: any): any => ({
      ...base,
      color: 'white',
      paddingLeft: 14,
      '& input': {
        font: 'inherit',
      },
    }),
    clearIndicator: (base: any): any => ({
      ...base,
      cursor: 'pointer',
      color: 'white',
      '&:hover': {
        color: '#ffffffb3',
      },
    }),
  };

  const newClasses = { ...classes, ...propsClasses };

  return (
    <div className={classes.root}>
      <Select
        classes={newClasses}
        arrowRenderer={null}
        styles={selectStyles}
        options={suggestions}
        onMenuOpen={onMenuOpen}
        components={{
          DropdownIndicator: (): null => null,
          IndicatorSeparator: (): null => null,
          ...components,
          ...customFields,
        }}
        value={passedValue || single}
        onChange={(selected: any): void => {
          setSingle(selected);
          onSelect(selected ? selected.value : null);
        }}
        placeholder={placeholder}
        isClearable
      />
    </div>
  );
};

IntegrationReactSelect.defaultProps = {
  customFields: (): void => {},
  selected: null,
  suggestions: [],
  onMenuOpen: (): void => {},
  placeholder: 'Search for a client ...',
};

export default IntegrationReactSelect;
