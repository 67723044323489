import * as types from '../constants/User';
import version from '../constants/Config';

const User = (state: any = { loggedIn: false }, action: any): any => {
  switch (action.type) {
    case types.USER_FORM_ACTION_REQUEST:
      return {
        ...state,
        data: action.data,
      };
    case types.USER_FORM_ACTION_SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    case types.USER_FORM_ACTION_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case types.USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.profileData,
      };
    case types.USER_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case types.USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        sessionEnded: false,
        version,
      };
    case types.USER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        loggedIn: false,
      };
    case types.USER_ROLES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.USER_ROLES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.roles,
      };
    case types.USER_ROLES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users,
      };
    case types.USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.USER_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.USER_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
      };
    case types.USER_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.USER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.USER_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.USER_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.USER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.USER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.USER_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.USER_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.USER_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        reference: 'deleted',
        users: {
          ...state.users,
          content: state.users.content.filter(
            (u: any): any => u.id !== action.id
          ),
        },
      };
    case types.USER_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    case types.REMOVE_CLIENT_CACHE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_CLIENT_CACHE_SUCCESS:
      return {
        ...state,
        loading: false,
        removed: true,
      };
    case types.REMOVE_CLIENT_CACHE_FAILURE:
      return {
        ...state,
        loading: false,
        removed: false,
        error: action.error,
      };
    case types.REMOVE_ALL_CLIENTS_CACHE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_ALL_CLIENTS_CACHE_SUCCESS:
      return {
        ...state,
        loading: false,
        removed: true,
      };
    case types.REMOVE_ALL_CLIENTS_CACHE_FAILURE:
      return {
        ...state,
        loading: false,
        removed: false,
        error: action.error,
      };
    case types.USER_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.USER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.USER_PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.USER_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.USER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.USER_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.USER_OPEN_FORM:
      return {
        ...state,
        openForm: true,
      };
    case types.USER_CLOSE_FORM:
      return {
        ...state,
        openForm: false,
      };
    case types.USER_OPEN_REPORT_SETTINGS:
      return {
        ...state,
        openReportSettings: true,
      };
    case types.USER_CLOSE_REPORT_SETTINGS:
      return {
        ...state,
        openReportSettings: false,
      };
    case types.USER_OPEN_CONFIRM_DIALOG:
      return {
        ...state,
        openConfirmDialog: true,
      };
    case types.USER_CLOSE_CONFIRM_DIALOG:
      return {
        ...state,
        openConfirmDialog: false,
      };
    case types.REPORT_TOGGLE:
      return {
        ...state,
        enableReport: !state.enableReport,
      };
    case types.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        sessionEnded: action.sessionEnded,
        openForm: false,
      };
    default:
      return state;
  }
};

export default User;
