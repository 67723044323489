import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    formContainer: {
      width: '100%',
      height: '100%',
      margin: '10px 30px',
    },
    halfField: {
      width: '48%',
      margin: '5px 5px',
    },
    fieldWithIconContainer: {
      display: 'inline-block',
      width: '98%',
      margin: '5px 5px',
    },
    fullField: { margin: '5px 5px', width: '98%' },
    fieldWithIcon: {
      marginTop: '0px',
      marginLeft: '16px',
      width: 'calc(100% - 40px)',
    },
    icon: {
      marginTop: '5px',
      fontSize: 24,
      opacity: 0.7,
    },
    dropDown: {
      width: '48%',
      marginRight: '5px',
      marginTop: 0,
    },
    label: {
      margin: '5px 0px',
      fontWeight: 600,
      fontSize: 14,
      opacity: 0.8,
    },
    errorText: {
      color: theme.palette.common.red,
    },
    progress: {
      margin: 'auto',
      width: '100%',
      position: 'absolute',
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      minHeight: 100,
    },
    text: {
      margin: '35px',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    title: {
      display: 'inline-block',
      paddingTop: '2px',
    },
    tableCount: {
      height: 26,
      minWidth: 26,
      textAlign: 'center',
      borderRadius: '50%',
      lineHeight: '28px',
      fontSize: 13,
      marginLeft: 12,
      marginTop: 4,
      color: '#1976d2',
      backgroundColor: '#0000000d',
      display: 'inline-block',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    toolbar: {
      justifyContent: 'space-between',
      marginTop: '30px',
      marginBottom: '20px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    addButton: {},
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
    },
    invisRoot: {
      minWidth: '20px',
      maxWidth: '20px',
      width: '20px',
    },
    info: {
      color: 'rgb(0,0,0,0.5)',
      marginLeft: 5,
      fontSize: '10px',
      fontWeight: 350,
    },
    userformContainer: {
      width: '100%',
      height: 'calc(100% - 10px)',
      marginLeft: '30px',
      paddingTop: '10px',
      display: 'flex',
      overflowY: 'hidden',
    },
    userFormDialogPaper: {
      height: 600,
    },
    tabs: {
      width: '150px',
      borderRight: '1px solid rgb(0,0,0, 0.5)',
      paddingRight: '10px',
    },
    tab: {
      minWidth: 120,

      color: 'rgb(0,0,0,0.7)',
    },
    tabsValue: {
      minHeight: '100%',
      height: 'unset',
      overflowX: 'auto',
      marginRight: '20px',
      overflowY: 'hidden',
    },
    invisibleIndicator: { backgroundColor: 'white' },
    tabsPadding: { paddingTop: 4 },
    selectedTab: {
      backgroundColor: 'white',
      color: '#039be5 !important',
    },
    tabLabelContainer: {
      padding: '6px 30px',
    },
    flexContainer: {
      flexDirection: 'column',
    },
    person: {
      color: theme.palette.common.white,
      width: '70%',
      height: '70%',
    },
    icon2: {
      fontSize: 45,
      color: theme.palette.text.primary,
      display: 'flex',
      justifyContent: 'center',
    },
    rootButton: {
      padding: 0,
      height: 100,
      width: 100,
    },
    button: {
      display: 'flex',
      margin: 'auto',
    },
    removeRootButton: {
      padding: 0,
    },
    removeButton: {
      marginLeft: '-22px',
    },
    noImage: {
      border: `3px 'solid' ${theme.palette.common.white}`,
    },
    iconLabel: {
      height: '100px',
    },
    photoName: { marginTop: 2, width: '70%' },
    selectButton: { marginRight: 5 },
    profileInfo: { margin: '20px 10px' },
    avatar: { width: 200, height: 200 },
    link: {
      '&:hover': { cursor: 'pointer', backgroundColor: '#d6d6d6' },
    },
    labelCheckbox: {
      marginTop: 3,
      color: 'rgb(0,0,0,0.47)',
      marginLeft: '-8px',
      fontSize: '13px',
      fontWeight: 400,
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    checkbox: {
      marginLeft: '-9px',
      marginTop: '-9px',
    },
    clientName: {
      margin: '0 0 0 20px',
      height: 35,
      width: '85%',
      padding: '15px 0 5px 20px',
      borderBottom: '1px solid #d6d6d6',
    },
    checkboxroot: { color: 'rgb(0,0,0,0.23)' },
    icon3: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: '10px',
      marginBottom: '4px',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    success: {
      backgroundColor: theme.palette.common.green,
    },
    error: {
      backgroundColor: theme.palette.common.red,
    },
    dailogContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      minHeight: 100,
    },
  });

export default styles;
