import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: '8px 20px',
    },
    table: {},
    row: {
      height: 25,
    },
    AddbuttonContainer: {
      justifyContent: 'flex-end',
    },
    addButton: {
      minWidth: '60px',
      padding: '0px 5px',
      fontSize: '10px',
    },
    column: {},
    deleteIcon: {
      color: theme.palette.common.red,
    },
    cell: {
      whiteSpace: 'nowrap',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    title: {},
    tableCount: {
      marginTop: 2,
      height: 20,
      width: 20,
      textAlign: 'center',
      borderRadius: '50%',
      lineHeight: '10px',
      fontSize: 8,
    },
    headerCell: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#000',
    },
    toolbar: {
      justifyContent: 'space-between',
      marginTop: '30px',
      marginBottom: '20px',
      padding: 0,
    },
    noMargin: {
      justifyContent: 'space-between',
      marginBottom: '20px',
      padding: 0,
      paddingLeft: '10px',
    },
    actionCell: {
      minWidth: '300px',
      maxWidth: '300px',
    },
    divider: {
      borderStyle: 'inset',
    },
    rowContainer: {
      height: 20,
      borderTop: '1px dotted rgb(0,0,0, 0.2)',
      borderBottom: '1px dotted rgb(0,0,0, 0.2)',
    },
    rowContainer2: {
      height: 30,
      borderTop: '1px dotted rgb(0,0,0, 0.2)',
      borderBottom: '1px dotted rgb(0,0,0, 0.2)',
    },
    serachableHeader: {
      display: 'inline-block',
      '&:hover': { cursor: 'pointer', color: '#1976d2' },
    },
    cancelSerachButton: {
      position: 'absolute',
      top: 26,
      right: '-6px',
      height: 26,
      padding: 4,
    },
    titleWithButton: {
      lineHeight: '48px',
    },
    cellRoot: {
      position: 'relative',
      padding: '6px 12px 6px 12px',
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '12px',
      },
      maxWidth: '40px',
      minWidth: '40px',
      textAlign: 'center',
      height: 'auto !important',
      borderBottom: 'none',
      '&:last-child': {
        padding: '6px 12px 6px 12px',
      },
      '&:first-child': {
        padding: '6px 12px 6px 12px',
      },
      '&:focus': {
        outline: 'none',
        color: 'transparent',
        textShadow: '0 0 0 black',
        border: '1px solid rgba(25, 118 , 210, 0.5)',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
    cellRoot2: {
      position: 'relative',
      padding: '3px 6px 1px 1px',
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '12px',
      },
      maxWidth: '5%',
      minWidth: '5%',
      whiteSpace: 'nowrap',
      textAlign: 'right',
      height: 'auto !important',
      borderBottom: 'none',
      '&:last-child': {
        padding: '3px 6px 1px 1px',
      },
      '&:first-child': {
        padding: '4px 8px 4px 8px',
      },
      '&:focus': {
        outline: 'none',
        color: 'transparent',
        textShadow: '0 0 0 black',
        border: '1px solid rgba(25, 118 , 210, 0.5)',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
    cellRoot4: {
      position: 'relative',
      padding: '3px 6px 1px 1px',
      fontSize: '12px',
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
      },
      maxWidth: '140px',
      minWidth: '140px',
      whiteSpace: 'nowrap',
      textAlign: 'right',
      height: 'auto !important',
      borderBottom: 'none',
      '&:last-child': {
        padding: '3px 6px 1px 1px',
      },
      '&:first-child': {
        padding: '4px 8px 4px 8px',
      },
      '&:focus': {
        outline: 'none',
        color: 'transparent',
        textShadow: '0 0 0 black',
        border: '1px solid rgba(25, 118 , 210, 0.5)',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
    cellRoot5: {
      position: 'relative',
      padding: '3px 6px 1px 1px',
      fontSize: '12px',
      maxWidth: '20px',
      minWidth: '20px',
      whiteSpace: 'nowrap',
      textAlign: 'right',
      height: 'auto !important',
      borderBottom: 'none',
      '&:last-child': {
        padding: '3px 6px 1px 1px',
      },
      '&:first-child': {
        padding: '4px 8px 4px 8px',
      },
      '&:focus': {
        outline: 'none',
        color: 'transparent',
        textShadow: '0 0 0 black',
        border: '1px solid rgba(25, 118 , 210, 0.5)',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
    cellRoot3: {
      position: 'relative',
      padding: '4px 8px 4px 8px',
      fontSize: '12px',
      textAlign: 'right',
      border: 'none',
      height: 'auto !important',
      '&:last-child': {
        padding: '4px 8px 4px 8px',
      },
      '&:first-child': {
        padding: '4px 8px 4px 8px',
      },
      '&:focus': {
        outline: 'none',
        color: 'transparent',
        textShadow: '0 0 0 black',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
    colorCellRoot: {
      position: 'relative',
      padding: '1px 2px 1px 2px',
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '12px',
      },
      maxWidth: '20px',
      minWidth: '20px',
      textAlign: 'center',
      height: 'auto !important',
      borderBottom: 'none',
      '&:last-child': {
        padding: '6px 12px 6px 12px',
      },
      '&:first-child': {
        padding: '1px 2px 1px 2px',
      },
      '&:focus': {
        outline: 'none',
        color: 'transparent',
        textShadow: '0 0 0 black',
        border: '1px solid rgba(25, 118 , 210, 0.5)',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
    name: {
      textAlign: 'left !important' as any,
      whiteSpace: 'nowrap',
    },
    selected: {
      color: '#006400',
      borderColor: '#006400',
    },
    headerName: {
      textAlign: 'left !important' as any,
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#000',
    },
    textfield: {
      width: 40,
      textAlign: 'center',
    },
    editingTextfield: {
      textAlign: 'center',
      fontWeight: 600,
      // fontSize: '9px',
    },
    riskClassesRowContainer: {
      height: 32,
      borderTop: '0px',
      borderBottom: '1px dotted rgb(0,0,0, 0.2)',
    },
  });

export default styles;
