import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      minHeight: '100%',
      flexDirection: 'column',
      WebkitAnimation: 'fadein 2s',
    },
    root1: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '10px',
      width: 'calc(100% - 10px)',
      marginBottom: 100,
    },
    reportButtonContainer: {
      justifyContent: 'flex-end',
    },
    table: {
      flex: '0 0 30%',
    },
    titleContainer: {
      width: '100%',
      textAlign: 'left',
      marginLeft: '25px',
    },
    title: {
      marginLeft: '8px',
      fontSize: '12px',
      fontWeight: 600,
      color: '#424242',
    },
    toolbar: {
      marginTop: '5px',
      marginBottom: '20px',
      padding: 0,
      textAlign: 'center',
    },
    chart: {
      flex: '0 0 30%',
      padding: '0 20px',
      marginTop: '72px',
    },
    leftContainer: {
      flex: '1 0 15%',
      padding: '46px 10px 0 30px',
    },
    rightContainer: {
      flex: '1 0 70%',
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '30px',
    },
    addButton: {
      minWidth: '50%',

      marginRight: '10px',
    },
    leftPart: {
      flex: '1 0 58%',
      display: 'flex',
    },
    rightPart: {
      flex: '1 0 38%',
      display: 'flex',
      flexDirection: 'column',
    },
    chartContainer: {
      position: 'relative',
      height: '100%',
      width: '100%',
      minHeight: 100,
      maxHeight: 200,
    },
    riskImage: {
      marginLeft: '30px',
    },
    lowRiskLabel: {
      display: 'inline',
      marginLeft: 25,
    },
    highRiskLabel: {
      display: 'inline',
      position: 'relative',
      left: 100,
    },
    asb: {
      padding: '0px 3px',
      fontSize: '9px',
      width: '100%',
      height: 44,
      marginTop: '2px',
    },
    input: {
      padding: '0px 3px 0px 3px',
      fontSize: '12px',
      lineHeight: '40px',
      color: '#039be5',
    },
    test: { width: '100%' },
    minorTitle: {
      lineHeight: '40px',
    },
    minorContainer: {
      display: 'flex',
      paddingTop: '8px',
    },
    fullField: {
      width: '95px',
      padding: '1px 0px 1px 5px',
      [theme.breakpoints.down('md')]: {
        width: '80px',
      },
    },
    first: {
      width: '100px',
    },
    second: {
      width: '100px',
    },
    third: {
      width: '100px',
    },
    leftChart: {
      width: '50%',
      height: '400px',
    },
    rightChart: {
      width: '50%',
      height: '400px',

      marginLeft: 10,
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: '1 0 auto',
      paddingTop: '38px',
      maxWidth: 1870,
    },
    bottomContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: '1 0 auto',
      padding: '0px 40px 20px 30px',
      marginBottom: 75,
      maxWidth: 1800,
    },

    imputRoot: {
      color: '#039be5',
      fontSize: '12px',
      textAlign: 'right',
      '&:focus': {
        textAlign: 'right',
      },
    },

    textFieldRoot: {
      borderColor: '#1976d270 !important',
    },

    borderClass: {
      borderColor: '#1976d280 !important',
      height: 44,
    },
    textRootClass: {
      padding: '0px 5px',
      marginTop: 0,
      marginBottom: 0,
    },
    titleContainer2: {
      width: '100%',
    },
    title2: { marginLeft: '30px' },
    toolbar2: {
      marginTop: '20px',
      marginBottom: '30px',
      padding: 0,
    },
    dropInputClass: {
      fontSize: '12px',
      lineHeight: '40px',
      padding: '0 31px 0 20px',
      color: '#039be5',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        padding: '0 20px',
      },
    },
    toDateContainer: {
      display: 'flex',
      paddingTop: 0,
      marginTop: 12,
      marginBottom: -2,
    },
  });

export default styles;
