import React, { ReactElement, useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import classNames from 'classnames';
import Dates from '../../constants/Date';
import DataTable from '../../containers/DataTable';
import TabsComponent from '../../containers/Tabs';
import Dialog from '../../containers/Dialog';
import DatePicker from '../../components/DatePicker';
import AddDialogForm from './AddDialogForm';
import EditDialogForm from './EditDialogForm';
import styles from './styles';
import {
  AccountSecurityLifetimeType,
  AccountSecurityType,
  SecurityType,
  ClientType,
  AccountType,
  UserType,
  CreateAccountSecurityLifetimeType,
  UpdateAccountSecurityLifetimeType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface StartDateObj {
  month: number;
  year: number;
}

interface AccountSecurityCreateObj {
  refAccountId: number;
  refSecurityId: number;
}

interface BreadcrumbItemObj {
  name: string;
  route: string;
}

interface Props {
  getAccountSecuritiesList: (
    id: number,
    obj: { refAccountId: number; year: number; month: number }
  ) => void;
  getSecuritiesList: () => void;
  getAccountItem: (id: number) => void;
  removeAccountSecurity: (
    clientid: number,
    objid: number,
    obj: { refAccountId: number; year: number; month: number }
  ) => void;
  getAllAccountSecuritiesList: (id: number) => void;
  accountSecurityLifetimes: Array<AccountSecurityLifetimeType>;
  accountSecurities: Array<AccountSecurityType>;
  allAccountSecurities: Array<AccountSecurityType>;
  securities: { content: Array<SecurityType> };
  openDialog: () => void;
  closeDialog: () => void;
  createSecurityLifetime: (
    obj: CreateAccountSecurityLifetimeType
  ) => Promise<any>;
  updateSecurityLifetime: (
    obj: UpdateAccountSecurityLifetimeType
  ) => Promise<any>;
  removeSecurityLifetime: (id: number) => void;
  deleted: boolean;
  loading: boolean;
  success: boolean;
  error: any;
  match: any;
  history: any;
  client: ClientType;
  account: AccountType;
  getAccountSecurityStartDate: (id: number) => Promise<StartDateObj>;
  attachSecurity: (obj: AccountSecurityCreateObj) => void;
  getSecurityLifeTime: (accountid: number, id: number) => void;
  setYear: (year: number) => void;
  setMonth: (month: number) => void;
  selectedMonth: number;
  selectedYear: number;
  getUserItem: (id: number) => Promise<UserType>;
  checkToRemoveAccountSecurity: (
    accountId: number,
    securityId: number
  ) => Promise<any>;
}

const AccountSecurity = (props: Props): ReactElement => {
  const {
    match: {
      params: { accountId },
    },
    getAccountSecuritiesList,
    getAllAccountSecuritiesList,
    getAccountItem,
    getSecuritiesList,
    selectedYear,
    selectedMonth,
    openDialog,
    closeDialog,
    removeAccountSecurity,
    history,
    setMonth,
    setYear,
    getAccountSecurityStartDate,
    attachSecurity,
    getSecurityLifeTime,
    accountSecurityLifetimes,
    createSecurityLifetime,
    updateSecurityLifetime,
    removeSecurityLifetime,
    accountSecurities,
    allAccountSecurities,
    securities,
    loading,
    deleted,
    error,
    success,
    client,
    account,
    getUserItem,
    checkToRemoveAccountSecurity,
    ...others
  } = props;

  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [model, setModel] = useState<AccountSecurityType | undefined>(
    undefined
  );
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [tabNumber, setTabNumber] = useState<number>(1);
  const [
    accountSecurityHasValueForResponse,
    setAccountSecurityHasValueResponse,
  ] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    getAllAccountSecuritiesList(accountId);
    getAccountItem(accountId);
    getSecuritiesList();
  }, [
    accountId,
    getAllAccountSecuritiesList,
    getAccountItem,
    getSecuritiesList,
  ]);

  useEffect(() => {
    getAccountSecuritiesList(accountId, {
      refAccountId: accountId,
      year: selectedYear,
      month: selectedMonth,
    });
  }, [getAccountSecuritiesList, selectedYear, selectedMonth, accountId]);

  const handleOpenAdd = (): void => {
    openDialog();
    setOpenAdd(true);
    setModel(undefined);
  };

  const handleOpenEdit = (event: any, row: AccountSecurityType): void => {
    openDialog();
    setOpenEdit(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: AccountSecurityType): void => {
    openDialog();
    setOpenDelete(true);
    setSelectedRow(row);
    checkToRemoveAccountSecurity(accountId, row.id)
      .then((hasValueResponse: any): void =>
        setAccountSecurityHasValueResponse(hasValueResponse?.hasValue)
      )
      .catch((someError: any): void => console.warn(someError));
  };

  const handleClose = (): void => {
    closeDialog();
    setOpenAdd(false);
    setOpenEdit(false);
  };

  const onRowSelection = (row: AccountSecurityType): void => {
    setSelectedRow(row);
  };

  const remove = (): void => {
    setIsRemoving(true);
    removeAccountSecurity(accountId, selectedRow.id, {
      refAccountId: accountId,
      year: selectedYear,
      month: selectedMonth,
    });
  };

  const securityDetails = (e: any, data: AccountSecurityType): void => {
    history.push(`/security/${data.id}`);
  };

  const setStart = (): void => {
    getAccountSecurityStartDate(accountId).then((date: StartDateObj): void => {
      setMonth(date.month);
      setYear(date.year);
    });
  };

  const getDate = (dateInput: string): string => {
    if (dateInput) {
      const date = new Date(dateInput);
      const monthObj = Dates.months.find(
        each => each.valueNumber - 1 === date.getMonth()
      );
      const month = monthObj && monthObj.label;
      const year = date.getFullYear();
      return `${month}-${year}`;
    }
    return '';
  };

  const dateData = {
    refAccountId: accountId,
    year: selectedYear,
    month: selectedMonth,
  };

  const columns = [
    { title: 'Name', name: 'name' },
    { title: 'Display', name: 'display' },
    { title: 'ISIN', name: 'isin' },
    { title: 'Start Date', name: 'startDate' },
    { title: 'End Date', name: 'endDate' },
    { title: 'Comment 1', name: 'comment1' },
    { title: 'Comment 2', name: 'comment2' },
  ];

  const breadcrumbItems = [
    {
      name: client ? client.name : '',
      route: client ? `/selected-client/${client.id}` : '',
    },
    {
      name: 'Accounts',
      route: client ? `/client/${client.id}/accounts` : '',
    },
    {
      name: account ? account.refAssetManager.name : '',
      route: client ? `/client/${client.id}/accounts` : '',
    },
  ];

  const tabNames = ['Current', 'All'];
  const tabValues = [1, 2];

  const allAccSecurities =
    allAccountSecurities &&
    allAccountSecurities.map((each: AccountSecurityType): any => ({
      ...each,
      comment1: (each.lifetime && each.lifetime.comment1) || '',
      comment2: (each.lifetime && each.lifetime.comment2) || '',
      startDate: getDate(each.lifetime && each.lifetime.startDate),
      endDate: getDate(each.lifetime && each.lifetime.endDate),
    }));

  const accSecurities =
    accountSecurities &&
    accountSecurities.map((each: AccountSecurityType): any => ({
      ...each,
      comment1: (each.lifetime && each.lifetime.comment1) || '',
      comment2: (each.lifetime && each.lifetime.comment2) || '',
      startDate: getDate(each.lifetime && each.lifetime.startDate),
      endDate: getDate(each.lifetime && each.lifetime.endDate),
    }));

  return (
    <div className={classes.root}>
      <div style={{ height: '100%' }}>
        <div style={{ height: 'calc(100% - 30px)' }}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.titleContainer}>
              <Tooltip title="Back">
                <IconButton
                  onClick={(): void => history.goBack()}
                  classes={{ root: classes.backRotButton }}
                  color="inherit"
                >
                  <LeftIcon />
                </IconButton>
              </Tooltip>

              <div className={classes.titleContainer}>
                {breadcrumbItems.map(
                  (each: BreadcrumbItemObj): ReactElement => (
                    <div key={each.name}>
                      <Typography
                        component="a"
                        variant="h2"
                        onClick={(): void => history.push(each.route)}
                        className={classNames(
                          classes.title,
                          classes.titleWithButton,
                          classes.link
                        )}
                      >
                        {each.name}
                      </Typography>
                      <NavigateNextIcon
                        fontSize="small"
                        style={{
                          marginBottom: '-5px',
                          marginLeft: '10px',
                          marginRight: '10px',
                        }}
                      />
                    </div>
                  )
                )}
                <Typography
                  className={classNames(classes.title, classes.titleWithButton)}
                  id="tableTitle"
                  variant="h2"
                >
                  Account Securities
                </Typography>
                <div className={classes.tableCount}>
                  {accountSecurities ? accountSecurities.length : 0}
                </div>
              </div>
            </div>

            <div className={classes.datePickerContainer}>
              <DatePicker setStart={setStart} />
            </div>

            <Button
              onClick={handleOpenAdd}
              variant="outlined"
              className={classes.addButton}
            >
              Add Account Security
            </Button>
          </Toolbar>
          <div style={{ display: 'flex' }}>
            <TabsComponent
              selectedTab={tabNumber}
              changeTab={(value: number): void => setTabNumber(value)}
              tabs={tabNames}
              values={tabValues}
            />
          </div>
          <div style={{ overflow: 'auto', height: 'calc(100% - 136px)' }}>
            {tabNumber === 1 && (
              <div style={{ height: '100%' }}>
                <DataTable
                  hasAddButton
                  hasEditAndDelete
                  onEdit={handleOpenEdit}
                  onDelete={handleDeleteOpen}
                  onAdd={handleOpenAdd}
                  onRowSelection={onRowSelection}
                  columns={columns}
                  rows={accSecurities}
                  hasBackButton
                  hasDetails
                  noMargin
                  breadcrumbItems={breadcrumbItems}
                  hasBreadcrumb
                  onClickRow={securityDetails}
                  entity="Account Security"
                  entities="Account Securities"
                  hasToolbar={false}
                  history={history}
                  {...others}
                />
              </div>
            )}

            {tabNumber === 2 && (
              <div style={{ height: '100%' }}>
                <DataTable
                  hasAddButton
                  hasEditAndDelete
                  onEdit={handleOpenEdit}
                  onDelete={handleDeleteOpen}
                  onAdd={handleOpenAdd}
                  onRowSelection={onRowSelection}
                  columns={columns}
                  rows={allAccSecurities}
                  hasBackButton
                  hasDetails
                  noMargin
                  breadcrumbItems={breadcrumbItems}
                  hasBreadcrumb
                  onClickRow={securityDetails}
                  entity="Account Security"
                  entities="Account Securities"
                  hasToolbar={false}
                  history={history}
                  {...others}
                />
              </div>
            )}
          </div>
        </div>
        <AddDialogForm
          open={openAdd}
          onClose={handleClose}
          securities={
            (securities &&
              securities.content &&
              securities.content.filter(
                (secuirity: SecurityType): any =>
                  accountSecurities &&
                  allAccountSecurities &&
                  !(
                    accountSecurities.find(
                      (as: AccountSecurityType): any => as.id === secuirity.id
                    ) ||
                    allAccountSecurities.find(
                      (as: AccountSecurityType): any => as.id === secuirity.id
                    )
                  )
              )) ||
            []
          }
          accountId={accountId}
          attachSecurity={attachSecurity}
          dateData={dateData}
          loading={loading}
          success={success}
          error={error}
        />
        <EditDialogForm
          model={model}
          open={openEdit}
          loading={loading}
          success={success}
          error={error}
          onClose={handleClose}
          createSecurityLifetime={createSecurityLifetime}
          updateSecurityLifetime={updateSecurityLifetime}
          removeSecurityLifetime={removeSecurityLifetime}
          getSecurityLifeTime={getSecurityLifeTime}
          accountId={accountId}
          dateData={dateData}
          account={account}
          accountSecurityLifetimes={accountSecurityLifetimes}
          getUserItem={getUserItem}
        />
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          title="Are You Sure?"
          actions={
            isRemoving
              ? [
                  {
                    label: 'OK',
                    onClick: handleDeleteClose,
                  },
                ]
              : [
                  {
                    label: 'cancel',
                    onClick: handleDeleteClose,
                    type: 'secondary',
                  },
                  {
                    label: 'Delete',
                    onClick: remove,
                  },
                ]
          }
        >
          {!isRemoving && !accountSecurityHasValueForResponse && !loading && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                Are you sure?
              </Typography>
            </div>
          )}
          {!isRemoving && accountSecurityHasValueForResponse && !loading && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                There is data attached to the Security in this Account.
                <br /> Are you sure you want to remove this Security and the
                data?
              </Typography>
            </div>
          )}
          {loading && (
            <div className={classes.container}>
              <LinearProgress className={classes.progress} />
            </div>
          )}
          {isRemoving && !loading && deleted && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                Security removed successfully.
              </Typography>
            </div>
          )}
          {isRemoving && !loading && !deleted && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                Error: {error && error.messages && error.messages[0]}
              </Typography>
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
};

AccountSecurity.defaultProps = {
  accountSecurityLifetimes: [],
  accountSecurities: [],
  allAccountSecurities: [],
  securities: undefined,
  deleted: false,
  accountType: undefined,
  success: false,
  error: undefined,
  securityStartDate: undefined,
  account: undefined,
};

export default AccountSecurity;
