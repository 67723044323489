import React, { ReactElement } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Clients from '../../scenes/Clients';
import Home from '../../scenes/Home';
import Client from '../Client';
import Currency from '../../scenes/Currency';
import AssetClassSet from '../../scenes/AssetClassSet';
import AssetManager from '../../scenes/AssetManager';
import AssetClassSetItem from '../../scenes/AssetClassSet/AssetClassSetItem';
import AccountType from '../../scenes/AccountType';
import AccountCode from '../../scenes/AccountCode';
import Region from '../../scenes/Region';
import Securities from '../../scenes/Securities';
import Benchmark from '../../scenes/Benchmark';
import Security from '../../scenes/Security';
import ClientAccounts from '../../scenes/ClientAccounts';
import AccountSecurity from '../../scenes/AccountSecurity';
import AccountDetails from '../../scenes/AccountDetails';
import Reports from '../../scenes/Reports';
import Users from '../../scenes/Users';
import UserClients from '../../scenes/UserClients';
import ClientsOverview from '../../scenes/ClientsOverview';
import SecuritiesOverview from '../../scenes/SecuritiesOverview';
import AssetManagersOverview from '../../scenes/AssetManagersOverview';
import Environment from '../../scenes/Environments';
import ClientType from '../../scenes/ClientType';

interface Props {
  loggedIn?: boolean;
}

const Lobby = ({ loggedIn }: Props): ReactElement => {
  if (!loggedIn) return <div />;
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/selected-client/:clientId" component={Client} />
        <Route exact path="/selected-client" component={Client} />
        <Route path="/clients" exact component={Clients} />
        <Route
          path="/client/:clientId/accounts"
          exact
          component={ClientAccounts}
        />
        <Route
          path="/client/:clientId/:accountId/securities"
          exact
          component={AccountSecurity}
        />
        <Route
          path="/client/:clientId/:accountId/details"
          exact
          component={AccountDetails}
        />
        <Route path="/currencies" exact component={Currency} />
        <Route path="/asset-class-set" exact component={AssetClassSet} />
        <Route
          path="/asset-class-set/:assetClassSetId"
          exact
          component={AssetClassSetItem}
        />
        <Route path="/asset-manager" exact component={AssetManager} />
        <Route path="/account-type" exact component={AccountType} />
        <Route path="/account-code" exact component={AccountCode} />
        <Route path="/region" exact component={Region} />
        <Route path="/security" exact component={Securities} />
        <Route path="/security/:securityId" exact component={Security} />
        <Route path="/benchmark" exact component={Benchmark} />
        <Route path="/reports" exact component={Reports} />
        <Route path="/clients-overview" exact component={ClientsOverview} />
        <Route
          path="/securities-overview"
          exact
          component={SecuritiesOverview}
        />
        <Route
          path="/assetmanagers-overview"
          exact
          component={AssetManagersOverview}
        />
        <Route path="/environments" exact component={Environment} />
        <Route path="/users" exact component={Users} />
        <Route path="/user/:userId/clients" exact component={UserClients} />
        <Route path="/client-group" exact component={ClientType} />
      </Switch>
    </>
  );
};

Lobby.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};

Lobby.defaultProps = {
  loggedIn: false,
};

export default Lobby;
