import { ErrorHandler } from '../helpers/ErrorHandler';
import Request, { getUrl } from '../helpers/Request';

const upload = (data: any): any =>
  fetch(getUrl('file/upload'), {
    method: 'POST',
    body: data,
    credentials: 'include',
  })
    .then(ErrorHandler)
    .then((uploadResponse: any): any => uploadResponse);

const getFile = (id: number): any =>
  Request(`file/${id}`, 'GET')
    .then(ErrorHandler)
    .then((response: any): any => response);

const FileService = {
  upload,
  getFile,
};

export default FileService;
