import React, { useEffect, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Dates from '../../constants/Date';
import DataTable from '../../containers/DataTable';
import styles from './styles';
import { BenchmarkType, ExportType, BenchmarkValueType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface BenchmarkObject {
  name?: string;
  id?: number;
}

interface Props {
  getBenchmarkValues?: (id: number) => void;
  benchmarkValues?: Array<BenchmarkValueType>;
  goBack: () => void;
  benchmark: BenchmarkType;
  exportBenchmarkValueTable?: (id: number) => Promise<ExportType>;
}

const BenchmarkItem = (props: Props): ReactElement => {
  const {
    benchmark,
    getBenchmarkValues,
    goBack,
    exportBenchmarkValueTable,
    benchmarkValues,
    ...others
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if (getBenchmarkValues) getBenchmarkValues(benchmark.id);
  }, [benchmark, getBenchmarkValues]);

  const getDate = (dateInput: string): string => {
    if (dateInput) {
      const date = new Date(dateInput);
      const year = date.getFullYear();
      const monthObj = Dates.months.find(
        each => each.valueNumber - 1 === date.getMonth()
      );
      const month = monthObj && monthObj.label;
      return `${month}-${year}`;
    }
    return '';
  };

  const exportTable = (): void => {
    if (exportBenchmarkValueTable)
      exportBenchmarkValueTable(benchmark.id).then((res: ExportType): void => {
        const csvData = new Blob([res.export], {
          type: 'text/csv;charset=utf-8;',
        });
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', res.fileName || 'Benchmarks.csv');
        tempLink.click();
      });
  };
  const columns = [
    {
      title: 'Date',
      name: 'formattedDate',
      searchable: true,
      style: { width: 300 },
      rowStyle: { width: 300 },
      sortable: true,
      sortField: 'date',
      type: 'number',
    },
    {
      title: 'Value',
      name: 'formattedValue',
      sortable: true,
      type: 'number',
      sortField: 'value',
    },
  ];

  const formattedBenchmark =
    benchmarkValues &&
    benchmarkValues.map((each: BenchmarkValueType): any => ({
      ...each,
      formattedValue: `${(Math.round(each.value * 100) / 100).toFixed(2)}`,
      formattedDate: getDate(each.date),
    }));

  const breadcrumbItems = [
    {
      name: 'Benchmarks',
      route: '',
    },
  ];

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.titleContainer}>
          <Tooltip title="Back">
            <IconButton
              onClick={(): void => goBack()}
              classes={{ root: classes.backRotButton }}
              color="inherit"
            >
              <LeftIcon />
            </IconButton>
          </Tooltip>

          <div className={classes.titleContainer}>
            {breadcrumbItems.map(each => (
              <div key={each.name}>
                <Typography
                  component="a"
                  variant="h2"
                  className={classNames(
                    classes.title,
                    classes.titleWithButton,
                    classes.link
                  )}
                >
                  {each.name}
                </Typography>
                <NavigateNextIcon
                  fontSize="small"
                  style={{
                    marginBottom: '-5px',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                />
              </div>
            ))}
            <Typography
              className={classNames(classes.title, classes.titleWithButton)}
              id="tableTitle"
              variant="h2"
            >
              {(benchmark && benchmark.name) || ''}
            </Typography>
            <div className={classes.tableCount}>
              {benchmarkValues ? benchmarkValues.length : 0}
            </div>
          </div>
        </div>
      </Toolbar>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          columns={columns}
          rows={formattedBenchmark || []}
          hasBackButton
          hasDetails
          noMargin
          hasExportButton
          onExport={exportTable}
          breadcrumbItems={breadcrumbItems}
          hasBreadcrumb
          hasEditAndDelete
          entities="Benchmarks"
          entity="Benchmark"
          hasToolbar={false}
          {...others}
        />
      </div>
    </div>
  );
};

BenchmarkItem.defaultProps = {
  benchmarkValues: [],
  benchmark: undefined,
  goBack: undefined,
};

export default BenchmarkItem;
