export const BENCHMARKS_LIST_REQUEST = 'BENCHMARKS_LIST_REQUEST';
export const BENCHMARKS_LIST_SUCCESS = 'BENCHMARKS_LIST_SUCCESS';
export const BENCHMARKS_LIST_FAILURE = 'BENCHMARKS_LIST_FAILURE';
export const BENCHMARK_SAVE_REQUEST = 'BENCHMARK_SAVE_REQUEST';
export const BENCHMARK_SAVE_SUCCESS = 'BENCHMARK_SAVE_SUCCESS';
export const BENCHMARK_SAVE_FAILURE = 'BENCHMARK_SAVE_FAILURE';
export const BENCHMARK_ITEM_REQUEST = 'BENCHMARK_ITEM_REQUEST';
export const BENCHMARK_ITEM_SUCCESS = 'BENCHMARK_ITEM_SUCCESS';
export const BENCHMARK_ITEM_FAILURE = 'BENCHMARK_ITEM_FAILURE';
export const BENCHMARK_REMOVE_REQUEST = 'BENCHMARK_REMOVE_REQUEST';
export const BENCHMARK_REMOVE_SUCCESS = 'BENCHMARK_REMOVE_SUCCESS';
export const BENCHMARK_REMOVE_FAILURE = 'BENCHMARK_REMOVE_FAILURE';
export const BENCHMARK_GET_VALUES_REQUEST = 'BENCHMARK_GET_VALUES_REQUEST';
export const BENCHMARK_GET_VALUES_SUCCESS = 'BENCHMARK_GET_VALUES_SUCCESS';
export const BENCHMARK_GET_VALUES_FAILURE = 'BENCHMARK_GET_VALUES_FAILURE';
export const BENCHMARK_EDIT_REQUEST = 'BENCHMARK_EDIT_REQUEST';
export const BENCHMARK_EDIT_SUCCESS = 'BENCHMARK_EDIT_SUCCESS';
export const BENCHMARK_EDIT_FAILURE = 'BENCHMARK_EDIT_FAILURE';
export const BENCHMARK_PUSH_REQUEST = 'BENCHMARK_PUSH_REQUEST';
export const BENCHMARK_PUSH_SUCCESS = 'BENCHMARK_PUSH_SUCCESS';
export const BENCHMARK_PUSH_FAILURE = 'BENCHMARK_PUSH_FAILURE';
