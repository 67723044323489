import React, { useState, useEffect, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import DialogForm from './DialogForm';
import styles from './styles';
import {
  AccountCodeType,
  ExportType,
  UserType,
  MutateAccountCodeType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  getAccountCodesList: () => void;
  removeAccountCode: (id: number) => void;
  createAccountCode: (obj: MutateAccountCodeType) => void;
  updateAccountCode: (obj: MutateAccountCodeType, id: number) => void;
  accountCodes: { content: Array<AccountCodeType> };
  deleted: boolean;
  success: boolean;
  loading: boolean;
  error: any;
  openDialog: () => void;
  closeDialog: () => void;
  getUserItem: (id: number) => Promise<UserType>;
  exportAccountCodesTable: () => Promise<ExportType>;
}

const AccountCode = (props: Props): ReactElement => {
  const {
    getAccountCodesList,
    openDialog,
    closeDialog,
    removeAccountCode,
    exportAccountCodesTable,
    accountCodes,
    updateAccountCode,
    createAccountCode,
    loading,
    success,
    error,
    deleted,
    getUserItem,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setopenDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<AccountCodeType | undefined>(
    undefined
  );
  const [model, setModel] = useState<AccountCodeType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    getAccountCodesList();
  }, [getAccountCodesList]);

  const openAdd = (): void => {
    openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const openEdit = (event: any, row: AccountCodeType): void => {
    openDialog();
    setOpen(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setopenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: AccountCodeType): void => {
    openDialog();
    setopenDelete(true);
    setSelectedRow(row);
  };

  const handleClose = (): void => {
    closeDialog();
    setOpen(false);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) removeAccountCode(selectedRow.id);
  };

  const exportTable = (): void => {
    exportAccountCodesTable().then((res: ExportType): void => {
      const csvData = new Blob([res.export], {
        type: 'text/csv;charset=utf-8;',
      });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', res.fileName || 'AccountCodes.csv');
      tempLink.click();
    });
  };

  const columns = [
    {
      title: 'Name',
      name: 'code',
      type: 'string',
      sortable: true,
      sortField: 'code',
    },
  ];

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          hasEditAndDelete
          onEdit={openEdit}
          onDelete={handleDeleteOpen}
          onAdd={openAdd}
          columns={columns}
          rows={accountCodes && accountCodes.content}
          entity="Account Code"
          entities="Account Codes"
          hasExportButton
          hasHighlightBackground
          onExport={exportTable}
          noMargin
        />
      </div>
      <DialogForm
        model={model}
        open={open}
        onClose={handleClose}
        update={updateAccountCode}
        create={createAccountCode}
        loading={loading}
        success={success}
        error={error}
        getUserItem={getUserItem}
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {isRemoving && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Account Code removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error:{' '}
              {(error && error.messages && error.messages[0]) || 'Unknown'}
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};

AccountCode.defaultProps = {
  error: undefined,
  deleted: false,
  success: false,
  accountCodes: undefined,
};

export default AccountCode;
