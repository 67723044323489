import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const create = (data: any): any =>
  Request('assetvalue', 'POST', data)
    .then(ErrorHandler)
    .then((assetValue: any): any => assetValue);

const createComment = (data: any): any =>
  Request('assetvalue/comment', 'POST', data)
    .then(ErrorHandler)
    .then((assetValueComment: any): any => assetValueComment);

const updateComment = (data: any): any =>
  Request('assetvalue/comment', 'PUT', data)
    .then(ErrorHandler)
    .then((assetValueComment: any): any => assetValueComment);

const update = (data: any): any =>
  Request('assetvalue', 'PUT', data)
    .then(ErrorHandler)
    .then((assetValue: any): any => assetValue);

const remove = (data: any): any =>
  Request('assetvalue', 'DELETE', data)
    .then(ErrorHandler)
    .then((assetValue: any): any => assetValue);

const createExtra = (data: any): any =>
  Request('assetvalue/extra', 'POST', data)
    .then(ErrorHandler)
    .then((assetValue: any): any => assetValue);

const updateExtra = (data: any): any =>
  Request('assetvalue/extra', 'PUT', data)
    .then(ErrorHandler)
    .then((assetValue: any): any => assetValue);

const removeExtra = (data: any): any =>
  Request('assetvalue/extra', 'DELETE', data)
    .then(ErrorHandler)
    .then((assetValue: any): any => assetValue);

const AssetValueService = {
  create,
  update,
  remove,
  createExtra,
  updateExtra,
  removeExtra,
  createComment,
  updateComment,
};

export default AssetValueService;
