import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccountTypeActions from '../../actions/AccountTypeActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import ExportActions from '../../actions/ExportActions';
import AccountType from './AccountType';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...AccountTypeActions,
      ...DialogActions,
      ...UserActions,
      ...ExportActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  accountTypes: state.AccountType.accountTypes,
  deleted: state.AccountType.deleted,
  accountType: state.AccountType.accountType,
  success: state.AccountType.success,
  error: state.AccountType.error,
  loading: state.AccountType.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountType);
