import React from 'react';
import { render } from 'react-dom';
import { Store, history, persistor } from './helpers';
import registerServiceWorker from './registerServiceWorker';
import Root from './Root';

render(
  <Root store={Store} history={history} persistor={persistor} />,
  document.getElementById('root')
);
registerServiceWorker();
