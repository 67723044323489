import createStyles from '@material-ui/core/styles/createStyles';

const styles = (): any =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      minHeight: '100%',
      marginTop: '38px',
      flexDirection: 'column',
      WebkitAnimation: 'fadein 2s',
    },
    root1: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      marginLeft: '10px',
      width: 'calc(100% - 10px)',
    },
    table: {
      flex: '0 0 55%',
      marginTop: '-8px',
      maxWidth: '340px',
    },
    titleContainer: {
      textAlign: 'left',
    },
    title: {
      marginLeft: '8px',
    },
    toolbar: {
      marginBottom: '20px',
      padding: 0,
      textAlign: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    chart: {
      flex: '0 0 40%',
      maxWidth: '340px',
      paddingLeft: 54,
    },
    addButton: {
      marginRight: '20px',
      height: 20,
      fontSize: '8px',
      lineHeight: '20px',
    },
    buttonLabel: {
      fontSize: '8px',
      lineHeight: '20px',
    },
    leftPart: {
      flex: '1 0 auto',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '380px',
      marginLeft: 10,
    },
    middlePart: {
      flex: '1 0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '340px',
      marginLeft: 20,
      paddingRight: 20,
    },
    rightPart: {
      flex: '1 0 auto',
      display: 'flex',
      marginLeft: '20px',
    },
    chartContainer: {
      position: 'relative',
      height: '100%',
      width: '100%',
      minHeight: 100,
      maxHeight: 200,
    },
    riskImage: {
      marginLeft: '20px',
      marginTop: '4px',
    },
    number8: {
      position: 'relative',
      left: 132,
      lineHeight: '23px',
      fontSize: 12,
      width: 30,
      color: 'rgb(0,0,0, 0.5)',
    },
    riskLabels: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      opacity: 0.65,
      '& p': {
        fontSize: 12,
      },
    },
    asb: {
      padding: '0px 3px',
      fontSize: '9px',
      width: '100%',
      height: 44,
    },
    test: {
      width: '100%',
    },
    input: {
      color: '#039be5',
      padding: '0px 3px 0px 3px',
      fontSize: '12px',
      lineHeight: '40px',
    },
    topContainer: {
      flex: '1 0 auto',
      paddingLeft: '10px',
      maxWidth: '1400px',
    },
    bottomContainer: {
      display: 'flex',
      flex: '1 0 330px',
      height: '330px',
      maxWidth: '1400px',
    },
    borderClass: {
      borderColor: '#1976d280 !important',
      height: 44,
    },
    riskClassMinMax: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 5px',
      marginTop: -22,
    },
    riskClassesTitle: {
      paddingLeft: 0,
      marginBottom: 8,
    },
    riskClassesRowContainer: {
      height: 32,
      borderTop: '0px',
      borderBottom: '1px dotted rgb(0,0,0, 0.2)',
    },
  });

export default styles;
