import createStyles from '@material-ui/core/styles/createStyles';

const styles = (): any =>
  createStyles({
    root: {
      display: 'flex',
      width: 'calc(100% - 10px)',
      height: 'unset',
      minHeight: '100%',
      marginLeft: '10px',
      WebkitAnimation: 'fadein 2s',
    },
    root1: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      marginLeft: '10px',
      width: 'calc(100% - 10px)',
      marginBottom: 50,
    },
    table: {
      flex: '0 0 30%',
    },
    titleContainer: {
      width: '100%',
    },
    title: {},
    toolbar: {
      marginTop: '38px',
      marginBottom: '30px',
      padding: 0,
    },
    chart: {
      flex: '0 0 30%',
      padding: '0 20px',
    },
    leftContainer: {
      flex: '1 0 55%',
      paddingRight: '20px',
      maxWidth: '900px',
    },
    rightContainer: {
      flex: '1 0 35%',
      paddingRight: '30px',
    },
  });

export default styles;
