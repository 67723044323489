import AllocationService from '../services/AllocationService';
import * as types from '../constants/AssetAllocation';

const createAllocationWeightValue = (data: any): any => (
  dispatch: any
): any => {
  dispatch({ type: types.CREATE_ALLOCATION_WEIGHT_REQUEST });
  return AllocationService.createAllocationWeightValue(data)
    .then((value: any): any => {
      dispatch({ type: types.CREATE_ALLOCATION_WEIGHT_SUCCESS, value });
      return Promise.resolve(value);
    })
    .catch((error: any): any => {
      dispatch({ type: types.CREATE_ALLOCATION_WEIGHT_FAILURE, error });
      return Promise.reject(error);
    });
};

const updateAllocationWeightValue = (data: any): any => (
  dispatch: any
): any => {
  dispatch({ type: types.UPDATE_ALLOCATION_WEIGHT_REQUEST });
  return AllocationService.updateAllocationWeightValue(data)
    .then((value: any): any => {
      dispatch({ type: types.UPDATE_ALLOCATION_WEIGHT_SUCCESS, value });
      return Promise.resolve(value);
    })
    .catch((error: any): any => {
      dispatch({ type: types.UPDATE_ALLOCATION_WEIGHT_FAILURE, error });
      return Promise.reject(error);
    });
};

const removeAllocationWeightValue = (data: any): any => (
  dispatch: any
): any => {
  dispatch({ type: types.REMOVE_ALLOCATION_WEIGHT_REQUEST });
  return AllocationService.removeAllocationWeightValue(data)
    .then((value: any): any => {
      dispatch({ type: types.REMOVE_ALLOCATION_WEIGHT_SUCCESS, value });
      return Promise.resolve(value);
    })
    .catch((error: any): any => {
      dispatch({ type: types.REMOVE_ALLOCATION_WEIGHT_FAILURE, error });
      return Promise.reject(error);
    });
};

const createAssetAllocationValue = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.CREATE_ALLOCATION_VALUE_REQUEST });
  return AllocationService.createAssetAllocationValue(data)
    .then((value: any): any => {
      dispatch({ type: types.CREATE_ALLOCATION_VALUE_SUCCESS, value });
      return Promise.resolve(value);
    })
    .catch((error: any): any => {
      dispatch({ type: types.CREATE_ALLOCATION_VALUE_FAILURE, error });
      return Promise.reject(error);
    });
};

const updateAssetAllocationValue = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.UPDATE_ALLOCATION_VALUE_REQUEST });
  return AllocationService.updateAssetAllocationValue(data)
    .then((value: any): any => {
      dispatch({ type: types.UPDATE_ALLOCATION_VALUE_SUCCESS, value });
      return Promise.resolve(value);
    })
    .catch((error: any): any => {
      dispatch({ type: types.UPDATE_ALLOCATION_VALUE_FAILURE, error });
      return Promise.reject(error);
    });
};

const removeAssetAllocationValue = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.REMOVE_ALLOCATION_VALUE_REQUEST });
  return AllocationService.removeAssetAllocationValue(data)
    .then((value: any): any => {
      dispatch({ type: types.REMOVE_ALLOCATION_VALUE_SUCCESS, value });
      return Promise.resolve(value);
    })
    .catch((error: any): any => {
      dispatch({ type: types.REMOVE_ALLOCATION_VALUE_FAILURE, error });
      return Promise.reject(error);
    });
};

const AssetAllocationActions = {
  createAllocationWeightValue,
  updateAllocationWeightValue,
  createAssetAllocationValue,
  updateAssetAllocationValue,
  removeAllocationWeightValue,
  removeAssetAllocationValue,
};

export default AssetAllocationActions;
