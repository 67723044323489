import * as types from '../constants/Client';
import * as UserTypes from '../constants/User';
import defaultClientData from '../constants/ClientData';

const setNewClient = (client: any, clientData: any): any => {
  if (!client) return clientData;
  if (!clientData) {
    return [{ id: client.id, ...defaultClientData }];
  }
  if (!clientData.find((each: any): any => each.id === client.id)) {
    const newClientData = clientData.concat({
      id: client.id,
      ...defaultClientData,
    });
    return newClientData;
  }
  const newClientData = clientData.map((each: any): any => {
    if (
      each.id === client.id &&
      (!each.selectedRows ||
        !each.selectedDate ||
        !each.selectedTab ||
        !each.selectedColumns ||
        !each.selectedHistory ||
        !each.expandedRows)
    ) {
      return { id: client.id, ...defaultClientData };
    }
    return each;
  });
  return newClientData;
};

const setDataToRelatedDate = (
  clientId: number,
  date: any,
  name: string,
  data: any,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (
      clientId === each.id &&
      each.dates.find((eachDate: any): any => eachDate.date === date)
    ) {
      const newDates = each.dates.map((eachDate: any): any => {
        if (eachDate.date === date) {
          return { ...eachDate, [name]: data };
        }
        return eachDate;
      });
      return { ...each, dates: newDates };
    }
    if (
      clientId === each.id &&
      !each.dates.find((eachDate: any): any => eachDate.date === date)
    ) {
      return { ...each, dates: [...each.dates, { date, [name]: data }] };
    }

    return each;
  });
  return newClientData;
};

const setNewSelectedRowsToClient = (
  clientId: number,
  selectedRows: Array<number>,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, selectedRows, dates: [], hasSetSelectedRows: true };
    }
    return each;
  });
  return newClientData;
};

const setRowsNumberToClient = (
  clientId: number,
  rowsNumber: number,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, rowsNumber };
    }
    return each;
  });
  return newClientData;
};

const setNewSelectedColumnsToClient = (
  clientId: number,
  selectedColumns: Array<number>,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, selectedColumns, dates: [] };
    }
    return each;
  });
  return newClientData;
};

const setNewSelectedSelectionToClient = (
  clientId: number,
  selectedRows: Array<number>,
  selectedColumns: Array<number>,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, selectedColumns, selectedRows, dates: [] };
    }
    return each;
  });
  return newClientData;
};

const setDateToClient = (clientId: number, date: any, clientData: any): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, selectedDate: date };
    }
    return each;
  });
  return newClientData;
};

const setSelectedHistoryToClient = (
  clientId: number,
  selectedHistory: Array<number>,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, selectedHistory };
    }
    return each;
  });
  return newClientData;
};

const setActiveHistoryClient = (
  clientId: number,
  historyActive: boolean,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, historyActive };
    }
    return each;
  });
  return newClientData;
};

const setExpandedRowsToClient = (
  clientId: number,
  expandedRows: Array<number>,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, expandedRows };
    }
    return each;
  });
  return newClientData;
};

const setAssetsChartToClient = (
  clientId: number,
  chart: string,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, assetsChart: chart };
    }
    return each;
  });
  return newClientData;
};

const setRegccyChartToClient = (
  clientId: number,
  chart: string,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, regccyChart: chart };
    }
    return each;
  });
  return newClientData;
};

const setChartingStartYearToClient = (
  clientId: number,
  startYear: number,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, chartingStartYear: startYear };
    }
    return each;
  });
  return newClientData;
};

const setChartingStartMonthToClient = (
  clientId: number,
  startMonth: number,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, chartingStartMonth: startMonth };
    }
    return each;
  });
  return newClientData;
};

const setChartingEndYearToClient = (
  clientId: number,
  endYear: number,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, chartingEndYear: endYear };
    }
    return each;
  });
  return newClientData;
};

const setChartingEndMonthToClient = (
  clientId: number,
  endMonth: number,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, chartingEndMonth: endMonth };
    }
    return each;
  });
  return newClientData;
};

const setChartingRiskFreeToClient = (
  clientId: number,
  riskFree: number,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, chartingRiskFree: riskFree };
    }
    return each;
  });
  return newClientData;
};

const setChartingShowingRowsToClient = (
  clientId: number,
  showingRows: Array<number | string>,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, chartingShowingRows: showingRows };
    }
    return each;
  });
  return newClientData;
};

const setMonitorShowingRowsToClient = (
  clientId: number,
  showingRows: Array<string | number>,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, monitorShowingRows: showingRows };
    }
    return each;
  });
  return newClientData;
};

const setPerformSharpToClient = (
  clientId: number,
  sharp: number,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, performSharp: sharp };
    }
    return each;
  });
  return newClientData;
};

const setMonitorChart = (
  clientId: number,
  chart: string,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, monitorChart: chart };
    }
    return each;
  });
  return newClientData;
};

const setAllocationFirstStatusToClient = (
  clientId: number,
  allocationFirstStatus: string,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, allocationFirstStatus };
    }
    return each;
  });
  return newClientData;
};

const setAllocationSecondStatusToClient = (
  clientId: number,
  allocationSecondStatus: string,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      return { ...each, allocationSecondStatus };
    }
    return each;
  });
  return newClientData;
};

const setSelectedTabToClient = (
  clientId: number,
  tab: number,
  clientData: any
): any => {
  const newClientData = clientData.map((each: any): any => {
    if (each.id === clientId) {
      if (tab === 1) {
        if (each.assetsChart) {
          return { ...each, selectedTab: tab };
        }
        return {
          ...each,
          assetsChart: defaultClientData.assetsChart,
          selectedTab: tab,
        };
      }
      if (tab === 2) {
        if (each.monitorShowingRows && each.monitorChart) {
          return { ...each, selectedTab: tab };
        }
        return {
          ...each,
          monitorChart: defaultClientData.monitorChart,
          monitorShowingRows: defaultClientData.monitorShowingRows,
          selectedTab: tab,
        };
      }
      if (tab === 4) {
        if (each.regccyChart) {
          return { ...each, selectedTab: tab };
        }
        return {
          ...each,
          regccyChart: defaultClientData.regccyChart,
          selectedTab: tab,
        };
      }
      if (tab === 5) {
        if (each.performSharp) {
          return { ...each, selectedTab: tab };
        }
        return {
          ...each,
          performSharp: defaultClientData.performSharp,
          selectedTab: tab,
        };
      }
      if (tab === 6) {
        if (
          each.chartingShowingRows &&
          each.chartingRiskFree &&
          each.chartingEndMonth &&
          each.chartingEndYear &&
          each.chartingStartMonth &&
          each.chartingStartYear
        ) {
          return { ...each, selectedTab: tab };
        }
        return {
          ...each,
          chartingShowingRows: defaultClientData.chartingShowingRows,
          chartingRiskFree: defaultClientData.chartingRiskFree,
          chartingEndMonth: defaultClientData.chartingEndMonth,
          chartingEndYear: defaultClientData.chartingEndYear,
          chartingStartMonth: defaultClientData.chartingStartMonth,
          chartingStartYear: defaultClientData.chartingStartYear,
          selectedTab: tab,
        };
      }
      return { ...each, selectedTab: tab };
    }
    return each;
  });
  return newClientData;
};

const Client = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.CLIENTS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        clients: action.clients,
      };
    case types.CLIENTS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.CLIENT_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CLIENT_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        client: action.client,
      };
    case types.CLIENT_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.CLIENT_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CLIENT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.client.reference,
      };
    case types.CLIENT_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.CLIENT_DUPLICATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CLIENT_DUPLICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.client.reference,
      };
    case types.CLIENT_DUPLICATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.CLIENT_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CLIENT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.client.reference,
      };
    case types.CLIENT_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.CLIENT_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CLIENT_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        reference: 'deleted',
        clients: {
          ...state.clients,
          content: state.clients.content.filter(
            (ac: any): any => ac.id !== action.id
          ),
        },
      };
    case types.CLIENT_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    case types.CLIENT_CHECK_TO_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CLIENT_CHECK_TO_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.CLIENT_CHECK_TO_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.SELECT_CLIENT:
      return {
        ...state,
        clientData: setNewClient(action.client, state.clientData),
        selectedClient: action.client,
      };
    case types.SET_DATE:
      return {
        ...state,
        currentDate: action.date,
      };
    case types.SET_SELECTED_ROWS:
      return {
        ...state,
        clientData: setNewSelectedRowsToClient(
          action.clientId,
          action.selectedRows,
          state.clientData
        ),
      };
    case types.SET_SELECTED_COLUMNS:
      return {
        ...state,
        clientData: setNewSelectedColumnsToClient(
          action.clientId,
          action.selectedColumns,
          state.clientData
        ),
      };
    case types.SET_SELECTED_SELECTION:
      return {
        ...state,
        clientData: setNewSelectedSelectionToClient(
          action.clientId,
          action.selectedRows,
          action.selectedColumns,
          state.clientData
        ),
      };
    case types.SET_SELECTED_HISTORY:
      return {
        ...state,
        clientData: setSelectedHistoryToClient(
          action.clientId,
          action.selectedHistory,
          state.clientData
        ),
      };
    case types.SET_DATE_TO_CLIENT:
      return {
        ...state,
        clientData: setDateToClient(
          action.clientId,
          action.date,
          state.clientData
        ),
      };
    case types.SET_NEW_CLIENT:
      return {
        ...state,
        clientData: setNewClient(action.clientId, state.clientData),
      };

    case types.SET_ACTIVE_HISTORY:
      return {
        ...state,
        clientData: setActiveHistoryClient(
          action.clientId,
          action.historyActive,
          state.clientData
        ),
      };
    case types.SET_EXPANDED_ROWS:
      return {
        ...state,
        clientData: setExpandedRowsToClient(
          action.clientId,
          action.expandedRows,
          state.clientData
        ),
      };
    case types.SET_ASSETS_CHART:
      return {
        ...state,
        clientData: setAssetsChartToClient(
          action.clientId,
          action.chart,
          state.clientData
        ),
      };
    case types.SET_REGCCY_CHART:
      return {
        ...state,
        clientData: setRegccyChartToClient(
          action.clientId,
          action.chart,
          state.clientData
        ),
      };
    case types.SET_ROWS_NUMBER:
      return {
        ...state,
        clientData: setRowsNumberToClient(
          action.clientId,
          action.rowsNumber,
          state.clientData
        ),
      };
    case types.SET_CHARTING_START_YEAR:
      return {
        ...state,
        clientData: setChartingStartYearToClient(
          action.clientId,
          action.startYear,
          state.clientData
        ),
      };
    case types.SET_CHARTING_START_MONTH:
      return {
        ...state,
        clientData: setChartingStartMonthToClient(
          action.clientId,
          action.startMonth,
          state.clientData
        ),
      };
    case types.SET_CHARTING_END_YEAR:
      return {
        ...state,
        clientData: setChartingEndYearToClient(
          action.clientId,
          action.endYear,
          state.clientData
        ),
      };
    case types.SET_CHARTING_END_MONTH:
      return {
        ...state,
        clientData: setChartingEndMonthToClient(
          action.clientId,
          action.endMonth,
          state.clientData
        ),
      };
    case types.SET_CHARTING_RSIK_FREE:
      return {
        ...state,
        clientData: setChartingRiskFreeToClient(
          action.clientId,
          action.riskFree,
          state.clientData
        ),
      };
    case types.SET_CHARTING_SHOWING_ROWS:
      return {
        ...state,
        clientData: setChartingShowingRowsToClient(
          action.clientId,
          action.showingRows,
          state.clientData
        ),
      };
    case types.SET_MONITOR_SHOWING_ROWS:
      return {
        ...state,
        clientData: setMonitorShowingRowsToClient(
          action.clientId,
          action.showingRows,
          state.clientData
        ),
      };
    case types.SET_PERFORM_SHARPE:
      return {
        ...state,
        clientData: setPerformSharpToClient(
          action.clientId,
          action.sharp,
          state.clientData
        ),
      };
    case types.SET_MONITOR_CHART:
      return {
        ...state,
        clientData: setMonitorChart(
          action.clientId,
          action.chart,
          state.clientData
        ),
      };
    case types.SET_SELECTED_TAB:
      return {
        ...state,
        clientData: setSelectedTabToClient(
          action.clientId,
          action.tab,
          state.clientData
        ),
      };
    case types.SET_ALLOCATION_FIRST_STATUS:
      return {
        ...state,
        clientData: setAllocationFirstStatusToClient(
          action.clientId,
          action.allocationFirstStatus,
          state.clientData
        ),
      };
    case types.SET_ALLOCATION_SECOND_STATUS:
      return {
        ...state,
        clientData: setAllocationSecondStatusToClient(
          action.clientId,
          action.allocationSecondStatus,
          state.clientData
        ),
      };
    case types.ASSETS_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case types.ASSETS_SUCCESS:
      return {
        ...state,
        loadingData: false,
        assets: action.assets,
        clientData: setDataToRelatedDate(
          state.selectedClient.id,
          state.currentDate,
          'assets',
          action.assets,
          state.clientData
        ),
      };
    case types.ASSETS_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.error,
      };
    case types.MONITOR_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case types.MONITOR_SUCCESS:
      return {
        ...state,
        loadingData: false,
        monitorData: action.monitorData,
        clientData: setDataToRelatedDate(
          state.selectedClient.id,
          state.currentDate,
          'monitor',
          action.monitorData,
          state.clientData
        ),
      };
    case types.MONITOR_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.error,
      };
    case types.CHARTING_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case types.CHARTING_SUCCESS:
      return {
        ...state,
        loadingData: false,
        chartingData: action.chartingData,
        clientData: setDataToRelatedDate(
          state.selectedClient.id,
          state.currentDate,
          'charting',
          action.chartingData,
          state.clientData
        ),
      };
    case types.CHARTING_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.error,
      };
    case types.PERFORMANCE_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case types.PERFORMANCE_SUCCESS:
      return {
        ...state,
        loadingData: false,
        performanceData: action.performanceData,
        clientData: setDataToRelatedDate(
          state.selectedClient.id,
          state.currentDate,
          'performance',
          action.performanceData,
          state.clientData
        ),
      };
    case types.PERFORMANCE_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.error,
      };
    case types.REGCCY_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case types.REGCCY_SUCCESS:
      return {
        ...state,
        loadingData: false,
        regccys: action.regccys,
        clientData: setDataToRelatedDate(
          state.selectedClient.id,
          state.currentDate,
          'regccy',
          action.regccys,
          state.clientData
        ),
      };
    case types.REGCCY_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.error,
      };
    case types.HISTORY_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case types.HISTORY_SUCCESS:
      return {
        ...state,
        loadingData: false,
        history: action.history,
        clientData: setDataToRelatedDate(
          state.selectedClient.id,
          state.currentDate,
          'history',
          action.history,
          state.clientData
        ),
      };
    case types.HISTORY_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.error,
      };
    case types.ASSET_TABLE_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case types.ASSET_TABLE_SUCCESS:
      return {
        ...state,
        loadingData: false,
        assetTable: action.assetTable,
        clientData: setDataToRelatedDate(
          state.selectedClient.id,
          state.currentDate,
          'assetTable',
          action.assetTable,
          state.clientData
        ),
      };
    case types.ASSET_TABLE_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.error,
      };
    case types.GET_SIMULATION_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case types.GET_SIMULATION_SUCCESS:
      return {
        ...state,
        loadingData: false,
        simulation: action.simulation,
        clientData: setDataToRelatedDate(
          state.selectedClient.id,
          state.currentDate,
          'simulation',
          action.simulation,
          state.clientData
        ),
      };
    case types.GET_SIMULATION_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.error,
      };
    case types.GET_ALLOCATION_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case types.GET_ALLOCATION_SUCCESS:
      return {
        ...state,
        loadingData: false,
        simulation: action.simulation,
        clientData: setDataToRelatedDate(
          state.selectedClient.id,
          state.currentDate,
          'allocation',
          action.allocation,
          state.clientData
        ),
      };
    case types.GET_ALLOCATION_FAILURE:
      return {
        ...state,
        loadingData: false,
        error: action.error,
      };
    case types.SET_SELECTION_CHANGED:
      return {
        ...state,
        selectionChanged: !state?.selectionChanged,
      };
    case UserTypes.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loadingData: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default Client;
