import React, { ReactElement } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

interface Props {
  children: any;
}

interface State {
  error?: any;
  showError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    error: null,
    showError: false,
  };

  componentDidCatch(error: any): void {
    this.setState({ showError: true, error });
  }

  render(): ReactElement {
    const { error, showError } = this.state;
    const { children } = this.props;

    const ErrorSnackBar = (): ReactElement => (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showError}
        autoHideDuration={5000}
        onClose={(event: any, reason: any): void => {
          if (reason === 'clickaway') {
            this.setState({ showError: false });
            return;
          }
          this.setState({ showError: false });
        }}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          message={
            <div style={{ display: 'flex' }}>
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={(): void => this.setState({ showError: false })}
              >
                <CloseIcon />
              </IconButton>
              <h3 style={{ margin: '0 0 4px 0' }}>
                {error && error.message ? '' : 'Oops, An Error Occurred.'}
              </h3>
              {error && error.message ? (
                <span style={{ marginRight: 20 }}>{error.message}</span>
              ) : (
                `Something is broken. Please let us know what you were doing when
              this error occurred. We will fix it as soon as possible. Sorry for
              any inconvenience.`
              )}
            </div>
          }
          style={{
            backgroundColor: error && error.message ? 'white' : '#d32f2f',
            color: error && error.message ? 'black' : 'white',
            fontWeight: 'bold',
          }}
        />
      </Snackbar>
    );

    if (error && error.message) {
      return <ErrorSnackBar />;
    }

    return children;
  }
}

export default ErrorBoundary;
