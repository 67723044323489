import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RiskClassActions from '../../actions/RiskClassActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import ExportActions from '../../actions/ExportActions';
import RiskClass from './RiskClass';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...RiskClassActions,
      ...DialogActions,
      ...UserActions,
      ...ExportActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  deleted: state.RiskClass.deleted,
  riskClass: state.RiskClass.riskClass,
  riskClasses: state.RiskClass.riskClasses,
  success: state.RiskClass.success,
  error: state.RiskClass.error,
  loading: state.RiskClass.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(RiskClass);
