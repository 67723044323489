export const SELECTION_PROFILE_LIST_REQUEST = 'SELECTION_PROFILE_LIST_REQUEST';
export const SELECTION_PROFILE_LIST_SUCCESS = 'SELECTION_PROFILE_LIST_SUCCESS';
export const SELECTION_PROFILE_LIST_FAILURE = 'SELECTION_PROFILE_LIST_FAILURE';
export const SELECTION_PROFILE_CREATE_REQUEST =
  'SELECTION_PROFILE_CREATE_REQUEST';
export const SELECTION_PROFILE_CREATE_SUCCESS =
  'SELECTION_PROFILE_CREATE_SUCCESS';
export const SELECTION_PROFILE_CREATE_FAILURE =
  'SELECTION_PROFILE_CREATE_FAILURE';
export const SELECTION_PROFILE_REMOVE_REQUEST =
  'SELECTION_PROFILE_REMOVE_REQUEST';
export const SELECTION_PROFILE_REMOVE_SUCCESS =
  'SELECTION_PROFILE_REMOVE_SUCCESS';
export const SELECTION_PROFILE_REMOVE_FAILURE =
  'SELECTION_PROFILE_REMOVE_FAILURE';
