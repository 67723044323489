import * as types from '../constants/AccountType';

const AccountType = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.ACCOUNT_TYPES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_TYPES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        accountTypes: action.accountTypes,
      };
    case types.ACCOUNT_TYPES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ACCOUNT_TYPE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_TYPE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.accountType.reference
          ? action.accountType.reference
          : action.accountType.id,
      };
    case types.ACCOUNT_TYPE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.ACCOUNT_TYPE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.accountType.reference
          ? action.accountType.reference
          : action.accountType.id,
      };
    case types.ACCOUNT_TYPE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.ACCOUNT_TYPE_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_TYPE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        accountType: action.accountType,
      };
    case types.ACCOUNT_TYPE_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ACCOUNT_TYPE_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCOUNT_TYPE_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        reference: 'deleted',
        accountTypes: {
          ...state.accountTypes,
          content: state.accountTypes.content.filter(
            (at: any): any => at.id !== action.id
          ),
        },
      };
    case types.ACCOUNT_TYPE_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default AccountType;
