import UserService from '../services/UserService';
import * as types from '../constants/User';
import { UserType, UserRoleType, UserProfileType } from '../Types';

const getProfile = (): any => (dispatch: any): void => {
  dispatch({ type: types.USER_PROFILE_REQUEST });
  UserService.profile()
    .then((profileData: UserProfileType): void => {
      dispatch({
        type: types.USER_PROFILE_SUCCESS,
        profileData,
      });
    })
    .catch((error: any): void => {
      dispatch({ type: types.USER_PROFILE_ERROR, error });
    });
};

const getUserList = (): any => (dispatch: any): any => {
  dispatch({ type: types.USER_LIST_REQUEST });
  return UserService.getUserList()
    .then((users: { content: Array<UserType> }): void => {
      dispatch({ type: types.USER_LIST_SUCCESS, users });
    })
    .catch((error: any): void => {
      dispatch({ type: types.USER_LIST_FAILURE, error });
    });
};

const getUserItem = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.USER_ITEM_REQUEST });
  return UserService.getItem(id)
    .then((user: UserType): any => {
      dispatch({
        type: types.USER_ITEM_SUCCESS,
        user,
      });
      return Promise.resolve(user);
    })
    .catch((error: any): void => {
      dispatch({ type: types.USER_ITEM_FAILURE, error });
    });
};

const getRoles = (): any => (dispatch: any): any => {
  dispatch({ type: types.USER_ROLES_LIST_REQUEST });
  return UserService.getRoles()
    .then((roles: Array<UserRoleType>): void => {
      dispatch({ type: types.USER_ROLES_LIST_SUCCESS, roles });
    })
    .catch((error: any): void => {
      dispatch({ type: types.USER_ROLES_LIST_FAILURE, error });
    });
};

const login = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.USER_LOGIN_REQUEST });
  return UserService.login(data)
    .then((res: any): any => {
      if (res) {
        dispatch({
          type: types.USER_LOGIN_SUCCESS,
        });
        dispatch(getProfile());
        dispatch(getRoles());
      } else {
        dispatch({
          type: types.USER_LOGIN_ERROR,
          error: { message: 'wront input' },
        });
      }
      return Promise.resolve(res);
    })
    .catch((error: any): void => {
      dispatch({ type: types.USER_LOGIN_ERROR, error });
    });
};

const logout = (sessionEnded?: boolean): any => (dispatch: any): void => {
  dispatch({ type: types.USER_LOGOUT_SUCCESS, sessionEnded });
};

const toggleReport = (): any => (dispatch: any): void => {
  dispatch({
    type: types.REPORT_TOGGLE,
  });
};

const createUser = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.USER_CREATE_REQUEST });
  return UserService.createUser(data)
    .then((user: any): any => {
      dispatch({
        type: types.USER_CREATE_SUCCESS,
        user,
      });
      dispatch(getUserList());
      return Promise.resolve(user);
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.USER_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.USER_CREATE_FAILURE, error });
      }
    });
};

const updateUser = (data: any, id: number): any => (dispatch: any): any => {
  dispatch({ type: types.USER_UPDATE_REQUEST });
  return UserService.updateUser(data, id)
    .then((user: any): any => {
      dispatch({
        type: types.USER_UPDATE_SUCCESS,
        user,
      });
      dispatch(getUserList());
      return Promise.resolve(user);
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.USER_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.USER_UPDATE_FAILURE, error });
      }
    });
};

const removeUser = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.USER_REMOVE_REQUEST });
  UserService.removeUser(id)
    .then((user: any): void => {
      dispatch({ type: types.USER_REMOVE_SUCCESS, user, id });
    })
    .catch((error: any): void => {
      dispatch({ type: types.USER_REMOVE_FAILURE, error });
    });
};

const updateProfile = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.USER_PROFILE_UPDATE_REQUEST });
  return UserService.updateProfile(data)
    .then((response: any): void => {
      dispatch({
        type: types.USER_PROFILE_UPDATE_SUCCESS,
        userPrfoile: response,
      });
    })
    .catch((error: any): void => {
      dispatch({ type: types.USER_PROFILE_UPDATE_FAILURE, error });
    });
};

const changePassword = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.USER_CHANGE_PASSWORD_REQUEST });
  return UserService.changePassword(data)
    .then((response: any): void => {
      dispatch({ type: types.USER_CHANGE_PASSWORD_SUCCESS, response });
    })
    .catch((error: any): void => {
      dispatch({ type: types.USER_CHANGE_PASSWORD_FAILURE, error });
    });
};

const openUserForm = (): any => (dispatch: any): void => {
  dispatch({
    type: types.USER_OPEN_FORM,
  });
};

const closeUserForm = (): any => (dispatch: any): void => {
  dispatch({
    type: types.USER_CLOSE_FORM,
  });
};

const openReportSettings = (): any => (dispatch: any): void => {
  dispatch({
    type: types.USER_OPEN_REPORT_SETTINGS,
  });
};

const closeReportSettings = () => (dispatch: any): void => {
  dispatch({
    type: types.USER_CLOSE_REPORT_SETTINGS,
  });
};

const removeClientCache = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.REMOVE_CLIENT_CACHE_REQUEST });
  return UserService.removeClientCache(id)
    .then((): any => {
      dispatch({ type: types.REMOVE_CLIENT_CACHE_SUCCESS });
      return Promise.resolve();
    })
    .catch((error: any): any => {
      dispatch({ type: types.REMOVE_CLIENT_CACHE_FAILURE, error });
      return Promise.reject();
    });
};

const removeAllClientsCache = (): any => (dispatch: any): any => {
  dispatch({ type: types.REMOVE_ALL_CLIENTS_CACHE_REQUEST });
  return UserService.removeAllClientsCache()
    .then((): any => {
      dispatch({ type: types.REMOVE_ALL_CLIENTS_CACHE_SUCCESS });
      return Promise.resolve();
    })
    .catch((error: any): any => {
      dispatch({ type: types.REMOVE_CLIENT_CACHE_FAILURE, error });
      return Promise.reject();
    });
};

const openConfirmDialog = (): any => (dispatch: any): void => {
  dispatch({
    type: types.USER_OPEN_CONFIRM_DIALOG,
  });
};

const closeConfirmDialog = (): any => (dispatch: any): void => {
  dispatch({
    type: types.USER_CLOSE_CONFIRM_DIALOG,
  });
};

const UserActions = {
  getProfile,
  getUserItem,
  login,
  toggleReport,
  logout,
  getRoles,
  createUser,
  updateUser,
  getUserList,
  updateProfile,
  changePassword,
  openUserForm,
  closeUserForm,
  removeUser,
  removeAllClientsCache,
  removeClientCache,
  openConfirmDialog,
  closeConfirmDialog,
  openReportSettings,
  closeReportSettings,
};

export default UserActions;
