import React, { useEffect, useState, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Tooltip from '@material-ui/core/Tooltip';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ClientsIcon from '@material-ui/icons/PeopleOutline';
import AccountsIcon from '@material-ui/icons/AccountCircleOutlined';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import Dropdown from '../../components/Dropdown';
import DataTable from '../../containers/DataTable';
import Dates from '../../constants/Date';
import { getCurrentMonth, getCurrentYear } from '../../helpers/utils';
import styles from './styles';
import GearLoader from '../../containers/Client/Loaders/infinity.gif';
import { SecurityOverviewType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  securitiesOverview: SecurityOverviewType;
  getSecuritiesOverview: (year: number, month: number) => void;
  loading: boolean;
}

const SecuritiesOverview = (props: Props): ReactElement => {
  const { getSecuritiesOverview, securitiesOverview, loading } = props;
  const [step, setStep] = useState<number>(1);
  const [selectedSecurity, setSelectedSecurity] = useState<any>(undefined);
  const [selectedClient, setSelectedClient] = useState<any>(undefined);
  const [selectedYear, setSelectedYear] = useState<number>(
    getCurrentMonth() === 0 ? getCurrentYear() - 1 : getCurrentYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    getCurrentMonth() === 0 ? 12 : getCurrentMonth()
  );
  const [rows, setRows] = useState<any[]>([]);
  const formatNumber = (number: number | string): string => {
    const newNumber = Number(number).toFixed(0);
    if (newNumber !== 'NaN') {
      return newNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return '';
  };

  useEffect(() => {
    if (!securitiesOverview) return;
    const newRows = [
      {
        ...securitiesOverview.total,
        securityName: securitiesOverview.total.name,
        value: formatNumber(securitiesOverview.total.value),
        valueNumber: securitiesOverview.total.value,
        clientsCount: securitiesOverview.rows
          .map((each: any): number => Object.keys(each.rows).length)
          .reduce((sum: number, current: number): number => sum + current),
        style: { fontWeight: 600 },
        id: 'total',
      },
    ];
    securitiesOverview.rows.forEach((each: any): void => {
      newRows.push({
        ...each,
        clientsArray: each.rows,
        clientsCount: Object.keys(each.rows).length,
        id: each.securityId,
        value: formatNumber(each.total.value),
        valueNumber: each.total.value,
      });
    });
    setRows(newRows);
  }, [securitiesOverview]);

  const classes = useStyles();
  useEffect(() => {
    getSecuritiesOverview(selectedYear, selectedMonth);
  }, [selectedMonth, selectedYear, getSecuritiesOverview]);

  const goBack = (): void => {
    if (step === 3) setStep(2);
    if (step === 2) setStep(1);
  };

  const firstStepColumns = [
    {
      title: 'Name',
      name: 'securityName',
      sortable: true,
      type: 'string',
      sortField: 'securityName',
    },
    {
      title: 'Clients',
      name: 'clientsCount',
      style: { textAlign: 'right', justifyContent: 'flex-end' },
      rowStyle: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: 32,
      },
      sortable: true,
      type: 'number',
      sortField: 'clientsCount',
    },
    {
      title: 'Value',
      name: 'value',
      style: { textAlign: 'right', justifyContent: 'flex-end' },
      rowStyle: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: 32,
      },
      sortable: true,
      type: 'number',
      sortField: 'valueNumber',
    },
  ];

  const secondStepColumns = [
    {
      title: 'Name',
      name: 'clientName',
      sortable: true,
      type: 'string',
      sortField: 'clientName',
    },
    {
      title: 'Accounts',
      name: 'accountsCount',
      style: { textAlign: 'right', justifyContent: 'flex-end' },
      rowStyle: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: 32,
      },
      sortable: true,
      type: 'number',
      sortField: 'accountsCount',
    },
    {
      title: 'Value',
      name: 'value',
      style: { textAlign: 'right', justifyContent: 'flex-end' },
      rowStyle: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: 32,
      },
      sortable: true,
      type: 'number',
      sortField: 'valueNumber',
    },
  ];

  const ThirdStepColumns = [
    {
      title: 'Name',
      name: 'accountName',
      sortable: true,
      sortField: 'accountName',
      type: 'string',
    },
    {
      title: 'Value',
      name: 'value',
      style: { textAlign: 'right', justifyContent: 'flex-end' },
      rowStyle: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: 32,
      },
      sortable: true,
      sortField: 'valueNumber',
      type: 'number',
    },
  ];

  const selectSecurity = (row: any): void => {
    if (row.id === 'total') return;
    const foundSecurity = rows.find(each => each.id === row.id);
    const newRows = [
      {
        id: 'total',
        clientName: 'Total',
        value: formatNumber(foundSecurity.total.value),
        valueNumber: foundSecurity.total.value,
        accountsCount: Object.keys(foundSecurity.rows)
          .map(each => foundSecurity.rows[each].rows.length)
          .reduce((sum, current) => sum + current),
        style: { fontWeight: 600 },
      },
    ];
    Object.keys(foundSecurity.rows)
      .map(each => ({
        ...foundSecurity.rows[each],
        accountsCount: foundSecurity.rows[each].rows.length,
        clientName: each,
        id: each,
        value: formatNumber(foundSecurity.rows[each].total.value),
        valueNumber: foundSecurity.rows[each].total.value,
      }))
      .forEach(each => newRows.push(each));
    setSelectedSecurity({
      ...foundSecurity,
      rows: newRows,
    });
    setStep(2);
  };

  const selectClient = (row: any): void => {
    if (row.id === 'total') return;
    const foundClient = selectedSecurity.rows.find(
      (each: any): any =>
        each.clientName === row.clientName &&
        each.accountsCount === row.accountsCount
    );
    const newRows = [
      {
        id: 'total',
        accountName: 'Total',
        value: formatNumber(foundClient.total.value),
        valueNumber: foundClient.total.value,
        style: { fontWeight: 600 },
      },
    ];
    foundClient.rows
      .map((each: any): any => ({
        ...each,
        value: formatNumber(each.value),
        valueNumber: each.value,
        accountName: each.name,
        id: each.name,
      }))
      .forEach((each: any): any => newRows.push(each));
    setSelectedClient({
      ...foundClient,
      clientName: foundClient.clientName,
      rows: newRows,
    });
    setStep(3);
  };

  const previousMonth = (): void => {
    if (selectedMonth === 1) {
      setSelectedMonth(12);
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };

  const nextMonth = (): void => {
    if (selectedMonth === 12) {
      setSelectedMonth(1);
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
  };

  const selectMonth = (value: number): void => {
    const month = Dates.months.find((m: any): any => m.value === value);
    if (month && month.valueNumber) setSelectedMonth(month.valueNumber);
  };

  const selectYear = (value: number): void => {
    const year = Dates.years.find((y: any): any => y.value === value);
    if (year && year.valueNumber) setSelectedYear(year.valueNumber);
  };

  const exportSecuritiesTable = (): void => {
    const csvData = new Blob([securitiesOverview.export], {
      type: 'text/csv;charset=utf-8;',
    });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute(
      'download',
      securitiesOverview.fileName || 'SecuritiesOverview.csv'
    );
    tempLink.click();
  };

  const exportClientsTable = (): void => {
    const csvData = new Blob([selectedSecurity.export], {
      type: 'text/csv;charset=utf-8;',
    });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute(
      'download',
      selectedSecurity.fileName || 'Securities_ClientsOverview.csv'
    );
    tempLink.click();
  };

  const exportAccountsTable = (): void => {
    const csvData = new Blob([selectedClient.export], {
      type: 'text/csv;charset=utf-8;',
    });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute(
      'download',
      selectedClient.fileName || 'Securities_Clients_AccountsOverview.csv'
    );
    tempLink.click();
  };

  const firstStepDetails = (row: any): any =>
    row.id === 'total'
      ? []
      : [
          {
            icon: <ClientsIcon fontSize="small" />,
            title: 'Clients',
            onClick: (): void => selectSecurity(row),
            disabled: row.clientsCount === 0,
          },
        ];

  const secondStepDetails = (row: any): any =>
    row.id === 'total'
      ? []
      : [
          {
            icon: <AccountsIcon fontSize="small" />,
            title: 'Accounts',
            onClick: (): void => selectClient(row),
            disabled: row.clientsCount === 0,
          },
        ];

  const monthInitValue = Dates.months.find(
    m => m.valueNumber === selectedMonth
  );
  const yearInitValue = Dates.years.find(y => y.valueNumber === selectedYear);

  return (
    <div
      style={{
        height: '100%',
        backgroundColor: '#eff0f1',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {loading && (
        <div className={classes.loaderContainer} style={{ opacity: 0.35 }}>
          <img src={GearLoader} alt="" style={{ width: 120 }} />
        </div>
      )}
      <Toolbar className={classes.toolbar}>
        <div style={{ display: 'inherit', flex: 1 }}>
          {step !== 1 && (
            <Tooltip title="Back">
              <IconButton
                onClick={goBack}
                classes={{ root: classes.backRotButton }}
                color="inherit"
              >
                {' '}
                <LeftIcon />{' '}
              </IconButton>
            </Tooltip>
          )}
          <div className={classes.titleContainer}>
            {step === 1 && (
              <Typography
                className={classnames(classes.title1)}
                id="tableTitle"
                variant="h2"
              >
                Securities Overview
              </Typography>
            )}
            {step === 2 && (
              <>
                <Typography
                  className={classnames(classes.breadCrumbItem)}
                  id="tableTitle"
                  component="a"
                  variant="h2"
                  onClick={(): void => setStep(1)}
                >
                  Securities Overview
                </Typography>
                <NavigateNextIcon
                  fontSize="small"
                  style={{
                    marginTop: '15px',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                />
                <Typography className={classnames(classes.title1)} variant="h2">
                  {selectedSecurity.securityName}
                </Typography>
              </>
            )}
            {step === 3 && (
              <>
                <Typography
                  className={classnames(classes.breadCrumbItem)}
                  id="tableTitle"
                  component="a"
                  variant="h2"
                  onClick={(): void => setStep(1)}
                >
                  Securities Overview
                </Typography>
                <NavigateNextIcon
                  fontSize="small"
                  style={{
                    marginTop: '15px',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                />
                <Typography
                  className={classnames(classes.breadCrumbItem)}
                  id="tableTitle"
                  component="a"
                  variant="h2"
                  onClick={(): void => setStep(2)}
                >
                  {selectedSecurity.securityName}
                </Typography>
                <NavigateNextIcon
                  fontSize="small"
                  style={{
                    marginTop: '15px',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                />
                <Typography className={classnames(classes.title1)} variant="h2">
                  {selectedClient.clientName}
                </Typography>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'inherit',
            marginRight: 15,
          }}
        >
          <IconButton
            className={classes.iconButton}
            onClick={previousMonth}
            classes={{ root: classes.arrowButtonRoot }}
            color="primary"
          >
            <LeftIcon />
          </IconButton>
          <div className={classes.yearPickerContainer}>
            <Dropdown
              onSelect={selectMonth}
              suggestions={Dates.months}
              initValue={monthInitValue}
              accountButtonClass={classes.select}
            />
          </div>
          <div className={classes.yearPickerContainer}>
            <Dropdown
              onSelect={selectYear}
              suggestions={Dates.years}
              initValue={yearInitValue}
              accountButtonClass={classes.select}
            />
          </div>

          <IconButton
            className={classes.iconButton}
            onClick={nextMonth}
            classes={{ root: classes.arrowButtonRoot }}
            color="primary"
          >
            <RightIcon />
          </IconButton>
        </div>
      </Toolbar>
      <div style={{ height: '100%' }}>
        {step === 1 && (
          <DataTable
            columns={firstStepColumns}
            noMargin
            rows={rows || []}
            details={firstStepDetails}
            entity="Clients Overview"
            entities="Clients Overview"
            hasToolbar={false}
            hasEditAndDelete
            hasDetails
            hasExportButton
            onExport={exportSecuritiesTable}
            onClickRow={(event: any, data: any): void => selectSecurity(data)}
          />
        )}
        {step === 2 && (
          <DataTable
            hasEditAndDelete
            columns={secondStepColumns}
            noMargin
            rows={selectedSecurity.rows || []}
            details={secondStepDetails}
            entity="Clients Overview"
            entities="Clients Overview"
            hasToolbar={false}
            hasDetails
            hasExportButton
            onExport={exportClientsTable}
            onClickRow={(event: any, data: any): void => selectClient(data)}
          />
        )}
        {step === 3 && (
          <DataTable
            columns={ThirdStepColumns}
            noMargin
            rows={selectedClient.rows || []}
            entity="Clients Overview"
            entities="Clients Overview"
            hasEditAndDelete
            hasExportButton
            onExport={exportAccountsTable}
            hasToolbar={false}
          />
        )}
      </div>
    </div>
  );
};

SecuritiesOverview.propTypes = {};

SecuritiesOverview.defaultProps = {
  securitiesOverview: undefined,
  loading: false,
};

export default SecuritiesOverview;
