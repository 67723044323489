import * as types from '../constants/RiskClass';

const RiskClass = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.RISK_CLASSES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.RISK_CLASSES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        riskClasses: action.riskClasses,
      };
    case types.RISK_CLASSES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.RISK_CLASS_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.RISK_CLASS_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        riskClass: action.riskClass,
      };
    case types.RISK_CLASS_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.RISK_CLASS_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.RISK_CLASS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.riskClass.reference
          ? action.riskClass.reference
          : action.riskClass,
      };
    case types.RISK_CLASS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.RISK_CLASS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.RISK_CLASS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.riskClass.reference
          ? action.riskClass.reference
          : action.riskClass,
      };
    case types.RISK_CLASS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.RISK_CLASS_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.RISK_CLASS_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        reference: 'deleted',
        riskClasses: {
          ...state.riskClasses,
          content: state.riskClasses.content.filter(
            (ac: any): any => ac.id !== action.id
          ),
        },
      };
    case types.RISK_CLASS_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default RiskClass;
