export const ASSET_TABLE_REQUEST = 'ASSET_TABLE_REQUEST';
export const ASSET_TABLE_SUCCESS = 'ASSET_TABLE_SUCCESS';
export const ASSET_TABLE_FAILURE = 'ASSET_TABLE_FAILURE';
export const START_DATE_REQUEST = 'START_DATE_REQUEST';
export const START_DATE_SUCCESS = 'START_DATE_SUCCESS';
export const START_DATE_FAILURE = 'START_DATE_FAILURE';
export const ASSET_VALUE_REQUEST = 'ASSET_VALUE_REQUEST';
export const ASSET_VALUE_SUCCESS = 'ASSET_VALUE_SUCCESS';
export const ASSET_VALUE_FAILURE = 'ASSET_VALUE_FAILURE';
export const ASSET_VALUE_REMOVE_REQUEST = 'ASSET_VALUE_REMOVE_REQUEST';
export const ASSET_VALUE_REMOVE_SUCCESS = 'ASSET_VALUE_REMOVE_SUCCESS';
export const ASSET_VALUE_REMOVE_FAILURE = 'ASSET_VALUE_REMOVE_FAILURE';
export const ASSET_VALUE_COMMENT_REQUEST = 'ASSET_VALUE_COMMENT_REQUEST';
export const ASSET_VALUE_COMMENT_SUCCESS = 'ASSET_VALUE_COMMENT_SUCCESS';
export const ASSET_VALUE_COMMENT_FAILURE = 'ASSET_VALUE_COMMENT_FAILURE';
export const CLEAN_LOADING = 'CLEAN_LOADING';
