import EnvironmentService from '../services/EnvironmentServices';
import * as types from '../constants/Environment';
import { EnvironmentType } from '../Types';

const getEnvironmentsList = (): any => (dispatch: any): void => {
  dispatch({ type: types.ENVIRONMENTS_LIST_REQUEST });
  EnvironmentService.getEnvironmentsList()
    .then((environments: Array<EnvironmentType>): void => {
      dispatch({ type: types.ENVIRONMENTS_LIST_SUCCESS, environments });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ENVIRONMENTS_LIST_FAILURE, error });
    });
};

const getEnvironmentItem = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ENVIRONMENT_ITEM_REQUEST });
  EnvironmentService.getEnvironmentItem(id)
    .then((environment: EnvironmentType): void => {
      dispatch({ type: types.ENVIRONMENT_ITEM_SUCCESS, environment });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ENVIRONMENT_ITEM_FAILURE, error });
    });
};

const updateEnvironment = (id: number, data: any): any => (
  dispatch: any
): void => {
  dispatch({ type: types.ENVIRONMENT_UPDATE_REQUEST });
  EnvironmentService.updateEnvironment(id, data)
    .then((environment: any): void => {
      dispatch({ type: types.ENVIRONMENT_UPDATE_SUCCESS, environment });
      dispatch(getEnvironmentsList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ENVIRONMENT_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ENVIRONMENT_UPDATE_FAILURE, error });
      }
    });
};

const createEnvironment = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.ENVIRONMENT_CREATE_REQUEST });
  EnvironmentService.createEnvironment(data)
    .then((environment: any): void => {
      dispatch({ type: types.ENVIRONMENT_CREATE_SUCCESS, environment });
      dispatch(getEnvironmentsList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ENVIRONMENT_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ENVIRONMENT_CREATE_FAILURE, error });
      }
    });
};

const removeEnvironment = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ENVIRONMENT_REMOVE_REQUEST });
  EnvironmentService.removeEnvironment(id)
    .then((environment: any): void => {
      dispatch({ type: types.ENVIRONMENT_REMOVE_SUCCESS, environment, id });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ENVIRONMENT_REMOVE_FAILURE, error });
    });
};

const getCurrentEnvironment = (): any => (dispatch: any): void => {
  dispatch({ type: types.ENVIRONMENT_CURRENT_REQUEST });
  EnvironmentService.getCurrentEnvironment()
    .then((currentEnvironment: EnvironmentType): void => {
      dispatch({
        type: types.ENVIRONMENT_CURRENT_SUCCESS,
        currentEnvironment,
      });
    })
    .catch((error: any): void => {
      console.log(error)
      dispatch({ type: types.ENVIRONMENT_CURRENT_FAILURE, error });
    });
};

const EnvironmentActions = {
  getEnvironmentsList,
  getEnvironmentItem,
  createEnvironment,
  updateEnvironment,
  removeEnvironment,
  getCurrentEnvironment,
};

export default EnvironmentActions;
