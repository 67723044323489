import * as types from '../constants/AssetManager';

const AssetManager = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.ASSET_MANAGERS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ASSET_MANAGERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        assetManagers: action.assetManagers,
      };
    case types.ASSET_MANAGERS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ASSET_MANAGER_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ASSET_MANAGER_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        assetManager: action.assetManager,
      };
    case types.ASSET_MANAGER_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ASSET_MANAGER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ASSET_MANAGER_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.assetManager,
      };
    case types.ASSET_MANAGER_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.ASSET_MANAGER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ASSET_MANAGER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.assetManager,
      };
    case types.ASSET_MANAGER_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.ASSET_MANAGER_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ASSET_MANAGER_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        reference: 'deleted',
        assetManagers: {
          ...state.assetManagers,
          content: state.assetManagers.content.filter(
            (am: any): any => am.id !== action.id
          ),
        },
      };
    case types.ASSET_MANAGER_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default AssetManager;
