import AccountCodeService from '../services/AccountCodeService';
import * as types from '../constants/AccountCode';
import { AccountCodeType } from '../Types';

const getAccountCodesList = (): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_CODES_LIST_REQUEST });
  AccountCodeService.list()
    .then((accountCodes: { content: Array<AccountCodeType> }): void => {
      dispatch({ type: types.ACCOUNT_CODES_LIST_SUCCESS, accountCodes });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_CODES_LIST_FAILURE, error });
    });
};

const getAccountCodeItem = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_CODE_ITEM_REQUEST });
  AccountCodeService.item(id)
    .then((accountCode: AccountCodeType): void => {
      dispatch({ type: types.ACCOUNT_CODE_ITEM_SUCCESS, accountCode });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_CODE_ITEM_FAILURE, error });
    });
};

const createAccountCode = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.ACCOUNT_CODE_CREATE_REQUEST, data });
  AccountCodeService.create(data)
    .then((accountCode: any): void => {
      dispatch({ type: types.ACCOUNT_CODE_CREATE_SUCCESS, accountCode });
      dispatch(getAccountCodesList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ACCOUNT_CODE_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ACCOUNT_CODE_CREATE_FAILURE, error });
      }
    });
};

const updateAccountCode = (data: any, id: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.ACCOUNT_CODE_UPDATE_REQUEST });
  AccountCodeService.update(data, id)
    .then((accountCode: any): void => {
      dispatch({ type: types.ACCOUNT_CODE_UPDATE_SUCCESS, accountCode });
      dispatch(getAccountCodesList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ACCOUNT_CODE_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ACCOUNT_CODE_UPDATE_FAILURE, error });
      }
    });
};

const removeAccountCode = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_CODE_REMOVE_REQUEST });
  AccountCodeService.remove(id)
    .then((accountCode: any): void => {
      dispatch({ type: types.ACCOUNT_CODE_REMOVE_SUCCESS, accountCode, id });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_CODE_REMOVE_FAILURE, error });
    });
};

const AccountCodeActions = {
  getAccountCodesList,
  getAccountCodeItem,
  createAccountCode,
  updateAccountCode,
  removeAccountCode,
};

export default AccountCodeActions;
