import React, { useState, useEffect, ReactElement } from 'react';
import Iframe from 'react-iframe';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styles from './styles';
import { SimulationType } from '../../../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  selectedRows?: Array<number>;
  selectedColumns?: Array<number>;
  month: number;
  year: number;
  clientId: number;
  showNotification: () => void;
  getSimulation?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>
  ) => Promise<SimulationType>;
  showComponent?: boolean;
}

const Simulation = (props: Props): ReactElement => {
  const {
    selectedRows,
    selectedColumns,
    month,
    year,
    clientId,
    showNotification,
    getSimulation,
    showComponent,
  } = props;
  const [simulationDate, setSimulationData] = useState<
    SimulationType | undefined
  >(undefined);
  const classes = useStyles();

  const getSimulationData = (): void => {
    setSimulationData(undefined);
    if (getSimulation)
      getSimulation(
        clientId,
        year,
        month,
        selectedRows || [],
        selectedColumns || []
      )
        .then((simulation: SimulationType): void => {
          setSimulationData(simulation);
        })
        .catch((): any => showNotification && showNotification());
  };

  useEffect(() => {
    if (!showComponent) return;
    getSimulationData();
  }, [month, year, selectedColumns, selectedRows, clientId, showComponent]);

  return (
    <div
      className={classes.root}
      style={{ display: !showComponent ? 'none' : 'inherit' }}
    >
      {simulationDate !== undefined && (
        <Iframe
          url=""
          src={`${simulationDate.URI}?value=${simulationDate.value}&return=${simulationDate.returnVal}&risk=${simulationDate.risk}`}
          height="1000px"
          className={classes.iframe}
          frameBorder={0}
          styles={{
            height: '1000px !important',
          }}
        />
      )}
    </div>
  );
};

Simulation.defaultProps = {
  selectedColumns: [],
  selectedRows: [],
  showNotification: undefined,
};

export default Simulation;
