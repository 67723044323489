import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BenchmarkActions from '../../actions/BenchmarkActions';
import DialogActions from '../../actions/DialogActions';
import ExportActions from '../../actions/ExportActions';
import BenchmarkItem from './BenchmarkItem';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    { ...DialogActions, ...BenchmarkActions, ...ExportActions },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  benchmarkValues: state.Benchmark.benchmarkValues,
  deleted: state.Benchmark.deleted,
  success: state.Benchmark.success,
  error: state.Benchmark.error,
  loading: state.Benchmark.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(BenchmarkItem);
