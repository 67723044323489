import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const list = (clientId: number): any =>
  Request('account', 'GET', null, { clientid: clientId })
    .then(ErrorHandler)
    .then((account: any): any => account);

const item = (id: number): any =>
  Request(`account/${id}`, 'GET')
    .then(ErrorHandler)
    .then((account: any): any => account);

const remove = (id: number): any =>
  Request(`account/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((account: any): any => account);

const removeSecurity = (accountId: number, id: number): any =>
  Request(`account/${accountId}/security/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((accountSecurity: any): any => accountSecurity);

const removeSecurityLifetime = (id: number): any =>
  Request(`account/security/lifetime/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((inputSecurityLifetime: any): any => inputSecurityLifetime);

const create = (data: any): any =>
  Request('account', 'POST', data)
    .then(ErrorHandler)
    .then((account: any): any => account);

const update = (data: any, id: number): any =>
  Request(`account/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((account: any): any => account);

const securities = (id: number, data: any): any =>
  Request(`account/${id}/security`, 'POST', data)
    .then(ErrorHandler)
    .then((accountSecurities: any): any => accountSecurities);

const allSecurities = (id: number): any =>
  Request(`account/${id}/security/all`, 'GET')
    .then(ErrorHandler)
    .then((allAccountSecurities: any): any => allAccountSecurities);

const getAccountSecurityStartDate = (id: number): any =>
  Request(`account/${id}/security/startdate`, 'GET')
    .then(ErrorHandler)
    .then((date: any): any => date);

const getAccountStartDate = (id: number): any =>
  Request(`account/${id}/startdate`, 'GET')
    .then(ErrorHandler)
    .then((date: any): any => date);

const securityLifetime = (accountId: number, securityId: number): any =>
  Request(`account/${accountId}/security/lifetime`, 'GET', null, {
    securityId,
  })
    .then(ErrorHandler)
    .then((securityLifetimes: any): any => securityLifetimes);

const values = (id: number, year: number, month: number): any =>
  Request(`account/${id}/values`, 'GET', null, {
    year,
    month,
  })
    .then(ErrorHandler)
    .then((accountValues: any): any => accountValues);

const saveValues = (data: any): any =>
  Request('account/values', 'POST', data)
    .then(ErrorHandler)
    .then((accountValues: any): any => accountValues);

const createSecurityLifetime = (data: any): any =>
  Request('account/security/lifetime', 'POST', data)
    .then(ErrorHandler)
    .then((accountSecurityLifetime: any): any => ({
      accountSecurityLifetime,
      objectId: data.id,
    }));

const updateSecurityLifetime = (data: any, id: number): any =>
  Request(`account/security/lifetime/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((accountSecurityLifetime: any): any => ({
      accountSecurityLifetime,
      objectId: data.id,
    }));

const attachSecurity = (data: any): any =>
  Request('account/security', 'POST', data)
    .then(ErrorHandler)
    .then((accountSecurity: any): any => accountSecurity);

const checkToRemoveAccount = (id: number): any =>
  Request(`account/hasvalue/${id}`, 'GET')
    .then(ErrorHandler)
    .then((hasValueResponse: any): any => hasValueResponse);

const checkToRemoveAccountSecurity = (
  accountId: number,
  securityId: number
): any =>
  Request(`account/${accountId}/security/hasvalue/${securityId}`, 'GET')
    .then(ErrorHandler)
    .then((hasValueResponse: any): any => hasValueResponse);

const AccountService = {
  list,
  item,
  create,
  update,
  remove,
  securities,
  attachSecurity,
  values,
  saveValues,
  createSecurityLifetime,
  updateSecurityLifetime,
  securityLifetime,
  removeSecurityLifetime,
  removeSecurity,
  getAccountSecurityStartDate,
  getAccountStartDate,
  allSecurities,
  checkToRemoveAccount,
  checkToRemoveAccountSecurity,
};

export default AccountService;
