import * as types from '../constants/Environment';

const Environment = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.ENVIRONMENTS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ENVIRONMENTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        environments: action.environments,
      };
    case types.ENVIRONMENTS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ENVIRONMENT_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ENVIRONMENT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.environment.reference,
      };
    case types.ENVIRONMENT_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.ENVIRONMENT_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ENVIRONMENT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.environment.reference,
      };
    case types.ENVIRONMENT_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.ENVIRONMENT_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ENVIRONMENT_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        environment: action.environment,
      };
    case types.ENVIRONMENT_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ENVIRONMENT_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ENVIRONMENT_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        environment: action.environment,
        environments: state.environments.filter(
          (e: any): any => e.id !== action.id
        ),
      };
    case types.ENVIRONMENT_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    case types.ENVIRONMENT_CURRENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ENVIRONMENT_CURRENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentEnvironment: action.currentEnvironment,
      };
    case types.ENVIRONMENT_CURRENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default Environment;
