import React, {
  useState,
  useEffect,
  useRef,
  ReactElement,
  useLayoutEffect,
} from 'react';
import classnames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styles from './styles';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  valueItem: { name: string; value: number | string };
  handleChangeValues: (name: string, value: number) => void;
  type: string;
  focusedIndex: number;
  index: number;
  switchField: (indes: number) => void;
}

const SecurityValue = (props: Props): ReactElement => {
  const {
    valueItem,
    type,
    focusedIndex,
    index,
    handleChangeValues,
    switchField,
  } = props;

  const [value, setValue] = useState<string | number>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  useLayoutEffect(() => {});

  useEffect(() => {
    if (type === 'text') {
      setValue(valueItem.value);
    } else {
      setValue(
        valueItem.value === 0
          ? ''
          : (Math.round(Number(valueItem.value) * 100) / 100).toFixed(2)
      );
    }
    return (): void => {
      if (type === 'text') {
        setValue(valueItem.value);
      } else {
        setValue(
          valueItem.value === 0
            ? ''
            : (Math.round(Number(valueItem.value) * 100) / 100).toFixed(2)
        );
      }
    };
  }, [valueItem]);

  useEffect(() => {
    if (focusedIndex === index && inputRef.current !== null)
      inputRef.current.focus();
  }, [focusedIndex]);

  const handleChange = (event: any): void => {
    const pattern = '^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$';
    const pattern2 = '^[^0-9]+$';
    const newValue = event.target.value;
    if (type !== 'text') {
      if (newValue.match(pattern) || newValue === '') {
        setValue(newValue);
        handleChangeValues(valueItem.name, Number(newValue));
      }
    } else if (newValue.match(pattern2) || newValue === '') {
      setValue(newValue);
      handleChangeValues(valueItem.name, newValue);
    }
  };

  const onKeyPress = (event: any): void => {
    if (event.key === 'Enter') {
      switchField(index);
      if (inputRef.current !== null) inputRef.current.blur();
    }
  };

  return (
    <div className={classnames(classes.valueContainer)}>
      <Typography variant="subtitle2" className={classes.label}>
        {valueItem.name}
      </Typography>
      <TextField
        id={`outlined-${valueItem.name}`}
        inputRef={inputRef}
        value={value}
        onChange={handleChange}
        variant="outlined"
        className={classes.textfield}
        onKeyPress={onKeyPress}
        autoFocus={focusedIndex === index}
        InputProps={{
          classes: {
            input: classes.rootInput,
            root: classes.textfieldRoot,
          },
        }}
        // onBlur={this.setValue}
      />
    </div>
  );
};

SecurityValue.defaultProps = {
  type: undefined,
  focusedIndex: undefined,
};

export default SecurityValue;
