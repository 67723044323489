export const GET_CLIENTS_OVERVIEW_REQUEST = 'GET_CLIENTS_OVERVIEW_REQUEST';
export const GET_CLIENTS_OVERVIEW_SUCCESS = 'GET_CLIENTS_OVERVIEW_SUCCESS';
export const GET_CLIENTS_OVERVIEW_FAILURE = 'GET_CLIENTS_OVERVIEW_FAILURE';
export const GET_SECURITIES_OVERVIEW_REQUEST =
  'GET_SECURITIES_OVERVIEW_REQUEST';
export const GET_SECURITIES_OVERVIEW_SUCCESS =
  'GET_SECURITIES_OVERVIEW_SUCCESS';
export const GET_SECURITIES_OVERVIEW_FAILURE =
  'GET_SECURITIES_OVERVIEW_FAILURE';
export const GET_ASSET_MANAGERS_OVERVIEW_REQUEST =
  'GET_ASSET_MANAGERS_OVERVIEW_REQUEST';
export const GET_ASSET_MANAGERS_OVERVIEW_SUCCESS =
  'GET_ASSET_MANAGERS_OVERVIEW_SUCCESS';
export const GET_ASSET_MANAGERS_OVERVIEW_FAILURE =
  'GET_ASSET_MANAGERS_OVERVIEW_FAILURE';
