import React, { useState, ReactElement } from 'react';
import classnames from 'classnames';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveIcon from '@material-ui/icons/Clear';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/EditOutlined';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import GearLoader from '../../containers/Client/Loaders/infinity.gif';
import {
  ReportingTabTemplateType,
  UpdateReportingTabTemplateType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  onClose: () => void;
  open: boolean;
  update: (
    obj: UpdateReportingTabTemplateType,
    id: number,
    dataId: number
  ) => void;
  success: boolean;
  loading: boolean;
  error: any;
  reportTemplates: Array<ReportingTabTemplateType>;
  clientId: number;
  removeReportTemplate: (id: number) => void;
  deleted: boolean;
}

const DialogForm = (props: Props): ReactElement => {
  const {
    onClose,
    update,
    clientId,
    reportTemplates,
    removeReportTemplate,
    open,
    loading,
    error,
    deleted,
    success,
  } = props;
  const [selectedReportTemplate, setSelectedReportTemplate] = useState<
    ReportingTabTemplateType | undefined
  >(undefined);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');
  const [focused, setFocused] = useState<string>('');
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [hovered, setHovered] = useState<string>('');
  const classes = useStyles();

  const submit = (
    id: number,
    inputTitle: string,
    inputSubtitle: string
  ): void => {
    update({ title: inputTitle, subTitle: inputSubtitle }, clientId, id);
    setFocused('');
    setTitle('');
    setSubtitle('');
    setHovered('');
    setOpenNotification(true);
  };

  const removeTemplate = (id: number): void => {
    const foundTemplate = reportTemplates.find(
      (each: any): any => each.id === id
    );
    setOpenDelete(true);
    setSelectedReportTemplate(foundTemplate);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedReportTemplate) removeReportTemplate(selectedReportTemplate.id);
  };

  const handleDeleteClose = (): void => {
    setOpenDelete(false);
    setSelectedReportTemplate(undefined);
    setIsRemoving(false);
  };

  const actions = [
    {
      label: 'Close',
      onClick: onClose,
      disabled: loading,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      actions={actions}
      title="Edit Report Templates"
      paperClass={classes.reportTemplateDialog}
    >
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            fontSize: 30,
            fontWeight: 800,
          }}
        >
          {' '}
          <img src={GearLoader} alt="" style={{ width: 120 }} />
        </div>
      )}

      <div
        className={classes.formContainer}
        style={{ opacity: loading ? 0.5 : 1 }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '98%',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          {reportTemplates &&
            reportTemplates.map(item => (
              <div key={item.id} style={{ margin: '20px 20px' }}>
                <Card className={classes.card}>
                  <Tooltip title="Remove">
                    <IconButton
                      onClick={(): void => removeTemplate(item.id)}
                      aria-label="Expand"
                      style={{ marginRight: -2, marginLeft: -8 }}
                      classes={{
                        root: classes.iconRoot,
                      }}
                      className={classes.removeIcon}
                    >
                      <RemoveIcon style={{ fontSize: '20px' }} />
                    </IconButton>
                  </Tooltip>

                  <CardActionArea>
                    <CardContent>
                      {focused !== `title-${item.id}` && (
                        <Typography
                          onMouseEnter={(): void =>
                            setHovered(`title-${item.id}`)
                          }
                          onMouseLeave={(): void => setHovered('')}
                          gutterBottom
                          variant="h6"
                          component="h5"
                          style={{
                            color: '#1976d2',
                            marginTop: '-10px',
                          }}
                        >
                          {item.title}
                          {hovered === `title-${item.id}` && (
                            <IconButton
                              className={classes.editButton}
                              onClick={(): void =>
                                setFocused(`title-${item.id}`)
                              }
                              classes={{
                                root: classes.iconButtonRoot,
                              }}
                            >
                              <EditIcon
                                style={{ fontSize: '18px', color: '#1976d2' }}
                              />
                            </IconButton>
                          )}
                        </Typography>
                      )}
                      {focused === `title-${item.id}` && (
                        <TextField
                          id="title"
                          label="Title"
                          value={title || item.title}
                          onChange={(e: any): void => {
                            setTitle(e.target.value);
                          }}
                          variant="outlined"
                          className={classes.editCardField}
                          onBlur={(): void => {
                            setTitle('');
                            setFocused('');
                          }}
                          onKeyPress={(event: any): void => {
                            if (event.key === 'Enter') {
                              submit(item.id, title, item.subtitle);
                            } else if (event.key === 'Escape') {
                              setFocused('');
                              setTitle('');
                            }
                          }}
                          InputProps={{
                            classes: {
                              root: classes.cardFieldInput,
                            },
                          }}
                        />
                      )}
                      {focused !== `subtitle-${item.id}` && (
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          component="p"
                          style={{ height: 30 }}
                          onMouseEnter={(): void =>
                            setHovered(`subtitle-${item.id}`)
                          }
                          onMouseLeave={(): void => setHovered('')}
                        >
                          {item.subtitle}
                          {hovered === `subtitle-${item.id}` && (
                            <IconButton
                              className={classes.editButton}
                              onClick={(): void =>
                                setFocused(`subtitle-${item.id}`)
                              }
                              classes={{
                                root: classes.iconButtonRoot,
                              }}
                            >
                              <EditIcon
                                style={{ fontSize: '18px', color: '#1976d2' }}
                              />
                            </IconButton>
                          )}
                        </Typography>
                      )}
                      {focused === `subtitle-${item.id}` && (
                        <TextField
                          id="subtitle"
                          label="Subtitle"
                          value={subtitle || item.subtitle}
                          onChange={(e: any): void => {
                            setSubtitle(e.target.value);
                          }}
                          variant="outlined"
                          className={classes.editCardField}
                          onBlur={(): void => {
                            setFocused('');
                            setSubtitle('');
                          }}
                          onKeyPress={(event: any): void => {
                            if (event.key === 'Enter') {
                              submit(item.id, item.title, subtitle);
                            } else if (event.key === 'Escape') {
                              setFocused('');
                              setSubtitle('');
                            }
                          }}
                          InputProps={{
                            classes: {
                              root: classes.cardFieldInput,
                            },
                          }}
                        />
                      )}
                    </CardContent>
                    <CardMedia
                      className={classes.media}
                      image={item.imageBase64}
                      title={item.title}
                    />
                  </CardActionArea>
                </Card>
              </div>
            ))}
        </div>
      </div>

      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {isRemoving && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Template removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error: {error && error.messages && error.messages[0]}
            </Typography>
          </div>
        )}
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openNotification && !loading}
        autoHideDuration={3000}
        onClose={(): void => setOpenNotification(false)}
      >
        <div>
          {success && (
            <SnackbarContent
              className={classnames(classes.success)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <CheckCircleIcon
                    className={classnames(
                      classes.snackbarIcon,
                      classes.iconVariant
                    )}
                  />
                  Report Template Updated Successfully
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={(): void => setOpenNotification(false)}
                >
                  <CloseIcon className={classes.snackbarIcon} />
                </IconButton>,
              ]}
            />
          )}
          {!success && (
            <SnackbarContent
              className={classnames(classes.error)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <ErrorIcon
                    className={classnames(
                      classes.snackbarIcon,
                      classes.iconVariant
                    )}
                  />
                  Error: {error && error.message}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={(): void => setOpenNotification(false)}
                >
                  <CloseIcon className={classes.snackbarIcon} />
                </IconButton>,
              ]}
            />
          )}
        </div>
      </Snackbar>
    </Dialog>
  );
};

export default DialogForm;
