import React, { useState, useEffect, ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DetailsIcon from '@material-ui/icons/FilterList';
import FullCheckboxIcon from '@material-ui/icons/Done';
import DataTable from '../../containers/DataTable';
import BenchmarkItem from '../BenchmarkItem';
import Dates from '../../constants/Date';
import Dialog from '../../containers/Dialog';
import DialogForm from './DialogForm';
import EditDialogForm from './EditDialogForm';
import styles from './styles';
import {
  BenchmarkType,
  ExportType,
  UserType,
  MutateBenchmarkType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  getList: () => void;
  removeItem: (id: number) => void;
  createItem: (file: any) => Promise<any>;
  benchmarks: { content: Array<BenchmarkType> };
  loading: boolean;
  success: boolean;
  deleted: boolean;
  error: any;
  openDialog: () => void;
  closeDialog: () => void;
  getUserItem: (id: number) => Promise<UserType>;
  editBenchmark: (id: number, obj: MutateBenchmarkType) => void;
  pushBenchmarks: () => Promise<any>;
  exportBenchmarksTable: () => Promise<ExportType>;
  currentEnvironment: any;
  getSampleBenchmark: () => Promise<any>;
}

const Benchmark = (props: Props): ReactElement => {
  const {
    getList,
    openDialog,
    closeDialog,
    removeItem,
    // pushBenchmarks,
    exportBenchmarksTable,
    benchmarks,
    createItem,
    loading,
    success,
    error,
    deleted,
    editBenchmark,
    getUserItem,
    currentEnvironment,
    ...others
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setopenDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<BenchmarkType | undefined>(
    undefined
  );
  const [model, setModel] = useState<BenchmarkType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  // const [showNotification, setShowNotification] = useState<boolean>(false);
  // const [pushSuccess, setPushSuccess] = useState<boolean>(false);
  const [goDetails, setGoDetails] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    getList();
  }, [getList]);

  const openAdd = (): void => {
    openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const handleEditOpen = (event: any, row: BenchmarkType): void => {
    openDialog();
    setOpenEdit(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setopenDelete(false);
    setIsRemoving(false);
  };

  const handleClose = (): void => {
    closeDialog();
    setOpen(false);
  };

  const handleEditClose = (): void => {
    closeDialog();
    setOpenEdit(false);
  };

  const onRowSelection = (row: BenchmarkType): void => {
    setSelectedRow(row);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) removeItem(selectedRow.id);
  };

  const getDate = (dateInput: string): string => {
    if (dateInput) {
      const date = new Date(dateInput);
      const year = date.getFullYear();
      const monthObj = Dates.months.find(
        each => each.valueNumber - 1 === date.getMonth()
      );
      const month = monthObj && monthObj.label;
      return `${month}-${year}`;
    }
    return '';
  };

  const handleDeleteOpen = (event: any, row: BenchmarkType): void => {
    openDialog();
    setopenDelete(true);
    setSelectedRow(row);
  };

  const benchmarkDetails = (e: any, data: BenchmarkType): void => {
    setSelectedRow(data);
    setGoDetails(true);
  };

  const backFromDetails = (): void => {
    setGoDetails(false);
  };

  // const handlePushBenchmarks = (): void => {
  //   pushBenchmarks()
  //     .then((): void => {
  //       setShowNotification(true);
  //       setPushSuccess(true);
  //     })
  //     .catch(() => {
  //       setShowNotification(true);
  //       setPushSuccess(false);
  //     });
  // };

  const exportTable = (): void => {
    exportBenchmarksTable().then((res: ExportType): void => {
      const csvData = new Blob([res.export], {
        type: 'text/csv;charset=utf-8;',
      });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', res.fileName || 'Benchmarks.csv');
      tempLink.click();
    });
  };

  const columns = [
    {
      title: 'Name',
      name: 'name',
      searchable: true,
      sortable: true,
      type: 'string',
      sortField: 'name',
    },
    {
      title: 'First Date',
      name: 'formattedFirstDate',
      sortable: true,
      sortField: 'firstDate',
      type: 'number',
    },
    {
      title: 'Last Date',
      name: 'formattedLastDate',
      sortable: true,
      sortField: 'lastDate',
      type: 'number',
    },
    {
      title: 'Risk Factor',
      name: 'riskFactor',
      style: { textAlign: 'right', justifyContent: 'flex-end' },
      rowStyle: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: 32,
      },
      sortable: true,
      type: 'number',
      sortField: 'riskFactor',
    },
    {
      title: 'Return Factor',
      name: 'returnFactor',
      style: {
        textAlign: 'right',
        justifyContent: 'flex-end',
      },
      rowStyle: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: 32,
      },
      sortable: true,
      type: 'number',
      sortField: 'returnFactor',
    },
    {
      title: 'Global',
      name: 'globalValue',
      style: { justifyContent: 'center', textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
        textAlign: 'center',
      },
      sortable: true,
      sortField: 'global',
      type: 'boolean',
    },
    {
      title: 'Hidden',
      name: 'hiddenValue',
      style: { justifyContent: 'center', textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
        textAlign: 'center',
      },
      sortable: true,
      sortField: 'hidden',
      type: 'boolean',
    },
  ];

  const details = (row: BenchmarkType): any => [
    {
      icon: <DetailsIcon fontSize="small" />,
      title: 'Details',
      onClick: (): void => benchmarkDetails(undefined, row),
    },
  ];

  // const extraButtons = [
  //   {
  //     label: 'Push Benchmarks',
  //     handleClick: handlePushBenchmarks,
  //   },
  // ];

  const editedBenchmarks =
    benchmarks &&
    benchmarks.content &&
    benchmarks.content.map((each: BenchmarkType): any => ({
      ...each,
      returnFactor: `${(Math.round(each.returnFactor * 100) / 100).toFixed(2)}`,
      riskFactor: `${(Math.round(each.riskFactor * 100) / 100).toFixed(2)}`,
      formattedLastDate: getDate(each.lastDate),
      formattedFirstDate: getDate(each.firstDate),
      globalValue: each.global ? (
        <FullCheckboxIcon style={{ marginBottom: '-10px' }} />
      ) : (
        ''
      ),
      hiddenValue: each.hidden ? (
        <FullCheckboxIcon style={{ marginBottom: '-10px' }} />
      ) : (
        ''
      ),
    }));

  const isCurrentEnvironmentMaster = currentEnvironment?.type === 'MASTER';

  const disableEditButton = (row: any): boolean => {
    if (!isCurrentEnvironmentMaster && row.global) return true;

    return false;
  };

  return (
    <div style={{ height: '100%' }}>
      {!goDetails && (
        <div style={{ height: '100%' }}>
          {' '}
          <div style={{ height: '100%' }}>
            <DataTable
              hasAddButton
              hasEditAndDelete
              onAdd={openAdd}
              onRowSelection={onRowSelection}
              columns={columns}
              details={details}
              hasDetails
              onClickRow={benchmarkDetails}
              addButtonLabel="Upload Benchmarks"
              noMargin
              onDelete={handleDeleteOpen}
              onEdit={handleEditOpen}
              rows={editedBenchmarks || []}
              hasExportButton
              hasHighlightBackground
              onExport={exportTable}
              disableEditButton={disableEditButton}
              entity="Benchmarks"
              entities="Benchmarks"
              {...others}
            />
          </div>
          <DialogForm
            open={open}
            onClose={handleClose}
            create={createItem}
            loading={loading}
            success={success}
            error={error}
          />
          <EditDialogForm
            model={model}
            open={openEdit}
            onClose={handleEditClose}
            edit={editBenchmark}
            loading={loading}
            success={success}
            error={error}
            getUserItem={getUserItem}
            isCurrentEnvironmentMaster={isCurrentEnvironmentMaster}
          />
          <Dialog
            open={openDelete}
            onClose={handleDeleteClose}
            title="Warning"
            actions={
              isRemoving
                ? [
                    {
                      label: 'OK',
                      onClick: handleDeleteClose,
                    },
                  ]
                : [
                    {
                      label: 'cancel',
                      onClick: handleDeleteClose,
                      type: 'secondary',
                    },
                    {
                      label: 'Delete',
                      onClick: remove,
                    },
                  ]
            }
          >
            {!isRemoving && (
              <div className={classes.container}>
                <Typography variant="subtitle2" className={classes.text}>
                  Are you sure?
                </Typography>
              </div>
            )}
            {isRemoving && loading && (
              <div className={classes.container}>
                <LinearProgress className={classes.progress} />
              </div>
            )}
            {isRemoving && !loading && deleted && (
              <div className={classes.container}>
                <Typography variant="subtitle2" className={classes.text}>
                  Benchmark file removed successfully.
                </Typography>
              </div>
            )}
            {isRemoving && !loading && !deleted && (
              <div className={classes.container}>
                <Typography variant="subtitle2" className={classes.text}>
                  Error: {error && error.messages && error.messages[0]}
                </Typography>
              </div>
            )}
          </Dialog>{' '}
        </div>
      )}
      {goDetails && (
        <BenchmarkItem benchmark={selectedRow} goBack={backFromDetails} />
      )}
    </div>
  );
};

Benchmark.defaultProps = {
  success: false,
  error: undefined,
  benchmarks: undefined,
  deleted: false,
  loading: false,
};

export default Benchmark;
