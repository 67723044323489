import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SecurityActions from '../../actions/SecurityActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import ExportActions from '../../actions/ExportActions';
import Securities from './Securities';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    { ...SecurityActions, ...DialogActions, ...UserActions, ...ExportActions },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  securities: state.Security.securities,
  deleted: state.Security.deleted,
  security: state.Security.security,
  success: state.Security.success,
  error: state.Security.error,
  loading: state.Security.loading,
  securityValues: state.Security.securityValues,
});

export default connect(mapStateToProps, mapDispatchToProps)(Securities);
