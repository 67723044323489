import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const login = (data: any): any =>
  Request('auth/login', 'POST', data)
    .then(ErrorHandler)
    .then((response: any): any => response);

const profile = (): any =>
  Request('user/profile', 'GET')
    .then(ErrorHandler)
    .then((response: any): any => response);

const getUserList = (): any =>
  Request('user', 'GET')
    .then(ErrorHandler)
    .then((users: any): any => users);

const getItem = (id: number): any =>
  Request(`user/${id}`, 'GET')
    .then(ErrorHandler)
    .then((user: any): any => user);

const getRoles = (): any =>
  Request('user/roles', 'GET')
    .then(ErrorHandler)
    .then((roles: any): any => roles);

const createUser = (data: any): any =>
  Request('user', 'POST', data)
    .then(ErrorHandler)
    .then((response: any): any => response);

const updateUser = (data: any, id:number): any => {
  return Request(`user/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((response: any): any => response);
};

const removeUser = (id: number): any =>
  Request(`user/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((user: any): any => user);

const updateProfile = (data: any): any =>
  Request('user/profile', 'PUT', data)
    .then(ErrorHandler)
    .then((response: any): any => response);

const changePassword = (data: any): any =>
  Request('user/profile/password', 'PUT', data)
    .then(ErrorHandler)
    .then((response: any): any => response);

const removeClientCache = (id: any): any =>
  Request(`client/${id}/cache`, 'DELETE')
    .then(ErrorHandler)
    .then((resp: any): any => resp);

const removeAllClientsCache = (): any =>
  Request(`client/all/cache`, 'DELETE')
    .then(ErrorHandler)
    .then((resp: any): any => resp);

const UserService = {
  getUserList,
  getItem,
  login,
  profile,
  getRoles,
  createUser,
  updateUser,
  updateProfile,
  changePassword,
  removeUser,
  removeClientCache,
  removeAllClientsCache,
};

export default UserService;
