import SelectionProfileService from '../services/SelectionProfileService';
import * as types from '../constants/SelectionProfile';
import { SelectionProfileType, MutateSelectionProfileType } from '../Types';

const getSelectionProfileList = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.SELECTION_PROFILE_LIST_REQUEST });
  SelectionProfileService.getSelectionProfileList(id)
    .then((selectionProfileList: Array<SelectionProfileType>): void => {
      dispatch({
        type: types.SELECTION_PROFILE_LIST_SUCCESS,
        selectionProfileList,
      });
    })
    .catch((error: any): void => {
      dispatch({ type: types.SELECTION_PROFILE_LIST_FAILURE, error });
    });
};

const createSelectionProfile = (data: MutateSelectionProfileType): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SELECTION_PROFILE_CREATE_REQUEST });
  SelectionProfileService.createSelectionProfile(data)
    .then((): void => {
      dispatch({ type: types.SELECTION_PROFILE_CREATE_SUCCESS });
    })
    .catch((error: any): void => {
      dispatch({ type: types.SELECTION_PROFILE_CREATE_FAILURE, error });
    });
};

const removeSelectionProfile = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.SELECTION_PROFILE_REMOVE_REQUEST });
  return SelectionProfileService.removeSelectionProfile(id)
    .then((): any => {
      dispatch({
        type: types.SELECTION_PROFILE_REMOVE_SUCCESS,
        selectionProfileId: id,
      });
      return Promise.resolve();
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.SELECTION_PROFILE_REMOVE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.SELECTION_PROFILE_REMOVE_FAILURE, error });
      }

      return Promise.reject();
    });
};

const SelectionProfileActions = {
  getSelectionProfileList,
  createSelectionProfile,
  removeSelectionProfile,
};

export default SelectionProfileActions;
