import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
      marginLeft: '-9px',
      padding: '0px 0px 30px 0px',
      WebkitAnimation: 'fadein 2s',
      minHeight: 1000,
      flexDirection: 'column',
    },
    topElement: {
      background: 'white',
      height: '258px',
      top: '0px !important',
      zIndex: 1000,
      marginRight: '-1px',
    },
    root1: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '10px',
      width: 'calc(100% - 10px)',
    },
    iframe: {
      width: 'calc(100% - 80px)',
    },
    input: {
      fontSize: '10px',
      lineHeight: '10px',
      padding: '0 20px 0 5px',
      minWidth: 42,
      [theme.breakpoints.down('md')]: {
        fontSize: '9px',
        padding: '0 10px',
      },
      color: '#039be5',
    },
    input2: {
      fontSize: '10px',
      lineHeight: '10px',
      padding: '0 20px 0 5px',
      minWidth: 42,
      [theme.breakpoints.down('md')]: {
        fontSize: '9px',
        padding: '0 10px',
      },
      color: '#039be5',
    },
    selectRoot: {
      height: 25,
    },
    borderClass: {
      borderColor: '#1976d280 !important',
    },
    popLabel: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    dropIcon: {
      right: '0px',
    },
  });

export default styles;
