import React, {
  useState,
  useEffect,
  useRef,
  ReactElement,
  useLayoutEffect,
} from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Person from '@material-ui/icons/Person';
import Add from '@material-ui/icons/Add';
import LinearProgress from '@material-ui/core/LinearProgress';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '../../actions/DialogActions';
import FileActions from '../../actions/FileActions';
import UserActions from '../../actions/UserActions';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import { UserProfileType, UploadResponseType, ClientType, UpdateUserProfilePasswordType, UpdateUserProfileType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  open?: boolean;
  closeUserForm?: () => void;
  success?: boolean;
  error?: any;
  changePassword?: (obj: UpdateUserProfilePasswordType) => Promise<any>;
  updateProfile?: (obj: UpdateUserProfileType) => Promise<any>;
  upload?: (obj: any) => Promise<UploadResponseType>;
  profile?: UserProfileType;
  getProfile?: () => void;
  clients?: { content: Array<ClientType> };
  history: any;
  removeAllClientsCache?: () => Promise<any>;
  openConfirm?: boolean;
  closeConfirmDialog?: () => void;
}

const UserForm = (props: Props): ReactElement => {
  const {
    profile,
    open,
    getProfile,
    closeUserForm,
    upload,
    updateProfile,
    changePassword,
    error,
    success,
    clients,
    history,
    openConfirm,
    closeConfirmDialog,
    removeAllClientsCache,
  } = props;

  const [name, setName] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [imageUID, setImageUID] = useState<string>('');
  const [openCacheNotification, setOpenCacheNotification] = useState<boolean>(
    false
  );
  const [cacheSuccess, setCacheSuccess] = useState<boolean>(false);
  const classes = useStyles();
  const inputReference = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {});

  useEffect(() => {
    if (!profile) return;
    setName(profile.name);
    setImageUID(profile.profilePictureUID);
  }, [profile]);

  useEffect(() => {
    if (getProfile) getProfile();
  }, [getProfile, open]);

  const resetFields = (): void => {
    setOldPassword('');
    setNewPassword('');
    setNewPasswordRepeat('');
    setShowError(false);
    setShowNotification(false);
    setTab(0);
  };

  const onClose = (): void => {
    resetFields();
    if (closeUserForm) closeUserForm();
  };

  const handleFile = (files: any): void => {
    setShowError(false);
    setLoading(true);
    let reader = null;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2000000) {
        setShowError(true);
        setLoading(false);
        return;
      }
      reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        const formData = new FormData();
        formData.set('file', file);
        if (upload)
          upload(formData)
            .then((response: UploadResponseType): void => {
              const newProfile = { name, profilePictureUid: response.uid };
              if (updateProfile)
                updateProfile(newProfile)
                  .then(() => {
                    setLoading(false);
                    if (getProfile) getProfile();
                  })
            .catch((e: any): any => console.warn(e)); //eslint-disable-line
            })
            .catch((e: any): any => {
              console.warn(e); // eslint-disable-line no-console
              setLoading(false);
            });
      };

      if (files && reader) {
        reader.onerror = (e: any): void => {
          console.error('Error: ', e); // eslint-disable-line no-console
        };
      }
    }
  };

  const handleChangePassword = (): void => {
    if (newPassword !== newPasswordRepeat) {
      setShowError(true);
      return;
    }
    const data = { newPassword, oldPassword };
    if (changePassword)
      changePassword(data)
        .then(() => setLoading(false))
      .catch((error: any): any => console.warn(error));//eslint-disable-line
    setShowNotification(true);
  };

  const handleUpdateProfile = (): void => {
    setLoading(true);
    const newProfile = { name, profilePictureUid: imageUID };
    if (updateProfile)
      updateProfile(newProfile)
        .then(() => {
          setLoading(false);
          if (getProfile) getProfile();
        })
      .catch((error: any): any => console.warn(error)); //eslint-disable-line
    setShowNotification(true);
  };

  const submit = (): void => {
    if (tab === 0) {
      handleUpdateProfile();
    } else {
      handleChangePassword();
    }
  };

  const retry = (): void => {
    setShowNotification(false);
  };

  const actions: any = showNotification
    ? [
        {
          label: 'OK',
          onClick: onClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: onClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions.push({
      label: 'Retry',
      onClick: retry,
    });
  }

  return (
    <div>
      <Dialog
        open={open === undefined ? false : open}
        onClose={onClose}
        actions={actions}
        paperClass={!showNotification ? classes.userFormDialogPaper : ''}
        title="Edit Profile"
      >
        {showNotification && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {showNotification && !loading && success && tab === 0 && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Profile Updated successfully.
            </Typography>
          </div>
        )}
        {showNotification && !loading && success && tab === 1 && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Password Changed successfully.
            </Typography>
          </div>
        )}
        {showNotification && !loading && !success && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error:{' '}
              {(error && error.messages && error.messages[0]) || 'Unknown'}
            </Typography>
          </div>
        )}
        {!showNotification && (
          <div className={classes.userformContainer}>
            <Tabs
              value={tab}
              onChange={(e: any, value: number): void => setTab(value)}
              aria-label="Vertical tabs example"
              className={classes.tabs}
              classes={{
                indicator: classes.invisibleIndicator,
                fixed: classes.tabsPadding,
                flexContainer: classes.flexContainer,
              }}
              textColor="primary"
            >
              <Tab
                classes={{
                  selected: classes.selectedTab,
                  root: classes.tabLabelContainer,
                }}
                className={classes.tab}
                label="General"
              />
              <Tab
                classes={{
                  selected: classes.selectedTab,
                  root: classes.tabLabelContainer,
                }}
                className={classes.tab}
                label="Security"
              />
              <Tab
                classes={{
                  selected: classes.selectedTab,
                  root: classes.tabLabelContainer,
                }}
                className={classes.tab}
                label="Clients"
              />
            </Tabs>
            {tab === 0 && (
              <div style={{ width: '100%', padding: '0px 5px' }}>
                <div style={{ display: 'flex' }}>
                  <input
                    id="file"
                    ref={inputReference}
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e: any): void => handleFile(e.target.files)}
                    type="file"
                  />
                  <div>
                    {imageUID && imageUID !== '' && (
                      <div>
                        <Avatar
                          src={
                            window.location.hostname === 'localhost'
                              ? `https://iam2.sahand.cloud/api/file/${imageUID}`
                              : `https://${window.location.hostname}/api/file/${imageUID}`
                          }
                          className={classNames(classes.avatar)}
                        />
                      </div>
                    )}
                    {(!imageUID || imageUID === '') && (
                      <Avatar
                        className={classNames(classes.avatar, classes.noImage)}
                      >
                        <Typography className={classNames(classes.icon2)}>
                          {`${name && name.charAt(0).toUpperCase()}` ||
                            (profile && (
                              <Person className={classes.person} />
                            )) || <Add className={classes.person} />}
                        </Typography>
                      </Avatar>
                    )}
                  </div>
                  <div
                    style={{
                      marginLeft: 50,
                      display: 'flex',
                      marginTop: 50,
                      flexDirection: 'column',
                    }}
                  >
                    <Button
                      className={classes.selectButton}
                      color="primary"
                      onClick={(): void => {
                        if (inputReference.current !== null)
                          inputReference.current.click();
                      }}
                      variant="outlined"
                    >
                      Upload
                    </Button>

                    <Button
                      className={classes.selectButton}
                      color="secondary"
                      onClick={(): void => setImageUID('')}
                      variant="outlined"
                      style={{ marginTop: 20 }}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
                <TextField
                  error={showError && name === ''}
                  id="outlined-name"
                  label="Name"
                  style={{ marginTop: 20 }}
                  value={name}
                  onChange={(e: any): void => setName(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.fullField}
                  helperText={showError && name === '' && 'Name is required'}
                />

                <TextField
                  id="outlined-email"
                  label="Email"
                  style={{ marginTop: 20 }}
                  value={(profile && profile.email) || ''}
                  margin="normal"
                  variant="outlined"
                  disabled
                  className={classes.fullField}
                />
                <TextField
                  id="outlined-username"
                  label="Username"
                  style={{ marginTop: 20 }}
                  value={(profile && profile.username) || ''}
                  margin="normal"
                  variant="outlined"
                  disabled
                  className={classes.fullField}
                />
                <TextField
                  id="outlined-role"
                  label="Role"
                  style={{ marginTop: 20 }}
                  value={
                    (profile &&
                      profile.roles &&
                      profile.roles[0] &&
                      profile.roles[0].name) ||
                    ''
                  }
                  margin="normal"
                  variant="outlined"
                  disabled
                  className={classes.fullField}
                />
              </div>
            )}
            {tab === 1 && (
              <div style={{ width: '100%', padding: '0px 5px' }}>
                <TextField
                  error={showError && oldPassword === ''}
                  id="outlined-old-password"
                  label="Old Password"
                  type="password"
                  value={oldPassword}
                  onChange={(e: any): void => setOldPassword(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.fullField}
                  helperText={
                    showError && oldPassword === '' && 'Name is required'
                  }
                />
                <TextField
                  error={showError && newPassword === ''}
                  id="outlined-new-password"
                  label="New Password"
                  value={newPassword}
                  onChange={(e: any): void => setNewPassword(e.target.value)}
                  margin="normal"
                  type="password"
                  variant="outlined"
                  className={classes.fullField}
                  helperText={
                    showError && newPassword === '' && 'Name is required'
                  }
                />
                <TextField
                  error={showError && newPasswordRepeat === ''}
                  id="outlined-confirm-password"
                  label="Confirm New Password"
                  value={newPasswordRepeat}
                  type="password"
                  onChange={(e: any): void =>
                    setNewPasswordRepeat(e.target.value)
                  }
                  margin="normal"
                  variant="outlined"
                  className={classes.fullField}
                  helperText={showError && name === '' && 'Name is required'}
                />
              </div>
            )}
            {tab === 2 && (
              <div style={{ width: '100%', paddingLeft: '5px' }}>
                {profile && !profile.accessToAllClients && (
                  <div style={{ height: '100%', overflowY: 'auto' }}>
                    {profile &&
                      profile.clients &&
                      profile.clients.map((each: ClientType): any => (
                        <Typography
                          key={each.id}
                          variant="subtitle2"
                          onClick={(): void => {
                            if (closeUserForm) closeUserForm();
                            history.push(`/selected-client/${each.id}`);
                          }}
                          className={classNames(classes.text, classes.link)}
                        >
                          {each.name}
                        </Typography>
                      ))}
                  </div>
                )}
                {profile && profile.accessToAllClients && (
                  <div style={{ height: '100%', overflowY: 'auto' }}>
                    {clients &&
                      clients.content &&
                      clients.content.map(
                        (each: ClientType, idx: number): any => (
                          <Typography
                            key={each.id}
                            variant="subtitle2"
                            onClick={(): void => {
                              if (closeUserForm) closeUserForm();
                              history.push(`/selected-client/${each.id}`);
                            }}
                            className={classNames(
                              classes.clientName,
                              classes.link
                            )}
                          >
                            {`${idx + 1}. ${each.name}`}
                          </Typography>
                        )
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Dialog>
      <Dialog
        open={openConfirm === undefined ? false : openConfirm}
        onClose={closeConfirmDialog || ((): void => {})}
        title="Warning"
        actions={[
          {
            label: 'cancel',
            onClick: closeConfirmDialog,
            type: 'secondary',
          },
          {
            label: 'remove',
            onClick: (): void => {
              if (removeAllClientsCache)
                removeAllClientsCache()
                  .then(() => {
                    setOpenCacheNotification(true);
                    setCacheSuccess(true);
                  })
                  .catch(() => {
                    setOpenCacheNotification(true);
                    setCacheSuccess(false);
                  });
              if (closeConfirmDialog) closeConfirmDialog();
            },
          },
        ]}
      >
        <div className={classes.dailogContainer}>
          <Typography variant="subtitle2" className={classes.text}>
            Are you sure you want to remove all calculation caches of all
            clients?
          </Typography>
        </div>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openCacheNotification}
        autoHideDuration={3000}
        onClose={(): void => setOpenCacheNotification(false)}
      >
        <div>
          {cacheSuccess && (
            <SnackbarContent
              className={classNames(classes.success)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <CheckCircleIcon
                    className={classNames(classes.icon3, classes.iconVariant)}
                  />
                  Client cahce removed successfuly.
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={(): void => setOpenCacheNotification(false)}
                >
                  <CloseIcon className={classes.icon3} />
                </IconButton>,
              ]}
            />
          )}
          {!cacheSuccess && (
            <SnackbarContent
              className={classNames(classes.error)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <ErrorIcon
                    className={classNames(classes.icon3, classes.iconVariant)}
                  />
                  Error: Something went wrong, please report the issue!
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={(): void => setOpenCacheNotification(false)}
                >
                  <CloseIcon className={classes.icon3} />
                </IconButton>,
              ]}
            />
          )}
        </div>
      </Snackbar>
    </div>
  );
};

UserForm.defaultProps = {
  open: false,
  success: false,
  error: undefined,
  profile: undefined,
  clients: undefined,
  history: undefined,
  openConfirm: false,
};

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    { ...UserActions, ...DialogActions, ...FileActions },
    dispatch
  );

const mapStateToProps = (state: any): any => {
  return {
    open: state.User.openForm,
    profile: state.User.profile,
    success: state.User.success,
    error: state.User.error,
    clients: state.Client.clients,
    openConfirm: state.User.openConfirmDialog,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UserForm);
