import React, { useState, useEffect, ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dates from '../../constants/Date';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import {
  UserType,
  UserRoleType,
  UserProfileType,
  CreateUserType,
  UpdateUserType,
  EnvironmentType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  onClose: () => void;
  model: UserType;
  open: boolean;
  update: (obj: UpdateUserType, id: number) => Promise<any>;
  create: (obj: CreateUserType) => void;
  roles: Array<UserRoleType>;
  success: boolean;
  loading: boolean;
  error: any;
  getUserItem: (id: number) => Promise<UserType>;
  profile: UserProfileType;
  getProfile: () => void;
  currentEnvironment: EnvironmentType;
}

const DialogForm = (props: Props): ReactElement => {
  const {
    model,
    open,
    getUserItem,
    onClose,
    update,
    create,
    profile,
    getProfile,
    currentEnvironment,
    loading,
    success,
    roles,
    error,
  } = props;

  const [name, setName] = useState<string>('');
  const [password, setPassword] = useState<string | undefined>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<any>(undefined);
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [modifyDate, setModifyDate] = useState<string>('');
  const [relatedUsername, setRelatedUsername] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [passwordFocused, setPasswordFocused] = useState<boolean>(false);
  const [accessToAllClients, setAccessToAllClients] = useState<boolean>(false);
  const [simulationTab, setSimulationTab] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    if (!model || !open) return;
    setName(model.name);
    setPassword(model.password);
    setEmail(model.email);
    setUsername(model.username);
    setAccessToAllClients(model.accessToAllClients);
    setSimulationTab(model.simulationTab);
    setRole(
      roles &&
        roles.find(
          (r: UserRoleType): any =>
            r && model.roles && model.roles[0] && r.id === model.roles[0].id
        )
    );
  }, [model, open]);

  useEffect(() => {
    if (!model || !open) return;
    const { lastModifiedBy, lastModifiedDate, createdDate } = model;
    if (lastModifiedBy && lastModifiedDate !== undefined) {
      getUserItem(lastModifiedBy).then((user: UserType) => {
        if (lastModifiedDate === createdDate) {
          const date = new Date(lastModifiedDate);
          const monthObj = Dates.months.find(
            each => each.valueNumber === date.getMonth() + 1
          );
          const month = monthObj && monthObj.value;
          const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
          setIsUpdated(false);
          setRelatedUsername(user.name);
          setModifyDate(newModifyDate);
        } else {
          const date = new Date(lastModifiedDate);
          const monthObj = Dates.months.find(
            each => each.valueNumber === date.getMonth() + 1
          );
          const month = monthObj && monthObj.value;
          const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
          setIsUpdated(true);
          setRelatedUsername(user.name);
          setModifyDate(newModifyDate);
        }
      });
    } else {
      setIsUpdated(false);
      setModifyDate('');
      setRelatedUsername('');
    }
  }, [open, model, getUserItem]);

  const resetFields = (): void => {
    setName('');
    setPassword('');
    setEmail('');
    setRole(undefined);
    setShowError(false);
    setShowNotification(false);
    setIsUpdated(false);
    setModifyDate('');
    setRelatedUsername('');
    setUsername('');
    setAccessToAllClients(false);
    setSimulationTab(false);
  };

  const handleClose = (): void => {
    resetFields();
    onClose();
  };

  const canSubmit = (): boolean => {
    if (name === '' || role === undefined) {
      return false;
    }
    return true;
  };

  const submit = (): void => {
    if (canSubmit()) {
      const data = {
        name,
        password,
        email,
        username,
        roles: [role],
        accessToAllClients,
        simulationTab,
      };

      if (model?.id) {
        update(
          {
            ...data,
            clients:
              (model.clients &&
                model.clients.map((each: any): any => ({
                  ...each,
                  refAssetClassSet: {
                    createdBy: each.refAssetClassSet.createdBy,
                    createdDate: each.refAssetClassSet.createdDate,
                    id: each.refAssetClassSet.id,
                    lastModifiedBy: each.refAssetClassSet.lastModifiedBy,
                    lastModifiedDate: each.refAssetClassSet.lastModifiedDate,
                    name: each.refAssetClassSet.name,
                    useCorrelation: each.refAssetClassSet.useCorrelation,
                  },
                }))) ||
              [],
          },
          model.id
        ).then(() => {
          if (model.id === profile.id) {
            getProfile();
          }
        });
      } else {
        create(data);
      }
      setShowNotification(true);
    } else {
      setShowError(true);
    }
  };

  const retry = (): void => {
    setShowNotification(false);
  };

  const profileRole =
    profile && profile.roles && profile.roles[0] && profile.roles[0].name;

  let actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions = [
      {
        label: 'Back',
        onClick: retry,
      },
      {
        label: 'Cancel',
        onClick: handleClose,
        type: 'secondary',
      },
    ];
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      actions={actions}
      title={model && model.id ? 'Edit User' : 'New User'}
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            User {model && model.id ? 'updated' : 'added'} successfully.
          </Typography>
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error: {(error && error.messages && error.messages[0]) || 'Unknown'}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div className={classes.formContainer}>
          <TextField
            error={showError && name === ''}
            id="outlined-name"
            label="Name"
            value={name}
            onChange={(e: any): void => setName(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
            helperText={showError && name === '' && 'Required'}
          />

          <TextField
            error={showError && email === ''}
            id="outlined-email"
            label="Email"
            value={email}
            onChange={(e: any): void => setEmail(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
            helperText={showError && email === '' && 'Required'}
          />

          <TextField
            error={showError && username === ''}
            id="outlined-username"
            label="Username"
            value={username}
            onChange={(e: any): void => setUsername(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
            helperText={showError && username === '' && 'Required'}
          />

          <TextField
            error={showError && password === ''}
            id="outlined-password"
            label="Password"
            value={
              // eslint-disable-next-line
                !model?.id
                ? password
                : passwordFocused
                ? password
                : password || 'password'
            }
            type="password"
            onChange={(e: any): void => setPassword(e.target.value)}
            margin="normal"
            variant="outlined"
            onFocus={(): void => setPasswordFocused(true)}
            onBlur={(): void => setPasswordFocused(false)}
            className={classes.fullField}
            helperText={showError && password === '' && 'Required'}
          />

          <TextField
            id="outlined-select-roles"
            select
            error={showError && role === undefined}
            label="Role"
            value={role || ''}
            onChange={(e: any): void => setRole(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
            helperText={showError && role === undefined && 'Required'}
          >
            {profileRole === 'ADMIN'
              ? roles
                  .filter(
                    (each: UserRoleType): any => each.name !== 'SUPER_ADMIN'
                  )
                  .map(
                    (option: any): ReactElement => (
                      <MenuItem key={option.id} value={option}>
                        {option.name}
                      </MenuItem>
                    )
                  )
              : roles.map(
                  (option: any): ReactElement => (
                    <MenuItem key={option.id} value={option}>
                      {option.name}
                    </MenuItem>
                  )
                )}
          </TextField>

          <FormControlLabel
            control={
              <Checkbox
                checked={accessToAllClients}
                onChange={(e: any): void =>
                  setAccessToAllClients(e.target.checked)
                }
                value="checkedA"
                color="primary"
                classes={{ root: classes.checkboxroot }}
              />
            }
            label="Access to all clients"
            className={classes.checkbox}
            classes={{
              label: classes.labelCheckbox,
            }}
          />
          {currentEnvironment && currentEnvironment.simulationTab ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={simulationTab}
                  onChange={(e: any): void =>
                    setSimulationTab(e.target.checked)
                  }
                  value="checkedA"
                  color="primary"
                  classes={{ root: classes.checkboxroot }}
                />
              }
              label="Simulation tab"
              className={classes.checkbox}
              classes={{
                label: classes.labelCheckbox,
              }}
            />
          ) : (
            ''
          )}
          {relatedUsername !== '' && (
            <Typography variant="subtitle2" className={classes.info}>
              {isUpdated
                ? `Updated by: ${relatedUsername} on ${modifyDate}`
                : `Created by: ${relatedUsername} on ${modifyDate}`}
            </Typography>
          )}
        </div>
      )}
    </Dialog>
  );
};

DialogForm.defaultProps = {
  model: undefined,
  success: false,
  error: undefined,
  profile: undefined,
};

export default DialogForm;
