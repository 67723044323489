import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import AssetTableActions from '../../../../actions/AssetTableActions';
import ClientActions from '../../../../actions/ClientActions';
import AssetTable from './AssetTable';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ ...AssetTableActions, ...ClientActions }, dispatch);

const getClientId = (state: any): number =>
  state.Client.selectedClient && state.Client.selectedClient.id;
const getClientData = (state: any): any => state.Client.clientData;
const getCurrentDate = (state: any): any => state.Client.currentDate;

const getFoundClientData = createSelector(
  [getClientData, getClientId],
  (data, id) => data && data.find((each: any): any => each.id === id)
);

const getCurrentDateData = createSelector(
  [getFoundClientData, getCurrentDate],
  (data, date) =>
    data &&
    data.dates &&
    data.dates.find((eachDate: any): any => eachDate.date === date)
);

const getAssetTableOfCurrentDate = createSelector(
  [getCurrentDateData],
  data => data && data.assetTable
);

const getSelectedRows = createSelector(
  [getFoundClientData],
  data => data && data.selectedRows
);

const getSelectedColumns = createSelector(
  [getFoundClientData],
  data => data && data.selectedColumns
);

const getSelectedHistory = createSelector(
  [getFoundClientData],
  data => data && data.history
);

const getActiveHistory = createSelector(
  [getFoundClientData],
  data => data && data.historyActive
);

const getSelectedDate = createSelector(
  [getFoundClientData],
  data => data && data.selectedDate
);

const getExpandedRows = createSelector(
  [getFoundClientData],
  data => data && data.expandedRows
);

const getSetHasSelectedRows = createSelector(
  [getFoundClientData],
  data => data && data.hasSetSelectedRows
);

const mapStateToProps = (state: any): any => ({
  loading: state.AssetTable.loading || state.Client.loading,
  client: state.Client.selectedClient,
  assetTable: getAssetTableOfCurrentDate(state),
  profile: state.User.profile,
  selectedRows: getSelectedRows(state),
  selectedColumns: getSelectedColumns(state),
  selectedHistory: getSelectedHistory(state),
  historyActive: getActiveHistory(state),
  selectedDate: getSelectedDate(state),
  expandedRows: getExpandedRows(state),
  hasSetSelectedRows: getSetHasSelectedRows(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetTable);
