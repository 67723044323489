export const ACCOUNT_CODES_LIST_REQUEST = 'ACCOUNT_CODES_LIST_REQUEST';
export const ACCOUNT_CODES_LIST_SUCCESS = 'ACCOUNT_CODES_LIST_SUCCESS';
export const ACCOUNT_CODES_LIST_FAILURE = 'ACCOUNT_CODES_LIST_FAILURE';
export const ACCOUNT_CODE_CREATE_REQUEST = 'ACCOUNT_CODE_CREATE_REQUEST';
export const ACCOUNT_CODE_CREATE_SUCCESS = 'ACCOUNT_CODE_CREATE_SUCCESS';
export const ACCOUNT_CODE_CREATE_FAILURE = 'ACCOUNT_CODE_CREATE_FAILURE';
export const ACCOUNT_CODE_UPDATE_REQUEST = 'ACCOUNT_CODE_UPDATE_REQUEST';
export const ACCOUNT_CODE_UPDATE_SUCCESS = 'ACCOUNT_CODE_UPDATE_SUCCESS';
export const ACCOUNT_CODE_UPDATE_FAILURE = 'ACCOUNT_CODE_UPDATE_FAILURE';
export const ACCOUNT_CODE_ITEM_REQUEST = 'ACCOUNT_CODE_ITEM_REQUEST';
export const ACCOUNT_CODE_ITEM_SUCCESS = 'ACCOUNT_CODE_ITEM_SUCCESS';
export const ACCOUNT_CODE_ITEM_FAILURE = 'ACCOUNT_CODE_ITEM_FAILURE';
export const ACCOUNT_CODE_REMOVE_REQUEST = 'ACCOUNT_CODE_REMOVE_REQUEST';
export const ACCOUNT_CODE_REMOVE_SUCCESS = 'ACCOUNT_CODE_REMOVE_SUCCESS';
export const ACCOUNT_CODE_REMOVE_FAILURE = 'ACCOUNT_CODE_REMOVE_FAILURE';
