import AssetTableService from '../services/AssetTableService';
import AssetValueService from '../services/AssetValueService';
import * as types from '../constants/AssetTable';
import { AssetTableType } from '../Types';

const cleanLoading = () => (dispatch: any): any =>
  dispatch({ type: types.CLEAN_LOADING });

const getTable = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>
): any => (dispatch: any): any => {
  dispatch({ type: types.ASSET_TABLE_REQUEST });
  return AssetTableService.assetTable(
    clientId,
    year,
    month,
    enabledRows,
    disabledColumns
  )
    .then((aTable: AssetTableType): any => {
      dispatch({ type: types.ASSET_TABLE_SUCCESS, assetTable: aTable });
      return Promise.resolve(aTable);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ASSET_TABLE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ASSET_TABLE_FAILURE, error });
      }

      return Promise.reject(error);
    })
    .finally(() => dispatch(cleanLoading()));
};

const getStartDate = (clientId: number): any => (dispatch: any): any => {
  dispatch({ type: types.START_DATE_REQUEST });
  return AssetTableService.getStartDate(clientId)
    .then((date: any): any => {
      dispatch({ type: types.START_DATE_SUCCESS, date });
      return Promise.resolve(date);
    })
    .catch((error: any): void => {
      dispatch({ type: types.START_DATE_FAILURE, error });
    });
};

const createValue = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.ASSET_VALUE_REQUEST });
  return AssetValueService.create(data)
    .then((assetValue: any): any => {
      dispatch({ type: types.ASSET_VALUE_SUCCESS, assetValue });
      return Promise.resolve(assetValue);
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_VALUE_FAILURE, error });
    });
};

const createComment = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.ASSET_VALUE_COMMENT_REQUEST });
  return AssetValueService.createComment(data)
    .then((assetValueComment: any): any => {
      dispatch({
        type: types.ASSET_VALUE_COMMENT_SUCCESS,
        assetValueComment,
      });
      return Promise.resolve(assetValueComment);
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_VALUE_COMMENT_FAILURE, error });
    });
};

const updateComment = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.ASSET_VALUE_COMMENT_REQUEST });
  return AssetValueService.updateComment(data)
    .then((assetValueComment: any): any => {
      dispatch({
        type: types.ASSET_VALUE_COMMENT_SUCCESS,
        assetValueComment,
      });
      return Promise.resolve(assetValueComment);
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_VALUE_COMMENT_FAILURE, error });
    });
};

const updateValue = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.ASSET_VALUE_REQUEST });
  return AssetValueService.update(data)
    .then((assetValue: any): any => {
      dispatch({ type: types.ASSET_VALUE_SUCCESS, assetValue });
      return Promise.resolve(assetValue);
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_VALUE_FAILURE, error });
    });
};

const deleteValue = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.ASSET_VALUE_REMOVE_REQUEST });
  return AssetValueService.remove(data)
    .then((assetValue: any): any => {
      dispatch({ type: types.ASSET_VALUE_REMOVE_SUCCESS, assetValue });
      return Promise.resolve(assetValue);
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_VALUE_REMOVE_FAILURE, error });
    });
};

const createExtra = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.ASSET_VALUE_REQUEST });
  return AssetValueService.createExtra(data)
    .then((assetValue: any): any => {
      dispatch({ type: types.ASSET_VALUE_SUCCESS, assetValue });
      return Promise.resolve(assetValue);
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_VALUE_FAILURE, error });
    });
};

const updateExtra = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.ASSET_VALUE_REQUEST });
  return AssetValueService.updateExtra(data)
    .then((assetValue: any): any => {
      dispatch({ type: types.ASSET_VALUE_SUCCESS, assetValue });
      return Promise.resolve(assetValue);
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_VALUE_FAILURE, error });
    });
};

const deleteExtra = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.ASSET_VALUE_REMOVE_REQUEST });
  return AssetValueService.removeExtra(data)
    .then((assetValue: any): any => {
      dispatch({ type: types.ASSET_VALUE_REMOVE_SUCCESS, assetValue });
      return Promise.resolve(assetValue);
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_VALUE_REMOVE_FAILURE, error });
    });
};

const AssetTableActions = {
  getTable,
  getStartDate,
  createValue,
  updateValue,
  deleteValue,
  createExtra,
  updateExtra,
  deleteExtra,
  updateComment,
  createComment,
  cleanLoading,
};

export default AssetTableActions;
