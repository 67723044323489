import * as types from '../constants/Security';

const Security = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.SECURITIES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SECURITIES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        securities: action.securities,
      };
    case types.SECURITIES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SECURITY_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SECURITY_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.security.reference,
      };
    case types.SECURITY_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.SECURITY_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SECURITY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.security.reference,
      };
    case types.SECURITY_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.SECURITY_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SECURITY_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        security: action.security,
      };
    case types.SECURITY_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SECURITY_ITEM_VALUES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SECURITY_ITEM_VALUES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        securityValues: action.securityValues,
      };
    case types.SECURITY_ITEM_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.SECURITY_ITEM_VALUES_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SECURITY_ITEM_VALUES_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.SECURITY_ITEM_VALUES_SAVE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.SECURITY_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SECURITY_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        reference: 'deleted',
        securities: {
          ...state.securities,
          content: state.securities.content.filter(
            (ac: any): any => ac.id !== action.id
          ),
        },
      };
    case types.SECURITY_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    case types.SECURITY_START_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SECURITY_START_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        securityStartDate: action.date,
      };
    case types.SECURITY_START_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default Security;
