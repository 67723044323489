export const ADMIN = [
  '/',
  '/home',
  '/selected-client',
  '/selected-client/:clientId',
  '/clients',
  '/client/:clientId/accounts',
  '/client/:clientId/:accountId/securities',
  '/client/:clientId/:accountId/details',
  '/currencies',
  '/asset-class-set',
  '/asset-class-set/:assetClassSetId',
  '/asset-manager',
  '/account-type',
  '/account-code',
  '/region',
  '/security',
  '/security/:securityId',
  '/benchmark',
  '/reports',
  '/users',
  '/user/:userId/clients',
  '/clients-overview',
  '/securities-overview',
  '/assetmanagers-overview',
  '/client-group',
];

export const SUPER_ADMIN = [
  '/',
  '/home',
  '/selected-client',
  '/selected-client/:clientId',
  '/clients',
  '/client/:clientId/accounts',
  '/client/:clientId/:accountId/securities',
  '/client/:clientId/:accountId/details',
  '/currencies',
  '/asset-class-set',
  '/asset-class-set/:assetClassSetId',
  '/asset-manager',
  '/account-type',
  '/account-code',
  '/region',
  '/security',
  '/security/:securityId',
  '/benchmark',
  '/reports',
  '/users',
  '/user/:userId/clients',
  '/clients-overview',
  '/securities-overview',
  '/assetmanagers-overview',
  '/environments',
  '/client-group',
];

export const USER = [
  '/',
  '/home',
  '/selected-client/:clientId',
  '/selected-client',
];

export const VIEWER = [
  '/',
  '/home',
  '/selected-client/:clientId',
  '/selected-client',
];
