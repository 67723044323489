import * as types from '../constants/AssetTable';
import * as UserTypes from '../constants/User';

const AssetTable = (state: any = {}, action: any): any => {
  // Those `loading: false` are commented because it seems that we always call ASSET_TABLE requests after them we let them
  // false the loading value, if do not, the progressbar appears two times which is nice. But we can think of another workaround later
  switch (action.type) {
    // case types.ASSET_TABLE_REQUEST:
    //   return {
    //     ...state,
    //     loadingValue: true,
    //   };
    // case types.ASSET_TABLE_SUCCESS:
    //   return {
    //     ...state,
    //     loadingValue: false,
    //     assetTable: action.assetTable,
    //   };
    // case types.ASSET_TABLE_FAILURE:
    //   return {
    //     ...state,
    //     loadingValue: false,
    //     error: action.error,
    //   };
    case types.START_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.START_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        startDate: action.date,
      };
    case types.START_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ASSET_VALUE_REQUEST:
      return {
        ...state,
        loadingValue: true,
      };
    case types.ASSET_VALUE_SUCCESS:
      return {
        ...state,
        loadingValue: false,
        success: true,
      };
    case types.ASSET_VALUE_FAILURE:
      return {
        ...state,
        loadingValue: false,
        error: action.error,
      };
    case types.ASSET_VALUE_REMOVE_REQUEST:
      return {
        ...state,
        loadingValue: true,
      };
    case types.ASSET_VALUE_REMOVE_SUCCESS:
      return {
        ...state,
        loadingValue: false,
        removed: true,
      };
    case types.ASSET_VALUE_REMOVE_FAILURE:
      return {
        ...state,
        loadingValue: false,
        error: action.error,
        removed: false,
      };
    case types.ASSET_VALUE_COMMENT_REQUEST:
      return {
        ...state,
        loadingValue: true,
      };
    case types.ASSET_VALUE_COMMENT_SUCCESS:
      return {
        ...state,
        loadingValue: false,
        successComment: true,
      };
    case types.ASSET_VALUE_COMMENT_FAILURE:
      return {
        ...state,
        loadingValue: false,
        successComment: false,
        error: action.messages,
      };
    case types.CLEAN_LOADING:
      return {
        ...state,
        loadingValue: false,
        loading: false,
      };
    case UserTypes.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loadingValue: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default AssetTable;
