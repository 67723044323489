import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const list = (assetClassSetId: number): any =>
  Request('assetclass', 'GET', null, {
    assetclasssetid: assetClassSetId,
  })
    .then(ErrorHandler)
    .then((assetClasses: any): any => assetClasses);

const item = (id: number): any =>
  Request(`assetclass/${id}`, 'GET')
    .then(ErrorHandler)
    .then((assetClass: any): any => assetClass);

const create = (data: any): any =>
  Request('assetclass', 'POST', data)
    .then(ErrorHandler)
    .then((assetClass: any): any => assetClass);

const update = (data: any, id: number): any =>
  Request(`assetclass/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((assetClass: any): any => assetClass);

const remove = (id: number): any =>
  Request(`assetclass/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((assetClass: any): any => assetClass);

const AssetClassService = {
  list,
  item,
  create,
  update,
  remove,
};

export default AssetClassService;
