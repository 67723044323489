export const ENVIRONMENTS_LIST_REQUEST = 'ENVIRONMENTS_LIST_REQUEST';
export const ENVIRONMENTS_LIST_SUCCESS = 'ENVIRONMENTS_LIST_SUCCESS';
export const ENVIRONMENTS_LIST_FAILURE = 'ENVIRONMENTS_LIST_FAILURE';
export const ENVIRONMENT_CREATE_REQUEST = 'ENVIRONMENT_CREATE_REQUEST';
export const ENVIRONMENT_CREATE_SUCCESS = 'ENVIRONMENT_CREATE_SUCCESS';
export const ENVIRONMENT_CREATE_FAILURE = 'ENVIRONMENT_CREATE_FAILURE';
export const ENVIRONMENT_UPDATE_REQUEST = 'ENVIRONMENT_UPDATE_REQUEST';
export const ENVIRONMENT_UPDATE_SUCCESS = 'ENVIRONMENT_UPDATE_SUCCESS';
export const ENVIRONMENT_UPDATE_FAILURE = 'ENVIRONMENT_UPDATE_FAILURE';
export const ENVIRONMENT_ITEM_REQUEST = 'ENVIRONMENT_ITEM_REQUEST';
export const ENVIRONMENT_ITEM_SUCCESS = 'ENVIRONMENT_ITEM_SUCCESS';
export const ENVIRONMENT_ITEM_FAILURE = 'ENVIRONMENT_ITEM_FAILURE';
export const ENVIRONMENT_REMOVE_REQUEST = 'ENVIRONMENT_REMOVE_REQUEST';
export const ENVIRONMENT_REMOVE_SUCCESS = 'ENVIRONMENT_REMOVE_SUCCESS';
export const ENVIRONMENT_REMOVE_FAILURE = 'ENVIRONMENT_REMOVE_FAILURE';
export const ENVIRONMENT_CURRENT_REQUEST = 'ENVIRONMENT_CURRENT_REQUEST';
export const ENVIRONMENT_CURRENT_SUCCESS = 'ENVIRONMENT_CURRENT_SUCCESS';
export const ENVIRONMENT_CURRENT_FAILURE = 'ENVIRONMENT_CURRENT_FAILURE';
