import * as types from '../constants/Drawer';

const openDrawer = (): any => (dispatch: any): void => {
  dispatch({
    type: types.DRAWER_OPEN,
  });
};

const closeDrawer = (): any => (dispatch: any): void => {
  dispatch({
    type: types.DRAWER_CLOSE,
  });
};

const toggleDrawer = (): any => (dispatch: any): void => {
  dispatch({
    type: types.DRAWER_TOGGLE,
  });
};

const setCurrentItem = (item: any): any => (dispatch: any): void => {
  dispatch({
    type: types.DRAWER_SET_ITEM,
    item,
  });
};

export default { openDrawer, closeDrawer, toggleDrawer, setCurrentItem };
