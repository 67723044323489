import ClientTypeService from '../services/ClientTypeService';
import * as types from '../constants/ClientType';
import { ClientTypeType } from '../Types';

const getClientTypesList = (): any => (dispatch: any): any => {
  dispatch({ type: types.CLIENT_TYPES_LIST_REQUEST });
  return ClientTypeService.getClientTypeList()
    .then((clientTypes: { content: Array<ClientTypeType> }): any => {
      dispatch({ type: types.CLIENT_TYPES_LIST_SUCCESS, clientTypes });
      return Promise.resolve(clientTypes);
    })
    .catch((error: any): any => {
      dispatch({ type: types.CLIENT_TYPES_LIST_FAILURE, error });
      return Promise.reject(error);
    });
};

const getClientTypeItem = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.CLIENT_TYPE_ITEM_REQUEST });
  ClientTypeService.getClientTypeItem(id)
    .then((clientType: ClientTypeType): void => {
      dispatch({ type: types.CLIENT_TYPE_ITEM_SUCCESS, clientType });
    })
    .catch((error: any): void => {
      dispatch({ type: types.CLIENT_TYPE_ITEM_FAILURE, error });
    });
};

const createClientType = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.CLIENT_TYPE_CREATE_REQUEST });
  ClientTypeService.createClientType(data)
    .then((clientType: any): void => {
      dispatch({ type: types.CLIENT_TYPE_CREATE_SUCCESS, clientType });
      dispatch(getClientTypesList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.CLIENT_TYPE_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.CLIENT_TYPE_CREATE_FAILURE, error });
      }
    });
};

const updateClientType = (data: any, id: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.CLIENT_TYPE_UPDATE_REQUEST });
  ClientTypeService.updateClientType(data, id)
    .then((clientType: any): void => {
      dispatch({ type: types.CLIENT_TYPE_UPDATE_SUCCESS, clientType });
      dispatch(getClientTypesList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.CLIENT_TYPE_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.CLIENT_TYPE_UPDATE_FAILURE, error });
      }
    });
};

const removeClientType = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.CLIENT_TYPE_REMOVE_REQUEST });
  ClientTypeService.removeClientType(id)
    .then((clientType: any): void => {
      dispatch({ type: types.CLIENT_TYPE_REMOVE_SUCCESS, clientType, id });
    })
    .catch((error: any): void => {
      dispatch({ type: types.CLIENT_TYPE_REMOVE_FAILURE, error });
    });
};

const ClientTypeActions = {
  getClientTypesList,
  getClientTypeItem,
  createClientType,
  updateClientType,
  removeClientType,
};

export default ClientTypeActions;
