import React, { useState, useEffect, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import DetailsIcon from '@material-ui/icons/FilterList';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import DialogForm from './DialogForm';
import styles from './styles';
import {
  AssetClassSetType,
  ExportType,
  UserType,
  MutateAssetClassSetType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  getAssetClassSetsList: () => void;
  removeAssetClassSet: (id: number) => void;
  createAssetClassSet: (obj: MutateAssetClassSetType) => void;
  updateAssetClassSet: (obj: MutateAssetClassSetType, id: number) => void;
  openDialog: () => void;
  closeDialog: () => void;
  assetClassSets: { content: Array<AssetClassSetType> };
  deleted: boolean;
  success: boolean;
  loading: boolean;
  error: any;
  history: any;
  getUserItem: (id: number) => Promise<UserType>;
  exportAssetClassSetsTable: () => Promise<ExportType>;
  checkToRemoveAssetClassSet: (id: number) => Promise<any>;
}

const AssetClassSet = (props: Props): ReactElement => {
  const {
    getAssetClassSetsList,
    openDialog,
    closeDialog,
    removeAssetClassSet,
    history,
    exportAssetClassSetsTable,
    assetClassSets,
    updateAssetClassSet,
    createAssetClassSet,
    loading,
    error,
    success,
    deleted,
    getUserItem,
    checkToRemoveAssetClassSet,
  } = props;

  const [selectedRow, setSelectedRow] = useState<AssetClassSetType | undefined>(
    undefined
  );
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [model, setModel] = useState<AssetClassSetType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [
    assetClassSetHasValueResponse,
    setAssetClassSetHasValueResponse,
  ] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    getAssetClassSetsList();
  }, [getAssetClassSetsList]);

  const openAdd = (): void => {
    openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const openEdit = (event: any, row: AssetClassSetType): void => {
    openDialog();
    setOpen(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: AssetClassSetType): void => {
    openDialog();
    setOpenDelete(true);
    setSelectedRow(row);
    checkToRemoveAssetClassSet(row.id)
      .then((hasValueResponse: any): void =>
        setAssetClassSetHasValueResponse(hasValueResponse?.hasValue)
      )
      .catch((someError: any): void => console.warn(someError));
  };

  const handleClose = (): void => {
    closeDialog();
    setOpen(false);
  };

  const onRowSelection = (row: AssetClassSetType): void => {
    closeDialog();
    setSelectedRow(row);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) removeAssetClassSet(selectedRow.id);
  };

  const assetClassSetDetails = (e: any, data: AssetClassSetType): void => {
    history.push(`asset-class-set/${data.id}`);
  };

  const exportTable = (): void => {
    exportAssetClassSetsTable().then((res: ExportType): void => {
      const csvData = new Blob([res.export], {
        type: 'text/csv;charset=utf-8;',
      });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', res.fileName || 'AssetClassSets.csv');
      tempLink.click();
    });
  };

  const columns = [
    {
      title: 'Name',
      name: 'name',
      hasDetails: true,
      sortable: true,
      type: 'string',
      sortField: 'name',
      style: { width: 1000 },
      rowStyle: { width: 1000 },
    },
  ];

  const details = (row: AssetClassSetType): any => [
    {
      icon: <DetailsIcon fontSize="small" />,
      title: 'Details',
      onClick: (): void => assetClassSetDetails(undefined, row),
    },
  ];

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          hasEditAndDelete
          onEdit={openEdit}
          onDelete={handleDeleteOpen}
          onAdd={openAdd}
          onRowSelection={onRowSelection}
          columns={columns}
          details={details}
          noMargin
          hasDetails
          hasExportButton
          hasHighlightBackground
          onExport={exportTable}
          onClickRow={assetClassSetDetails}
          rows={assetClassSets && assetClassSets.content}
          entity="Asset Class Set"
          entities="Asset Class Sets"
        />
      </div>
      <DialogForm
        model={model}
        open={open}
        onClose={handleClose}
        update={updateAssetClassSet}
        create={createAssetClassSet}
        loading={loading}
        success={success}
        error={error}
        getUserItem={getUserItem}
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && !loading && !assetClassSetHasValueResponse && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {!isRemoving && !loading && assetClassSetHasValueResponse && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              There is data attached to this Asset Class Set.
              <br />
              Are you sure you want to remove this Asset Class Set and the data?
            </Typography>
          </div>
        )}
        {loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Asset Class Set removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error:{' '}
              {(error && error.messages && error.messages[0]) || 'Unknown'}
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};

AssetClassSet.defaultProps = {
  error: undefined,
  success: false,
  deleted: false,
};

export default AssetClassSet;
