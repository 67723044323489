import createStyles from '@material-ui/core/styles/createStyles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = (theme: any): any =>
  createStyles({
    formContainer: {
      width: '100%',
      height: 'calc(100% - 10px)',
      marginLeft: 25,
      display: 'flex',
      overflowY: 'hidden',
      paddingTop: '10px',
    },
    addSecurityDialog: {
      height: 375,
    },
    dialogContent: {
      overflow: 'visible',
    },
    autoCompRoot: {
      width: '100%',
      height: 35,
      marginTop: 13,
      marginLeft: 10,
    },
    autoInput: {
      border: '1px solid #6a6a6a',
      borderRadius: 5,
      display: 'flex',
      width: '100%',
      '& div div': {
        color: '#6a6a6a',
        '&:hover': {
          color: '#575757',
        },
      },
      height: 20,
      color: 'white',
      position: 'relative',
      backgroundColor: fade('#FFF', 0.15),
      '&:hover': {
        backgroundColor: fade('#FFF', 0.25),
      },
    },
    autoCompSuggestions: {
      '& div': {
        maxHeight: 200,
      },
      position: 'absolute',
      zIndex: 1,
      left: 0,
      right: 0,
    },
    autoCompSingleValue: {
      color: 'black',
      fontSize: 16,
      paddingLeft: 16,
    },
    autoCompPlace: {
      color: '#6a6a6a',
      position: 'absolute',
      left: 16,
      top: 6,
      fontSize: 15,
    },
    autoCompText: {
      color: 'black',
      '& div': {
        color: 'black !important',
      },
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    fullField: {
      margin: '5px 5px',
      width: '98%',
    },
    fieldWithIconContainer: {
      display: 'block',
      margin: '5px 5px',
    },
    actionButtons: {
      display: 'inline-block',
      float: 'right',
    },
    dropDown: {
      width: '46%',
      marginRight: '5px',
      marginTop: 0,
    },
    iconButton: {
      marginTop: '15px',
    },
    deleteIcon: {
      color: theme.palette.common.red,
    },
    divider: {
      margin: '10px 0',
    },
    doneIcon: {
      color: theme.palette.common.green,
    },
    archiveIcon: {
      color: theme.palette.common.blue,
    },
    progress: {
      margin: 'auto',
      position: 'absolute',
      width: '100%',
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      minHeight: 100,
    },
    text: {
      margin: '35px',
    },
    toolbar: {
      justifyContent: 'space-between',
      marginTop: '30px',
      marginBottom: '20px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    titleWithButton: {
      lineHeight: '48px',
    },
    title: {
      display: 'inline-block',
    },
    tableCount: {
      marginTop: 10,
      height: 26,
      minWidth: 26,
      textAlign: 'center',
      borderRadius: '50%',
      lineHeight: '29px',
      fontSize: 13,
      marginLeft: 10,
      color: '#1976d2',
      backgroundColor: '#0000000d',
      display: 'inline-block',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      flexWrap: 'wrap',
    },
    addButton: {},
    link: {
      '&:hover': { cursor: 'pointer' },
    },
    arrowButtonRoot: {
      padding: 4,
    },
    accountButton: {
      margin: 0,
    },
    yearPickerContainer: { display: 'inline-block', paddingTop: 2 },
    iconButtonDate: { height: 42 },
    accountButton2: {
      marginLeft: '10px',
      width: '95px',
    },
    buttonLabel: {
      // padding: '4px 2px 2px 1px',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: '#eff0f1',
    },
    backRotButton: {
      height: '48px',
      marginLeft: '-18px',
    },
    datePickerContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    progressBar: {
      width: '100%',
      position: 'absolute',
    },
    leftContainer: {
      flex: '1 0 35%',
      borderRight: '1px solid rgb(0,0,0, 0.5)',
      display: 'flex',
      paddingRight: '10px',
    },
    rightContainer: {
      flex: '1 0 55%',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
    lifeContainer: {
      padding: '10px',
    },
    button: {
      float: 'right',
    },
    success: {
      backgroundColor: theme.palette.common.green,
    },
    error: {
      backgroundColor: theme.palette.common.red,
    },
    rootInput: {
      textAlign: 'right',
      fontSize: '13px',
      '&:focus': {
        textAlign: 'center',
      },
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: '10px',
      marginBottom: '4px',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    dialogPaper: {
      height: 600,
    },
    securityInfo: {
      color: 'rgb(0,0,0,0.5)',
      margin: '12px 0px',
    },
    info: {
      color: 'rgb(0,0,0,0.5)',
      marginLeft: 5,
      fontSize: '10px',
      fontWeight: 350,
      position: 'absolute',
      bottom: 80,
    },
  });

export default styles;
