import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CorrelationActions from '../../actions/CorrelationActions';
import Correlation from './Correlation';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...CorrelationActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  matrix: state.Correlation.matrix,
  correlation: state.Correlation.correlation,
  success: state.Correlation.success,
  error: state.Correlation.error,
  loading: state.Correlation.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Correlation);
