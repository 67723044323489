import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import Users from './Users';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ ...UserActions, ...DialogActions }, dispatch);

const mapStateToProps = (state: any): any => ({
  users: state.User.users,
  deleted: state.User.deleted,
  success: state.User.success,
  error: state.User.error,
  loading: state.User.loading,
  roles: state.User.roles,
  profile: state.User.profile,
  currentEnvironment: state.Environment.currentEnvironment,
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
