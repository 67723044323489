import * as types from '../constants/Tour';

const Tour = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.DISABLE_HOME_TOUR:
      return {
        ...state,
        homeTourDisabled: true,
      };
    case types.DISABLE_USER_MENU_TOUR:
      return {
        ...state,
        userMenuTourDisabled: true,
      };
    default:
      return state;
  }
};

export default Tour;
