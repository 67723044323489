import React, {
  useState,
  ReactElement,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogActions from '../../actions/DialogActions';
import ReportActions from '../../actions/ReportActions';
import UserActions from '../../actions/UserActions';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import Logo from './images/logo.png';
import { ReportSettingType, MutateReportSettingType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

const borderCssObject = {
  borderWidth: 2,
  borderColor: 'rgb(25, 118, 210)',
  logoWidth: 160,
};

const textCssObject = {
  firstPageFirstLineFontColor: '#FFF',
  firstPageFirstLineFontStyle: 'bold',
  firstPageFirstLineFontSize: 34,
  firstPageFirstLineAlign: 'left',
  firstPageSecondLineFontColor: '#FFF',
  firstPageSecondLineFontStyle: 'bold',
  firstPageSecondLineFontSize: 20,
  firstPageSecondLineAlign: 'right',
  firstPageThirdLineFontColor: '#FFF',
  firstPageThirdLineFontStyle: 'bold',
  firstPageThirdLineFontSize: 20,
  firstPageThirdLineAlign: 'right',
  firstPageCardBackgroundColor: 'rgb(0, 204, 102)',
  pageTitleFontSize: 20,
  pageTitleFontColor: '#000',
  pageTitleFontStyle: 'bold',
  pageSubtitleFontSize: 14,
  pageSubtitleFontColor: '#000',
  pageSubtitleFontStyle: 'bold',
  pageNumberFontSize: 16,
  pageNumberBackgoundColor: 'rgb(25, 118, 210)',
  contactFontSize: 32,
  contactFontColor: 'rgb(25, 118, 210)',
  contactFontStyle: 'normal',
  contactDetailFontSize: 28,
  contactDetailFontStyle: 'normal',
  disclaimerFontSize: 12,
  disclaimerFontColor: '#000',
  disclaimerFontStyle: 'normal',
};

const firstPageTextObj = {
  firstLine: 'Monthly Report',
  secondLine: '{client_name}',
  thirdLine: '{month} {year}',
};

const lastPagetextObj = {
  city: 'Amsterdam',
  phoneNumber: '020 - 717 3334',
  address1: 'Concertgebouwplein 21 1071 LM Amsterdam',
  address2: '',
};

interface Props {
  history: any;
  open?: boolean;
  closeReportSettings?: () => void;
  success?: boolean;
  error?: any;
  updateReportingSettings?: (obj: MutateReportSettingType) => Promise<any>;
  reportingSettings?: ReportSettingType;
  getReportingSettings?: () => void;
}

const ReportSettings = (props: Props): ReactElement => {
  const {
    reportingSettings,
    open,
    getReportingSettings,
    closeReportSettings,
    updateReportingSettings,
    error,
    success,
  } = props;

  const [logoUrl, setLogoUrl] = useState<string>('');
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disclaimer, setDisclaimer] = useState<string>(
    `De opstellers van deze rapportage aanvaarden geen aansprakelijkheid voor schade van welke aard dan ook die het gevolg is van het gebruik van deze informa:e. Aan de informa:e kunnen dan ook geen rechten worden ontleend.\nOpinies en voorstellen dienen door de eigenaar van het vermogen getoetst te worden op passendheid m.b.t. de doelstelling van het belegd vermogen en aanvaardbare risico’s. Beleggingen dienen uitsluitend te geschieden als de risico’s die aan een belegging zijn verbonden worden begrepen. Niets in deze rapportage mag beschouwd worden als een aanbod of een uitnodiging van de zijde van de opstellers om op enigerlei wijze te handelen in effecten.\nDe waarde van beleggingen kan fluctueren. In het verleden behaalde resultaten bieden geen garan:e voor de toekomst. De opstellers van deze rapportages aanvaarden geen aansprakelijkheid voor het koersverloop van beleggingen.`
  );
  const [borderCss, setBorderCss] = useState<string>(
    JSON.stringify(borderCssObject, null, '\t')
  );
  const [textCss, setTextCss] = useState<string>(
    JSON.stringify(textCssObject, null, '\t')
  );
  const [firstPageText, setFirstPageText] = useState<string>(
    JSON.stringify(firstPageTextObj, null, '\t')
  );
  const [lastPageText, setLastPageText] = useState<string>(
    JSON.stringify(lastPagetextObj, null, '\t')
  );
  const classes = useStyles();
  const inputReference = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useLayoutEffect(() => {});

  const setModel = (): void => {
    if (reportingSettings) {
      if (reportingSettings.disclaimer) {
        setDisclaimer(reportingSettings.disclaimer);
      }
      if (reportingSettings.borderCss) {
        setBorderCss(reportingSettings.borderCss);
      }
      if (reportingSettings.textCss) {
        setTextCss(reportingSettings.textCss);
      }
      if (reportingSettings.firstPageText) {
        setFirstPageText(reportingSettings.firstPageText);
      }
      if (reportingSettings.lastPageText) {
        setLastPageText(reportingSettings.lastPageText);
      }
      if (reportingSettings?.logo) {
        setLogoUrl(reportingSettings?.logo);
      }
    }
  };

  useEffect(() => {
    setModel();
  }, []);

  useEffect(() => {
    if (getReportingSettings) getReportingSettings();
  }, [open, getReportingSettings]);

  useEffect(() => {
    setModel();
  }, [reportingSettings]);

  const resetFields = (): void => {
    setLogoUrl('');
    setShowNotification(false);
    setLoading(false);
    setDisclaimer(
      `De opstellers van deze rapportage aanvaarden geen aansprakelijkheid voor schade van welke aard dan ook die het gevolg is van het gebruik van deze informa:e. Aan de informa:e kunnen dan ook geen rechten worden ontleend.\nOpinies en voorstellen dienen door de eigenaar van het vermogen getoetst te worden op passendheid m.b.t. de doelstelling van het belegd vermogen en aanvaardbare risico’s. Beleggingen dienen uitsluitend te geschieden als de risico’s die aan een belegging zijn verbonden worden begrepen. Niets in deze rapportage mag beschouwd worden als een aanbod of een uitnodiging van de zijde van de opstellers om op enigerlei wijze te handelen in effecten.\nDe waarde van beleggingen kan fluctueren. In het verleden behaalde resultaten bieden geen garan:e voor de toekomst. De opstellers van deze rapportages aanvaarden geen aansprakelijkheid voor het koersverloop van beleggingen.`
    );
    setBorderCss(JSON.stringify(borderCssObject, null, '\t'));
    setTextCss(JSON.stringify(textCssObject, null, '\t'));
    setFirstPageText(`Monthly Reporting \n \n         {month} {year}`);
    setLastPageText(JSON.stringify(lastPagetextObj, null, '\t'));
  };

  const onClose = (): void => {
    resetFields();
    if (closeReportSettings) closeReportSettings();
  };

  const handleFile = (files: any): void => {
    setLoading(true);
    let reader: any = null;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2000000) {
        setLoading(false);
        return;
      }
      reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        setLogoUrl(reader.result);
      };
      reader.onerror = (e: any): void => {
        console.error('Error: ', e); //eslint-disable-line
      };
    }
  };

  const submit = (): void => {
    setLoading(true);
    const newSettings = {
      disclaimer,
      logo: logoUrl,
      firstPageText,
      borderCss,
      textCss,
      lastPageText,
    };
    if (updateReportingSettings)
      updateReportingSettings(newSettings)
        .then((): void => {
          setLoading(false);
          if (getReportingSettings) getReportingSettings();
        })
      .catch(error => console.warn(error)); //eslint-disable-line
    setShowNotification(true);
  };

  const actions: Array<any> = showNotification
    ? [
        {
          label: 'OK',
          onClick: onClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: onClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions.push({
      label: 'Retry',
      onClick: (): void => setShowNotification(false),
    });
  }

  return (
    <div>
      <Dialog
        open={!!open}
        onClose={onClose}
        actions={actions}
        paperClass={!showNotification ? classes.userFormDialogPaper : ''}
        title="Report Settings"
      >
        {showNotification && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {showNotification && !loading && success && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Report Settings Updated successfully.
            </Typography>
          </div>
        )}
        {showNotification && !loading && !success && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error:{' '}
              {(error && error.messages && error.messages[0]) || 'Unknown'}
            </Typography>
          </div>
        )}
        {!showNotification && (
          <div className={classes.userformContainer}>
            <div style={{ width: '100%', padding: '0px 5px' }}>
              <div style={{ display: 'flex' }}>
                <input
                  id="file"
                  ref={inputReference}
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(e: any): void => handleFile(e.target.files)}
                  type="file"
                />
                <div>
                  {logoUrl && logoUrl !== '' && (
                    <div>
                      <img
                        src={logoUrl}
                        className={classNames(classes.avatar)}
                        alt="logo"
                      />
                    </div>
                  )}
                  {(!logoUrl || logoUrl === '') && (
                    <img
                      src={Logo}
                      className={classNames(classes.avatar)}
                      alt="logo"
                    />
                  )}
                </div>
                <div
                  style={{
                    marginLeft: 50,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Button
                    className={classes.selectButton}
                    color="primary"
                    onClick={(): void => {
                      if (inputReference.current !== null)
                        inputReference.current.click();
                    }}
                    variant="outlined"
                  >
                    Upload
                  </Button>

                  <Button
                    className={classes.selectButton}
                    color="secondary"
                    onClick={(): void => setLogoUrl('')}
                    variant="outlined"
                    style={{ marginTop: 20 }}
                  >
                    Remove
                  </Button>
                </div>
              </div>
              <Typography variant="subtitle2" className={classes.formText}>
                Logo will be displayed as same ratio as you see here
              </Typography>
              <TextField
                id="firstPageText"
                label="First Page Text"
                style={{ marginTop: 20 }}
                value={firstPageText}
                onChange={(e: any): void => setFirstPageText(e.target.value)}
                margin="normal"
                variant="outlined"
                className={classes.settingTextField}
                multiline
                rows={3}
                InputProps={{
                  classes: {
                    root: classes.textarea,
                  },
                }}
                helperText="Available dynamic variables: {month}, {year}, {client_name}"
              />
              <TextField
                id="lastPageText"
                label="Last Page Text"
                style={{ marginTop: 20 }}
                value={lastPageText}
                onChange={(e: any): void => setLastPageText(e.target.value)}
                margin="normal"
                variant="outlined"
                className={classes.settingTextField}
                multiline
                rows={4}
                InputProps={{
                  classes: {
                    root: classes.textarea,
                  },
                }}
                helperText="Modify only available variables"
              />
              <TextField
                id="disclaimer"
                label="Disclaimer"
                style={{ marginTop: 20 }}
                value={disclaimer}
                onChange={(e: any): void => setDisclaimer(e.target.value)}
                margin="normal"
                variant="outlined"
                className={classes.settingTextField}
                multiline
                rows={4}
                InputProps={{
                  classes: {
                    root: classes.textarea,
                  },
                }}
              />

              <TextField
                id="borderCss"
                label="Border Style"
                style={{ marginTop: 20 }}
                value={borderCss}
                onChange={(e: any): void => setBorderCss(e.target.value)}
                margin="normal"
                variant="outlined"
                className={classes.settingTextField}
                multiline
                rows={6}
                InputProps={{
                  classes: {
                    root: classes.textarea,
                  },
                }}
                helperText="These settings are not actual CSS. Please only update the values that are already shown in the input above"
              />
              <TextField
                id="textCss"
                label="Text Style"
                style={{ marginTop: 20 }}
                value={textCss}
                onChange={(e: any): void => setTextCss(e.target.value)}
                margin="normal"
                variant="outlined"
                className={classes.settingTextField}
                multiline
                rows={4}
                InputProps={{
                  classes: {
                    root: classes.textarea,
                  },
                }}
                helperText="These settings are not actual CSS. Please only update the values that are already shown in the input above"
              />
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    { ...ReportActions, ...DialogActions, ...UserActions },
    dispatch
  );

const mapStateToProps = (state: any): any => {
  return {
    open: state.User.openReportSettings,
    reportingSettings: state.Report.reportingSettings,
    success: state.Report.success,
    error: state.Report.error,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReportSettings
);
