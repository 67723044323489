import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const getReportClientList = (id: number): any =>
  Request(`reporting/${id}`, 'GET')
    .then(ErrorHandler)
    .then((reports: any): any => reports);

const getReportItem = (id: number): any =>
  Request(`reporting/report/${id}`, 'GET')
    .then(ErrorHandler)
    .then((report: any): any => report);

const removeReport = (id: number): any =>
  Request(`reporting/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((report: any): any => report);

const createReport = (data: any): any =>
  Request('reporting', 'Post', data)
    .then(ErrorHandler)
    .then((report: any): any => report);

const updateReport = (data: any, id: number): any => {
  return Request(`reporting/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((report: any): any => report);
};

const createReportTemplate = (data: any): any =>
  Request('reporting/tab', 'POST', data)
    .then(ErrorHandler)
    .then((reportTemplate: any): any => reportTemplate);

const getReportTemplateClientList = (id: number): any =>
  Request(`reporting/tab/${id}`, 'GET')
    .then(ErrorHandler)
    .then((reportTemplates: any): any => reportTemplates);

const removeReportTemplate = (id: number): any =>
  Request(`reporting/tab/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((reportTemplate: any): any => reportTemplate);

const updateReportTemplate = (data: any, id: number): any => {
  return Request(`reporting/tab/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((report: any): any => report);
};

const getReportingSettings = (): any =>
  Request(`reportingSettings`, 'GET')
    .then(ErrorHandler)
    .then((reportTemplates: any): any => reportTemplates);

const updateReportingSettings = (data: any): any =>
  Request(`reportingSettings`, 'PUT', data)
    .then(ErrorHandler)
    .then((report: any): any => report);

const ReportService = {
  getReportClientList,
  getReportItem,
  getReportTemplateClientList,
  createReport,
  createReportTemplate,
  updateReport,
  removeReport,
  removeReportTemplate,
  updateReportTemplate,
  getReportingSettings,
  updateReportingSettings,
};

export default ReportService;
