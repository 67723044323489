import AssetManagerService from '../services/AssetManagerService';
import * as types from '../constants/AssetManager';
import { AssetManagerType } from '../Types';

const getAssetManagersList = (): any => (dispatch: any): void => {
  dispatch({ type: types.ASSET_MANAGERS_LIST_REQUEST });
  AssetManagerService.list()
    .then((assetManagers: { content: Array<AssetManagerType> }): void => {
      dispatch({ type: types.ASSET_MANAGERS_LIST_SUCCESS, assetManagers });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_MANAGERS_LIST_FAILURE, error });
    });
};

const getAssetManagerItem = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.ASSET_MANAGER_ITEM_REQUEST });
  AssetManagerService.item(id)
    .then((assetManager: AssetManagerType): void => {
      dispatch({ type: types.ASSET_MANAGER_ITEM_SUCCESS, assetManager });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_MANAGER_ITEM_FAILURE, error });
    });
};

const createAssetManager = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.ASSET_MANAGER_CREATE_REQUEST });
  AssetManagerService.create(data)
    .then((assetManager: any): void => {
      dispatch({ type: types.ASSET_MANAGER_CREATE_SUCCESS, assetManager });
      dispatch(getAssetManagersList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ASSET_MANAGER_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ASSET_MANAGER_CREATE_FAILURE, error });
      }
    });
};

const updateAssetManager = (data: any, id: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.ASSET_MANAGER_UPDATE_REQUEST });
  AssetManagerService.update(data, id)
    .then((assetManager: any): void => {
      dispatch({ type: types.ASSET_MANAGER_UPDATE_SUCCESS, assetManager });
      dispatch(getAssetManagersList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ASSET_MANAGER_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ASSET_MANAGER_UPDATE_FAILURE, error });
      }
    });
};

const removeAssetManager = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ASSET_MANAGER_REMOVE_REQUEST });
  AssetManagerService.remove(id)
    .then((assetManager: any): void => {
      dispatch({
        type: types.ASSET_MANAGER_REMOVE_SUCCESS,
        assetManager,
        id,
      });
      dispatch(getAssetManagersList());
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_MANAGER_REMOVE_FAILURE, error });
    });
};

const AssetManagerActions = {
  getAssetManagersList,
  getAssetManagerItem,
  createAssetManager,
  updateAssetManager,
  removeAssetManager,
};

export default AssetManagerActions;
