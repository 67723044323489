import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const list = (): any =>
  Request('accountcode', 'GET')
    .then(ErrorHandler)
    .then((accountCodes: any): any => accountCodes);

const item = (id: number): any =>
  Request(`accountcode/${id}`, 'GET')
    .then(ErrorHandler)
    .then((accountCode: any): any => accountCode);

const remove = (id: number): any =>
  Request(`accountcode/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((accountCode: any): any => accountCode);

const create = (data: any): any =>
  Request('accountcode', 'POST', data)
    .then(ErrorHandler)
    .then((accountCode: any): any => accountCode);

const update = (data: any, id: number): any =>
  Request(`accountcode/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((accountCode: any): any => accountCode);

const AccountCodeService = {
  list,
  item,
  update,
  create,
  remove,
};

export default AccountCodeService;
