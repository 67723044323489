import * as types from '../constants/Drawer';

const Drawer = (state: any, action: any): any => {
  switch (action.type) {
    case types.DRAWER_OPEN:
      return {
        ...state,
        open: true,
      };

    case types.DRAWER_CLOSE:
      return {
        ...state,
        open: false,
      };

    case types.DRAWER_TOGGLE:
      return {
        ...state,
        open: !state.open,
      };
    case types.DRAWER_SET_ITEM:
      return {
        ...state,
        item: action.item,
      };

    default:
      return {
        ...state,
      };
  }
};

export default Drawer;
