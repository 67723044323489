import * as types from '../constants/DatePicker';

const setYear = (year: number): any => (dispatch: any): void => {
  dispatch({
    type: types.DATEPICKER_SET_YEAR,
    year,
  });
};

const setMonth = (month: number): any => (dispatch: any): void => {
  dispatch({
    type: types.DATEPICKER_SET_MONTH,
    month,
  });
};

export default { setYear, setMonth };
