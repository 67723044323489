import React, { ReactElement, useState, useRef, useLayoutEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import LogoImage from '../../assets/img/logo1.png';
import styles from './styles';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  login: any;
  sessionEnded: boolean;
}

const Login = (props: Props): ReactElement => {
  const { login, sessionEnded } = props;

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(true);
  const classes = useStyles();

  const passwordRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {});

  const submit = (): void => {
    login({ username, password }).then((response: boolean): void => {
      if (response === false) {
        setShowError(true);
      }
    });
  };

  const onKeyPress = (event: any): void => {
    if (
      event.key === 'Enter' &&
      passwordRef !== null &&
      passwordRef.current !== null
    ) {
      submit();
      passwordRef.current.blur();
    }
  };

  return (
    <div className={classes.container}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <img
          src={LogoImage}
          alt="logo"
          style={{ width: 280, marginBottom: '30px' }}
        />

        <div className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            value={username}
            className={classes.field}
            onChange={(e: any): void => setUsername(e.target.value)}
            inputProps={{ className: classes.input }}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={(e: any): void => setPassword(e.target.value)}
            autoComplete="current-password"
            className={classes.field}
            onKeyPress={onKeyPress}
            inputProps={{ className: classes.input }}
            inputRef={passwordRef}
          />
          {showError && (
            <Typography variant="caption" style={{ color: 'red' }}>
              Invalid Username or Password
            </Typography>
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(): void => submit()}
          >
            Sign In
          </Button>
          <div className={classes.caption}>
            <Typography variant="caption">
              Powered by iassetmonitor.com
            </Typography>
          </div>
        </div>
      </Paper>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={showMessage && sessionEnded}
        autoHideDuration={2000}
        onClose={(): void => setShowMessage(false)}
      >
        <SnackbarContent
          className={classes.snack}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              Your Session Has Ended
            </span>
          }
        />
      </Snackbar>
    </div>
  );
};

Login.defaultProps = {
  login: undefined,
  sessionEnded: false,
};
export default Login;
