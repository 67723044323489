import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegionActions from '../../actions/RegionActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import ExportActions from '../../actions/ExportActions';
import Region from './Region';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...RegionActions,
      ...DialogActions,
      ...UserActions,
      ...ExportActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  regions: state.Region.regions,
  deleted: state.Region.deleted,
  region: state.Region.accountType,
  success: state.Region.success,
  error: state.Region.error,
  loading: state.Region.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Region);
