import * as types from '../constants/Region';

const Region = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.REGIONS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REGIONS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        regions: action.regions,
      };
    case types.REGIONS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.REGION_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REGION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.region.reference,
      };
    case types.REGION_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.REGION_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REGION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.region.reference,
      };
    case types.REGION_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.REGION_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REGION_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        region: action.region,
      };
    case types.REGION_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.REGION_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REGION_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        region: action.region,
        regions: {
          ...state.regions,
          content: state.regions.content.filter(
            (ac: any): any => ac.id !== action.id
          ),
        },
      };
    case types.REGION_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default Region;
