import createStyles from '@material-ui/core/styles/createStyles';

const styles = (): any =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
      margin: '50px 30px',
      WebkitAnimation: 'fadein 2s',
      minHeight: 1000,
      width: '100%',
    },
    iframe: {
      width: 'calc(100% - 80px)',
    },
  });

export default styles;
