import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    root: {
      height: 'calc(100% - 60px)',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#eff0f1',
    },
    formContainer: {
      width: '100%',
      height: '100%',
      margin: '10px 10px',
    },
    fullField: {
      margin: '5px 10px',
      width: '96%',
    },
    container: {
      marginLeft: '10px',
      marginTop: '10px',
    },
    toolbar: {
      justifyContent: 'space-between',
      marginTop: '30px',
      marginBottom: '20px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    accountButton2: {
      marginLeft: '10px',
      width: '95px',
    },
    addButton: {},
    iconButton: { margin: '0 5px' },
    accountButton: { margin: '0 300px 0 30px' },
    yearPickerContainer: { display: 'inline-block' },
    generalTab: {
      margin: '10px',
      border: '1px solid #00000026',
      borderRadius: '10px',
      padding: '20px',
    },
    divider: {
      margin: '20px 0',
    },
    title: {
      display: 'inline',
      opacity: 0.8,
    },
    detail: {
      position: 'absolute',
      left: '250px',
      display: 'inline',
    },
    left: { flex: '1 0 45%', margin: '20px 20px 5px 20px' },
    right: { flex: '1 0 45%', margin: '20px 20px 5px 20px' },
    parent: {
      display: 'flex',
      paddingBottom: '40px',
      paddingTop: '20px',
      height: '100%',
      backgroundClip: 'padding-box',
    },
    totalTitle: {
      margin: '10px 0 50px 22px',
      display: 'inline',
      color: 'rgb(0,0,0)',
      fontSize: '13px',
      flex: '1 0 20%',
    },
    totalValue: {
      display: 'inline',
      fontSize: '13px',
    },
    totalRed: {
      color: 'red',
      textAlign: 'right',
      fontSize: '13px',
    },
    totalGreen: {
      textAlign: 'right',
      fontSize: '13px',
      color: 'green',
    },
    select: {
      marginLeft: 0,
    },
    arrowButtonRoot: {
      padding: 4,
    },
    textfieldRoot: {},
    success: {
      backgroundColor: theme.palette.common.green,
    },
    error: {
      backgroundColor: theme.palette.common.red,
    },
    rootInput: {
      textAlign: 'right',
      fontSize: '13px',
      '&:focus': {
        textAlign: 'center',
      },
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: '10px',
      marginBottom: '4px',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    link: {
      '&:hover': { cursor: 'pointer' },
    },
    title1: {
      display: 'inline-block',
      lineHeight: '48px',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    backRotButton: {
      height: '48px',
      marginLeft: '-18px',
    },
    leftContainer: {
      width: 550,
    },
    rightContainer: {
      width: 350,
      height: 350,
      padding: '40px 80px 80px 80px',
      marginLeft: 100,
    },
    tabsValue: {
      overflow: 'auto',
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      minHeight: '100%',
    },
  });

export default styles;
