import UserActions from '../actions/UserActions';
import Store from './Store';

export async function ErrorHandler(response: any): Promise<any> {
  switch (response.status) {
    case 200:
      return response.json();
    case 201:
      return {
        reference: response.headers.get('Location'),
      };
    case 204:
      return { success: true };
    case 400:
      return Promise.reject(await response.json());
    case 401:
      Store.dispatch(UserActions.logout(true));
      break;
    case 403:
      return Promise.reject(await response.json());
    case 404:
      return Promise.reject(await response.json());
    case 500:
      return Promise.reject(await response.json());
    default:
      return Promise.reject(await response.json());
  }
}

export default null;
