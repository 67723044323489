import React, { useRef, ReactElement, useState, useLayoutEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import styles from './styles';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  onClose: () => void;
  open: boolean;
  create: (file: any) => Promise<any>;
  loading: boolean;
  success: boolean;
  error: any;
}

const DialogForm = (props: Props): ReactElement => {
  const { onClose, create, open, loading, success, error } = props;

  const [fileName, setFileName] = useState<string>('');
  const [file, setFile] = useState<any>(undefined);
  const [disable, setDisable] = useState<boolean>(true);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [benchmarkArray, setBenchmarkArray] = useState<any[]>([]);
  const classes = useStyles();

  const inputReference = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {});

  const resetFields = (): void => {
    setFile(undefined);
    setFileName('');
    setShowNotification(false);
    setDisable(true);
  };

  const handleClose = (): void => {
    resetFields();
    onClose();
  };

  const submit = (): void => {
    create(file).then((response: any): void => {
      const newArray = Object.keys(response.benchmarks).map(key => ({
        name: key,
        value: `${(Math.round(response.benchmarks[key] * 100) / 100).toFixed(
          2
        )}`,
        id: `${key}-${response.benchmarks[key]}`,
      }));
      setBenchmarkArray(newArray);
    });
    setShowNotification(true);
  };

  const handleFile = (files: any[]): void => {
    if (files.length > 0) {
      const newFile = files[0];
      const { name } = newFile;
      const formData = new FormData();
      formData.set('file', newFile);
      setFile(formData);
      setDisable(false);
      setFileName(name);
    } else {
      setDisable(true);
      setFile(null);
    }
  };

  const openFileDialog = (): void => {
    if (inputReference.current !== null) inputReference.current.click();
  };

  const downloadSample = (): void => {
    const url =
      window.location.hostname === 'localhost'
        ? `https://iam2.sahand.cloud/api/benchmark/sample`
        : `https://${window.location.hostname}/api/benchmark/sample`;
    const requestOptions: any = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    fetch(url, requestOptions).then((response: any): void => {
      response.blob().then((res: any): void => {
        const l = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = l;
        link.setAttribute('download', 'iAm - Sample Portfolio Benchmark.xls');
        document.body.appendChild(link);
        link.click();
      });
    });
  };

  const actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'upload',
          onClick: submit,
          disabled: disable,
        },
      ];

  const extraActions = [
    {
      label: 'download sample file',
      onClick: downloadSample,
      disabled: loading,
    },
  ];

  const columns = [
    { title: 'Date', name: 'name' },
    { title: 'Value', name: 'value' },
  ];
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      actions={actions}
      extraActions={extraActions}
      paperClass={showNotification && !loading && success ? classes.paper : ''}
      title={
        // eslint-disable-next-line
          !showNotification
          ? 'Upload Benchmark file'
          : !loading && success
          ? 'Successfully Uploaded'
          : 'Uploading Benchmark File'
      }
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <DataTable
            columns={columns}
            rows={benchmarkArray || []}
            noMargin
            entity="Benchmark"
            entities={fileName || ''}
            notShowFooter
          />
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error: {(error && error.messages && error.messages[0]) || 'Unknown'}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div className={classes.formContainer}>
          <input
            id="file"
            ref={inputReference}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            style={{ display: 'none' }}
            onChange={(e: any): void => handleFile(e.target.files)}
            type="file"
          />
          <Button
            variant="outlined"
            onClick={openFileDialog}
            className={classes.selectButton}
          >
            Select File
          </Button>

          <TextField
            id="outlined-filename"
            label="File Name"
            value={fileName}
            className={classes.photoName}
            disabled
            variant="outlined"
          />
        </div>
      )}
    </Dialog>
  );
};

DialogForm.defaultProps = {
  model: undefined,
  success: false,
  error: undefined,
  loading: false,
};

export default DialogForm;
