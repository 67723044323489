import React, { useState, useEffect, Fragment, ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classnames from 'classnames';
import Dates from '../../constants/Date';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import {
  AccountSecurityLifetimeType,
  AccountSecurityType,
  AccountType,
  UserType,
  CreateAccountSecurityLifetimeType,
  UpdateAccountSecurityLifetimeType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  onClose: () => void;
  model: AccountSecurityType;
  open: boolean;
  loading: boolean;
  accountId: number;
  createSecurityLifetime: (
    obj: CreateAccountSecurityLifetimeType,
    accountid: number,
    modelid: number,
    dateobj: any
  ) => Promise<any>;
  updateSecurityLifetime: (
    obj: UpdateAccountSecurityLifetimeType,
    accountid: number,
    modelid: number,
    dateobj: any,
    lifetimeId: number
  ) => Promise<any>;
  removeSecurityLifetime: (
    objid: number,
    accountid: number,
    modelid: number,
    dateobj: any
  ) => void;
  getSecurityLifeTime: (accountid: number, id: number) => void;
  dateData: { refAccountId: number; year: number; month: number };
  accountSecurityLifetimes: Array<AccountSecurityLifetimeType>;
  error: any;
  success: boolean;
  account: AccountType;
  getUserItem: (id: number) => Promise<UserType>;
}

const EditDialogForm = (props: Props): ReactElement => {
  const {
    getSecurityLifeTime,
    accountId,
    model,
    getUserItem,
    onClose,
    removeSecurityLifetime,
    dateData,
    createSecurityLifetime,
    updateSecurityLifetime,
    account,
    accountSecurityLifetimes,
    loading,
    success,
    error,
    open,
  } = props;

  const [comment1, setComment1] = useState<string | undefined>('');
  const [comment2, setComment2] = useState<string | undefined>('');
  const [startMonthDate, setStartMonthDate] = useState<any>(undefined);
  const [startYearDate, setStartYearDate] = useState<string | number>('');
  const [endMonthDate, setEndMonthDate] = useState<any>(undefined);
  const [endYearDate, setEndYearDate] = useState<string | number>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [dateValidation, setDateValidation] = useState<boolean>(true);
  const [modifyDate, setModifyDate] = useState<string>('');
  const [relatedUsername, setRelatedUsername] = useState<string>('');
  const classes = useStyles();

  const resetFields = (holdNotification?: boolean): void => {
    setComment1('');
    setComment2('');
    setStartMonthDate(undefined);
    setStartYearDate('');
    setEndMonthDate(undefined);
    setEndYearDate('');
    setIsEditing(false);
    setId(0);
    setOpenNotification(holdNotification || false);
    setModifyDate('');
    setRelatedUsername('');
    setDateValidation(true);
  };

  const getLifeTime = (): void => {
    getSecurityLifeTime(accountId, model.id);
  };

  useEffect(() => {
    if (model) getLifeTime();
    resetFields();
  }, [model]);

  const getUser = (newModel: any): void => {
    const { lastModifiedBy, lastModifiedDate } = newModel;

    if (lastModifiedBy) {
      getUserItem(lastModifiedBy).then((user: UserType): void => {
        const date = new Date(lastModifiedDate);
        const monthObj = Dates.months.find(
          each => each.valueNumber === date.getMonth() + 1
        );
        const month = monthObj && monthObj.value;
        const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
        setRelatedUsername(user?.name || '');
        setModifyDate(newModifyDate);
      });
    } else {
      setModifyDate('');
      setRelatedUsername('');
    }
  };

  const removeUser = (): void => {
    setModifyDate('');
    setRelatedUsername('');
  };

  const handleClose = (): void => {
    onClose();
    resetFields();
  };

  const edit = (event: any, newModel: any): void => {
    const startDate = new Date(newModel.startDate);
    const endDate = new Date(newModel.endDate);
    setComment1(newModel.comment1);
    setComment2(newModel.comment2);
    setStartMonthDate(
      (startDate &&
        Dates.months.find(
          each => each.valueNumber - 1 === startDate.getMonth()
        )) ||
        undefined
    );
    setStartYearDate((startDate && startDate.getFullYear()) || '');
    setEndMonthDate(
      (endDate &&
        Dates.months.find(
          each => each.valueNumber - 1 === endDate.getMonth()
        )) ||
        undefined
    );
    setEndYearDate((endDate && endDate.getFullYear()) || '');
    setIsEditing(true);
    setId(newModel.id || 0);
  };

  const remove = (event: any, data: AccountSecurityLifetimeType): void => {
    removeSecurityLifetime(
      data && data.id,
      Number(accountId),
      model.id,
      dateData
    );
  };

  const canSubmit = (): boolean => {
    let startDate = '';
    let endDate = '';

    if (startYearDate !== '' && startMonthDate)
      startDate = new Date(
        Number(startYearDate),
        startMonthDate && startMonthDate.valueNumber
      ).toISOString();
    if (endYearDate !== '' && endMonthDate)
      endDate = new Date(
        Number(endYearDate),
        endMonthDate && endMonthDate.valueNumber
      ).toISOString();
    if (startDate !== '' && endDate !== '') {
      if (
        new Date(Number(startYearDate), startMonthDate?.valueNumber + 1) >
        new Date(
          Number(endYearDate),
          endMonthDate && endMonthDate.valueNumber + 1
        )
      ) {
        setDateValidation(false);
        return false;
      }
      setDateValidation(true);
    }
    if (
      startDate !== '' &&
      account &&
      account.startDate &&
      new Date(account.startDate) > new Date(startDate)
    ) {
      setDateValidation(false);
      return false;
    }
    setDateValidation(true);
    if (
      endDate !== '' &&
      account &&
      account.endDate &&
      new Date(account.endDate) < new Date(endDate)
    ) {
      setDateValidation(false);
      return false;
    }
    setDateValidation(true);

    return true;
  };

  const save = (): void => {
    if (canSubmit()) {
      const data: any = {};
      let startDate = '';
      let endDate = '';

      if (startYearDate !== '' && startMonthDate)
        startDate = new Date(
          Number(startYearDate),
          startMonthDate && startMonthDate.valueNumber
        ).toISOString();
      if (endYearDate !== '' && endMonthDate)
        endDate = new Date(
          Number(endYearDate),
          endMonthDate && endMonthDate.valueNumber
        ).toISOString();
      data.comment1 = comment1;
      data.comment2 = comment2;
      data.startDate = startDate;
      data.endDate = endDate;
      if (id !== 0) {
        updateSecurityLifetime(
          data,
          Number(accountId),
          model.id,
          dateData,
          id
        ).then(() => resetFields(true));
      } else {
        data.refAccountId = Number(accountId);
        data.refSecurityId = model.id;
        createSecurityLifetime(
          data,
          Number(accountId),
          model.id,
          dateData
        ).then(() => resetFields(true));
      }
      setOpenNotification(true);
    } else {
      setShowError(true);
    }
  };

  const getDate = (dateInput: string): string => {
    if (dateInput) {
      const date = new Date(dateInput);
      const year = date.getFullYear();
      const month = Dates.months.find(
        each => each.valueNumber - 1 === date.getMonth()
      );
      return `${(month && month.label) || ''} ${year}`;
    }
    return '';
  };

  const getYearDate = (date: string): number => {
    return new Date(date).getFullYear();
  };

  const accountStartYear =
    account && account.startDate ? getYearDate(account.startDate) : undefined;
  const accountEndYear =
    account && account.endDate ? getYearDate(account.endDate) : undefined;

  const actions = [
    {
      label: 'close',
      onClick: handleClose,
    },
  ];

  const sortedLifeTimes =
    (accountSecurityLifetimes &&
      accountSecurityLifetimes.length > 0 &&
      accountSecurityLifetimes.sort((a: any, b: any): any => b - a)) ||
    [];
  if (relatedUsername === '' && sortedLifeTimes.length > 0) {
    getUser(sortedLifeTimes[0]);
  }

  if (relatedUsername !== '' && sortedLifeTimes.length === 0) {
    removeUser();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        actions={actions}
        title="Security"
        paperClass={classes.dialogPaper}
      >
        {loading && <LinearProgress className={classes.progressBar} />}
        <div className={classes.formContainer}>
          <div className={classes.leftContainer}>
            <div>
              <TextField
                id="outlined-select-comment1"
                label="Comment 1"
                value={comment1}
                onChange={(e: any): void => setComment1(e.target.value)}
                multiline
                rowsMax="4"
                margin="normal"
                variant="outlined"
                className={classes.fullField}
              />
              <TextField
                id="outlined-select-comment2"
                label="Comment 2"
                value={comment2}
                onChange={(e: any): void => setComment2(e.target.value)}
                multiline
                rowsMax="4"
                margin="normal"
                variant="outlined"
                className={classes.fullField}
              />
              <div className={classes.fieldWithIconContainer}>
                <Typography variant="subtitle1">Start Date</Typography>
                <TextField
                  error={showError && !dateValidation}
                  id="outlined-select-startMonthDate"
                  select
                  label="Month"
                  value={startMonthDate || ''}
                  onChange={(e: any): void => setStartMonthDate(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.dropDown}
                  helperText={showError && !dateValidation && 'Invalid Date'}
                >
                  <MenuItem value={undefined}>{'<Empty>'}</MenuItem>
                  {Dates.months.map((option: any): any => (
                    <MenuItem key={option.value} value={option}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  error={showError && !dateValidation}
                  id="outlined-select-startYearDate"
                  select
                  label="Year"
                  value={startYearDate}
                  onChange={(e: any): void => setStartYearDate(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.dropDown}
                  helperText={showError && !dateValidation && 'Invalid Date'}
                >
                  <MenuItem value="">{'<Empty>'}</MenuItem>
                  {accountStartYear
                    ? Dates.years
                        .filter(
                          (each: any): any =>
                            each.valueNumber >= Number(accountStartYear)
                        )
                        .filter((each: any): any => {
                          if (
                            accountEndYear &&
                            each.valueNumber > Number(accountEndYear)
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .map((option: any): any => (
                          <MenuItem
                            key={option.value}
                            value={option.valueNumber}
                          >
                            {option.label}
                          </MenuItem>
                        ))
                    : Dates.years
                        .filter((each: any): any => {
                          if (
                            accountEndYear &&
                            each.valueNumber > Number(accountEndYear)
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .map((option: any): any => (
                          <MenuItem
                            key={option.value}
                            value={option.valueNumber}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                </TextField>
              </div>
              <div className={classes.fieldWithIconContainer}>
                <Typography variant="subtitle1" className={classes.label}>
                  End Date
                </Typography>
                <TextField
                  error={showError && !dateValidation}
                  id="outlined-select-endMonthDate"
                  select
                  label="Month"
                  value={endMonthDate || ''}
                  onChange={(e: any): void => setEndMonthDate(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.dropDown}
                  helperText={showError && !dateValidation && 'Invalid Date'}
                >
                  <MenuItem value={undefined}>{'<Empty>'}</MenuItem>
                  {Dates.months.map((option: any): any => (
                    <MenuItem key={option.value} value={option}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  error={showError && !dateValidation}
                  id="outlined-select-endYearDate"
                  select
                  label="Year"
                  value={endYearDate}
                  onChange={(e: any): void => setEndYearDate(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  className={classes.dropDown}
                  helperText={showError && !dateValidation && 'Invalid Date'}
                >
                  <MenuItem value="">{'<Empty>'}</MenuItem>
                  {startYearDate !== ''
                    ? Dates.years
                        .filter(
                          (each: any): any =>
                            each.valueNumber >= Number(startYearDate)
                        )
                        .filter((each: any): any => {
                          if (
                            accountEndYear &&
                            each.valueNumber > Number(accountEndYear)
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .map((option: any): any => (
                          <MenuItem
                            key={option.value}
                            value={option.valueNumber}
                          >
                            {option.label}
                          </MenuItem>
                        ))
                    : Dates.years
                        .filter((each: any): any => {
                          if (
                            accountStartYear &&
                            each.valueNumber < Number(accountStartYear)
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .filter((each: any): any => {
                          if (
                            accountEndYear &&
                            each.valueNumber > Number(accountEndYear)
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .map((option: any): any => (
                          <MenuItem
                            key={option.value}
                            value={option.valueNumber}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                </TextField>
              </div>
              <div>
                {isEditing && (
                  <Tooltip title="Cancel" className={classes.cancelButton}>
                    <IconButton
                      onClick={(): void => resetFields()}
                      aria-label="cancel"
                    >
                      <CancelIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={save}
                >
                  Submit
                </Button>
              </div>
              {relatedUsername !== '' && (
                <Typography variant="subtitle2" className={classes.info}>
                  {`Last modified by: ${relatedUsername} on ${modifyDate}`}
                </Typography>
              )}
            </div>
          </div>
          <div className={classes.rightContainer}>
            {sortedLifeTimes.map(
              (each: AccountSecurityLifetimeType): ReactElement => (
                <Fragment key={each.id}>
                  <div className={classes.lifeContainer}>
                    <Typography variant="subtitle1">
                      {`${getDate(each.startDate)} - ${getDate(each.endDate)}`}
                    </Typography>
                    <Typography variant="subtitle2">{each.comment1}</Typography>
                    <Typography variant="subtitle2">{each.comment2}</Typography>

                    <div className={classes.actionButtons}>
                      <Tooltip title="Edit" className={classes.iconButton}>
                        <IconButton
                          onClick={(event: any): void => edit(event, each)}
                          aria-label="Edit"
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete" className={classes.iconButton}>
                        <IconButton
                          onClick={(event: any): void => remove(event, each)}
                          aria-label="Delete"
                        >
                          <DeleteIcon
                            fontSize="small"
                            className={classes.deleteIcon}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <Divider className={classes.divider} />
                </Fragment>
              )
            )}
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openNotification && !loading}
            autoHideDuration={5000}
            onClose={(): void => setOpenNotification(false)}
          >
            <div>
              {success && (
                <SnackbarContent
                  className={classnames(classes.success)}
                  aria-describedby="client-snackbar"
                  message={
                    <span id="client-snackbar" className={classes.message}>
                      <CheckCircleIcon
                        className={classnames(
                          classes.icon,
                          classes.iconVariant
                        )}
                      />
                      Security Lifetime Saved successfully
                    </span>
                  }
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={(): void => setOpenNotification(false)}
                    >
                      <CloseIcon className={classes.icon} />
                    </IconButton>,
                  ]}
                />
              )}
              {!success && (
                <SnackbarContent
                  className={classnames(classes.error)}
                  aria-describedby="client-snackbar"
                  message={
                    <span id="client-snackbar" className={classes.message}>
                      <ErrorIcon
                        className={classnames(
                          classes.icon,
                          classes.iconVariant
                        )}
                      />
                      Error: {error && error.message}
                    </span>
                  }
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={(): void => setOpenNotification(false)}
                    >
                      <CloseIcon className={classes.icon} />
                    </IconButton>,
                  ]}
                />
              )}
            </div>
          </Snackbar>
        </div>
      </Dialog>
    </div>
  );
};

EditDialogForm.defaultProps = {
  model: undefined,
  error: undefined,
  success: false,
  account: undefined,
};

export default EditDialogForm;
