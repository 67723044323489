import { getCurrentMonth, getCurrentYear } from '../helpers/utils';

const defaultClientData = {
  dates: [],
  selectedRows: [],
  selectedColumns: [],
  selectedHistory: [],
  expandedRows: [],
  historyActive: false,
  assetsChart: 'assetClassSetPortions',
  regccyChart: 'currencyPortions',
  chartingStartYear: undefined,
  chartingStartMonth: getCurrentMonth() === 0 ? 12 : getCurrentMonth(),
  chartingEndYear:
    getCurrentMonth() === 0 ? getCurrentYear() - 1 : getCurrentYear(),
  chartingEndMonth: getCurrentMonth() === 0 ? 12 : getCurrentMonth(),
  chartingRiskFree: 3,
  chartingShowingRows: [],
  monitorShowingRows: [],
  performSharp: 3,
  hasSetSelectedRows: false,
  monitorChart: 'Risk',
  selectedTab: 3,
  allocationFirstStatus: 'Total',
  allocationSecondStatus: 'Actual',
  selectedDate: {
    month: getCurrentMonth() === 0 ? 12 : getCurrentMonth(),
    year: getCurrentMonth() === 0 ? getCurrentYear() - 1 : getCurrentYear(),
  },
  rowsNumber: 0,
};

export default defaultClientData;
