import * as types from '../constants/Correlation';

const Correlation = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.CORRELATION_MATRIX_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CORRELATION_MATRIX_SUCCESS:
      return {
        ...state,
        loading: false,
        matrix: action.matrix,
      };
    case types.CORRELATION_MATRIX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SAVE_CORRELATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SAVE_CORRELATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        correlation: action.correlation,
      };
    case types.SAVE_CORRELATION_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.CHANGE_CORRELATION_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CHANGE_CORRELATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        correlation: action.correlation,
      };
    case types.CHANGE_CORRELATION_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default Correlation;
