import React, { useState, useEffect, ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dates from '../../constants/Date';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import { BenchmarkType, UserType, MutateBenchmarkType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  onClose: () => void;
  model?: BenchmarkType;
  open: boolean;
  edit: (id: number, obj: MutateBenchmarkType) => void;
  success: boolean;
  loading: boolean;
  error: any;
  getUserItem: (id: number) => Promise<UserType>;
  isCurrentEnvironmentMaster: boolean;
}

const DialogForm = (props: Props): ReactElement => {
  const {
    model,
    open,
    getUserItem,
    onClose,
    edit,
    loading,
    success,
    error,
    isCurrentEnvironmentMaster,
  } = props;

  const [riskFactor, setRiskFactor] = useState<string | number>('');
  const [returnFactor, setReturnFactor] = useState<string | number>('');
  const [global, setGlobal] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [modifyDate, setModifyDate] = useState<string>('');
  const [relatedUsername, setRelatedUsername] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (!model || !open) return;
    setRiskFactor(model.riskFactor);
    setReturnFactor(model.returnFactor);
    setGlobal(model.global);
    setHidden(model.hidden);
  }, [model, open]);

  useEffect(() => {
    if (!model || !open) return;
    const { lastModifiedBy, lastModifiedDate, createdDate } = model;
    if (lastModifiedBy && lastModifiedDate !== undefined) {
      getUserItem(lastModifiedBy).then((user: UserType) => {
        if (lastModifiedDate === createdDate) {
          const date = new Date(lastModifiedDate);
          const monthObj = Dates.months.find(
            each => each.valueNumber === date.getMonth() + 1
          );
          const month = monthObj && monthObj.value;
          const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
          setIsUpdated(true);
          setRelatedUsername(user.name);
          setModifyDate(newModifyDate);
        } else {
          const date = new Date(lastModifiedDate);
          const monthObj = Dates.months.find(
            each => each.valueNumber === date.getMonth() + 1
          );
          const month = monthObj && monthObj.value;
          const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
          setIsUpdated(true);
          setRelatedUsername(user.name);
          setModifyDate(newModifyDate);
        }
      });
    } else {
      setIsUpdated(false);
      setModifyDate('');
      setRelatedUsername('');
    }
  }, [open, model, getUserItem]);

  const resetFields = (): void => {
    setRiskFactor('');
    setReturnFactor('');
    setGlobal(false);
    setHidden(false);
    setShowError(false);
    setShowNotification(false);
    setIsUpdated(false);
    setModifyDate('');
    setRelatedUsername('');
  };

  const handleClose = (): void => {
    resetFields();
    onClose();
  };

  const canSubmit = (): boolean => {
    if (riskFactor === '' || returnFactor === '') return false;
    return true;
  };

  const submit = (): void => {
    if (canSubmit() && model) {
      edit(model.id, {
        riskFactor: Number(riskFactor),
        returnFactor: Number(returnFactor),
        global,
        hidden,
      });
      setShowNotification(true);
    } else {
      setShowError(true);
    }
  };

  const retry = (): void => {
    setShowNotification(false);
  };

  let actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions = [
      {
        label: 'Back',
        onClick: retry,
      },
      {
        label: 'Cancel',
        onClick: handleClose,
        type: 'secondary',
      },
    ];
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      actions={actions}
      title={`Edit ${model ? model.name : ''}`}
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Benchmark Updated successfully.
          </Typography>
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error: {(error && error.messages && error.messages[0]) || 'Unknown'}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div className={classes.formContainerForm}>
          <TextField
            error={showError && riskFactor === ''}
            id="outlined-risk-factor"
            label="Risk Factor"
            value={riskFactor}
            onChange={(e: any): void => setRiskFactor(e.target.value)}
            margin="normal"
            variant="outlined"
            helperText={showError && riskFactor === '' && 'Required'}
            className={classes.fullField}
          />
          <TextField
            id="outlined-return-factor"
            label="Return Factor"
            value={returnFactor}
            onChange={(e: any): void => setReturnFactor(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
          />
          {isCurrentEnvironmentMaster && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={global}
                  onChange={(e: any): void => setGlobal(e.target.checked)}
                  value="checkedA"
                  color="primary"
                  classes={{ root: classes.checkboxroot }}
                />
              }
              label="Global"
              className={classes.checkbox}
              classes={{
                label: classes.labelCheckbox,
              }}
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={hidden}
                onChange={(e: any): void => setHidden(e.target.checked)}
                value="checkedB"
                color="primary"
                classes={{ root: classes.checkboxroot }}
              />
            }
            label="Hidden"
            className={classes.checkbox}
            classes={{
              label: classes.labelCheckbox,
            }}
          />
          {relatedUsername !== '' && (
            <Typography variant="subtitle2" className={classes.info}>
              {isUpdated
                ? `Updated by: ${relatedUsername} on ${modifyDate}`
                : `Created by: ${relatedUsername} on ${modifyDate}`}
            </Typography>
          )}
        </div>
      )}
    </Dialog>
  );
};

DialogForm.defaultProps = {
  model: undefined,
  error: undefined,
  success: false,
  loading: false,
};

export default DialogForm;
