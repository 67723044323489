export const CLIENT_TYPES_LIST_REQUEST = 'CLIENT_TYPES_LIST_REQUEST';
export const CLIENT_TYPES_LIST_SUCCESS = 'CLIENT_TYPES_LIST_SUCCESS';
export const CLIENT_TYPES_LIST_FAILURE = 'CLIENT_TYPES_LIST_FAILURE';
export const CLIENT_TYPE_CREATE_REQUEST = 'CLIENT_TYPE_CREATE_REQUEST';
export const CLIENT_TYPE_CREATE_SUCCESS = 'CLIENT_TYPE_CREATE_SUCCESS';
export const CLIENT_TYPE_CREATE_FAILURE = 'CLIENT_TYPE_CREATE_FAILURE';
export const CLIENT_TYPE_UPDATE_REQUEST = 'CLIENT_TYPE_UPDATE_REQUEST';
export const CLIENT_TYPE_UPDATE_SUCCESS = 'CLIENT_TYPE_UPDATE_SUCCESS';
export const CLIENT_TYPE_UPDATE_FAILURE = 'CLIENT_TYPE_UPDATE_FAILURE';
export const CLIENT_TYPE_ITEM_REQUEST = 'CLIENT_TYPE_ITEM_REQUEST';
export const CLIENT_TYPE_ITEM_SUCCESS = 'CLIENT_TYPE_ITEM_SUCCESS';
export const CLIENT_TYPE_ITEM_FAILURE = 'CLIENT_TYPE_ITEM_FAILURE';
export const CLIENT_TYPE_REMOVE_REQUEST = 'CLIENT_TYPE_REMOVE_REQUEST';
export const CLIENT_TYPE_REMOVE_SUCCESS = 'CLIENT_TYPE_REMOVE_SUCCESS';
export const CLIENT_TYPE_REMOVE_FAILURE = 'CLIENT_TYPE_REMOVE_FAILURE';
