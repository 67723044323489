import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';

const defaultTheme = createMuiTheme();

const theme: any = {
  palette,
  toolbar: {
    height: 60,
  },
  spacing: 4,
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h1: {
        color: 'palette.primary.main',
        fontWeight: 600,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        [defaultTheme.breakpoints.between('xs', 'sm')]: {
          fontSize: '12px',
        },
        [defaultTheme.breakpoints.between('sm', 'md')]: {
          fontSize: '16px',
        },
        [defaultTheme.breakpoints.between('md', 'lg')]: {
          fontSize: '24px',
        },
        [defaultTheme.breakpoints.up('lg')]: {
          fontSize: '24px',
        },
      },
      h2: {
        color: 'palette.primary.main',
        fontWeight: 600,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        [defaultTheme.breakpoints.between('xs', 'sm')]: {
          fontSize: '12px',
        },
        [defaultTheme.breakpoints.between('sm', 'md')]: {
          fontSize: '16px',
        },
        [defaultTheme.breakpoints.between('md', 'lg')]: {
          fontSize: '24px',
        },
        [defaultTheme.breakpoints.up('lg')]: {
          fontSize: '24px',
        },
      },
      h3: {
        fontWeight: 500,
        color: 'rgb(0, 0 , 0 ,0.7)',
        textTransform: 'uppercase',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '12px',
        },
        [defaultTheme.breakpoints.between('sm', 'md')]: {
          fontSize: '14px',
        },
        [defaultTheme.breakpoints.between('md', 'lg')]: {
          fontSize: '13px',
        },
        [defaultTheme.breakpoints.up('lg')]: {
          fontSize: '13px',
        },
      },
      subtitle1: {
        fontSize: '16px',
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: '14px',
      },
    },
    MuiInput: {
      root: {
        height: 30,
      },
    },
    MuiOutlinedInput: {
      root: {
        height: 40,
      },
    },
    MuiPrivateNotchedOutline: {
      root: {
        height: 40,
      },
    },
    MuiBackdrop: {
      root: {},
    },
    MuiFilledInput: {
      root: {
        padding: 0,
      },
      input: {
        padding: 0,
        fontSize: '12px',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 10px) scale(1)',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '13px',
        opacity: 0.7,
        '&:focus': {
          opacity: 1,
        },
      },
    },
    MuiOutlinedButton: {
      fontWeight: 700,
      lineHeight: '40px',
    },
    MuiButton: {
      outlined: {
        height: 40,
        overflow: 'hidden',
        color: palette.primary.main,
        border: `1px solid ${palette.primary.main}`,
        [defaultTheme.breakpoints.between('xs', 'sm')]: {
          minWidth: 100,
          padding: '0 4px',
        },
        [defaultTheme.breakpoints.between('sm', 'md')]: {
          minWidth: 120,
          padding: '0 6px',
        },
        [defaultTheme.breakpoints.between('md', 'lg')]: {
          minWidth: 150,
          padding: '0 8px',
        },
        [defaultTheme.breakpoints.up('lg')]: {
          minWidth: 180,
          padding: '0 10px',
        },
      },
      outlinedPrimary: {
        height: 40,
        lineHeight: '40px',
        padding: '5px 10px',
        overflow: 'hidden',
        color: palette.primary.main,
        [defaultTheme.breakpoints.between('xs', 'sm')]: {
          minWidth: '40px',
          padding: '0 4px',
        },
        [defaultTheme.breakpoints.between('sm', 'md')]: {
          minWidth: '50px',
          padding: '0 6px',
        },
        [defaultTheme.breakpoints.between('md', 'lg')]: {
          minWidth: '60px',
          padding: '0 8px',
        },
        [defaultTheme.breakpoints.up('lg')]: {
          minWidth: '70px',
          padding: '0 10px',
        },
      },
      outlinedSecondary: {
        height: 40,
        padding: '0 10px',
        overflow: 'hidden',
        color: '#dc004e',
        [defaultTheme.breakpoints.between('xs', 'sm')]: {
          minWidth: '50px',
        },
        [defaultTheme.breakpoints.between('sm', 'md')]: {
          minWidth: '60px',
        },
        [defaultTheme.breakpoints.between('md', 'lg')]: {
          minWidth: '70px',
        },
        [defaultTheme.breakpoints.up('lg')]: {
          minWidth: '80px',
        },
      },
      label: {
        [defaultTheme.breakpoints.between('xs', 'sm')]: {
          fontSize: '10px',
        },
        [defaultTheme.breakpoints.between('sm', 'md')]: {
          fontSize: '11px',
        },
        [defaultTheme.breakpoints.between('md', 'lg')]: {
          fontSize: '13px',
        },
        [defaultTheme.breakpoints.up('lg')]: {
          fontSize: '13px',
        },
      },
    },
    MuiIconButton: {
      root: {},
      colorPrimary: {
        color: palette.primary.main,
      },
      colorInherit: {
        color: '#000000',
      },
    },
    props: {
      MuiInputLabel: {
        shrink: true,
      },
      MuiInput: {
        root: {
          height: 30,
        },
        disableUnderline: true,
      },
    },
  },
};

export default theme;
