import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const list = (): any =>
  Request('accounttype', 'GET')
    .then(ErrorHandler)
    .then((accountTypes: any): any => accountTypes);

const item = (id: number): any =>
  Request(`accounttype/${id}`, 'GET')
    .then(ErrorHandler)
    .then((accountType: any): any => accountType);

const remove = (id: number): any =>
  Request(`accounttype/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((accountType: any): any => accountType);

const create = (data: any): any =>
  Request('accounttype', 'POST', data)
    .then(ErrorHandler)
    .then((accountType: any): any => accountType);

const update = (data: any, id: number): any =>
  Request(`accounttype/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((accountType: any): any => accountType);

const AccountTypeService = {
  list,
  item,
  update,
  create,
  remove,
};

export default AccountTypeService;
