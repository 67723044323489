export const USER_FORM_ACTION_REQUEST = 'USER_FORM_ACTION_REQUEST';
export const USER_FORM_ACTION_SUCCESS = 'USER_FORM_ACTION_SUCCESS';
export const USER_FORM_ACTION_FAILURE = 'USER_FORM_ACTION_FAILURE';
export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const REPORT_TOGGLE = 'REPORT_TOGGLE';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_ITEM_REQUEST = 'USER_ITEM_REQUEST';
export const USER_ITEM_SUCCESS = 'USER_ITEM_SUCCESS';
export const USER_ITEM_FAILURE = 'USER_ITEM_FAILURE';
export const USER_ROLES_LIST_REQUEST = 'USER_ROLES_LIST_REQUEST';
export const USER_ROLES_LIST_SUCCESS = 'USER_ROLES_LIST_SUCCESS';
export const USER_ROLES_LIST_FAILURE = 'USER_ROLES_LIST_FAILURE';
export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE';
export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';
export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_FAILURE = 'USER_CHANGE_PASSWORD_FAILURE';
export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST';
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_FAILURE = 'USER_PROFILE_UPDATE_FAILURE';
export const USER_OPEN_FORM = 'USER_OPEN_FORM';
export const USER_CLOSE_FORM = 'USER_CLOSE_FORM';
export const USER_REMOVE_REQUEST = 'USER_REMOVE_REQUEST';
export const USER_REMOVE_SUCCESS = 'USER_REMOVE_SUCCESS';
export const USER_REMOVE_FAILURE = 'USER_REMOVE_FAILURE';
export const REMOVE_CLIENT_CACHE_REQUEST = 'REMOVE_CLIENT_CACHE_REQUEST';
export const REMOVE_CLIENT_CACHE_SUCCESS = 'REMOVE_CLIENT_CACHE_SUCCESS';
export const REMOVE_CLIENT_CACHE_FAILURE = 'REMOVE_CLIENT_CACHE_FAILURE';
export const REMOVE_ALL_CLIENTS_CACHE_REQUEST =
  'REMOVE_ALL_CLIENTS_CACHE_REQUEST';
export const REMOVE_ALL_CLIENTS_CACHE_SUCCESS =
  'REMOVE_ALL_CLIENTS_CACHE_SUCCESS';
export const REMOVE_ALL_CLIENTS_CACHE_FAILURE =
  'REMOVE_ALL_CLIENTS_CACHE_FAILURE';
export const USER_OPEN_CONFIRM_DIALOG = 'USER_OPEN_CONFIRM_DIALOG';
export const USER_CLOSE_CONFIRM_DIALOG = 'USER_CLOSE_CONFIRM_DIALOG';
export const USER_OPEN_REPORT_SETTINGS = 'USER_OPEN_REPORT_SETTINGS';
export const USER_CLOSE_REPORT_SETTINGS = 'USER_CLOSE_REPORT_SETTINGS';
