import * as types from '../constants/Client';

const throttled: any = {};
let timerId: any;

const throttle = (): any => (next: any): any => (action: any): any => {
  const time = action.meta && action.meta.throttle;
  switch (action.type) {
    case types.SET_SELECTED_ROWS:
      if (!throttled.selectedRows) {
        throttled.selectedRows = action.selectedRows;
        timerId = setTimeout(() => next(action), time);
      } else {
        clearTimeout(timerId);
        throttled.selectedRows = action.selectedRows;
        timerId = setTimeout(() => next(action), time);
      }
      break;
    case types.SET_SELECTED_COLUMNS:
      if (!throttled.selectedColumns) {
        throttled.selectedColumns = action.selectedColumns;
        timerId = setTimeout(() => next(action), time);
      } else {
        clearTimeout(timerId);
        throttled.selectedColumns = action.selectedColumns;
        timerId = setTimeout(() => next(action), time);
      }
      break;
    default:
      next(action);
      break;
  }
};

export default throttle;
