import * as types from '../constants/Benchmark';
import * as UserTypes from '../constants/User';

const Benchmark = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.BENCHMARKS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.BENCHMARKS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        benchmarks: action.benchmarks,
      };
    case types.BENCHMARKS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.BENCHMARK_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.BENCHMARK_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.benchmark.reference,
      };
    case types.BENCHMARK_SAVE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.BENCHMARK_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.BENCHMARK_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        benchmark: action.benchmark,
      };
    case types.BENCHMARK_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.BENCHMARK_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.BENCHMARK_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        benchmark: action.benchmark,
      };
    case types.BENCHMARK_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.BENCHMARK_PUSH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.BENCHMARK_PUSH_SUCCESS:
      return {
        ...state,
        loading: false,
        benchmark: action.benchmark,
      };
    case types.BENCHMARK_PUSH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.BENCHMARK_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.BENCHMARK_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        deleted: true,
        reference: 'deleted',
        benchmark: action.benchmark,
      };
    case types.BENCHMARK_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        deleted: false,
        error: action.error,
      };
    case types.BENCHMARK_GET_VALUES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.BENCHMARK_GET_VALUES_SUCCESS:
      return {
        ...state,
        loading: false,
        benchmarkValues: action.benchmarkValues,
      };
    case types.BENCHMARK_GET_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UserTypes.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default Benchmark;
