export const CREATE_ALLOCATION_WEIGHT_REQUEST =
  'CREATE_ALLOCATION_WEIGHT_REQUEST';
export const CREATE_ALLOCATION_WEIGHT_SUCCESS =
  'CREATE_ALLOCATION_WEIGHT_SUCCESS';
export const CREATE_ALLOCATION_WEIGHT_FAILURE =
  'CREATE_ALLOCATION_WEIGHT_FAILURE';
export const UPDATE_ALLOCATION_WEIGHT_REQUEST =
  'UPDATE_ALLOCATION_WEIGHT_REQUEST';
export const UPDATE_ALLOCATION_WEIGHT_SUCCESS =
  'UPDATE_ALLOCATION_WEIGHT_SUCCESS';
export const UPDATE_ALLOCATION_WEIGHT_FAILURE =
  'UPDATE_ALLOCATION_WEIGHT_FAILURE';
export const REMOVE_ALLOCATION_WEIGHT_REQUEST =
  'REMOVE_ALLOCATION_WEIGHT_REQUEST';
export const REMOVE_ALLOCATION_WEIGHT_SUCCESS =
  'REMOVE_ALLOCATION_WEIGHT_SUCCESS';
export const REMOVE_ALLOCATION_WEIGHT_FAILURE =
  'REMOVE_ALLOCATION_WEIGHT_FAILURE';

export const CREATE_ALLOCATION_VALUE_REQUEST =
  'CREATE_ALLOCATION_VALUE_REQUEST';
export const CREATE_ALLOCATION_VALUE_SUCCESS =
  'CREATE_ALLOCATION_VALUE_SUCCESS';
export const CREATE_ALLOCATION_VALUE_FAILURE =
  'CREATE_ALLOCATION_VALUE_FAILURE';
export const UPDATE_ALLOCATION_VALUE_REQUEST =
  'UPDATE_ALLOCATION_VALUE_REQUEST';
export const UPDATE_ALLOCATION_VALUE_SUCCESS =
  'UPDATE_ALLOCATION_VALUE_SUCCESS';
export const UPDATE_ALLOCATION_VALUE_FAILURE =
  'UPDATE_ALLOCATION_WEIGHT_FAILURE';
export const REMOVE_ALLOCATION_VALUE_REQUEST =
  'REMOVE_ALLOCATION_VALUE_REQUEST';
export const REMOVE_ALLOCATION_VALUE_SUCCESS =
  'REMOVE_ALLOCATION_VALUE_SUCCESS';
export const REMOVE_ALLOCATION_VALUE_FAILURE =
  'REMOVE_ALLOCATION_WEIGHT_FAILURE';
