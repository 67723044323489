import React, {
  ReactElement,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from 'react';
import classnames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/EditOutlined';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import domtoimage from 'dom-to-image';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RestartIcon from '@material-ui/icons/RefreshOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import ReportIcon from '@material-ui/icons/AssessmentOutlined';
import Footer from '../Footer';
import TabsComponent from '../Tabs';
import AssetTable from '../../scenes/Client/components/AssetTable/AssetTableContainer';
import Assets from '../../scenes/Client/components/Assets/AssetsContainer';
import Regccy from '../../scenes/Client/components/Reg-ccy/RegccyContainer';
import Monitor from '../../scenes/Client/components/Monitor/MonitorContainer';
import Charting from '../../scenes/Client/components/Charting/ChartingContainer';
import PerformanceComponent from '../../scenes/Client/components/Performance/PerformanceContainer';
import History from '../../scenes/Client/components/History';
import Simulation from '../../scenes/Client/components/Simulation';
import Allocation from '../../scenes/Client/components/Allocation';
import Dialog from '../Dialog';
import DatePicker from '../../components/DatePicker';
import GearLoader from './Loaders/infinity.gif';
import SelectionProfile from '../../scenes/SelectionProfile';
import styles from './styles';
import SelectionIcon from './Loaders/SelectionIcon2.svg';
import {
  ClientType,
  UserProfileType,
  EnvironmentType,
  SelectionProfileType,
} from '../../Types';

let stallingTime = 0; // don't ask about it :)

const useStyles = makeStyles<typeof styles>(styles);

const tabNames = [
  'ASSETS',
  'MONITOR',
  'VALUES',
  'ALLOCATION',
  'REG-CCY',
  'PERFORM',
  'CHARTING',
  'HISTORY',
  'SIMULATION',
];

interface DateObj {
  year: number;
  month: number;
}

interface Props {
  client: ClientType;
  clients: Array<ClientType>;
  selectClient: (obj?: ClientType) => void;
  setDate: (date: string) => void;
  history: any;
  setYear: (year: number) => void;
  setMonth: (month: number) => void;
  selectedMonth: number;
  selectedYear: number;
  month: number;
  getStartDate: (id: number) => Promise<DateObj>;
  loading: boolean;
  loadingData: boolean;
  enabledReport: boolean;
  match: any;
  profile: UserProfileType;
  selectedDate: DateObj;
  setDateToClient: (id: number, obj: DateObj) => void;
  setSelectedTab: (id: number, tabNumber: number) => void;
  selectedTab: number;
  success: boolean;
  error: any;
  selectedRows: Array<number>;
  selectedColumns: Array<number>;
  assetsChart: string;
  regccyChart: string;
  chartingStartYear: number;
  chartingStartMonth: number;
  chartingEndYear: number;
  chartingEndMonth: number;
  chartingRiskFree: number;
  chartingShowingRows: Array<string | number>;
  monitorShowingRows: Array<string | number>;
  performSharp: number;
  monitorChart: string;
  createReportTemplate: (obj: any) => void;
  reportError: any;
  reportSuccess: boolean;
  removeClientCache: (id: number) => Promise<any>;
  currentEnvironment: EnvironmentType;
  firstStatus: string;
  secondStatus: string;
  rowsNumber: number;
  setSelectedSelection?: (
    clientId: number,
    selectedRows: Array<number>,
    selectedColumns: Array<number>
  ) => void;
  selectionProfileSuccess?: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  setSelectionChanged: () => void;
}

const Client = (props: Props): ReactElement => {
  const {
    history,
    client,
    clients,
    selectClient,
    setDate,
    selectedDate,
    match: {
      params: { clientId },
    },
    getStartDate,
    setDateToClient,
    setYear,
    setMonth,
    selectedTab,
    selectedRows,
    selectedColumns,
    createReportTemplate,
    assetsChart,
    monitorShowingRows,
    monitorChart,
    regccyChart,
    performSharp,
    chartingEndMonth,
    chartingEndYear,
    chartingRiskFree,
    chartingStartMonth,
    chartingStartYear,
    chartingShowingRows,
    setSelectedTab,
    removeClientCache,
    enabledReport,
    loading,
    loadingData,
    profile,
    reportSuccess,
    reportError,
    success,
    error,
    currentEnvironment,
    firstStatus,
    secondStatus,
    rowsNumber,
    setSelectedSelection,
    selectionProfileSuccess,
    openDialog,
    closeDialog,
    setSelectionChanged,
    ...others
  } = props;

  const [isSelectionActivated, setIsSelectionActivated] = useState<boolean>(
    false
  );
  const [isHistoryActivated, setIsHistoryActivated] = useState<boolean>(false);
  const [footerPos, setFooterPos] = useState<number>(0);
  const [openReportDialog, setOpenReportDialog] = useState<boolean>(false);
  const [reportImageUrl, setReportImageUrl] = useState<string>('');
  const [reportTitle, setReportTitle] = useState<string>('');
  const [reportSubTitle, setReportSubTitle] = useState<string>('');
  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [firstLoading, setFirstLoading] = useState<boolean>(false);
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [openReportNotification, setOpenReportNotification] = useState<boolean>(
    false
  );
  const [openClearCacheDialog, setOpenClearCacheDialog] = useState<boolean>(
    false
  );
  const [openCacheNotification, setOpenCacheNotification] = useState<boolean>(
    false
  );
  const [cacheSuccess, setCacheSuccess] = useState<boolean>(false);
  const [
    openWarningTemplateNotification,
    setOpenWarningTemplateNotification,
  ] = useState<boolean>(false);
  const [openSelectionProfile, setOpenSelectionProfile] = useState<boolean>(
    false
  );
  const [
    openSelectionProfileNotification,
    setopenSelectionProfileNotification,
  ] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    const foundClient = clients.find(
      eachClient => eachClient.id === client?.id
    );
    if (foundClient) {
      const newClientId = history.location.pathname.split('/').slice(-1)[0];
      if (Number.isNaN(Number(newClientId)) && !client) {
        history.push('/');
      } else if (Number.isNaN(Number(newClientId)) && client) {
        history.push(`/selected-client/${client.id}`);
      } else if (
        !Number.isNaN(Number(newClientId)) &&
        (!client || client.id !== Number(newClientId))
      ) {
        const index = clients.findIndex(
          (c: any): any => c.id === Number(newClientId)
        );
        if (index > -1) {
          selectClient(clients[index]);
          history.push(`/selected-client/${Number(clients[index].id)}`);
        } else {
          history.push('/');
        }
      }
    } else {
      selectClient(undefined);
      history.push('/');
    }
  }, []);

  useEffect(() => {
    setTimeout(() => setFirstLoading(false), 1000);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      setDate(`${selectedDate.year}-${selectedDate.month}`);
      setYear(selectedDate.year);
      setMonth(selectedDate.month);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!client) {
      const index = clients.findIndex(
        (c: any): any => c.id === Number(clientId)
      );
      selectClient(clients[index]);
    }
  }, [clientId]);

  const ref = useRef<any>(undefined);

  useLayoutEffect(() => {});

  const setStart = (): void => {
    getStartDate(client.id).then((date: any): void => {
      setDateToClient(client.id, { ...date });
      setDate(`${date.year}-${date.month}`);
      setYear(date.year);
      setMonth(date.month);
    });
  };

  const goToAccounts = (): void => {
    history.push(`/client/${client.id}/accounts`);
  };

  const goToReporting = (): void => {
    history.push(`/reports`);
  };

  const openReportTemplate = (): void => {
    if (selectedTab === 9) {
      setOpenWarningTemplateNotification(true);
      return;
    }
    let title = '';
    if (selectedTab === 1) title = 'Assets';
    if (selectedTab === 2) title = 'Monitor';
    if (selectedTab === 3) title = 'Values';
    if (selectedTab === 4) title = 'Allocation';
    if (selectedTab === 5) title = 'Region-Currency';
    if (selectedTab === 6) title = 'Performance';
    if (selectedTab === 7) title = 'Charting';
    if (selectedTab === 8) title = 'History';
    if (selectedTab === 9) title = 'Simulation';
    title += `-${selectedDate.month}-${selectedDate.year}`;
    setOpenReportDialog(true);
    setReportLoading(true);
    domtoimage
      .toPng(ref.current)
      .then((dataUrl: string): void => {
        setReportImageUrl(dataUrl);
        setReportTitle(title);
        setReportLoading(false);
      })
      .catch((e: any): void => {
        // eslint-disable-next-line
        console.error('oops, something went wrong!', e);
      });
  };

  const closeReportDialog = (): void => {
    setOpenReportDialog(false);
  };

  const changeTitle = (event: any): void => {
    setReportTitle(event.target.value);
  };

  const changeSubTitle = (event: any): void => {
    setReportSubTitle(event.target.value);
  };

  const saveReportTemplate = (): void => {
    let reportTemplate: { [key: string]: any } = {
      enabledRows: selectedRows,
      disabledColumns: selectedColumns,
      subTitle: reportSubTitle,
      imageBase64: reportImageUrl,
      title: reportTitle,
      refClientId: client.id,
    };
    switch (selectedTab) {
      case 1:
        reportTemplate = {
          ...reportTemplate,
          tabName: 'ASSETS',
          extraData: JSON.stringify({ assetsChart }),
        };
        break;
      case 2:
        reportTemplate = {
          ...reportTemplate,
          tabName: 'MONITOR',
          extraData: JSON.stringify({ monitorChart, monitorShowingRows }),
        };
        break;
      case 4:
        reportTemplate = {
          ...reportTemplate,
          tabName: 'ASEETALLOCATION',
          extraData: JSON.stringify({ firstStatus, secondStatus, rowsNumber }),
        };
        break;
      case 5:
        reportTemplate = {
          ...reportTemplate,
          tabName: 'REGCCY',
          extraData: JSON.stringify({ regccyChart }),
        };
        break;
      case 6:
        reportTemplate = {
          ...reportTemplate,
          tabName: 'PERFORM',
          extraData: JSON.stringify({ performSharp, rowsNumber }),
        };
        break;
      case 7:
        reportTemplate = {
          ...reportTemplate,
          tabName: 'CHARTING',
          extraData: JSON.stringify({
            chartingEndMonth,
            chartingEndYear,
            chartingRiskFree,
            chartingShowingRows,
            chartingStartMonth,
            chartingStartYear,
          }),
        };
        break;
      case 8:
        reportTemplate = {
          ...reportTemplate,
          tabName: 'HISTORY',
          extraData: JSON.stringify({ rowsNumber }),
        };
        break;
      case 9:
        reportTemplate = {
          ...reportTemplate,
          tabName: 'Simulation',
        };
        break;
      default:
        reportTemplate = {
          ...reportTemplate,
          tabName: 'VALUES',
          extraData: JSON.stringify({ rowsNumber }),
        };
        break;
    }
    createReportTemplate(reportTemplate);
    setOpenReportDialog(false);
    setReportTitle('');
    setReportSubTitle('');
    setOpenReportNotification(true);
  };

  const changeTab = (value: number): void => {
    setSelectedTab(client.id, value);
  };

  const closeNotification = (): void => {
    setOpenNotification(false);
  };

  const closeReportNotification = (): void => {
    setOpenReportNotification(false);
  };

  const closeCacheNotification = (): void => {
    setOpenCacheNotification(false);
  };

  const openClearClientCache = (): void => {
    setOpenClearCacheDialog(true);
  };

  const closeClearCacheDialog = (): void => {
    setOpenClearCacheDialog(false);
  };

  const clearClientCache = (): void => {
    removeClientCache(client.id)
      .then((): void => {
        setOpenCacheNotification(true);
        setCacheSuccess(true);
      })
      .catch((): void => {
        setOpenCacheNotification(true);
        setCacheSuccess(false);
      });
    closeClearCacheDialog();
  };

  const handleOpenSelectionProfileDialog = (): void => {
    openDialog();
    setOpenSelectionProfile(true);
  };

  const handleCloseSelectionProfileDialog = (
    hasRemovedSelection?: boolean
  ): void => {
    closeDialog();
    setOpenSelectionProfile(false);
    if (hasRemovedSelection && (selectedTab === 2 || selectedTab === 7)) {
      setSelectionChanged();
    }
  };

  const handleSelectProfile = (
    inputSelectionProfile: SelectionProfileType,
    hasRemovedSelection?: boolean
  ): void => {
    const { enabledRows, disabledColumns } = inputSelectionProfile;
    if (setSelectedSelection)
      setSelectedSelection(client.id, enabledRows, disabledColumns);
    handleCloseSelectionProfileDialog(hasRemovedSelection);
  };

  const tabValues = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const role =
    profile && profile.roles && profile.roles[0] && profile.roles[0].name;

  if (!client) return <div />;

  return (
    <Paper className={classes.root}>
      <div className={classes.appContainer}>
        <Toolbar className={classes.toolbar}>
          <div
            className={classes.pickerContainer}
            style={{ flexDirection: 'column', marginTop: '26px' }}
          >
            <div
              className={classes.pickerContainer}
              style={{ display: 'block', alignSelf: 'start' }}
            >
              <Typography variant="h1" className={classes.clientName}>
                {client.name}
              </Typography>
              {role !== 'VIEWER' && (
                <Tooltip title="Edit">
                  <IconButton
                    className={classes.editButton}
                    onClick={goToAccounts}
                    classes={{
                      root: classes.iconButtonRoot,
                    }}
                  >
                    <EditIcon style={{ fontSize: '21px', color: '#1976d2' }} />
                  </IconButton>
                </Tooltip>
              )}
              {(role === 'ADMIN' || role === 'SUPER_ADMIN') && (
                <Tooltip title="Refresh">
                  <IconButton
                    className={classes.editButton}
                    onClick={openClearClientCache}
                    classes={{
                      root: classes.iconButtonRoot,
                    }}
                    style={{ marginLeft: '-5px' }}
                  >
                    <RestartIcon
                      style={{ fontSize: '21px', color: '#1976d2' }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Selection List">
                <IconButton
                  className={classes.editButton}
                  onClick={handleOpenSelectionProfileDialog}
                  classes={{
                    root: classes.iconButtonRoot,
                  }}
                  style={{ marginLeft: '-5px' }}
                >
                  <img
                    src={SelectionIcon}
                    alt=""
                    style={{ margin: 'auto' }}
                    width={18}
                    height={18}
                  />
                </IconButton>
              </Tooltip>
              {(role === 'ADMIN' || role === 'SUPER_ADMIN') && (
                <Tooltip title="Reporting">
                  <IconButton
                    className={classes.editButton}
                    onClick={goToReporting}
                    classes={{
                      root: classes.iconButtonRoot,
                    }}
                    style={{ marginLeft: '-5px' }}
                  >
                    <ReportIcon
                      style={{ fontSize: '21px', color: '#1976d2' }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            {isSelectionActivated && (
              <Typography variant="h4" className={classes.selection}>
                Selection Activated
              </Typography>
            )}
            {!isSelectionActivated && (
              <Typography variant="h4" className={classes.selection} />
            )}
            {isHistoryActivated && (
              <Typography variant="h4" className={classes.selection}>
                Selection of Historic/Future AM
              </Typography>
            )}
            {!isHistoryActivated && (
              <Typography variant="h4" className={classes.selection} />
            )}
          </div>
          <DatePicker setStart={setStart} />
        </Toolbar>

        <div>
          <TabsComponent
            selectedTab={selectedTab}
            changeTab={changeTab}
            tabs={tabNames.filter(each =>
              each === 'SIMULATION'
                ? currentEnvironment &&
                  currentEnvironment.simulationTab &&
                  profile.simulationTab
                : true
            )}
            values={tabValues}
            showRightTab={enabledReport}
            runRightTabMethod={openReportTemplate}
          />
        </div>
        <div
          className={classes.tabsValue}
          onScroll={(event: any): void => {
            if (
              event.target.scrollLeft < 1000 &&
              new Date().getTime() - stallingTime > 100
            ) {
              setFooterPos(event.target.scrollLeft);
              stallingTime = 0;
            }

            if (event.target.scrollWidth > 10000) {
              stallingTime = new Date().getTime();
            }
          }}
        >
          {(firstLoading || loadingData || loading) && (
            <div
              className={classes.loaderContainer}
              style={{
                opacity:
                  selectedTab === 3 ||
                  selectedTab === 4 ||
                  (loading && !loadingData)
                    ? 0.35
                    : 1,
              }}
            >
              <img
                src={GearLoader}
                alt=""
                style={{ width: 120, zIndex: 3000 }}
              />
            </div>
          )}
          {!firstLoading && (
            <div
              style={{
                width: '100%',
                flex: '1 0 auto',
                display:
                  loadingData && selectedTab !== 3 && selectedTab !== 4
                    ? 'none'
                    : 'inherit',
              }}
            >
              {selectedTab === 1 && (
                <Assets
                  clientId={client.id}
                  parentReference={ref}
                  showNotification={(): void => setOpenNotification(true)}
                  {...others}
                  month={selectedDate.month}
                  year={selectedDate.year}
                />
              )}
              {selectedTab === 2 && (
                <Monitor
                  clientId={client.id}
                  parentReference={ref}
                  showNotification={(): void => setOpenNotification(true)}
                  {...others}
                  month={selectedDate.month}
                  year={selectedDate.year}
                />
              )}
              {selectedTab === 3 && (
                <AssetTable
                  clientId={client.id}
                  showNotification={(): void => setOpenNotification(true)}
                  showSelectionProfileNotification={(): void =>
                    setopenSelectionProfileNotification(true)
                  }
                  activateSelection={(value: boolean): void =>
                    setIsSelectionActivated(value)
                  }
                  activateHistory={(value: boolean): void =>
                    setIsHistoryActivated(value)
                  }
                  parentReference={ref}
                  {...others}
                  month={selectedDate.month}
                  year={selectedDate.year}
                  history={history}
                />
              )}
              {selectedTab === 5 && (
                <Regccy
                  clientId={client.id}
                  showNotification={(): void => setOpenNotification(true)}
                  parentReference={ref}
                  {...others}
                  month={selectedDate.month}
                  year={selectedDate.year}
                />
              )}
              {selectedTab === 6 && (
                <PerformanceComponent
                  clientId={client.id}
                  showNotification={(): void => setOpenNotification(true)}
                  parentReference={ref}
                  {...others}
                  month={selectedDate.month}
                  year={selectedDate.year}
                />
              )}
              {selectedTab === 7 && (
                <Charting
                  clientId={client.id}
                  showNotification={(): void => setOpenNotification(true)}
                  parentReference={ref}
                  {...others}
                  month={selectedDate.month}
                  year={selectedDate.year}
                />
              )}
              {selectedTab === 8 && (
                <History
                  clientId={client.id}
                  showNotification={(): void => setOpenNotification(true)}
                  parentReference={ref}
                  {...others}
                  month={selectedDate.month}
                  year={selectedDate.year}
                />
              )}
              {selectedTab === 4 && (
                <Allocation
                  clientId={client.id}
                  showNotification={(): void => setOpenNotification(true)}
                  parentReference={ref}
                  {...others}
                  month={selectedDate.month}
                  year={selectedDate.year}
                />
              )}

              {currentEnvironment &&
                currentEnvironment.simulationTab &&
                currentEnvironment.type === 'MASTER' && (
                  <Simulation
                    showComponent={selectedTab === 9}
                    clientId={client.id}
                    showNotification={(): void => setOpenNotification(true)}
                    {...others}
                    month={selectedDate.month}
                    year={selectedDate.year}
                  />
                )}
            </div>
          )}
          <Footer
            style={
              // eslint-disable-next-line no-nested-ternary
              firstLoading
                ? {
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                  }
                : loadingData && selectedTab !== 3 && selectedTab !== 4
                ? {
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                  }
                : {
                    marginTop: 50,
                    width: `calc(100% + ${footerPos > 1000 ? 0 : footerPos}px)`,
                  }
            }
            textStyle={{ marginLeft: footerPos }}
          />
          <Dialog
            open={openReportDialog}
            onClose={closeReportDialog}
            title="New Report Template"
            actions={[
              {
                label: 'cancel',
                onClick: closeReportDialog,
                type: 'secondary',
              },
              {
                label: 'save',
                onClick: saveReportTemplate,
                disabled: reportLoading,
              },
            ]}
            paperClass={classes.reportPaperDialog}
          >
            {reportLoading && (
              <div className={classes.container}>
                <LinearProgress className={classes.progress} />
              </div>
            )}
            {!reportLoading && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div>
                  <TextField
                    id="title"
                    label="Title"
                    value={reportTitle}
                    onChange={changeTitle}
                    margin="normal"
                    variant="outlined"
                    className={classes.fullField}
                    style={{ marginTop: 20 }}
                  />
                  <TextField
                    id="subTitle"
                    label="SubTitle"
                    value={reportSubTitle}
                    onChange={changeSubTitle}
                    margin="normal"
                    variant="outlined"
                    className={classes.fullField}
                  />
                </div>
                <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                  <img
                    src={reportImageUrl}
                    alt="report"
                    width={1200}
                    style={{ margin: 'auto' }}
                  />
                </div>
              </div>
            )}
          </Dialog>
        </div>
      </div>
      {openSelectionProfile && (
        <SelectionProfile
          open={openSelectionProfile}
          onClose={handleCloseSelectionProfileDialog}
          clientId={client.id}
          handleSelectProfile={handleSelectProfile}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openNotification && !loading}
        autoHideDuration={3000}
        onClose={closeNotification}
      >
        <div>
          {!success && (
            <SnackbarContent
              className={classnames(classes.error)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <ErrorIcon
                    className={classnames(classes.icon, classes.iconVariant)}
                  />
                  Error:{' '}
                  {(error && error.messages && error.messages[0]) ||
                    'Report the issue'}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={closeNotification}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
            />
          )}
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSelectionProfileNotification && !loadingData}
        autoHideDuration={3000}
        onClose={(): void => setopenSelectionProfileNotification(false)}
      >
        <div>
          {selectionProfileSuccess && (
            <SnackbarContent
              className={classnames(classes.success)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <CheckCircleIcon
                    className={classnames(classes.icon, classes.iconVariant)}
                  />
                  Selection profile added successfully.
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={(): void =>
                    setopenSelectionProfileNotification(false)
                  }
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
            />
          )}
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openReportNotification && !loading}
        autoHideDuration={3000}
        onClose={closeReportNotification}
      >
        <div>
          {reportSuccess && (
            <SnackbarContent
              className={classnames(classes.success)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <CheckCircleIcon
                    className={classnames(classes.icon, classes.iconVariant)}
                  />
                  Report template saved successfully
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={closeReportNotification}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
            />
          )}
          {!reportSuccess && (
            <SnackbarContent
              className={classnames(classes.error)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <ErrorIcon
                    className={classnames(classes.icon, classes.iconVariant)}
                  />
                  Error:{' '}
                  {reportError &&
                    reportError.messages &&
                    reportError.messages[0]}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={closeReportNotification}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
            />
          )}
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openCacheNotification}
        autoHideDuration={3000}
        onClose={closeCacheNotification}
      >
        <div>
          {cacheSuccess && (
            <SnackbarContent
              className={classnames(classes.success)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <CheckCircleIcon
                    className={classnames(classes.icon, classes.iconVariant)}
                  />
                  Client cahce removed successfuly.
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={closeCacheNotification}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
            />
          )}
          {!cacheSuccess && (
            <SnackbarContent
              className={classnames(classes.error)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <ErrorIcon
                    className={classnames(classes.icon, classes.iconVariant)}
                  />
                  Error: Something went wrong, please report the issue!
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={closeCacheNotification}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
            />
          )}
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openWarningTemplateNotification}
        autoHideDuration={3000}
        onClose={(): void => setOpenWarningTemplateNotification(false)}
      >
        <SnackbarContent
          className={classnames(classes.warning)}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <CheckCircleIcon
                className={classnames(classes.icon, classes.iconVariant)}
              />
              Report template generation for this tab is not availble
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={(): void => setOpenWarningTemplateNotification(false)}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
      <Dialog
        open={openClearCacheDialog}
        onClose={closeClearCacheDialog}
        title="Warning"
        actions={[
          {
            label: 'cancel',
            onClick: closeClearCacheDialog,
            type: 'secondary',
          },
          {
            label: 'remove',
            onClick: clearClientCache,
          },
        ]}
      >
        <div className={classes.dailogContainer}>
          <Typography variant="subtitle2" className={classes.text}>
            Are you sure you want to remove all calculation caches of this
            client?
          </Typography>
        </div>
      </Dialog>
    </Paper>
  );
};

export default Client;
