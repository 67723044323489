import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    formContainer: {
      width: '100%',
      height: '100%',
    },
    halfField: {
      width: '48%',
      margin: '5px 5px',
    },
    fieldWithIconContainer: {
      display: 'inline-block',
      width: '48%',
      margin: '5px 5px',
    },
    fullField: { margin: '20px 0px 20px 30px', width: '95%' },
    fieldWithIcon: {
      marginTop: '0px',
      marginLeft: '16px',
      width: 'calc(100% - 40px)',
    },
    icon: {
      marginTop: '5px',
      fontSize: 24,
      opacity: 0.7,
    },
    dropDown: {
      width: '48%',
      marginRight: '5px',
      marginTop: 0,
    },
    label: {
      margin: '5px 0px',
      fontWeight: 600,
      fontSize: 14,
      opacity: 0.8,
    },
    errorText: {
      color: theme.palette.common.red,
    },
    progress: {
      margin: 'auto',
      width: '100%',
      position: 'absolute',
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      minHeight: 100,
    },
    text: {
      margin: '35px',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    title: {
      display: 'inline-block',
      paddingTop: '2px',
    },
    tableCount: {
      height: 26,
      minWidth: 26,
      textAlign: 'center',
      borderRadius: '50%',
      lineHeight: '28px',
      fontSize: 13,
      marginLeft: 12,
      color: '#1976d2',
      backgroundColor: '#0000000d',
      display: 'inline-block',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    toolbar: {
      justifyContent: 'space-between',
      marginTop: '30px',
      marginBottom: '20px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    addButton: {},
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
    },
    invisRoot: {
      minWidth: '20px',
      maxWidth: '20px',
      width: '20px',
    },
    tab: {
      display: 'flex',
    },
    reportDialog: {
      height: '70%',
      width: 1050,
    },
    secondReportDialog: {
      width: '100%',
      height: '100%',
    },
    card: {
      width: 450,
      height: 285,
      position: 'relative',
    },
    media: {
      height: 160,
    },
    paperA4: {
      width: 470,
      height: 'unset',
      padding: 1,
      minHeight: 600,
      boxShadow: 'none',
    },
    leftContainer: {
      width: '48%',
      overflowY: 'auto',
      padding: 10,
    },
    rightContainer: {
      width: '48%',
      overflowY: 'auto',
      padding: 10,
      alignItems: 'right',
    },
    listPaper: {
      width: 470,
      height: 'unset',
      padding: 10,
      minHeight: 260,
    },
    reportContent: {
      display: 'block',
    },
    dateSelectPaper: {
      width: 330,
      height: 'auto',
    },
    dateSelectContainer: {
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      padding: '5px 50px',
    },
    info: {
      color: 'rgb(0,0,0,0.5)',
      marginLeft: 5,
      fontSize: '10px',
      fontWeight: 350,
    },
    iconRoot: {
      padding: '3px',
      color: '#000',
    },
    removeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      zIndex: 100,
    },
    count: {
      position: 'absolute',
      top: 8,
      left: 8,
      backgroundColor: '#0000000d',
      borderRadius: '50%',
      color: '#1976d2',
      minWidth: 26,
      textAlign: 'center',
    },
    test: {
      width: 1400,
    },
    cardField: {
      marginLeft: 30,
      height: 30,
    },
    cardFieldInput: {
      height: 30,
    },
    success: {
      backgroundColor: theme.palette.common.green,
    },
    error: {
      backgroundColor: theme.palette.common.red,
    },
    snackbarIcon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: '10px',
      marginBottom: '4px',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    reportTemplateDialog: {
      height: '70%',
      width: 495,
    },
    userformContainer: {
      width: '100%',
      height: 'calc(100% - 40px)',
      margin: '0px 30px',
      padding: '20px 0px',
      display: 'flex',
    },
    avatar: { width: 240, height: 80 },
    settingTextField: { margin: '5px 5px', width: '98%' },
    textarea: { height: 'unset' },
    formText: {
      margin: '10px 24px',
      fontSize: 12,
      color: 'rgba(0,0,0,0.54)',
    },
    editCardField: {
      height: 30,
    },
    iconButtonRoot: { padding: 4 },
    editButton: {
      marginTop: '-4px',
      marginLeft: 15,
    },
  });

export default styles;
