import BenchmarkService from '../services/BenchmarkService';
import * as types from '../constants/Benchmark';
import { BenchmarkType, BenchmarkValueType } from '../Types';

const getList = (): any => (dispatch: any): any => {
  dispatch({ type: types.BENCHMARKS_LIST_REQUEST });
  return BenchmarkService.list()
    .then((benchmarks: { content: Array<BenchmarkType> }): any => {
      dispatch({ type: types.BENCHMARKS_LIST_SUCCESS, benchmarks });
      return Promise.resolve(benchmarks);
    })
    .catch((error: any): any => {
      dispatch({ type: types.BENCHMARKS_LIST_FAILURE, error });
      return Promise.reject();
    });
};

const createItem = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.BENCHMARK_SAVE_REQUEST });
  return BenchmarkService.parse(data)
    .then((benchmark: any): any => {
      dispatch({ type: types.BENCHMARK_SAVE_SUCCESS, benchmark });
      dispatch(getList());
      return Promise.resolve(benchmark);
    })
    .catch((error: any): void => {
      dispatch({ type: types.BENCHMARK_SAVE_FAILURE, error });
    });
};

const removeItem = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.BENCHMARK_REMOVE_REQUEST });
  BenchmarkService.remove(id)
    .then((benchmark: any): void => {
      dispatch({ type: types.BENCHMARK_REMOVE_SUCCESS, benchmark, id });
      dispatch(getList());
    })
    .catch((error: any): void => {
      dispatch({ type: types.BENCHMARK_REMOVE_FAILURE, error });
    });
};

const getBenchmarkValues = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.BENCHMARK_GET_VALUES_REQUEST });
  return BenchmarkService.getBenchmarkValues(id)
    .then((benchmarkValues: Array<BenchmarkValueType>): void => {
      dispatch({ type: types.BENCHMARK_GET_VALUES_SUCCESS, benchmarkValues });
    })
    .catch((error: any): void => {
      dispatch({ type: types.BENCHMARK_GET_VALUES_FAILURE, error });
    });
};

const getBenchmarkItem = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.BENCHMARK_ITEM_REQUEST });
  return BenchmarkService.getBenchmarkItem(id)
    .then((benchmark: BenchmarkType): void => {
      dispatch({ type: types.BENCHMARK_ITEM_SUCCESS, benchmark });
    })
    .catch((error: any): void => {
      dispatch({ type: types.BENCHMARK_ITEM_FAILURE, error });
    });
};

const editBenchmark = (id: number, data: any): any => (dispatch: any): any => {
  dispatch({ type: types.BENCHMARK_EDIT_REQUEST });
  return BenchmarkService.editBenchmark(id, data)
    .then((benchmark: any): void => {
      dispatch({ type: types.BENCHMARK_EDIT_SUCCESS, benchmark });
      dispatch(getList());
    })
    .catch((error: any): void => {
      dispatch({ type: types.BENCHMARK_EDIT_FAILURE, error });
    });
};

const pushBenchmarks = (): any => (dispatch: any): any => {
  dispatch({ type: types.BENCHMARK_PUSH_REQUEST });
  return BenchmarkService.pushBenchmarks()
    .then((benchmark: any): any => {
      dispatch({ type: types.BENCHMARK_PUSH_SUCCESS, benchmark });
      return Promise.resolve();
    })
    .catch((error: any): any => {
      dispatch({ type: types.BENCHMARK_PUSH_FAILURE, error });
      return Promise.reject();
    });
};

const BenchmarkActions = {
  getList,
  createItem,
  removeItem,
  getBenchmarkValues,
  getBenchmarkItem,
  editBenchmark,
  pushBenchmarks,
};

export default BenchmarkActions;
