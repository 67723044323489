import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const getMatrix = (assetClassSetId: number): any =>
  Request(`correlation/${assetClassSetId}/matrix`, 'GET')
    .then(ErrorHandler)
    .then((matrix: any): any => matrix);

const saveCorrelation = (data: any): any =>
  Request(`correlation`, 'POST', data)
    .then(ErrorHandler)
    .then((correlation: any): any => correlation);

const changeCorrelationStatus = (data: any): any =>
  Request(`correlation/status`, 'POST', data)
    .then(ErrorHandler)
    .then((correlationStatus: any): any => correlationStatus);

const CorrelationService = {
  getMatrix,
  saveCorrelation,
  changeCorrelationStatus,
};

export default CorrelationService;
