import ExportService from '../services/ExportService';
import * as types from '../constants/Export';
import { ExportType } from '../Types';

const exportClientsTable = (): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_CLIENTS_TABLE_REQUEST });
  return ExportService.exportClientsTable()
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_CLIENTS_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_CLIENTS_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportAssetClassSetsTable = (): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_ASSET_CLASS_SETS_TABLE_REQUEST });
  return ExportService.exportAssetClassSetsTable()
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_ASSET_CLASS_SETS_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_ASSET_CLASS_SETS_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportAssetClassesTable = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_ASSET_CLASSES_TABLE_REQUEST });
  return ExportService.exportAssetClassesTable(id)
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_ASSET_CLASSES_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_ASSET_CLASSES_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportRiskClassesTable = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_RISK_CLASSES_TABLE_REQUEST });
  return ExportService.exportRiskClassesTable(id)
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_RISK_CLASSES_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_RISK_CLASSES_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportAssetManagersTable = (): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_ASSET_MANAGERS_TABLE_REQUEST });
  return ExportService.exportAssetManagersTable()
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_ASSET_MANAGERS_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_ASSET_MANAGERS_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportAccountCodesTable = (): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_ACCOUNT_CODES_TABLE_REQUEST });
  return ExportService.exportAccountCodesTable()
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_ACCOUNT_CODES_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_ACCOUNT_CODES_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportAccountTypesTable = (): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_CLIENTS_TABLE_REQUEST });
  return ExportService.exportAccountTypesTable()
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_CLIENTS_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_CLIENTS_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportRegionsTable = (): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_REGIONS_TABLE_REQUEST });
  return ExportService.exportRegionsTable()
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_REGIONS_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_REGIONS_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportCurrenciesTable = (): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_CURRENCIES_TABLE_REQUEST });
  return ExportService.exportCurrenciesTable()
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_CURRENCIES_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_CURRENCIES_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportSecuritiesTable = (): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_SECURITIES_TABLE_REQUEST });
  return ExportService.exportSecuritiesTable()
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_SECURITIES_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_SECURITIES_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportBenchmarksTable = (): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_BENCHMARKS_TABLE_REQUEST });
  return ExportService.exportBenchmarksTable()
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_BENCHMARKS_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_BENCHMARKS_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportBenchmarkValueTable = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_BENCHMARK_VALUE_TABLE_REQUEST });
  return ExportService.exportBenchmarkValueTable(id)
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_BENCHMARK_VALUE_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_BENCHMARK_VALUE_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportAccountsTable = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_ACCOUNTS_TABLE_REQUEST });
  return ExportService.exportAccountsTable(id)
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_ACCOUNTS_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_ACCOUNTS_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const exportClientTypesTable = (): any => (dispatch: any): any => {
  dispatch({ type: types.EXPORT_CLIENT_TYPES_TABLE_REQUEST });
  return ExportService.exportClientTypesTable()
    .then((eTable: ExportType): any => {
      dispatch({ type: types.EXPORT_CLIENT_TYPES_TABLE_SUCCESS, eTable });
      return Promise.resolve(eTable);
    })
    .catch((error: any): any => {
      dispatch({ type: types.EXPORT_CLIENT_TYPES_TABLE_FAILURE, error });
      return Promise.reject(error);
    });
};

const ExportActions = {
  exportClientsTable,
  exportAssetClassSetsTable,
  exportAssetClassesTable,
  exportRiskClassesTable,
  exportAssetManagersTable,
  exportAccountCodesTable,
  exportAccountTypesTable,
  exportRegionsTable,
  exportCurrenciesTable,
  exportSecuritiesTable,
  exportBenchmarksTable,
  exportBenchmarkValueTable,
  exportAccountsTable,
  exportClientTypesTable,
};

export default ExportActions;
