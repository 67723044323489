import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const list = (): any =>
  Request('assetclassset', 'GET')
    .then(ErrorHandler)
    .then((assetClassSets: any): any => assetClassSets);

const item = (id: number): any =>
  Request(`assetclassset/${id}`, 'GET', null, { extended: true })
    .then(ErrorHandler)
    .then((assetClassSet: any): any => assetClassSet);

const create = (data: any): any =>
  Request('assetclassset', 'POST', data)
    .then(ErrorHandler)
    .then((assetClassSet: any): any => assetClassSet);

const update = (data: any, id: number): any =>
  Request(`assetclassset/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((assetClassSet: any): any => assetClassSet);

const remove = (id: number): any =>
  Request(`assetclassset/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((deleted: any): any => deleted);

const checkToRemoveAssetClassSet = (id: number): any =>
  Request(`assetclassset/hasvalue/${id}`, 'GET')
    .then(ErrorHandler)
    .then((hasValueResponse: any): any => hasValueResponse);

const AssetClassSetService = {
  list,
  item,
  create,
  update,
  remove,
  checkToRemoveAssetClassSet,
};

export default AssetClassSetService;
