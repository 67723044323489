export const REPORTS_CLIENT_LIST_REQUEST = 'REPORTS_LIST_REQUEST';
export const REPORTS_CLIENT_LIST_SUCCESS = 'REPORTS_LIST_SUCCESS';
export const REPORTS_CLIENT_LIST_FAILURE = 'REPORTS_LIST_FAILURE';
export const REPORT_CREATE_REQUEST = 'REPORT_CREATE_REQUEST';
export const REPORT_CREATE_SUCCESS = 'REPORT_CREATE_SUCCESS';
export const REPORT_CREATE_FAILURE = 'REPORT_CREATE_FAILURE';
export const REPORT_UPDATE_REQUEST = 'REPORT_UPDATE_REQUEST';
export const REPORT_UPDATE_SUCCESS = 'REPORT_UPDATE_SUCCESS';
export const REPORT_UPDATE_FAILURE = 'REPORT_UPDATE_FAILURE';
export const REPORT_ITEM_REQUEST = 'REPORT_ITEM_REQUEST';
export const REPORT_ITEM_SUCCESS = 'REPORT_ITEM_SUCCESS';
export const REPORT_ITEM_FAILURE = 'REPORT_ITEM_FAILURE';
export const REPORT_REMOVE_REQUEST = 'REPORT_REMOVE_REQUEST';
export const REPORT_REMOVE_SUCCESS = 'REPORT_REMOVE_SUCCESS';
export const REPORT_REMOVE_FAILURE = 'REPORT_REMOVE_FAILURE';
export const REPORT_TEMPLATE_CREATE_REQUEST = 'REPORT_TEMPLATE_CREATE_REQUEST';
export const REPORT_TEMPLATE_CREATE_SUCCESS = 'REPORT_TEMPLATE_CREATE_SUCCESS';
export const REPORT_TEMPLATE_CREATE_FAILURE = 'REPORT_TEMPLATE_CREATE_FAILURE';
export const REPORT_TEMPLATE_LIST_REQUEST = 'REPORT_TEMPLATE_LIST_REQUEST';
export const REPORT_TEMPLATE_LIST_SUCCESS = 'REPORT_TEMPLATE_LIST_SUCCESS';
export const REPORT_TEMPLATE_LIST_FAILURE = 'REPORT_TEMPLATE_LIST_FAILURE';
export const REPORT_TEMPLATE_REMOVE_REQUEST = 'REPORT_TEMPLATE_REMOVE_REQUEST';
export const REPORT_TEMPLATE_REMOVE_SUCCESS = 'REPORT_TEMPLATE_REMOVE_SUCCESS';
export const REPORT_TEMPLATE_REMOVE_FAILURE = 'REPORT_TEMPLATE_REMOVE_FAILURE';
export const REPORT_TEMPLATE_UPDATE_REQUEST = 'REPORT_TEMPLATE_UPDATE_REQUEST';
export const REPORT_TEMPLATE_UPDATE_SUCCESS = 'REPORT_TEMPLATE_UPDATE_SUCCESS';
export const REPORT_TEMPLATE_UPDATE_FAILURE = 'REPORT_TEMPLATE_UPDATE_FAILURE';
export const REPORT_SETTING_REQUEST = 'REPORT_SETTING_REQUEST';
export const REPORT_SETTING_SUCCESS = 'REPORT_SETTING_SUCCESS';
export const REPORT_SETTING_FAILURE = 'REPORT_SETTING_FAILURE';
export const REPORT_SETTING_UPDATE_REQUEST = 'REPORT_SETTING_UPDATE_REQUEST';
export const REPORT_SETTING_UPDATE_SUCCESS = 'REPORT_SETTING_UPDATE_SUCCESS';
export const REPORT_SETTING_UPDATE_FAILURE = 'REPORT_SETTING_UPDATE_FAILURE';
