import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const getClientTypeList = (): any =>
  Request('clientType', 'GET')
    .then(ErrorHandler)
    .then((clientTypes: any): any => clientTypes);

const getClientTypeItem = (id: number): any =>
  Request(`clientType/${id}`, 'GET')
    .then(ErrorHandler)
    .then((clientType: any): any => clientType);

const removeClientType = (id: number): any =>
  Request(`clientType/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((clientType: any): any => clientType);

const createClientType = (data: any): any =>
  Request('clientType', 'POST', data)
    .then(ErrorHandler)
    .then((clientType: any): any => clientType);

const updateClientType = (data: any, id: number): any =>
  Request(`clientType/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((clientType: any): any => clientType);

const ClientTypeService = {
  getClientTypeList,
  getClientTypeItem,
  updateClientType,
  createClientType,
  removeClientType,
};

export default ClientTypeService;
