import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const list = (): any =>
  Request('assetmanager', 'GET')
    .then(ErrorHandler)
    .then((assetManagers: any): any => assetManagers);

const item = (id: number): any =>
  Request(`assetmanager/${id}`, 'GET')
    .then(ErrorHandler)
    .then((assetManager: any): any => assetManager);

const create = (data: any): any =>
  Request('assetmanager', 'POST', data)
    .then(ErrorHandler)
    .then((assetManager: any): any => assetManager);

const update = (data: any, id: number): any =>
  Request(`assetmanager/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((assetManager: any): any => assetManager);

const remove = (id: number): any =>
  Request(`assetmanager/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((assetManager: any): any => assetManager);

const AssetManagerService = {
  list,
  item,
  create,
  update,
  remove,
};

export default AssetManagerService;
