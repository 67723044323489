export const RISK_CLASSES_LIST_REQUEST = 'RISK_CLASSES_LIST_REQUEST';
export const RISK_CLASSES_LIST_SUCCESS = 'RISK_CLASSES_LIST_SUCCESS';
export const RISK_CLASSES_LIST_FAILURE = 'RISK_CLASSES_LIST_FAILURE';
export const RISK_CLASS_ITEM_REQUEST = 'RISK_CLASS_ITEM_REQUEST';
export const RISK_CLASS_ITEM_SUCCESS = 'RISK_CLASS_ITEM_SUCCESS';
export const RISK_CLASS_ITEM_FAILURE = 'RISK_CLASS_ITEM_FAILURE';
export const RISK_CLASS_CREATE_REQUEST = 'RISK_CLASS_CREATE_REQUEST';
export const RISK_CLASS_CREATE_SUCCESS = 'RISK_CLASS_CREATE_SUCCESS';
export const RISK_CLASS_CREATE_FAILURE = 'RISK_CLASS_CREATE_FAILURE';
export const RISK_CLASS_UPDATE_REQUEST = 'RISK_CLASS_UPDATE_REQUEST';
export const RISK_CLASS_UPDATE_SUCCESS = 'RISK_CLASS_UPDATE_SUCCESS';
export const RISK_CLASS_UPDATE_FAILURE = 'RISK_CLASS_UPDATE_FAILURE';
export const RISK_CLASS_REMOVE_REQUEST = 'RISK_CLASS_REMOVE_REQUEST';
export const RISK_CLASS_REMOVE_SUCCESS = 'RISK_CLASS_REMOVE_SUCCESS';
export const RISK_CLASS_REMOVE_FAILURE = 'RISK_CLASS_REMOVE_FAILURE';
