import React, { useState, useEffect, ReactElement } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import DialogForm from './DialogForm';
import styles from './styles';
import {
  CurrencyType,
  ExportType,
  UserType,
  MutateCurrencyType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  getCurrenciesList: () => void;
  removeCurrency: (id: number) => void;
  createCurrency: (obj: MutateCurrencyType) => void;
  updateCurrency: (obj: MutateCurrencyType, id: number) => void;
  currencies: { content: Array<CurrencyType> };
  openDialog: () => void;
  closeDialog: () => void;
  deleted: boolean;
  success: boolean;
  error: any;
  loading: boolean;
  getUserItem: (id: number) => Promise<UserType>;
  exportCurrenciesTable: () => Promise<ExportType>;
}

const Currency = (props: Props): ReactElement => {
  const {
    getCurrenciesList,
    openDialog,
    closeDialog,
    currencies,
    updateCurrency,
    createCurrency,
    removeCurrency,
    exportCurrenciesTable,
    loading,
    error,
    success,
    deleted,
    getUserItem,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<CurrencyType | undefined>(
    undefined
  );
  const [model, setModel] = useState<CurrencyType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    getCurrenciesList();
  }, [getCurrenciesList]);

  const openAdd = (): void => {
    openDialog();
    setModel(undefined);
    setOpen(true);
  };

  const openEdit = (event: any, row: CurrencyType): void => {
    openDialog();
    setModel(row);
    setOpen(true);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setIsRemoving(false);
    setOpenDelete(false);
  };

  const handleDeleteOpen = (event: any, row: CurrencyType): void => {
    openDialog();
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const handleClose = (): void => {
    closeDialog();
    setOpen(false);
  };

  const onRowSelection = (row: CurrencyType): void => {
    setSelectedRow(row);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) removeCurrency(selectedRow.id);
  };

  const exportTable = (): void => {
    exportCurrenciesTable().then((res: ExportType) => {
      const csvData = new Blob([res.export], {
        type: 'text/csv;charset=utf-8;',
      });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', res.fileName || 'Currencies.csv');
      tempLink.click();
    });
  };

  const columns = [
    {
      title: 'Name',
      name: 'name',
      sortable: true,
      type: 'string',
      sortField: 'name',
    },
    { title: 'Symbol', name: 'symbol' },
  ];

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          hasEditAndDelete
          onEdit={openEdit}
          onDelete={handleDeleteOpen}
          onAdd={openAdd}
          onRowSelection={onRowSelection}
          columns={columns}
          noMargin
          hasExportButton
          hasHighlightBackground
          onExport={exportTable}
          rows={currencies && currencies.content}
          entity="Currency"
          entities="Currencies"
        />
      </div>
      <DialogForm
        model={model}
        open={open}
        onClose={handleClose}
        update={updateCurrency}
        create={createCurrency}
        loading={loading}
        success={success}
        error={error}
        getUserItem={getUserItem}
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {isRemoving && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Currency removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error: {error && error.messages && error.messages[0]}
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default Currency;
