import * as types from '../constants/SelectionProfile';
import * as UserTypes from '../constants/User';

const SelectionProfile = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.SELECTION_PROFILE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SELECTION_PROFILE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        selectionProfileList: action.selectionProfileList,
      };
    case types.SELECTION_PROFILE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SELECTION_PROFILE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SELECTION_PROFILE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.SELECTION_PROFILE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.SELECTION_PROFILE_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SELECTION_PROFILE_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        reference: 'deleted',
        selectionProfileList: state.selectionProfileList.filter(
          (sp: any): any => sp.id !== action.selectionProfileId
        ),
      };
    case types.SELECTION_PROFILE_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    case UserTypes.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default SelectionProfile;
