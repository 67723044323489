import React, {
  useRef,
  useLayoutEffect,
  useState,
  ReactElement,
  useEffect,
} from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Container, Draggable } from 'react-smooth-dnd';
import Dates from '../../constants/Date';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import FirstPage from './images/FirstPage.png';
import LastPage from './images/LastPage.png';
import {
  ReportTemplateType,
  ReportingTabTemplateType,
  UserType,
  MutateReportingTemplateType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface DragableCardComponentProps {
  classes: any;
  card: any;
  type: string;
  remove: (id: number) => void;
  count: number;
  changeTitle: (value: string, id: number) => void;
  changeSubtitle: (value: string, id: number) => void;
}

const DragableCardComponent = (
  props: DragableCardComponentProps
): ReactElement => {
  const {
    card,
    classes,
    type,
    remove,
    count,
    changeTitle,
    changeSubtitle,
  } = props;

  const [focused, setFocused] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [hovered, setHovered] = useState<string>('');

  return (
    <div style={{ margin: '20px 10px' }}>
      <Card className={classes.card}>
        {type === 'list' && card.id !== 'cover' && card.id !== 'contact' && (
          <Tooltip title="Remove">
            <IconButton
              onClick={(): void => remove(card.id)}
              aria-label="Expand"
              style={{ marginRight: -2, marginLeft: -8 }}
              classes={{
                root: classes.iconRoot,
              }}
              className={classes.removeIcon}
            >
              <RemoveIcon style={{ fontSize: '20px' }} />
            </IconButton>
          </Tooltip>
        )}
        {type === 'selected' && (
          <Typography className={classes.count}>{count}</Typography>
        )}
        <CardActionArea>
          <CardContent>
            {focused !== 'title' && (
              <Typography
                gutterBottom
                variant="h6"
                component="h5"
                style={{
                  color: '#1976d2',
                  marginLeft: type === 'selected' ? 30 : 0,
                  marginTop: '-10px',
                }}
                onMouseEnter={(): void => setHovered('title')}
                onMouseLeave={(): void => setHovered('')}
              >
                {card.title}
                {hovered === `title` &&
                  type === 'selected' &&
                  card.id !== 'cover' &&
                  card.id !== 'contact' && (
                    <IconButton
                      className={classes.editButton}
                      onClick={(): void => setFocused('title')}
                      classes={{
                        root: classes.iconButtonRoot,
                      }}
                    >
                      <EditIcon
                        style={{ fontSize: '18px', color: '#1976d2' }}
                      />
                    </IconButton>
                  )}
              </Typography>
            )}
            {focused === 'title' && (
              <TextField
                id="title"
                label="Title"
                value={value || card.title}
                onChange={(e: any): void => {
                  setValue(e.target.value);
                  changeTitle(e.target.value, card.id);
                }}
                variant="outlined"
                className={classes.cardField}
                onBlur={(): void => {
                  setValue('');
                  setFocused('');
                }}
                onKeyPress={(event: any): void => {
                  if (event.key === 'Enter' || event.key === 'Escape') {
                    setFocused('');
                    setValue('');
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.cardFieldInput,
                  },
                }}
              />
            )}
            {focused !== 'subtitle' && (
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="p"
                style={{ height: 30, marginLeft: 30 }}
                onMouseEnter={(): void => setHovered('subtitle')}
                onMouseLeave={(): void => setHovered('')}
              >
                {card.subtitle}
                {hovered === `subtitle` &&
                  type === 'selected' &&
                  card.id !== 'cover' &&
                  card.id !== 'contact' && (
                    <IconButton
                      className={classes.editButton}
                      onClick={(): void => setFocused('subtitle')}
                      classes={{
                        root: classes.iconButtonRoot,
                      }}
                    >
                      <EditIcon
                        style={{ fontSize: '18px', color: '#1976d2' }}
                      />
                    </IconButton>
                  )}
              </Typography>
            )}
            {focused === 'subtitle' && (
              <TextField
                id="subtitle"
                label="Subtitle"
                value={value || card.subtitle}
                onChange={(e: any): void => {
                  setValue(e.target.value);
                  changeSubtitle(e.target.value, card.id);
                }}
                variant="outlined"
                className={classes.cardField}
                onBlur={(): void => {
                  setValue('');
                  setFocused('');
                }}
                onKeyPress={(event: any): void => {
                  if (event.key === 'Enter' || event.key === 'Escape') {
                    setFocused('');
                    setValue('');
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.cardFieldInput,
                  },
                }}
              />
            )}
          </CardContent>
          <CardMedia
            className={classes.media}
            image={card.imageBase64}
            title={card.title}
          />
        </CardActionArea>
      </Card>
    </div>
  );
};

interface Props {
  onClose: () => void;
  model?: ReportTemplateType;
  open: boolean;
  update: (obj: MutateReportingTemplateType, id: number) => void;
  create: (obj: MutateReportingTemplateType) => void;
  success: boolean;
  loading: boolean;
  error: any;
  getUserItem: (id: number) => Promise<UserType>;
  reportTemplates: Array<ReportingTabTemplateType>;
  clientId: number;
  removeReportTemplate: (id: number) => void;
  deleted: boolean;
}

const DialogForm = (props: Props): ReactElement => {
  const {
    model,
    open,
    reportTemplates,
    getUserItem,
    onClose,
    update,
    create,
    clientId,
    removeReportTemplate,
    loading,
    success,
    error,
    deleted,
  } = props;

  const [name, setName] = useState<string>('');
  const [selectedTemplates, setSelectedTemplates] = useState<any[]>([]);
  const [repTemplates, setRepTemplates] = useState<any[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [modifyDate, setModifyDate] = useState<string>('');
  const [relatedUsername, setRelatedUsername] = useState<string>('');
  const [selectedReportTemplate, setSelectedReportTemplate] = useState<any>(
    undefined
  );
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [hasCover, setHasCover] = useState<boolean>(false);
  const [hasDisclaimer, setHasDisclaimer] = useState<boolean>(false);
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    // remove warning
  });

  const setModel = (
    newReportTemplates: Array<ReportingTabTemplateType>,
    newModel?: ReportTemplateType
  ): void => {
    const templates: Array<any> = newModel?.templates || [];
    const sortedTemplates = templates.sort(
      (firstTemplate: any, secondTemplate: any) => {
        if (firstTemplate?.orderno && secondTemplate?.orderno) {
          return firstTemplate?.orderno - secondTemplate?.orderno;
        }
        return 0;
      }
    );
    const reportTemplatesWithCover: Array<any> = !newModel?.hasCover
      ? [
          {
            id: 'cover',
            title: 'Cover',
            type: 'templateCard',
            imageBase64: FirstPage,
            subtitle: '',
          },
        ].concat(...(newReportTemplates as Array<any>))
      : [...newReportTemplates];
    const reportTemplatesWithCoverAndContact: Array<any> = !newModel?.hasDisclaimer
      ? reportTemplatesWithCover.concat({
          id: 'contact',
          title: 'Contact',
          type: 'templateCard',
          imageBase64: LastPage,
          subtitle: '',
        })
      : [...reportTemplatesWithCover];
    const modelTemplates: Array<any> = newModel?.hasCover
      ? [
          {
            id: 'cover',
            title: 'Cover',
            type: 'templateCard',
            imageBase64: FirstPage,
            subtitle: ' ',
          },
        ].concat(...sortedTemplates)
      : [...sortedTemplates];
    const modelTemplatesWithContact: Array<any> = newModel?.hasDisclaimer
      ? modelTemplates.concat({
          id: 'contact',
          title: 'Contact',
          type: 'templateCard',
          imageBase64: LastPage,
          subtitle: ' ',
        })
      : [...modelTemplates];
    const finalModelTemplates: Array<any> = modelTemplatesWithContact.map(
      (each: any): any => ({
        ...each,
        imageBase64:
          each.id === 'cover' || each.id === 'contact'
            ? each.imageBase64
            : each.refReportingTabTemplate.imageBase64,
        title: each.title || each.refReportingTabTemplate.title,
        subtitle: each.subtitle || each.refReportingTabTemplate.subtitle,
      })
    );
    setName(model?.name || '');
    setSelectedTemplates(finalModelTemplates);
    setRepTemplates(reportTemplatesWithCoverAndContact);
    setHasCover(model?.hasCover || false);
    setHasDisclaimer(model?.hasDisclaimer || false);
  };

  useEffect(() => {
    if (reportTemplates && open) setModel(reportTemplates, model);
  }, [model, reportTemplates, open]);

  useEffect(() => {
    if (!model || !open) return;
    const { lastModifiedBy, lastModifiedDate, createdDate } = model;
    if (lastModifiedBy && lastModifiedDate !== undefined) {
      getUserItem(lastModifiedBy).then((user: UserType) => {
        if (lastModifiedDate === createdDate) {
          const date = new Date(lastModifiedDate);
          const monthObj = Dates.months.find(
            each => each.valueNumber === date.getMonth() + 1
          );
          const month = monthObj && monthObj.value;
          const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
          setIsUpdated(false);
          setRelatedUsername(user?.name);
          setModifyDate(newModifyDate);
        } else {
          const date = new Date(lastModifiedDate);
          const monthObj = Dates.months.find(
            each => each.valueNumber === date.getMonth() + 1
          );
          const month = monthObj && monthObj.value;
          const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
          setIsUpdated(true);
          setRelatedUsername(user?.name);
          setModifyDate(newModifyDate);
        }
      });
    } else {
      setIsUpdated(false);
      setModifyDate('');
      setRelatedUsername('');
    }
  }, [open, model, getUserItem]);

  const resetFields = (): void => {
    setName('');
    setSelectedTemplates([]);
    setShowError(false);
    setShowNotification(false);
    setIsUpdated(false);
    setModifyDate('');
    setRelatedUsername('');
    setHasDisclaimer(false);
    setHasCover(false);
  };

  const handleClose = (): void => {
    resetFields();
    onClose();
  };

  const canSubmit = (): boolean => {
    if (name === '' || selectedTemplates.length < 1) {
      if (inputRef.current !== null)
        inputRef.current.scrollIntoView({ behavior: 'smooth' });
      return false;
    }
    return true;
  };

  const submit = (): void => {
    const templates = selectedTemplates
      .filter(
        (each: any): any => !(each.id === 'cover' || each.id === 'contact')
      )
      .map((each: any, index: number): any => {
        const template: { [key: string]: any } = {
          title: each?.title,
          subTitle: each?.subtitle,
          templateId: each?.refReportingTabTemplate
            ? each?.refReportingTabTemplate?.id
            : each.id,
          orderno: index + 1,
        };
        return template;
      });

    if (canSubmit()) {
      if (model?.id) {
        update(
          {
            name,
            refClientId: clientId,
            templates,
            hasCover,
            hasDisclaimer,
          },
          model.id
        );
      } else {
        create({
          refClientId: clientId,
          templates,
          name,
          hasCover,
          hasDisclaimer,
        });
      }
      setShowNotification(true);
    } else {
      setShowError(true);
    }
  };

  const setPaper = (item: any, paper: any): void => {
    if (paper.type === 'list') {
      if (!repTemplates.find((each: any): any => each.id === item.id)) {
        const newReportTemplates = repTemplates.concat(item);
        setRepTemplates(newReportTemplates);
      }
    } else if (!selectedTemplates.find(each => each.id === item.id)) {
      const newSelectedTemplates = selectedTemplates.concat(item);
      setSelectedTemplates(newSelectedTemplates);
    }
  };

  const removeItem = (item: any, paper: any): void => {
    if (paper.type === 'list') {
      if (!repTemplates.find(each => each.id === item.id)) {
        const newSelectedTemplates = selectedTemplates.filter(
          each => each.id !== item.id
        );
        setSelectedTemplates(newSelectedTemplates);
        setPaper(item, paper);
      }
    } else {
      const newArray = repTemplates.filter(each => each.id !== item.id);
      setRepTemplates(newArray);
      setPaper(item, paper);
    }
  };

  const removeTemplate = (id: number): void => {
    const foundTemplate = reportTemplates.find(
      (each: any): any => each.id === id
    );
    setOpenDelete(true);
    setSelectedReportTemplate(foundTemplate);
  };

  const remove = (): void => {
    setIsRemoving(true);
    removeReportTemplate(selectedReportTemplate.id);
  };

  const handleDeleteClose = (): void => {
    setOpenDelete(false);
    setSelectedReportTemplate(undefined);
    setIsRemoving(false);
  };

  const handleDrop = (event: any, type: string): void => {
    const { removedIndex, addedIndex, payload } = event;
    if (type === 'list') {
      const result = [...repTemplates];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        [itemToAdd] = result.splice(removedIndex, 1);
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      setRepTemplates(result);
    }
    if (type === 'selected') {
      const result = [...selectedTemplates];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        [itemToAdd] = result.splice(removedIndex, 1);
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      result.sort((firstTemplate, secondTemplate) => {
        if (firstTemplate.id === 'cover') return -1;
        if (secondTemplate.id === 'cover') return 1;
        if (firstTemplate.id === 'contact') return 1;
        if (secondTemplate.id === 'contact') return -1;
        return 0;
      });

      const newHasCover = result.find(each => each.id === 'cover');
      const newHasDisclaimer = result.find(each => each.id === 'contact');

      setSelectedTemplates(result);
      setHasCover(!!newHasCover);
      setHasDisclaimer(!!newHasDisclaimer);
    }
  };

  const changeTitle = (value: string, id: number): void => {
    const foundTemplate = selectedTemplates.find(
      (each: any): any => each.id === id
    );
    const newTemplate = { ...foundTemplate, title: value, titleChanged: true };
    const newSelectedTemplates = selectedTemplates.map((each: any): any => {
      if (each.id === id) return newTemplate;
      return each;
    });
    setSelectedTemplates(newSelectedTemplates);
  };

  const changeSubtitle = (value: string, id: number): void => {
    const foundTemplate = selectedTemplates.find(
      (each: any): any => each.id === id
    );
    const newTemplate = {
      ...foundTemplate,
      subtitle: value,
      subtitleChanged: true,
    };
    const newSelectedTemplates = selectedTemplates.map(each => {
      if (each.id === id) return newTemplate;
      return each;
    });
    setSelectedTemplates(newSelectedTemplates);
  };

  const listPaper = {
    type: 'list',
    cards:
      repTemplates &&
      repTemplates.map(each => ({ ...each, type: 'templateCard' })),
  };
  const selectedPaper = {
    type: 'selected',
    cards:
      selectedTemplates.map(each => ({ ...each, type: 'templateCard' })) || [],
  };

  let actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions = [
      {
        label: 'Back',
        onClick: (): void => setShowNotification(false),
      },
      {
        label: 'Cancel',
        onClick: handleClose,
        type: 'secondary',
      },
    ];
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      actions={actions}
      title={model && model.id ? 'Edit Report' : 'New Report'}
      paperClass={showNotification ? '' : classes.reportDialog}
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Report {model && model.id ? 'updated' : 'added'} successfully.
          </Typography>
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error: {(error && error.messages && error.messages[0]) || 'Unknown'}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div className={classes.formContainer}>
          {!clientId && (
            <Typography variant="subtitle2" className={classes.text}>
              No client is Selected!
            </Typography>
          )}
          {clientId && (
            <div>
              <TextField
                error={showError && name === ''}
                id="outlined-name"
                label="Name"
                value={name}
                onChange={(e: any): void => setName(e.target.value)}
                margin="normal"
                variant="outlined"
                helperText={showError && name === '' && 'Required'}
                className={classes.fullField}
                ref={inputRef}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '98%',
                  height: '100%',
                  justifyContent: 'space-between',
                  marginLeft: '15px',
                }}
              >
                <div className={classes.listPaper}>
                  <Container
                    onDrop={(event: any): void =>
                      handleDrop(event, selectedPaper.type)
                    }
                    getChildPayload={(idx: number): any =>
                      selectedPaper.cards[idx]
                    }
                    groupName="Templates"
                    dropPlaceholder={{
                      animationDuration: 150,
                      showOnTop: true,
                    }}
                    style={{ height: '100%' }}
                  >
                    {selectedPaper.cards &&
                      selectedPaper.cards.map((item, idx) => (
                        // eslint-disable-next-line
                          <Draggable key={`${item.id}-${idx}`}>
                          <DragableCardComponent
                            card={item}
                            remove={removeTemplate}
                            count={idx + 1}
                            type={selectedPaper.type}
                            classes={classes}
                            changeTitle={changeTitle}
                            changeSubtitle={changeSubtitle}
                          />
                        </Draggable>
                      ))}
                  </Container>
                </div>

                <div className={classes.listPaper}>
                  <Container
                    onDrop={(event: any): void =>
                      handleDrop(event, listPaper.type)
                    }
                    getChildPayload={(idx: number): any => listPaper.cards[idx]}
                    groupName="Templates"
                    dropPlaceholder={{
                      animationDuration: 150,
                      showOnTop: true,
                    }}
                  >
                    {listPaper.cards &&
                      listPaper.cards.map((item, idx) => (
                        // eslint-disable-next-line
                          <Draggable key={`${item.id}-${idx}`}>
                          <DragableCardComponent
                            card={item}
                            remove={removeTemplate}
                            count={idx + 1}
                            type={listPaper.type}
                            classes={classes}
                            changeTitle={changeTitle}
                            changeSubtitle={changeSubtitle}
                          />
                        </Draggable>
                      ))}
                  </Container>
                </div>
              </div>

              {relatedUsername !== '' && (
                <Typography variant="subtitle2" className={classes.info}>
                  {isUpdated
                    ? `Updated by: ${relatedUsername} on ${modifyDate}`
                    : `Created by: ${relatedUsername} on ${modifyDate}`}
                </Typography>
              )}
            </div>
          )}
        </div>
      )}
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {isRemoving && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Template removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error: {error && error.messages && error.messages[0]}
            </Typography>
          </div>
        )}
      </Dialog>
    </Dialog>
  );
};

export default DialogForm;
