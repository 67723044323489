import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const getSimulation = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>
): any =>
  Request(`assettable/simulation`, 'POST', {
    year,
    month,
    id: clientId,
    disabledColumns,
    enabledRows,
  })
    .then(ErrorHandler)
    .then((simulation: any): any => simulation);

const SimulationService = {
  getSimulation,
};

export default SimulationService;
