import AccountService from '../services/AccountService';
import * as types from '../constants/Account';
import {
  AccountType,
  AccountSecurityType,
  AccountValuesType,
  AccountSecurityLifetimeType,
} from '../Types';

const getAccountsList = (clientId: number): any => (dispatch: any): void => {
  dispatch({
    type: types.ACCOUNT_LIST_REQUEST,
  });
  AccountService.list(clientId)
    .then((accounts: { content: Array<AccountType> }): void => {
      dispatch({ type: types.ACCOUNT_LIST_SUCCESS, accounts });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_LIST_FAILURE, error });
    });
};

const getAccountItem = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_ITEM_REQUEST });
  AccountService.item(id)
    .then((account: AccountType): void => {
      dispatch({ type: types.ACCOUNT_ITEM_SUCCESS, account });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_ITEM_FAILURE, error });
    });
};

const createAccount = (data: any, id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_CREATE_REQUEST });
  AccountService.create(data)
    .then((account: any): void => {
      dispatch({ type: types.ACCOUNT_CREATE_SUCCESS, account });
      dispatch(getAccountsList(id));
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ACCOUNT_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ACCOUNT_CREATE_FAILURE, error });
      }
    });
};

const updateAccount = (data: any, id: number, dataId: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.ACCOUNT_UPDATE_REQUEST });
  AccountService.update(data, dataId)
    .then((account: any): void => {
      dispatch({ type: types.ACCOUNT_UPDATE_SUCCESS, account });
      dispatch(getAccountsList(id));
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ACCOUNT_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ACCOUNT_UPDATE_FAILURE, error });
      }
    });
};

const removeAccount = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_REMOVE_REQUEST });
  AccountService.remove(id)
    .then((account: any): any => {
      dispatch({ type: types.ACCOUNT_REMOVE_SUCCESS, account, id });
    })
    .catch((error: any): any => {
      dispatch({ type: types.ACCOUNT_REMOVE_FAILURE, error });
    });
};

const getAccountSecuritiesList = (accountId: number, data: any): any => (
  dispatch: any
): void => {
  dispatch({ type: types.ACCOUNT_SECURITIES_LIST_REQUEST });
  AccountService.securities(accountId, data)
    .then((accountSecurities: Array<AccountSecurityType>): void => {
      dispatch({
        type: types.ACCOUNT_SECURITIES_LIST_SUCCESS,
        accountSecurities,
      });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_SECURITIES_LIST_FAILURE, error });
    });
};

const getAllAccountSecuritiesList = (accountId: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.ALL_ACCOUNT_SECURITIES_LIST_REQUEST });
  AccountService.allSecurities(accountId)
    .then((allAccountSecurities: Array<AccountSecurityType>): void => {
      dispatch({
        type: types.ALL_ACCOUNT_SECURITIES_LIST_SUCCESS,
        allAccountSecurities,
      });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ALL_ACCOUNT_SECURITIES_LIST_FAILURE, error });
    });
};

const getAccountSecurityStartDate = (accountId: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.SECURITY_START_DATE_REQUEST });
  return AccountService.getAccountSecurityStartDate(accountId)
    .then((date: any): any => {
      dispatch({ type: types.SECURITY_START_DATE_SUCCESS, date });
      return Promise.resolve(date);
    })
    .catch((error: any): void => {
      dispatch({ type: types.SECURITY_START_DATE_FAILURE, error });
    });
};

const getAccountStartDate = (accountId: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.ACCOUNT_START_DATE_REQUEST });
  return AccountService.getAccountStartDate(accountId)
    .then((date: any): any => {
      dispatch({ type: types.ACCOUNT_START_DATE_SUCCESS, date });
      return Promise.resolve(date);
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_START_DATE_FAILURE, error });
    });
};

const removeAccountSecurity = (
  accountId: number,
  id: number,
  data: any
): any => (dispatch: any): any => {
  dispatch({ type: types.ACCOUNT_SECURITY_REMOVE_REQUEST });
  AccountService.removeSecurity(accountId, id)
    .then((accountSecurity: any): void => {
      dispatch({
        type: types.ACCOUNT_SECURITY_REMOVE_SUCCESS,
        accountSecurity,
      });
      dispatch(getAccountSecuritiesList(accountId, data));
      dispatch(getAllAccountSecuritiesList(accountId));
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_SECURITY_REMOVE_FAILURE, error });
    });
};

const getAccountValues = (
  accountId: number,
  year: number,
  month: number
): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_VALUES_LIST_REQUEST });
  AccountService.values(accountId, year, month)
    .then((accountValues: AccountValuesType): void => {
      dispatch({ type: types.ACCOUNT_VALUES_LIST_SUCCESS, accountValues });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_VALUES_LIST_FAILURE, error });
    });
};

const saveAccountValues = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_SAVE_VALUES_REQUEST, data });
  AccountService.saveValues(data)
    .then((): void => {
      dispatch({ type: types.ACCOUNT_SAVE_VALUES_SUCCESS });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_SAVE_VALUES_FAILURE, error });
    });
};

const getSecurityLifeTime = (accountId: number, securityId: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.ACCOUNT_SECURITY_LIFETIME_REQUEST });
  AccountService.securityLifetime(accountId, securityId)
    .then((securityLifetimes: Array<AccountSecurityLifetimeType>): void => {
      dispatch({
        type: types.ACCOUNT_SECURITY_LIFETIME_SUCCESS,
        securityLifetimes,
      });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_SECURITY_LIFETIME_FAILURE, error });
    });
};

const createSecurityLifetime = (
  data: any,
  accountId: number,
  securityId: number,
  dateData: any
): any => (dispatch: any): any => {
  dispatch({ type: types.ACCOUNT_SAVE_SECURITY_LIFETIME_REQUEST, data });
  return AccountService.createSecurityLifetime(data)
    .then((accountSecurityLifetime: any): any => {
      dispatch({
        type: types.ACCOUNT_SAVE_SECURITY_LIFETIME_SUCCESS,
        accountSecurityLifetime,
      });
      dispatch(getSecurityLifeTime(accountId, securityId));
      dispatch(getAccountSecuritiesList(accountId, dateData));
      dispatch(getAllAccountSecuritiesList(accountId));
      return Promise.resolve(accountSecurityLifetime);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({
            type: types.ACCOUNT_SAVE_SECURITY_LIFETIME_FAILURE,
            error: e,
          })
        );
      } else {
        dispatch({ type: types.ACCOUNT_SAVE_SECURITY_LIFETIME_FAILURE, error });
      }

      return Promise.reject(error);
    });
};

const updateSecurityLifetime = (
  data: any,
  accountId: number,
  securityId: number,
  dateData: any,
  lifetimeId: number
): any => (dispatch: any): any => {
  dispatch({ type: types.ACCOUNT_SAVE_SECURITY_LIFETIME_REQUEST, data });
  return AccountService.updateSecurityLifetime(data, lifetimeId)
    .then((accountSecurityLifetime: any): any => {
      dispatch({
        type: types.ACCOUNT_SAVE_SECURITY_LIFETIME_SUCCESS,
        accountSecurityLifetime,
      });
      dispatch(getSecurityLifeTime(accountId, securityId));
      dispatch(getAccountSecuritiesList(accountId, dateData));
      dispatch(getAllAccountSecuritiesList(accountId));
      return Promise.resolve(accountSecurityLifetime);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({
            type: types.ACCOUNT_SAVE_SECURITY_LIFETIME_FAILURE,
            error: e,
          })
        );
      } else {
        dispatch({ type: types.ACCOUNT_SAVE_SECURITY_LIFETIME_FAILURE, error });
      }
      return Promise.reject(error);
    });
};

const removeSecurityLifetime = (
  id: number,
  accountId: number,
  securityId: number,
  dateData: any
): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_REMOVE_SECURITY_LIFETIME_REQUEST });
  AccountService.removeSecurityLifetime(id)
    .then((): void => {
      dispatch({ type: types.ACCOUNT_REMOVE_SECURITY_LIFETIME_SUCCESS, id });
      dispatch(getSecurityLifeTime(accountId, securityId));
      dispatch(getAccountSecuritiesList(accountId, dateData));
      dispatch(getAllAccountSecuritiesList(accountId));
    })
    .catch((error: any): void => {
      dispatch({
        type: types.ACCOUNT_REMOVE_SECURITY_LIFETIME_FAILURE,
        error,
      });
    });
};

const attachSecurity = (data: any, accountId: number, dataExtra: any): any => (
  dispatch: any
): void => {
  dispatch({ type: types.ACCOUNT_ATTACH_SECURITY_REQUEST });
  AccountService.attachSecurity(data)
    .then((accountSecurity: any): void => {
      dispatch({
        type: types.ACCOUNT_ATTACH_SECURITY_SUCCESS,
        accountSecurity,
      });
      dispatch(getAllAccountSecuritiesList(accountId));
      dispatch(getAccountSecuritiesList(accountId, dataExtra));
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ACCOUNT_ATTACH_SECURITY_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ACCOUNT_ATTACH_SECURITY_FAILURE, error });
      }
    });
};

const checkToRemoveAccount = (accountId: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.ACCOUNT_CHECK_TO_REMOVE_REQUEST });
  return AccountService.checkToRemoveAccount(accountId)
    .then((hasValueResponse: any): any => {
      dispatch({ type: types.ACCOUNT_CHECK_TO_REMOVE_SUCCESS });
      return Promise.resolve(hasValueResponse);
    })
    .catch((error: any): any => {
      dispatch({ type: types.ACCOUNT_CHECK_TO_REMOVE_FAILURE });
      Promise.reject(error);
    });
};
const checkToRemoveAccountSecurity = (
  accountId: number,
  securityId: number
): any => (dispatch: any): any => {
  dispatch({ type: types.ACCOUNT_SECURITY_CHECK_TO_REMOVE_REQUEST });
  return AccountService.checkToRemoveAccountSecurity(accountId, securityId)
    .then((hasValueResponse: any): any => {
      dispatch({ type: types.ACCOUNT_SECURITY_CHECK_TO_REMOVE_SUCCESS });
      return Promise.resolve(hasValueResponse);
    })
    .catch((error: any): any => {
      dispatch({ type: types.ACCOUNT_SECURITY_CHECK_TO_REMOVE_FAILURE });
      Promise.reject(error);
    });
};

const AccountActions = {
  getAccountsList,
  getAccountItem,
  createAccount,
  updateAccount,
  removeAccount,
  getAccountSecuritiesList,
  attachSecurity,
  removeAccountSecurity,
  getAccountValues,
  saveAccountValues,
  createSecurityLifetime,
  updateSecurityLifetime,
  getSecurityLifeTime,
  removeSecurityLifetime,
  getAccountSecurityStartDate,
  getAccountStartDate,
  getAllAccountSecuritiesList,
  checkToRemoveAccount,
  checkToRemoveAccountSecurity,
};

export default AccountActions;
