const months = [
  { label: 'Jan', value: 'Jan', valueNumber: 1, fullLabel: 'January' },
  { label: 'Feb', value: 'Feb', valueNumber: 2, fullLabel: 'February' },
  { label: 'Mar', value: 'Mar', valueNumber: 3, fullLabel: 'March' },
  { label: 'Apr', value: 'Apr', valueNumber: 4, fullLabel: 'April' },
  { label: 'May', value: 'May', valueNumber: 5, fullLabel: 'May' },
  { label: 'Jun', value: 'Jun', valueNumber: 6, fullLabel: 'June' },
  { label: 'Jul', value: 'Jul', valueNumber: 7, fullLabel: 'July' },
  { label: 'Aug', value: 'Aug', valueNumber: 8, fullLabel: 'August' },
  { label: 'Sep', value: 'Sep', valueNumber: 9, fullLabel: 'September' },
  { label: 'Oct', value: 'Oct', valueNumber: 10, fullLabel: 'October' },
  { label: 'Nov', value: 'Nov', valueNumber: 11, fullLabel: 'November' },
  { label: 'Dec', value: 'Dec', valueNumber: 12, fullLabel: 'December' },
];

const getCurrentYear = (): number => new Date().getFullYear();

const calculateYears = (): Array<any> => {
  const years = [];
  for (let year = 2004; year < getCurrentYear() + 11; year += 1) {
    years.push({ label: `${year}`, value: `${year}`, valueNumber: year });
  }
  return years;
};

const years = calculateYears();

const Dates = {
  months,
  years,
};

export default Dates;
