import React, { useState, useEffect, ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Dates from '../../constants/Date';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import { RegionType, UserType, MutateRegionType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface RegionObject {
  name?: string;
  description?: string;
}

interface Props {
  onClose: () => void;
  model: RegionType;
  open: boolean;
  update: (obj: MutateRegionType, id: number) => void;
  create: (obj: MutateRegionType) => void;
  success: boolean;
  loading: boolean;
  error: any;
  getUserItem: (id: number) => Promise<UserType>;
}

const DialogForm = (props: Props): ReactElement => {
  const {
    model,
    open,
    getUserItem,
    onClose,
    update,
    create,
    loading,
    success,
    error,
  } = props;

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [modifyDate, setModifyDate] = useState<string>('');
  const [relatedUsername, setRelatedUsername] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (!model || !open) return;
    setName(model.name);
    setDescription(model.description);
  }, [model, open]);

  useEffect(() => {
    if (!model || !open) return;
    const { lastModifiedBy, lastModifiedDate, createdDate } = model;
    if (lastModifiedBy && lastModifiedDate !== undefined) {
      getUserItem(lastModifiedBy).then((user: UserType) => {
        if (lastModifiedDate === createdDate) {
          const date = new Date(lastModifiedDate);
          const monthObj = Dates.months.find(
            each => each.valueNumber === date.getMonth() + 1
          );
          const month = monthObj && monthObj.value;
          const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
          setIsUpdated(true);
          setRelatedUsername(user.name);
          setModifyDate(newModifyDate);
        } else {
          const date = new Date(lastModifiedDate);
          const monthObj = Dates.months.find(
            each => each.valueNumber === date.getMonth() + 1
          );
          const month = monthObj && monthObj.value;
          const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
          setIsUpdated(false);
          setRelatedUsername(user.name);
          setModifyDate(newModifyDate);
        }
      });
    } else {
      setIsUpdated(false);
      setModifyDate('');
      setRelatedUsername('');
    }
  }, [open, model, getUserItem]);

  const resetFields = (): void => {
    setName('');
    setDescription('');
    setShowError(false);
    setShowNotification(false);
    setIsUpdated(false);
    setModifyDate('');
    setRelatedUsername('');
  };

  const handleClose = (): void => {
    resetFields();
    onClose();
  };

  const canSubmit = (): boolean => {
    if (name === '' || description === '') return false;
    return true;
  };

  const submit = (): void => {
    if (canSubmit()) {
      if (model && model.id) {
        update({ name, description }, model.id);
      } else {
        create({ name, description });
      }
      setShowNotification(true);
    } else {
      setShowError(true);
    }
  };

  const retry = (): void => {
    setShowNotification(false);
  };

  let actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions = [
      {
        label: 'Back',
        onClick: retry,
      },
      {
        label: 'Cancel',
        onClick: handleClose,
        type: 'secondary',
      },
    ];
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      actions={actions}
      title={model && model.id ? 'Edit Region' : 'New Region'}
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Region {model && model.id ? 'updated' : 'added'} successfully.
          </Typography>
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error: {(error && error.messages && error.messages[0]) || 'Unknown'}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div className={classes.formContainer}>
          <TextField
            error={showError && name === ''}
            id="outlined-name"
            label="Name"
            value={name}
            onChange={(e: any): void => setName(e.target.value)}
            margin="normal"
            variant="outlined"
            helperText={showError && name === '' && 'Required'}
            className={classes.fullField}
          />
          <TextField
            error={showError && description === ''}
            id="outlined-description"
            label="Description"
            value={description}
            onChange={(e: any): void => setDescription(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
            helperText={showError && description === '' && 'Required'}
          />
          {relatedUsername !== '' && (
            <Typography variant="subtitle2" className={classes.info}>
              {isUpdated
                ? `Updated by: ${relatedUsername} on ${modifyDate}`
                : `Created by: ${relatedUsername} on ${modifyDate}`}
            </Typography>
          )}
        </div>
      )}
    </Dialog>
  );
};

DialogForm.defaultProps = {
  model: undefined,
  error: undefined,
  success: false,
  loading: false,
};

export default DialogForm;
