import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  ReactElement,
} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import CancelSearch from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import TablePagination from '@material-ui/core/TablePagination';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import UpIcon from '@material-ui/icons/ArrowUpward';
import DownIcon from '@material-ui/icons/ArrowDownward';
import ExportIcon from '@material-ui/icons/SaveAlt';
import Footer from '../Footer';
import styles from './styles';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  columns: any;
  rows: any;
  entity: string;
  entities: string;
  hasAddButton: boolean;
  onAdd: () => void;
  onEdit: any;
  onDelete: any;
  hasEditAndDelete: boolean;
  hasDetails: boolean;
  details: any;
  history: any;
  hasBackButton: boolean;
  onClickRow: any;
  hasBreadcrumb: boolean;
  breadcrumbItems: any;
  hasToolbar: boolean;
  noMargin: boolean;
  notShowFooter: boolean;
  onRowSelection: any;
  disableEditButton: any;
  disableDeleteButton: any;
  addButtonLabel: string;
  extraButtons: any;
  hasExportButton: boolean;
  onExport: any;
  hasHighlightBackground: boolean;
  inDialog?: boolean;
}

const DataTable = (props: Props): ReactElement => {
  const {
    onAdd,
    history,
    onRowSelection,
    rows: allRows,
    columns,
    entity,
    entities,
    hasAddButton,
    hasEditAndDelete,
    onEdit,
    hasBackButton,
    onDelete,
    hasDetails,
    details,
    onClickRow,
    hasBreadcrumb,
    breadcrumbItems,
    hasToolbar,
    noMargin,
    notShowFooter,
    disableDeleteButton,
    disableEditButton,
    addButtonLabel,
    extraButtons,
    hasExportButton,
    onExport,
    hasHighlightBackground,
    inDialog,
  } = props;

  const [selected, setSelected] = useState<string>('');
  const [hovered, setHovered] = useState<string>('');
  const [searchingTitle, setSearchingTitle] = useState<any>({});
  const [page, setPage] = useState<number>(0);
  const [scrollableDiv, setScrollableDiv] = useState<any>(null);
  const [sortStatus, setSortStatus] = useState<string>('none');
  const [sortColumn, setSortColumn] = useState<string>('');
  const [sortType, setSortType] = useState<string>('');
  const classes = useStyles();

  const scrollableDivRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {});

  useEffect(() => {
    setScrollableDiv(scrollableDivRef);
  }, []);

  const handleAdd = (): void => {
    onAdd();
    setSelected('');
  };

  const setSelectedRow = (event: any, row: any): void => {
    if (selected === row.id) {
      setSelected('');
    } else {
      setSelected(row.id);
      if (onRowSelection) onRowSelection(row);
    }
  };

  const handleSort = (event: any, col: any): void => {
    if (sortColumn === col.sortField) {
      if (sortStatus === 'ASC') {
        setSortStatus('DEC');
      } else {
        setSortColumn('');
        setSortStatus('');
        setSortType('');
      }
    } else {
      setSortColumn(col.sortField);
      setSortStatus('ASC');
      setSortType(col.type);
    }
  };

  const resolve = (path: string, obj: any): any =>
    path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj);

  let rows = [...allRows];
  if (searchingTitle.value) {
    rows = rows.filter(row =>
      resolve(searchingTitle.name, row)
        .toLowerCase()
        .includes(searchingTitle.value.toLowerCase() || '')
    );
  }

  const sortedRows = rows;
  if (sortStatus === 'ASC') {
    sortedRows.sort((rowA, rowB) => {
      const a = resolve(sortColumn, rowA);
      const b = resolve(sortColumn, rowB);
      if (sortType === 'number') {
        return a - b;
      }
      if (sortType === 'boolean') {
        if (a && !b) return 1;
        if (!a && b) return -1;
        return 0;
      }
      const aString = a.toUpperCase();
      const bString = b.toUpperCase();
      if (aString < bString) return -1;
      if (aString > bString) return 1;
      return 0;
    });
  }

  if (sortStatus === 'DEC') {
    sortedRows.sort((rowA, rowB) => {
      const a = resolve(sortColumn, rowA);
      const b = resolve(sortColumn, rowB);
      if (sortType === 'number') {
        return b - a;
      }
      if (sortType === 'boolean') {
        if (a && !b) return -1;
        if (!a && b) return 1;
        return 0;
      }
      const aString = a.toUpperCase();
      const bString = b.toUpperCase();
      if (aString < bString) return 1;
      if (aString > bString) return -1;
      return 0;
    });
  }

  const SerachableHeader = (column: any): ReactElement => (
    <div className={classes.serachableHeader}>
      {searchingTitle.name !== column.name ? (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {
            <Typography
              onClick={
                column.sortable
                  ? (e: any): void => handleSort(e, column)
                  : (): void => {}
              }
            >
              {column.title}
            </Typography>
          }{' '}
          <SearchIcon
            onClick={(): void => setSearchingTitle({ name: column.name })}
            style={{ fontSize: 13, marginLeft: 8, marginTop: 5 }}
          />
        </div>
      ) : (
        <div style={{ position: 'relative' }}>
          <TextField
            label={column.title}
            onChange={(event: any): void =>
              setSearchingTitle({
                name: column.name,
                value: event.target.value,
              })
            }
            style={{
              margin: 0,
              fontWeight: 500,
              marginBottom: '-1px',
              width: 150,
            }}
            placeholder="Search ..."
            InputProps={{
              style: {
                lineHeight: '28px',
                fontSize: '15px',
                paddingRight: '16px',
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <IconButton
            className={classes.cancelSerachButton}
            onClick={(): void => setSearchingTitle({})}
            aria-label="cancel"
          >
            <CancelSearch style={{ fontSize: 18 }} />
          </IconButton>
        </div>
      )}
    </div>
  );

  const scrollElement = scrollableDiv && scrollableDiv.current;
  // console.log(scrollElement);
  return (
    <Paper
      className={classNames(classes.root, {
        [classes.highlightBackground]: hasHighlightBackground,
      })}
      style={{ margin: !noMargin ? 15 : 0 }}
    >
      {hasToolbar && (
        <Toolbar className={classes.toolbar}>
          <div className={classes.titleContainer}>
            {hasBackButton && (
              <Tooltip title="Back">
                <IconButton
                  onClick={(): void => history.goBack()}
                  classes={{ root: classes.backRotButton }}
                  color="inherit"
                >
                  <LeftIcon />
                </IconButton>
              </Tooltip>
            )}

            {hasBreadcrumb && (
              <div className={classes.titleContainer}>
                {breadcrumbItems.map(
                  (each: any): ReactElement => (
                    <div key={each.name}>
                      <Typography
                        variant="h2"
                        component={each.route ? 'a' : 'p'}
                        onClick={
                          each.route
                            ? (): void => {
                                history.push(each.route);
                              }
                            : (): void => {}
                        }
                        className={classNames(classes.title, {
                          [classes.titleWithButton]: hasBackButton,
                          [classes.link]: each.route,
                        })}
                      >
                        {each.name}
                      </Typography>
                      <NavigateNextIcon
                        fontSize="small"
                        style={{
                          marginBottom: '-5px',
                          marginLeft: '10px',
                          marginRight: '10px',
                        }}
                      />
                    </div>
                  )
                )}
                <Typography
                  className={classNames(classes.title, {
                    [classes.titleWithButton]: hasBackButton,
                  })}
                  id="tableTitle"
                  variant="h2"
                >
                  {entities}
                </Typography>
                <div className={classes.tableCount}>
                  {rows ? rows.length : 0}
                </div>
              </div>
            )}
            {!hasBreadcrumb && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  className={classNames(classes.title, {
                    [classes.titleWithButton]: hasBackButton,
                  })}
                  id="tableTitle"
                  variant="h1"
                >
                  {entities}
                </Typography>
                <div className={classes.tableCount}>
                  {rows ? rows.length : 0}
                </div>
              </div>
            )}
          </div>
          <div>
            {extraButtons &&
              extraButtons.length > 0 &&
              extraButtons.map(
                (each: any): ReactElement => (
                  <Button
                    key={each.label}
                    onClick={each.handleClick}
                    variant="outlined"
                    className={classes.addButton}
                    style={{ marginLeft: 10 }}
                  >
                    {each.label}
                  </Button>
                )
              )}
            {hasAddButton && (
              <Button
                onClick={handleAdd}
                variant="outlined"
                className={classes.addButton}
                style={{ marginLeft: 10 }}
              >
                {addButtonLabel || `Add ${entity}`}
              </Button>
            )}
          </div>
        </Toolbar>
      )}
      <div style={{ overflowX: 'auto', height: '100%' }} ref={scrollableDiv}>
        <Table
          className={classNames(classes.table, {
            [classes.tableInDialog]: inDialog,
          })}
        >
          <TableHead>
            <TableRow className={classes.rowContainer}>
              {columns &&
                columns.map(
                  (col: any): ReactElement => (
                    <TableCell
                      key={col.title}
                      align="left"
                      className={classes.headerCell}
                      classes={{
                        root: classes.cellRoot,
                      }}
                      style={{
                        ...col.style,
                        paddingBottom: col.searchable && 0,
                        paddingRight: col.searchable && 0,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          ...col.style,
                        }}
                      >
                        {col.searchable ? (
                          SerachableHeader(col)
                        ) : (
                          <Typography
                            onClick={
                              col.sortable
                                ? (e: any): void => handleSort(e, col)
                                : (): void => {}
                            }
                            className={classNames({
                              [classes.sortableHeader]: col.sortable,
                            })}
                          >
                            {' '}
                            {col.title}
                          </Typography>
                        )}
                        {sortColumn === col.sortField ? (
                          (sortStatus === 'ASC' && (
                            <UpIcon className={classes.sortIcon} />
                          )) ||
                          (sortStatus === 'DEC' && (
                            <DownIcon className={classes.sortIcon} />
                          ))
                        ) : (
                          <div
                            className={classes.sortIcon}
                            style={{ width: 13 }}
                          />
                        )}
                      </div>
                    </TableCell>
                  )
                )}
              {hasEditAndDelete && (
                <TableCell
                  align="right"
                  className={classNames(
                    classes.headerCell,
                    classes.actionCell,
                    { [classes.actionCellRootInDialog]: inDialog }
                  )}
                  classes={{ root: classes.actionCellRoot }}
                >
                  <span style={{ marginRight: 5 }}>Actions</span>
                  {hasExportButton && (
                    <Tooltip title="Export">
                      <IconButton onClick={onExport} aria-label="Edit">
                        <ExportIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.length > 0 &&
              rows &&
              rows.slice(page * 50, page * 50 + 50).map(row => (
                <TableRow
                  key={row.id}
                  selected={selected === row.id}
                  className={classNames(classes.row, {
                    [classes.hasDetails]: hasDetails,
                  })}
                  onMouseEnter={(): void => setHovered(row.id)}
                  onMouseLeave={(): void => setHovered('')}
                  onClick={(event: any): void => setSelectedRow(event, row)}
                  hover
                  style={row.style}
                >
                  {columns.map(
                    (col: any): ReactElement => (
                      <TableCell
                        key={col.name}
                        className={classes.cell}
                        align="left"
                        onClick={
                          onClickRow
                            ? (event: any): void => onClickRow(event, row)
                            : (): void => {}
                        }
                        style={{
                          ...row.style,
                          ...col.rowStyle,
                          ...row[`${col.name}.style`],
                        }}
                        classes={{
                          root: classes.cellRoot,
                        }}
                      >
                        <span style={{ lineHeight: '45px' }}>
                          {// eslint-disable-next-line no-nested-ternary
                          typeof resolve(col.name, row) === 'boolean'
                            ? resolve(col.name, row) === true
                              ? 'Yes'
                              : 'No'
                            : resolve(col.name, row)}
                        </span>
                      </TableCell>
                    )
                  )}
                  {hasEditAndDelete && (
                    <TableCell
                      className={classNames(classes.actionCell, {
                        [classes.actionCellRootInDialog]: inDialog,
                      })}
                      classes={{ root: classes.actionCellRoot }}
                      align="right"
                    >
                      {(hovered === row.id || selected === row.id) && (
                        <div style={{ zIndex: 100 }}>
                          {details &&
                            details(row).map(
                              (detail: any): ReactElement => (
                                <Tooltip
                                  title={detail.title}
                                  key={detail.title}
                                >
                                  <span>
                                    <IconButton
                                      onClick={(event: any): void =>
                                        detail.onClick(event, row)
                                      }
                                      aria-label={detail.title}
                                      disabled={detail.disabled}
                                    >
                                      {detail.icon}
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              )
                            )}
                          {onEdit && (
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={(event: any): void =>
                                  onEdit(event, row)
                                }
                                aria-label="Edit"
                                disabled={
                                  disableEditButton && disableEditButton(row)
                                }
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {onDelete && (
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={(event: any): void =>
                                  onDelete(event, row)
                                }
                                aria-label="Delete"
                                disabled={
                                  disableDeleteButton &&
                                  disableDeleteButton(row)
                                }
                              >
                                <DeleteIcon
                                  fontSize="small"
                                  className={
                                    disableDeleteButton &&
                                    disableDeleteButton(row)
                                      ? ''
                                      : classes.deleteIcon
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            {rows && rows.length > 0 && rows.length < 10 && <TableRow />}
            {rows && rows.length === 0 && <TableRow />}
            {rows && rows.length === 0 && (
              <TableRow className={classes.noDataRow}>
                <TableCell
                  colSpan={columns.length + 2}
                  style={{
                    textAlign: 'center',
                    color: 'rgb(0,0,0,0.54)',
                    border: 'none',
                  }}
                >
                  No Data
                </TableCell>
              </TableRow>
            )}
            {rows && rows.length === 0 && <TableRow />}
            <TableRow style={{ height: 55 }}>
              <TablePagination
                style={{ borderTop: '1px solid #e0e0e0' }}
                colSpan={columns && columns.length + 1}
                count={(rows && rows.length) || 0}
                rowsPerPage={50}
                page={page}
                onChangePage={(e: any, newPage: number): void =>
                  setPage(newPage)
                }
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
              />
            </TableRow>
          </TableBody>
        </Table>
        {!notShowFooter && (
          <Footer
            style={
              scrollElement &&
              scrollElement.scrollHeight > scrollElement.clientHeight
                ? {}
                : { position: 'absolute', bottom: 0, width: '100%' }
            }
          />
        )}
      </div>
    </Paper>
  );
};

DataTable.defaultProps = {
  disableDeleteButton: undefined,
  disableEditButton: undefined,
  hasAddButton: false,
  hasEditAndDelete: false,
  hasDetails: false,
  onAdd: undefined,
  onEdit: undefined,
  onDelete: undefined,
  onRowSelection: undefined,
  details: undefined,
  hasBackButton: false,
  onClickRow: undefined,
  hasBreadcrumb: false,
  breadcrumbItems: undefined,
  hasToolbar: true,
  noMargin: false,
  history: undefined,
  rows: [],
  notShowFooter: false,
  addButtonLabel: undefined,
  extraButtons: [],
  hasExportButton: false,
  onExport: undefined,
  hasHighlightBackground: false,
};

export default DataTable;
