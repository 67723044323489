import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SecurityActions from '../../actions/SecurityActions';
import DatePickerActions from '../../actions/DatePickerActions';
import Security from './Security';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ ...SecurityActions, ...DatePickerActions }, dispatch);

const mapStateToProps = (state: any): any => ({
  securities: state.Security.securities,
  deleted: state.Security.deleted,
  security: state.Security.security,
  success: state.Security.success,
  error: state.Security.error,
  loading: state.Security.loading,
  securityValues: state.Security.securityValues,
  securityStartDate: state.Security.securityStartDate,
  selectedYear: state.DatePicker.year,
  selectedMonth: state.DatePicker.month,
});

export default connect(mapStateToProps, mapDispatchToProps)(Security);
