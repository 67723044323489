import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '8px',
      backgroundColor: '#eff0f1',
      height: 'calc(100% - 60px)',
    },
    formContainer: {
      width: '100%',
      height: '100%',
      margin: '10px 10px',
    },
    fullField: {
      margin: '5px 10px',
      width: '96%',
    },
    container: {
      marginLeft: '10px',
      marginTop: '10px',
    },
    toolbar: {
      justifyContent: 'space-between',
      marginTop: '30px',
      marginBottom: '20px',
      paddingRight: '24px',
    },
    accountButton2: {
      marginLeft: '10px',
      width: '95px',
    },
    addButton: {},
    tabs: {
      borderBottom: '1px solid #00000026',
    },
    tab: { minWidth: 120, marginLeft: 4, color: 'rgb(0,0,0,0.7)' },
    tabLabelContainer: { padding: '0 12px' },
    invisibleIndicator: { backgroundColor: '#1976d2' },
    tabsPadding: { paddingTop: 4 },
    selectedTab: {
      backgroundColor: 'white',
      boxShadow: '0px 1px 6px 0px #0000004d',
      borderRadius: '6px 6px 0 0',
      color: '#1976d2 !important',
      fontWeight: 600,
    },
    generalTab: {
      display: 'flex',
      padding: '24px',
      backgroundColor: 'white',
      backgroundClip: 'padding-box',
    },
    divider: {
      margin: '20px 0',
    },
    title: {
      display: 'inline',
      opacity: 0.8,
    },
    detail: {
      position: 'relative',
      display: 'inline',
    },
    tow: { flex: '1 0 45%', margin: '20px 10px 5px 10px' },
    one: { flex: '1 0 90%', margin: '20px 10px 5px 10px' },
    three: { flex: '1 0 30%', margin: '20px 10px 5px 10px' },
    four: { flex: '1 0 22%', margin: '20px 10px 5px 10px' },
    parent: {
      paddingBottom: '40px',
      paddingTop: '20px',
      display: 'flex',
      backgroundColor: 'white',
      backgroundClip: 'padding-box',
    },
    totalTitle: {
      margin: '10px 0 50px 22px',
      display: 'inline',
      color: 'rgb(0,0,0)',
      fontSize: '13px',
      flex: '1 0 20%',
    },
    totalValue: {
      display: 'inline',
      fontSize: '13px',
    },
    yearPickerContainer: { display: 'inline-block' },
    totalRed: {
      color: 'red',
      textAlign: 'right',
      fontSize: '13px',
    },
    totalGreen: {
      color: 'green',
      textAlign: 'right',
      fontSize: '13px',
    },
    label: {
      marginBottom: '5px',
      marginLeft: '22px',
      color: 'rgb(0,0,0, 0.7)',
      fontSize: '13px',
      display: 'inline-block',
      flex: '1 0 20%',
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: '10px',
      marginBottom: '4px',
    },
    success: {
      backgroundColor: theme.palette.common.green,
    },
    error: {
      backgroundColor: theme.palette.common.red,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    textfield: {
      display: 'inline-block',
      marginTop: '-7px',
    },
    valueContainer: {
      margin: '10px 10px 14px 10px',
      display: 'flex',
    },
    arrowButtonRoot: {
      padding: 4,
    },
    accountButton: {
      marginLeft: 0,
    },
    rootInput: {
      textAlign: 'right',
      fontSize: '13px',
      '&:focus': {
        textAlign: 'right',
      },
    },
    textfieldRoot: {
      // height: 25,
    },
    backRotButton: {
      marginLeft: '-6px',
      height: '48px',
    },
    leftContainer: {
      width: 550,
    },
    rightContainer: {
      width: 350,
      height: 350,
      padding: '40px 80px 80px 80px',
      marginLeft: 100,
    },
    tabsValue: {
      overflow: 'auto',
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      minHeight: '100%',
    },
  });

export default styles;
