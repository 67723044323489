import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccountActions from '../../actions/AccountActions';
import DialogActions from '../../actions/DialogActions';
import SecurityActions from '../../actions/SecurityActions';
import DatePickerActions from '../../actions/DatePickerActions';
import UserActions from '../../actions/UserActions';
import AccountSecurity from './AccountSecurity';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...AccountActions,
      ...SecurityActions,
      ...DialogActions,
      ...DatePickerActions,
      ...UserActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  accountSecurities: state.Account.accountSecurities,
  allAccountSecurities: state.Account.allAccountSecurities,
  securities: state.Security.securities,
  accountSecurityLifetime: state.Account.accountSecurityLifetime,
  accountSecurityLifetimes: state.Account.accountSecurityLifetimes,
  lifetimeSaved: state.Account.lifetimeSaved,
  lifetimeDeleted: state.Account.lifetimeDeleted,
  securityStartDate: state.Account.securityStartDate,
  id: state.Account.id,
  deleted: state.Account.deleted,
  success: state.Account.success,
  error: state.Account.error,
  loading: state.Account.loading,
  client: state.Client.client,
  account: state.Account.account,
  selectedYear: state.DatePicker.year,
  selectedMonth: state.DatePicker.month,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSecurity);
