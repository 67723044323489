import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  ReactElement,
} from 'react';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Bar } from 'react-chartjs-2';
import DataTable from '../ClientDataTable';
import styles from './styles';
import { PerformanceType } from '../../../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  statePerformance?: PerformanceType;
  year: number;
  month: number;
  getPerformanceData?: (
    id: number,
    sharp: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>
  ) => Promise<PerformanceType>;
  clientId: number;
  readOnly?: boolean;
  sharp?: number;
  parentReference: any;
  getNode?: (ref: any, name: string, id: number) => void;
  cardId?: number;
  selectedDate?: any;
  selectedColumns?: Array<number>;
  selectedRows?: Array<number>;
  selectedSharp?: number;
  setPerformSharp?: (id: number, value: number) => void;
  showNotification: () => void;
  rootClass?: any;
  root1Class?: any;
  historyActive?: boolean;
}

const PerformanceComponent = (props: Props): ReactElement => {
  const {
    clientId,
    readOnly,
    selectedSharp,
    cardId,
    sharp,
    getNode,
    month,
    year,
    getPerformanceData,
    selectedRows,
    selectedColumns,
    showNotification,
    setPerformSharp,
    parentReference,
    root1Class,
    rootClass,
    historyActive,
  } = props;

  const [tableRows, setTableRows] = useState<any>([]);
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [totalRow, setTotalRow] = useState<any>(undefined);
  const [statePerformance, setStatePerformance] = useState<
    undefined | PerformanceType
  >(undefined);
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {});

  const setPerformanceData = (newPerformanceData?: PerformanceType): void => {
    if (newPerformanceData) {
      const { rows, columns, totalRow: propsTotalRow } = newPerformanceData;
      const newColumns = Object.keys(columns).map(key => ({
        title:
          // eslint-disable-next-line
          columns[key] === 'Sharp'
            ? readOnly
              ? `Sharp ${sharp}%`
              : 'Sharp'
            : columns[key],
        name: key,
      }));
      newColumns.unshift({ title: 'Asset Manager', name: 'name' });
      let filteredRows = [];
      if (historyActive) {
        filteredRows = [...rows];
      } else {
        filteredRows = rows.filter(
          (each: any): any => each?.rangePosition !== -1
        );
      }
      const newRows = filteredRows.map((each: any): any => {
        const object: { [key: string]: any } = {
          name: each.label,
          id: each.id,
        };
        each.calculations.forEach((value: any): void => {
          object[`${value.type}`] = value.value || 0;
          object[`${value.type}-label`] = value.calculation;
        });
        return object;
      });
      const newTotalRow: { [key: string]: any } = {
        name: propsTotalRow.label,
        id: propsTotalRow.id,
      };
      propsTotalRow.calculations.forEach((each: any): void => {
        newTotalRow[`${each.type}`] = each.value;
      });
      setTotalRow(newTotalRow);
      setTableColumns(newColumns);
      setTableRows(newRows);
      if (readOnly)
        setTimeout(() => {
          if (getNode && cardId) getNode(containerRef, 'PERFORM', cardId);
        }, 2000);
    }
  };

  const getPerformanceDataMethod = (): void => {
    if (getPerformanceData && selectedSharp && selectedRows && selectedColumns)
      getPerformanceData(
        clientId,
        selectedSharp,
        year,
        month,
        selectedRows,
        selectedColumns
      )
        .then((response: PerformanceType): void => {
          setPerformanceData(response);
          setStatePerformance(response);
        })
        .catch(() => showNotification && showNotification());
  };

  useEffect(() => {
    getPerformanceDataMethod();
  }, [
    month,
    year,
    clientId,
    selectedRows,
    selectedColumns,
    selectedSharp,
    cardId,
  ]);

  const onKeyPressHandler = (event: any, value: string | number): void => {
    if (event.key === 'Enter' && setPerformSharp) {
      setPerformSharp(clientId, Number(value));
    }
  };

  const chartDataSet: any[] = [
    {
      data:
        (statePerformance &&
          statePerformance.chart &&
          statePerformance.chart.data &&
          Array.isArray(statePerformance.chart.data) &&
          statePerformance.chart.data.map(
            (each: any): number => each.riskIndicator
          )) ||
        [],
      type: 'line',
      label: 'Risk Indicator',
      yAxisID: '1',
      backgroundColor: '#000000',
      fill: false,
    },
  ];

  const labels: any[] = [];

  if (
    statePerformance &&
    statePerformance.chart &&
    statePerformance.chart.riskClasses &&
    Array.isArray(statePerformance.chart.riskClasses) &&
    statePerformance.chart.data &&
    Array.isArray(statePerformance.chart.data)
  ) {
    statePerformance.chart.riskClasses.forEach((rc: any): any => {
      const data = statePerformance.chart.data.map(
        (each: any): number =>
          each.riskValues.find((rv: any): any => rv.riskId === rc.id).value
      );
      const label = rc.name;
      const backgroundColor = rc.color;
      chartDataSet.push({
        label,
        data,
        backgroundColor,
        type: 'bar',
        yAxisID: '2',
      });
    });
    statePerformance.chart.data.forEach((each: any): any =>
      labels.push(each.title)
    );
  }

  return (
    <div className={classnames(classes.root1, root1Class)}>
      <div
        className={classnames(classes.root, rootClass)}
        ref={readOnly ? containerRef : parentReference}
      >
        <div className={classes.leftContainer}>
          <DataTable
            title="Performance Overview"
            rows={tableRows}
            columns={tableColumns}
            totalRow={totalRow}
            sharp={
              selectedSharp
                ? (Math.round(selectedSharp * 100) / 100).toFixed(2)
                : 0
            }
            onKeyPressHandler={onKeyPressHandler}
            showHeaders
            type2
            hasTotalRow
          />
        </div>
        <div
          className={classes.rightContainer}
          style={{
            minHeight: 'calc(100% - 100px)',
            height: tableRows.length * 30 + 180,
          }}
        >
          <Toolbar className={classes.toolbar}>
            <div className={classes.titleContainer}>
              <Typography
                className={classes.title}
                id="tableTitle"
                variant="h3"
              >
                Evolution of Risk
              </Typography>
            </div>
          </Toolbar>
          <Bar
            key={tableRows.length > 0 ? 3 : Math.random()}
            data={{
              datasets: chartDataSet,
              labels,
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              scaleUse2Y: true,
              legend: {
                display: true,
                position: 'bottom',
                fullWidth: false,
                labels: {
                  padding: 20,
                },
              },
              tooltips: {
                enabled: false,
              },
              scales: {
                yAxes: [
                  {
                    id: '2',
                    type: 'linear',
                    position: 'left',
                    display: true,
                    stacked: true,

                    ticks: {
                      beginAtZero: true,
                      callback(value: any): string {
                        const newNumber = Number(value).toFixed(0);
                        if (newNumber !== 'NaN') {
                          return newNumber
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                        return '';
                      },
                    },
                  },
                  {
                    id: '1',
                    type: 'linear',
                    position: 'right',
                    display: true,
                    stacked: false,
                    gridLines: {
                      display: false,
                    },

                    ticks: {
                      beginAtZero: true,
                      min: 0,
                      callback(value: any): string {
                        return `${(Math.round(value * 100) / 100).toFixed(2)}`;
                      },
                    },
                  },
                ],
                xAxes: [
                  {
                    barPercentage: 0.6,
                    display: true,
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              plugins: {
                labels: false,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

PerformanceComponent.defaultProps = {
  statePerformance: undefined,
  readOnly: false,
  tableEnabledRows: [],
  tableDisabledColumns: [],
  sharp: undefined,
  reportObject: undefined,
  setReportJSON: undefined,
  parentReference: undefined,
  getNode: undefined,
  cardId: undefined,
  selectedRows: [],
  selectedColumns: [],
  selectedDate: undefined,
  selectedSharp: undefined,
  showNotification: undefined,
};

export default PerformanceComponent;
