export const CURRENCIES_LIST_REQUEST = 'CURRENCIES_LIST_REQUEST';
export const CURRENCIES_LIST_SUCCESS = 'CURRENCIES_LIST_SUCCESS';
export const CURRENCIES_LIST_FAILURE = 'CURRENCIES_LIST_FAILURE';
export const CURRENCY_CREATE_REQUEST = 'CURRENCY_CREATE_REQUEST';
export const CURRENCY_CREATE_SUCCESS = 'CURRENCY_CREATE_SUCCESS';
export const CURRENCY_CREATE_FAILURE = 'CURRENCY_CREATE_FAILURE';
export const CURRENCY_UPDATE_REQUEST = 'CURRENCY_UPDATE_REQUEST';
export const CURRENCY_UPDATE_SUCCESS = 'CURRENCY_UPDATE_SUCCESS';
export const CURRENCY_UPDATE_FAILURE = 'CURRENCY_UPDATE_FAILURE';
export const CURRENCY_ITEM_REQUEST = 'CURRENCY_ITEM_REQUEST';
export const CURRENCY_ITEM_SUCCESS = 'CURRENCY_ITEM_SUCCESS';
export const CURRENCY_ITEM_FAILURE = 'CURRENCY_ITEM_FAILURE';
export const CURRENCY_REMOVE_REQUEST = 'CURRENCY_REMOVE_REQUEST';
export const CURRENCY_REMOVE_SUCCESS = 'CURRENCY_REMOVE_SUCCESS';
export const CURRENCY_REMOVE_FAILURE = 'CURRENCY_REMOVE_FAILURE';
