import React, { useState, useEffect, ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dates from '../../constants/Date';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import {
  AccountType,
  UserType,
  AccountTypeType,
  AssetManagerType,
  AccountCodeType,
  ClientType,
  MutateAccountType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  onClose: () => void;
  model: AccountType;
  open: boolean;
  update: (obj: MutateAccountType, id: number, dataId: number) => void;
  create: (obj: MutateAccountType, id: number) => void;
  accountTypes: Array<AccountTypeType>;
  assetManagers: Array<AssetManagerType>;
  accountCodes: Array<AccountCodeType>;
  success: boolean;
  loading: boolean;
  error: any;
  clientId: number;
  getUserItem: (id: number) => Promise<UserType>;
  client: ClientType;
}

const DialogForm = (props: Props): ReactElement => {
  const {
    model,
    open,
    getUserItem,
    onClose,
    update,
    create,
    clientId,
    client,
    accountCodes,
    accountTypes,
    assetManagers,
    loading,
    success,
    error,
  } = props;

  const [number, setNumber] = useState<string | number | undefined>('');
  const [refAccountCode, setRefAccountCode] = useState<
    AccountCodeType | undefined | string
  >(undefined);
  const [refAccountType, setRefAccountType] = useState<
    AccountTypeType | undefined | string
  >(undefined);
  const [refAssetManager, setRefAssetManager] = useState<
    AssetManagerType | undefined
  >(undefined);
  const [assetType, setAssetType] = useState<string | undefined>('');
  const [securitiesEnabled, setSecuritiesEnabled] = useState<
    string | undefined
  >('');
  const [startMonthDate, setStartMonthDate] = useState<any | undefined>(
    undefined
  );
  const [startYearDate, setStartYearDate] = useState<
    string | number | undefined
  >('');
  const [endMonthDate, setEndMonthDate] = useState<any | undefined>(undefined);
  const [endYearDate, setEndYearDate] = useState<string | number | undefined>(
    ''
  );
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [dateValidation, setDateValidation] = useState<boolean>(true);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [modifyDate, setModifyDate] = useState<string>('');
  const [relatedUsername, setRelatedUsername] = useState<string>('');
  const classes = useStyles();

  const resetFields = (): void => {
    setNumber('');
    setRefAccountCode(undefined);
    setRefAccountType(undefined);
    setRefAssetManager(undefined);
    setAssetType('');
    setSecuritiesEnabled('');
    setStartMonthDate(undefined);
    setStartYearDate('');
    setEndMonthDate(undefined);
    setEndYearDate('');
    setShowError(false);
    setDateValidation(true);
    setShowNotification(false);
    setIsUpdated(false);
    setModifyDate('');
    setRelatedUsername('');
  };

  useEffect(() => {
    if (model && model.id && open) {
      setNumber(model.number);
      setAssetType(model.assetType);
      setSecuritiesEnabled(model.securitiesEnabled);
      setRefAccountCode(
        (accountCodes &&
          accountCodes.find(
            (ac: AccountCodeType): any =>
              model &&
              model.refAccountCode &&
              model.refAccountCode.id &&
              ac &&
              ac.id === model.refAccountCode.id
          )) ||
          '<Empty>'
      );
      setRefAccountType(
        (accountTypes &&
          accountTypes.find(
            (at: AccountTypeType): any =>
              model &&
              model.refAccountType &&
              model.refAccountType.id &&
              at &&
              at.id === model.refAccountType.id
          )) ||
          '<Empty>'
      );
      setRefAssetManager(
        assetManagers &&
          assetManagers.find(
            (am: AssetManagerType): any =>
              am && am.id === model.refAssetManager.id
          )
      );
      if (model.startDate) {
        const date = new Date(model.startDate);
        setStartMonthDate(
          Dates.months.find(month => month.valueNumber - 1 === date.getMonth())
        );
        setStartYearDate(date.getFullYear());
      } else {
        setStartYearDate('');
        setStartMonthDate(undefined);
      }
      if (model.endDate) {
        const date = new Date(model.endDate);
        setEndMonthDate(
          Dates.months.find(month => month.valueNumber - 1 === date.getMonth())
        );
        setEndYearDate(date.getFullYear());
      } else {
        setEndMonthDate(undefined);
        setEndYearDate('');
      }
    } else {
      resetFields();
    }
  }, [model, open]);

  useEffect(() => {
    if (!model || !open) return;
    const { lastModifiedBy, lastModifiedDate, createdDate } = model;
    if (lastModifiedBy && lastModifiedDate !== undefined) {
      getUserItem(lastModifiedBy).then((user: UserType) => {
        if (lastModifiedDate === createdDate) {
          const date = new Date(lastModifiedDate);
          const monthObj = Dates.months.find(
            each => each.valueNumber === date.getMonth() + 1
          );
          const month = monthObj && monthObj.value;
          const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
          setIsUpdated(false);
          setRelatedUsername(user.name);
          setModifyDate(newModifyDate);
        } else {
          const date = new Date(lastModifiedDate);
          const monthObj = Dates.months.find(
            each => each.valueNumber === date.getMonth() + 1
          );
          const month = monthObj && monthObj.value;
          const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
          setIsUpdated(true);
          setRelatedUsername(user.name);
          setModifyDate(newModifyDate);
        }
      });
    } else {
      setIsUpdated(false);
      setModifyDate('');
      setRelatedUsername('');
    }
  }, [open, model, getUserItem]);

  const handleClose = (): void => {
    resetFields();
    onClose();
  };

  const canSubmit = (): boolean => {
    let startDate = '';
    let endDate = '';

    if (startYearDate !== '' && startMonthDate) {
      startDate = new Date(
        Number(startYearDate),
        startMonthDate && startMonthDate.valueNumber
      ).toISOString();
    }

    if (endYearDate !== '' && endMonthDate) {
      endDate = new Date(
        Number(endYearDate),
        endMonthDate && endMonthDate.valueNumber
      ).toISOString();
    }

    if (startDate !== '' && endDate !== '') {
      if (
        new Date(
          Number(startYearDate),
          startMonthDate && startMonthDate.valueNumber
        ) >
        new Date(Number(endYearDate), endMonthDate && endMonthDate.valueNumber)
      ) {
        setDateValidation(false);
        return false;
      }
      setDateValidation(true);
    }

    if (
      startDate !== '' &&
      client &&
      client.startDate &&
      new Date(client.startDate) > new Date(startDate)
    ) {
      setDateValidation(false);
      return false;
    }
    setDateValidation(true);

    if (
      endDate !== '' &&
      client &&
      client.endDate &&
      new Date(client.endDate) < new Date(endDate)
    ) {
      setDateValidation(false);
      return false;
    }
    setDateValidation(true);

    if (
      refAssetManager === undefined ||
      assetType === '' ||
      securitiesEnabled === ''
    )
      return false;
    return true;
  };

  const submit = (): void => {
    if (canSubmit()) {
      let startDate = '';
      let endDate = '';

      if (startYearDate !== '' && startMonthDate)
        startDate = new Date(
          Number(startYearDate),
          startMonthDate && startMonthDate.valueNumber
        ).toISOString();
      if (endYearDate !== '' && endMonthDate)
        endDate = new Date(
          Number(endYearDate),
          endMonthDate && endMonthDate.valueNumber
        ).toISOString();

      const data: any = {
        number,
        refAccountCodeId:
          refAccountCode === undefined || typeof refAccountCode === 'string'
            ? null
            : refAccountCode.id,
        refAccountTypeId:
          refAccountType === undefined || typeof refAccountType === 'string'
            ? null
            : refAccountType.id,
        refAssetManagerId: refAssetManager ? refAssetManager.id : null,
        assetType,
        securitiesEnabled,
        startDate,
        endDate,
        refClientId: Number(clientId),
        hidden: true,
      };
      if (model?.id) {
        update(data, clientId, model.id);
      } else {
        create(data, clientId);
      }
      setShowNotification(true);
    } else {
      setShowError(true);
    }
  };

  const retry = (): void => {
    setShowNotification(false);
  };

  const getYearDate = (date: string): number => {
    return new Date(date).getFullYear();
  };

  const getMonthDate = (date: number): any => {
    return Dates.months.find(
      each => each.valueNumber - 1 === new Date(date).getMonth()
    );
  };

  const clientStartYear =
    client && client.startDate ? getYearDate(client.startDate) : undefined;
  const clientEndYear =
    client && client.endDate ? getYearDate(client.endDate) : undefined;

  const assetTypes = ['LIQUID', 'FIXED'];

  const securities = [
    {
      id: 'No',
      name: 'No',
    },
    {
      id: 'Yes',
      name: 'Yes',
    },
    {
      id: 'Mixed',
      name: 'Mixed',
    },
  ];

  let actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions = [
      {
        label: 'Back',
        onClick: retry,
      },
      {
        label: 'Cancel',
        onClick: handleClose,
        type: 'secondary',
      },
    ];
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      actions={actions}
      title={model && model.id ? 'Edit Account' : 'New Account'}
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Account {model && model.id ? 'updated' : 'added'} successfully.
          </Typography>
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error: {(error && error.messages && error.messages[0]) || 'Unknown'}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div className={classes.formContainer}>
          <TextField
            error={showError && refAssetManager === undefined}
            id="outlined-select-assetManager"
            select
            label="Asset Manager"
            value={refAssetManager || ''}
            onChange={(e: any): void => setRefAssetManager(e.target.value)}
            margin="normal"
            variant="outlined"
            helperText={
              showError && refAssetManager === undefined && 'Required'
            }
            className={classes.fullField}
          >
            {assetManagers.map(
              (option: any): ReactElement => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              )
            )}
          </TextField>

          <TextField
            id="outlined-select-accountCode"
            select
            label="Account Code"
            value={refAccountCode || ''}
            onChange={(e: any): void => setRefAccountCode(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
          >
            <MenuItem value={'<Empty>'}>{'<Empty>'}</MenuItem>
            {accountCodes.map(
              (option: any): ReactElement => (
                <MenuItem key={option.id} value={option}>
                  {option.code}
                </MenuItem>
              )
            )}
          </TextField>

          <TextField
            id="outlined-select-accountType"
            select
            error={showError && refAccountType === undefined}
            label="Account Type"
            value={refAccountType || ''}
            onChange={(e: any): void => setRefAccountType(e.target.value)}
            margin="normal"
            variant="outlined"
            helperText={showError && refAccountType === undefined && 'Required'}
            className={classes.fullField}
          >
            <MenuItem value={'<Empty>'}>{'<Empty>'}</MenuItem>
            {accountTypes.map(
              (option: any): ReactElement => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              )
            )}
          </TextField>

          <TextField
            id="outlined-select-security"
            select
            error={showError && securitiesEnabled === ''}
            label="Securities"
            value={securitiesEnabled}
            onChange={(e: any): void => setSecuritiesEnabled(e.target.value)}
            margin="normal"
            variant="outlined"
            helperText={showError && securitiesEnabled === '' && 'Required'}
            className={classes.fullField}
          >
            {securities.map(
              (option: any): ReactElement => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              )
            )}
          </TextField>

          <TextField
            id="outlined-select-assetType"
            select
            error={showError && assetType === ''}
            label="Asset Type"
            value={assetType || ''}
            onChange={(e: any): void => setAssetType(e.target.value)}
            margin="normal"
            variant="outlined"
            helperText={showError && assetType === '' && 'Required'}
            className={classes.fullField}
          >
            {assetTypes.map(
              (option: string): ReactElement => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              )
            )}
          </TextField>

          <TextField
            id="outlined-number"
            label="Account Number"
            value={number}
            onChange={(e: any): void => setNumber(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
          />

          <div className={classes.fieldWithIconContainer}>
            <Typography variant="subtitle1" className={classes.label}>
              Start Date
            </Typography>
            <TextField
              error={showError && !dateValidation}
              id="outlined-select-startMonthDate"
              select
              label="Month"
              value={startMonthDate || ''}
              onChange={(e: any): void => setStartMonthDate(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.dropDown}
              helperText={showError && !dateValidation && 'Invalid Date'}
            >
              <MenuItem value={undefined}>{'<Empty>'}</MenuItem>
              {Dates.months.map((option: any): any => (
                <MenuItem key={option.value} value={option}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              error={showError && !dateValidation}
              id="outlined-select-startYearDate"
              select
              label="Year"
              value={startYearDate}
              onChange={(e: any): void => setStartYearDate(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.dropDown}
              helperText={showError && !dateValidation && 'Invalid Date'}
            >
              <MenuItem value="">{'<Empty>'}</MenuItem>
              {clientStartYear
                ? Dates.years
                    .filter(
                      (each: any): any =>
                        each.valueNumber >= Number(clientStartYear)
                    )
                    .filter((each: any): any => {
                      if (
                        clientEndYear &&
                        each.valueNumber > Number(clientEndYear)
                      ) {
                        return false;
                      }
                      return true;
                    })
                    .map((option: any): any => (
                      <MenuItem key={option.value} value={option.valueNumber}>
                        {option.label}
                      </MenuItem>
                    ))
                : Dates.years
                    .filter((each: any): any => {
                      if (
                        clientEndYear &&
                        each.valueNumber > Number(clientEndYear)
                      ) {
                        return false;
                      }
                      return true;
                    })
                    .map((option: any): any => (
                      <MenuItem key={option.value} value={option.valueNumber}>
                        {option.label}
                      </MenuItem>
                    ))}
            </TextField>
          </div>
          <div className={classes.fieldWithIconContainer}>
            <Typography variant="subtitle1" className={classes.label}>
              End Date
            </Typography>
            <TextField
              error={showError && !dateValidation}
              id="outlined-select-endMonthDate"
              select
              label="Month"
              value={endMonthDate || ''}
              onChange={(e: any): void => setEndMonthDate(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.dropDown}
              helperText={showError && !dateValidation && 'Invalid Date'}
            >
              <MenuItem value={undefined}>{'<Empty>'}</MenuItem>
              {Dates.months.map((option: any): any => (
                <MenuItem key={option.value} value={option}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              error={showError && !dateValidation}
              id="outlined-select-endYearDate"
              select
              label="Year"
              value={endYearDate}
              onChange={(e: any): void => setEndYearDate(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.dropDown}
              helperText={showError && !dateValidation && 'Invalid Date'}
            >
              <MenuItem value="">{'<Empty>'}</MenuItem>
              {startYearDate !== ''
                ? Dates.years
                    .filter(
                      (each: any): any =>
                        each.valueNumber >= Number(startYearDate)
                    )
                    .filter((each: any): any => {
                      if (
                        clientEndYear &&
                        each.valueNumber > Number(clientEndYear)
                      ) {
                        return false;
                      }
                      return true;
                    })
                    .map((option: any): any => (
                      <MenuItem key={option.value} value={option.valueNumber}>
                        {option.label}
                      </MenuItem>
                    ))
                : Dates.years
                    .filter((each: any): any => {
                      if (
                        clientStartYear &&
                        each.valueNumber < Number(clientStartYear)
                      ) {
                        return false;
                      }
                      return true;
                    })
                    .filter((each: any): any => {
                      if (
                        clientEndYear &&
                        each.valueNumber > Number(clientEndYear)
                      ) {
                        return false;
                      }
                      return true;
                    })
                    .map((option: any): any => (
                      <MenuItem key={option.value} value={option.valueNumber}>
                        {option.label}
                      </MenuItem>
                    ))}
            </TextField>
          </div>
          {relatedUsername !== '' && (
            <Typography variant="subtitle2" className={classes.info}>
              {isUpdated
                ? `Updated by: ${relatedUsername} on ${modifyDate}`
                : `Created by: ${relatedUsername} on ${modifyDate}`}
            </Typography>
          )}
        </div>
      )}
    </Dialog>
  );
};

DialogForm.defaultProps = {
  model: undefined,
  error: undefined,
  success: false,
  loading: false,
  client: undefined,
};

export default DialogForm;
