import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const performance = (
  clientId: number,
  sharp: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>
): any =>
  Request(`assettable/performance`, 'POST', {
    year,
    month,
    sharp,
    id: clientId,
    disabledColumns,
    enabledRows,
  })
    .then(ErrorHandler)
    .then((performanceData: any): any => performanceData);

const PerformanceService = {
  performance,
};

export default PerformanceService;
