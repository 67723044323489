import React, { useState, useEffect, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import Dates from '../../constants/Date';
import DialogForm from './DialogForm';
import styles from './styles';
import {
  UserType,
  UserRoleType,
  ClientType,
  UpdateUserType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface UserClientType extends ClientType {
  startDateTable: string;
  endDateTable: string;
}

interface Props {
  getUserItem: (id: number) => Promise<any>;
  getClientsList: () => void;
  updateUser: (obj: UpdateUserType, id: number) => Promise<any>;
  openDialog: () => void;
  closeDialog: () => void;
  deleted: boolean;
  success: boolean;
  error: any;
  loading: boolean;
  match: any;
  user: UserType;
  clients: { content: Array<ClientType> };
}

const UserClients = (props: Props): ReactElement => {
  const {
    getUserItem,
    getClientsList,
    match: {
      params: { userId },
    },
    openDialog,
    closeDialog,
    updateUser,
    user,
    loading,
    success,
    error,
    clients,
    ...others
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [model, setModel] = useState<UserClientType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<UserClientType | undefined>(
    undefined
  );
  const [successU, setSuccessU] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    getClientsList();
    getUserItem(userId);
  }, [getUserItem, getClientsList, userId]);

  const openAdd = (): void => {
    openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: UserClientType): void => {
    openDialog();
    setOpenDelete(true);
    setSelectedRow(row);
  };

  const handleClose = (): void => {
    closeDialog();
    setOpen(false);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) {
      const newClients = user.clients
        .filter((each: ClientType): any => each.id !== selectedRow.id)
        .map((each: ClientType): any => ({
          ...each,
          refAssetClassSet: {
            createdBy: each.refAssetClassSet.createdBy,
            createdDate: each.refAssetClassSet.createdDate,
            id: each.refAssetClassSet.id,
            lastModifiedBy: each.refAssetClassSet.lastModifiedBy,
            lastModifiedDate: each.refAssetClassSet.lastModifiedDate,
            name: each.refAssetClassSet.name,
            useCorrelation: each.refAssetClassSet.useCorrelation,
          },
        }));
      updateUser(
        {
          name: user.name,
          password: user.password,
          email: user.email,
          username: user.username,
          roles: user.roles,
          clients: newClients,
          accessToAllClients: user.accessToAllClients,
        },
        user.id
      ).then(() => getUserItem(user.id).then(() => setSuccessU(true)));
    }
  };

  const getDate = (dateInput: string): string => {
    if (dateInput) {
      const date = new Date(dateInput);
      const year = date.getFullYear();
      const monthObj = Dates.months.find(
        each => each.valueNumber - 1 === date.getMonth()
      );
      const month = monthObj && monthObj.label;
      return `${month}-${year}`;
    }
    return '';
  };

  const columns = [
    {
      name: 'name',
      title: 'Name',
      style: { width: 250 },
      rowStyle: { width: 250 },
      searchable: true,
      sortable: true,
      type: 'string',
      sortField: 'name',
    },
    {
      name: 'refAssetClassSet.name',
      title: 'Asset Class Set',
      searchable: true,
      sortable: true,
      type: 'string',
      sortField: 'refAssetClassSet.name',
    },
    {
      title: 'Start Date',
      name: 'startDateTable',
      sortable: true,
      sortField: 'startDate',
      type: 'number',
    },
    {
      title: 'End Date',
      name: 'endDateTable',
      sortable: true,
      sortField: 'endDate',
      type: 'number',
    },
  ];

  const breadcrumbItems = [
    {
      name: 'Users',
      route: '/users',
    },
    {
      name: user ? user.name : '',
    },
  ];

  const editedClients =
    (user &&
      user.clients &&
      user.clients.map(
        (each: ClientType): UserClientType => ({
          ...each,
          startDateTable: getDate(each.startDate),
          endDateTable: getDate(each.endDate),
        })
      )) ||
    [];

  const filteredClients =
    (user &&
      user.clients &&
      clients &&
      clients.content &&
      clients.content.filter(
        (each: ClientType): any =>
          !user.clients.find(
            (eachClient: ClientType): any => eachClient.id === each.id
          )
      )) ||
    [];

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          hasEditAndDelete
          onDelete={handleDeleteOpen}
          onAdd={openAdd}
          columns={columns}
          noMargin
          hasBackButton
          hasHighlightBackground
          rows={editedClients}
          breadcrumbItems={breadcrumbItems}
          hasBreadcrumb
          entity="Client"
          entities="Clients"
          {...others}
        />
      </div>
      <DialogForm
        open={open}
        onClose={handleClose}
        update={updateUser}
        loading={loading}
        success={success}
        getUser={getUserItem}
        error={error}
        user={user}
        clients={filteredClients}
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {isRemoving && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && successU && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Client removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !successU && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error: {error && error.messages && error.messages[0]}
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};

UserClients.defaultProps = {
  deleted: false,
  client: undefined,
  success: undefined,
  error: undefined,
  assetClassSets: undefined,
  profile: undefined,
  match: undefined,
  user: undefined,
  clients: undefined,
};

export default UserClients;
