import React, { useState, useEffect, ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Dates from '../../constants/Date';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import {
  AssetClassType,
  AssetClassSetType,
  UserType,
  RiskClassType,
  BenchmarkType,
  MutateAssetClassType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  onClose: () => void;
  model: AssetClassType;
  open: boolean;
  update?: (obj: MutateAssetClassType, id: number, dataId: number) => void;
  create?: (obj: MutateAssetClassType, id: number) => void;
  riskClasses: Array<RiskClassType>;
  assetClassSetId: number;
  assetClassSet: AssetClassSetType;
  success: boolean;
  loading: boolean;
  error: any;
  getUserItem?: (id: number) => Promise<UserType>;
  benchmarks: Array<BenchmarkType>;
}

const DialogForm = (props: Props): ReactElement => {
  const {
    model,
    open,
    riskClasses,
    getUserItem,
    onClose,
    update,
    create,
    assetClassSetId,
    loading,
    error,
    success,
    benchmarks,
  } = props;

  const [rankno, setRankno] = useState<string | number | undefined>('');
  const [description, setDescription] = useState<string>('');
  const [columnHeading, setColumnHeading] = useState<string>('');
  const [riskFactor, setRiskFactor] = useState<string | number | undefined>('');
  const [returnFactor, setReturnFactor] = useState<string | number | undefined>(
    ''
  );
  const [refRiskClass, setRefRiskClass] = useState<any | undefined>(undefined);
  const [refBenchmark, setRefBenchmark] = useState<any | undefined>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [modifyDate, setModifyDate] = useState<string>('');
  const [relatedUsername, setRelatedUsername] = useState<string>('');
  const classes = useStyles();

  const resetFields = (): void => {
    setRankno('');
    setDescription('');
    setColumnHeading('');
    setRiskFactor('');
    setReturnFactor('');
    setRefRiskClass({});
    setRefBenchmark('');
    setShowError(false);
    setShowNotification(false);
    setIsUpdated(false);
    setModifyDate('');
    setRelatedUsername('');
  };

  useEffect(() => {
    if (!model || !open) return;
    if (model.id) {
      setRankno(model.rankno);
      setDescription(model.description);
      setColumnHeading(model.columnHeading);
      setRiskFactor(model.riskFactor);
      setReturnFactor(model.returnFactor);
      setRefRiskClass(
        riskClasses.find(
          (rc: any): any => rc && rc.id === model.refRiskClass.id
        )
      );
      setRefBenchmark(
        benchmarks.find((b: any): any => b && b.id === model?.refBenchmark?.id)
      );
    } else {
      resetFields();
    }
  }, [model, open]);

  useEffect(() => {
    if (!model || !open) return;
    const { lastModifiedBy, lastModifiedDate, createdDate } = model;
    if (lastModifiedBy && lastModifiedDate !== undefined) {
      if (getUserItem)
        getUserItem(lastModifiedBy).then((user: UserType): void => {
          if (lastModifiedDate === createdDate) {
            const date = new Date(lastModifiedDate);
            const monthObj = Dates.months.find(
              each => each.valueNumber === date.getMonth() + 1
            );
            const month = monthObj && monthObj.value;
            const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
            setIsUpdated(false);
            setRelatedUsername(user.name);
            setModifyDate(newModifyDate);
          } else {
            const date = new Date(lastModifiedDate);
            const monthObj = Dates.months.find(
              each => each.valueNumber === date.getMonth() + 1
            );
            const month = monthObj && monthObj.value;
            const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
            setIsUpdated(true);
            setRelatedUsername(user.name);
            setModifyDate(newModifyDate);
          }
        });
    } else {
      setIsUpdated(false);
      setModifyDate('');
      setRelatedUsername('');
    }
  }, [open, model, getUserItem]);

  const handleClose = (): void => {
    resetFields();
    onClose();
  };

  const canSubmit = (): boolean => {
    if (rankno === '' || refRiskClass === undefined) return false;
    return true;
  };

  const submit = (): void => {
    if (canSubmit()) {
      if (model?.id && update) {
        update(
          {
            rankno: Number(rankno),
            description,
            columnHeading,
            riskFactor: Number(riskFactor),
            returnFactor: Number(returnFactor),
            refRiskClassId: refRiskClass.id,
            refAssetClassSetId: assetClassSetId,
            refBenchmarkId: refBenchmark?.id,
            notInTotal: model?.notInTotal,
            inPerformance: model?.inPerformance,
            hidden: model?.hidden,
          },
          assetClassSetId,
          model.id
        );
      } else {
        const data = {
          rankno: Number(rankno),
          description,
          columnHeading,
          riskFactor: Number(riskFactor),
          returnFactor: Number(returnFactor),
          refRiskClassId: refRiskClass.id,
          hidden: true,
          refAssetClassSetId: assetClassSetId,
          notInTotal: true,
          inPerformance: true,
          refBenchmarkId: refBenchmark.id,
        };
        if (create) create(data, assetClassSetId);
      }
      setShowNotification(true);
    } else {
      setShowError(true);
    }
  };

  const retry = (): void => {
    setShowNotification(false);
  };

  let actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions = [
      {
        label: 'Back',
        onClick: retry,
      },
      {
        label: 'Cancel',
        onClick: handleClose,
        type: 'secondary',
      },
    ];
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      actions={actions}
      title={model && model.id ? 'Edit Asset Class' : 'New Asset Class'}
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Asset Class {model && model.id ? 'updated' : 'added'} successfully.
          </Typography>
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error: {(error && error.messages && error.messages[0]) || 'Unknown'}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div className={classes.formContainer}>
          <TextField
            error={showError && rankno === ''}
            id="outlined-rankno"
            label="Rank No"
            value={rankno}
            onChange={(e: any): void => setRankno(e.target.value)}
            margin="normal"
            variant="outlined"
            helperText={showError && rankno === '' && 'Required'}
            className={classes.fullField}
          />
          <TextField
            id="outlined-description"
            label="Description"
            value={description}
            onChange={(e: any): void => setDescription(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
          />
          <TextField
            id="outlined-columnHeading"
            label="Column Heading"
            value={columnHeading}
            onChange={(e: any): void => setColumnHeading(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
          />
          <TextField
            id="outlined-riskFactor"
            label="Risk Factor"
            value={riskFactor}
            onChange={(e: any): void => setRiskFactor(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.halfField}
          />
          <TextField
            id="outlined-returnFactor"
            label="Return Factor"
            value={returnFactor}
            onChange={(e: any): void => setReturnFactor(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.halfField}
          />
          <TextField
            id="outlined-select-riskClass"
            select
            error={showError && refRiskClass === undefined}
            label="Risk Class"
            value={refRiskClass || ''}
            onChange={(e: any): void => setRefRiskClass(e.target.value)}
            margin="normal"
            variant="outlined"
            helperText={showError && refRiskClass === undefined && 'Required'}
            className={classes.fullField}
          >
            {riskClasses.map(
              (option: any): ReactElement => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              )
            )}
          </TextField>
          <TextField
            id="outlined-select-benchmark"
            select
            error={showError && refBenchmark === undefined}
            label="Benchmark"
            value={refBenchmark || ''}
            onChange={(e: any): void => setRefBenchmark(e.target.value)}
            margin="normal"
            variant="outlined"
            helperText={showError && refBenchmark === undefined && 'Required'}
            className={classes.fullField}
          >
            {benchmarks.map(
              (option: any): ReactElement => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              )
            )}
          </TextField>
          {relatedUsername !== '' && (
            <Typography variant="subtitle2" className={classes.info}>
              {isUpdated
                ? `Updated by: ${relatedUsername} on ${modifyDate}`
                : `Created by: ${relatedUsername} on ${modifyDate}`}
            </Typography>
          )}
        </div>
      )}
    </Dialog>
  );
};

DialogForm.defaultProps = {
  model: undefined,
  error: undefined,
  success: false,
  assetClassSet: undefined,
  loading: false,
};

export default DialogForm;
