import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import ClientActions from '../../../../actions/ClientActions';
import BenchmarkActions from '../../../../actions/BenchmarkActions';
import Monitor from './Monitor';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ ...ClientActions, ...BenchmarkActions }, dispatch);

const getClientId = (state: any): number =>
  state.Client.selectedClient && state.Client.selectedClient.id;
const getClientData = (state: any): any => state.Client.clientData;
const getCurrentDate = (state: any): any => state.Client.currentDate;

const getFoundClientData = createSelector(
  [getClientData, getClientId],
  (data, id) => data && data.find((each: any): any => each.id === id)
);

const getCurrentDateData = createSelector(
  [getFoundClientData, getCurrentDate],
  (data, date) =>
    data &&
    data.dates &&
    data.dates.find((eachDate: any): any => eachDate.date === date)
);

const getMonitorOfCurrentDate = createSelector(
  [getCurrentDateData],
  data => data && data.monitor
);

const getSelectedShowingRows = createSelector(
  [getFoundClientData],
  data => data && data.monitorShowingRows
);

const getSelectedRows = createSelector(
  [getFoundClientData],
  data => data && data.selectedRows
);

const getSelectedColumns = createSelector(
  [getFoundClientData],
  data => data && data.selectedColumns
);

const getSelectedDate = createSelector(
  [getFoundClientData],
  data => data && data.selectedDate
);

const getSelectedChart = createSelector(
  [getFoundClientData],
  data => data && data.monitorChart
);

const mapStateToProps = (state: any): any => ({
  loading: state.Client.loadingData,
  client: state.Client.selectedClient,
  monitorData: getMonitorOfCurrentDate(state),
  selectedShowingRows: getSelectedShowingRows(state),
  selectedRows: getSelectedRows(state),
  selectedColumns: getSelectedColumns(state),
  selectedDate: getSelectedDate(state),
  selectedChart: getSelectedChart(state),
  selectionChanged: state.Client.selectionChanged,
});

export default connect(mapStateToProps, mapDispatchToProps)(Monitor);
