import AccountTypeService from '../services/AccountTypeService';
import * as types from '../constants/AccountType';
import { AccountTypeType } from '../Types';

const getAccountTypesList = (): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_TYPES_LIST_REQUEST });
  AccountTypeService.list()
    .then((accountTypes: { content: Array<AccountTypeType> }): void => {
      dispatch({ type: types.ACCOUNT_TYPES_LIST_SUCCESS, accountTypes });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_TYPES_LIST_FAILURE, error });
    });
};

const getAccountTypeItem = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_TYPE_ITEM_REQUEST });
  AccountTypeService.item(id)
    .then((accountType: AccountTypeType): void => {
      dispatch({ type: types.ACCOUNT_TYPE_ITEM_SUCCESS, accountType });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_TYPE_ITEM_FAILURE, error });
    });
};

const createAccountType = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_TYPE_CREATE_REQUEST });
  AccountTypeService.create(data)
    .then((accountType: any): void => {
      dispatch({ type: types.ACCOUNT_TYPE_CREATE_SUCCESS, accountType });
      dispatch(getAccountTypesList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ACCOUNT_TYPE_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ACCOUNT_TYPE_CREATE_FAILURE, error });
      }
    });
};

const updateAccountType = (data: any, id: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.ACCOUNT_TYPE_UPDATE_REQUEST });
  AccountTypeService.update(data, id)
    .then((accountType: any): void => {
      dispatch({ type: types.ACCOUNT_TYPE_UPDATE_SUCCESS, accountType });
      dispatch(getAccountTypesList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ACCOUNT_TYPE_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ACCOUNT_TYPE_UPDATE_FAILURE, error });
      }
    });
};

const removeAccountType = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ACCOUNT_TYPE_REMOVE_REQUEST });
  AccountTypeService.remove(id)
    .then((accountType: any): void => {
      dispatch({ type: types.ACCOUNT_TYPE_REMOVE_SUCCESS, accountType, id });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ACCOUNT_TYPE_REMOVE_FAILURE, error });
    });
};

const AccountTypeActions = {
  getAccountTypesList,
  getAccountTypeItem,
  createAccountType,
  updateAccountType,
  removeAccountType,
};

export default AccountTypeActions;
