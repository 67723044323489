export const REGIONS_LIST_REQUEST = 'REGIONS_LIST_REQUEST';
export const REGIONS_LIST_SUCCESS = 'REGIONS_LIST_SUCCESS';
export const REGIONS_LIST_FAILURE = 'REGIONS_LIST_FAILURE';
export const REGION_CREATE_REQUEST = 'REGION_CREATE_REQUEST';
export const REGION_CREATE_SUCCESS = 'REGION_CREATE_SUCCESS';
export const REGION_CREATE_FAILURE = 'REGION_CREATE_FAILURE';
export const REGION_UPDATE_REQUEST = 'REGION_UPDATE_REQUEST';
export const REGION_UPDATE_SUCCESS = 'REGION_UPDATE_SUCCESS';
export const REGION_UPDATE_FAILURE = 'REGION_UPDATE_FAILURE';
export const REGION_ITEM_REQUEST = 'REGION_ITEM_REQUEST';
export const REGION_ITEM_SUCCESS = 'REGION_ITEM_SUCCESS';
export const REGION_ITEM_FAILURE = 'REGION_ITEM_FAILURE';
export const REGION_REMOVE_REQUEST = 'REGION_REMOVE_REQUEST';
export const REGION_REMOVE_SUCCESS = 'REGION_REMOVE_SUCCESS';
export const REGION_REMOVE_FAILURE = 'REGION_REMOVE_FAILURE';
