import React, {
  useState,
  useEffect,
  useRef,
  ReactElement,
  useLayoutEffect,
} from 'react';
import classnames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckboxIcon from '@material-ui/icons/CheckBox';
import CheckboxBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import TableFooter from '@material-ui/core/TableFooter';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Popper from '@material-ui/core/Popper';
import MoreIcon from '@material-ui/icons/MoreVert';
import LensIcon from '@material-ui/icons/Lens';
import CommentIcon from '@material-ui/icons/ModeComment';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/EditOutlined';
import RemoveIcon from '@material-ui/icons/Clear';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './styles';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  rows: any;
  columns: any;
  hasColumnCheckbox: boolean;
  hasRowCheckbox: boolean;
  selectOnDoubleClick: boolean;
  moveBetweenCells: boolean;
  onHoverColumnHeading: boolean;
  showEditOnHover: boolean;
  editOptions: any;
  cellRootClass: string;
  checkboxBlankInputIcon: string;
  checkedAllIcon: string;
  spaceBeforeTotalRow: boolean;
  totalRow: any;
  totalCellClass: string;
  role: string;
  hasChangeTableStatusButton: boolean;
  onChangeTableStatus: any;
  changeTableStatusIcon: string;
  changeStatusTableTitle: string;
  hasSecondChangeTableStatusButton: boolean;
  onSecondChangeTableStatus: any;
  secondChangeTableStatusIcon: string;
  secondChangeStatusTableTitle: string;
  onShrink: any;
  onExpand: any;
  goToDetail: any;
  showPopperForHeaderCell: boolean;
  cellDetailsIcon: any;
  cellDetailIconHovered: any;
  onSubmit: any;
  checkedIcon: string;
  extraOption: any;
  handleCheckRows: any;
  handleCheckColumns: any;
  focusOnTable: boolean;
  hasExpandColumns: boolean;
  onExpandColumns: any;
  expandColumnsIcon: string;
  expandColumnsTitle: string;
  numberPrecision: number;
  stickyHeader: boolean;
  headerCellClass: string;
  totalRowDetails: any;
  hasTotalRowDetails: boolean;
  onShrinkTotal: any;
  onExpandTotal: any;
  loading: boolean;
  disableOnLoading: boolean;
  getCellValue: any;
  formatCellMethod: any;
  tableStatusComponent: any;
  secondTableStatusComponent: any;
  showHeader: boolean;
  placeCellBeforeRow: boolean;
  selectOnEnter: boolean;
  hasColumnSelection: boolean;
  dontShowZero: boolean;
}

const ValuesTable = (props: Props): ReactElement => {
  const {
    rows,
    columns,
    hasColumnCheckbox,
    hasRowCheckbox,
    selectOnDoubleClick,
    moveBetweenCells,
    onHoverColumnHeading,
    showEditOnHover,
    editOptions,
    extraOption,
    cellRootClass,
    checkboxBlankInputIcon,
    checkedAllIcon,
    checkedIcon,
    spaceBeforeTotalRow,
    totalRow,
    totalCellClass,
    role,
    hasChangeTableStatusButton,
    onChangeTableStatus,
    changeTableStatusIcon,
    changeStatusTableTitle,
    hasSecondChangeTableStatusButton,
    onSecondChangeTableStatus,
    secondChangeTableStatusIcon,
    secondChangeStatusTableTitle,
    onShrink,
    onExpand,
    goToDetail,
    showPopperForHeaderCell,
    cellDetailsIcon,
    cellDetailIconHovered,
    onSubmit,
    handleCheckRows,
    handleCheckColumns,
    focusOnTable,
    hasExpandColumns,
    onExpandColumns,
    expandColumnsIcon,
    expandColumnsTitle,
    numberPrecision,
    stickyHeader,
    headerCellClass,
    totalRowDetails,
    hasTotalRowDetails,
    onExpandTotal,
    onShrinkTotal,
    loading,
    disableOnLoading,
    tableStatusComponent,
    secondTableStatusComponent,
    formatCellMethod,
    getCellValue,
    showHeader,
    placeCellBeforeRow,
    selectOnEnter,
    hasColumnSelection,
    dontShowZero,
  } = props;

  const [hoveredColumn, setHoveredColumn] = useState<any>(undefined);
  const [hoveredElement, setHoveredElement] = useState<any>(null);
  const [
    columnDetailsAnchorEelement,
    setColumnDetailsAnchorEelement,
  ] = useState<any>(null);
  const [focusedCell, setFocusedCell] = useState<string>('');
  const [selectedCell, setSelectedCell] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<any>(undefined);
  const [hoveredCell, setHoveredCell] = useState<string>('');
  const [hoveredCellElement, setHoveredCellElement] = useState<any>(null);
  const [value, setValue] = useState<any>(undefined);
  const [detailMessage, setDetailMessage] = useState<string>('');
  const [detailAnchorEl, setDetailAnchorEl] = useState<any>(null);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<any>(null);
  const classes = useStyles();

  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {});

  const resolve = (path: string, obj: any): any =>
    path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj);

  const handleHoverColumnHeading = (event: any, columnId: string): void => {
    setHoveredColumn(columnId);
    setHoveredElement(event.currentTarget);
  };
  const handleCancelHoverColumnHeading = (): void => {
    setHoveredElement(null);
    setHoveredColumn(undefined);
  };

  const formatNumber = (number: string | number): string => {
    const newNumber = Number(number).toFixed(numberPrecision);
    if (newNumber !== 'NaN') {
      return newNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return '';
  };

  const onCellKeyPress = (event: any, passedCell: string): void => {
    if (focusedCell === selectedCell) return;
    const cell = passedCell || selectedCell;
    event.preventDefault();
    let rowIndex;
    let columnIndex;
    rows.forEach((r: any, index: number): void => {
      if (cell.indexOf(`${r.id}`) === 0) {
        rowIndex = index;
      }
    });
    columns.forEach((column: any, index: number): void => {
      if (cell.includes(column.columnId)) {
        columnIndex = index;
      }
    });
    if (focusedCell === '' && moveBetweenCells) {
      if (event.key === 'ArrowUp') {
        if (
          rowIndex !== undefined &&
          !(rowIndex < 1) &&
          columnIndex !== undefined
        ) {
          const newColumn = columns[columnIndex];
          const row = rows[rowIndex - 1];
          if (row.isDetail && row.hidden) {
            const mainRow = rows.find((r: any): any => r.id === row.sourceId);
            const newRow = rows[rowIndex - mainRow.details.length - 1];
            setSelectedCell(`${newRow.id}-${newColumn.columnId}`);
            setSelectedRow(newRow.id);
          } else if (row.hiddenRow) {
            const newRow = rows[rowIndex - 2];
            setSelectedCell(`${newRow.id}-${newColumn.columnId}`);
            setSelectedRow(newRow.id);
          } else {
            const newRow = rows[rowIndex - 1];
            setSelectedCell(`${newRow.id}-${newColumn.columnId}`);
            setSelectedRow(newRow.id);
          }
        }
      } else if (event.key === 'ArrowDown') {
        if (
          rowIndex !== undefined &&
          rowIndex < rows.length - 1 &&
          columnIndex !== undefined
        ) {
          const newColumn = columns[columnIndex];
          const row = rows[rowIndex];
          if (row.hasDetails && !row.isExpanded) {
            if (rows[rowIndex + row.details.length + 1]) {
              const newRow = rows[rowIndex + row.details.length + 1];
              setSelectedCell(`${newRow.id}-${newColumn.columnId}`);
              setSelectedRow(newRow.id);
            }
          } else {
            const newRow = rows[rowIndex + 1];
            if (newRow.hiddenRow) {
              const nextRow = rows[rowIndex + 2];
              setSelectedCell(`${nextRow.id}-${newColumn.columnId}`);
              setSelectedRow(nextRow.id);
            } else {
              setSelectedCell(`${newRow.id}-${newColumn.columnId}`);
              setSelectedRow(newRow.id);
            }
          }
        }
      } else if (event.key === 'ArrowLeft') {
        if (
          columnIndex !== undefined &&
          !(columnIndex <= 1) &&
          rowIndex !== undefined
        ) {
          const newColumn = columns[columnIndex - 1];
          const row = rows[rowIndex];
          setSelectedCell(`${row.id}-${newColumn.columnId}`);
          setSelectedRow(row.id);
        }
      } else if (event.key === 'ArrowRight') {
        if (
          columnIndex !== undefined &&
          columnIndex < columns.length - 1 &&
          rowIndex !== undefined
        ) {
          const newColumn = columns[columnIndex + 1];
          const row = rows[rowIndex];
          setSelectedCell(`${row.id}-${newColumn.columnId}`);
          setSelectedRow(row.id);
        }
      } else if (
        event.key === 'Enter' &&
        rowIndex !== undefined &&
        (!rows[rowIndex].securities || selectOnEnter)
      ) {
        if (role === 'VIEWER') return;
        setFocusedCell(cell);
      }
    }
  };

  const onClick = (event: any): void => {
    if (!(ref.current && ref.current.contains(event.target))) {
      setSelectedCell('');
      setSelectedRow(undefined);
    }
    if (!(event && event.toElement && event.toElement.nodeName === 'INPUT')) {
      setFocusedCell('');
      setValue(undefined);
    }
  };

  const onDoubleClick = (event: void, row: any, col: any): void => {
    if (role === 'VIEWER') return;
    setFocusedCell(`${row.id}-${col.columnId}`);
  };

  useEffect(() => {
    if (focusOnTable) {
      window.addEventListener('keydown', onCellKeyPress as EventListener);
      window.addEventListener('click', onClick);
    }

    return (): void => {
      window.removeEventListener('keydown', onCellKeyPress as EventListener);
      window.removeEventListener('click', onClick);
    };
  }, [onCellKeyPress, focusOnTable]);
  const visibleColumns = columns.filter((each: any): any => !each.hidden);

  const onClickDetailCell = (event: any, row: any, name: string): void => {
    setDetailAnchorEl(event.currentTarget);
    setDetailMessage(row[`${name}.detail`]);
  };

  const handleFocus = (event: any): void => event.target.select();

  const onKeyDownTextField = (event: any): void => {
    if (event.key === 'Escape') {
      setFocusedCell('');
      setValue(undefined);
    }
  };

  const onKeyPress = (event: any, row: any, name: string): void => {
    if (role === 'VIEWER') return;
    if (event.key === 'Enter') {
      onSubmit(row, name, value);
      setFocusedCell('');
      setValue(undefined);
    } else if (event.key === 'Escape') {
      setFocusedCell('');
      setValue(undefined);
    }
  };

  const BodyCells = (): ReactElement =>
    rows &&
    rows.map(
      (row: any): ReactElement | any =>
        !row.hidden &&
        !row.hiddenRow && (
          <TableRow
            key={row.id}
            selected={selectedRow === row.id}
            className={classes.rowContainer}
            onClick={(): void => setSelectedRow(row.id)}
          >
            {!row.isDetail && hasRowCheckbox && (
              <TableCell
                className={classnames(
                  classes.noneEffectCell,
                  classes.checkCellBackground
                )}
                classes={{
                  root: classes.checkCellRoot,
                }}
              >
                <Checkbox
                  onChange={(event: any, checked: boolean): void => {
                    handleCheckRows(row.id, checked);
                    event.stopPropagation();
                  }}
                  disabled={disableOnLoading && loading}
                  className={classes.checkbox}
                  checked={row.enabled}
                  classes={{
                    root: classes.checkboxRootSide,
                  }}
                  icon={
                    checkboxBlankInputIcon ? (
                      <img
                        className={classes.checkboxSize}
                        src={checkboxBlankInputIcon}
                        alt=""
                      />
                    ) : (
                      <CheckboxBlankIcon />
                    )
                  }
                  checkedIcon={
                    checkedIcon ? (
                      <img
                        className={classes.checkboxSize}
                        src={checkedIcon}
                        alt=""
                      />
                    ) : (
                      <CheckboxIcon />
                    )
                  }
                />
              </TableCell>
            )}
            {row.isDetail && (
              <TableCell
                className={classes.noneEffectCell}
                style={{ background: 'white' }}
              />
            )}
            {placeCellBeforeRow && (
              <TableCell
                className={classes.noneEffectCell}
                style={{ background: 'white', backgroundClip: 'padding-box' }}
                classes={{
                  root: classes.checkCellRoot,
                }}
              />
            )}
            {columns.map(
              (col: any): ReactElement | any =>
                col &&
                !col.hidden && (
                  <TableCell
                    key={`${row.id}-${col.columnId}`}
                    onMouseEnter={(event: any): void => {
                      setHoveredCell(`${row.id}-${col.columnId}`);
                      setHoveredCellElement(event.currentTarget);
                    }}
                    onMouseLeave={(): void => {
                      setHoveredCell('');
                      setHoveredCellElement(null);
                    }}
                    className={classnames(classes.cell, {
                      [classes.selectedRow]: row.id === selectedRow,
                      [classes.disabledRow]: row.enabled === false,
                      [classes.selectedCell]:
                        selectedCell === `${row.id}-${col.columnId}`,
                      [classes.subjectCell]: col.type === 'header',
                    })}
                    align="left"
                    classes={{
                      root: classes.cellRoot,
                    }}
                    style={{
                      ...row.style,
                      ...col.style,
                      ...row[`${col.name}.style`],
                    }}
                    onClick={
                      col.type !== 'header' ||
                      focusedCell !== `${row.id}-${col.columnId}`
                        ? (): void =>
                            setSelectedCell(`${row.id}-${col.columnId}`)
                        : (): void => {}
                    }
                    onDoubleClick={
                      selectOnDoubleClick
                        ? (event: any): void => onDoubleClick(event, row, col)
                        : (): void => {}
                    }
                  >
                    {col.type === 'header' && (
                      <div>
                        {row.hasDetails && row.isExpanded && (
                          <Tooltip title="Shrink">
                            <IconButton
                              onClick={(event: any): any =>
                                onShrink(event, row)
                              }
                              aria-label="Shrink"
                              style={{
                                marginRight: -2,
                                marginLeft: -8,
                                textAlign: 'left',
                              }}
                              classes={{
                                root: classes.iconRoot,
                              }}
                            >
                              <UpIcon style={{ fontSize: '20px' }} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {row.hasDetails && !row.isExpanded && (
                          <Tooltip title="Expand">
                            <span>
                              <IconButton
                                onClick={(event: any): void =>
                                  onExpand(event, row)
                                }
                                aria-label="Expand"
                                style={{ marginRight: -2, marginLeft: -8 }}
                                classes={{
                                  root: classes.iconRoot,
                                }}
                                disabled={row.details.length < 1}
                              >
                                <DownIcon style={{ fontSize: '20px' }} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                        {row.isDetail && (
                          <Tooltip title="Details">
                            <IconButton
                              onClick={(event: any): void =>
                                goToDetail(event, row)
                              }
                              aria-label="Details"
                              style={{
                                marginRight: '5px',
                                marginLeft: '-5px',
                              }}
                              classes={{
                                root: classes.iconRoot,
                              }}
                            >
                              <InfoIcon
                                style={{
                                  fontSize: '18px',
                                  marginBottom: 2,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        <span
                          style={{ color: row.hasTotalWithNoValue && 'red' }}
                        >
                          {resolve(col.name, row)}
                        </span>
                        {hoveredCell === `${row.id}-${col.columnId}` &&
                          !row.isDeatil &&
                          showPopperForHeaderCell && (
                            <Popper
                              id={`${row.id}-${col.columnId}`}
                              open={hoveredCell === `${row.id}-${col.columnId}`}
                              anchorEl={hoveredCellElement}
                              placement="right"
                              style={{ zIndex: 5000 }}
                            >
                              {row.PopperDetails}
                            </Popper>
                          )}
                      </div>
                    )}
                    {col.type === 'readOnly' && (
                      <span>{resolve(col.name, row)}</span>
                    )}
                    <div
                      style={{ position: 'absolute', width: '100%', top: 0 }}
                    >
                      {row[`${col.name}.hasDetail`] &&
                        hoveredCell !== `${row.id}-${col.columnId}` && (
                          <IconButton
                            onClick={(event: any): void =>
                              onClickDetailCell(event, row, col.name)
                            }
                            className={classes.cellIcon}
                            classes={{
                              root: classes.inCellIconRoot,
                            }}
                          >
                            {cellDetailsIcon || (
                              <LensIcon
                                style={{ fontSize: '5px', color: '#1976d2' }}
                              />
                            )}
                          </IconButton>
                        )}
                      {row[`${col.name}.hasDetail`] &&
                        hoveredCell === `${row.id}-${col.columnId}` && (
                          <>
                            <IconButton
                              onClick={(event: any): void =>
                                onClickDetailCell(event, row, col.name)
                              }
                              className={classes.cellIcon}
                              classes={{
                                root: classes.inCellIconRoot,
                              }}
                              style={{
                                display: 'block',
                                color: 'transparent',
                                textShadow: '0 0 0 black',
                                top: -1,
                                left: -2,
                              }}
                            >
                              {cellDetailIconHovered || (
                                <CommentIcon
                                  style={{ fontSize: '10px', color: '#1976d2' }}
                                />
                              )}
                            </IconButton>
                            <Popover
                              open={Boolean(detailAnchorEl)}
                              anchorEl={detailAnchorEl}
                              onClose={(): void => {
                                setDetailAnchorEl(null);
                                setDetailMessage('');
                              }}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              <Typography className={classes.commentPopover}>
                                {detailMessage}
                              </Typography>
                            </Popover>
                          </>
                        )}
                      {hoveredCell === `${row.id}-${col.columnId}` &&
                        focusedCell !== `${row.id}-${col.columnId}` &&
                        row[`${col.name}.isEditable`] &&
                        showEditOnHover && (
                          <>
                            <div
                              style={{
                                color: 'transparent',
                                textShadow: '0 0 0 black',
                                position: 'absolute',
                                right: 0,
                                top: 0,
                              }}
                            >
                              {role !== 'VIEWER' && (
                                <IconButton
                                  onClick={(event: any): void =>
                                    setOptionsAnchorEl(event.currentTarget)
                                  }
                                  className={classes.cellIcon}
                                  classes={{
                                    root: classes.inCellIconRoot,
                                  }}
                                  style={{
                                    float: 'right',
                                    color: 'transparent',
                                    textShadow: '0 0 0 black',
                                  }}
                                >
                                  <EditIcon
                                    style={{
                                      fontSize: '10px',
                                      color: '#1976d2',
                                    }}
                                  />
                                </IconButton>
                              )}

                              <Popover
                                open={Boolean(optionsAnchorEl)}
                                anchorEl={optionsAnchorEl}
                                onClose={(): void => setOptionsAnchorEl(null)}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <List>
                                  {editOptions.map(
                                    (eachOption: any): ReactElement => (
                                      <ListItem
                                        key={eachOption.text}
                                        button
                                        onClick={(event: any): void => {
                                          setOptionsAnchorEl(null);
                                          eachOption.onClick(event, row, col);
                                        }}
                                      >
                                        <ListItemIcon>
                                          {eachOption.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={eachOption.text}
                                          classes={{
                                            primary: classes.popoverText,
                                          }}
                                        />
                                      </ListItem>
                                    )
                                  )}
                                  {row[`${col.name}.hasExtraOption`] &&
                                    extraOption && (
                                      <ListItem
                                        button
                                        onClick={(event: any): void => {
                                          setOptionsAnchorEl(null);
                                          extraOption.onClick(event, row, col);
                                        }}
                                      >
                                        <ListItemIcon>
                                          {extraOption.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={extraOption.text}
                                          classes={{
                                            primary: classes.popoverText,
                                          }}
                                        />
                                      </ListItem>
                                    )}
                                </List>
                              </Popover>
                            </div>
                          </>
                        )}
                    </div>

                    {((col.type !== 'readOnly' &&
                      row.rowType === 'readOnly' &&
                      !row[`${col.name}.editable`]) ||
                      row[`${col.name}.notEditable`]) && (
                      <span
                        style={{
                          color:
                            // eslint-disable-next-line
                            row.enabled === false || col.disabled === true
                              ? resolve(col.name, row) &&
                                resolve(col.name, row) < 0
                                ? 'rgb(255,0,0,0.5)'
                                : ''
                              : resolve(col.name, row) &&
                                resolve(col.name, row) < 0
                              ? 'rgb(255,0,0)'
                              : '',
                        }}
                      >
                        {formatCellMethod
                          ? formatCellMethod(col, row)
                          : formatNumber(resolve(col.name, row))}
                      </span>
                    )}
                    {((col.type !== 'readOnly' &&
                      row.rowType !== 'readOnly' &&
                      !row[`${col.name}.notEditable`]) ||
                      (row.rowType === 'readOnly' &&
                        row[`${col.name}.editable`])) && (
                      <div>
                        {focusedCell === `${row.id}-${col.columnId}` && (
                          <TextField
                            className={classes.textfield}
                            inputProps={{
                              className: classes.editingTextfield,
                            }}
                            // eslint-disable-next-line
                                InputProps={{ disableUnderline: true }}
                            variant="filled"
                            value={
                              // eslint-disable-next-line
                              value === undefined
                                ? getCellValue
                                  ? getCellValue(col, row)
                                  : resolve(col.name, row)
                                : value
                            }
                            onChange={(event: any): void =>
                              setValue(event.target.value)
                            }
                            onKeyPress={(event: any): void =>
                              onKeyPress(event, row, col.name)
                            }
                            onKeyDown={onKeyDownTextField}
                            autoFocus
                            onFocus={handleFocus}
                          />
                        )}
                        {focusedCell !== `${row.id}-${col.columnId}` && (
                          <span
                            style={{
                              color:
                                // eslint-disable-next-line
                                row.enabled === false || col.disabled === true
                                  ? resolve(col.name, row) &&
                                    resolve(col.name, row) < 0
                                    ? 'rgb(255,0,0,0.5)'
                                    : ''
                                  : resolve(col.name, row) &&
                                    resolve(col.name, row) < 0
                                  ? 'rgb(255,0,0)'
                                  : '',
                            }}
                          >
                            {formatCellMethod && formatCellMethod(col, row)}
                            {// eslint-disable-next-line
                            !formatCellMethod
                              ? formatNumber(resolve(col.name, row)) === '0' &&
                                (row[`${col.name}.status`] === 'HISTORICAL' ||
                                  col.dontShowZero ||
                                  dontShowZero)
                                ? ''
                                : formatNumber(resolve(col.name, row))
                              : ''}
                          </span>
                        )}
                      </div>
                    )}
                  </TableCell>
                )
            )}
          </TableRow>
        )
    );

  return (
    <div className={classes.root1} ref={ref}>
      <div className={classes.root}>
        <div>
          <Table className={classes.table}>
            {hasColumnCheckbox && (
              <TableHead classes={{ root: classes.tableHead }}>
                <TableRow className={classes.rowContainer}>
                  {visibleColumns &&
                    visibleColumns.map(
                      (col: any, index: number): ReactElement => (
                        <TableCell
                          key={col.columnId}
                          align="left"
                          className={classnames(classes.checkMarkCells, {
                            [classes.stickyCheckMarkCells]: stickyHeader,
                          })}
                          classes={{
                            root: classes.checkboxCell,
                          }}
                          style={{
                            border: index === 0 ? 'none' : undefined,
                            textAlign: index === 0 ? 'right' : undefined,
                            paddingRight: index === 0 ? '13px' : undefined,
                            zIndex:
                              // eslint-disable-next-line
                              index === 1 && hasRowCheckbox
                                ? 410
                                : index === 0 && !hasRowCheckbox // eslint-disable-line
                                ? 410
                                : secondTableStatusComponent &&
                                  !tableStatusComponent
                                ? 400
                                : 200,
                            boxShadow:
                              index === 0 && hasRowCheckbox
                                ? 'none'
                                : undefined,
                          }}
                        >
                          {index === 0 &&
                            hasChangeTableStatusButton &&
                            hasRowCheckbox && (
                              <Tooltip title={changeStatusTableTitle}>
                                <IconButton
                                  onClick={onChangeTableStatus}
                                  classes={{
                                    root: classes.iconRoot,
                                  }}
                                  style={{
                                    padding: 4,
                                    margin: '-1px -1px 4px 0px',
                                    height: '30px',
                                  }}
                                >
                                  <img src={changeTableStatusIcon} alt="" />
                                </IconButton>
                              </Tooltip>
                            )}
                          {index === 0 && !hasRowCheckbox && (
                            <div>
                              {hasExpandColumns && (
                                <Tooltip title={expandColumnsTitle}>
                                  <IconButton
                                    onClick={onExpandColumns}
                                    classes={{
                                      root: classes.iconRoot,
                                    }}
                                    style={{
                                      padding: 4,
                                      margin: '0px -24px 8px 50px',
                                      height: '30px',
                                      zIndex: '300 !important' as any,
                                    }}
                                  >
                                    <img src={expandColumnsIcon} alt="" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          )}

                          {index === 1 && (
                            <div>
                              {hasSecondChangeTableStatusButton && (
                                <Tooltip title={secondChangeStatusTableTitle}>
                                  <IconButton
                                    style={{
                                      marginBottom: 10,
                                      marginRight: 10,
                                      padding: 4,
                                    }}
                                    onClick={onSecondChangeTableStatus}
                                  >
                                    <img
                                      src={secondChangeTableStatusIcon}
                                      alt=""
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {hasColumnCheckbox && hasColumnSelection && (
                                <Checkbox
                                  classes={{
                                    root: classes.checkboxRootTop,
                                  }}
                                  onChange={(
                                    event: any,
                                    checked: boolean
                                  ): void => {
                                    handleCheckColumns('all', checked);
                                  }}
                                  checked={
                                    !columns.find(
                                      (column: any): any =>
                                        column.canDisable &&
                                        column.disabled === true
                                    )
                                  }
                                  icon={
                                    checkboxBlankInputIcon ? (
                                      <img
                                        className={classes.checkboxSize}
                                        src={checkboxBlankInputIcon}
                                        alt=""
                                      />
                                    ) : (
                                      <CheckboxBlankIcon />
                                    )
                                  }
                                  checkedIcon={
                                    checkedAllIcon ? (
                                      <img
                                        className={classes.checkboxSize}
                                        src={checkedAllIcon}
                                        alt=""
                                      />
                                    ) : (
                                      <CheckboxIcon />
                                    )
                                  }
                                />
                              )}
                              {hasExpandColumns && hasRowCheckbox && (
                                <Tooltip title={expandColumnsTitle}>
                                  <IconButton
                                    onClick={onExpandColumns}
                                    classes={{
                                      root: classes.iconRoot,
                                    }}
                                    style={{
                                      padding: 4,
                                      margin: '0px -24px 8px 50px',
                                      height: '30px',
                                      zIndex: '200 !important' as any,
                                    }}
                                  >
                                    <img src={expandColumnsIcon} alt="" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          )}
                          {index > 1 &&
                            index < visibleColumns.length - 1 &&
                            hasColumnSelection && (
                              <Checkbox
                                classes={{
                                  root: classes.checkboxRootTop,
                                }}
                                onChange={(
                                  event: any,
                                  checked: boolean
                                ): void => {
                                  handleCheckColumns(
                                    visibleColumns[index - 1].id,
                                    checked
                                  );
                                }}
                                checked={!visibleColumns[index - 1].disabled}
                                icon={
                                  checkboxBlankInputIcon ? (
                                    <img
                                      className={classes.checkboxSize}
                                      src={checkboxBlankInputIcon}
                                      alt=""
                                    />
                                  ) : (
                                    <CheckboxBlankIcon />
                                  )
                                }
                                checkedIcon={
                                  checkedIcon ? (
                                    <img
                                      className={classes.checkboxSize}
                                      src={checkedIcon}
                                      alt=""
                                    />
                                  ) : (
                                    <CheckboxIcon />
                                  )
                                }
                              />
                            )}
                          {index === visibleColumns.length - 1 &&
                            secondTableStatusComponent &&
                            secondTableStatusComponent}
                        </TableCell>
                      )
                    )}
                  {visibleColumns &&
                    visibleColumns.length > 0 &&
                    hasColumnSelection && (
                      <TableCell
                        align="left"
                        className={classnames(classes.checkMarkCells, {
                          [classes.stickyCheckMarkCells]: stickyHeader,
                        })}
                        classes={{
                          root: classes.checkboxCell,
                        }}
                        style={{
                          boxShadow: !tableStatusComponent
                            ? '1px 0 0 0 white'
                            : 'inherit',
                          background: !tableStatusComponent
                            ? 'white'
                            : 'inherit',
                          zIndex: 300,
                        }}
                      >
                        {tableStatusComponent && tableStatusComponent}
                      </TableCell>
                    )}
                </TableRow>
              </TableHead>
            )}
            {
              // contents display because of style issue in safari
            }
            <TableHead classes={{ root: classes.tableHead }}>
              {showHeader && (
                <TableRow
                  className={classnames(
                    classes.rowContainer,
                    classes.headerRow
                  )}
                  style={{ borderTop: 'none' }}
                >
                  {hasRowCheckbox && columns && columns.length > 0 && (
                    <TableCell
                      className={classnames(
                        headerCellClass,
                        classes.headerCell,
                        classes.noneEffectCell,
                        {
                          [classes.stickyHeaderCell]: stickyHeader,
                        }
                      )}
                      style={{ boxShadow: 'none' }}
                    >
                      <Checkbox
                        classes={{
                          root: classes.checkboxRootSide,
                        }}
                        className={classes.checkbox}
                        onChange={(event: any, checked: boolean): void => {
                          handleCheckRows('all', checked);
                        }}
                        checked={
                          !rows.find(
                            (r: any): any =>
                              r.accountId && r.enabled === false && !r.hiddenRow
                          )
                        }
                        icon={
                          checkboxBlankInputIcon ? (
                            <img
                              className={classes.checkboxSize}
                              src={checkboxBlankInputIcon}
                              alt=""
                            />
                          ) : (
                            <CheckboxBlankIcon />
                          )
                        }
                        checkedIcon={
                          checkedAllIcon ? (
                            <img
                              className={classes.checkboxSize}
                              src={checkedAllIcon}
                              alt=""
                            />
                          ) : (
                            <CheckboxIcon />
                          )
                        }
                      />
                    </TableCell>
                  )}
                  {placeCellBeforeRow && (
                    <TableCell
                      classes={{
                        root: cellRootClass || classes.cellRoot,
                      }}
                      style={{ borderBottom: 'none' }}
                    />
                  )}
                  {columns &&
                    columns.map(
                      (col: any): ReactElement | any =>
                        col &&
                        !col.hidden && (
                          <TableCell
                            key={col.columnId}
                            align="left"
                            className={classnames(headerCellClass, {
                              [classes.stickyHeaderCell]: stickyHeader,
                              [classes.subjectCell]: col.type === 'header',
                              [classes.headerCell]: !col.alignLeftHeader,
                              [classes.leftHeaderCell]: col.alignLeftHeader,
                            })}
                            classes={{
                              root: cellRootClass || classes.cellRoot,
                            }}
                            style={{
                              ...col.style,
                              paddingBottom: col.searchable && 0,
                              paddingRight: col.searchable && 0,
                            }}
                            onMouseEnter={
                              onHoverColumnHeading
                                ? (event: any): void => {
                                    handleHoverColumnHeading(
                                      event,
                                      col.columnId
                                    );
                                  }
                                : (): void => {}
                            }
                            onMouseLeave={
                              onHoverColumnHeading
                                ? (): void => {
                                    handleCancelHoverColumnHeading();
                                  }
                                : (): void => {}
                            }
                          >
                            {hoveredColumn === col.columnId &&
                              col.hoveredComponent && (
                                <Popper
                                  open={hoveredColumn === col.columnId}
                                  anchorEl={hoveredElement}
                                  placement="bottom"
                                  style={{ zIndex: 5000 }}
                                >
                                  <Paper
                                    style={{
                                      padding: '5px',
                                      fontSize: '12px',
                                    }}
                                  >
                                    {col.hoveredComponent}
                                  </Paper>
                                </Popper>
                              )}
                            {col.hasDetails && (
                              <div
                                style={{
                                  position: 'absolute',
                                  right: 0,
                                  top: 5,
                                }}
                              >
                                <Tooltip title={col.detailsTitle}>
                                  <IconButton
                                    onClick={(event: any): void =>
                                      setColumnDetailsAnchorEelement(
                                        event.currentTarget
                                      )
                                    }
                                    className={classes.cellIcon}
                                    classes={{
                                      root: classes.inCellIconRoot,
                                    }}
                                  >
                                    <MoreIcon
                                      style={{
                                        fontSize: '18px',
                                        color: '#1976d2',
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Popover
                                  open={Boolean(columnDetailsAnchorEelement)}
                                  anchorEl={columnDetailsAnchorEelement}
                                  onClose={(): void =>
                                    setColumnDetailsAnchorEelement(null)
                                  }
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                >
                                  <Tooltip title="Close">
                                    <IconButton
                                      onClick={(): void =>
                                        setColumnDetailsAnchorEelement(null)
                                      }
                                      aria-label="Close"
                                      style={{
                                        marginRight: -2,
                                        marginLeft: -8,
                                      }}
                                      classes={{
                                        root: classes.removeIconRoot,
                                      }}
                                      className={classes.removeIcon}
                                    >
                                      <RemoveIcon
                                        style={{ fontSize: '12px' }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <div style={{ marginTop: 10 }}>
                                    {col.details.map(
                                      (each: any): ReactElement => (
                                        <div
                                          key={each.name}
                                          style={{ paddingLeft: 15 }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={each.selected}
                                                onChange={(
                                                  event: any,
                                                  checked: boolean
                                                ): void => {
                                                  each.onClick(
                                                    checked,
                                                    each.name
                                                  );
                                                }}
                                                icon={each.notCheckedIcon}
                                                checkedIcon={each.checkedIcon}
                                                classes={{
                                                  root: classes.checkboxroot,
                                                }}
                                                color="primary"
                                                value="status"
                                              />
                                            }
                                            label={each.name || '<Empty>'}
                                            classes={{
                                              label: classes.label,
                                            }}
                                            disabled={
                                              loading && disableOnLoading
                                            }
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Popover>
                              </div>
                            )}
                            {col.title}
                          </TableCell>
                        )
                    )}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {BodyCells()}
              {spaceBeforeTotalRow && (
                <TableRow className={classes.rowContainer} />
              )}
              {totalRow && (
                <TableRow className={classes.rowContainer}>
                  {hasRowCheckbox && <TableCell style={{ border: 'none' }} />}
                  {columns.map(
                    (col: any): ReactElement | any =>
                      col &&
                      !col.hidden && (
                        <TableCell
                          key={col.id}
                          className={classnames(
                            totalCellClass || classes.totalCell,
                            {
                              [classes.subjectCell]: col.type === 'header',
                            }
                          )}
                          align="left"
                          style={{
                            ...col.style,
                            ...totalRow[`${col.name}.style`],
                            ...totalRow[`${col.name}.disabled.style`],
                          }}
                          classes={{
                            root: classes.cellRoot,
                          }}
                        >
                          <div>
                            {col.type === 'header' && (
                              <div style={{ display: 'inline' }}>
                                {totalRow.hasDetails && totalRow.isExpanded && (
                                  <Tooltip title="Shrink">
                                    <IconButton
                                      onClick={(event: any): void =>
                                        onShrinkTotal(event)
                                      }
                                      aria-label="Shrink"
                                      style={{
                                        marginRight: -2,
                                        marginLeft: -8,
                                        textAlign: 'left',
                                      }}
                                      classes={{
                                        root: classes.iconRoot,
                                      }}
                                    >
                                      <UpIcon style={{ fontSize: '20px' }} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {totalRow.hasDetails && !totalRow.isExpanded && (
                                  <Tooltip title="Expand">
                                    <span>
                                      <IconButton
                                        onClick={(event: any): void =>
                                          onExpandTotal(event)
                                        }
                                        aria-label="Expand"
                                        style={{
                                          marginRight: -2,
                                          marginLeft: -8,
                                        }}
                                        classes={{
                                          root: classes.iconRoot,
                                        }}
                                        disabled={totalRow.details.length < 1}
                                      >
                                        <DownIcon
                                          style={{ fontSize: '20px' }}
                                        />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                )}
                              </div>
                            )}
                            <span
                              style={{
                                color:
                                  resolve(col.name, totalRow) &&
                                  resolve(col.name, totalRow) < 0
                                    ? 'red'
                                    : '',
                                marginLeft: col.type === 'header' ? 8 : 0,
                              }}
                            >
                              {// eslint-disable-next-line
                              col.type === 'readOnly' || col.type === 'header'
                                ? resolve(col.name, totalRow)
                                : formatNumber(resolve(col.name, totalRow)) ===
                                    '0' && dontShowZero
                                ? ''
                                : formatNumber(resolve(col.name, totalRow))}
                            </span>
                          </div>
                        </TableCell>
                      )
                  )}
                </TableRow>
              )}
              {hasTotalRowDetails &&
                totalRowDetails &&
                totalRowDetails.map(
                  (eachRow: any): ReactElement | any =>
                    !eachRow.hidden && (
                      <TableRow
                        key={eachRow.id}
                        className={classes.rowContainer}
                        style={eachRow.style}
                      >
                        {hasRowCheckbox && (
                          <TableCell style={{ border: 'none' }} />
                        )}
                        {columns.map(
                          (col: any): ReactElement | any =>
                            col &&
                            !col.hidden && (
                              <TableCell
                                key={col.name}
                                className={classnames(
                                  totalCellClass || classes.totalCell,
                                  {
                                    [classes.subjectCell]:
                                      col.type === 'header',
                                  }
                                )}
                                align="left"
                                style={{
                                  ...col.style,
                                  ...eachRow[`${col.name}.style`],
                                  ...eachRow[`${col.name}.disabled.style`],
                                }}
                                classes={{
                                  root: classes.cellRoot,
                                }}
                              >
                                <div>
                                  <span
                                    style={{
                                      color:
                                        resolve(col.name, eachRow) &&
                                        resolve(col.name, eachRow) < 0
                                          ? 'red'
                                          : '',
                                      marginLeft: col.type === 'header' ? 8 : 0,
                                    }}
                                  >
                                    {// eslint-disable-next-line
                                    col.type === 'readOnly' ||
                                    col.type === 'header'
                                      ? resolve(col.name, eachRow)
                                      : formatNumber(
                                          resolve(col.name, eachRow)
                                        ) === '0' && dontShowZero
                                      ? ''
                                      : formatNumber(
                                          resolve(col.name, eachRow)
                                        )}
                                  </span>
                                </div>
                              </TableCell>
                            )
                        )}
                      </TableRow>
                    )
                )}
            </TableBody>
            <TableFooter>
              <TableRow />
            </TableFooter>
          </Table>
        </div>
      </div>
    </div>
  );
};

ValuesTable.defaultProps = {
  rows: [],
  columns: [],
  hasRowCheckbox: false,
  hasColumnCheckbox: false,
  notHoveringColumnsHeading: [],
  selectOnDoubleClick: false,
  moveBetweenCells: false,
  onHoverColumnHeading: false,
  cancelHoverColumnHeading: undefined,
  selectCellonClick: false,
  showEditOnHover: false,
  editOptions: undefined,
  cellRootClass: undefined,
  checkboxBlankInputIcon: undefined,
  checkedAllIcon: undefined,
  spaceBeforeTotalRow: false,
  totalRow: undefined,
  totalCellClass: undefined,
  role: undefined,
  hasChangeTableStatusButton: false,
  onChangeTableStatus: undefined,
  changeTableStatusIcon: undefined,
  changeStatusTableTitle: undefined,
  hasSecondChangeTableStatusButton: false,
  onSecondChangeTableStatus: undefined,
  secondChangeTableStatusIcon: undefined,
  secondChangeStatusTableTitle: undefined,
  onShrink: undefined,
  onExpand: undefined,
  goToDetail: undefined,
  showPopperForHeaderCell: false,
  cellDetailsIcon: undefined,
  cellDetailIconHovered: undefined,
  onSubmit: undefined,
  checkedIcon: undefined,
  extraOption: undefined,
  handleCheckColumns: undefined,
  handleCheckRows: undefined,
  focusOnTable: true,
  hasExpandColumns: false,
  onExpandColumns: undefined,
  expandColumnsIcon: undefined,
  expandColumnsTitle: undefined,
  numberPrecision: 0,
  stickyHeader: false,
  headerCellClass: undefined,
  totalRowDetails: undefined,
  hasTotalRowDetails: false,
  onShrinkTotal: undefined,
  onExpandTotal: undefined,
  loading: false,
  disableOnLoading: false,
  getCellValue: undefined,
  formatCellMethod: undefined,
  tableStatusComponent: undefined,
  secondTableStatusComponent: undefined,
  showHeader: true,
  placeCellBeforeRow: false,
  selectOnEnter: false,
  hasColumnSelection: false,
  dontShowZero: false,
};

export default ValuesTable;
