import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  ReactElement,
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Scatter, Line } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import DataTable from '../ClientDataTable';
import Dropdown from '../../../../components/Dropdown';
import DatePicker from '../../../../components/DatePicker';
import Dates from '../../../../constants/Date';
import styles from './styles';
import {
  ChartingType,
  ChartingRowType,
  BenchmarkType,
} from '../../../../Types';

const colors = [
  '#bd7db9',
  '#6acff5',
  '#add660',
  '#ffd842',
  '#f2665a',
  'rgb(0,0,0,0)',
];

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  chartingData?: ChartingType;
  charting?: (obj: {
    id: number;
    startmonth: number;
    endmonth: number;
    startyear: number;
    endyear: number;
    riskFree: number;
    month: number;
    year: number;
    enabledRows: Array<number>;
    disabledColumns: Array<number>;
    benchmarkIds: Array<number>;
  }) => Promise<ChartingType>;
  month: number;
  year: number;
  readOnly?: boolean;
  getNode?: (ref: any, name: string, id: number) => void;
  parentReference: any;
  cardId?: number;
  selectedRows?: Array<number>;
  selectedColumns?: Array<number>;
  selectedRiskFree?: number;
  selectedStartYear?: number;
  selectedStartMonth?: number;
  selectedEndYear?: number;
  selectedEndMonth?: number;
  clientId: number;
  selectedDate?: any;
  setMonitorShowingRows?: (id: number, rows: Array<number | string>) => void;
  setChartingRiskFree?: (id: number, value: number) => void;
  setChartingStartYear?: (id: number, value: number) => void;
  setChartingStartMonth?: (id: number, value: number) => void;
  setChartingEndYear?: (id: number, value: number) => void;
  setChartingEndMonth?: (id: number, value: number) => void;
  selectedShowingRows?: Array<string | number>;
  showNotification: () => void;
  getList?: () => Promise<{ content: Array<BenchmarkType> }>;
  getChartingRow?: (obj: {
    startmonth: number;
    endmonth: number;
    startyear: number;
    endyear: number;
    riskFree: number;
    month: number;
    year: number;
    benchmarkId: number;
  }) => Promise<ChartingRowType>;
  rootClass?: any;
  root1Class?: any;
  selectionChanged?: boolean;
}

const Charting = (props: Props): ReactElement => {
  const {
    month,
    year,
    clientId,
    selectedRiskFree,
    selectedStartMonth,
    selectedStartYear,
    selectedEndMonth,
    selectedEndYear,
    cardId,
    setChartingEndMonth,
    setChartingEndYear,
    setChartingStartYear,
    selectedShowingRows,
    getNode,
    readOnly,
    setMonitorShowingRows,
    getChartingRow,
    showNotification,
    chartingData,
    charting,
    selectedRows,
    selectedColumns,
    getList,
    setChartingStartMonth,
    setChartingRiskFree,
    parentReference,
    root1Class,
    rootClass,
    selectionChanged,
  } = props;

  const [tableRows, setTableRows] = useState<any>([]);
  const [finalTableRows, setFinalTableRows] = useState<any>([]);
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [value, setValue] = useState<any>(undefined);
  const [showError, setShowError] = useState<boolean>(false);
  const [benchmarksArray, setBenchmarksArray] = useState<Array<any>>([]);
  const [hasStrategic, setHasStrategic] = useState<boolean>(false);
  const [hasTactical, setHasTactical] = useState<boolean>(false);
  const [profileRows, setProfileRows] = useState<Array<any>>([]);
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {});

  const setChartingData = (
    newChartingData: ChartingType,
    newBenchmarksArray: Array<any>
  ): void => {
    if (newChartingData) {
      const { rows, columns } = newChartingData.table;
      const strategicRow = rows.find(each => each.title === 'Strategic');
      const tacticalRow = rows.find(each => each.title === 'Tactical');
      const recievedProfileRows = rows.filter(
        (eachRow: any) =>
          eachRow.title !== 'Strategic' &&
          eachRow.title !== 'Tactical' &&
          eachRow.title !== 'Portfolio' &&
          !newBenchmarksArray.find(
            (eachBenchmark: any) => eachBenchmark?.name === eachRow.title
          )
      );
      const newColumns =
        (columns &&
          Object.keys(columns).map((key: string): any => ({
            title:
              // eslint-disable-next-line no-nested-ternary
              key.indexOf('Std') > -1 &&
              key.replace('Charting_Std', '').length > 2 ? (
                <div>
                  <div>St.Dev.</div>
                  <div>{columns[key].replace('St.Dev. ', '')}</div>
                </div>
              ) : key.indexOf('Return') > -1 ? (
                <div>
                  <div>Return</div>
                  <div>{columns[key].replace('Return ', '')}</div>
                </div>
              ) : (
                columns[key]
              ),
            name: key,
          }))) ||
        [];
      newColumns.unshift({ title: 'Portfolio Summary', name: 'name' });
      let newRows: Array<any> = [];
      if (selectedShowingRows && selectedShowingRows.length > 0) {
        newRows = selectedShowingRows.map((each: any, index: number): any => {
          if (typeof each === 'string' && each.indexOf('Portfolio') > -1) {
            const foundRow = rows.find(
              (eachRow: ChartingRowType): any => eachRow.title === 'Portfolio'
            );
            const object: { [key: string]: any } = {
              title: foundRow?.title,
              color: colors[index],
              name: `${foundRow?.title}-${index}`,
              value: foundRow?.title || '',
              id: `${foundRow?.title}-${index}`,
              calculations: foundRow?.calculations,
              evolution: foundRow?.evolution,
            };

            if (foundRow)
              foundRow.calculations.forEach((newValue: any): void => {
                object[`${newValue.type}`] = (newValue && newValue.value) || 0;
                object[`${newValue.type}-label`] =
                  (newValue && newValue.calculation) || '';
                if (newValue.partial)
                  object[`${newValue.type}-style`] = {
                    backgroundColor: '#FFCCCC',
                    backgroundClip: 'content-box',
                  };
              });
            return object;
          }
          if (typeof each === 'string' && each.indexOf('Strategic') > -1) {
            const foundRow = rows.find(
              (eachRow: ChartingRowType): any => eachRow.title === 'Strategic'
            );
            const object: { [key: string]: any } = {
              title: foundRow?.title,
              color: colors[index],
              name: `${foundRow?.title}-${index}`,
              value: foundRow?.title || '',
              id: `${foundRow?.title}-${index}`,
              calculations: foundRow?.calculations,
              evolution: foundRow?.evolution,
            };

            if (foundRow)
              foundRow.calculations.forEach((newValue: any): void => {
                object[`${newValue.type}`] = (newValue && newValue.value) || 0;
                object[`${newValue.type}-label`] =
                  (newValue && newValue.calculation) || '';
                if (newValue.partial)
                  object[`${newValue.type}-style`] = {
                    backgroundColor: '#FFCCCC',
                    backgroundClip: 'content-box',
                  };
              });
            return object;
          }
          if (typeof each === 'string' && each.indexOf('Tactical') > -1) {
            const foundRow = rows.find(
              (eachRow: ChartingRowType): any => eachRow.title === 'Tactical'
            );
            const object: { [key: string]: any } = {
              title: foundRow?.title,
              color: colors[index],
              name: `${foundRow?.title}-${index}`,
              value: foundRow?.title || '',
              id: `${foundRow?.title}-${index}`,
              calculations: foundRow?.calculations,
              evolution: foundRow?.evolution,
            };

            if (foundRow)
              foundRow.calculations.forEach((newValue: any): void => {
                object[`${newValue.type}`] = (newValue && newValue.value) || 0;
                object[`${newValue.type}-label`] =
                  (newValue && newValue.calculation) || '';
                if (newValue.partial)
                  object[`${newValue.type}-style`] = {
                    backgroundColor: '#FFCCCC',
                    backgroundClip: 'content-box',
                  };
              });
            return object;
          }
          if (
            typeof each === 'string' &&
            recievedProfileRows.find(
              (eachProfileRow: any) => each.indexOf(eachProfileRow.title) > -1
            )
          ) {
            const foundRow = recievedProfileRows.find(
              (eachProfileRow: any) => each.indexOf(eachProfileRow.title) > -1
            );
            const object: { [key: string]: any } = {
              title: foundRow?.title,
              color: colors[index],
              name: `${foundRow?.title}-${index}`,
              value: foundRow?.title || '',
              id: `${foundRow?.title}-${index}`,
              calculations: foundRow?.calculations,
              evolution: foundRow?.evolution,
            };

            if (foundRow)
              foundRow.calculations.forEach((newValue: any): void => {
                object[`${newValue.type}`] = (newValue && newValue.value) || 0;
                object[`${newValue.type}-label`] =
                  (newValue && newValue.calculation) || '';
                if (newValue.partial)
                  object[`${newValue.type}-style`] = {
                    backgroundColor: '#FFCCCC',
                    backgroundClip: 'content-box',
                  };
              });
            return object;
          }
          if (typeof each === 'string' && each.indexOf('None') > -1) {
            const object = {
              title: 'None',
              color: colors[index],
              name: `None-${index}`,
              value: 'None',
              id: `None-${index}`,
              calculations: [],
              evolution: [],
            };
            return object;
          }
          if (typeof each === 'string') {
            const object = {
              title: 'None',
              color: colors[index],
              name: `None-${index}`,
              value: 'None',
              id: `None-${index}`,
              calculations: [],
              evolution: [],
            };
            return object;
          }
          const foundBenchmark = newBenchmarksArray.find(
            (eachBenchmark: any): any => eachBenchmark.id === each
          );
          const foundRow = rows.find(
            (eachRow: ChartingRowType): any =>
              eachRow.title === foundBenchmark.name
          );
          const object: { [key: string]: any } = {
            title: foundRow?.title,
            color: colors[index],
            name: `${foundRow?.title}-${index}`,
            value: foundRow?.title || '',
            id: `${foundRow?.title}-${index}`,
            calculations: foundRow?.calculations,
            evolution: foundRow?.evolution,
          };
          if (foundRow)
            foundRow.calculations.forEach((newValue: any): void => {
              object[`${newValue.type}`] = (newValue && newValue.value) || 0;
              object[`${newValue.type}-label`] =
                (newValue && newValue.calculation) || '';
              if (newValue.partial)
                object[`${newValue.type}-style`] = {
                  backgroundColor: '#FFCCCC',
                  backgroundClip: 'content-box',
                };
            });
          return object;
        });
      } else {
        const filteredRows = rows.filter(
          each =>
            each.title === 'Portfolio' ||
            each.title === 'Strategic' ||
            each.title === 'Tactical'
        );
        const benchmarkRows = rows.filter(
          each =>
            each.title !== 'Portfolio' &&
            each.title !== 'Strategic' &&
            each.title !== 'Tactical' &&
            !recievedProfileRows.find(
              (eachProfile: any) => eachProfile.title === each.title
            )
        );
        const finalRows = filteredRows.concat(
          benchmarkRows.slice(0, 5 - filteredRows.length)
        );
        newRows = finalRows.map((each: ChartingRowType, index: number): any => {
          const object: { [key: string]: any } = {
            title: each.title,
            color: colors[index],
            name: `${each.title}-${index}`,
            value: each.title || '',
            id: `${each.title}-${index}`,
            calculations: each.calculations,
            evolution: each.evolution,
          };
          each.calculations.forEach((newValue: any): void => {
            object[`${newValue.type}`] = (newValue && newValue.value) || 0;
            object[`${newValue.type}-label`] =
              (newValue && newValue.calculation) || '';
            if (newValue.partial)
              object[`${newValue.type}-style`] = {
                backgroundColor: '#FFCCCC',
                backgroundClip: 'content-box',
              };
          });
          return object;
        });
      }
      setTableColumns(newColumns);
      setHasStrategic(!!strategicRow);
      setHasTactical(!!tacticalRow);
      setProfileRows(recievedProfileRows);
      setTimeout(() => setTableRows(newRows), 200);
      if (readOnly) {
        setTimeout(() => {
          if (getNode && cardId) getNode(containerRef, 'CHARTING', cardId);
        }, 1000);
      }
    }
  };

  const handleChange = (
    event: any,
    rowId: string,
    newTableRows: Array<any>,
    newBenchmarksArray: Array<any>,
    newProfileRows: Array<any>
  ): void => {
    const newValue = event.target.value;
    if (newValue === 'None') {
      const newRows = newTableRows.map((each: any, index: number): any => {
        if (each.id === rowId) {
          const object = {
            title: 'None',
            color: colors[index],
            name: `None-${index}`,
            value: 'None',
            id: `None-${index}`,
            calculations: [],
            evolution: [],
          };
          return object;
        }
        return each;
      });
      setTableRows(newRows);
      const newSelectedShowingRows = newRows.map((each: any): any => {
        if (
          each.id.indexOf('None') > -1 ||
          each.id.indexOf('Tactical') > -1 ||
          each.id.indexOf('Strategic') > -1 ||
          each.id.indexOf('Portfolio') > -1 ||
          newProfileRows.find(
            (eachProfileRow: any) => each.id.indexOf(eachProfileRow.title) > -1
          )
        ) {
          return each.id;
        }
        const { id } = newBenchmarksArray.find(
          (eachBenchmark: any): any => eachBenchmark.name === each.title
        );
        return id;
      });
      if (setMonitorShowingRows)
        setMonitorShowingRows(clientId, newSelectedShowingRows);
    } else if (newValue === 'Portfolio') {
      const rowData =
        chartingData &&
        chartingData.table &&
        chartingData.table.rows &&
        chartingData.table.rows.find(
          (each: any): any => each.title === 'Portfolio'
        );
      const newRows = newTableRows.map((each: any, index: number): any => {
        if (each.id === rowId && rowData) {
          const object: { [key: string]: any } = {
            title: rowData.title,
            color: colors[index],
            name: `${rowData.title}-${index}`,
            value: rowData.title || '',
            id: `${rowData.title}-${index}`,
            calculations: rowData.calculations,
            evolution: rowData.evolution,
          };
          rowData.calculations.forEach((eachValue: any): void => {
            object[`${eachValue.type}`] = eachValue.value;
            object[`${eachValue.type}-label`] = eachValue.calculation;
            if (eachValue.partial)
              object[`${eachValue.type}-style`] = {
                backgroundColor: '#FFCCCC',
                backgroundClip: 'content-box',
              };
          });
          return object;
        }
        return each;
      });
      setTableRows(newRows);
      const newSelectedShowingRows = newRows.map((each: any): any => {
        if (
          each.id.indexOf('None') > -1 ||
          each.id.indexOf('Tactical') > -1 ||
          each.id.indexOf('Strategic') > -1 ||
          each.id.indexOf('Portfolio') > -1 ||
          newProfileRows.find(
            (eachProfileRow: any) => each.id.indexOf(eachProfileRow.title) > -1
          )
        ) {
          return each.id;
        }
        const { id } = newBenchmarksArray.find(
          (eachBenchmark: any): any => eachBenchmark.name === each.title
        );
        return id;
      });
      if (setMonitorShowingRows)
        setMonitorShowingRows(clientId, newSelectedShowingRows);
    } else if (newValue === 'Strategic') {
      const rowData =
        chartingData &&
        chartingData.table &&
        chartingData.table.rows &&
        chartingData.table.rows.find(
          (each: any): any => each.title === 'Strategic'
        );
      const newRows = newTableRows.map((each: any, index: number): any => {
        if (each.id === rowId && rowData) {
          const object: { [key: string]: any } = {
            title: rowData.title,
            color: colors[index],
            name: `${rowData.title}-${index}`,
            value: rowData.title || '',
            id: `${rowData.title}-${index}`,
            calculations: rowData.calculations,
            evolution: rowData.evolution,
          };
          rowData.calculations.forEach((eachValue: any): void => {
            object[`${eachValue.type}`] = eachValue.value;
            object[`${eachValue.type}-label`] = eachValue.calculation;
            if (eachValue.partial)
              object[`${eachValue.type}-style`] = {
                backgroundColor: '#FFCCCC',
                backgroundClip: 'content-box',
              };
          });
          return object;
        }
        return each;
      });
      setTableRows(newRows);
      const newSelectedShowingRows = newRows.map((each: any): any => {
        if (
          each.id.indexOf('None') > -1 ||
          each.id.indexOf('Tactical') > -1 ||
          each.id.indexOf('Strategic') > -1 ||
          each.id.indexOf('Portfolio') > -1 ||
          newProfileRows.find(
            (eachProfileRow: any) => each.id.indexOf(eachProfileRow.title) > -1
          )
        ) {
          return each.id;
        }
        const { id } = newBenchmarksArray.find(
          (eachBenchmark: any): any => eachBenchmark.name === each.title
        );
        return id;
      });
      if (setMonitorShowingRows)
        setMonitorShowingRows(clientId, newSelectedShowingRows);
    } else if (newValue === 'Tactical') {
      const rowData =
        chartingData &&
        chartingData.table &&
        chartingData.table.rows &&
        chartingData.table.rows.find(
          (each: any): any => each.title === 'Tactical'
        );
      const newRows = newTableRows.map((each: any, index: number): any => {
        if (each.id === rowId && rowData) {
          const object: { [key: string]: any } = {
            title: rowData.title,
            color: colors[index],
            name: `${rowData.title}-${index}`,
            value: rowData.title || '',
            id: `${rowData.title}-${index}`,
            calculations: rowData.calculations,
            evolution: rowData.evolution,
          };
          rowData.calculations.forEach((eachValue: any): void => {
            object[`${eachValue.type}`] = eachValue.value;
            object[`${eachValue.type}-label`] = eachValue.calculation;
            if (eachValue.partial)
              object[`${eachValue.type}-style`] = {
                backgroundColor: '#FFCCCC',
                backgroundClip: 'content-box',
              };
          });
          return object;
        }
        return each;
      });
      setTableRows(newRows);
      const newSelectedShowingRows = newRows.map((each: any): any => {
        if (
          each.id.indexOf('None') > -1 ||
          each.id.indexOf('Tactical') > -1 ||
          each.id.indexOf('Strategic') > -1 ||
          each.id.indexOf('Portfolio') > -1 ||
          newProfileRows.find(
            (eachProfileRow: any) => each.id.indexOf(eachProfileRow.title) > -1
          )
        ) {
          return each.id;
        }
        const { id } = newBenchmarksArray.find(
          (eachBenchmark: any): any => eachBenchmark.name === each.title
        );
        return id;
      });
      if (setMonitorShowingRows)
        setMonitorShowingRows(clientId, newSelectedShowingRows);
    } else if (
      newProfileRows.find(
        (eachProfileRow: any) => eachProfileRow.title === newValue
      )
    ) {
      const rowData =
        chartingData &&
        chartingData.table &&
        chartingData.table.rows &&
        chartingData.table.rows.find(
          (each: any): any => each.title === newValue
        );
      const newRows = newTableRows.map((each: any, index: number): any => {
        if (each.id === rowId && rowData) {
          const object: { [key: string]: any } = {
            title: rowData.title,
            color: colors[index],
            name: `${rowData.title}-${index}`,
            value: rowData.title || '',
            id: `${rowData.title}-${index}`,
            calculations: rowData.calculations,
            evolution: rowData.evolution,
          };
          rowData.calculations.forEach((eachValue: any): void => {
            object[`${eachValue.type}`] = eachValue.value;
            object[`${eachValue.type}-label`] = eachValue.calculation;
            if (eachValue.partial)
              object[`${eachValue.type}-style`] = {
                backgroundColor: '#FFCCCC',
                backgroundClip: 'content-box',
              };
          });
          return object;
        }
        return each;
      });
      setTableRows(newRows);
      const newSelectedShowingRows = newRows.map((each: any): any => {
        if (
          each.id.indexOf('None') > -1 ||
          each.id.indexOf('Tactical') > -1 ||
          each.id.indexOf('Strategic') > -1 ||
          each.id.indexOf('Portfolio') > -1 ||
          newProfileRows.find(
            (eachProfileRow: any) => each.id.indexOf(eachProfileRow.title) > -1
          )
        ) {
          return each.id;
        }
        const { id } = newBenchmarksArray.find(
          (eachBenchmark: any): any => eachBenchmark.name === each.title
        );
        return id;
      });
      if (setMonitorShowingRows)
        setMonitorShowingRows(clientId, newSelectedShowingRows);
    } else {
      const benchmark = newBenchmarksArray.find(
        (each: any): any => each.name === newValue
      );
      if (
        getChartingRow &&
        selectedStartMonth &&
        selectedEndMonth &&
        selectedStartYear &&
        selectedEndYear &&
        selectedRiskFree
      )
        getChartingRow({
          startmonth: selectedStartMonth,
          endmonth: selectedEndMonth,
          startyear: selectedStartYear,
          endyear: selectedEndYear,
          riskFree: selectedRiskFree,
          month,
          year,
          benchmarkId: benchmark?.id,
        })
          .then((rowData: ChartingRowType): void => {
            const newRows = newTableRows.map(
              (each: any, index: number): any => {
                if (each.id === rowId) {
                  const object: { [key: string]: any } = {
                    title: rowData.title,
                    color: colors[index],
                    name: `${rowData.title}-${index}`,
                    value: rowData.title || '',
                    id: `${rowData.title}-${index}`,
                    calculations: rowData.calculations,
                    evolution: rowData.evolution,
                  };
                  rowData.calculations.forEach((eachValue: any): void => {
                    object[`${eachValue.type}`] = eachValue.value;
                    object[`${eachValue.type}-label`] = eachValue.calculation;
                    if (eachValue.partial)
                      object[`${eachValue.type}-style`] = {
                        backgroundColor: '#FFCCCC',
                        backgroundClip: 'content-box',
                      };
                  });
                  return object;
                }
                return each;
              }
            );
            setTableRows(newRows);
            const newSelectedShowingRows = newRows.map((each: any): any => {
              if (
                each.id.indexOf('None') > -1 ||
                each.id.indexOf('Tactical') > -1 ||
                each.id.indexOf('Strategic') > -1 ||
                each.id.indexOf('Portfolio') > -1 ||
                newProfileRows.find(
                  (eachProfileRow: any) =>
                    each.id.indexOf(eachProfileRow.title) > -1
                )
              ) {
                return each.id;
              }
              const { id } = newBenchmarksArray.find(
                (eachBenchmark: any): any => eachBenchmark.name === each.title
              );
              return id;
            });
            if (setMonitorShowingRows)
              setMonitorShowingRows(clientId, newSelectedShowingRows);
          })
          .catch(() => showNotification && showNotification());
    }
  };

  const createSelectCell = (
    name: string,
    selectValue: string,
    newBenchmarksArray: Array<any>,
    hasStrategicRow: boolean,
    hasTacticalRow: boolean,
    newTableRows: Array<any>,
    newProfileRows: Array<any>
  ): ReactElement => {
    return (
      <TextField
        id="outlined-select-monitor-title"
        select
        value={selectValue}
        onChange={(event: any): any =>
          handleChange(
            event,
            name,
            newTableRows,
            newBenchmarksArray,
            newProfileRows
          )
        }
        margin="none"
        variant="outlined"
        InputProps={{
          classes: {
            root: classes.asb,
            input: classes.input,
            notchedOutline: classes.borderClass,
          },
        }}
        classes={{
          root: classes.test,
        }}
      >
        <MenuItem value="None" style={{ fontSize: '12px' }}>
          None
        </MenuItem>
        <MenuItem value="Portfolio" style={{ fontSize: '12px' }}>
          Portfolio
        </MenuItem>
        {hasStrategicRow && (
          <MenuItem value="Strategic" style={{ fontSize: '12px' }}>
            Strategic
          </MenuItem>
        )}
        {hasTacticalRow && (
          <MenuItem value="Tactical" style={{ fontSize: '12px' }}>
            Tactical
          </MenuItem>
        )}
        <Divider />
        {newProfileRows &&
          newProfileRows.length > 0 &&
          newProfileRows.map((eachProfileRow: any, idx: number) => (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${eachProfileRow.title}-${idx}`}
              value={eachProfileRow.title}
              style={{ fontSize: '12px' }}
            >
              {eachProfileRow.title}
            </MenuItem>
          ))}
        <Divider />
        {newBenchmarksArray.map(
          (option: any, idx: number): ReactElement => (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${option.name}-${idx}`}
              value={option.name}
              style={{ fontSize: '12px' }}
            >
              {option.name}
            </MenuItem>
          )
        )}
      </TextField>
    );
  };

  const setSelectToTableRows = (
    newTableRows: Array<any>,
    newBenchmarksArray: Array<any>,
    hasStrategicRow: boolean,
    hasTacticalRow: boolean,
    newProfileRows: Array<any>
  ): void => {
    const newFinalRows = tableRows.map((each: any): any => ({
      ...each,
      name: createSelectCell(
        each.name,
        each.value,
        newBenchmarksArray,
        hasStrategicRow,
        hasTacticalRow,
        newTableRows,
        newProfileRows
      ),
    }));
    setFinalTableRows(newFinalRows);
  };

  useEffect(() => {
    setSelectToTableRows(
      tableRows,
      benchmarksArray,
      hasStrategic,
      hasTactical,
      profileRows
    );
  }, [tableRows]);

  const getChartingData = (): void => {
    if (getList)
      getList().then((benchmarks: { content: Array<BenchmarkType> }): void => {
        const newBenchmarksArray =
          benchmarks &&
          benchmarks.content &&
          benchmarks.content
            .filter((each: BenchmarkType) => !each.hidden)
            .map((each: BenchmarkType): any => ({
              id: each.id,
              name: each.name,
            }));
        if (selectedShowingRows && selectedShowingRows.length > 0) {
          const filteredShowingRows = selectedShowingRows.filter(
            (each: string | number): any => typeof each !== 'string'
          );
          if (
            charting &&
            selectedStartMonth &&
            selectedEndMonth &&
            selectedEndYear &&
            selectedRiskFree &&
            selectedStartYear &&
            selectedRows &&
            selectedColumns
          )
            charting({
              id: clientId,
              startmonth: selectedStartMonth,
              endmonth: selectedEndMonth,
              startyear: selectedStartYear,
              endyear: selectedEndYear,
              riskFree: selectedRiskFree,
              month,
              year,
              enabledRows: selectedRows,
              disabledColumns: selectedColumns,
              benchmarkIds: filteredShowingRows as Array<number>,
            })
              .then((data: ChartingType): void => {
                setChartingData(data, newBenchmarksArray);
                setBenchmarksArray(newBenchmarksArray);
              })
              .catch(() => showNotification && showNotification());
        } else if (
          charting &&
          selectedStartMonth &&
          selectedEndMonth &&
          selectedStartYear &&
          selectedEndYear &&
          selectedRiskFree &&
          selectedRows &&
          selectedColumns
        )
          charting({
            id: clientId,
            startmonth: selectedStartMonth,
            endmonth: selectedEndMonth,
            startyear: selectedStartYear,
            endyear: selectedEndYear,
            riskFree: selectedRiskFree,
            month,
            year,
            enabledRows: selectedRows,
            disabledColumns: selectedColumns,
            benchmarkIds: newBenchmarksArray
              .slice(0, 4)
              .map((each: any): any => each.id),
          })
            .then((data: ChartingType): any => {
              setChartingData(data, newBenchmarksArray);
              setBenchmarksArray(newBenchmarksArray);
            })
            .catch(() => showNotification && showNotification());
      });
  };

  useEffect(() => {
    getChartingData();
  }, [
    selectedColumns,
    selectedRows,
    selectedEndMonth,
    selectedEndYear,
    selectedStartMonth,
    selectedStartYear,
    selectedRiskFree,
    cardId,
    clientId,
    selectionChanged,
  ]);

  useEffect(() => {
    const yearObj = Dates.years.find(
      (each: any): any => each.valueNumber === year
    );
    const monthObj = Dates.months.find(
      (each: any): any => each.valueNumber === month
    );
    const startYearObj = Dates.years.find(
      (each: any): any => each.valueNumber === year - 4
    );
    if (setChartingEndMonth && monthObj)
      setChartingEndMonth(clientId, monthObj.valueNumber);
    if (setChartingEndYear && yearObj)
      setChartingEndYear(clientId, yearObj.valueNumber);
    if (!selectedStartYear && setChartingStartYear && startYearObj)
      setChartingStartYear(clientId, startYearObj.valueNumber);
  }, []);

  const selectStartMonth = (newValue: number): void => {
    const newmonth = Dates.months.find((m: any): any => m.value === newValue);
    if (setChartingStartMonth && newmonth)
      setChartingStartMonth(clientId, newmonth.valueNumber);
  };

  const selectStartYear = (newValue: number): void => {
    const newyear = Dates.years.find((y: any): any => y.value === newValue);
    if (setChartingStartYear && newyear)
      setChartingStartYear(clientId, newyear.valueNumber);
  };

  const selectEndMonth = (newValue: number): void => {
    const newmonth = Dates.months.find(
      (m: any): any => m.valueNumber === newValue
    );
    if (setChartingEndMonth && newmonth)
      setChartingEndMonth(clientId, newmonth.valueNumber);
  };

  const selectEndYear = (newValue: number): void => {
    const newyear = Dates.years.find(
      (y: any): any => y.valueNumber === newValue
    );
    if (setChartingEndYear && newyear)
      setChartingEndYear(clientId, newyear.valueNumber);
  };

  useEffect(() => {
    const yearObj = Dates.years.find(
      (each: any): any => each.valueNumber === year
    );

    if (yearObj) selectEndYear(yearObj.valueNumber);
  }, [year]);

  useEffect(() => {
    const monthObj = Dates.months.find(
      (each: any): any => each.valueNumber === month
    );

    if (monthObj) selectEndMonth(monthObj.valueNumber);
  }, [month]);

  const onBlur = (): void => {
    setValue(undefined);
  };

  const getDate = (dateInput: number): string => {
    if (dateInput) {
      const date = new Date(dateInput);
      const newYear = date.getFullYear();
      const monthObj = Dates.months.find(
        (each: any): any => each.valueNumber - 1 === date.getMonth()
      );
      return `${(monthObj && monthObj.label) || ''}-${newYear}`;
    }
    return '';
  };

  const onKeyPress = (event: any): void => {
    if (event.key === 'Enter') {
      if (setChartingRiskFree) setChartingRiskFree(clientId, Number(value));
      if (inputRef && inputRef.current) inputRef.current.blur();
    }
  };

  const changeRiskFree = (event: any): void => {
    setValue(event.target.value);
  };

  const startYearObj = Dates.years.find(each => each.valueNumber === year - 4);

  const newSelectedStartYear =
    selectedStartYear || (startYearObj && startYearObj.valueNumber);

  const scatterX1 = tableRows.filter(Boolean).map((row: any): any => {
    if (row.calculations.find((each: any): any => each.type === 'Charting_Std'))
      return {
        title: row.title,
        x: row.calculations.find(
          (each: any): any => each.type === 'Charting_Std'
        ).value,
      };
    return { title: row.title, x: 0 };
  });
  const scatterY1 = tableRows.filter(Boolean).map((row: any): number => {
    if (
      row.calculations.find((each: any): any => each.type === 'Charting_YearP')
    )
      return row.calculations.find(
        (each: any): any => each.type === 'Charting_YearP'
      ).value;
    return 0;
  });
  const scatterData1 = scatterX1.map((each: any, idx: number): any => ({
    ...each,
    y: scatterY1[idx],
  }));
  const scatterX2 = tableRows.filter(Boolean).map((row: any): any => ({
    title: row.title,
    xData:
      (row &&
        row.evolution &&
        Array.isArray(row.evolution) &&
        row.evolution.map((each: any): any => ({
          label: getDate(each.date),
          date: each.date,
        }))) ||
      [],
  }));
  const scatterY2 = tableRows.map(
    (row: any): any =>
      (row &&
        row.evolution &&
        Array.isArray(row.evolution) &&
        row.evolution.map((each: any): number => each.value)) ||
      []
  );
  const scatterData2 = scatterX2.map((each: any, index: number): any => ({
    title: each.title,
    data: each.xData.map((eachXData: any, idx: number): any => ({
      label: eachXData.label,
      date: eachXData.date,
      value: scatterY2[index][idx] || null,
    })),
  }));

  const startmonthValue = Dates.months.find(
    (m: any): any => m.valueNumber === selectedStartMonth
  );
  const startyearValue = Dates.years.find(
    (y: any): any => y.valueNumber === newSelectedStartYear
  );
  /* if (
      selectedStartYear === selectedEndYear &&
      selectedStartMonth > selectedEndMonth
    ) {
      selectMonth(
        Dates.months.find(each => each.valueNumber === selectedStartMonth).value
      );
    } */

  const scatterData = tableRows
    .map((each: any): any => ({
      ...scatterData1.find((data: any): any => data.title === each.title),
    }))
    .map((each: any): any => ({ x: each.x, y: each.y }));
  scatterData.push({ x: 20, y: 20 });

  const labels: any[] = [];
  const dates: any[] = [];
  tableRows
    .map((each: any): any => ({
      ...scatterData2.find((data: any): any => data.title === each.title),
    }))
    .map(
      (each: any): any =>
        each &&
        each.data &&
        Array.isArray(each.data) &&
        each.data.map((eachData: any): string => eachData.label)
    )
    .forEach((each: any): void => {
      if (each) labels.push(...each);
    });
  tableRows
    .map((each: any): any => ({
      ...scatterData2.find((data: any): any => data.title === each.title),
    }))
    .map(
      (each: any): any =>
        each &&
        each.data &&
        each.data.map((eachData: any): any => ({
          date: eachData.date,
          label: eachData.label,
        }))
    )
    .forEach((each: any): void => {
      if (each) dates.push(...each);
    });
  const uniqeLabels = [...new Set(labels)];
  const sortedLabels = uniqeLabels
    .map(
      (each: any): number =>
        dates.find((eachDate: any): any => eachDate.label === each).date
    )
    .sort()
    .map((each: number): string => getDate(each));

  const lineData = tableRows
    .map((each: any): any => ({
      ...scatterData2.find((data: any): any => data.title === each.title),
      color: each.color,
    }))
    .map(
      (each: any): any =>
        each && {
          label: each.title,
          fill: false,
          borderColor: each.color,
          spanGaps: true,
          data: sortedLabels.map((eachLabel: string): any => {
            const labelValue =
              each &&
              each.data &&
              each.data.find(
                (eachData: any): any => eachData.label === eachLabel
              );
            if (labelValue) {
              return labelValue.value;
            }
            return null;
          }),
        }
    );

  return (
    <div className={classNames(classes.root1, root1Class)}>
      <div
        className={classNames(classes.root, rootClass)}
        ref={readOnly ? containerRef : parentReference}
      >
        <div className={classes.topContainer}>
          <div className={classes.leftContainer}>
            <div className={classes.minorContainer}>
              <Typography
                className={classNames(classes.minorTitle, classes.first)}
              >
                {' '}
                From:{' '}
              </Typography>
              <Dropdown
                onSelect={(newValue: number): void => {
                  setShowError(false);
                  selectStartMonth(newValue);
                }}
                suggestions={
                  newSelectedStartYear === selectedEndYear
                    ? Dates.months.filter(
                        each =>
                          selectedEndMonth &&
                          each.valueNumber <= selectedEndMonth
                      )
                    : Dates.months
                }
                textRootClass={classes.textRootClass}
                inputClass={classes.dropInputClass}
                initValue={startmonthValue}
              />
              <Dropdown
                onSelect={(newValue: number): void => {
                  const newYear = Dates.years.find(
                    (each: any): any => each.value === newValue
                  );
                  if (
                    newYear &&
                    selectedEndYear &&
                    newYear.valueNumber > selectedEndYear
                  ) {
                    setShowError(true);
                  } else if (
                    newYear &&
                    selectedEndYear &&
                    newYear.valueNumber === selectedEndYear
                  ) {
                    if (
                      selectedStartMonth &&
                      selectedEndMonth &&
                      selectedStartMonth > selectedEndMonth
                    ) {
                      setShowError(true);
                    } else {
                      setShowError(false);
                      selectStartYear(newValue);
                    }
                  } else {
                    setShowError(false);
                    selectStartYear(newValue);
                  }
                }}
                textRootClass={classes.textRootClass}
                inputClass={classes.dropInputClass}
                suggestions={Dates.years}
                initValue={startyearValue}
              />
            </div>
            <div className={classes.toDateContainer}>
              <Typography
                className={classNames(classes.minorTitle, classes.first)}
              >
                {' '}
                To:{' '}
              </Typography>
              <DatePicker
                noButton
                noArrowButton
                textRootClass={classes.textRootClass}
                inputClass={classes.dropInputClass}
                minDate={{
                  year: startyearValue ? startyearValue.valueNumber : 1,
                  month: startmonthValue ? startmonthValue.valueNumber : 1,
                }}
              />
            </div>
            <div
              className={classes.minorContainer}
              style={{ paddingTop: '12px' }}
            >
              <Typography
                className={classNames(classes.minorTitle, classes.first)}
              >
                Risk Free:{' '}
              </Typography>
              <div className={classes.second} style={{ display: 'flex' }}>
                <TextField
                  id="outlined-riskFree"
                  value={
                    value === undefined && selectedRiskFree
                      ? (Math.round(selectedRiskFree * 100) / 100).toFixed(2)
                      : value
                  }
                  inputRef={inputRef}
                  onChange={changeRiskFree}
                  onKeyPress={onKeyPress}
                  margin="none"
                  variant="outlined"
                  InputProps={{
                    classes: {
                      root: classes.imputRoot,
                      notchedOutline: classes.textFieldRoot,
                      input: classes.imputRoot,
                    },
                  }}
                  className={classes.fullField}
                  onBlur={onBlur}
                />
              </div>

              <Typography
                className={classNames(classes.minorTitle, classes.third)}
                style={{
                  marginLeft: '5px',
                  marginTop: '2px',
                }}
              >
                %{' '}
              </Typography>
            </div>
            {showError && (
              <div>
                <Typography
                  className={classNames(classes.minorTitle)}
                  style={{ color: 'red' }}
                >
                  Invalid Date!
                </Typography>
              </div>
            )}
          </div>
          <div className={classes.rightContainer}>
            <div>
              <DataTable
                title="Charting"
                rows={
                  finalTableRows.length > 0
                    ? finalTableRows
                    : [{}, {}, {}, {}, {}]
                }
                columns={tableColumns}
                showToolbar={false}
                hasColor
                showNumber={tableRows.length > 0}
                showHeaders
              />
            </div>
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <div className={classes.leftChart}>
            <div>
              <Toolbar className={classes.toolbar2}>
                <div className={classes.titleContainer2}>
                  <Typography
                    className={classes.title2}
                    id="lineChartTitle"
                    variant="h3"
                  >
                    Evolution
                  </Typography>
                </div>
              </Toolbar>
              <div
                style={{
                  border: '0px solid rgb(0,0,0,0.5)',
                  borderRadius: 5,
                  paddingBottom: 20,
                  paddingTop: 20,
                }}
              >
                <Line
                  data={{
                    labels: sortedLabels,
                    datasets: lineData,
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                      display: false,
                    },
                    elements: {
                      point: {
                        radius: 1,
                      },
                      line: {
                        tension: 0, // disables bezier curves
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: 'Date',
                          },
                          ticks: {
                            maxRotation: 0,
                            callback(newValue: any): string {
                              const parts = newValue.split('-');
                              if (parts.length === 2) {
                                return parts[0] + parts[1].substr(2);
                              }

                              return '';
                            },
                          },
                        },
                      ],
                      yAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: 'Index',
                          },
                          ticks: {
                            callback(newValue: any): string {
                              return (Math.round(newValue * 100) / 100).toFixed(
                                2
                              );
                            },
                          },
                        },
                      ],
                    },
                    tooltips: {
                      callbacks: {
                        label(tooltipItem: any, data: any): string {
                          let label =
                            data.datasets[tooltipItem.datasetIndex].label || '';

                          if (label) {
                            label += ': ';
                          }
                          label += (
                            Math.round(tooltipItem.yLabel * 100) / 100
                          ).toFixed(2);
                          return label;
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.rightChart}>
            <Toolbar className={classes.toolbar2}>
              <div className={classes.titleContainer2}>
                <Typography
                  className={classes.title2}
                  id="scatterChartTitle"
                  variant="h3"
                >
                  Risk and Return
                </Typography>
              </div>
            </Toolbar>
            <div
              style={{
                border: '0px solid rgb(0,0,0,0.5)',
                borderRadius: 5,
                paddingBottom: 20,
                paddingTop: 20,
              }}
            >
              <Scatter
                data={{
                  datasets: [
                    {
                      data: scatterData,
                      backgroundColor: colors,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  legend: {
                    display: false,
                  },
                  elements: {
                    point: {
                      radius: 10,
                      borderColor: 'rgb(0,0,0,0)',
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: 'Risk %',
                        },
                        ticks: {
                          callback(newValue: any): string {
                            return `${(
                              Math.round(newValue * 100) / 100
                            ).toFixed(2)}%`;
                          },
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: 'Return %',
                        },
                        ticks: {
                          callback(newValue: any): string {
                            return `${(
                              Math.round(newValue * 100) / 100
                            ).toFixed(2)}%`;
                          },
                        },
                      },
                    ],
                  },
                  tooltips: {
                    callbacks: {
                      label(tooltipItem: any, data: any): string {
                        if (tooltipItem.index !== 5) {
                          let label =
                            data.datasets[tooltipItem.datasetIndex].label || '';

                          if (label) {
                            label += ': ';
                          }
                          label += `${(
                            Math.round(tooltipItem.xLabel * 100) / 100
                          ).toFixed(2)} ,${(
                            Math.round(tooltipItem.yLabel * 100) / 100
                          ).toFixed(2)}`;
                          return label;
                        }
                        return '';
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Charting.defaultProps = {
  chartingData: undefined,
  readOnly: false,
  tableEnabledRows: [],
  tableDisabledColumns: [],
  tableValues: [],
  inputRiskFree: undefined,
  inputStartMonth: undefined,
  inputStartYear: undefined,
  inputEndMonth: undefined,
  inputEndYear: undefined,
  reportObject: undefined,
  setReportJSON: undefined,
  getNode: undefined,
  parentReference: undefined,
  cardId: 0,
  selectedRows: [],
  selectedColumns: [],
  selectedRiskFree: undefined,
  selectedStartYear: undefined,
  selectedStartMonth: undefined,
  selectedEndYear: undefined,
  selectedEndMonth: undefined,
  selectedDate: undefined,
  selectedShowingRows: undefined,
  showNotification: undefined,
};

export default Charting;
