import React, { useState, useEffect, ReactElement } from 'react';
import classnames from 'classnames';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import TextField from '@material-ui/core/TextField';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Pie } from 'react-chartjs-2';
import DatePicker from '../../components/DatePicker';
import TabsComponent from '../../containers/Tabs';
import styles from './styles';
import SecurityValue from '../Security/SecurityValue';
import { setColor } from '../../helpers/utils';
import Footer from '../../containers/Footer';
import { AccountValuesType, AccountType, AccountValueType } from '../../Types';

let stallingTime = 0; // don't ask about it :)

const useStyles = makeStyles<typeof styles>(styles);

interface AccountValuesObj extends AccountValuesType {
  year: number;
  month: number;
}

interface StartDateType {
  month: number;
  year: number;
}

interface BreadcrumbItemObj {
  name: string;
  route: string;
}

interface Props {
  match: any;
  history: any;
  accountValues: AccountValuesType;
  getAccountItem: (id: number) => void;
  saveAccountValues: (obj: any) => void;
  getAccountValues: (id: number, year: number, month: number) => void;
  loading: boolean;
  success: boolean;
  error: any;
  getStartDate: (id: number) => Promise<StartDateType>;
  account: AccountType;
  selectedMonth: number;
  selectedYear: number;
  setMonth: (month: number) => void;
  setYear: (year: number) => void;
}

const AccountDetails = (props: Props): ReactElement => {
  const {
    match: {
      params: { accountId },
    },
    getAccountItem,
    getAccountValues,
    saveAccountValues,
    history,
    getStartDate,
    account,
    loading,
    success,
    error,
    selectedMonth,
    selectedYear,
    setMonth,
    setYear,
    accountValues,
  } = props;

  const [tabNumber, setTabNumber] = useState<number>(1);
  const [stateAccountValues, setStateAccountValues] = useState<
    AccountValuesType | undefined
  >(undefined);
  const [focusedIndex, setFocusedIndex] = useState<any | undefined>(undefined);
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [footerPos, setFooterPos] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    getAccountItem(accountId);
  }, [getAccountItem, accountId]);

  useEffect(() => {
    getAccountValues(accountId, selectedYear, selectedMonth);
  }, [getAccountValues, accountId, selectedMonth, selectedYear]);

  useEffect(() => {
    setStateAccountValues(accountValues);
  }, [accountValues]);

  const handleChangeValues = (type: string) => (
    name: string,
    value: number
  ): void => {
    const newAccountValues: any = { ...stateAccountValues };
    const index = newAccountValues[type].findIndex(
      (obj: any): any => obj.name === name
    );
    newAccountValues[type][index].value = value;
    setStateAccountValues(newAccountValues);
  };

  const save = (): void => {
    saveAccountValues({
      ...stateAccountValues,
      year: selectedYear,
      month: selectedMonth,
    });
    setOpenNotification(true);
  };

  const setStart = (): void => {
    getStartDate(account.refClient.id).then((date: StartDateType): void => {
      setYear(date.year);
      setMonth(date.month);
    });
  };

  const breadcrumbItems = [
    {
      name: account ? account.refClient.name : '',
      route: account ? `/selected-client/${account.refClient.id}` : '',
    },
    {
      name: 'Accounts',
      route: account ? `/client/${account.refClient.id}/accounts` : '',
    },
    {
      name: account ? account.refAssetManager.name : '',
      route: account ? `/client/${account.refClient.id}/accounts` : '',
    },
  ];

  const tabNames = ['Regions', 'Currencies'];

  const tabValues = [1, 2];

  return (
    <div className={classes.root}>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Toolbar className={classes.toolbar}>
          <div style={{ display: 'inherit', flex: 1 }}>
            <Tooltip title="Back">
              <IconButton
                onClick={(): void => history.goBack()}
                classes={{ root: classes.backRotButton }}
                color="inherit"
              >
                {' '}
                <LeftIcon />{' '}
              </IconButton>
            </Tooltip>
            <div className={classes.titleContainer}>
              {breadcrumbItems && (
                <div className={classes.titleContainer}>
                  {breadcrumbItems
                    .filter((each: BreadcrumbItemObj): any => each.name !== '')
                    .map(
                      (each: BreadcrumbItemObj): ReactElement => (
                        <div key={each.name}>
                          <Typography
                            component="a"
                            variant="h2"
                            onClick={(): void => {
                              history.push(each.route);
                            }}
                            className={classnames(classes.title1, classes.link)}
                          >
                            {each.name}
                          </Typography>
                          <NavigateNextIcon
                            fontSize="small"
                            style={{
                              marginBottom: '-5px',
                              marginLeft: '10px',
                              marginRight: '10px',
                            }}
                          />
                        </div>
                      )
                    )}
                  <Typography
                    className={classnames(classes.title1)}
                    id="tableTitle"
                    variant="h2"
                  >
                    Reg-Ccy
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'inherit',
              marginRight: 15,
            }}
          >
            <DatePicker setStart={setStart} />
          </div>
          <Button
            onClick={save}
            variant="outlined"
            className={classes.addButton}
          >
            Save
          </Button>
        </Toolbar>
        <div style={{ height: '100%' }}>
          <TabsComponent
            selectedTab={tabNumber}
            changeTab={(value: number): void => {
              setTabNumber(value);
              setFocusedIndex(undefined);
            }}
            tabs={tabNames}
            values={tabValues}
          />

          <div
            onScroll={(event: any): void => {
              if (
                event.target.scrollLeft < 1000 &&
                new Date().getTime() - stallingTime > 100
              ) {
                setFooterPos(event.target.scrollLeft);
                stallingTime = 0;
              }

              if (event.target.scrollWidth > 10000) {
                stallingTime = new Date().getTime();
              }
            }}
            className={classes.tabsValue}
          >
            {tabNumber === 1 && (
              <div style={{ backgroundColor: 'white', height: '100%' }}>
                <div className={classes.parent}>
                  <div className={classes.leftContainer}>
                    {stateAccountValues &&
                      stateAccountValues.regionValues &&
                      stateAccountValues.regionValues.map(
                        (
                          value: AccountValueType,
                          idx: number
                        ): ReactElement => (
                          <SecurityValue
                            key={value.pid}
                            index={idx}
                            switchField={(index: number): void =>
                              setFocusedIndex(index + 1)
                            }
                            focusedIndex={focusedIndex}
                            valueItem={value}
                            handleChangeValues={handleChangeValues(
                              'regionValues'
                            )}
                          />
                        )
                      )}

                    <div
                      style={{
                        margin: '10px 10px 50px 10px',
                        display: 'flex',
                      }}
                    >
                      <Typography variant="h6" className={classes.totalTitle}>
                        Total:{' '}
                      </Typography>
                      <TextField
                        disabled
                        InputProps={{
                          classes: {
                            root: classes.textfieldRoot,
                            input:
                              // eslint-disable-next-line no-nested-ternary
                              stateAccountValues &&
                              stateAccountValues.regionValues &&
                              stateAccountValues.regionValues
                                .map(
                                  (each: AccountValueType): number => each.value
                                )
                                .reduce(
                                  (total: number, each: number): number =>
                                    Number(total) + Number(each)
                                ) > 100
                                ? classes.totalRed
                                : stateAccountValues &&
                                  stateAccountValues.regionValues &&
                                  stateAccountValues.regionValues
                                    .map(
                                      (each: AccountValueType): number =>
                                        each.value
                                    )
                                    .reduce(
                                      (
                                        total: string | number,
                                        each: string | number
                                      ): number => Number(total) + Number(each)
                                    ) === 100
                                ? classes.totalGreen
                                : classes.rootInput,
                          },
                        }}
                        className={classnames(classes.totalValue)}
                        value={
                          stateAccountValues &&
                          stateAccountValues.regionValues &&
                          stateAccountValues.regionValues
                            .map((each: AccountValueType): number => each.value)
                            .reduce(
                              (total: number, each: number): number =>
                                Number(total) + Number(each)
                            ) === 0
                            ? ''
                            : (
                                Math.round(
                                  stateAccountValues &&
                                    stateAccountValues.regionValues
                                    ? stateAccountValues.regionValues
                                        .map(
                                          (each: AccountValueType): number =>
                                            each.value
                                        )
                                        .reduce(
                                          (
                                            total: number,
                                            each: number
                                          ): number =>
                                            Number(total) + Number(each)
                                        ) * 100
                                    : 0
                                ) / 100
                              ).toFixed(2)
                        }
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className={classes.rightContainer}>
                    <Pie
                      data={{
                        datasets: [
                          {
                            data: (stateAccountValues &&
                              stateAccountValues.regionValues &&
                              stateAccountValues.regionValues.map(
                                (each: AccountValueType): number => each.value
                              )) || [1],
                            backgroundColor:
                              stateAccountValues &&
                              stateAccountValues.regionValues
                                ? setColor(
                                    stateAccountValues.regionValues.length
                                  )
                                : ['#D3D3D3'],
                          },
                        ],
                        labels: (stateAccountValues &&
                          stateAccountValues.regionValues &&
                          stateAccountValues.regionValues.map(
                            (each: AccountValueType): string => each.name
                          )) || ['No Data'],
                      }}
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        legend: {
                          display: false,
                        },

                        plugins: {
                          labels: {
                            render:
                              stateAccountValues &&
                              stateAccountValues.regionValues
                                ? 'percentage'
                                : 'label',
                            fontColor: 'white',
                            precision: 2,
                            fontSize: 11,
                            position: 'border',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {tabNumber === 2 && (
              <div style={{ backgroundColor: 'white' }}>
                <div className={classes.parent}>
                  <div className={classes.leftContainer}>
                    {stateAccountValues &&
                      stateAccountValues.currencyValues &&
                      stateAccountValues.currencyValues.map(
                        (
                          value: AccountValueType,
                          idx: number
                        ): ReactElement => (
                          <SecurityValue
                            key={value.pid}
                            index={idx}
                            switchField={(index: number): void =>
                              setFocusedIndex(index + 1)
                            }
                            focusedIndex={focusedIndex}
                            valueItem={value}
                            handleChangeValues={handleChangeValues(
                              'currencyValues'
                            )}
                          />
                        )
                      )}

                    <div
                      style={{
                        margin: '10px 10px 50px 10px',
                        display: 'flex',
                      }}
                    >
                      <Typography variant="h6" className={classes.totalTitle}>
                        Total:{' '}
                      </Typography>
                      <TextField
                        disabled
                        InputProps={{
                          classes: {
                            root: classes.textfieldRoot,
                            input:
                              // eslint-disable-next-line no-nested-ternary
                              stateAccountValues &&
                              stateAccountValues.currencyValues &&
                              stateAccountValues.currencyValues
                                .map(
                                  (each: AccountValueType): number => each.value
                                )
                                .reduce(
                                  (total: number, each: number): number =>
                                    Number(total) + Number(each)
                                ) > 100
                                ? classes.totalRed
                                : stateAccountValues &&
                                  stateAccountValues.currencyValues &&
                                  stateAccountValues.currencyValues
                                    .map(
                                      (each: AccountValueType): number =>
                                        each.value
                                    )
                                    .reduce(
                                      (total: number, each: number): number =>
                                        Number(total) + Number(each)
                                    ) === 100
                                ? classes.totalGreen
                                : classes.rootInput,
                          },
                        }}
                        className={classnames(classes.totalValue)}
                        value={
                          stateAccountValues &&
                          stateAccountValues.currencyValues &&
                          stateAccountValues.currencyValues
                            .map((each: AccountValueType): number => each.value)
                            .reduce(
                              (total: number, each: number): number =>
                                Number(total) + Number(each)
                            ) === 0
                            ? ''
                            : (
                                Math.round(
                                  stateAccountValues &&
                                    stateAccountValues.currencyValues
                                    ? stateAccountValues.currencyValues
                                        .map(
                                          (each: AccountValueType): number =>
                                            each.value
                                        )
                                        .reduce(
                                          (
                                            total: number,
                                            each: number
                                          ): number =>
                                            Number(total) + Number(each)
                                        ) * 100
                                    : 0
                                ) / 100
                              ).toFixed(2)
                        }
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className={classes.rightContainer}>
                    <Pie
                      data={{
                        datasets: [
                          {
                            data: (stateAccountValues &&
                              stateAccountValues.currencyValues &&
                              stateAccountValues.currencyValues.map(
                                (each: AccountValueType): number => each.value
                              )) || [1],
                            backgroundColor:
                              stateAccountValues &&
                              stateAccountValues.currencyValues
                                ? setColor(
                                    stateAccountValues.currencyValues.length
                                  )
                                : ['#D3D3D3'],
                          },
                        ],
                        labels: (stateAccountValues &&
                          stateAccountValues.currencyValues &&
                          stateAccountValues.currencyValues.map(
                            (each: AccountValueType): string => each.name
                          )) || ['No Data'],
                      }}
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        legend: {
                          display: false,
                        },

                        plugins: {
                          labels: {
                            render:
                              stateAccountValues &&
                              stateAccountValues.currencyValues
                                ? 'percentage'
                                : 'label',
                            fontColor: 'white',
                            precision: 2,
                            fontSize: 11,
                            position: 'border',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <Footer
              style={{
                marginTop: 50,
                width: `calc(100% + ${footerPos > 1000 ? 0 : footerPos}px)`,
              }}
              textStyle={{ marginLeft: footerPos }}
            />
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openNotification && !loading}
        autoHideDuration={3000}
        onClose={(): void => setOpenNotification(false)}
      >
        <div>
          {success && (
            <SnackbarContent
              className={classnames(classes.success)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <CheckCircleIcon
                    className={classnames(classes.icon, classes.iconVariant)}
                  />
                  Values updated successfully
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={(): void => setOpenNotification(false)}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
            />
          )}
          {!success && (
            <SnackbarContent
              className={classnames(classes.error)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <ErrorIcon
                    className={classnames(classes.icon, classes.iconVariant)}
                  />
                  Error: {error && error.messages && error.messages[0]}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={(): void => setOpenNotification(false)}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
            />
          )}
        </div>
      </Snackbar>
    </div>
  );
};

export default AccountDetails;
