import React, { ReactElement, useState, useEffect, useRef } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Avatar from '@material-ui/core/Avatar';
import RestartIcon from '@material-ui/icons/RefreshOutlined';
import Joyride, { STATUS } from 'react-joyride';
import logo from '../../assets/img/logo.png';
import ClientActions from '../../actions/ClientActions';
import AutoComplete from '../../components/AutoComplete';
import drawerActions from '../../actions/DrawerActions';
import UserActions from '../../actions/UserActions';
import EnvironmentActions from '../../actions/EnvironmentActions';
import TourActions from '../../actions/TourActions';
import styles from './styles';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  history: any;
  clients?: any;
  client?: any;
  closeDrawer?: () => void;
  toggleDrawer?: () => void;
  selectClient?: any;
  getClientsList?: any;
  profile?: any;
  logout?: () => void;
  toggleReport?: any;
  ReportValue?: boolean;
  openUserForm?: () => void;
  openConfirmDialog?: () => void;
  getCurrentEnvironment?: any;
  currentEnvironment?: any;
  openReportSettings?: () => void;
  homeTourDisabled?: boolean;
  userMenuTourDisabled?: boolean;
  disbaleHomeTour?: () => void;
  disbaleUserMenuTour?: () => void;
}

const MainToolbar = (props: Props): ReactElement => {
  const {
    getClientsList,
    getCurrentEnvironment,
    client,
    history,
    selectClient,
    closeDrawer,
    clients,
    toggleDrawer,
    toggleReport,
    ReportValue,
    logout,
    profile,
    openUserForm,
    openConfirmDialog,
    currentEnvironment,
    openReportSettings,
    homeTourDisabled,
    userMenuTourDisabled,
    disbaleHomeTour,
    disbaleUserMenuTour,
  } = props;

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const classes = useStyles();

  useEffect(() => {
    getCurrentEnvironment();
    getClientsList();
  }, []);

  const hanldeSelectClient = (value: any): void => {
    selectClient(value);
    if (closeDrawer) closeDrawer();
    history.push(value ? `/selected-client/${value.id}` : '/');
  };

  useEffect(() => {
    if (
      history &&
      history.location &&
      (history.location.pathname === '/' ||
        history.location.pathname.indexOf('/selected-client') > -1)
    ) {
      if (client) hanldeSelectClient(client);
    }
  }, []);

  const handleMenu = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const role =
    profile && profile.roles && profile.roles[0] && profile.roles[0].name;

  const [baseJoyrideSteps, setBaseJoyRideSteps] = useState<Array<any>>([
    {
      target: '#client_name_input',
      content: 'From here you can choose the client you wish',
      placement: 'left',
    },
    {
      target: '#user_setting_button',
      content: 'From here you can access settings for user',
      placement: 'left',
    },
  ]);

  const [userJoyRideSteps, setUserJoyRideSteps] = useState<Array<any>>([
    {
      target: '#user-profile-button',
      content: 'From here you can access your profile info and edit them',
      placement: 'left',
    },
  ]);

  useEffect(() => {
    if (role === 'ADMIN' || role === 'SUPER_ADMIN') {
      const newJoyrideSteps = baseJoyrideSteps.concat({
        target: '#drawer_button',
        content:
          'From here you can access basic data, overviews and report handler',
        placement: 'right',
        placementBeacon: 'right',
      });
      setBaseJoyRideSteps(newJoyrideSteps);
      const newuserJoyrideSteps = userJoyRideSteps.concat(
        ...[
          {
            target: '#users_table',
            content: 'From here you can access to users and manage them',
            placement: 'left',
          },
          {
            target: '#report_toggle',
            content:
              'From here you can enable or disable creating report template',
            placement: 'left',
          },
          {
            target: '#report_setting_open',
            content: 'From here you can edit settings fro creating report',
            placement: 'left',
          },
          {
            target: '#reset_cache_button',
            content:
              'You can reset server cache for all clients with this button',
            placement: 'left',
          },
        ]
      );
      if (currentEnvironment?.type === 'MASTER') {
        newuserJoyrideSteps.push({
          target: '#environments_table',
          content: 'You can access environments and manage them',
          placement: 'left',
        });
      }
      setUserJoyRideSteps(newuserJoyrideSteps);
    }
  }, [role]);

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <div className={classes.container}>
          {(role === 'ADMIN' || role === 'SUPER_ADMIN') && (
            <IconButton
              id="drawer_button"
              className={classes.menuButton}
              aria-label="Menu"
              onClick={
                role && (role === 'ADMIN' || role === 'SUPER_ADMIN')
                  ? (): void => {
                      if (toggleDrawer) toggleDrawer();
                    }
                  : (): void => undefined
              }
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            onClick={(): void => {
              history.push('/');
            }}
            className={classes.appTitle}
          >
            <img
              className={classes.appLogo}
              width="25%"
              src={logo}
              alt="logo"
            />
          </Typography>
          <span id="client_name_input">
            <AutoComplete
              onSelect={(value: any): void => hanldeSelectClient(value)}
              selected={client && { label: client.name, value: client }}
              suggestions={clients.map((c: any): any => ({
                label: c.name,
                value: c,
              }))}
              onMenuOpen={getClientsList}
            />
          </span>
          <div className={classes.rightContainer}>
            <Typography className={classes.profileData}>
              {(profile && profile.name) || ''}
            </Typography>
            <IconButton
              id="user_setting_button"
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              className={classes.accountButton}
              classes={{
                label: classes.iconButtonLabel,
              }}
            >
              {profile &&
                profile.profilePictureUID &&
                profile.profilePictureUID !== '' && (
                  <Avatar
                    src={
                      window.location.hostname === 'localhost'
                        ? `https://iam2.sahand.cloud/api/file/${profile.profilePictureUID}`
                        : `https://${window.location.hostname}/api/file/${profile.profilePictureUID}`
                    }
                  />
                )}
              {(!(profile && profile.profilePictureUID) ||
                profile.profilePictureUID === '') && (
                <AccountCircle style={{ color: 'white' }} />
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                id="user-profile-button"
                onClick={(): void => {
                  handleClose();
                  if (openUserForm) openUserForm();
                }}
              >
                Profile
              </MenuItem>

              {role && (role === 'ADMIN' || role === 'SUPER_ADMIN') && (
                <MenuItem
                  id="users_table"
                  onClick={(): void => {
                    handleClose();
                    history.push('/users');
                  }}
                >
                  Users
                </MenuItem>
              )}
              {role &&
                role === 'SUPER_ADMIN' &&
                currentEnvironment &&
                currentEnvironment.type === 'MASTER' && (
                  <MenuItem
                    id="environments_table"
                    onClick={(): void => {
                      handleClose();
                      history.push('/environments');
                    }}
                  >
                    Environments
                  </MenuItem>
                )}
              {role && (role === 'ADMIN' || role === 'SUPER_ADMIN') && (
                <MenuItem id="report_toggle">
                  <FormControlLabel
                    value="Report"
                    control={
                      <Switch
                        checked={ReportValue}
                        color="primary"
                        onChange={(): void => toggleReport()}
                      />
                    }
                    classes={{
                      label: classes.formRoot,
                      labelPlacementStart: classes.formRoot2,
                    }}
                    label="Report"
                    labelPlacement="start"
                  />
                </MenuItem>
              )}

              {role && (role === 'ADMIN' || role === 'SUPER_ADMIN') && (
                <MenuItem
                  id="report_setting_open"
                  onClick={(): void => {
                    handleClose();
                    if (openReportSettings) openReportSettings();
                  }}
                >
                  Report Settings
                </MenuItem>
              )}

              {role && (role === 'ADMIN' || role === 'SUPER_ADMIN') && (
                <MenuItem
                  id="reset_cache_button"
                  onClick={(): void => {
                    handleClose();
                    if (openConfirmDialog) openConfirmDialog();
                  }}
                >
                  Reset Cache{' '}
                  <RestartIcon style={{ fontSize: '21px', marginLeft: 10 }} />
                </MenuItem>
              )}
              <MenuItem
                onClick={(): void => {
                  selectClient(null);
                  history.push('/');
                  handleClose();
                  if (logout) logout();
                }}
              >
                Logout
              </MenuItem>
              {currentEnvironment && currentEnvironment.type === 'TEST' && (
                <Joyride
                  steps={userJoyRideSteps}
                  styles={{
                    options: {
                      zIndex: 20000,
                    },
                  }}
                  continuous
                  showProgress
                  showSkipButton
                  run={!userMenuTourDisabled}
                  callback={(event: any): void => {
                    const { status } = event;
                    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                      if (disbaleUserMenuTour) disbaleUserMenuTour();
                    }
                  }}
                />
              )}
            </Menu>
          </div>
        </div>
      </Toolbar>
      {currentEnvironment && currentEnvironment.type === 'TEST' && (
        <Joyride
          steps={baseJoyrideSteps}
          styles={{
            options: {
              zIndex: 20000,
            },
          }}
          continuous
          showProgress
          showSkipButton
          run={!homeTourDisabled}
          callback={(event: any): void => {
            const { status } = event;
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
              if (disbaleHomeTour) disbaleHomeTour();
            }
          }}
          // callback  set redux state too false on e.type === 'tour:end'
        />
      )}
    </AppBar>
  );
};

const mapStateToProps = (state: any): any => ({
  open: state.Drawer.open,
  client: state.Client.selectedClient,
  clients: state.Client.clients ? state.Client.clients.content : [],
  ReportValue: state.User.enableReport,
  profile: state.User.profile,
  roles: state.User.roles,
  currentEnvironment: state.Environment.currentEnvironment,
  homeTourDisabled: state.Tour.homeTourDisabled,
  userMenuTourDisabled: state.Tour.userMenuTourDisabled,
});

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...drawerActions,
      ...ClientActions,
      ...UserActions,
      ...EnvironmentActions,
      ...TourActions,
    },
    dispatch
  );

MainToolbar.defaultProps = {
  client: undefined,
  roles: [],
  ReportValue: false,
  profile: undefined,
  currentEnvironment: undefined,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MainToolbar
);
