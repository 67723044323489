import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OverviewActions from '../../actions/OverviewActions';
import AssetManagersOverview from './AssetManagersOverview';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ ...OverviewActions }, dispatch);

const mapStateToProps = (state: any): any => ({
  loading: state.Overview.loading,
  assetManagersOverview: state.Overview.assetManagersOverview,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetManagersOverview);
