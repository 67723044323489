import createStyles from '@material-ui/core/styles/createStyles';

const styles = (): any =>
  createStyles({
    root: {
      display: 'flex',
      padding: '0px 0px 30px 0px',
      marginLeft: '-9px',
    },
    topElement: {
      background: 'white',
      height: '38px',
      position: 'sticky !important' as any,
      top: '0px !important',
      zIndex: 1000,
      marginRight: '-1px',
    },
    root1: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      marginLeft: '10px',
      width: 'calc(100% - 10px)',
    },
    popLabel: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    formContainer: {
      width: '100%',
      margin: '10px 30px',
    },
    fullField: {
      margin: '5px 5px',
      width: '98%',
    },
    savePorfileButton: {
      minWidth: 60,
      width: 146,
      height: 30,
      paddingTop: 2,
      marginBottom: 8,
    },
    savePorfileButtonLabel: {
      fontSize: 12,
    },
  });

export default styles;
