import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import ClientActions from '../../actions/ClientActions';
import UserClients from './UserClients';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    { ...UserActions, ...DialogActions, ...ClientActions },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  deleted: state.User.deleted,
  success: state.User.success,
  error: state.User.error,
  loading: state.User.loading,
  roles: state.User.roles,
  profile: state.User.profile,
  user: state.User.user,
  clients: state.Client.clients,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserClients);
