import React, { ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiDialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import styles from './styles';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  title: string;
  actions: any;
  children: any;
  paperClass: any;
  titleClassName: string;
  contentClassName: string;
  actionBarClassName: string;
  loadings: boolean;
  paddingType: string;
  dialogProps: string;
  extraActions?: any;
}

const Dialog = ({
  open,
  onClose,
  title,
  actions,
  paperClass,
  titleClassName,
  contentClassName,
  children,
  paddingType,
  dialogProps,
  extraActions,
}: Props): ReactElement => {
  const classes = useStyles();
  return (
    <MuiDialog
      maxWidth={false}
      fullWidth
      open={open}
      onClose={onClose}
      classes={{ paper: classNames(classes.paper, paperClass) }}
      BackdropProps={{ className: classes.backdrop }}
      {...dialogProps}
    >
      {title && (
        <DialogTitle
          className={classNames(classes.title, titleClassName)}
          disableTypography
        >
          <Typography className={classes.titleText}>{title}</Typography>
        </DialogTitle>
      )}
      <Divider />
      {children && (
        <DialogContent
          className={classNames(
            classes.content,
            contentClassName,
            classes[paddingType]
          )}
          classes={{ root: classes.content }}
        >
          {children}
        </DialogContent>
      )}
      <Divider />
      {actions && Array.isArray(actions) && actions.length > 0 && (
        <DialogActions className={classNames(classes.actionContainer)}>
          <div className={classes.extraAction}>
            {extraActions &&
              extraActions.map(
                (
                  { type, label, Icon, onClick, disabled, style }: any,
                  index: number
                ): ReactElement => (
                  <Button
                    className={classNames(classes.actionButton, {
                      [classes.primaryActionButton]: type === 'primary',
                    })}
                    color={type || 'default'}
                    key={`button-bar-button-${index}`} // eslint-disable-line react/no-array-index-key
                    onClick={onClick || onClose}
                    disabled={Boolean(disabled)}
                    style={style}
                    variant="outlined"
                  >
                    {label}
                    {Icon != null && <Icon className={classes.icon} />}
                  </Button>
                )
              )}
          </div>
          <div className={classes.actionBar}>
            {actions.map(
              (
                { type, label, Icon, onClick, disabled, style },
                index: number
              ): ReactElement => (
                <Button
                  className={classNames(classes.actionButton, {
                    [classes.primaryActionButton]: type === 'primary',
                  })}
                  color={type || 'default'}
                  key={`button-bar-button-${index}`} // eslint-disable-line react/no-array-index-key
                  onClick={onClick || onClose}
                  disabled={Boolean(disabled)}
                  style={style}
                  variant="outlined"
                >
                  {label}
                  {Icon != null && <Icon className={classes.icon} />}
                </Button>
              )
            )}
          </div>
        </DialogActions>
      )}
    </MuiDialog>
  );
};

Dialog.defaultProps = {
  actions: [],
  loading: false,
  titleClassName: undefined,
  actionBarClassName: undefined,
  contentClassName: undefined,
  paperClass: undefined,
  title: undefined,
  paddingType: 'none',
  loadings: false,
  children: undefined,
  dialogProps: undefined,
};

export default Dialog;
