import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserActions from '../../actions/UserActions';
import Login from './Login';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ ...UserActions }, dispatch);

export default connect(
  (state: any): any => ({ sessionEnded: state.User.sessionEnded }),
  mapDispatchToProps
)(Login);
