import React, { useState, useEffect, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import ClientsIcon from '@material-ui/icons/PeopleOutline';
import Typography from '@material-ui/core/Typography';
import FullCheckboxIcon from '@material-ui/icons/Done';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import DialogForm from './DialogForm';
import styles from './styles';
import {
  UserType,
  UserRoleType,
  UserProfileType,
  CreateUserType,
  UpdateUserType,
  EnvironmentType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  getUserList: () => void;
  createUser: (obj: CreateUserType) => void;
  updateUser: (obj: UpdateUserType, id: number) => Promise<any>;
  openDialog: () => void;
  closeDialog: () => void;
  users: { content: Array<UserType> };
  roles: Array<UserRoleType>;
  deleted: boolean;
  success: boolean;
  error: any;
  loading: boolean;
  getUserItem: (id: number) => Promise<UserType>;
  getProfile: () => void;
  profile: UserProfileType;
  currentEnvironment: EnvironmentType;
  removeUser: (id: number) => void;
  history: any;
}

const Users = (props: Props): ReactElement => {
  const {
    getUserList,
    openDialog,
    profile,
    currentEnvironment,
    closeDialog,
    removeUser,
    history,
    users,
    updateUser,
    createUser,
    roles,
    loading,
    success,
    error,
    deleted,
    getUserItem,
    getProfile,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [model, setModel] = useState<UserType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<UserType | undefined>(
    undefined
  );
  const classes = useStyles();

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  const openAdd = (): void => {
    openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const openEdit = (event: any, row: UserType): void => {
    if (
      profile &&
      profile.roles &&
      profile.roles[0] &&
      profile.roles[0].name === 'ADMIN'
    ) {
      if (!(row.roles && row.roles[0] && row.roles[0].name === 'SUPER_ADMIN')) {
        openDialog();
        setOpen(true);
        setModel(row);
      } else {
        return;
      }
    }
    openDialog();
    setOpen(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: UserType): void => {
    if (
      profile &&
      profile.roles &&
      profile.roles[0] &&
      profile.roles[0].name === 'ADMIN'
    ) {
      if (!(row.roles && row.roles[0] && row.roles[0].name === 'SUPER_ADMIN')) {
        openDialog();
        setOpenDelete(true);
        setSelectedRow(row);
      } else {
        return;
      }
    }
    openDialog();
    setOpenDelete(true);
    setSelectedRow(row);
  };

  const handleClose = (): void => {
    closeDialog();
    setOpen(false);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) removeUser(selectedRow.id);
  };

  const userClients = (e: any, data: UserType): void => {
    history.push(`user/${data.id}/clients`);
  };

  const disableDeleteButton = (row: UserType): boolean => {
    if (
      profile &&
      profile.roles &&
      profile.roles[0] &&
      profile.roles[0].name === 'ADMIN'
    ) {
      if (row.roles && row.roles[0] && row.roles[0].name === 'SUPER_ADMIN') {
        return true;
      }
      return false;
    }
    return false;
  };

  const disableEditButton = (row: UserType): boolean => {
    if (
      profile &&
      profile.roles &&
      profile.roles[0] &&
      profile.roles[0].name === 'ADMIN'
    ) {
      if (row.roles && row.roles[0] && row.roles[0].name === 'SUPER_ADMIN') {
        return true;
      }
      return false;
    }
    return false;
  };

  const columns = [
    {
      name: 'name',
      title: 'Name',
      style: { width: 250 },
      rowStyle: { width: 250 },
      searchable: true,
      sortable: true,
      type: 'string',
      sortField: 'name',
    },
    {
      name: 'clientLength',
      title: 'Clients',
      style: { textAlign: 'center', width: 250, justifyContent: 'center' },
      rowStyle: { textAlign: 'center', width: 250, justifyContent: 'center' },
      sortable: true,
      type: 'number',
      sortField: 'clientLength',
    },
    {
      name: 'username',
      title: 'Username',
      style: { width: 250 },
      rowStyle: { width: 250 },
      searchable: true,
      sortable: true,
      type: 'string',
      sortField: 'username',
    },
    {
      name: 'role',
      title: 'Role',
      style: { width: 250 },
      rowStyle: { width: 250 },
      searchable: true,
      sortable: true,
      type: 'string',
      sortField: 'role',
    },
    {
      name: 'email',
      title: 'Email',
      style: { width: 150 },
      rowStyle: { width: 150 },
      searchable: true,
    },
  ];

  if (currentEnvironment && currentEnvironment.simulationTab) {
    columns.push({
      title: 'Simulation Tab',
      name: 'simulationTabValue',
      style: { justifyContent: 'center', textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
        textAlign: 'center',
      },
      sortable: true,
      sortField: 'simulationTab',
      type: 'boolean',
    } as any);
  }

  const details = (row: UserType): any => [
    {
      icon: <ClientsIcon fontSize="small" />,
      title: 'Clients',
      onClick: userClients,
      disabled:
        row.accessToAllClients ||
        (profile &&
          profile.roles &&
          profile.roles[0] &&
          profile.roles[0].name === 'ADMIN' &&
          row.roles &&
          row.roles[0] &&
          row.roles[0].name === 'SUPER_ADMIN'),
    },
  ];

  const editedUsers =
    (users &&
      users.content &&
      users.content.map((each: UserType): any => ({
        ...each,
        accessToAllClientsString: each.accessToAllClients ? 'YES' : 'No',
        simulationTabValue: each.simulationTab ? (
          <FullCheckboxIcon style={{ marginBottom: '-10px' }} />
        ) : (
          ''
        ),
        role: (each.roles && each.roles[0] && each.roles[0].name) || '',
        clientLength: each.accessToAllClients
          ? 'All'
          : each.clients && each.clients.length,
      }))) ||
    [];

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          hasEditAndDelete
          onEdit={openEdit}
          onAdd={openAdd}
          onDelete={handleDeleteOpen}
          columns={columns}
          noMargin
          details={details}
          rows={editedUsers}
          hasHighlightBackground
          disableDeleteButton={disableDeleteButton}
          disableEditButton={disableEditButton}
          entity="User"
          entities="Users"
        />
      </div>
      <DialogForm
        model={model}
        open={open}
        onClose={handleClose}
        update={updateUser}
        create={createUser}
        loading={loading}
        success={success}
        error={error}
        roles={roles}
        getUserItem={getUserItem}
        profile={profile}
        getProfile={getProfile}
        currentEnvironment={currentEnvironment}
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {isRemoving && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              User removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error: {error && error.messages && error.messages[0]}
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};

Users.defaultProps = {
  deleted: false,
  client: undefined,
  success: undefined,
  error: undefined,
  history: undefined,
  assetClassSets: undefined,
  profile: undefined,
};

export default Users;
