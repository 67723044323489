import React, { useEffect, useState, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import Dropdown from '../../components/Dropdown';
import Dates from '../../constants/Date';
import { getCurrentMonth, getCurrentYear } from '../../helpers/utils';
import DataTable from '../../containers/DataTable';
import styles from './styles';
import GearLoader from '../../containers/Client/Loaders/infinity.gif';
import { ClientOverviewType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  clientsOverview: ClientOverviewType;
  getClientsOverview: (year: number, month: number) => void;
  history: any;
  loading: boolean;
}

const ClientsOverview = (props: Props): ReactElement => {
  const { getClientsOverview, clientsOverview, history, loading } = props;
  const [selectedYear, setSelectedYear] = useState<number>(
    getCurrentMonth() === 0 ? getCurrentYear() - 1 : getCurrentYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    getCurrentMonth() === 0 ? 12 : getCurrentMonth()
  );
  const [rows, setRows] = useState<any>([]);
  const classes = useStyles();

  const formatNumber = (number: number | string): string => {
    const newNumber = Number(number).toFixed(0);
    if (newNumber !== 'NaN') {
      return newNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return '';
  };

  useEffect(() => {
    getClientsOverview(selectedYear, selectedMonth);
  }, [selectedMonth, selectedYear, getClientsOverview]);

  useEffect(() => {
    if (!clientsOverview) return;
    const newRows = [
      {
        ...clientsOverview.total,
        total: formatNumber(clientsOverview.total.total),
        totalNumber: clientsOverview.total.total,
        fixed: formatNumber(clientsOverview.total.fixed),
        fixedNumber: clientsOverview.total.fixed,
        liquid: formatNumber(clientsOverview.total.liquid),
        liquidNumber: clientsOverview.total.liquid,
        style: { fontWeight: 600 },
        id: 'total',
      },
    ];
    clientsOverview.rows.forEach((each: any): void => {
      newRows.push({
        ...each,
        total: formatNumber(each.total),
        totalNumber: each.total,
        fixed: formatNumber(each.fixed),
        fixedNumber: each.fixed,
        liquid: formatNumber(each.liquid),
        liquidNumber: each.liquid,
        id: each.clientId,
      });
    });
    setRows(newRows);
  }, [clientsOverview]);

  const previousMonth = (): void => {
    if (selectedMonth === 1) {
      setSelectedMonth(12);
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };

  const nextMonth = (): void => {
    if (selectedMonth === 12) {
      setSelectedMonth(1);
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
  };

  const selectMonth = (value: number): void => {
    const month = Dates.months.find((m: any): any => m.value === value);
    if (month && month.valueNumber) setSelectedMonth(month.valueNumber);
  };

  const selectYear = (value: number): void => {
    const year = Dates.years.find((y: any): any => y.value === value);
    if (year && year.valueNumber) setSelectedYear(year.valueNumber);
  };

  const selectClient = (e: any, data: any): void => {
    history.push(`selected-client/${data.id}`);
  };

  const exportTable = (): void => {
    const csvData = new Blob([clientsOverview.export], {
      type: 'text/csv;charset=utf-8;',
    });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute(
      'download',
      clientsOverview.fileName || 'ClientsOverview.csv'
    );
    tempLink.click();
  };

  const columns = [
    {
      title: 'Name',
      name: 'clientName',
      sortable: true,
      type: 'string',
      sortField: 'clientName',
    },
    {
      title: 'Total',
      name: 'total',
      style: { textAlign: 'right', justifyContent: 'flex-end' },
      rowStyle: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: 32,
      },
      sortable: true,
      type: 'number',
      sortField: 'totalNumber',
    },
    {
      title: 'Fixed',
      name: 'fixed',
      style: { textAlign: 'right', justifyContent: 'flex-end' },
      rowStyle: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: 32,
      },
      sortable: true,
      type: 'number',
      sortField: 'fixedNumber',
    },
    {
      title: 'Liquid',
      name: 'liquid',
      style: { textAlign: 'right', justifyContent: 'flex-end' },
      rowStyle: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: 32,
      },
      sortable: true,
      type: 'number',
      sortField: 'liquidNumber',
    },
  ];

  const monthInitValue = Dates.months.find(
    m => m.valueNumber === selectedMonth
  );
  const yearInitValue = Dates.years.find(y => y.valueNumber === selectedYear);

  return (
    <div
      style={{
        height: 'calc(100% - 115px)',
        backgroundColor: '#eff0f1',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {loading && (
        <div className={classes.loaderContainer} style={{ opacity: 0.35 }}>
          <img src={GearLoader} alt="" style={{ width: 120 }} />
        </div>
      )}
      <Toolbar className={classes.toolbar}>
        <div style={{ display: 'inherit', flex: 1 }}>
          <div className={classes.titleContainer}>
            <Typography
              className={classnames(classes.title1)}
              id="tableTitle"
              variant="h2"
            >
              Clients Overview
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'inherit',
            marginRight: 15,
          }}
        >
          <IconButton
            className={classes.iconButton}
            onClick={previousMonth}
            classes={{ root: classes.arrowButtonRoot }}
            color="primary"
          >
            <LeftIcon />
          </IconButton>
          <div className={classes.yearPickerContainer}>
            <Dropdown
              onSelect={selectMonth}
              suggestions={Dates.months}
              initValue={monthInitValue}
              accountButtonClass={classes.select}
            />
          </div>
          <div className={classes.yearPickerContainer}>
            <Dropdown
              onSelect={selectYear}
              suggestions={Dates.years}
              initValue={yearInitValue}
              accountButtonClass={classes.select}
            />
          </div>

          <IconButton
            className={classes.iconButton}
            onClick={nextMonth}
            classes={{ root: classes.arrowButtonRoot }}
            color="primary"
          >
            <RightIcon />
          </IconButton>
        </div>
      </Toolbar>
      <div style={{ height: '100%' }}>
        <DataTable
          hasEditAndDelete
          columns={columns}
          noMargin
          rows={rows}
          hasDetails
          hasExportButton
          onExport={exportTable}
          onClickRow={selectClient}
          entity="Clients Overview"
          entities="Clients Overview"
          hasToolbar={false}
        />
      </div>
    </div>
  );
};

ClientsOverview.propTypes = {};

ClientsOverview.defaultProps = {
  clientsOverview: undefined,
  loading: false,
};

export default ClientsOverview;
