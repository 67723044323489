import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const exportClientsTable = (): any =>
  Request(`export/clients`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportAssetClassSetsTable = (): any =>
  Request(`export/assetclasssets`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportAssetClassesTable = (id: number): any =>
  Request(`export/assetclassset/${id}/assetclasses`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportRiskClassesTable = (id: number): any =>
  Request(`export/assetclassset/${id}/riskclasses`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportAssetManagersTable = (): any =>
  Request(`export/assetmanagers`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportAccountCodesTable = (): any =>
  Request(`export/accountcodes`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportAccountTypesTable = (): any =>
  Request(`export/accounttypes`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportRegionsTable = (): any =>
  Request(`export/regions`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportCurrenciesTable = (): any =>
  Request(`export/currencies`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportSecuritiesTable = (): any =>
  Request(`export/securities`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportBenchmarksTable = (): any =>
  Request(`export/benchmarks`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportBenchmarkValueTable = (id: number): any =>
  Request(`export/benchmark/${id}/values`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportAccountsTable = (id: number): any =>
  Request(`export/client/${id}/accounts`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const exportClientTypesTable = (): any =>
  Request(`export/clienttypes`, 'GET')
    .then(ErrorHandler)
    .then((eTable: any): any => eTable);

const ExportService = {
  exportClientsTable,
  exportAssetClassSetsTable,
  exportAssetClassesTable,
  exportRiskClassesTable,
  exportAssetManagersTable,
  exportAccountCodesTable,
  exportAccountTypesTable,
  exportRegionsTable,
  exportCurrenciesTable,
  exportSecuritiesTable,
  exportBenchmarksTable,
  exportBenchmarkValueTable,
  exportAccountsTable,
  exportClientTypesTable,
};

export default ExportService;
