import AssetClassService from '../services/AssetClassService';
import * as types from '../constants/AssetClass';
import { AssetClassType } from '../Types';

const getAssetClassesList = (assetClassSetId: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.ASSET_CLASSES_LIST_REQUEST });
  AssetClassService.list(assetClassSetId)
    .then((assetClasses: { content: Array<AssetClassType> }): void => {
      dispatch({ type: types.ASSET_CLASSES_LIST_SUCCESS, assetClasses });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_CLASSES_LIST_FAILURE, error });
    });
};

const getAssetClassItem = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ASSET_CLASS_ITEM_REQUEST });
  AssetClassService.item(id)
    .then((assetClass: AssetClassType): void => {
      dispatch({ type: types.ASSET_CLASS_ITEM_SUCCESS, assetClass });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_CLASS_ITEM_FAILURE, error });
    });
};

const createAssetClass = (data: any, id: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.ASSET_CLASS_CREATE_REQUEST });
  AssetClassService.create(data)
    .then((assetClass: any): void => {
      dispatch({ type: types.ASSET_CLASS_CREATE_SUCCESS, assetClass });
      dispatch(getAssetClassesList(id));
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ASSET_CLASS_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ASSET_CLASS_CREATE_FAILURE, error });
      }
    });
};

const updateAssetClass = (data: any, id: number, dataId: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.ASSET_CLASS_UPDATE_REQUEST });
  AssetClassService.update(data, dataId)
    .then((assetClass: any): void => {
      dispatch({ type: types.ASSET_CLASS_UPDATE_SUCCESS, assetClass });
      dispatch(getAssetClassesList(id));
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ASSET_CLASS_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ASSET_CLASS_UPDATE_FAILURE, error });
      }
    });
};

const removeAssetClass = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ASSET_CLASS_REMOVE_REQUEST });
  AssetClassService.remove(id)
    .then((assetClass: any): void => {
      dispatch({ type: types.ASSET_CLASS_REMOVE_SUCCESS, assetClass, id });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_CLASS_REMOVE_FAILURE, error });
    });
};

const AssetClassActions = {
  getAssetClassesList,
  getAssetClassItem,
  createAssetClass,
  updateAssetClass,
  removeAssetClass,
};

export default AssetClassActions;
