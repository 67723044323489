import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    root: {
      display: 'flex',
      padding: '0px 0px 30px 0px',
      marginLeft: '-9px',
    },
    topElement: {
      background: 'white',
      height: '38px',
      position: 'sticky !important' as any,
      top: '0px !important',
      zIndex: 100,
      marginRight: '-1px',
    },
    root1: {
      display: 'flex',
      height: 'calc(100% + 30px)',
      flexDirection: 'column',
      marginLeft: '10px',
      width: 'calc(100% - 10px)',
      marginBottom: 30,
    },
    table: {
      width: 'calc(100% - 5px)',
    },
    cell: {
      whiteSpace: 'nowrap',
      border: '1px solid rgba(25, 118 , 210, 0.5)',
      minWidth: '40px',
      fontSize: '12px',
      color: 'rgb(0,0,0, 0.7)',
      overflow: 'hidden',
    },
    headerCell: {
      color: '#1976d2',
      textAlign: 'center !important' as any,
      border: '1px solid rgba(25, 118 , 210, 0.5)',
      fontWeight: 600,
      background: 'white',
      zIndex: 100,
      boxShadow: '0 1px 0 0 rgba(25, 118 , 210, 0.5)',
      backgroundClip: 'padding-box',
    },
    leftHeaderCell: {
      color: '#1976d2 !important',
      textAlign: 'left !important' as any,
      border: '1px solid rgba(25, 118 , 210, 0.5)',
      fontWeight: '600 !important' as any,
      background: 'white',
      zIndex: 10,
      boxShadow: '0 1px 0 0 rgba(25, 118 , 210, 0.5)',
      backgroundClip: 'padding-box',
    },
    stickyHeaderCell: {
      position: 'sticky !important' as any,
      top: '78px !important',
    },
    checkMarkCells: {
      background: 'white',
      zIndex: 10,
      boxShadow: '0 1px 0 0 rgba(25, 118 , 210, 0.5)',
    },
    stickyCheckMarkCells: {
      position: 'sticky !important' as any,
      top: '38px !important',
    },
    checkMarkCell: {
      border: 'none',
      textAlign: 'center !important' as any,
    },
    rowContainer: {
      height: 30,
    },
    hasDetails: {
      '&:hover': { cursor: 'pointer' },
    },
    cellRoot: {
      position: 'relative',
      WebkitAnimation: 'fadein 2s',
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '8px',
        maxWidth: '50px',
        minWidth: '50px',
        padding: '6px 6px 1px 1px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '9px',
        maxWidth: '55px',
        minWidth: '55px',
        padding: '5px 6px 1px 1px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '10px',
        maxWidth: '60px',
        minWidth: '60px',
        padding: '4px 6px 1px 1px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '12px',
        maxWidth: '65px',
        minWidth: '65px',
        padding: '3px 6px 1px 1px',
      },
      textAlign: 'right',
      height: 'auto !important',
      '&:last-child': {
        padding: '3px 6px 1px 1px',
      },
      '&:first-child': {
        padding: '4px 8px 4px 8px',
      },
      '&:focus': {
        outline: 'none',
        color: 'transparent',
        textShadow: '0 0 0 black',
        border: '1px solid rgba(25, 118 , 210, 0.5)',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
    checkCellRoot: {
      position: 'relative',
      WebkitAnimation: 'fadein 2s',
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '8px',
        maxWidth: '50px',
        minWidth: '50px',
        padding: '6px 6px 1px 1px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '9px',
        maxWidth: '55px',
        minWidth: '55px',
        padding: '5px 6px 1px 1px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '10px',
        maxWidth: '60px',
        minWidth: '60px',
        padding: '4px 6px 1px 1px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '12px',
        maxWidth: '65px',
        minWidth: '65px',
        padding: '3px 6px 1px 1px',
      },
      textAlign: 'right',
      height: 'auto !important',
      '&:last-child': {
        padding: '3px 6px 1px 1px',
      },
      '&:first-child': {
        padding: '4px 8px 4px 8px',
        backgroundColor: 'white !important',
      },
      '&:focus': {
        outline: 'none',
        color: 'transparent',
        textShadow: '0 0 0 black',
        border: '1px solid rgba(25, 118 , 210, 0.5)',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
    checkboxCell: {
      position: 'relative',
      padding: '1px 3px 1px 1px',
      fontSize: '9px',
      textAlign: 'center',
      maxWidth: '50px',
      minWidth: '50px',
      borderBottom: '1px solid rgb(25, 118 , 210,0.5)',
      height: 'auto !important',
      '&:last-child': {
        padding: '1px 3px 1px 1px',
      },
      '&:first-child': {
        padding: '4px 8px 4px 8px',
      },
      '&:focus': {
        outline: 'none',
        color: 'transparent',
        textShadow: '0 0 0 black',
        border: '1px solid rgba(25, 118 , 210, 0.5)',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
    textfield: {
      marginTop: '',
      textAlign: 'center',
    },
    iconRoot: {
      padding: '3px',
      color: '#000',
    },
    inCellIconRoot: {
      padding: '1px',
      color: '#000',
    },
    cellIcon: {
      display: 'block',
    },
    commentPopover: {
      padding: '10px',
    },
    popoverText: {
      fontSize: '10px',
    },
    selectedRow: {
      // height: 35,
      borderTop: '2px solid #0099ff !important',
      borderBottom: '2px solid #0099ff !important',
    },
    subjectCell: {
      fontWeight: 500,
      color: 'rgb(0,0,0, 0.7)',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      maxWidth: '120px',
      minWidth: '120px',
      border: '1px solid rgba(25, 118 , 210, 0.5)',
      paddingLeft: 8,
    },
    selectedCell: {
      backgroundColor: '#b5d3ff !important',
      color: 'rgb(0,0,0, 0.7)',
      whiteSpace: 'nowrap',
    },
    noneEffectCell: {
      padding: '4px 8px',
      textAlign: 'right !important' as any,
      border: 'none',
    },
    secNoEffectCell: {
      border: 'none',
      background: '#e8e8e8 !important',
    },
    secSubjectCell: {
      border: '1px solid rgba(25, 118 , 210, 0.5)',
      paddingLeft: 20,
    },
    editingTextfield: {
      background: '#b5d3ff !important',
      textAlign: 'center',
      fontWeight: 600,
      // fontSize: '9px',
    },
    totalCell: {
      fontWeight: 600,
      color: 'rgb(0,0,0, 0.7)',
      border: '1px solid rgba(25, 118 , 210, 0.5)',
      padding: '0px 6px 1px 1px',
    },
    cashCell: {
      backgroundColor: '#e9f8fe !important',
      backgroundClip: 'padding-box !important',
    },
    disabledRow: {
      color: 'rgb(0, 0, 0, 0.4)',
    },
    checkboxSize: {
      width: '18px',
      height: '18px',
    },
    checkboxRootTop: {
      padding: '1px 1px 1px 1px',
      marginBottom: '9px',
    },
    checkboxRootSide: {
      padding: '1px 1px 1px 1px',
      marginRight: '9px',
    },
    popLabel: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    label: {
      marginTop: 2,
      fontSize: '12px',
    },
    checkboxroot: {
      padding: 6,
    },
    checkCellBackground: {
      backgroundColor: 'white !important',
      backgroundClip: 'padding-box',
    },
    tableHead: {
      display: 'contents',
    },
    removeIconRoot: {
      padding: '3px',
      color: '#000',
    },
    removeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
      zIndex: 10,
      marginBottom: 5,
    },
  });

export default styles;
