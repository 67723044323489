import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import AppWrapper from './containers/App-Wrapper';
import Lobby from './containers/Lobby';

const Root = ({ store, persistor }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <AppWrapper>
            <Lobby />
          </AppWrapper>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default Root;
