import React, {
  useEffect,
  useState,
  useRef,
  ReactElement,
  useLayoutEffect,
} from 'react';
import classnames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import { Bar } from 'react-chartjs-2';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './styles';
import ValuesTable from '../../../../containers/ValuesTable';
import Dates from '../../../../constants/Date';
import None from '../AssetTable/custom-icons/none2.svg';
import { HistoryType } from '../../../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  year: number;
  month: number;
  getHistory?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>,
    historyType: string
  ) => Promise<HistoryType>;
  clientId: number;
  readOnly?: boolean;
  parentReference: any;
  getNode?: (ref: any, name: string, id: number) => void;
  cardId?: number;
  selectedDate?: any;
  selectedColumns?: Array<number>;
  selectedRows?: Array<number>;
  showNotification: () => void;
  rootClass?: any;
  root1Class?: any;
}

const History = (props: Props): ReactElement => {
  const {
    selectedDate,
    selectedRows,
    selectedColumns,
    month,
    year,
    getHistory,
    clientId,
    parentReference,
    readOnly,
    getNode,
    cardId,
    showNotification,
    root1Class,
    rootClass,
  } = props;
  const historyTypes = [
    { label: 'Quarterly', value: 'QUARTERLY' },
    { label: 'Monthly', value: 'MONTHLY' },
  ];
  const [tableRows, setTableRows] = useState<any>([]);
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [labels, setLabels] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);
  const [tableTotalRow, setTableTotalRow] = useState<any>(undefined);
  const [totalRowDetails, setTotalRowDetails] = useState<any>(undefined);
  const [historyType, setHistoryType] = useState<any>('Quarterly');
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {});

  const setHistory = (inputHistory: HistoryType): void => {
    if (inputHistory) {
      const { rows, columns, totalRow } = inputHistory;
      const newColumns: any[] = [
        {
          title: 'Asset Manager',
          name: 'assetManager',
          type: 'header',
          columnId: 'assetManager-Asset Manager',
        },
        {
          title: 'Account Code',
          name: 'accountCode',
          style: { textAlign: 'Left', paddingLeft: '6px' },
          type: 'readOnly',
          columnId: 'accountCode-Account Code',
          hidden: true,
        },
        {
          title: 'Account Type',
          name: 'accountType',
          style: { textAlign: 'Left', paddingLeft: '6px' },
          type: 'readOnly',
          columnId: 'accountType-Account Type',
          hidden: true,
        },
        {
          title: 'ACcount Number',
          name: 'accountNumber',
          style: { textAlign: 'Left', paddingLeft: '6px' },
          type: 'readOnly',
          columnId: 'accountNumber-Account Number',
          hidden: true,
        },
      ];
      columns.forEach((each: number, idx: number): void => {
        const date = new Date(each);
        const columnMonth = Dates.months.find(
          (eachMonth: any): any => eachMonth.valueNumber - 1 === date.getMonth()
        );
        const columnYear = Dates.years.find(
          (eachyear: any): any => eachyear.valueNumber === date.getFullYear()
        );
        const newColumn = {
          columnId: `${each}-${idx}`,
          id: `${each}-${idx}`,
          name: `${each}`,
          title: (
            <span>
              <span>{`${(columnMonth && columnMonth.label) || ''}`}</span>
              <br /> <span>{` ${(columnYear && columnYear.label) || ''}`}</span>
            </span>
          ),
        };
        newColumns.push(newColumn);
      });
      const newRows: any[] = [];
      rows.forEach((each: any, idx: number): void => {
        const newRow: { [key: string]: any } = {
          accountCode: each.accountCode,
          accountType: each.accountType,
          accountNumber: each.number,
          accountId: each.accountId,
          assetManager: each.assetManager,
          assetManagerId: each.assetManagerId,
          id: `${each.assetManagerId}-${idx}`,
          isExpanded: false,
          hasDetails: true,
          details: ['cash'],
          PopperDetails: (
            <Paper
              style={{
                padding: '5px',
                fontSize: '12px',
              }}
            >
              <div className={classes.popLabel}>
                <strong>Asset Manager:</strong> {each.assetManager}
              </div>
              <div className={classes.popLabel}>
                <strong>Account Code:</strong> {each.accountCode}
              </div>
              <div className={classes.popLabel}>
                <strong>Account Type:</strong> {each.accountType}
              </div>
              <div className={classes.popLabel}>
                <strong>Account Number:</strong> {each.accountNumber}
              </div>
            </Paper>
          ),
        };
        Object.keys(each.values).forEach((eachValue: any): void => {
          newRow[`${eachValue}`] = each.values[eachValue];
        });
        newRows.push(newRow);
        const newCashRow: { [key: string]: any } = {
          id: `${each.assetManagerId}-${idx}-cash`,
          isCash: true,
          sourceId: `${each.assetManagerId}-${idx}`,
          assetManager: 'Cash In/Out',
          hidden: true,
          style: {
            background: '#e9f8fe',
            backgroundClip: 'padding-box',
          },
        };
        Object.keys(each.cashValues).forEach((eachValue: any): void => {
          newCashRow[`${eachValue}`] = each.cashValues[eachValue];
        });
        newRows.push(newCashRow);
      });

      const newTotalRow: { [key: string]: any } = {
        assetManager: 'Total',
        id: `total-general`,
        isExpanded: false,
        hasDetails: true,
        details: ['cash'],
      };
      Object.keys(totalRow.values).forEach((eachValue: any): void => {
        newTotalRow[`${eachValue}`] = totalRow.values[eachValue];
      });
      const newTotalCashRow: { [key: string]: any } = {
        id: `total-cash`,
        isCash: true,
        sourceId: `total-general`,
        assetManager: 'Cash In/Out',
        hidden: true,
        style: {
          background: '#e9f8fe',
          backgroundClip: 'padding-box',
        },
      };
      Object.keys(totalRow.cashValues).forEach((eachValue: any): void => {
        newTotalCashRow[`${eachValue}`] = totalRow.cashValues[eachValue];
      });

      const newLables = columns.map((each: any): string => {
        const date = new Date(each);
        const columnMonth = Dates.months.find(
          (eachMonth: any): any => eachMonth.valueNumber - 1 === date.getMonth()
        );
        const columnYear = Dates.years.find(
          (eachyear: any): any => eachyear.valueNumber === date.getFullYear()
        );
        return `${(columnMonth && columnMonth.label) || ''}-${(columnYear &&
          columnYear.label) ||
          ''}`;
      });

      const newChartData = {
        label: 'Total',
        fill: false,
        borderColor: '#1976d2',
        backgroundColor: '#1976d2',
        spanGaps: true,
        data: Object.keys(totalRow.values)
          .sort((a: any, b: any): number => {
            if (a < b) return -1;
            if (b < a) return 1;
            return 0;
          })
          .map((each: any): number => {
            return totalRow.values[each] - totalRow.cashValues[each];
          }),
      };
      setChartData([newChartData]);
      setLabels(newLables);
      setTableTotalRow(newTotalRow);
      setTotalRowDetails([newTotalCashRow]);
      setTableColumns(newColumns);
      setTableRows(newRows);
      if (readOnly) {
        setTimeout(() => {
          if (getNode && cardId) getNode(containerRef, 'HISTORY', cardId);
        }, 2000);
      }
    }
  };

  const getHistoryData = (): void => {
    const selectedType = historyTypes.find(
      (eachType: any): any => eachType.label === historyType
    );

    const typeValue = selectedType?.value || '';
    if (getHistory && selectedRows && selectedColumns)
      getHistory(
        clientId,
        year,
        month,
        selectedRows,
        selectedColumns,
        typeValue
      )
        .then((inputHistory: HistoryType): void => setHistory(inputHistory))
        .catch(() => showNotification && showNotification());
  };

  useEffect(() => {
    getHistoryData();
  }, [
    selectedColumns,
    selectedRows,
    selectedDate,
    cardId,
    month,
    year,
    historyType,
  ]);

  const expand = (event: any, row: any): void => {
    const newRows = tableRows.map((r: any): any => {
      if (r.isCash && r.sourceId === row.id) {
        return { ...r, hidden: false };
      }
      if (r.id === row.id) {
        return { ...r, isExpanded: true };
      }
      return r;
    });
    setTableRows(newRows);
  };

  const unexpand = (event: any, row: any): void => {
    const newRows = tableRows.map((r: any): any => {
      if (r.isCash && r.sourceId === row.id) {
        return { ...r, hidden: true };
      }
      if (r.id === row.id) {
        return { ...r, isExpanded: false };
      }
      return r;
    });
    setTableRows(newRows);
  };

  const expandTotal = (): void => {
    const newTotalRow = { ...tableTotalRow, isExpanded: true };
    const newTotalDetails = totalRowDetails.map((each: any): any => ({
      ...each,
      hidden: false,
    }));
    setTableTotalRow(newTotalRow);
    setTotalRowDetails(newTotalDetails);
  };

  const unexpandTotal = (): void => {
    const newTotalRow = { ...tableTotalRow, isExpanded: false };
    const newTotalDetails = totalRowDetails.map((each: any): any => ({
      ...each,
      hidden: true,
    }));
    setTableTotalRow(newTotalRow);
    setTotalRowDetails(newTotalDetails);
  };

  const toggleAccountDetails = (): void => {
    const accountCodeColumn = tableColumns.find(
      (col: any): any => col.name === 'accountCode'
    );
    const accountNumberColumn = tableColumns.find(
      (col: any): any => col.name === 'accountNumber'
    );
    const accountTypeColumn = tableColumns.find(
      (col: any): any => col.name === 'accountType'
    );
    const hidden =
      accountCodeColumn.hidden &&
      accountNumberColumn.hidden &&
      accountTypeColumn.hidden;
    const newColumns = tableColumns.map((col: any): any => {
      if (
        col.name === 'accountCode' ||
        col.name === 'accountNumber' ||
        col.name === 'accountType'
      ) {
        return { ...col, hidden: !hidden };
      }
      return col;
    });
    setTableColumns(newColumns);
  };

  return (
    <div className={classnames(classes.root1, root1Class)}>
      <div
        className={classnames(classes.root, rootClass)}
        ref={readOnly ? containerRef : parentReference}
        style={{ paddingRight: readOnly ? 50 : 0 }}
      >
        <div>
          <div className={classes.topElement}>
            <div
              style={{
                position: 'absolute',
                top: '37px',
                zIndex: 420,
                display: 'flex',
              }}
            >
              <TextField
                id="outlined-select-second-status"
                select
                value={historyType}
                onChange={(e: any): void => setHistoryType(e.target.value)}
                variant="outlined"
                InputProps={{
                  classes: {
                    root: classes.selectRoot,
                    input: classes.input2,
                    notchedOutline: classes.borderClass,
                  },
                }}
                SelectProps={{
                  classes: {
                    iconOutlined: classes.dropIcon,
                  },
                }}
              >
                {historyTypes.map(
                  (option: any): ReactElement => (
                    <MenuItem
                      key={option.value}
                      value={option.label}
                      style={{
                        fontSize: '14px',
                        height: '18px',
                        padding: '2px 4px',
                        color: '#039be5',
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  )
                )}
              </TextField>
            </div>
          </div>
          <ValuesTable
            rows={tableRows}
            columns={tableColumns}
            onShrink={unexpand}
            onExpand={expand}
            onExpandTotal={expandTotal}
            onShrinkTotal={unexpandTotal}
            showPopperForHeaderCell
            totalRow={tableTotalRow}
            hasTotalRowDetails
            dontShowZero
            totalRowDetails={totalRowDetails}
            spaceBeforeTotalRow
            hasColumnCheckbox
            hasExpandColumns
            onExpandColumns={toggleAccountDetails}
            expandColumnsIcon={None}
            expandColumnsTitle="View Account Code-Type-Number"
          />
        </div>

        <div
          className={classes.rightContainer}
          style={{ marginRight: readOnly ? 30 : 0 }}
        >
          <Bar
            key={tableColumns.filter((each: any): any => each.hidden).length}
            data={{
              datasets: chartData,
              labels,
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              scaleUse2Y: true,
              legend: {
                display: false,
                position: 'bottom',
                fullWidth: false,
                labels: {
                  padding: 20,
                },
              },
              tooltips: {
                enabled: false,
              },
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Value',
                    },
                    id: '2',
                    type: 'linear',
                    position: 'left',
                    display: true,
                    stacked: true,

                    ticks: {
                      beginAtZero: true,
                      callback(value: any): string {
                        const newNumber = Number(value).toFixed(0);
                        if (newNumber !== 'NaN') {
                          return newNumber
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                        return '';
                      },
                    },
                  },
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Month',
                    },
                    barPercentage: 0.6,
                    display: true,
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              plugins: {
                labels: false,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

History.defaultProps = {
  history: undefined,
  readOnly: false,
  parentReference: undefined,
  getNode: undefined,
  cardId: undefined,
  selectedDate: undefined,
  selectedColumns: [],
  selectedRows: [],
  showNotification: undefined,
};

export default History;
