import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EnvironmentActions from '../../actions/EnvironmentActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import Environments from './Environments';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    { ...EnvironmentActions, ...DialogActions, ...UserActions },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  loading: state.Environment.loading,
  environments: state.Environment.environments,
  success: state.Environment.success,
  deleted: state.Environment.deleted,
  error: state.Environment.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(Environments);
