import * as types from '../constants/Overview';

const Overview = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.GET_CLIENTS_OVERVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_CLIENTS_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        clientsOverview: action.clientsOverview,
      };
    case types.GET_CLIENTS_OVERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.GET_SECURITIES_OVERVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SECURITIES_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        securitiesOverview: action.securitiesOverview,
      };
    case types.GET_SECURITIES_OVERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.GET_ASSET_MANAGERS_OVERVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ASSET_MANAGERS_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        assetManagersOverview: action.assetManagersOverview,
      };
    case types.GET_ASSET_MANAGERS_OVERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default Overview;
