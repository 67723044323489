import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const monitor = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>,
  benchmarkIds: Array<number | string>
): any =>
  Request(`assettable/monitor`, 'POST', {
    year,
    month,
    id: clientId,
    disabledColumns,
    enabledRows,
    benchmarkIds,
  })
    .then(ErrorHandler)
    .then((monitorData: any): any => monitorData);

const getMonitorRow = (
  year: number,
  month: number,
  benchmarkId: number,
  monthCount: number
): any =>
  Request(`assettable/monitor/benchmark`, 'POST', {
    year,
    month,
    benchmarkId,
    monthCount,
  })
    .then(ErrorHandler)
    .then((monitorRow: any): any => monitorRow);

const MonitorService = {
  monitor,
  getMonitorRow,
};

export default MonitorService;
