import React, { useState, useEffect, ReactElement } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import { Pie } from 'react-chartjs-2';
import TabsComponent from '../../containers/Tabs';
import DatePicker from '../../components/DatePicker';
import styles from './styles';
import SecurityValue from './SecurityValue';
import Footer from '../../containers/Footer';
import {
  SecurityType,
  SecurityValuesType,
  AddSecurityValueType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface StartDateObj {
  year: number;
  month: number;
}

interface Props {
  match: any;
  getSecurityValues: (id: number, year: number, month: number) => void;
  securityValues: SecurityValuesType;
  security: SecurityType;
  getSecurityItem: (id: number) => void;
  saveSecurityValues: (obj: AddSecurityValueType) => void;
  loading: boolean;
  success: boolean;
  error: any;
  history: any;
  getSecurityStartDate: (id: number) => Promise<StartDateObj>;
  setYear: (value: number) => void;
  setMonth: (value: number) => void;
  selectedMonth: number;
  selectedYear: number;
}

let stallingTime = 0; // don't ask about it :)

const Security = (props: Props): ReactElement => {
  const {
    match: {
      params: { securityId },
    },
    getSecurityItem,
    history,
    securityValues,
    getSecurityValues,
    selectedMonth,
    selectedYear,
    saveSecurityValues,
    setYear,
    setMonth,
    getSecurityStartDate,
    loading,
    success,
    error,
    security,
  } = props;

  const [tabNumber, setTabNumber] = useState<number>(1);
  const [secValues, setSecValues] = useState<SecurityValuesType | undefined>(
    undefined
  );
  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [focusedIndex, setFocusedIndex] = useState<any>(undefined);
  const [footerPos, setFooterPos] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    getSecurityValues(securityId, selectedYear, selectedMonth);
    getSecurityItem(securityId);
  }, [getSecurityItem, securityId, selectedMonth, selectedYear]);

  useEffect(() => {
    setSecValues(securityValues);
  }, [securityValues]);

  const changeTab = (value: number): void => {
    setTabNumber(value);
    setFocusedIndex(undefined);
  };

  const handleChangeValues = (type: string) => (
    name: string,
    value: number
  ): void => {
    if (secValues) {
      const newSecValues: SecurityValuesType = { ...secValues };
      if (type === 'assetClassSetsValues') {
        const selectedACSIndex = newSecValues.assetClassSetsValues.findIndex(
          (acs: any): any => acs.pid + 4 === tabNumber
        );
        const valueIndex = newSecValues.assetClassSetsValues[
          selectedACSIndex
        ].values.findIndex((obj: any): any => obj.name === name);
        newSecValues.assetClassSetsValues[selectedACSIndex].values[
          valueIndex
        ].value = Number(value);
      } else {
        const index = newSecValues[type].findIndex(
          (obj: any): any => obj.name === name
        );
        newSecValues[type][index].value = value;
      }
      setSecValues(newSecValues);
    }
  };

  const save = (): void => {
    saveSecurityValues({
      ...secValues,
      year: selectedYear,
      month: selectedMonth,
    });
    setOpenNotification(true);
    setFocusedIndex(undefined);
  };

  const setColor = (number: number): any => {
    const colors = [
      '#f2665a',
      '#f8964e',
      '#faad44',
      '#fdc43a',
      '#ffd842',
      '#fde354',
      '#f9ef6d',
      '#d8e35b',
      '#add660',
      '#77c973',
      '#77ceab',
      '#72d0d0',
      '#6acff5',
      '#71b0dd',
      '#8398cc',
      '#a27fbc',
      '#bd7db9',
      '#d07eb8',
      '#e784ba',
      '#f3a0c7',
      '#f1f7b9',
      '#dfb8bb',
      '#aae38f',
      '#4cb224',
    ];
    const matrix = [];

    if (number <= 0) {
      return [];
    }
    if (number >= 1) {
      const color = colors[9];
      matrix[18] = color;
    }
    if (number >= 2) {
      const color = colors[0];
      matrix[0] = color;
    }
    if (number >= 3) {
      const color = colors[2];
      matrix[2] = color;
    }
    if (number >= 4) {
      const color = colors[8];
      matrix[17] = color;
    }
    if (number >= 5) {
      const color = colors[4];
      matrix[4] = color;
    }
    if (number >= 6) {
      const color1 = colors[1];
      const color2 = colors[7];
      matrix[1] = color1;
      matrix[4] = color2;
    }
    if (number >= 7) {
      const color1 = colors[4];
      const color2 = colors[7];
      matrix[4] = color1;
      matrix[16] = color2;
    }
    if (number >= 8) {
      const color = colors[6];
      matrix[5] = color;
    }
    if (number >= 9) {
      const color = colors[3];
      matrix[3] = color;
    }
    if (number >= 10) {
      const color = colors[20];
      matrix[6] = color;
    }
    if (number >= 11) {
      const color1 = colors[22];
      const color2 = colors[23];
      matrix[16] = color1;
      matrix[19] = color2;
    }
    if (number >= 12) {
      const color1 = colors[12];
      const color2 = colors[10];
      matrix[14] = color1;
      matrix[15] = color2;
      matrix[17] = null;
    }
    if (number >= 13) {
      const color = colors[8];
      matrix[17] = color;
    }
    if (number >= 14) {
      const color = colors[13];
      matrix[13] = color;
    }
    if (number >= 15) {
      const color = colors[14];
      matrix[12] = color;
    }
    if (number >= 16) {
      const color = colors[15];
      matrix[11] = color;
    }
    if (number >= 17) {
      const color1 = colors[21];
      const color2 = colors[19];
      const color3 = colors[18];
      matrix[7] = color1;
      matrix[8] = color2;
      matrix[9] = color3;
      matrix[12] = null;
      matrix[16] = null;
    }
    if (number >= 18) {
      const color = colors[22];
      matrix[16] = color;
    }
    if (number >= 19) {
      const color = colors[16];
      matrix[10] = color;
    }
    if (number >= 20) {
      const color = colors[14];
      matrix[12] = color;
    }

    const result = matrix.reverse().filter(each => typeof each === 'string');

    return result;
  };

  const setStart = (): void => {
    getSecurityStartDate(securityId).then((date: StartDateObj): void => {
      setMonth(date.month);
      setYear(date.year);
    });
  };

  const selectedACS =
    securityValues &&
    securityValues.assetClassSetsValues &&
    securityValues.assetClassSetsValues.find(
      (acs: any): any => acs.pid + 4 === tabNumber
    );

  const tabNames = ['General', 'Regions', 'Currencies', 'Extra Data'];
  const tabValues = [1, 2, 3, 4];

  if (securityValues && securityValues.assetClassSetsValues) {
    securityValues.assetClassSetsValues.forEach((acs: any): void => {
      tabNames.push(acs.name);
      tabValues.push(acs.pid + 4);
    });
  }

  return (
    <div className={classes.root}>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Toolbar className={classes.toolbar}>
          <div style={{ display: 'inherit', flex: 1, alignItems: 'center' }}>
            <Tooltip title="Back">
              <IconButton
                onClick={(): void => history.goBack()}
                classes={{ root: classes.backRotButton }}
                color="inherit"
              >
                <LeftIcon />
              </IconButton>
            </Tooltip>
            <Typography
              variant="h1"
              id="tableTitle"
              style={{ paddingTop: '2px' }}
            >
              {security && security.name}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              margin: '0 20px 5px 0',
              alignItems: 'inherit',
            }}
          >
            <DatePicker setStart={setStart} />
          </div>
          <Button
            onClick={save}
            variant="outlined"
            className={classes.addButton}
          >
            Save
          </Button>
        </Toolbar>
        <div style={{ height: '100%' }}>
          <TabsComponent
            selectedTab={tabNumber}
            changeTab={changeTab}
            tabs={tabNames}
            values={tabValues}
          />
          <div
            onScroll={(event: any): void => {
              if (
                event.target.scrollLeft < 1000 &&
                new Date().getTime() - stallingTime > 100
              ) {
                setFooterPos(event.target.scrollLeft);
                stallingTime = 0;
              }

              if (event.target.scrollWidth > 10000) {
                stallingTime = new Date().getTime();
              }
            }}
            className={classes.tabsValue}
          >
            {tabNumber === 1 && (
              <div className={classes.generalTab}>
                <div style={{ width: '100%' }}>
                  <Typography variant="subtitle2" className={classes.title}>
                    Name:{' '}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.detail}
                    style={{ left: 200 }}
                  >
                    {security && security.name}
                  </Typography>
                  <Divider className={classes.divider} />
                  <Typography variant="subtitle2" className={classes.title}>
                    Display Name:{' '}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.detail}
                    style={{ left: 150 }}
                  >
                    {security && security.display}
                  </Typography>
                  <Divider className={classes.divider} />
                  <Typography variant="subtitle2" className={classes.title}>
                    ISIN:{' '}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.detail}
                    style={{ left: 210 }}
                  >
                    {security && security.isin}
                  </Typography>
                </div>
              </div>
            )}
            {tabNumber === 2 && (
              <div className={classes.parent}>
                <div className={classes.leftContainer}>
                  {securityValues &&
                    securityValues.regionValues &&
                    securityValues.regionValues.map(
                      (value: any, idx: number): any => (
                        <SecurityValue
                          key={value.pid}
                          valueItem={value}
                          index={idx}
                          switchField={(index: number): void =>
                            setFocusedIndex(index + 1)
                          }
                          focusedIndex={focusedIndex}
                          handleChangeValues={handleChangeValues(
                            'regionValues'
                          )}
                        />
                      )
                    )}

                  <div
                    style={{
                      margin: '10px 10px 50px 10px',
                      display: 'flex',
                    }}
                  >
                    <Typography variant="h6" className={classes.totalTitle}>
                      Total:{' '}
                    </Typography>
                    <TextField
                      disabled
                      InputProps={{
                        classes: {
                          root: classes.textfieldRoot,
                          input:
                            // eslint-disable-next-line no-nested-ternary
                            securityValues &&
                            securityValues.regionValues &&
                            securityValues.regionValues
                              .map((each: any): any => each.value)
                              .reduce(
                                (
                                  total: number,
                                  each: number | string
                                ): number => Number(total) + Number(each)
                              ) > 100
                              ? classes.totalRed
                              : securityValues &&
                                securityValues.regionValues &&
                                securityValues.regionValues
                                  .map((each: any): any => each.value)
                                  .reduce(
                                    (
                                      total: number,
                                      each: number | string
                                    ): number => Number(total) + Number(each)
                                  ) === 100
                              ? classes.totalGreen
                              : classes.rootInput,
                        },
                      }}
                      className={classnames(classes.totalValue)}
                      value={
                        securityValues &&
                        securityValues.regionValues &&
                        securityValues.regionValues
                          .map((each: any): any => each.value)
                          .reduce(
                            (total: number, each: number | string): number =>
                              Number(total) + Number(each)
                          ) === 0
                          ? ''
                          : (
                              Math.round(
                                securityValues &&
                                  securityValues.regionValues
                                    .map((each: any): any => each.value)
                                    .reduce(
                                      (
                                        total: number,
                                        each: number | string
                                      ): number => Number(total) + Number(each)
                                    ) * 100
                              ) / 100
                            ).toFixed(2)
                      }
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className={classes.rightContainer}>
                  <Pie
                    data={{
                      datasets: [
                        {
                          data: (securityValues &&
                            securityValues.regionValues &&
                            securityValues.regionValues.map(
                              (each: any): any => each.value
                            )) || [1],
                          backgroundColor:
                            securityValues && securityValues.regionValues
                              ? setColor(securityValues.regionValues.length)
                              : ['#D3D3D3'],
                        },
                      ],
                      labels: (securityValues &&
                        securityValues.regionValues &&
                        securityValues.regionValues.map(
                          (each: any): string => each.name
                        )) || ['No Data'],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      legend: {
                        display: false,
                      },

                      plugins: {
                        labels: {
                          render:
                            securityValues && securityValues.regionValues
                              ? 'percentage'
                              : 'label',
                          fontColor: 'white',
                          precision: 2,
                          fontSize: 11,
                          position: 'border',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            )}
            {tabNumber === 3 && (
              <div className={classes.parent}>
                <div className={classes.leftContainer}>
                  {securityValues &&
                    securityValues.currencyValues &&
                    securityValues.currencyValues.map(
                      (value: any, idx: number): any => (
                        <SecurityValue
                          key={value.pid}
                          valueItem={value}
                          index={idx}
                          switchField={(index: number): void =>
                            setFocusedIndex(index + 1)
                          }
                          focusedIndex={focusedIndex}
                          handleChangeValues={handleChangeValues(
                            'currencyValues'
                          )}
                        />
                      )
                    )}

                  <div
                    style={{
                      margin: '10px 10px 50px 10px',
                      display: 'flex',
                    }}
                  >
                    <Typography variant="h6" className={classes.totalTitle}>
                      Total:{' '}
                    </Typography>
                    <TextField
                      disabled
                      InputProps={{
                        classes: {
                          root: classes.textfieldRoot,
                          input:
                            // eslint-disable-next-line no-nested-ternary
                            securityValues &&
                            securityValues.currencyValues &&
                            securityValues.currencyValues
                              .map((each: any): any => each.value)
                              .reduce(
                                (
                                  total: number,
                                  each: number | string
                                ): number => Number(total) + Number(each)
                              ) > 100
                              ? classes.totalRed
                              : securityValues &&
                                securityValues.currencyValues &&
                                securityValues.currencyValues
                                  .map((each: any): any => each.value)
                                  .reduce(
                                    (
                                      total: number,
                                      each: number | string
                                    ): number => Number(total) + Number(each)
                                  ) === 100
                              ? classes.totalGreen
                              : classes.rootInput,
                        },
                      }}
                      className={classnames(classes.totalValue)}
                      value={
                        securityValues &&
                        securityValues.currencyValues &&
                        securityValues.currencyValues
                          .map((each: any): any => each.value)
                          .reduce(
                            (total: number, each: number | string): number =>
                              Number(total) + Number(each)
                          ) === 0
                          ? ''
                          : (
                              Math.round(
                                securityValues &&
                                  securityValues.currencyValues
                                    .map((each: any): any => each.value)
                                    .reduce(
                                      (
                                        total: number,
                                        each: number | string
                                      ): number => Number(total) + Number(each)
                                    ) * 100
                              ) / 100
                            ).toFixed(2)
                      }
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className={classes.rightContainer}>
                  <Pie
                    data={{
                      datasets: [
                        {
                          data: (securityValues &&
                            securityValues.currencyValues &&
                            securityValues.currencyValues.map(
                              (each: any): any => each.value
                            )) || [1],
                          backgroundColor:
                            securityValues && securityValues.currencyValues
                              ? setColor(securityValues.currencyValues.length)
                              : ['#D3D3D3'],
                        },
                      ],
                      labels: (securityValues &&
                        securityValues.currencyValues &&
                        securityValues.currencyValues.map(
                          (each: any): string => each.name
                        )) || ['No Data'],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      legend: {
                        display: false,
                      },

                      plugins: {
                        labels: {
                          render:
                            securityValues && securityValues.currencyValues
                              ? 'percentage'
                              : 'label',
                          fontColor: 'white',
                          precision: 2,
                          fontSize: 11,
                          position: 'border',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            )}
            {tabNumber === 4 && (
              <div className={classes.parent}>
                <div className={classes.leftContainer}>
                  {securityValues &&
                    securityValues.extraData &&
                    securityValues.extraData.map(
                      (value: any, idx: number): any => (
                        <SecurityValue
                          key={value.name}
                          valueItem={value}
                          index={idx}
                          switchField={(index: number): void =>
                            setFocusedIndex(index + 1)
                          }
                          focusedIndex={focusedIndex}
                          type="text"
                          handleChangeValues={handleChangeValues('extraData')}
                        />
                      )
                    )}
                </div>
                <div className={classes.rightContainer}>
                  <Pie
                    data={{
                      datasets: [
                        {
                          data: (securityValues &&
                            securityValues.extraData &&
                            securityValues.extraData.map(
                              (each: any): any => each.value
                            )) || [1],
                          backgroundColor:
                            securityValues && securityValues.extraData
                              ? setColor(securityValues.extraData.length)
                              : ['#D3D3D3'],
                        },
                      ],
                      labels: (securityValues &&
                        securityValues.extraData &&
                        securityValues.extraData.map(
                          (each: any): string => each.name
                        )) || ['No Data'],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      legend: {
                        display: false,
                      },

                      plugins: {
                        labels: {
                          render:
                            securityValues && securityValues.extraData
                              ? 'percentage'
                              : 'label',
                          fontColor: 'white',
                          precision: 2,
                          fontSize: 11,
                          position: 'border',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            )}
            {selectedACS && tabNumber === selectedACS.pid + 4 && (
              <div className={classes.parent}>
                <div className={classes.leftContainer}>
                  {selectedACS.values.map((value: any, idx: number): any => (
                    <SecurityValue
                      key={value.pid}
                      valueItem={value}
                      index={idx}
                      switchField={(index: number): void =>
                        setFocusedIndex(index + 1)
                      }
                      focusedIndex={focusedIndex}
                      handleChangeValues={handleChangeValues(
                        'assetClassSetsValues'
                      )}
                    />
                  ))}

                  <div
                    style={{
                      margin: '10px 10px 50px 10px',
                      display: 'flex',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      className={classes.totalTitle}
                    >
                      Total:{' '}
                    </Typography>
                    <TextField
                      disabled
                      InputProps={{
                        classes: {
                          root: classes.textfieldRoot,
                          input:
                            // eslint-disable-next-line no-nested-ternary
                            selectedACS &&
                            selectedACS.values &&
                            selectedACS.values
                              .map((each: any): any => each.value)
                              .reduce(
                                (
                                  total: number,
                                  each: number | string
                                ): number => Number(total) + Number(each)
                              ) > 100
                              ? classes.totalRed
                              : selectedACS &&
                                selectedACS.values &&
                                selectedACS.values
                                  .map((each: any): any => each.value)
                                  .reduce(
                                    (
                                      total: number,
                                      each: number | string
                                    ): number => Number(total) + Number(each)
                                  ) === 100
                              ? classes.totalGreen
                              : classes.rootInput,
                        },
                      }}
                      className={classnames(classes.totalValue)}
                      value={
                        selectedACS &&
                        selectedACS.values &&
                        selectedACS.values
                          .map((each: any): any => each.value)
                          .reduce(
                            (total: number, each: number | string): number =>
                              Number(total) + Number(each)
                          ) === 0
                          ? ''
                          : (
                              Math.round(
                                selectedACS &&
                                  selectedACS.values
                                    .map((each: any): any => each.value)
                                    .reduce(
                                      (
                                        total: number,
                                        each: number | string
                                      ): number => Number(total) + Number(each)
                                    ) * 100
                              ) / 100
                            ).toFixed(2)
                      }
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className={classes.rightContainer}>
                  <Pie
                    data={{
                      datasets: [
                        {
                          data: (selectedACS &&
                            selectedACS.values &&
                            selectedACS.values.map(
                              (each: any): any => each.value
                            )) || [1],
                          backgroundColor:
                            selectedACS && selectedACS.values
                              ? setColor(selectedACS.values.length)
                              : ['#D3D3D3'],
                        },
                      ],
                      labels: (selectedACS &&
                        selectedACS.values &&
                        selectedACS.values.map(
                          (each: any): string => each.name
                        )) || ['No Data'],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      legend: {
                        display: false,
                      },

                      plugins: {
                        labels: {
                          render:
                            selectedACS && selectedACS.values
                              ? 'percentage'
                              : 'label',
                          fontColor: 'white',
                          precision: 2,
                          fontSize: 11,
                          position: 'border',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            )}

            <Footer
              style={{
                marginTop: 50,
                width: `calc(100% + ${footerPos > 1000 ? 0 : footerPos}px)`,
              }}
              textStyle={{ marginLeft: footerPos }}
            />
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openNotification && !loading}
        autoHideDuration={3000}
        onClose={(): void => setOpenNotification(false)}
      >
        <div>
          {success && (
            <SnackbarContent
              className={classnames(classes.success)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <CheckCircleIcon
                    className={classnames(classes.icon, classes.iconVariant)}
                  />
                  Values updated successfully
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={(): void => setOpenNotification(false)}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
            />
          )}
          {!success && (
            <SnackbarContent
              className={classnames(classes.error)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <ErrorIcon
                    className={classnames(classes.icon, classes.iconVariant)}
                  />
                  Error: {error && error.messages && error.messages[0]}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={(): void => setOpenNotification(false)}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
            />
          )}
        </div>
      </Snackbar>
    </div>
  );
};

Security.defaultProps = {
  security: undefined,
  securityValues: undefined,
  securityStartDate: undefined,
  success: undefined,
  error: undefined,
  loading: undefined,
};

export default Security;
