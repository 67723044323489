import React, { useState, useEffect, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Dialog from '../../containers/Dialog';
import DataTable from '../../containers/DataTable';
import DialogForm from './DialogForm';
import styles from './styles';
import {
  AssetClassType,
  AssetClassSetType,
  ExportType,
  UserType,
  RiskClassType,
  BenchmarkType,
  MutateAssetClassType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  assetClassSetId: number;
  assetClassSet: AssetClassSetType;
  assetClasses?: { content: Array<AssetClassType> };
  riskClasses?: { content: Array<RiskClassType> };
  openDialog?: () => void;
  closeDialog?: () => void;
  removeAssetClass?: (id: number) => void;
  getAssetClassesList?: (id: number) => void;
  createAssetClass?: (obj: MutateAssetClassType, id: number) => void;
  updateAssetClass?: (
    obj: MutateAssetClassType,
    id: number,
    dataId: number
  ) => void;
  getRiskClassesList?: (id: number) => void;
  error?: any;
  success?: boolean;
  loading?: boolean;
  deleted?: boolean;
  getUserItem?: (id: number) => Promise<UserType>;
  exportAssetClassesTable?: (id: number) => Promise<ExportType>;
  benchmarks?: { content: Array<BenchmarkType> };
  getList?: () => void;
}

const AssetClass = (props: Props): ReactElement => {
  const {
    getAssetClassesList,
    getRiskClassesList,
    assetClassSetId,
    openDialog,
    closeDialog,
    removeAssetClass,
    exportAssetClassesTable,
    riskClasses,
    assetClassSet,
    assetClasses,
    createAssetClass,
    updateAssetClass,
    loading,
    error,
    success,
    deleted,
    getUserItem,
    benchmarks,
    getList,
    ...others
  } = props;

  const [selectedRow, setSelectedRow] = useState<AssetClassType | undefined>(
    undefined
  );
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [model, setModel] = useState<AssetClassType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (getAssetClassesList) getAssetClassesList(assetClassSetId);
    if (getRiskClassesList) getRiskClassesList(assetClassSetId);
    if (getList) getList();
  }, [getAssetClassesList, getRiskClassesList, assetClassSetId, getList]);

  const openAdd = (): void => {
    if (openDialog) openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const openEdit = (event: any, row: AssetClassType): void => {
    if (openDialog) openDialog();
    setOpen(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    if (closeDialog) closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: AssetClassType): void => {
    if (openDialog) openDialog();
    setOpenDelete(true);
    setSelectedRow(row);
  };

  const handleClose = (): void => {
    if (closeDialog) closeDialog();
    setOpen(false);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (removeAssetClass && selectedRow) removeAssetClass(selectedRow.id);
  };

  const exportTable = (): void => {
    if (exportAssetClassesTable)
      exportAssetClassesTable(assetClassSetId).then((res: ExportType): void => {
        const csvData = new Blob([res.export], {
          type: 'text/csv;charset=utf-8;',
        });
        const csvURL = window.URL.createObjectURL(csvData);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', res.fileName || 'AssetClasses.csv');
        tempLink.click();
      });
  };

  const columns = [
    {
      title: 'Rank No',
      name: 'rankno',
      sortable: true,
      type: 'number',
      sortField: 'rankno',
    },
    {
      title: 'Description',
      name: 'description',
      sortable: true,
      type: 'string',
      sortField: 'description',
    },
    {
      title: 'Column Heading',
      name: 'columnHeading',
      type: 'string',
      sortable: true,
      sortField: 'columnHeading',
    },
    {
      title: 'Risk Factor',
      name: 'riskFactor',
      type: 'number',
      sortable: true,
      sortField: 'riskFactor',
    },
    {
      title: 'Return Factor',
      name: 'returnFactor',
      type: 'number',
      sortable: true,
      sortField: 'returnFactor',
    },
    {
      title: 'Risk Class',
      name: 'refRiskClass.name',
      type: 'string',
      sortable: true,
      sortField: 'refRiskClass.name',
    },
    {
      title: 'Benchmark',
      name: 'refBenchmark.name',
      type: 'string',
      sortable: true,
      sortField: 'refBenchmark.name',
    },
  ];

  const editedAssetClasses =
    (assetClasses &&
      assetClasses.content &&
      assetClasses.content.map((eachAssetClass: any): any => ({
        ...eachAssetClass,
        returnFactor: `${(
          Math.round(eachAssetClass.returnFactor * 100) / 100
        ).toFixed(2)}`,
        riskFactor: `${(
          Math.round(eachAssetClass.riskFactor * 100) / 100
        ).toFixed(2)}`,
      }))) ||
    [];
  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          hasEditAndDelete
          noMargin
          onEdit={openEdit}
          onDelete={handleDeleteOpen}
          onAdd={openAdd}
          columns={columns}
          hasExportButton
          onExport={exportTable}
          rows={editedAssetClasses}
          entity="Asset Class"
          entities="Asset Classes"
          {...others}
        />
      </div>
      <DialogForm
        model={model}
        open={open}
        onClose={handleClose}
        update={updateAssetClass}
        create={createAssetClass}
        assetClassSet={assetClassSet}
        assetClassSetId={assetClassSetId}
        riskClasses={(riskClasses && riskClasses.content) || []}
        benchmarks={(benchmarks && benchmarks.content) || []}
        loading={loading}
        success={success}
        error={error}
        getUserItem={getUserItem}
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {isRemoving && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Asset Class removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error:{' '}
              {(error && error.messages && error.messages[0]) || 'Unknown'}
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};

AssetClass.defaultProps = {
  deleted: false,
  success: false,
  riskClasses: undefined,
  assetClasses: undefined,
  assetClassSet: undefined,
  error: undefined,
  loading: false,
};

export default AssetClass;
