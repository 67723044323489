import React, { ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Paper from '@material-ui/core/Paper';
import drawerActions from '../../actions/DrawerActions';
import { ClientType } from '../../Types';

const styles = (): any => ({
  centredDiv: {
    textAlign: 'center',
    flex: 1,
    margin: 'auto',
  },
  searchIcon: { width: 50, height: 50, color: '#bdbdbd', marginBottom: 20 },
  topText: { fontSize: 24, color: '#424242', fontWeight: 'bold' },
  buttText: { fontSize: 16, color: '#0000008a', fontWeight: '500' },
});

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  openDrawer: () => void;
  closeDrawer: () => void;
  client: ClientType;
  history: any;
}

const Home = (props: Props): ReactElement => {
  const { openDrawer, client, history, closeDrawer } = props;
  const classes = useStyles();

  if (client) {
    closeDrawer();
    history.push(client ? `/selected-client/${client.id}` : '/');
    return <div />;
  }
  openDrawer();
  return (
    <div style={{ height: 'calc(100% - 20px)', padding: 10 }}>
      <Paper style={{ height: '100%', display: 'flex' }}>
        <div className={classes.centredDiv}>
          <SearchIcon className={classes.searchIcon} />
          <Typography className={classes.topText}>
            No client selected. Please select one.
          </Typography>
          <Typography className={classes.buttText}>
            Use search bar on top of the screen
          </Typography>
        </div>
      </Paper>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(drawerActions, dispatch);
const mapStateToProps = (state: any): any => ({
  client: state.Client.selectedClient,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home);
