import * as types from '../constants/Dialog';

const Dialog = (state: any, action: any): any => {
  switch (action.type) {
    case types.DIALOG_OPEN:
      return {
        ...state,
        open: true,
      };

    case types.DIALOG_CLOSE:
      return {
        ...state,
        open: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default Dialog;
