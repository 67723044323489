import React, { useState, useEffect, ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import LensIcon from '@material-ui/icons/Lens';
import Dates from '../../constants/Date';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import { UserType, MutateRiskClassType } from '../../Types';
// import color picker and add it to form

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  onClose: () => void;
  model: any;
  open: boolean;
  update?: (obj: MutateRiskClassType, id: number, dataId: number) => void;
  create?: (obj: MutateRiskClassType, id: number) => void;
  assetClassSetId: number;
  success: boolean;
  loading?: boolean;
  error: any;
  getUserItem?: (id: number) => Promise<UserType>;
}

const DialogForm = (props: Props): ReactElement => {
  const {
    model,
    open,
    onClose,
    getUserItem,
    update,
    create,
    assetClassSetId,
    loading,
    success,
    error,
  } = props;

  const [name, setName] = useState<string>('');
  const [color, setColor] = useState<string>('');
  const [rankno, setRankno] = useState<number | string | undefined>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [modifyDate, setModifyDate] = useState<string>('');
  const [relatedUsername, setRelatedUsername] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (!model || !open) return;
    setRankno(model.rankno);
    setName(model.name);
    setColor(model.color);
  }, [model, open]);

  useEffect(() => {
    if (!model || !open) return;
    const { lastModifiedBy, lastModifiedDate, createdDate } = model;
    if (lastModifiedBy && lastModifiedDate !== undefined) {
      if (getUserItem)
        getUserItem(lastModifiedBy).then((user: UserType) => {
          if (lastModifiedDate === createdDate) {
            const date = new Date(lastModifiedDate);
            const monthObj = Dates.months.find(
              each => each.valueNumber === date.getMonth() + 1
            );
            const month = monthObj && monthObj.value;
            const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
            setIsUpdated(true);
            setRelatedUsername(user.name);
            setModifyDate(newModifyDate);
          } else {
            const date = new Date(lastModifiedDate);
            const monthObj = Dates.months.find(
              each => each.valueNumber === date.getMonth() + 1
            );
            const month = monthObj && monthObj.value;
            const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
            setIsUpdated(false);
            setRelatedUsername(user.name);
            setModifyDate(newModifyDate);
          }
        });
    } else {
      setIsUpdated(false);
      setModifyDate('');
      setRelatedUsername('');
    }
  }, [open, model, getUserItem]);

  const handleClose = (): void => {
    setName('');
    setColor('');
    setRankno('');
    setShowNotification(false);
    setShowError(false);
    setIsUpdated(false);
    setModifyDate('');
    setRelatedUsername('');
    onClose();
  };

  const canSubmit = (): boolean => {
    if (!name || !rankno || !color) return false;
    return true;
  };

  const submit = (): void => {
    if (canSubmit()) {
      if (model?.id && update) {
        update(
          {
            name,
            color,
            // eslint-disable-next-line radix
            rankno: parseInt(String(rankno)),
            refAssetClassSetId: assetClassSetId,
          },
          assetClassSetId,
          model.id
        );
      } else if (create)
        create(
          {
            name,
            refAssetClassSetId: assetClassSetId,
            color,
            // eslint-disable-next-line radix
            rankno: parseInt(String(rankno)),
          },
          assetClassSetId
        );
      setShowNotification(true);
    } else {
      setShowError(true);
    }
  };

  const retry = (): void => {
    setShowNotification(false);
  };

  let actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions = [
      {
        label: 'Back',
        onClick: retry,
      },
      {
        label: 'Cancel',
        onClick: handleClose,
        type: 'secondary',
      },
    ];
  }

  const colors = [
    { label: 'Color 1', value: '#77c973' },
    { label: 'Color 2', value: '#d8e35b' },
    { label: 'Color 3', value: '#f9ef6d' },
    { label: 'Color 4', value: '#ffd842' },
    { label: 'Color 5', value: '#f8964e' },
    { label: 'Color 6', value: '#f2665a' },
  ];
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      contentClassName={classes.dialogContent}
      actions={actions}
      title={model && model.id ? 'Edit Risk Class' : 'New Risk Class'}
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Risk Class {model && model.id ? 'updated' : 'added'} successfully.
          </Typography>
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error:{' '}
            {error &&
              error.messages &&
              Array.isArray(error.messages) &&
              error.messages[0]}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div className={classes.formContainer}>
          <TextField
            error={showError && !name}
            id="outlined-code"
            label="Name"
            value={name}
            onChange={(event: any): void => setName(event.target.value)}
            margin="normal"
            variant="outlined"
            helperText={showError && !name && 'Required'}
            className={classes.fullField}
          />
          <TextField
            id="outlined-code"
            label="Rank No."
            value={rankno}
            type="number"
            onChange={(event: any): void => setRankno(event.target.value)}
            helperText={showError && !rankno && 'Required'}
            error={showError && !rankno}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
          />
          <TextField
            id="outlined-select-assetClassSet"
            select
            error={showError && !color}
            label="Color"
            value={color || ''}
            onChange={(event: any): void => setColor(event.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
            helperText={showError && !color && 'Required'}
          >
            {colors.map(option => (
              <MenuItem key={option.value} value={option.value}>
                <LensIcon
                  style={{
                    color: option.value,
                    fontSize: 20,
                    marginRight: 8,
                  }}
                />
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {relatedUsername !== '' && (
            <Typography variant="subtitle2" className={classes.info}>
              {isUpdated
                ? `Updated by: ${relatedUsername} on ${modifyDate}`
                : `Created by: ${relatedUsername} on ${modifyDate}`}
            </Typography>
          )}
        </div>
      )}
    </Dialog>
  );
};

DialogForm.defaultProps = {
  model: undefined,
  error: undefined,
  success: false,
};

export default DialogForm;
