export const CORRELATION_MATRIX_REQUEST = 'CORRELATION_MATRIX_REQUEST';
export const CORRELATION_MATRIX_SUCCESS = 'CORRELATION_MATRIX_SUCCESS';
export const CORRELATION_MATRIX_FAILURE = 'CORRELATION_MATRIX_FAILURE';
export const SAVE_CORRELATION_REQUEST = 'SAVE_CORRELATION_REQUEST';
export const SAVE_CORRELATION_SUCCESS = 'SAVE_CORRELATION_SUCCESS';
export const SAVE_CORRELATION_FAILURE = 'SAVE_CORRELATION_FAILURE';
export const CHANGE_CORRELATION_STATUS_REQUEST =
  'CHANGE_CORRELATION_STATUS_REQUEST';
export const CHANGE_CORRELATION_STATUS_SUCCESS =
  'CHANGE_CORRELATION_STATUS_SUCCESS';
export const CHANGE_CORRELATION_STATUS_FAILURE =
  'CHANGE_CORRELATION_STATUS_FAILURE';
