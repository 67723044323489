import createStyles from '@material-ui/core/styles/createStyles';

const styles = (): any =>
  createStyles({
    tabs: {
      width: 'calc(100% )',
    },
    tab: {
      minWidth: 120,
      borderBottom: '1px solid #00000026',
      color: 'rgb(0,0,0,0.7)',
    },
    tabsValue: {
      minHeight: '100%',
      height: 'unset',
      overflowX: 'auto',
      marginRight: '20px',
      overflowY: 'hidden',
    },
    invisibleIndicator: { backgroundColor: 'white' },
    tabsPadding: { paddingTop: 4 },
    selectedTab: {
      backgroundColor: 'white',
      boxShadow: '0px 1px 6px 0px #0000004d',
      borderRadius: '6px 6px 0 0',
      color: '#039be5 !important',
    },
    invisTab: {
      flex: '1 0 auto',
      borderBottom: '1px solid #00000026',
      maxWidth: '2000px',
    },
    rightTab: {
      backgroundColor: 'white',
      boxShadow: '0px 1px 6px 0px #0000004d',
      borderRadius: '6px 6px 0 0',
      minWidth: '60px',
    },
    leftInvisTab: {
      flex: '1 0 auto',
      borderBottom: '1px solid #00000026',
      maxWidth: '20px',
    },
    invisRoot: {
      minWidth: '22px',
      maxWidth: '22px',
      width: '22px',
    },
    badge: {
      textAlign: 'center',
      borderRadius: '50%',
      lineHeight: '20px',
      fontSize: 11,
      color: '#1976d2',
      backgroundColor: '#0000000d',
      marginTop: '11px',
      marginRight: '-15px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    tabLabelContainer: {
      padding: '6px 30px',
    },
  });

export default styles;
