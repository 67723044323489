import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const list = (): any =>
  Request('client', 'GET')
    .then(ErrorHandler)
    .then((clients: any): any => clients);

const item = (id: any): any =>
  Request(`client/${id}`, 'GET')
    .then(ErrorHandler)
    .then((client: any): any => client);

const create = (data: any): any =>
  Request('client', 'POST', data)
    .then(ErrorHandler)
    .then((client: any): any => client);

const update = (data: any, id: number): any =>
  Request(`client/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((client: any): any => client);

const remove = (id: number): any =>
  Request(`client/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((client: any): any => client);

const checkToRemoveClient = (id: number): any =>
  Request(`client/hasvalue/${id}`, 'GET')
    .then(ErrorHandler)
    .then((hasValueResponse: any): any => hasValueResponse);

const duplicateClient = (
  data: { newName: string; month: number; year: number },
  id: number
): any =>
  Request(`client/${id}/duplicate`, 'POST', data)
    .then(ErrorHandler)
    .then((client: any): any => client);
const ClientService = {
  list,
  item,
  create,
  update,
  remove,
  checkToRemoveClient,
  duplicateClient,
};

export default ClientService;
