import React, { ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import CopyIcon from '@material-ui/icons/Copyright';
import { getCurrentYear } from '../../helpers/utils';

const styles = (): any =>
  createStyles({
    centredDiv: {
      textAlign: 'center',
      height: 64,
      background: '#fafafa',
      zIndex: 9,
    },
    footerText: {
      fontSize: 12,
      color: '#424242',
      opacity: 0.8,
      lineHeight: '64px',
    },
  });

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  style: any;
  textStyle: any;
}

const Footer = (props: Props): ReactElement => {
  const { style, textStyle } = props;
  const classes = useStyles();
  return (
    <div className={classes.centredDiv} style={style}>
      <Typography className={classes.footerText} style={textStyle}>
        All Rights Reserved{' '}
        <CopyIcon style={{ marginBottom: '-1px', fontSize: '12px' }} />
        {`${getCurrentYear()} iassetmonitor.com`}
      </Typography>
    </div>
  );
};

Footer.defaultProps = {
  style: {},
  textStyle: {},
};

export default Footer;
