import createStyles from '@material-ui/core/styles/createStyles';
// import Background from './bg.jpg';
import BackgroundSplash from '../../assets/img/family.png';

const styles = (): any =>
  createStyles({
    paper: {
      width: 360,
      height: 400,
      position: 'absolute',
      top: 'calc(50% - 200px)',
      left: 'calc(50% - 180px)',
      padding: '20px 40px',
      textAlign: 'center',
    },
    container: {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundImage: `url(${BackgroundSplash})`,
    },
    caption: {
      width: '100%',
      marginTop: '65px',
    },
    field: {
      margin: '10px 0px',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
    },
    submit: {
      marginTop: 16,
    },
    snack: {
      backgroundColor: 'white',
    },
    message: {
      color: 'black',
    },
    input: {
      fontSize: '1rem',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        fontSize: '1rem',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      },
    },
  });

export default styles;
