import * as roles from '../constants/Roles';
import { EnvironmentType, UserProfileType } from '../Types';

export const getCurrentMonth = (): any => new Date().getMonth();
export const getCurrentYear = (): any => new Date().getFullYear();

export const setColor = (number: number): any => {
  const colors = [
    '#f2665a',
    '#f8964e',
    '#faad44',
    '#fdc43a',
    '#ffd842',
    '#fde354',
    '#f9ef6d',
    '#d8e35b',
    '#add660',
    '#77c973',
    '#77ceab',
    '#72d0d0',
    '#6acff5',
    '#71b0dd',
    '#8398cc',
    '#a27fbc',
    '#bd7db9',
    '#d07eb8',
    '#e784ba',
    '#f3a0c7',
    '#f1f7b9',
    '#dfb8bb',
    '#aae38f',
    '#4cb224',
  ];
  const matrix = [];

  if (number <= 0) {
    return [];
  }
  if (number >= 1) {
    const color = colors[9];
    matrix[18] = color;
  }
  if (number >= 2) {
    const color = colors[0];
    matrix[0] = color;
  }
  if (number >= 3) {
    const color = colors[2];
    matrix[2] = color;
  }
  if (number >= 4) {
    const color = colors[8];
    matrix[17] = color;
  }
  if (number >= 5) {
    const color = colors[4];
    matrix[4] = color;
  }
  if (number >= 6) {
    const color1 = colors[1];
    const color2 = colors[7];
    matrix[1] = color1;
    matrix[4] = color2;
  }
  if (number >= 7) {
    const color1 = colors[4];
    const color2 = colors[7];
    matrix[4] = color1;
    matrix[16] = color2;
  }
  if (number >= 8) {
    const color = colors[6];
    matrix[5] = color;
  }
  if (number >= 9) {
    const color = colors[3];
    matrix[3] = color;
  }
  if (number >= 10) {
    const color = colors[20];
    matrix[6] = color;
  }
  if (number >= 11) {
    const color1 = colors[22];
    const color2 = colors[23];
    matrix[16] = color1;
    matrix[19] = color2;
  }
  if (number >= 12) {
    const color1 = colors[12];
    const color2 = colors[10];
    matrix[14] = color1;
    matrix[15] = color2;
    matrix[17] = null;
  }
  if (number >= 13) {
    const color = colors[8];
    matrix[17] = color;
  }
  if (number >= 14) {
    const color = colors[13];
    matrix[13] = color;
  }
  if (number >= 15) {
    const color = colors[14];
    matrix[12] = color;
  }
  if (number >= 16) {
    const color = colors[15];
    matrix[11] = color;
  }
  if (number >= 17) {
    const color1 = colors[21];
    const color2 = colors[19];
    const color3 = colors[18];
    matrix[7] = color1;
    matrix[8] = color2;
    matrix[9] = color3;
    matrix[12] = null;
    matrix[16] = null;
  }
  if (number >= 18) {
    const color = colors[22];
    matrix[16] = color;
  }
  if (number >= 19) {
    const color = colors[16];
    matrix[10] = color;
  }
  if (number >= 20) {
    const color = colors[14];
    matrix[12] = color;
  }

  const result = matrix.reverse().filter(each => typeof each === 'string');

  return result;
};

export const handleAccess = (
  path: string,
  profile: UserProfileType,
  environment: EnvironmentType
): boolean => {
  const role =
    profile && profile.roles && profile.roles[0] && profile.roles[0].name;
  const typedRoles: any = { ...roles };
  const rolePaths: any = typedRoles[role];
  let hasValidExtraCondition = true;
  if (path === '/environments' && environment.type !== 'MASTER') {
    hasValidExtraCondition = false;
  }
  const hasPath =
    rolePaths &&
    rolePaths.find((each: any): any => {
      if (each.indexOf(':') > -1) {
        const removedParamPath = each.slice(0, each.indexOf(':'));
        const removedParamInputPath = path.slice(0, each.indexOf(':'));
        if (removedParamInputPath === removedParamPath) {
          return true;
        }
        return false;
      }
      if (each === path) {
        return true;
      }
      return false;
    });
  return hasPath && hasValidExtraCondition;
};