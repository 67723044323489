import React, { useState, useEffect, ReactElement } from 'react';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import DetailsIcon from '@material-ui/icons/FilterList';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import Dates from '../../constants/Date';
import DialogForm from './DialogForm';
import styles from './styles';
import {
  AccountType,
  ExportType,
  UserType,
  AccountTypeType,
  AssetManagerType,
  AccountCodeType,
  ClientType,
  MutateAccountType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  getAccountsList: (id: number) => void;
  getAssetManagersList: () => void;
  getAccountCodesList: () => void;
  getAssetClassSetsList: () => void;
  getAccountTypesList: () => void;
  removeAccount: (id: number) => void;
  createAccount: (obj: MutateAccountType, id: number) => void;
  updateAccount: (obj: MutateAccountType, id: number, dataId: number) => void;
  openDialog: () => void;
  closeDialog: () => void;
  accounts: { content: Array<AccountType> };
  accountTypes: { content: Array<AccountTypeType> };
  assetManagers: { content: Array<AssetManagerType> };
  accountCodes: { content: Array<AccountCodeType> };
  deleted: boolean;
  success: boolean;
  error: any;
  match: any;
  history: any;
  loading: boolean;
  getClientItem: (id: number) => void;
  client: ClientType;
  getUserItem: (id: number) => Promise<UserType>;
  exportAccountsTable: (id: number) => Promise<ExportType>;
  checkToRemoveAccount: (id: number) => Promise<any>;
}

const ClientAccounts = (props: Props): ReactElement => {
  const {
    match: {
      params: { clientId },
    },
    getAccountsList,
    getAssetManagersList,
    getAccountCodesList,
    getAssetClassSetsList,
    getAccountTypesList,
    getClientItem,
    openDialog,
    closeDialog,
    removeAccount,
    history,
    exportAccountsTable,
    accounts,
    accountCodes,
    accountTypes,
    assetManagers,
    updateAccount,
    createAccount,
    loading,
    error,
    success,
    deleted,
    client,
    getUserItem,
    checkToRemoveAccount,
    ...others
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<AccountType | undefined>(
    undefined
  );
  const [model, setModel] = useState<AccountType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [accountHasValueForResponse, setAccountHasValueResponse] = useState<
    boolean
  >(false);
  const classes = useStyles();

  useEffect(() => {
    getClientItem(clientId);
    getAccountsList(clientId);
    getAssetManagersList();
    getAccountCodesList();
    getAssetClassSetsList();
    getAccountTypesList();
  }, [
    getAccountsList,
    getAssetManagersList,
    getAccountCodesList,
    getAssetClassSetsList,
    getAccountTypesList,
    getClientItem,
    clientId,
  ]);

  const openAdd = (): void => {
    openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const openEdit = (event: any, row: AccountType): void => {
    openDialog();
    setOpen(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: AccountType): void => {
    openDialog();
    setOpenDelete(true);
    setSelectedRow(row);
    checkToRemoveAccount(row.id)
      .then((hasValueResponse: any): void =>
        setAccountHasValueResponse(hasValueResponse?.hasValue)
      )
      .catch((someError: any): void => console.warn(someError));
  };

  const handleClose = (): void => {
    closeDialog();
    setOpen(false);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) removeAccount(selectedRow.id);
  };

  const goToSecurities = (e: any, data: AccountType): void => {
    if (
      data.securitiesEnabled === 'Yes' ||
      data.securitiesEnabled === 'Mixed'
    ) {
      history.push(`/client/${data.refClient.id}/${data.id}/securities`);
    } else {
      history.push(`/client/${data.refClient.id}/${data.id}/details`);
    }
  };

  const getDate = (dateInput: number): string => {
    if (dateInput) {
      const date = new Date(dateInput);
      const year = date.getFullYear();
      const monthObj = Dates.months.find(
        each => each.valueNumber - 1 === date.getMonth()
      );
      const month = monthObj && monthObj.label;
      return `${month}-${year}`;
    }
    return '';
  };

  const exportTable = (): void => {
    exportAccountsTable(clientId).then((res: ExportType): void => {
      const csvData = new Blob([res.export], {
        type: 'text/csv;charset=utf-8;',
      });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', res.fileName || 'Accounts.csv');
      tempLink.click();
    });
  };

  const columns = [
    {
      title: 'Asset Manager',
      name: 'refAssetManager.name',
      sortable: true,
      type: 'string',
      sortField: 'refAssetManager.name',
    },
    {
      title: 'Account Code',
      name: 'refAccountCode.code',
      sortable: true,
      type: 'string',
      sortField: 'refAccountCode.code',
    },
    {
      title: 'Account Type',
      name: 'refAccountType.name',
      sortable: true,
      type: 'string',
      sortField: 'refAccountType.name',
    },
    { title: 'Securities', name: 'securitiesEnabled' },
    {
      title: 'Asset Type',
      name: 'assetType',
      sortable: true,
      type: 'string',
      sortField: 'assetType',
    },
    {
      title: 'Account Number',
      name: 'number',
      sortable: true,
      type: 'number',
      sortField: 'number',
    },
    {
      title: 'Start Date',
      name: 'startDateTable',
      sortable: true,
      sortField: 'startDate',
      type: 'number',
    },
    {
      title: 'End Date',
      name: 'endDateTable',
      sortable: true,
      sortField: 'endDate',
      type: 'number',
    },
  ];

  const breadcrumbItems = [
    {
      name: client ? client.name : '',
      route: client ? `/selected-client/${client.id}` : '',
    },
  ];

  const getDetailsIcon = (securitiesEnabled?: string): ReactElement => {
    if (securitiesEnabled === 'Yes') {
      return <SecurityIcon fontSize="small" />;
    }

    if (securitiesEnabled === 'No') {
      return <DetailsIcon fontSize="small" />;
    }

    return <SecurityIcon fontSize="small" />;
  };

  const details = (row: AccountType): any => [
    {
      icon: getDetailsIcon(row.securitiesEnabled),
      title: row.securitiesEnabled !== 'No' ? 'Securities' : 'Details',
      onClick: goToSecurities,
    },
  ];

  const editedAccounts = accounts
    ? accounts.content
        .map((a: AccountType): any => {
          if (
            !a.regccy &&
            (a.securitiesEnabled === 'No' || a.securitiesEnabled === 'Mixed')
          ) {
            return {
              ...a,
              style: { backgroundColor: '#FFCCCC' },
            };
          }
          if (
            !a.haveSecurities &&
            (a.securitiesEnabled === 'Yes' || a.securitiesEnabled === 'Mixed')
          ) {
            return {
              ...a,
              style: { backgroundColor: '#FFCCCC' },
            };
          }
          return a;
        })
        .map((each: any): any => ({
          ...each,
          startDateTable: getDate(each.startDate),
          endDateTable: getDate(each.endDate),
        }))
    : [];

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          hasEditAndDelete
          onEdit={openEdit}
          onDelete={handleDeleteOpen}
          onAdd={openAdd}
          columns={columns}
          hasBackButton
          details={details}
          rows={editedAccounts}
          entity="Account"
          breadcrumbItems={breadcrumbItems}
          hasBreadcrumb
          noMargin
          hasHighlightBackground
          hasExportButton
          onExport={exportTable}
          entities="Accounts"
          hasDetails
          onClickRow={goToSecurities}
          history={history}
          {...others}
        />
      </div>
      <DialogForm
        model={model}
        open={open}
        onClose={handleClose}
        update={updateAccount}
        create={createAccount}
        accountTypes={(accountTypes && accountTypes.content) || []}
        assetManagers={(assetManagers && assetManagers.content) || []}
        accountCodes={(accountCodes && accountCodes.content) || []}
        clientId={clientId}
        client={client}
        loading={loading}
        error={error}
        success={success}
        getUserItem={getUserItem}
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && !accountHasValueForResponse && !loading && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {!isRemoving && accountHasValueForResponse && !loading && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              There is data attached to this Account. <br />
              Are you sure you want to remove the Account and the data?
            </Typography>
          </div>
        )}
        {loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Account removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error: {error && error.messages && error.messages[0]}
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};

ClientAccounts.defaultProps = {
  deleted: false,
  success: false,
  error: undefined,
  accounts: undefined,
  accountTypes: undefined,
  assetManagers: undefined,
  accountCodes: undefined,
  loading: false,
  client: undefined,
  history: undefined,
};

export default ClientAccounts;
