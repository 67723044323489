import RegionService from '../services/RegionService';
import * as types from '../constants/Region';
import { RegionType } from '../Types';

const getRegionsList = (): any => (dispatch: any): void => {
  dispatch({ type: types.REGIONS_LIST_REQUEST });
  RegionService.list()
    .then((regions: { content: Array<RegionType> }): void => {
      dispatch({ type: types.REGIONS_LIST_SUCCESS, regions });
    })
    .catch((error: any): void => {
      dispatch({ type: types.REGIONS_LIST_FAILURE, error });
    });
};

const getRegionItem = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.REGION_ITEM_REQUEST });
  RegionService.item(id)
    .then((region: RegionType): void => {
      dispatch({ type: types.REGION_ITEM_SUCCESS, region });
    })
    .catch((error: any): void => {
      dispatch({ type: types.REGION_ITEM_FAILURE, error });
    });
};

const updateRegion = (data: any, id: number): any => (dispatch: any): void => {
  dispatch({ type: types.REGION_UPDATE_REQUEST });
  RegionService.update(data, id)
    .then((region: any): void => {
      dispatch({ type: types.REGION_UPDATE_SUCCESS, region });
      dispatch(getRegionsList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.REGION_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.REGION_UPDATE_FAILURE, error });
      }
    });
};

const createRegion = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.REGION_CREATE_REQUEST });
  RegionService.create(data)
    .then((region: any): void => {
      dispatch({ type: types.REGION_CREATE_SUCCESS, region });
      dispatch(getRegionsList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.REGION_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.REGION_CREATE_FAILURE, error });
      }
    });
};

const removeRegion = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.REGION_REMOVE_REQUEST });
  RegionService.remove(id)
    .then((region: any): void => {
      dispatch({ type: types.REGION_REMOVE_SUCCESS, region, id });
    })
    .catch((error: any): void => {
      dispatch({ type: types.REGION_REMOVE_FAILURE, error });
    });
};

const RegionActions = {
  getRegionsList,
  getRegionItem,
  createRegion,
  updateRegion,
  removeRegion,
};

export default RegionActions;
