import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const getHistory = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>,
  historyType: string
): any =>
  Request(`assettable/history`, 'POST', {
    year,
    month,
    id: clientId,
    disabledColumns,
    enabledRows,
    historyType,
  })
    .then(ErrorHandler)
    .then((history: any): any => history);

const HistoryService = {
  getHistory,
};

export default HistoryService;
