import React, { useState, useEffect, ReactElement } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ValuesTable from '../../containers/ValuesTable';
import Footer from '../../containers/Footer';
import { AssetClassCorrelationType } from '../../Types';

const styles = (): any => ({
  root: {
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
  },
  headerCell: {
    color: '#1976d2',
    textAlign: 'center !important',
    border: '1px solid rgba(25, 118 , 210, 0.5)',
    backgroundColor: '#B6E7FA',
  },
  label: {
    marginTop: 5,
  },
  tabsValue: {
    overflow: 'auto',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    minHeight: '100%',
  },
});

const useStyles = makeStyles<typeof styles>(styles);

interface CorrelationCreateObj {
  assetClassIds: Array<number>;
  assetClassSetId: number;
  correlation: number;
}

interface Props {
  matrix?: AssetClassCorrelationType;
  getMatrix?: (id: number) => void;
  saveCorrelation?: (obj: CorrelationCreateObj, id: number) => void;
  assetClassSetId: number;
  changeCorrelationStatus?: (
    obj: { enabled: boolean; assetClassSetId: number },
    id: number
  ) => void;
}

let stallingTime = 0; // don't ask about it :)

const Correlation = (props: Props): ReactElement => {
  const {
    assetClassSetId,
    getMatrix,
    matrix,
    saveCorrelation,
    changeCorrelationStatus,
  } = props;

  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [footerPos, setFooterPos] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    if (getMatrix) getMatrix(assetClassSetId);
  }, [getMatrix, assetClassSetId]);

  useEffect(() => {
    if (matrix) {
      setCheckbox(matrix.enabled);
    }
    const matrixColumns = (matrix && matrix.assetClasses) || [];
    const matrixRows = (matrix && matrix.correlations) || [];
    const newColumns: any = [
      {
        title: '',
        name: 'name',
        type: 'header',
        style: { textAlign: 'left' },
        columnId: 'name',
      },
    ];
    matrixColumns.forEach((col: any): void => {
      newColumns.push({
        title: col.name,
        name: `${col.assetClassId}`,
        id: col.assetClassId,
        columnId: `${col.name}-${col.assetClassId}`,
      });
    });
    const newRows: any = [];
    matrixRows.forEach((r: any, rIndex: number): void => {
      const newRow: any = { rowType: 'readOnly' };
      r.forEach((rcorrelation: any, rcIndex: number): void => {
        newRow.id = rcorrelation.assetClass1;
        newRow[rcorrelation.assetClass2] = Number(
          rcorrelation.correlation
        ).toFixed(2);
        if ((rIndex + 1) / (rcIndex + 1) >= 1) {
          newRow[`${rcorrelation.assetClass2}.style`] = { opacity: 0.4 };
        } else {
          newRow[`${rcorrelation.assetClass2}.editable`] = true;
        }
        const nameObj = matrixColumns.find(
          (eachCol: any): any =>
            eachCol.assetClassId === rcorrelation.assetClass1
        );
        newRow.name = (nameObj && nameObj.name) || '';
      });
      newRows.push(newRow);
    });
    setRows(newRows);
    setColumns(newColumns);
  }, [matrix]);

  const onSubmit = (row: any, name: string, value: number): void => {
    const data = {
      assetClassIds: [row.id, Number(name)],
      assetClassSetId,
      correlation: value,
    };
    if (saveCorrelation) saveCorrelation(data, assetClassSetId);
  };

  const handleCheckbox = (): void => {
    if (changeCorrelationStatus)
      changeCorrelationStatus(
        { enabled: !checkbox, assetClassSetId },
        assetClassSetId
      );
    setCheckbox(!checkbox);
  };

  return (
    <div className={classes.root}>
      <div
        onScroll={(event: any): void => {
          if (
            event.target.scrollLeft < 1000 &&
            new Date().getTime() - stallingTime > 100
          ) {
            setFooterPos(event.target.scrollLeft);
            stallingTime = 0;
          }

          if (event.target.scrollWidth > 10000) {
            stallingTime = new Date().getTime();
          }
        }}
        className={classes.tabsValue}
      >
        <div style={{ padding: '30px 40px 30px 25px' }}>
          <Typography variant="h1" style={{ margin: '24px 0 20px 0' }}>
            Correlation
          </Typography>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkbox}
                  onChange={handleCheckbox}
                  color="primary"
                  value="status"
                />
              }
              label="Use this Correlation Matrix"
              classes={{
                label: classes.label,
              }}
            />
          </div>
          <div>
            <ValuesTable
              rows={rows}
              columns={columns}
              numberPrecision={2}
              headerCellClass={classes.headerCell}
              selectOnDoubleClick
              focusOnTable
              onSubmit={onSubmit}
            />
          </div>
        </div>
        <Footer
          style={{
            marginTop: 50,
            width: `calc(100% + ${footerPos > 1000 ? 0 : footerPos}px)`,
          }}
          textStyle={{ marginLeft: footerPos }}
        />
      </div>
    </div>
  );
};

Correlation.defaultProps = {
  matrix: undefined,
};

export default Correlation;
