export const SECURITIES_LIST_REQUEST = 'SECURITIES_LIST_REQUEST';
export const SECURITIES_LIST_SUCCESS = 'SECURITIES_LIST_SUCCESS';
export const SECURITIES_LIST_FAILURE = 'SECURITIES_LIST_FAILURE';
export const SECURITY_CREATE_REQUEST = 'SECURITY_CREATE_REQUEST';
export const SECURITY_CREATE_SUCCESS = 'SECURITY_CREATE_SUCCESS';
export const SECURITY_CREATE_FAILURE = 'SECURITY_CREATE_FAILURE';
export const SECURITY_UPDATE_REQUEST = 'SECURITY_UPDATE_REQUEST';
export const SECURITY_UPDATE_SUCCESS = 'SECURITY_UPDATE_SUCCESS';
export const SECURITY_UPDATE_FAILURE = 'SECURITY_UPDATE_FAILURE';
export const SECURITY_ITEM_REQUEST = 'SECURITY_ITEM_REQUEST';
export const SECURITY_ITEM_SUCCESS = 'SECURITY_ITEM_SUCCESS';
export const SECURITY_ITEM_FAILURE = 'SECURITY_ITEM_FAILURE';
export const SECURITY_ITEM_VALUES_REQUEST = 'SECURITY_ITEM_VALUES_REQUEST';
export const SECURITY_ITEM_VALUES_SUCCESS = 'SECURITY_ITEM_VALUES_SUCCESS';
export const SECURITY_ITEM_VALUES_FAILURE = 'SECURITY_ITEM_VALUES_FAILURE';
export const SECURITY_ITEM_VALUES_SAVE_REQUEST =
  'SECURITY_ITEM_VALUES_SAVE_REQUEST';
export const SECURITY_ITEM_VALUES_SAVE_SUCCESS =
  'SECURITY_ITEM_VALUES_SAVE_SUCCESS';
export const SECURITY_ITEM_VALUES_SAVE_FAILURE =
  'SECURITY_ITEM_VALUES_SAVE_FAILURE';
export const SECURITY_REMOVE_REQUEST = 'SECURITY_REMOVE_REQUEST';
export const SECURITY_REMOVE_SUCCESS = 'SECURITY_REMOVE_SUCCESS';
export const SECURITY_REMOVE_FAILURE = 'SECURITY_REMOVE_FAILURE';
export const SECURITY_START_DATE_REQUEST = 'SECURITY_START_DATE_REQUEST';
export const SECURITY_START_DATE_SUCCESS = 'SECURITY_START_DATE_SUCCESS';
export const SECURITY_START_DATE_FAILURE = 'SECURITY_START_DATE_FAILURE';
