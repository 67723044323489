import React, { useEffect, useState, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import DetailsIcon from '@material-ui/icons/FilterList';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import DialogForm from './DialogForm';
import styles from './styles';
import { SecurityType, ExportType, UserType, MutateSecurityType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  getSecuritiesList: () => void;
  removeSecurity: (id: number) => void;
  createSecurity: (obj: MutateSecurityType) => void;
  updateSecurity: (obj: MutateSecurityType, id:number) => void;
  securities: { content: Array<SecurityType> };
  deleted: boolean;
  success: boolean;
  loading: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  error: any;
  history: any;
  getUserItem: (id: number) => Promise<UserType>;
  exportSecuritiesTable: () => Promise<ExportType>;
}

const Security = (props: Props): ReactElement => {
  const {
    getSecuritiesList,
    openDialog,
    closeDialog,
    removeSecurity,
    history,
    exportSecuritiesTable,
    securities,
    updateSecurity,
    createSecurity,
    loading,
    success,
    deleted,
    error,
    getUserItem,
    ...others
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<SecurityType | undefined>(
    undefined
  );
  const [model, setModel] = useState<SecurityType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    getSecuritiesList();
  }, [getSecuritiesList]);

  const openAdd = (): void => {
    openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const openEdit = (event: any, row: SecurityType): void => {
    openDialog();
    setOpen(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: SecurityType): void => {
    openDialog();
    setOpenDelete(true);
    setSelectedRow(row);
  };

  const handleClose = (): void => {
    closeDialog();
    setOpen(false);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) removeSecurity(selectedRow.id);
  };

  const securityDetails = (e: any, data: SecurityType): void => {
    history.push(`security/${data.id}`);
  };

  const exportTable = (): void => {
    exportSecuritiesTable().then((res: ExportType): void => {
      const csvData = new Blob([res.export], {
        type: 'text/csv;charset=utf-8;',
      });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', res.fileName || 'Securities.csv');
      tempLink.click();
    });
  };

  const columns = [
    {
      title: 'Name',
      name: 'name',
      searchable: true,
      sortable: true,
      type: 'string,',
      sortField: 'name',
    },
    {
      title: 'Display',
      name: 'display',
      searchable: true,
      sortable: true,
      type: 'string',
      sortField: 'display',
    },
    {
      title: 'ISIN',
      name: 'isin',
      searchable: true,
      sortable: true,
      type: 'string',
      sortField: 'isin',
    },
  ];

  const details = (row: SecurityType): any => [
    {
      icon: <DetailsIcon fontSize="small" />,
      title: 'Details',
      onClick: (): void => securityDetails(undefined, row),
    },
  ];

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          hasEditAndDelete
          onEdit={openEdit}
          onDelete={handleDeleteOpen}
          onAdd={openAdd}
          columns={columns}
          details={details}
          noMargin
          hasHighlightBackground
          rows={securities && securities.content}
          hasDetails
          hasExportButton
          onExport={exportTable}
          onClickRow={securityDetails}
          entity="Security"
          entities="Securities"
          {...others}
        />
      </div>
      <DialogForm
        model={model}
        open={open}
        onClose={handleClose}
        update={updateSecurity}
        create={createSecurity}
        loading={loading}
        success={success}
        error={error}
        getUserItem={getUserItem}
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {isRemoving && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Security removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error: {error && error.messages && error.messages[0]}
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};

Security.defaultProps = {
  error: undefined,
  success: false,
  securities: undefined,
  deleted: false,
};

export default Security;
