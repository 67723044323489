import * as types from '../constants/Dialog';

const openDialog = (): any => (dispatch: any): void => {
  dispatch({
    type: types.DIALOG_OPEN,
  });
};

const closeDialog = (): any => (dispatch: any): void => {
  dispatch({
    type: types.DIALOG_CLOSE,
  });
};

export default { openDialog, closeDialog };
