import * as types from '../constants/AssetAllocation';
import * as UserTypes from '../constants/User';

const AssetAllocation = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.CREATE_ALLOCATION_WEIGHT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_ALLOCATION_WEIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.CREATE_ALLOCATION_WEIGHT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_ALLOCATION_WEIGHT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_ALLOCATION_WEIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_ALLOCATION_WEIGHT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.REMOVE_ALLOCATION_WEIGHT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_ALLOCATION_WEIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.REMOVE_ALLOCATION_WEIGHT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.CREATE_ALLOCATION_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_ALLOCATION_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.CREATE_ALLOCATION_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_ALLOCATION_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_ALLOCATION_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_ALLOCATION_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.REMOVE_ALLOCATION_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_ALLOCATION_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.REMOVE_ALLOCATION_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case UserTypes.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default AssetAllocation;
