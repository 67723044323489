import React, { useState, ReactElement } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import classNames from 'classnames';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import styles from './styles';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  columns: any;
  rows: any;
  title: string;
  isSelected: boolean;
  showHeaders: boolean;
  showToolbar: boolean;
  hasToolbarButton: boolean;
  ButtonLabel: string;
  onClickButton: any;
  hovered: string;
  hasTotalRow: boolean;
  showNumber: boolean;
  type2: boolean;
  sharp: number | string;
  onKeyPressHandler: any;
  totalRow: any;
  hasColor: boolean;
  noMarginToolbar: boolean;
}

const DataTable = (props: Props): ReactElement => {
  const {
    rows,
    columns,
    title,
    hovered,
    isSelected,
    showHeaders,
    showToolbar,
    hasToolbarButton,
    ButtonLabel,
    onClickButton,
    hasTotalRow,
    hasColor,
    showNumber,
    type2,
    sharp,
    onKeyPressHandler,
    totalRow,
    noMarginToolbar,
  } = props;

  const [value, setValue] = useState<any>(undefined);
  const classes = useStyles();

  const formatNumber = (number: string | number): string => {
    const newNumber = Number(number).toFixed(0);
    if (newNumber !== 'NaN') {
      return newNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return '';
  };

  const formatPercentage = (number: number): any =>
    (Math.round(number * 100) / 100).toFixed(2);

  const handleChange = (event: any): void => {
    const pattern = '^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$';
    const newValue = event.target.value;
    if (newValue.match(pattern) || newValue === '') {
      setValue(newValue);
    }
  };

  const handleFocus = (event: any): void => event.target.select();

  const onBlur = (): void => {
    setValue(undefined);
  };

  const resolve = (path: string, obj: any): any =>
    path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj);

  const TableToolbar = (): ReactElement => (
    <Toolbar
      className={classNames({
        [classes.noMargin]: noMarginToolbar,
        [classes.toolbar]: !noMarginToolbar,
      })}
    >
      <div className={classes.titleContainer}>
        <Typography className={classes.title} id="tableTitle" variant="h3">
          {title}
        </Typography>
      </div>
      {hasToolbarButton && (
        <Button
          onClick={(): void => onClickButton(title)}
          variant="outlined"
          className={classNames(classes.addButton, {
            [classes.selected]: isSelected,
          })}
          color="primary"
        >
          {ButtonLabel}
        </Button>
      )}
    </Toolbar>
  );

  const BodeyCells = (): ReactElement =>
    rows &&
    rows.map(
      (row: any, idx: number): ReactElement => (
        <TableRow
          // eslint-disable-next-line
          key={`${row.id}-${idx}`}
          className={
            type2
              ? classNames(classes.rowContainer2)
              : classNames(classes.rowContainer)
          }
          style={{
            backgroundColor:
              hovered === row.name || hovered === row.title ? row.color : '',
          }}
        >
          {hasColor && (
            <TableCell
              className={classes.cell}
              align="left"
              classes={{
                root: classes.colorCellRoot,
              }}
            >
              <div
                className={classes.tableCount}
                style={{ backgroundColor: resolve('color', row) }}
              >
                {' '}
                <span style={{ lineHeight: '20px', fontSize: '12px' }}>
                  {showNumber && idx + 1}
                </span>
              </div>
            </TableCell>
          )}
          {columns.map(
            (col: any, index: number): ReactElement => (
              <TableCell
                // eslint-disable-next-line
              key={`${col.name}-${index}`}
                className={
                  col.name === 'name' || col.name === 'title'
                    ? classes.name
                    : classes.cell
                }
                align="left"
                style={resolve(`${col.name}-style`, row)}
                classes={{
                  // eslint-disable-next-line
                root: type2
                    ? classes.cellRoot2
                    : index === 0
                    ? classes.cellRoot4
                    : classes.cellRoot,
                }}
              >
                {col.name !== 'color' &&
                  col.name !== 'name' &&
                  col.name !== 'value' &&
                  col.name !== 'title' && (
                    <span
                      style={{
                        color:
                          resolve(col.name, row) && resolve(col.name, row) < 0
                            ? 'rgb(255,0,0)'
                            : 'inherit',
                      }}
                    >
                      {' '}
                      {// eslint-disable-next-line
                    resolve('name', row) && resolve('name', row).props && resolve('name', row).props.value === 'None'
                        ? ''
                      : isNaN(formatPercentage(resolve(col.name, row)))// eslint-disable-line
                        ? '0.00%'
                        : `${formatPercentage(resolve(col.name, row))}%`}
                    </span>
                  )}
                {col.name === 'value' && !type2 && (
                  <span
                    style={{
                      color:
                        resolve(col.name, row) && resolve(col.name, row) < 0
                          ? 'rgb(255,0,0)'
                          : 'inherit',
                    }}
                  >
                    {' '}
                    {// eslint-disable-next-line
                  isNaN(formatPercentage(resolve(col.name, row)))
                      ? ''
                      : `${formatPercentage(resolve(col.name, row))}%`}
                  </span>
                )}
                {(col.name === 'name' || col.name === 'title') && (
                  <span>{resolve(col.name, row)}</span>
                )}
                {type2 && col.name === 'value' && (
                  <span> {`€ ${formatNumber(resolve(col.name, row))}`} </span>
                )}
              </TableCell>
            )
          )}
        </TableRow>
      )
    );

  return (
    <div className={classes.root}>
      {showToolbar && TableToolbar()}
      <div>
        <Table className={classes.table}>
          <TableHead>
            {showHeaders && (
              <TableRow
                className={
                  type2
                    ? classNames(classes.rowContainer2)
                    : classNames(classes.rowContainer)
                }
                style={{ borderTop: 'none' }}
              >
                {columns &&
                  columns.map(
                    (col: any, index: number): ReactElement => (
                      <TableCell
                        // eslint-disable-next-line
            key={`${col.title}-${index}`}
                        align="left"
                        className={
                          col.name === 'name'
                            ? classes.headerName
                            : classes.headerCell
                        }
                        classes={{
                          // eslint-disable-next-line
                root: type2
                            ? classes.cellRoot2
                            : index === 0
                            ? classes.cellRoot4
                            : classes.cellRoot,
                        }}
                        colSpan={hasColor && index === 0 ? 2 : 1}
                      >
                        {col.title !== 'Sharp' && <span>{col.title}</span>}
                        {col.title === 'Sharp' && (
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <span style={{ paddingTop: '2px' }}>
                              {' '}
                              {col.title}{' '}
                            </span>

                            <TextField
                              className={classes.textfield}
                              inputProps={{
                                className: classes.editingTextfield,
                              }}
                              // eslint-disable-next-line
                    InputProps={{ disableUnderline: true }}
                              variant="filled"
                              value={value === undefined ? sharp : value}
                              style={{ marginLeft: '2px', marginTop: '7px' }}
                              onChange={handleChange}
                              onKeyPress={(event: any): void =>
                                onKeyPressHandler(event, value)
                              }
                              onKeyDown={(): void => {}}
                              onBlur={onBlur}
                              onFocus={handleFocus}
                            />
                            <span style={{ paddingTop: '3px' }}> % </span>
                          </span>
                        )}
                      </TableCell>
                    )
                  )}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {BodeyCells()}
            {sharp !== undefined && (
              <TableRow
                className={
                  type2
                    ? classNames(classes.rowContainer2)
                    : classNames(classes.rowContainer)
                }
                style={{ borderBottom: 'none' }}
              />
            )}
            {hasTotalRow && !totalRow && rows && rows.length > 0 && (
              <TableRow
                className={
                  type2
                    ? classNames(classes.rowContainer2)
                    : classNames(classes.rowContainer)
                }
                style={{
                  borderBottom: 'none',
                  borderTop: sharp !== undefined ? '1px solid #000' : 'inherit',
                }}
              >
                {hasColor && (
                  <TableCell
                    classes={{
                      root: type2 ? classes.cellRoot2 : classes.cellRoot,
                    }}
                  />
                )}
                {columns.map(
                  (col: any): ReactElement => (
                    <TableCell
                      key={col.name}
                      className={
                        col.name === 'name' ? classes.name : classes.cell
                      }
                      align="left"
                      style={{ fontWeight: 600 }}
                      classes={{
                        root: type2 ? classes.cellRoot2 : classes.cellRoot,
                      }}
                    >
                      {col.name === 'value' && (
                        <span>
                          {' '}
                          {`€ ${formatNumber(
                            rows
                              .map((r: any): number => r.value)
                              .reduce(
                                (total: number, each: number): number =>
                                  total + each
                              )
                          )}`}{' '}
                        </span>
                      )}
                      {col.name !== 'value' && col.name !== 'name' && (
                        <span>
                          {' '}
                          {`${formatPercentage(
                            rows
                              .map((r: any): number => r.percentage)
                              .reduce(
                                (total: number, each: number): number =>
                                  total + each
                              )
                          )}%`}
                        </span>
                      )}
                      {col.name === 'name' && <span>Total</span>}
                    </TableCell>
                  )
                )}
              </TableRow>
            )}
            {totalRow && (
              <TableRow
                className={
                  type2
                    ? classNames(classes.rowContainer2)
                    : classNames(classes.rowContainer)
                }
                style={{
                  borderTop: '1px solid #000',
                  borderBottom: 'none',
                }}
              >
                {columns.map(
                  (col: any, idx: number): ReactElement => (
                    <TableCell
                      key={col.name}
                      className={
                        col.name === 'name' ? classes.name : classes.cell
                      }
                      align="left"
                      style={{ fontWeight: 600 }}
                      classes={{
                        // eslint-disable-next-line
                        root: type2
                          ? classes.cellRoot2
                          : idx === 0
                          ? classes.cellRoot4
                          : classes.cellRoot,
                      }}
                    >
                      {col.name !== 'name' && (
                        <span
                          style={{
                            color:
                              resolve(col.name, totalRow) &&
                              resolve(col.name, totalRow) < 0
                                ? 'rgb(255,0,0)'
                                : 'inherit',
                          }}
                        >
                          {' '}
                          {`${formatPercentage(resolve(col.name, totalRow))}%`}
                        </span>
                      )}
                      {col.name === 'name' && (
                        <span>{resolve(col.name, totalRow)}</span>
                      )}
                    </TableCell>
                  )
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

DataTable.defaultProps = {
  showChart: undefined,
  showHeaders: false,
  showToolbar: true,
  isSelected: false,
  hasToolbarButton: false,
  ButtonLabel: '',
  onClickButton: undefined,
  hovered: '',
  hasTotalRow: false,
  showNumber: false,
  type2: false,
  totalRow: undefined,
  sharp: undefined,
  setSharp: undefined,
  onKeyPressHandler: undefined,
  hasColor: false,
  blur: undefined,
  noMarginToolbar: false,
};

export default DataTable;
