import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';
import FileService from './FileService';

const list = (): any =>
  Request('benchmark', 'GET')
    .then(ErrorHandler)
    .then((benchmarks: any): any => benchmarks);

const parse = (data: any): any =>
  FileService.upload(data).then((response: any): any =>
    Request('benchmark/parse', 'POST', { uid: response.uid })
      .then(ErrorHandler)
      .then((benchmark: any): any => benchmark)
  );

const getBenchmarkItem = (id: number): any =>
  Request(`benchmark/${id}`, 'GET')
    .then(ErrorHandler)
    .then((benchmark: any): any => benchmark);

const remove = (id: number): any =>
  Request(`benchmark/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((assetManager: any): any => assetManager);

const getBenchmarkValues = (id: number): any =>
  Request(`benchmark/${id}/values`, 'GET')
    .then(ErrorHandler)
    .then((benchmark: any): any => benchmark);

const editBenchmark = (id: number, data: any): any =>
  Request(`benchmark/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((benchmark: any): any => benchmark);

const pushBenchmarks = (): any =>
  Request(`benchmark/push`, 'GET')
    .then(ErrorHandler)
    .then((benchmark: any): any => benchmark);

const BenchmarkService = {
  list,
  parse,
  remove,
  getBenchmarkValues,
  getBenchmarkItem,
  editBenchmark,
  pushBenchmarks,
};

export default BenchmarkService;
