import CurrencyService from '../services/CurrencyService';
import * as types from '../constants/Currency';
import { CurrencyType } from '../Types';

const getCurrenciesList = (): any => (dispatch: any): void => {
  dispatch({ type: types.CURRENCIES_LIST_REQUEST });
  CurrencyService.list()
    .then((currencies: { content: Array<CurrencyType> }): void => {
      dispatch({ type: types.CURRENCIES_LIST_SUCCESS, currencies });
    })
    .catch((error: any): void => {
      dispatch({ type: types.CURRENCIES_LIST_FAILURE, error });
    });
};

const getCurrencyItem = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.CURRENCY_ITEM_REQUEST });
  CurrencyService.item(id)
    .then((currency: CurrencyType): void => {
      dispatch({ type: types.CURRENCY_ITEM_SUCCESS, currency });
    })
    .catch((error: any): void => {
      dispatch({ type: types.CURRENCY_ITEM_FAILURE, error });
    });
};

const createCurrency = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.CURRENCY_CREATE_REQUEST });
  CurrencyService.create(data)
    .then((currency: any): void => {
      dispatch({ type: types.CURRENCY_CREATE_SUCCESS, currency });
      dispatch(getCurrenciesList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.CURRENCY_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.CURRENCY_CREATE_FAILURE, error });
      }
    });
};

const updateCurrency = (data: any, id: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.CURRENCY_UPDATE_REQUEST });
  CurrencyService.update(data, id)
    .then((currency: any): void => {
      dispatch({ type: types.CURRENCY_UPDATE_SUCCESS, currency });
      dispatch(getCurrenciesList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.CURRENCY_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.CURRENCY_UPDATE_FAILURE, error });
      }
    });
};

const removeCurrency = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.CURRENCY_REMOVE_REQUEST });
  CurrencyService.remove(id)
    .then((currency: any): void => {
      dispatch({ type: types.CURRENCY_REMOVE_SUCCESS, currency, id });
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.CURRENCY_REMOVE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.CURRENCY_REMOVE_FAILURE, error });
      }
    });
};

const CurrencyActions = {
  getCurrenciesList,
  getCurrencyItem,
  createCurrency,
  updateCurrency,
  removeCurrency,
};

export default CurrencyActions;
