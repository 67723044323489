import React, { useState, useEffect, ReactElement } from 'react';
import AccountsIcon from '@material-ui/icons/AccountCircleOutlined';
import DuplicateIcon from '@material-ui/icons/FileCopyOutlined';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import TabsComponent from '../../containers/Tabs';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import Dates from '../../constants/Date';
import DialogForm from './DialogForm';
import DuplicateClientForm from './DuplicateClientForm';
import styles from './styles';
import {
  ClientType,
  ExportType,
  UserType,
  AssetClassSetType,
  ClientTypeType,
  MutateClientType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  getClientsList: () => void;
  removeClient: (id: number) => Promise<any>;
  createClient: (obj: MutateClientType) => void;
  updateClient: (obj: MutateClientType, id: number) => void;
  duplicateClient: (
    obj: { newName: string; month: number; year: number },
    id: number
  ) => void;
  openDialog: () => void;
  closeDialog: () => void;
  clients: { content: Array<ClientType> };
  deleted: boolean;
  success: boolean;
  error: any;
  loading: boolean;
  getAssetClassSetsList: () => void;
  getClientTypesList: () => Promise<any>;
  assetClassSets: { content: Array<AssetClassSetType> };
  history: any;
  getUserItem: (id: number) => Promise<UserType>;
  exportClientsTable: () => Promise<ExportType>;
  clientTypes: { content: Array<ClientTypeType> };
  checkToRemoveClient: (id: number) => Promise<any>;
  currentMonth: number;
  currentYear: number;
}

const Clients = (props: Props): ReactElement => {
  const {
    getClientsList,
    getAssetClassSetsList,
    openDialog,
    closeDialog,
    removeClient,
    history,
    exportClientsTable,
    clients,
    updateClient,
    createClient,
    assetClassSets,
    loading,
    success,
    error,
    deleted,
    getUserItem,
    clientTypes,
    getClientTypesList,
    checkToRemoveClient,
    duplicateClient,
    currentMonth,
    currentYear,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<ClientType | undefined>(
    undefined
  );
  const [model, setModel] = useState<ClientType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [tabNumber, setTabNumber] = useState<number>(0);
  const [clientTypesWithClient, setClientTypesWithClient] = useState<
    Array<ClientTypeType>
  >([]);
  const [clientsWithNoType, setClientsWithNoType] = useState<Array<ClientType>>(
    []
  );
  const [tabValues, setTabValues] = useState<Array<number>>([]);
  const [tabNames, setTabNames] = useState<Array<string>>([]);
  const [badgeValues, setBadgeValues] = useState<Array<number>>([]);
  const [clientHasValueResponse, setClientHasValueResponse] = useState<boolean>(
    false
  );
  const [openDuplicateClientForm, setOpenDuplicateClientForm] = useState<
    boolean
  >(false);
  const classes = useStyles();

  useEffect(() => {
    getClientsList();
    getAssetClassSetsList();
    getClientTypesList();
  }, [getAssetClassSetsList, getClientsList]);

  const selectDefaultTab = (input: any): void => {
    if (input.length > 0) {
      const clientType = input[0];
      const { id } = clientType;
      setTabNumber(id);
    } else {
      setTabNumber(0.1);
    }
  };

  useEffect(() => {
    if (clientTypes) {
      const clientTypesContent = clientTypes?.content || [];
      const newClientTypesWithClient = clientTypesContent.filter(
        eachClientType =>
          clients &&
          clients.content &&
          clients.content.filter(
            (each: ClientType): any =>
              each?.clientType?.id === eachClientType?.id
          ).length !== 0
      );
      setClientTypesWithClient(newClientTypesWithClient);
      selectDefaultTab(newClientTypesWithClient);
    }
  }, [clientTypes, clients]);

  useEffect(() => {
    const newClientsWithNoType =
      (clients &&
        clients.content &&
        clients.content.filter((each: ClientType): any => !each.clientType)) ||
      [];
    setClientsWithNoType(newClientsWithNoType);
  }, [clients]);

  useEffect(() => {
    const newTabNames =
      clientsWithNoType.length === 0
        ? clientTypesWithClient.map(eachClientType => eachClientType.name)
        : clientTypesWithClient
            .map(eachClientType => eachClientType.name)
            .concat('No Group');
    setTabNames(newTabNames);
  }, [clientsWithNoType, clientTypesWithClient]);

  useEffect(() => {
    const newTabValues =
      clientsWithNoType.length === 0
        ? clientTypesWithClient.map(eachClientType => eachClientType?.id)
        : clientTypesWithClient
            .map(eachClientType => eachClientType?.id)
            .concat(0.1);
    setTabValues(newTabValues);
  }, [clientsWithNoType, clientTypesWithClient]);

  useEffect(() => {
    const newBadgeValues =
      clientsWithNoType.length === 0
        ? clientTypesWithClient.map(
            eachClientType =>
              clients &&
              clients.content &&
              clients.content.filter(
                (each: ClientType): any =>
                  each?.clientType?.id === eachClientType?.id
              ).length
          )
        : clientTypesWithClient
            .map(
              eachClientType =>
                clients &&
                clients.content &&
                clients.content.filter(
                  (each: ClientType): any =>
                    each?.clientType?.id === eachClientType?.id
                ).length
            )
            .concat(
              clients &&
                clients.content &&
                clients.content.filter(
                  (each: ClientType): any => !each.clientType
                ).length
            );
    setBadgeValues(newBadgeValues);
  }, [clientsWithNoType, clientTypesWithClient]);

  const openAdd = (): void => {
    openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const openEdit = (event: any, row: ClientType): void => {
    openDialog();
    setOpen(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: ClientType): void => {
    openDialog();
    setOpenDelete(true);
    setSelectedRow(row);
    checkToRemoveClient(row.id)
      .then((hasValueResponse: any): void =>
        setClientHasValueResponse(hasValueResponse?.hasValue)
      )
      .catch((someError: any): void => console.warn(someError));
  };

  const handleClose = (): void => {
    closeDialog();
    setOpen(false);
  };

  const onRowSelection = (row: ClientType): void => {
    setSelectedRow(row);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) removeClient(selectedRow.id);
  };

  const clientDetails = (e: any, data: ClientType): void => {
    history.push(`selected-client/${data.id}`);
  };

  const clientAccounts = (e: any, data: ClientType): void => {
    history.push(`client/${data.id}/accounts`);
  };

  const hanldeOpenDuplicateForm = (e: any, data: ClientType): void => {
    setModel(data);
    setOpenDuplicateClientForm(true);
  };

  const getDate = (dateInput: string): string => {
    if (dateInput) {
      const date = new Date(dateInput);
      const year = date.getFullYear();
      const monthObj = Dates.months.find(
        each => each.valueNumber - 1 === date.getMonth()
      );
      const month = monthObj && monthObj.label;
      return `${month}-${year}`;
    }
    return '';
  };

  const exportTable = (): void => {
    exportClientsTable().then((res: ExportType): void => {
      const csvData = new Blob([res.export], {
        type: 'text/csv;charset=utf-8;',
      });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', res.fileName || 'Clients.csv');
      tempLink.click();
    });
  };

  const columns = [
    {
      name: 'name',
      title: 'Name',
      style: { width: 250 },
      rowStyle: { width: 250 },
      searchable: true,
      sortable: true,
      type: 'string',
      sortField: 'name',
    },
    {
      name: 'refAssetClassSet.name',
      title: 'Asset Class Set',
      searchable: true,
      sortable: true,
      type: 'string',
      sortField: 'refAssetClassSet.name',
    },
    {
      title: 'Start Date',
      name: 'startDateTable',
      sortable: true,
      type: 'number',
      sortField: 'startDate',
    },
    {
      title: 'End Date',
      name: 'endDateTable',
      sortable: true,
      sortField: 'endDate',
      type: 'number',
    },
  ];

  const details = (): any => [
    {
      icon: <DuplicateIcon fontSize="small" />,
      title: 'Duplicate',
      onClick: hanldeOpenDuplicateForm,
    },
    {
      icon: <AccountsIcon fontSize="small" />,
      title: 'Accounts',
      onClick: clientAccounts,
    },
  ];

  const editedClients =
    (clients &&
      clients.content &&
      clients.content.map((each: ClientType): any => ({
        ...each,
        startDateTable: getDate(each.startDate),
        endDateTable: getDate(each.endDate),
      }))) ||
    [];

  const currentClientType = clientTypes
    ? clientTypes.content.find(
        eachClientType => eachClientType.id === tabNumber
      )
    : undefined;

  return (
    <div className={classes.root}>
      <div style={{ height: '100%' }}>
        <div style={{ height: 'calc(100% - 30px)' }}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.titleContainer}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  className={classes.title}
                  id="tableTitle"
                  variant="h1"
                >
                  Clients
                </Typography>
                <div className={classes.tableCount}>
                  {clients ? clients.content.length : 0}
                </div>
              </div>
            </div>

            <Button
              onClick={openAdd}
              variant="outlined"
              className={classes.addButton}
            >
              Add Client
            </Button>
          </Toolbar>
          <div className={classes.tab}>
            <TabsComponent
              selectedTab={tabNumber}
              changeTab={(value: number): void => setTabNumber(value)}
              tabs={tabNames}
              values={tabValues}
              showLabelInBadge
              badgeValues={badgeValues}
            />
            <div />
          </div>
          <div style={{ overflow: 'auto', height: 'calc(100% - 136px)' }}>
            {currentClientType && (
              <div style={{ height: '100%' }}>
                <DataTable
                  hasAddButton
                  hasEditAndDelete
                  onEdit={openEdit}
                  onDelete={handleDeleteOpen}
                  onAdd={openAdd}
                  onRowSelection={onRowSelection}
                  columns={columns}
                  details={details}
                  hasDetails
                  noMargin
                  hasToolbar={false}
                  hasExportButton
                  onExport={exportTable}
                  onClickRow={clientDetails}
                  rows={editedClients.filter(
                    (each: any): any =>
                      each?.clientType?.id === currentClientType?.id
                  )}
                  entities="Clients"
                  entity="Client"
                />
              </div>
            )}

            {tabNumber === 0.1 && (
              <div style={{ height: '100%' }}>
                <DataTable
                  hasAddButton
                  hasEditAndDelete
                  hasToolbar={false}
                  onEdit={openEdit}
                  onDelete={handleDeleteOpen}
                  onAdd={openAdd}
                  onRowSelection={onRowSelection}
                  columns={columns}
                  details={details}
                  hasDetails
                  hasExportButton
                  onExport={exportTable}
                  onClickRow={clientDetails}
                  noMargin
                  rows={editedClients.filter(
                    (each: any): any => !each.clientType
                  )}
                  entities="Clients"
                  entity="Client"
                />
              </div>
            )}
          </div>
        </div>
        <DialogForm
          model={model}
          open={open}
          onClose={handleClose}
          update={updateClient}
          create={createClient}
          assetClassSets={(assetClassSets && assetClassSets.content) || []}
          loading={loading}
          success={success}
          getUserItem={getUserItem}
          error={error}
          clientTypes={(clientTypes && clientTypes.content) || []}
        />
        {openDuplicateClientForm && (
          <DuplicateClientForm
            model={model}
            open={openDuplicateClientForm}
            onClose={(): void => setOpenDuplicateClientForm(false)}
            duplicate={duplicateClient}
            loading={loading}
            success={success}
            error={error}
            currentMonth={currentMonth}
            currentYear={currentYear}
          />
        )}
        <Dialog
          open={openDelete}
          onClose={handleDeleteClose}
          title="Warning"
          actions={
            isRemoving
              ? [
                  {
                    label: 'OK',
                    onClick: handleDeleteClose,
                  },
                ]
              : [
                  {
                    label: 'cancel',
                    onClick: handleDeleteClose,
                    type: 'secondary',
                  },
                  {
                    label: 'Delete',
                    onClick: remove,
                  },
                ]
          }
        >
          {!isRemoving && !loading && !clientHasValueResponse && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                Are you sure?
              </Typography>
            </div>
          )}
          {!isRemoving && !loading && clientHasValueResponse && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                There is data attached to this Client. <br />
                Are you sure you want to remove the Client and the data?
              </Typography>
            </div>
          )}
          {loading && (
            <div className={classes.container}>
              <LinearProgress className={classes.progress} />
            </div>
          )}
          {isRemoving && !loading && deleted && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                Client removed successfully.
              </Typography>
            </div>
          )}
          {isRemoving && !loading && !deleted && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                Error: {error && error.messages && error.messages[0]}
              </Typography>
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
};

Clients.defaultProps = {
  deleted: false,
  client: undefined,
  success: undefined,
  error: undefined,
  assetClassSets: undefined,
};

export default Clients;
