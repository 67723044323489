import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DialogActions from '../../actions/DialogActions';
import ReportActions from '../../actions/ReportActions';
import UserActions from '../../actions/UserActions';
import ClientActions from '../../actions/ClientActions';
import AssetTableActions from '../../actions/AssetTableActions';
import BenchmarkActions from '../../actions/BenchmarkActions';

import Reports from './Reports';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...DialogActions,
      ...ReportActions,
      ...UserActions,
      ...ClientActions,
      ...AssetTableActions,
      ...BenchmarkActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  loading: state.Report.loading,
  selectedClient: state.Client.selectedClient,
  reports: state.Report.reports,
  reportTemplates: state.Report.reportTemplates,
  success: state.Report.success,
  error: state.Report.error,
  deleted: state.Report.deleted,
  reportingSettings: state.Report.reportingSettings,
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
