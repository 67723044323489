import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const getEnvironmentsList = (): any =>
  Request('environment', 'GET')
    .then(ErrorHandler)
    .then((environments: any): any => environments);

const getEnvironmentItem = (id: number): any =>
  Request(`environment/${id}`, 'GET')
    .then(ErrorHandler)
    .then((environment: any): any => environment);

const removeEnvironment = (id: number): any =>
  Request(`environment/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((environment: any): any => environment);

const createEnvironment = (data: any): any =>
  Request('environment', 'POST', data)
    .then(ErrorHandler)
    .then((environment: any): any => environment);

const updateEnvironment = (id: number, data: any): any =>
  Request(`environment/${id}`, 'PUT', data)
    .then(ErrorHandler)
    .then((environment: any): any => environment);

const getCurrentEnvironment = (): any =>
  Request('environment/current', 'GET')
    .then(ErrorHandler)
    .then((currentEnvironment: any): any => currentEnvironment);

const EnvironmentService = {
  getEnvironmentsList,
  getEnvironmentItem,
  createEnvironment,
  updateEnvironment,
  removeEnvironment,
  getCurrentEnvironment,
};

export default EnvironmentService;
