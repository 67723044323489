import * as types from '../constants/AssetClass';

const AssetClass = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.ASSET_CLASSES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ASSET_CLASSES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        assetClasses: action.assetClasses,
      };
    case types.ASSET_CLASSES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.ASSET_CLASS_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ASSET_CLASS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.assetClass.reference,
      };
    case types.ASSET_CLASS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.ASSET_CLASS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ASSET_CLASS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        reference: action.assetClass.reference,
      };
    case types.ASSET_CLASS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case types.ASSET_CLASS_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ASSET_CLASS_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        assetClass: action.assetClass,
      };
    case types.ASSET_CLASS_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.messages,
      };
    case types.ASSET_CLASS_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ASSET_CLASS_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: true,
        reference: 'deleted',
        assetClasses: {
          ...state.assetClasses,
          content: state.assetClasses.content.filter(
            (ac: any): any => ac.id !== action.id
          ),
        },
      };
    case types.ASSET_CLASS_REMOVE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default AssetClass;
