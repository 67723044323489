import CorrelationService from '../services/CorrelationService';
import * as types from '../constants/Correlation';
import { AssetClassCorrelationType } from '../Types';

const getMatrix = (assetClassSetId: number): any => (dispatch: any): void => {
  dispatch({ type: types.CORRELATION_MATRIX_REQUEST });
  CorrelationService.getMatrix(assetClassSetId)
    .then((matrix: AssetClassCorrelationType): void => {
      dispatch({ type: types.CORRELATION_MATRIX_SUCCESS, matrix });
    })
    .catch((error: any): void => {
      dispatch({ type: types.CORRELATION_MATRIX_FAILURE, error });
    });
};

const saveCorrelation = (data: any, assetClassSetId: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.SAVE_CORRELATION_REQUEST });
  CorrelationService.saveCorrelation(data)
    .then((correlation: any): void => {
      dispatch({ type: types.SAVE_CORRELATION_SUCCESS, correlation });
      dispatch(getMatrix(assetClassSetId));
    })
    .catch((error: any): void => {
      dispatch({ type: types.SAVE_CORRELATION_FAILURE, error });
    });
};

const changeCorrelationStatus = (data: any, assetClassSetId: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.CHANGE_CORRELATION_STATUS_REQUEST });
  CorrelationService.changeCorrelationStatus(data)
    .then((correlation: any): void => {
      dispatch({
        type: types.CHANGE_CORRELATION_STATUS_SUCCESS,
        correlation,
      });
      dispatch(getMatrix(assetClassSetId));
    })
    .catch((error: any): void => {
      dispatch({ type: types.CHANGE_CORRELATION_STATUS_FAILURE, error });
    });
};

const CorrelationActions = {
  getMatrix,
  saveCorrelation,
  changeCorrelationStatus,
};

export default CorrelationActions;
