import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    uploadButton: {
      marginTop: '16px',
      marginBottom: '8px',
    },
    uploadText: {
      marginTop: '8px',
      width: '100%',
    },
    progress: {
      margin: 'auto',
      position: 'absolute',
      width: '100%',
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      minHeight: 100,
    },
    text: {
      margin: '35px',
    },
    text2: {
      margin: '10px 0px',
    },
    selectButton: { marginRight: 5 },
    photoName: { marginTop: 2, width: '70%' },
    formContainer: {
      width: '100%',
      height: '100%',
      margin: '10px 30px',
      display: 'flex',
    },
    paper: {
      width: '40%',
    },
    labelCheckbox: {
      marginTop: 3,
      color: 'rgb(0,0,0,0.47)',
      marginLeft: '-8px',
      fontSize: '13px',
      fontWeight: 400,
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    checkbox: {
      marginLeft: '-5px',
      marginTop: '-9px',
      display: 'block',
    },
    checkboxroot: { color: 'rgb(0,0,0,0.23)' },
    formContainerForm: {
      width: '100%',
      height: '100%',
      margin: '10px 30px',
    },
    fullField: { margin: '5px 5px', width: '98%' },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: '10px',
      marginBottom: '4px',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    success: {
      backgroundColor: theme.palette.common.green,
    },
    error: {
      backgroundColor: theme.palette.common.red,
    },
    info: {
      color: 'rgb(0,0,0,0.5)',
      marginLeft: 5,
      fontSize: '10px',
      fontWeight: 350,
    },
  });

export default styles;
