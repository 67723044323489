import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
      backgroundColor: '#eff0f1',
    },
    appContainer: { width: '100%', height: 'calc(100% - 30px)' },
    nameAndDateContainer: {
      width: '100%',
      marginTop: '38px',
      marginBottom: '20px',
      minHeight: '64px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: '24px',
    },
    tabsValue: {
      height: 'calc(100% - 140px)',
      overflow: 'auto',
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: 'white',
    },
    nameContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    clientName: {
      display: 'inline',
      paddingTop: '5px',
    },
    pickerContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    button: { width: 100, paddingTop: 7, margin: '0 5px' },
    iconButton: { height: 42, padding: 4 },
    yearPickerContainer: {
      width: 100,
      display: 'inline-block',
      margin: '0px 5px',
    },
    yearPicker: { fontSize: '12px', color: '#039be5' },
    accountButton: {
      display: 'inline',
      marginLeft: '10px',
      padding: '0px 10px',
      width: '95px',
      [theme.breakpoints.down('md')]: {
        width: '80px',
      },
    },
    editButton: {
      marginLeft: '10px',
      marginBottom: '4px',
    },
    invisRoot: {
      minWidth: '20px',
      maxWidth: '20px',
      width: '20px',
    },
    progressBar: {
      position: 'absolute',
      width: '100%',
    },
    toolbar: {
      justifyContent: 'space-between',
      marginTop: '0px',
      marginBottom: '10px',
      paddingLeft: '29px',
      paddingRight: '24px',
    },
    loaderContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      position: 'absolute',
    },
    selection: {
      fontSize: '13px',
      color: 'red',
      alignSelf: 'start',
      height: '15px',
    },
    reportPaperDialog: {
      width: '80%',
      height: '70%',
    },
    fullField: {
      width: '50%',
      margin: '10px 35px',
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      minHeight: 100,
      position: 'relative',
    },
    progress: {
      margin: 'auto',
      position: 'absolute',
      width: '100%',
      top: 0,
    },
    firstLoad: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: '10px',
      marginBottom: '4px',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    success: {
      backgroundColor: theme.palette.common.green,
    },
    error: {
      backgroundColor: theme.palette.common.red,
    },
    dailogContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      minHeight: 100,
    },
    text: {
      margin: '35px',
    },
    warning: {
      backgroundColor: '#ff9800',
    },
  });

export default styles;
