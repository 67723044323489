import * as types from '../constants/DatePicker';

const DatePicker = (state: any = {}, action: any): any => {
  switch (action.type) {
    case types.DATEPICKER_SET_YEAR:
      return {
        ...state,
        year: action.year,
      };

    case types.DATEPICKER_SET_MONTH:
      return {
        ...state,
        month: action.month,
      };

    default:
      return state;
  }
};

export default DatePicker;
