import React, {
  ReactElement,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Scatter, Pie } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import DataTable from '../ClientDataTable';
import riskImage0 from './RiskImages/risicowijzer_afm_200px_0.png';
import riskImage1 from './RiskImages/risicowijzer_afm_200px_1.png';
import riskImage2 from './RiskImages/risicowijzer_afm_200px_2.png';
import riskImage3 from './RiskImages/risicowijzer_afm_200px_3.png';
import riskImage4 from './RiskImages/risicowijzer_afm_200px_4.png';
import riskImage5 from './RiskImages/risicowijzer_afm_200px_5.png';
import riskImage6 from './RiskImages/risicowijzer_afm_200px_6.png';
import riskImage7 from './RiskImages/risicowijzer_afm_200px_7.png';
import riskImage8 from './RiskImages/risicowijzer_afm_200px_8.png';
import riskImage9 from './RiskImages/risicowijzer_afm_200px_9.png';
import riskImage10 from './RiskImages/risicowijzer_afm_200px_10.png';
import riskImage11 from './RiskImages/risicowijzer_afm_200px_11.png';
import riskImage12 from './RiskImages/risicowijzer_afm_200px_12.png';
import riskImage13 from './RiskImages/risicowijzer_afm_200px_13.png';
import riskImage14 from './RiskImages/risicowijzer_afm_200px_14.png';
import riskImage15 from './RiskImages/risicowijzer_afm_200px_15.png';
import riskImage16 from './RiskImages/risicowijzer_afm_200px_16.png';
import riskImage17 from './RiskImages/risicowijzer_afm_200px_17.png';
import riskImage18 from './RiskImages/risicowijzer_afm_200px_18.png';
import riskImage19 from './RiskImages/risicowijzer_afm_200px_19.png';
import riskImage20 from './RiskImages/risicowijzer_afm_200px_20.png';
import riskImage21 from './RiskImages/risicowijzer_afm_200px_21.png';
import riskImage22 from './RiskImages/risicowijzer_afm_200px_22.png';
import riskImage23 from './RiskImages/risicowijzer_afm_200px_23.png';
import riskImage24 from './RiskImages/risicowijzer_afm_200px_24.png';
import riskImage25 from './RiskImages/risicowijzer_afm_200px_25.png';
import styles from './styles';
import { MonitorType, ChartingRowType, BenchmarkType } from '../../../../Types';

const riskImages: { [key: string]: any } = {
  riskImage0,
  riskImage1,
  riskImage2,
  riskImage3,
  riskImage4,
  riskImage5,
  riskImage6,
  riskImage7,
  riskImage8,
  riskImage9,
  riskImage10,
  riskImage11,
  riskImage12,
  riskImage13,
  riskImage14,
  riskImage15,
  riskImage16,
  riskImage17,
  riskImage18,
  riskImage19,
  riskImage20,
  riskImage21,
  riskImage22,
  riskImage23,
  riskImage24,
  riskImage25,
};

const colors = ['#bd7db9', '#6acff5', '#add660', '#ffd842', '#f2665a'];

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  stateMonitorData?: MonitorType;
  year: number;
  month: number;
  monitor?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>,
    filteredRows: Array<number>
  ) => Promise<MonitorType>;
  clientId: number;
  readOnly?: boolean;
  parentReference: any;
  getNode?: (ref: any, name: string, id: number) => void;
  cardId?: number;
  selectedShowingRows?: Array<number | string>;
  selectedRows?: Array<number>;
  selectedColumns?: Array<number>;
  selectedDate?: any;
  setMonitorShowingRows?: (id: number, rows: Array<number | string>) => void;
  selectedChart?: string;
  setMonitorChart?: (id: number, name: string) => void;
  showNotification: () => void;
  getList?: () => Promise<{ content: Array<BenchmarkType> }>;
  getMonitorRow?: (
    year: number,
    month: number,
    id: number,
    count: number
  ) => Promise<ChartingRowType>;
  rootClass?: any;
  root1Class?: any;
  selectionChanged?: boolean;
}

const Monitor = (props: Props): ReactElement => {
  const {
    clientId,
    selectedDate,
    cardId,
    month,
    year,
    monitor,
    selectedRows,
    selectedColumns,
    showNotification,
    getList,
    selectedShowingRows,
    getNode,
    readOnly,
    setMonitorShowingRows,
    getMonitorRow,
    selectedChart,
    setMonitorChart,
    parentReference,
    rootClass,
    root1Class,
    selectionChanged,
  } = props;

  const [tableRows, setTableRows] = useState<any>([]);
  const [finalTableRows, setFinalTableRows] = useState<any>([]);
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [hovered, setHovered] = useState<string>('');
  const [count, setCount] = useState<number>(0);
  const [benchmarksArray, setBenchmarksArray] = useState<Array<any>>([]);
  const [hasStrategic, setHasStrategic] = useState<boolean>(false);
  const [hasTactical, setHasTactical] = useState<boolean>(false);
  const [profileRows, setProfileRows] = useState<Array<any>>([]);
  const [stateMonitorData, setStateMonitorData] = useState<
    undefined | MonitorType
  >(undefined);
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {});

  const setMonitorData = (
    newMonitorData: MonitorType,
    newBenchmarksArray: any
  ): void => {
    if (newMonitorData) {
      const { rows, columns } = newMonitorData.table;
      const strategicRow = rows.find(each => each.title === 'Strategic');
      const tacticalRow = rows.find(each => each.title === 'Tactical');
      const receivedProfileRows = rows.filter(
        (eachRow: any) =>
          eachRow.title !== 'Strategic' &&
          eachRow.title !== 'Tactical' &&
          eachRow.title !== 'Portfolio' &&
          !newBenchmarksArray.find(
            (eachBenchmark: any) => eachBenchmark?.name === eachRow.title
          )
      );
      const newColumns = Object.keys(columns).map((key: string): any => ({
        title:
          // eslint-disable-next-line no-nested-ternary
          key === 'StD' && columns[key] !== 'St.Dev. 24M' ? (
            <span>
              St.Dev.
              <span style={{ color: 'red' }}>
                {columns[key].replace('St.Dev. ', '')}
              </span>
            </span>
          ) : key === 'ReturnHistory' && columns[key] !== 'Return 24M' ? (
            <span>
              Return
              <span style={{ color: 'red' }}>
                {columns[key].replace('Return ', '')}
              </span>
            </span>
          ) : (
            columns[key]
          ),
        name: key,
      }));
      newColumns.unshift({ title: 'Portfolio Summary', name: 'name' });
      let newRows: Array<any> = [];
      if (selectedShowingRows && selectedShowingRows.length > 0) {
        newRows = selectedShowingRows.map((each: any, index: number): any => {
          if (typeof each === 'string' && each.indexOf('Portfolio') > -1) {
            const foundRow = rows.find(
              (eachRow: ChartingRowType): any => eachRow.title === 'Portfolio'
            );
            const object: { [key: string]: any } = {
              title: foundRow && foundRow.title,
              color: colors[index],
              name: `${foundRow?.title}-${index}`,
              value: foundRow?.title || '',
              id: `${foundRow?.title}-${index}`,
              calculations: foundRow && foundRow.calculations,
            };
            if (foundRow)
              foundRow.calculations.forEach((value: any): void => {
                object[`${value.type}`] = value.value;
                object[`${value.type}-label`] = value.calculation;
                if (value.partial)
                  object[`${value.type}-style`] = {
                    backgroundColor: '#FFCCCC',
                    backgroundClip: 'content-box',
                  };
              });
            return object;
          }
          if (typeof each === 'string' && each.indexOf('Strategic') > -1) {
            const foundRow = rows.find(
              (eachRow: ChartingRowType): any => eachRow.title === 'Strategic'
            );
            const object: { [key: string]: any } = {
              title: foundRow && foundRow.title,
              color: colors[index],
              name: `${foundRow?.title}-${index}`,
              value: foundRow?.title || '',
              id: `${foundRow?.title}-${index}`,
              calculations: foundRow && foundRow.calculations,
            };
            if (foundRow)
              foundRow.calculations.forEach((value: any): void => {
                object[`${value.type}`] = value.value;
                object[`${value.type}-label`] = value.calculation;
                if (value.partial)
                  object[`${value.type}-style`] = {
                    backgroundColor: '#FFCCCC',
                    backgroundClip: 'content-box',
                  };
              });
            return object;
          }
          if (typeof each === 'string' && each.indexOf('Tactical') > -1) {
            const foundRow = rows.find(
              (eachRow: ChartingRowType): any => eachRow.title === 'Tactical'
            );
            const object: { [key: string]: any } = {
              title: foundRow && foundRow.title,
              color: colors[index],
              name: `${foundRow?.title}-${index}`,
              value: foundRow?.title || '',
              id: `${foundRow?.title}-${index}`,
              calculations: foundRow && foundRow.calculations,
            };
            if (foundRow)
              foundRow.calculations.forEach((value: any): void => {
                object[`${value.type}`] = value.value;
                object[`${value.type}-label`] = value.calculation;
                if (value.partial)
                  object[`${value.type}-style`] = {
                    backgroundColor: '#FFCCCC',
                    backgroundClip: 'content-box',
                  };
              });
            return object;
          }
          if (
            typeof each === 'string' &&
            receivedProfileRows.find(
              (eachProfile: any) => each.indexOf(eachProfile.title) > -1
            )
          ) {
            const foundRow = receivedProfileRows.find(
              (eachProfile: any) => each.indexOf(eachProfile.title) > -1
            );
            const object: { [key: string]: any } = {
              title: foundRow && foundRow.title,
              color: colors[index],
              name: `${foundRow?.title}-${index}`,
              value: foundRow?.title || '',
              id: `${foundRow?.title}-${index}`,
              calculations: foundRow && foundRow.calculations,
            };
            if (foundRow)
              foundRow.calculations.forEach((value: any): void => {
                object[`${value.type}`] = value.value;
                object[`${value.type}-label`] = value.calculation;
                if (value.partial)
                  object[`${value.type}-style`] = {
                    backgroundColor: '#FFCCCC',
                    backgroundClip: 'content-box',
                  };
              });
            return object;
          }
          if (typeof each === 'string' && each.indexOf('None') > -1) {
            const object = {
              title: 'None',
              color: colors[index],
              name: `None-${index}`,
              value: 'None',
              id: `None-${index}`,
              calculations: [],
            };
            return object;
          }
          if (typeof each === 'string') {
            const object = {
              title: 'None',
              color: colors[index],
              name: `None-${index}`,
              value: 'None',
              id: `None-${index}`,
              calculations: [],
            };
            return object;
          }
          const foundBenchmark = newBenchmarksArray.find(
            (eachBenchmark: any): any => eachBenchmark.id === each
          );
          const foundRow = rows.find(
            (eachRow: ChartingRowType): any =>
              eachRow.title === foundBenchmark.name
          );
          const object: { [key: string]: any } = {
            title: foundRow && foundRow.title,
            color: colors[index],
            name: `${foundRow?.title}-${index}`,
            value: foundRow?.title || '',
            id: `${foundRow?.title}-${index}`,
            calculations: foundRow && foundRow.calculations,
          };
          if (foundRow)
            foundRow.calculations.forEach((value: any): void => {
              object[`${value.type}`] = value.value;
              object[`${value.type}-label`] = value.calculation;
              if (value.partial)
                object[`${value.type}-style`] = {
                  backgroundColor: '#FFCCCC',
                  backgroundClip: 'content-box',
                };
            });
          return object;
        });
      } else {
        const filteredRows = rows.filter(
          each =>
            each.title === 'Portfolio' ||
            each.title === 'Strategic' ||
            each.title === 'Tactical'
        );
        const benchmarkRows = rows.filter(
          each =>
            each.title !== 'Portfolio' &&
            each.title !== 'Strategic' &&
            each.title !== 'Tactical' &&
            !receivedProfileRows.find(
              (eachProfile: any) => eachProfile.title === each.title
            )
        );
        const finalRows = filteredRows.concat(
          benchmarkRows.slice(0, 5 - filteredRows.length)
        );
        newRows = finalRows.map((each: ChartingRowType, index: number): any => {
          const object: { [key: string]: any } = {
            title: each.title,
            color: colors[index],
            name: `${each.title}-${index}`,
            value: each.title || '',
            id: `${each.title}-${index}`,
            calculations: each.calculations,
          };
          each.calculations.forEach((value: any): void => {
            object[`${value.type}`] = value.value;
            object[`${value.type}-label`] = value.calculation;
            if (value.partial)
              object[`${value.type}-style`] = {
                backgroundColor: '#FFCCCC',
                backgroundClip: 'content-box',
              };
          });
          return object;
        });
      }
      setTableColumns(newColumns);
      setCount(newMonitorData.monthCount);
      setHasStrategic(!!strategicRow);
      setHasTactical(!!tacticalRow);
      setProfileRows(receivedProfileRows);
      setTimeout(() => setTableRows(newRows), 300);
      if (readOnly) {
        setTimeout(() => {
          if (getNode && cardId) getNode(containerRef, 'MONITOR', cardId);
        }, 1300);
      }
    }
  };

  const handleChange = (
    event: any,
    rowId: string,
    newTableRows: Array<any>,
    newBenchmarksArray: Array<any>,
    newCount: number,
    newProfileRows: Array<any>
  ): void => {
    const { value } = event.target;
    if (value === 'None') {
      const newRows = newTableRows.map((each: any, index: number): any => {
        if (each.id === rowId) {
          const object = {
            title: 'None',
            color: colors[index],
            name: `None-${index}`,
            value: 'None',
            id: `None-${index}`,
            calculations: [],
          };
          return object;
        }
        return each;
      });
      setTableRows(newRows);
      const newSelectedShowingRows = newRows.map((each: any): any => {
        if (
          each.id.indexOf('None') > -1 ||
          each.id.indexOf('Tactical') > -1 ||
          each.id.indexOf('Strategic') > -1 ||
          each.id.indexOf('Portfolio') > -1 ||
          newProfileRows.find(
            (eachprofileRow: any) => each.id.indexOf(eachprofileRow.title) > -1
          )
        ) {
          return each.id;
        }
        const { id } = newBenchmarksArray.find(
          (eachBenchmark: any): any => eachBenchmark.name === each.title
        );
        return id;
      });
      if (setMonitorShowingRows)
        setMonitorShowingRows(clientId, newSelectedShowingRows);
    } else if (value === 'Portfolio') {
      const rowData =
        stateMonitorData &&
        stateMonitorData.table &&
        stateMonitorData.table.rows &&
        stateMonitorData.table.rows.find(
          (each: any): any => each.title === 'Portfolio'
        );
      const newRows = newTableRows.map((each: any, index: number): any => {
        if (each.id === rowId && rowData) {
          const object: { [key: string]: any } = {
            title: rowData.title,
            color: colors[index],
            name: `${rowData.title}-${index}`,
            value: rowData.title || '',
            id: `${rowData.title}-${index}`,
            calculations: rowData.calculations,
          };
          rowData.calculations.forEach((eachValue: any): void => {
            object[`${eachValue.type}`] = eachValue.value;
            object[`${eachValue.type}-label`] = eachValue.calculation;
            if (eachValue.partial)
              object[`${eachValue.type}-style`] = {
                backgroundColor: '#FFCCCC',
                backgroundClip: 'content-box',
              };
          });
          return object;
        }
        return each;
      });
      setTableRows(newRows);
      const newSelectedShowingRows = newRows.map((each: any): any => {
        if (
          each.id.indexOf('None') > -1 ||
          each.id.indexOf('Tactical') > -1 ||
          each.id.indexOf('Strategic') > -1 ||
          each.id.indexOf('Portfolio') > -1 ||
          newProfileRows.find(
            (eachprofileRow: any) => each.id.indexOf(eachprofileRow.title) > -1
          )
        ) {
          return each.id;
        }
        const { id } = newBenchmarksArray.find(
          (eachBenchmark: any): any => eachBenchmark.name === each.title
        );
        return id;
      });
      if (setMonitorShowingRows)
        setMonitorShowingRows(clientId, newSelectedShowingRows);
    } else if (value === 'Strategic') {
      const rowData =
        stateMonitorData &&
        stateMonitorData.table &&
        stateMonitorData.table.rows &&
        stateMonitorData.table.rows.find(
          (each: any): any => each.title === 'Strategic'
        );
      const newRows = newTableRows.map((each: any, index: number): any => {
        if (each.id === rowId && rowData) {
          const object: { [key: string]: any } = {
            title: rowData.title,
            color: colors[index],
            name: `${rowData.title}-${index}`,
            value: rowData.title || '',
            id: `${rowData.title}-${index}`,
            calculations: rowData.calculations,
          };
          rowData.calculations.forEach((eachValue: any): void => {
            object[`${eachValue.type}`] = eachValue.value;
            object[`${eachValue.type}-label`] = eachValue.calculation;
            if (eachValue.partial)
              object[`${eachValue.type}-style`] = {
                backgroundColor: '#FFCCCC',
                backgroundClip: 'content-box',
              };
          });
          return object;
        }
        return each;
      });
      setTableRows(newRows);
      const newSelectedShowingRows = newRows.map((each: any): any => {
        if (
          each.id.indexOf('None') > -1 ||
          each.id.indexOf('Tactical') > -1 ||
          each.id.indexOf('Strategic') > -1 ||
          each.id.indexOf('Portfolio') > -1 ||
          newProfileRows.find(
            (eachprofileRow: any) => each.id.indexOf(eachprofileRow.title) > -1
          )
        ) {
          return each.id;
        }
        const { id } = newBenchmarksArray.find(
          (eachBenchmark: any): any => eachBenchmark.name === each.title
        );
        return id;
      });
      if (setMonitorShowingRows)
        setMonitorShowingRows(clientId, newSelectedShowingRows);
    } else if (value === 'Tactical') {
      const rowData =
        stateMonitorData &&
        stateMonitorData.table &&
        stateMonitorData.table.rows &&
        stateMonitorData.table.rows.find(
          (each: any): any => each.title === 'Tactical'
        );
      const newRows = newTableRows.map((each: any, index: number): any => {
        if (each.id === rowId && rowData) {
          const object: { [key: string]: any } = {
            title: rowData.title,
            color: colors[index],
            name: `${rowData.title}-${index}`,
            value: rowData.title || '',
            id: `${rowData.title}-${index}`,
            calculations: rowData.calculations,
          };
          rowData.calculations.forEach((eachValue: any): void => {
            object[`${eachValue.type}`] = eachValue.value;
            object[`${eachValue.type}-label`] = eachValue.calculation;
            if (eachValue.partial)
              object[`${eachValue.type}-style`] = {
                backgroundColor: '#FFCCCC',
                backgroundClip: 'content-box',
              };
          });
          return object;
        }
        return each;
      });
      setTableRows(newRows);
      const newSelectedShowingRows = newRows.map((each: any): any => {
        if (
          each.id.indexOf('None') > -1 ||
          each.id.indexOf('Tactical') > -1 ||
          each.id.indexOf('Strategic') > -1 ||
          each.id.indexOf('Portfolio') > -1 ||
          newProfileRows.find(
            (eachprofileRow: any) => each.id.indexOf(eachprofileRow.title) > -1
          )
        ) {
          return each.id;
        }
        const { id } = newBenchmarksArray.find(
          (eachBenchmark: any): any => eachBenchmark.name === each.title
        );
        return id;
      });
      if (setMonitorShowingRows)
        setMonitorShowingRows(clientId, newSelectedShowingRows);
    } else if (
      newProfileRows.find(
        (eachProfileRow: any) => eachProfileRow.title === value
      )
    ) {
      const rowData =
        stateMonitorData &&
        stateMonitorData.table &&
        stateMonitorData.table.rows &&
        stateMonitorData.table.rows.find(
          (each: any): any => each.title === value
        );
      const newRows = newTableRows.map((each: any, index: number): any => {
        if (each.id === rowId && rowData) {
          const object: { [key: string]: any } = {
            title: rowData.title,
            color: colors[index],
            name: `${rowData.title}-${index}`,
            value: rowData.title || '',
            id: `${rowData.title}-${index}`,
            calculations: rowData.calculations,
          };
          rowData.calculations.forEach((eachValue: any): void => {
            object[`${eachValue.type}`] = eachValue.value;
            object[`${eachValue.type}-label`] = eachValue.calculation;
            if (eachValue.partial)
              object[`${eachValue.type}-style`] = {
                backgroundColor: '#FFCCCC',
                backgroundClip: 'content-box',
              };
          });
          return object;
        }
        return each;
      });
      setTableRows(newRows);
      const newSelectedShowingRows = newRows.map((each: any): any => {
        if (
          each.id.indexOf('None') > -1 ||
          each.id.indexOf('Tactical') > -1 ||
          each.id.indexOf('Strategic') > -1 ||
          each.id.indexOf('Portfolio') > -1 ||
          newProfileRows.find(
            (eachprofileRow: any) => each.id.indexOf(eachprofileRow.title) > -1
          )
        ) {
          return each.id;
        }
        const { id } = newBenchmarksArray.find(
          (eachBenchmark: any): any => eachBenchmark.name === each.title
        );
        return id;
      });
      if (setMonitorShowingRows)
        setMonitorShowingRows(clientId, newSelectedShowingRows);
    } else {
      const benchmark = newBenchmarksArray.find(
        (each: any): any => each.name === value
      );
      if (getMonitorRow)
        getMonitorRow(year, month, benchmark?.id, newCount)
          .then((rowData: ChartingRowType): void => {
            const newRows = newTableRows.map(
              (each: any, index: number): any => {
                if (each.id === rowId) {
                  const object: { [key: string]: any } = {
                    title: rowData.title,
                    color: colors[index],
                    name: `${rowData.title}-${index}`,
                    value: rowData.title || '',
                    id: `${rowData.title}-${index}`,
                    calculations: rowData.calculations,
                  };
                  rowData.calculations.forEach((eachValue: any): void => {
                    object[`${eachValue.type}`] = eachValue.value;
                    object[`${eachValue.type}-label`] = eachValue.calculation;
                    if (eachValue.partial)
                      object[`${eachValue.type}-style`] = {
                        backgroundColor: '#FFCCCC',
                        backgroundClip: 'content-box',
                      };
                  });
                  return object;
                }
                return each;
              }
            );
            setTableRows(newRows);
            const newSelectedShowingRows = newRows.map((each: any): any => {
              if (
                each.id.indexOf('None') > -1 ||
                each.id.indexOf('Tactical') > -1 ||
                each.id.indexOf('Strategic') > -1 ||
                each.id.indexOf('Portfolio') > -1 ||
                newProfileRows.find(
                  (eachprofileRow: any) =>
                    each.id.indexOf(eachprofileRow.title) > -1
                )
              ) {
                return each.id;
              }
              const { id } = newBenchmarksArray.find(
                (eachBenchmark: any): any => eachBenchmark.name === each.title
              );
              return id;
            });
            if (setMonitorShowingRows)
              setMonitorShowingRows(clientId, newSelectedShowingRows);
          })
          .catch(() => showNotification && showNotification());
    }
  };

  const createSelectCell = (
    name: string,
    value: string,
    newBenchmarksArray: Array<any>,
    hasStrategicRow: boolean,
    hasTacticalRow: boolean,
    newCount: number,
    newTableRows: Array<any>,
    newProfileRows: Array<any>
  ): ReactElement => {
    return (
      <TextField
        id="outlined-select-monitor-title"
        select
        value={value}
        onChange={(event: any): any =>
          handleChange(
            event,
            name,
            newTableRows,
            newBenchmarksArray,
            newCount,
            newProfileRows
          )
        }
        margin="none"
        variant="outlined"
        InputProps={{
          classes: {
            root: classes.asb,
            input: classes.input,
            notchedOutline: classes.borderClass,
          },
        }}
        classes={{
          root: classes.test,
        }}
      >
        <MenuItem value="None" style={{ fontSize: '12px' }}>
          None
        </MenuItem>
        <MenuItem value="Portfolio" style={{ fontSize: '12px' }}>
          Portfolio
        </MenuItem>
        {hasStrategicRow && (
          <MenuItem value="Strategic" style={{ fontSize: '12px' }}>
            Strategic
          </MenuItem>
        )}
        {hasTacticalRow && (
          <MenuItem value="Tactical" style={{ fontSize: '12px' }}>
            Tactical
          </MenuItem>
        )}
        <Divider />
        {newProfileRows &&
          newProfileRows.length > 0 &&
          newProfileRows.map((eachProfileRow: any, idx: number) => (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${eachProfileRow.title}-${idx}`}
              value={eachProfileRow.title}
              style={{ fontSize: '12px' }}
            >
              {eachProfileRow.title}
            </MenuItem>
          ))}
        <Divider />
        {newBenchmarksArray.map(
          (option: any, idx: number): ReactElement => (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${option.name}-${idx}`}
              value={option.name}
              style={{ fontSize: '12px' }}
            >
              {option.name}
            </MenuItem>
          )
        )}
      </TextField>
    );
  };

  const setSelectToTableRows = (
    newTableRows: Array<any>,
    newBenchmarksArray: Array<any>,
    hasStrategicRow: boolean,
    hasTacticalRow: boolean,
    newCount: number,
    newProfileRows: Array<any>
  ): void => {
    const newFinalRows = tableRows.map((each: any): any => ({
      ...each,
      name: createSelectCell(
        each.name,
        each.value,
        newBenchmarksArray,
        hasStrategicRow,
        hasTacticalRow,
        newCount,
        newTableRows,
        newProfileRows
      ),
    }));
    setFinalTableRows(newFinalRows);
  };

  useEffect(() => {
    setSelectToTableRows(
      tableRows,
      benchmarksArray,
      hasStrategic,
      hasTactical,
      count,
      profileRows
    );
  }, [tableRows]);

  const getMonitorData = (): void => {
    if (getList)
      getList().then((benchmarks: { content: Array<BenchmarkType> }): void => {
        const newBenchmarksArray =
          benchmarks &&
          benchmarks.content &&
          benchmarks.content
            .filter((each: BenchmarkType) => !each.hidden)
            .map((each: BenchmarkType): any => ({
              id: each.id,
              name: each.name,
            }));

        if (selectedShowingRows && selectedShowingRows.length > 0) {
          const filteredShowingRows = selectedShowingRows.filter(
            (each: any): any => typeof each !== 'string'
          );
          if (monitor && selectedRows && selectedColumns)
            monitor(
              clientId,
              year,
              month,
              selectedRows,
              selectedColumns,
              filteredShowingRows as Array<number>
            )
              .then((data: MonitorType): void => {
                setMonitorData(data, newBenchmarksArray);
                setStateMonitorData(data);
                setBenchmarksArray([...newBenchmarksArray]);
              })
              .catch(() => showNotification && showNotification());
        } else if (monitor && selectedRows && selectedColumns)
          monitor(
            clientId,
            year,
            month,
            selectedRows,
            selectedColumns,
            newBenchmarksArray.slice(0, 4).map((each: any): any => each.id)
          )
            .then((data: MonitorType): void => {
              setMonitorData(data, newBenchmarksArray);
              setStateMonitorData(data);
              setBenchmarksArray([...newBenchmarksArray]);
            })
            .catch(() => showNotification && showNotification());
      });
  };

  useEffect(() => {
    getMonitorData();
  }, [
    month,
    year,
    clientId,
    selectedRows,
    selectedColumns,
    selectedDate,
    selectionChanged,
  ]);

  const changScatterChart = (): void => {
    if (selectedChart === 'Risk' && setMonitorChart) {
      setMonitorChart(clientId, 'StD');
    }
    if (selectedChart === 'StD' && setMonitorChart) {
      setMonitorChart(clientId, 'Risk');
    }
  };

  const showTooltip = (event: any): any => {
    const { dataPoints } = event;
    if (stateMonitorData) {
      if (Array.isArray(dataPoints)) {
        setHovered(stateMonitorData.riskValues[dataPoints[0].index].title);
      }
    }
    return event;
  };

  const cancelHover = (): void => {
    setHovered('');
  };

  const riskColumns = [
    { title: 'Title', name: 'title' },
    { title: 'Value', name: 'value' },
  ];

  const scatterX1 = tableRows.map((row: any): any => {
    if (row.calculations.find((each: any): any => each.type === 'Risk'))
      return {
        title: row.title,
        x: row.calculations.find((each: any): any => each.type === 'Risk')
          .value,
      };
    return { title: row.title, x: 0 };
  });
  const scatterY1 = tableRows.map((row: any): any => {
    if (row.calculations.find((each: any): any => each.type === 'Return'))
      return row.calculations.find((each: any): any => each.type === 'Return')
        .value;
    return 0;
  });
  const scatterX2 = tableRows.map((row: any): any => {
    if (row.calculations.find((each: any): any => each.type === 'StD'))
      return {
        title: row.title,
        x: row.calculations.find((each: any): any => each.type === 'StD').value,
      };
    return { title: row.title, x: 0 };
  });
  const scatterY2 = tableRows.map((row: any): any => {
    if (
      row.calculations.find((each: any): any => each.type === 'ReturnHistory')
    )
      return row.calculations.find(
        (each: any): any => each.type === 'ReturnHistory'
      ).value;
    return 0;
  });
  const scatterData1 = scatterX1.map((each: any, idx: number): any => ({
    ...each,
    y: scatterY1[idx],
  }));
  const scatterData2 = scatterX2.map((each: any, idx: number): any => ({
    ...each,
    y: scatterY2[idx],
  }));

  const riskTotal =
    stateMonitorData &&
    stateMonitorData.riskValues
      .map((each: any): any => each.value)
      .reduce((total: any, each: any): number => Number(total) + Number(each));
  let scatterData: any[] = [];
  if (selectedChart === 'Risk') {
    scatterData = tableRows
      .map((each: any): any => ({
        ...scatterData1.find((data: any): any => data.title === each.title),
      }))
      .map((each: any): any => ({ x: each.x, y: each.y }));
  }

  if (selectedChart === 'StD') {
    scatterData = tableRows
      .map((each: any): any => ({
        ...scatterData2.find((data: any): any => data.title === each.title),
      }))
      .map((each: any): any => ({ x: each.x, y: each.y }));
  }

  return (
    <div className={classNames(classes.root1, root1Class)}>
      <div
        className={classNames(classes.root, rootClass)}
        ref={readOnly ? containerRef : parentReference}
      >
        <div className={classes.topContainer}>
          <DataTable
            title="Monitor"
            rows={
              finalTableRows.length > 0 ? finalTableRows : [{}, {}, {}, {}, {}]
            }
            columns={tableColumns}
            hovered={hovered}
            showToolbar={false}
            hasColor
            showNumber={tableRows.length > 0}
            showHeaders
          />
        </div>
        <div className={classes.bottomContainer}>
          <div className={classes.leftPart}>
            <Toolbar className={classes.toolbar}>
              <div className={classes.titleContainer}>
                <Typography
                  style={{ marginLeft: 28 }}
                  id="scatterTitle"
                  variant="h3"
                >
                  Chart
                </Typography>
              </div>

              <Button
                onClick={changScatterChart}
                variant="outlined"
                className={classNames(classes.addButton)}
                color="primary"
                classes={{ label: classes.buttonLabel }}
              >
                {selectedChart === 'Risk' ? 'Show Actual' : 'Show Factor'}
              </Button>
            </Toolbar>
            <div>
              <Scatter
                data={{
                  datasets: [
                    {
                      data: scatterData,
                      backgroundColor: colors,
                      scaleLabel: {
                        display: true,
                        labelString:
                          selectedChart === 'Risk'
                            ? 'Risk Factor'
                            : 'St.Dev.24M',
                      },
                      ticks: {
                        callback(value: any): string {
                          return `${(Math.round(value * 100) / 100).toFixed(
                            2
                          )}%`;
                        },
                      },
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  legend: {
                    display: false,
                  },
                  elements: {
                    point: {
                      radius: 10,
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString:
                            selectedChart === 'Risk'
                              ? 'Risk Factor'
                              : 'St.Dev.24M',
                        },
                        ticks: {
                          callback(value: any): string {
                            return `${(Math.round(value * 100) / 100).toFixed(
                              2
                            )}%`;
                          },
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString:
                            selectedChart === 'Risk'
                              ? 'Return Factor'
                              : 'Return 24M',
                        },
                        ticks: {
                          callback(value: any): string {
                            return `${(Math.round(value * 100) / 100).toFixed(
                              2
                            )}%`;
                          },
                        },
                      },
                    ],
                  },
                  tooltips: {
                    callbacks: {
                      label(tooltipItem: any, data: any): string {
                        let label =
                          data.datasets[tooltipItem.datasetIndex].label || '';

                        if (label) {
                          label += ': ';
                        }
                        label += `${(
                          Math.round(tooltipItem.xLabel * 100) / 100
                        ).toFixed(2)} ,${(
                          Math.round(tooltipItem.yLabel * 100) / 100
                        ).toFixed(2)}`;
                        return label;
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className={classes.middlePart}>
            <Toolbar className={classes.toolbar} style={{ marginBottom: 0 }}>
              <div
                className={classes.titleContainer}
                style={{ width: 285, margin: 'auto' }}
              >
                <Typography
                  className={classes.title}
                  id="tableTitle"
                  variant="h3"
                  style={{ marginLeft: '16px' }}
                >
                  Risk Indicator
                </Typography>
              </div>
            </Toolbar>
            <div className={classes.riskImage}>
              <Typography className={classes.number8}>8</Typography>

              <img
                style={{ width: 270, height: 160 }}
                src={
                  riskImages[
                    `riskImage${stateMonitorData &&
                      stateMonitorData.riskIndicator}`
                  ]
                }
                alt=""
              />
              <div className={classes.riskClassMinMax}>
                <div className={classes.riskLabels}>
                  <Typography>0</Typography>
                  <Typography>Low Risk</Typography>
                </div>
                <div className={classes.riskLabels}>
                  <Typography>25</Typography>
                  <Typography>High Risk</Typography>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.rightPart}>
            <div className={classes.table}>
              <DataTable
                title="Risk Classes"
                rows={(stateMonitorData && stateMonitorData.riskValues) || []}
                columns={riskColumns}
                hovered={hovered}
                showHeaders={false}
                hasColor
                showNumber
                noMarginToolbar
              />
            </div>
            <div className={classes.chart}>
              <Toolbar className={classes.toolbar} style={{ marginBottom: 0 }}>
                <div className={classes.titleContainer} style={{ width: 250 }}>
                  <Typography
                    id="tableTitle"
                    variant="h3"
                    style={{ textAlign: 'center', marginLeft: -150 }}
                  >
                    Risk Chart
                  </Typography>
                </div>
              </Toolbar>
              <div style={{ height: 'calc(100% - 109px)' }}>
                <div
                  className={classes.chartContainer}
                  onMouseLeave={cancelHover}
                >
                  <Pie
                    width={210}
                    height={210}
                    data={{
                      datasets: [
                        {
                          data: (stateMonitorData &&
                            stateMonitorData.riskValues &&
                            // eslint-disable-next-line
                              !(riskTotal === 0 || isNaN(riskTotal)) &&
                            stateMonitorData.riskValues.map(
                              (each: any): any => each.value
                            )) || [1],
                          backgroundColor: (stateMonitorData &&
                            stateMonitorData.riskValues &&
                            // eslint-disable-next-line
                              !(riskTotal === 0 || isNaN(riskTotal)) &&
                            stateMonitorData.riskValues.map(
                              (each: any): any => {
                                if (each.title === hovered)
                                  return `${each.color}99`;
                                return each.color;
                              }
                            )) || ['#D3D3D3'],
                        },
                      ],
                      labels: (stateMonitorData &&
                        stateMonitorData.riskValues &&
                        // eslint-disable-next-line
                          !(riskTotal === 0 || isNaN(riskTotal)) &&
                        stateMonitorData.riskValues.map(
                          (each: any): any => each.title
                        )) || ['No Data'],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      responsive: false,
                      legend: {
                        display: false,
                      },
                      tooltips: {
                        custom: (event: any): any => showTooltip(event),
                        enabled: false,
                      },
                      plugins: {
                        labels: {
                          render:
                            stateMonitorData &&
                            stateMonitorData.riskValues &&
                            // eslint-disable-next-line
                              !(riskTotal === 0 || isNaN(riskTotal))
                              ? 'percentage'
                              : 'label',
                          fontColor: 'white',
                          precision: 0,
                          fontSize: 11,
                          position: 'border',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Monitor.defaultProps = {
  stateMonitorData: undefined,
  readOnly: false,
  tableEnabledRows: [],
  tableDisabledColumns: [],
  isActual: false,
  tableValues: [],
  reportObject: undefined,
  setReportJSON: undefined,
  parentReference: undefined,
  getNode: undefined,
  cardId: undefined,
  selectedShowingRows: undefined,
  selectedRows: [],
  selectedColumns: [],
  selectedDate: undefined,
  selectedChart: undefined,
  showNotification: undefined,
};

export default Monitor;
