import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  ReactElement,
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Pie } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import DataTable from '../ClientDataTable';
import { setColor } from '../../../../helpers/utils';
import styles from './styles';
import { RegccyType } from '../../../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  regccys?: RegccyType;
  year: number;
  month: number;
  getRegccy?: (
    id: number,
    year: number,
    month: number,
    rows: Array<number>,
    columns: Array<number>
  ) => Promise<RegccyType>;
  clientId: number;
  readOnly?: boolean;
  parentReference: any;
  getNode?: (ref: any, name: string, id: number) => void;
  cardId?: number;
  setRegccyChart?: (id: number, name: string) => void;
  selectedChart?: string;
  selectedColumns?: Array<number>;
  selectedRows?: Array<number>;
  selectedDate?: any;
  showNotification: () => void;
  rootClass?: any;
  root1Class?: any;
}

const Regccy = (props: Props): ReactElement => {
  const {
    clientId,
    regccys,
    readOnly,
    selectedDate,
    cardId,
    getNode,
    setRegccyChart,
    selectedChart,
    parentReference,
    year,
    month,
    selectedRows,
    selectedColumns,
    getRegccy,
    showNotification,
    root1Class,
    rootClass,
  } = props;

  const [currencyPortions, setCurrencyPortions] = useState<any>([]);
  const [regionPortions, setRegionPortions] = useState<any>([]);
  const [hovered, setHovered] = useState<string>('');
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {});

  const setRegccys = (newRegccys?: RegccyType): void => {
    if (newRegccys) {
      const currencyPortionsColors = setColor(
        newRegccys.currencyPortions && newRegccys.currencyPortions.length
      );
      const regionPortionsColors = setColor(
        newRegccys.regionPortions && newRegccys.regionPortions.length
      );
      const newCurrencyPortions =
        newRegccys.currencyPortions &&
        newRegccys.currencyPortions.map((each: any, index: number): any => ({
          ...each,
          color: currencyPortionsColors[index],
        }));
      const newRegionPortions =
        newRegccys.regionPortions &&
        newRegccys.regionPortions.map((each: any, index: number): any => ({
          ...each,
          color: regionPortionsColors[index],
        }));
      setCurrencyPortions(newCurrencyPortions);
      setRegionPortions(newRegionPortions);
      if (readOnly)
        setTimeout(() => {
          if (getNode && cardId) getNode(containerRef, 'REGCCY', cardId);
        }, 2000);
    }
  };

  useEffect(() => {
    if (!readOnly) setRegccys(regccys);
  }, []);

  const getRegccyData = (): void => {
    if (getRegccy && selectedRows && selectedColumns)
      getRegccy(clientId, year, month, selectedRows, selectedColumns)
        .then((response: RegccyType): void => {
          setRegccys(response);
        })
        .catch((): any => showNotification && showNotification());
  };

  useEffect(() => {
    getRegccyData();
  }, [
    month,
    year,
    clientId,
    selectedRows,
    selectedColumns,
    cardId,
    selectedDate,
  ]);

  const showChart = (title: string): void => {
    if (title === 'Currency' && setRegccyChart) {
      setRegccyChart(clientId, 'currencyPortions');
    } else if (title === 'Region' && setRegccyChart) {
      setRegccyChart(clientId, 'regionPortions');
    }
  };

  const showTooltip = (event: any): any => {
    const { dataPoints } = event;
    if (Array.isArray(dataPoints)) {
      if (
        selectedChart === 'currencyPortions' &&
        currencyPortions &&
        Array.isArray(currencyPortions) &&
        currencyPortions.length > 0
      ) {
        if (
          currencyPortions &&
          currencyPortions.length &&
          currencyPortions
            .map((r: any): number => r.value)
            .reduce((total: number, each: number): number => total + each) !== 0
        ) {
          setHovered(currencyPortions[dataPoints[0].index].name);
        }
      } else if (
        selectedChart === 'regionPortions' &&
        regionPortions &&
        Array.isArray(regionPortions) &&
        regionPortions.length > 0
      ) {
        if (
          regionPortions &&
          regionPortions.length &&
          regionPortions
            .map((r: any): number => r.value)
            .reduce((total: number, each: number): number => total + each) !== 0
        ) {
          setHovered(regionPortions[dataPoints[0].index].name);
        }
      }
    } else {
      setHovered('');
    }
  };

  const cancelHover = (): void => {
    setHovered('');
  };

  const columns = [
    { title: 'Name', name: 'name' },
    { title: 'Value', name: 'value' },
    { title: 'Percentage', name: 'percentage' },
  ];

  const isRegionData =
    regionPortions &&
    regionPortions.length &&
    regionPortions
      .map((r: any): number => r.value)
      .reduce((total: number, each: number): number => total + each) !== 0;
  const isCurrencyData =
    currencyPortions &&
    currencyPortions.length &&
    currencyPortions
      .map((r: any): number => r.value)
      .reduce((total: number, each: number): number => total + each) !== 0;

  return (
    <div className={classNames(classes.root1, root1Class)}>
      <div
        className={classNames(classes.root, rootClass)}
        ref={readOnly ? containerRef : parentReference}
      >
        <div className={classes.table}>
          <DataTable
            title="Region"
            rows={regionPortions}
            columns={columns}
            onClickButton={showChart}
            isSelected={selectedChart === 'regionPortions'}
            hovered={hovered}
            hasTotalRow
            hasToolbarButton={!readOnly}
            ButtonLabel="Show Chart"
            hasColor
            type2
          />
        </div>
        <div className={classes.chart}>
          {selectedChart === 'currencyPortions' && (
            <div style={{ height: '100%', width: '99%', margin: '0 auto' }}>
              <Toolbar className={classes.toolbar}>
                <div className={classes.titleContainer}>
                  <Typography
                    className={classNames(classes.title)}
                    id="tableTitle"
                    variant="h3"
                  >
                    Currency Chart
                  </Typography>
                </div>
              </Toolbar>
              <div
                className={classes.chartContainer}
                onMouseLeave={cancelHover}
              >
                <Pie
                  data={{
                    datasets: [
                      {
                        data: isCurrencyData
                          ? currencyPortions.map(
                              (each: any): number => each.value
                            )
                          : [1],
                        backgroundColor: isCurrencyData
                          ? currencyPortions.map((each: any): string => {
                              if (each.name === hovered)
                                return `${each.color}99`;
                              return each.color;
                            })
                          : ['#D3D3D3'],
                      },
                    ],
                    labels: isCurrencyData
                      ? currencyPortions.map((each: any): string => each.name)
                      : ['No Data'],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                      display: false,
                    },
                    tooltips: {
                      custom: (event: any): any => showTooltip(event),
                      enabled: false,
                    },
                    plugins: {
                      labels: {
                        render: isCurrencyData ? 'percentage' : 'label',
                        fontColor: 'white',
                        precision: 0,
                        fontSize: 11,
                        position: 'border',
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
          {selectedChart === 'regionPortions' && (
            <div style={{ height: '100%', width: '99%', margin: '0 auto' }}>
              <Toolbar className={classes.toolbar}>
                <div className={classes.titleContainer}>
                  <Typography
                    className={classNames(classes.title)}
                    id="tableTitle"
                    variant="h3"
                  >
                    Region Chart
                  </Typography>
                </div>
              </Toolbar>
              <div
                className={classes.chartContainer}
                onMouseLeave={cancelHover}
              >
                <Pie
                  data={{
                    datasets: [
                      {
                        data: isRegionData
                          ? regionPortions.map(
                              (each: any): number => each.value
                            )
                          : [1],
                        backgroundColor: isRegionData
                          ? regionPortions.map((each: any): string => {
                              if (each.name === hovered)
                                return `${each.color}99`;
                              return each.color;
                            })
                          : ['#D3D3D3'],
                      },
                    ],
                    labels: isRegionData
                      ? regionPortions.map((each: any): string => each.name)
                      : ['No Data'],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                      display: false,
                    },
                    tooltips: {
                      custom: (event: any): any => showTooltip(event),
                      enabled: false,
                    },
                    plugins: {
                      labels: {
                        render: isRegionData ? 'percentage' : 'label',
                        fontColor: 'white',
                        precision: 0,
                        fontSize: 11,
                        position: 'border',
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className={classes.table}>
          <DataTable
            title="Currency"
            rows={currencyPortions}
            columns={columns}
            onClickButton={showChart}
            isSelected={selectedChart === 'currencyPortions'}
            hovered={hovered}
            hasTotalRow
            hasToolbarButton={!readOnly}
            ButtonLabel="Show Chart"
            hasColor
            type2
          />
        </div>
      </div>
    </div>
  );
};

Regccy.defaultProps = {
  regccys: undefined,
  readOnly: false,
  isLeftChart: false,
  tableEnabledRows: [],
  tableDisabledColumns: [],
  reportObject: undefined,
  setReportJSON: undefined,
  parentReference: undefined,
  getNode: undefined,
  cardId: undefined,
  selectedChart: undefined,
  selectedColumns: [],
  selectedRows: [],
  selectedDate: undefined,
  showNotification: undefined,
};

export default Regccy;
