import createStyles from '@material-ui/core/styles/createStyles';

const styles = (): any =>
  createStyles({
    formContainer: {
      width: '100%',
      margin: '10px 30px',
    },
    fullField: {
      margin: '5px 5px',
      width: '98%',
    },
    toolbar: {
      justifyContent: 'space-between',
      marginTop: '30px',
      marginBottom: '20px',
      paddingLeft: '24px',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    title: {
      lineHeight: '48px',
    },
    progress: {
      margin: 'auto',
      width: '100%',
      position: 'absolute',
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      minHeight: 100,
    },
    text: {
      margin: '35px',
    },
    invisRoot: {
      minWidth: '20px',
      maxWidth: '20px',
      width: '20px',
    },
    backRotButton: {
      height: '48px',
      marginLeft: '-18px',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    info: {
      color: 'rgb(0,0,0,0.5)',
      marginLeft: 5,
      fontSize: '10px',
      fontWeight: 350,
    },
  });

export default styles;
