import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import Simulation from './Simulation';
import AssetTableActions from '../../../../actions/AssetTableActions';
import ClientActions from '../../../../actions/ClientActions';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ ...AssetTableActions, ...ClientActions }, dispatch);

const getClientId = (state: any): string =>
  state.Client.selectedClient && state.Client.selectedClient.id;
const getClientData = (state: any): any => state.Client.clientData;

const getFoundClientData = createSelector(
  [getClientData, getClientId],
  (data, id) => data && data.find((each: any): any => each.id === id)
);

const getSelectedRows = createSelector(
  [getFoundClientData],
  data => data && data.selectedRows
);

const getSelectedColumns = createSelector(
  [getFoundClientData],
  data => data && data.selectedColumns
);

const mapStateToProps = (state: any): any => ({
  loading: state.AssetTable.loading || state.Client.loading,
  client: state.Client.selectedClient,
  profile: state.User.profile,
  selectedRows: getSelectedRows(state),
  selectedColumns: getSelectedColumns(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Simulation);
