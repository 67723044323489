import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssetClassActions from '../../actions/AssetClassActions';
import RiskClassActions from '../../actions/RiskClassActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import ExportActions from '../../actions/ExportActions';
import BenchmarkActions from '../../actions/BenchmarkActions';
import AssetClass from './AssetClass';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...AssetClassActions,
      ...DialogActions,
      ...UserActions,
      ...ExportActions,
      ...RiskClassActions,
      ...BenchmarkActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  deleted: state.AssetClass.deleted,
  assetClass: state.AssetClass.assetClass,
  assetClasses: state.AssetClass.assetClasses,
  riskClasses: state.RiskClass.riskClasses,
  success: state.AssetClass.success,
  error: state.AssetClass.error,
  loading: state.AssetClass.loading,
  benchmarks: state.Benchmark.benchmarks,
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetClass);
