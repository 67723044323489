import React, { useState, useEffect, ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dates from '../../constants/Date';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import { ClientType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  onClose: () => void;
  model: ClientType;
  open: boolean;
  success: boolean;
  loading: boolean;
  error: any;
  duplicate: (
    obj: { newName: string; month: number; year: number },
    id: number
  ) => void;
  currentMonth: number;
  currentYear: number;
}

const DialogForm = (props: Props): ReactElement => {
  const {
    model,
    open,
    onClose,
    loading,
    success,
    error,
    duplicate,
    currentYear,
    currentMonth,
  } = props;

  const [name, setName] = useState<string>('');
  const [yearDate, setYearDate] = useState<string | number>('');
  const [monthDate, setMonthDate] = useState<any | undefined>(undefined);
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const classes = useStyles();

  const resetFields = (): void => {
    setName('');
    setYearDate('');
    setMonthDate(undefined);
    setShowError(false);
    setShowNotification(false);
  };

  useEffect(() => {
    if (model && model.id && open) {
      setName(`${model.name} (2)`);
      const monthObj = Dates.months.find(
        eachMonth => eachMonth.valueNumber === currentMonth
      );
      setMonthDate(monthObj);
      setYearDate(currentYear);
    } else {
      resetFields();
    }
  }, [model, open]);

  const handleClose = (): void => {
    resetFields();
    onClose();
  };

  const canSubmit = (): boolean => {
    if (yearDate === '') return false;
    if (monthDate === undefined) return false;
    if (name.trim() === model.name) return false;
    return true;
  };

  const submit = (): void => {
    if (canSubmit()) {
      const data = {
        month: monthDate.valueNumber || 0,
        year: Number(yearDate),
        newName: name.trim(),
      };
      duplicate(data, model.id);
      setShowNotification(true);
    } else {
      setShowError(true);
    }
  };

  const retry = (): void => {
    setShowNotification(false);
  };

  let actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions = [
      {
        label: 'Back',
        onClick: retry,
      },
      {
        label: 'Cancel',
        onClick: handleClose,
        type: 'secondary',
      },
    ];
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      actions={actions}
      title="Duplicate Client"
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Client duplicated successfully.
          </Typography>
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error: {(error && error.messages && error.messages[0]) || 'Unknown'}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div className={classes.formContainer}>
          <TextField
            error={showError && (name === '' || name.trim() === model.name)}
            id="outlined-name"
            label="Name"
            value={name}
            onChange={(e: any): void => setName(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
            helperText={
              showError &&
              ((name === '' && 'Required') ||
                (name.trim() === model.name && 'Invalid name'))
            }
          />

          <div className={classes.duplicateDateContainer}>
            <TextField
              id="outlined-select-startMonthDate"
              select
              label="Month"
              value={monthDate || ''}
              onChange={(e: any): void => setMonthDate(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.dropDown}
              error={showError && monthDate === undefined}
              helperText={showError && monthDate === undefined && 'Required'}
            >
              <MenuItem value={undefined}>{'<Empty>'}</MenuItem>
              {Dates.months.map((option: any): any => (
                <MenuItem key={option.value} value={option}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="outlined-select-startYearDate"
              select
              label="Year"
              value={yearDate}
              onChange={(e: any): void => setYearDate(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.dropDown}
              error={showError && yearDate === ''}
              helperText={showError && yearDate === '' && 'Required'}
            >
              <MenuItem value="">{'<Empty>'}</MenuItem>
              {Dates.years.map((option: any): any => (
                <MenuItem key={option.value} value={option.valueNumber}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      )}
    </Dialog>
  );
};

DialogForm.defaultProps = {
  model: undefined,
  success: false,
  error: undefined,
};

export default DialogForm;
