import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccountCodeActions from '../../actions/AccountCodeActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import ExportActions from '../../actions/ExportActions';
import AccountCode from './AccountCode';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...AccountCodeActions,
      ...DialogActions,
      ...UserActions,
      ...ExportActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  accountCodes: state.AccountCode.accountCodes,
  deleted: state.AccountCode.deleted,
  accountCode: state.AccountCode.accountCode,
  success: state.AccountCode.success,
  error: state.AccountCode.error,
  loading: state.AccountCode.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountCode);
