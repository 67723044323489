import React, { useState, ReactElement } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ClientActions from '../../actions/ClientActions';
import { UserForm } from '../../scenes/Users';
import { ReportSettings } from '../../scenes/Reports';
import theme from '../../helpers/Theme';
import AppWrapper from './AppWrapper';
import ErrorBoundary from './ErrorBoundary';

interface Props {
  selectClient?: any;
  history: any;
  loggedIn?: boolean;
  children?: any;
  location?: any;
  profile?: any;
  currentEnvironment?: any;
}

const AppWrapperContainer = (props: Props): ReactElement => {
  const { loggedIn, children, selectClient, history, ...others } = props;

  const [hasError, setHasError] = useState<boolean>(false);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);

  const appTheme: any = createMuiTheme(theme);

  const ErrorSnackBar = (): ReactElement => (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={snackBarOpen}
      autoHideDuration={10000}
      onClose={(event: any, reason: any): void => {
        if (reason === 'clickaway') {
          setSnackBarOpen(false);
          return;
        }
        setSnackBarOpen(false);
      }}
    >
      <SnackbarContent
        aria-describedby="client-snackbar"
        message={
          <div>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={(): void => setSnackBarOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <h3 style={{ margin: '0 0 4px 0' }}>Oops, An Error Occurred.</h3>
            Something is broken. Please let us know what you were doing when
            this error occurred. We will fix it as soon as possible. Sorry for
            any inconvenience.
          </div>
        }
        style={{
          backgroundColor: '#d32f2f',
          color: 'white',
          fontWeight: 'bold',
        }}
      />
    </Snackbar>
  );

  if (hasError) {
    selectClient(null);
    history.push('/');
    setHasError(false);
    setSnackBarOpen(true);
  }
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { ...props })
  );
  return (
    <>
      <MuiThemeProvider theme={appTheme}>
        <ErrorSnackBar />
        <UserForm {...props} history={history} />
        <ReportSettings {...props} history={history} />
        <ErrorBoundary>
          <AppWrapper loggedIn={loggedIn} history={history} {...others}>
            {childrenWithProps}
          </AppWrapper>
        </ErrorBoundary>
      </MuiThemeProvider>
    </>
  );
};

AppWrapperContainer.propTypes = {};

AppWrapperContainer.defaultProps = {
  profile: undefined,
  currentEnvironment: undefined,
};

export default compose(
  withRouter,
  connect(
    (state: any): any => ({
      loggedIn: state.User.loggedIn,
      profile: state.User.profile,
      currentEnvironment: state.Environment.currentEnvironment,
    }),
    dispatch => bindActionCreators({ ...ClientActions }, dispatch)
  )
)(AppWrapperContainer);
