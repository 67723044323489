export const ASSET_CLASS_SET_LIST_REQUEST = 'ASSET_CLASS_SET_LIST_REQUEST';
export const ASSET_CLASS_SET_LIST_SUCCESS = 'ASSET_CLASS_SET_LIST_SUCCESS';
export const ASSET_CLASS_SET_LIST_FAILURE = 'ASSET_CLASS_SET_LIST_FAILURE';
export const ASSET_CLASS_SET_ITEM_REQUEST = 'ASSET_CLASS_SET_ITEM_REQUEST';
export const ASSET_CLASS_SET_ITEM_SUCCESS = 'ASSET_CLASS_SET_ITEM_SUCCESS';
export const ASSET_CLASS_SET_ITEM_FAILURE = 'ASSET_CLASS_SET_ITEM_FAILURE';
export const ASSET_CLASS_SET_CREATE_REQUEST = 'ASSET_CLASS_SET_CREATE_REQUEST';
export const ASSET_CLASS_SET_CREATE_SUCCESS = 'ASSET_CLASS_SET_CREATE_SUCCESS';
export const ASSET_CLASS_SET_CREATE_FAILURE = 'ASSET_CLASS_SET_CREATE_FAILURE';
export const ASSET_CLASS_SET_UPDATE_REQUEST = 'ASSET_CLASS_SET_UPDATE_REQUEST';
export const ASSET_CLASS_SET_UPDATE_SUCCESS = 'ASSET_CLASS_SET_UPDATE_SUCCESS';
export const ASSET_CLASS_SET_UPDATE_FAILURE = 'ASSET_CLASS_SET_UPDATE_FAILURE';
export const ASSET_CLASS_SET_REMOVE_REQUEST = 'ASSET_CLASS_SET_REMOVE_REQUEST';
export const ASSET_CLASS_SET_REMOVE_SUCCESS = 'ASSET_CLASS_SET_REMOVE_SUCCESS';
export const ASSET_CLASS_SET_REMOVE_FAILURE = 'ASSET_CLASS_SET_REMOVE_FAILURE';
export const ASSET_CLASS_SET_CHECK_TO_REMOVE_REQUEST =
  'ASSET_CLASS_SET_CHECK_TO_REMOVE_REQUEST';
export const ASSET_CLASS_SET_CHECK_TO_REMOVE_SUCCESS =
  'ASSET_CLASS_SET_CHECK_TO_REMOVE_SUCCESS';
export const ASSET_CLASS_SET_CHECK_TO_REMOVE_FAILURE =
  'ASSET_CLASS_SET_CHECK_TO_REMOVE_FAILURE';
