import React, { useState, useEffect, ReactElement } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dates from '../../constants/Date';
import Dialog from '../../containers/Dialog';
import styles from './styles';
import {
  ClientType,
  UserType,
  AssetClassSetType,
  ClientTypeType,
  MutateClientType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  onClose: () => void;
  model: ClientType;
  open: boolean;
  update: (obj: MutateClientType, id: number) => void;
  create: (obj: MutateClientType) => void;
  assetClassSets: Array<AssetClassSetType>;
  success: boolean;
  loading: boolean;
  error: any;
  getUserItem: (id: number) => Promise<UserType>;
  clientTypes: Array<ClientTypeType>;
}

const DialogForm = (props: Props): ReactElement => {
  const {
    model,
    open,
    getUserItem,
    assetClassSets,
    onClose,
    update,
    create,
    loading,
    success,
    error,
    clientTypes,
  } = props;

  const [name, setName] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [zipcode, setZipcode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [email1, setEmail1] = useState<string>('');
  const [email2, setEmail2] = useState<string>('');
  const [refAssetClassSet, setRefAssetClassSet] = useState<
    AssetClassSetType | undefined
  >(undefined);
  const [startYearDate, setStartYearDate] = useState<string | number>('');
  const [startMonthDate, setStartMonthDate] = useState<any | undefined>(
    undefined
  );
  const [endYearDate, setEndYearDate] = useState<string | number>('');
  const [endMonthDate, setEndMonthDate] = useState<any | undefined>(undefined);
  const [showError, setShowError] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [dateValidation, setDateValidation] = useState<boolean>(true);
  const [clientType, setClientType] = useState<undefined | ClientTypeType>(
    undefined
  );
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [modifyDate, setModifyDate] = useState<string>('');
  const [relatedUsername, setRelatedUsername] = useState<string>('');
  const classes = useStyles();

  const resetFields = (): void => {
    setName('');
    setStreet('');
    setZipcode('');
    setCity('');
    setCountry('');
    setTelephone('');
    setMobile('');
    setEmail1('');
    setEmail2('');
    setRefAssetClassSet(undefined);
    setStartYearDate('');
    setStartMonthDate(undefined);
    setEndYearDate('');
    setEndMonthDate(undefined);
    setShowError(false);
    setShowNotification(false);
    setClientType(undefined);
    setIsUpdated(false);
    setModifyDate('');
    setRelatedUsername('');
  };

  useEffect(() => {
    if (model && model.id && open) {
      setName(model.name);
      setStreet(model.street);
      setZipcode(model.zipcode);
      setCity(model.city);
      setCountry(model.country);
      setTelephone(model.telephone);
      setMobile(model.mobile);
      setEmail1(model.email1);
      setEmail2(model.email2);
      setRefAssetClassSet(
        assetClassSets &&
          assetClassSets.find(
            (acs: AssetClassSetType): any =>
              acs && acs.id === model.refAssetClassSet.id
          )
      );
      setClientType(
        clientTypes &&
          clientTypes.find(
            (ct: ClientTypeType): any => ct && ct.id === model?.clientType?.id
          )
      );
      if (model.startDate) {
        const date = new Date(model.startDate);
        setStartMonthDate(
          Dates.months.find(month => month.valueNumber - 1 === date.getMonth())
        );
        setStartYearDate(date.getFullYear());
      } else {
        setStartYearDate('');
        setStartMonthDate(undefined);
      }
      if (model.endDate) {
        const date = new Date(model.endDate);
        setEndMonthDate(
          Dates.months.find(month => month.valueNumber - 1 === date.getMonth())
        );
        setEndYearDate(date.getFullYear());
      } else {
        setEndMonthDate(undefined);
        setEndYearDate('');
      }
    } else {
      resetFields();
    }
  }, [model, open]);

  useEffect(() => {
    if (!model || !open) return;
    const { lastModifiedBy, lastModifiedDate, createdDate } = model;
    if (lastModifiedBy && lastModifiedDate !== undefined) {
      getUserItem(lastModifiedBy).then((user: UserType) => {
        if (lastModifiedDate === createdDate) {
          const date = new Date(lastModifiedDate);
          const monthObj = Dates.months.find(
            each => each.valueNumber === date.getMonth() + 1
          );
          const month = monthObj && monthObj.value;
          const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
          setIsUpdated(false);
          setRelatedUsername(user.name);
          setModifyDate(newModifyDate);
        } else {
          const date = new Date(lastModifiedDate);
          const monthObj = Dates.months.find(
            each => each.valueNumber === date.getMonth() + 1
          );
          const month = monthObj && monthObj.value;
          const newModifyDate = `${date.getDate()}-${month}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
          setIsUpdated(true);
          setRelatedUsername(user.name);
          setModifyDate(newModifyDate);
        }
      });
    } else {
      setIsUpdated(false);
      setModifyDate('');
      setRelatedUsername('');
    }
  }, [open, model, getUserItem]);

  const handleClose = (): void => {
    resetFields();
    onClose();
  };

  const canSubmit = (): boolean => {
    let startDate = '';
    let endDate = '';

    if (startYearDate !== '' && startMonthDate)
      startDate = new Date(
        Number(startYearDate),
        startMonthDate && startMonthDate.valueNumber
      ).toISOString();
    if (endYearDate !== '' && endMonthDate)
      endDate = new Date(
        Number(endYearDate),
        endMonthDate && endMonthDate.valueNumber
      ).toISOString();
    if (startDate !== '' && endDate !== '') {
      if (
        new Date(
          Number(startYearDate),
          startMonthDate && startMonthDate.valueNumber
        ) >
        new Date(Number(endYearDate), endMonthDate && endMonthDate.valueNumber)
      ) {
        setDateValidation(false);
        return false;
      }
      setDateValidation(true);
    }
    if (
      name === '' ||
      refAssetClassSet === undefined ||
      clientType === undefined
    ) {
      return false;
    }
    return true;
  };

  const submit = (): void => {
    if (canSubmit()) {
      let startDate = '';
      let endDate = '';

      if (startYearDate !== '' && startMonthDate)
        startDate = new Date(
          Number(startYearDate),
          startMonthDate && startMonthDate.valueNumber
        ).toISOString();
      if (endYearDate !== '' && endMonthDate)
        endDate = new Date(
          Number(endYearDate),
          endMonthDate && endMonthDate.valueNumber
        ).toISOString();

      const data = {
        name,
        street,
        zipcode,
        city,
        country,
        telephone,
        mobile,
        email1,
        email2,
        startDate,
        endDate,
        refAssetClassSetId: refAssetClassSet ? refAssetClassSet.id : 0,
        refClientTypeId: clientType ? clientType.id : 0,
      };

      if (model?.id) {
        update(data, model.id);
      } else {
        create(data);
      }
      setShowNotification(true);
    } else {
      setShowError(true);
    }
  };

  const retry = (): void => {
    setShowNotification(false);
  };

  let actions = showNotification
    ? [
        {
          label: 'OK',
          onClick: handleClose,
          disabled: loading,
        },
      ]
    : [
        {
          label: 'cancel',
          onClick: handleClose,
          type: 'secondary',
        },
        {
          label: 'save',
          onClick: submit,
        },
      ];

  if (showNotification && !loading && !success) {
    actions = [
      {
        label: 'Back',
        onClick: retry,
      },
      {
        label: 'Cancel',
        onClick: handleClose,
        type: 'secondary',
      },
    ];
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      actions={actions}
      title={model && model.id ? 'Edit Client' : 'New Client'}
    >
      {showNotification && loading && (
        <div className={classes.container}>
          <LinearProgress className={classes.progress} />
        </div>
      )}
      {showNotification && !loading && success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Client {model && model.id ? 'updated' : 'added'} successfully.
          </Typography>
        </div>
      )}
      {showNotification && !loading && !success && (
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.text}>
            Error: {(error && error.messages && error.messages[0]) || 'Unknown'}
          </Typography>
        </div>
      )}
      {!showNotification && (
        <div className={classes.formContainer}>
          <TextField
            error={showError && name === ''}
            id="outlined-name"
            label="Name"
            value={name}
            onChange={(e: any): void => setName(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
            helperText={showError && name === '' && 'Required'}
          />
          <TextField
            id="outlined-street"
            label="Street"
            value={street}
            onChange={(e: any): void => setStreet(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.halfField}
          />
          <TextField
            id="outlined-zipcode"
            label="Zip Code"
            value={zipcode}
            onChange={(e: any): void => setZipcode(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.halfField}
          />
          <TextField
            id="outlined-city"
            label="City"
            value={city}
            onChange={(e: any): void => setCity(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.halfField}
          />
          <TextField
            id="outlined-country"
            label="Country"
            value={country}
            onChange={(e: any): void => setCountry(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.halfField}
          />
          <div className={classes.fieldWithIconContainer}>
            <PhoneIcon fontSize="small" className={classes.icon} />
            <TextField
              id="outlined-telephone"
              label="Telephone"
              value={telephone}
              onChange={(e: any): void => setTelephone(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.fieldWithIcon}
            />
          </div>
          <TextField
            id="outlined-mobile"
            label="Mobile"
            value={mobile}
            onChange={(e: any): void => setMobile(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.halfField}
          />
          <div className={classes.fieldWithIconContainer}>
            <EmailIcon fontSize="small" className={classes.icon} />
            <TextField
              id="outlined-email1"
              label="Email 1"
              value={email1}
              onChange={(e: any): void => setEmail1(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.fieldWithIcon}
            />
          </div>

          <TextField
            id="outlined-email2"
            label="Email 2"
            value={email2}
            onChange={(e: any): void => setEmail2(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.halfField}
          />
          <TextField
            id="outlined-select-assetClassSet"
            select
            error={showError && refAssetClassSet === undefined}
            label="Asset Class Set"
            value={refAssetClassSet || ''}
            onChange={(e: any): void => setRefAssetClassSet(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
            helperText={
              showError && refAssetClassSet === undefined && 'Required'
            }
          >
            {assetClassSets.map((option: any): any => (
              <MenuItem key={option.id} value={option}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="outlined-select-type"
            select
            error={showError && clientType === undefined}
            label="Group"
            value={clientType}
            onChange={(e: any): void => setClientType(e.target.value)}
            margin="normal"
            variant="outlined"
            className={classes.fullField}
            helperText={showError && clientType === undefined && 'Required'}
          >
            {clientTypes.map((option: any): any => (
              <MenuItem key={option.id} value={option}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <div className={classes.fieldWithIconContainer}>
            <Typography variant="subtitle1" className={classes.label}>
              Start Date
            </Typography>
            <TextField
              id="outlined-select-startMonthDate"
              select
              label="Month"
              value={startMonthDate || ''}
              onChange={(e: any): void => setStartMonthDate(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.dropDown}
            >
              <MenuItem value={undefined}>{'<Empty>'}</MenuItem>
              {Dates.months.map((option: any): any => (
                <MenuItem key={option.value} value={option}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="outlined-select-startYearDate"
              select
              label="Year"
              value={startYearDate}
              onChange={(e: any): void => setStartYearDate(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.dropDown}
            >
              <MenuItem value="">{'<Empty>'}</MenuItem>
              {Dates.years.map((option: any): any => (
                <MenuItem key={option.value} value={option.valueNumber}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div
            className={classes.fieldWithIconContainer}
            style={{ margin: '5px 0 5px 11px' }}
          >
            <Typography variant="subtitle1" className={classes.label}>
              End Date
            </Typography>
            <TextField
              error={showError && !dateValidation}
              id="outlined-select-endMonthDate"
              select
              label="Month"
              value={endMonthDate || ''}
              onChange={(e: any): void => setEndMonthDate(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.dropDown}
              helperText={showError && !dateValidation && 'Invalid Date'}
            >
              <MenuItem value={undefined}>{'<Empty>'}</MenuItem>
              {Dates.months.map((option: any): any => (
                <MenuItem key={option.value} value={option}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              error={showError && !dateValidation}
              id="outlined-select-endYearDate"
              select
              label="Year"
              value={endYearDate}
              onChange={(e: any): void => setEndYearDate(e.target.value)}
              margin="normal"
              variant="outlined"
              className={classes.dropDown}
              helperText={showError && !dateValidation && 'Invalid Date'}
            >
              <MenuItem value="">{'<Empty>'}</MenuItem>
              {startYearDate !== ''
                ? Dates.years
                    .filter(
                      (each: any): any =>
                        each.valueNumber >= Number(startYearDate)
                    )
                    .map((option: any): any => (
                      <MenuItem key={option.value} value={option.valueNumber}>
                        {option.label}
                      </MenuItem>
                    ))
                : Dates.years.map((option: any): any => (
                    <MenuItem key={option.value} value={option.valueNumber}>
                      {option.label}
                    </MenuItem>
                  ))}
            </TextField>
          </div>
          {relatedUsername !== '' && (
            <Typography variant="subtitle2" className={classes.info}>
              {isUpdated
                ? `Updated by: ${relatedUsername} on ${modifyDate}`
                : `Created by: ${relatedUsername} on ${modifyDate}`}
            </Typography>
          )}
        </div>
      )}
    </Dialog>
  );
};

DialogForm.defaultProps = {
  model: undefined,
  success: false,
  error: undefined,
};

export default DialogForm;
