import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClientActions from '../../actions/ClientActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import AssetClassSetActions from '../../actions/AssetClassSetActions';
import ExportActions from '../../actions/ExportActions';
import ClinetTypeActions from '../../actions/ClientTypeActions';
import Client from './Clients';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...ClientActions,
      ...UserActions,
      ...AssetClassSetActions,
      ...DialogActions,
      ...ExportActions,
      ...ClinetTypeActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  clients: state.Client.clients,
  deleted: state.Client.deleted,
  client: state.Client.client,
  success: state.Client.success,
  error: state.Client.error,
  loading: state.Client.loading,
  assetClassSets: state.AssetClassSet.assetClassSets,
  clientTypes: state.ClientType.clientTypes,
  currentMonth: state.DatePicker.month,
  currentYear: state.DatePicker.year,
});

export default connect(mapStateToProps, mapDispatchToProps)(Client);
