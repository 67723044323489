import React, { useState, useEffect, ReactElement } from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AssetClassSetActions from '../../actions/AssetClassSetActions';
import TabsComponent from '../../containers/Tabs';
import AssetClass from '../AssetClass';
import RiskClass from '../RiskClass';
import Correlation from '../Correlation';
import styles from './styles';
import { AssetClassSetType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  assetClassSet: AssetClassSetType;
  getAssetClassSetItem: (id: number) => void;
  match: any;
  history: any;
}

const AssetClassSetItem = (props: Props): ReactElement => {
  const {
    match: {
      params: { assetClassSetId },
    },
    getAssetClassSetItem,
    assetClassSet,
    history,
    ...others
  } = props;

  const [tabNumber, setTabNumber] = useState<number>(1);
  const classes = useStyles();

  useEffect(() => {
    getAssetClassSetItem(assetClassSetId);
  }, [getAssetClassSetItem, assetClassSetId]);

  const goBack = (): void => {
    history.goBack();
  };

  const tabNames = ['Asset Class', 'Risk Class', 'CORRELATION'];
  const tabValues = [1, 2, 3];
  return (
    <div className={classes.root} style={{ backgroundColor: '#eff0f1' }}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.titleContainer}>
          <Tooltip title="Back">
            <IconButton
              onClick={goBack}
              classes={{ root: classes.backRotButton }}
              color="inherit"
            >
              <LeftIcon />
            </IconButton>
          </Tooltip>
          <Typography className={classes.title} variant="h1" id="tableTitle">
            Asset Class Set:
            {` ${assetClassSet && assetClassSet.name}`}
          </Typography>
        </div>
      </Toolbar>
      <div style={{ display: 'flex' }}>
        <TabsComponent
          selectedTab={tabNumber}
          changeTab={(value: number): void => setTabNumber(value)}
          tabs={tabNames}
          values={tabValues}
        />
      </div>
      <div style={{ overflow: 'auto', height: '100%' }}>
        {tabNumber === 1 && (
          <AssetClass
            assetClassSetId={assetClassSetId}
            assetClassSet={assetClassSet}
            {...others}
          />
        )}

        {tabNumber === 2 && (
          <RiskClass assetClassSetId={assetClassSetId} {...others} />
        )}
        {tabNumber === 3 && (
          <Correlation assetClassSetId={assetClassSetId} {...others} />
        )}
      </div>
    </div>
  );
};

AssetClassSetItem.defaultProps = {
  assetClassSet: undefined,
};

export default connect(
  (state: any): any => ({
    assetClassSet: state.AssetClassSet.assetClassSet,
  }),
  (dispatch: any): any => ({
    getAssetClassSetItem: (id: number): any =>
      dispatch(AssetClassSetActions.getAssetClassSetItem(id)),
  })
)(AssetClassSetItem);
