import React, { ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Badge from '@material-ui/core/Badge';
import ReportIcon from '@material-ui/icons/AssessmentOutlined';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styles from './styles';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  selectedTab: number;
  changeTab: any;
  tabs: string[];
  values: any; // string or number
  showLabelInBadge: boolean;
  badgeValues: number[];
  showRightTab: boolean;
  runRightTabMethod: any;
}

const TabsComponent = ({
  selectedTab,
  changeTab,
  tabs,
  values,
  showLabelInBadge,
  badgeValues,
  showRightTab,
  runRightTabMethod,
}: Props): ReactElement => {
  const classes = useStyles();
  return (
    <Tabs
      className={classes.tabs}
      classes={{
        indicator: classes.invisibleIndicator,
        fixed: classes.tabsPadding,
      }}
      value={selectedTab}
      onChange={(event: any, value: number): void => {
        if (showRightTab && value === tabs.length + 2) {
          runRightTabMethod();
        } else {
          changeTab(value);
        }
      }}
      textColor="primary"
    >
      <Tab
        disabled
        className={classes.leftInvisTab}
        classes={{
          root: classes.invisRoot,
        }}
        // style={{ display: hideLeftTab && 'none' }}
      />

      {tabs.map(
        (name: string, idx: number): ReactElement => (
          <Tab
            classes={{
              selected: classes.selectedTab,
              root: classes.tabLabelContainer,
            }}
            style={showLabelInBadge ? { paddingRight: 42 } : {}}
            className={classes.tab}
            key={name}
            value={values[idx]}
            label={
              showLabelInBadge ? (
                <Badge
                  classes={{
                    badge: classes.badge,
                  }}
                  badgeContent={badgeValues[idx]}
                >
                  {name}
                </Badge>
              ) : (
                name
              )
            }
          />
        )
      )}
      <Tab disabled className={classes.invisTab} />
      {showRightTab && (
        <Tab className={classes.rightTab} icon={<ReportIcon />} />
      )}
    </Tabs>
  );
};

TabsComponent.defaultProps = {
  showLabelInBadge: false,
  badgeValues: undefined,
  showRightTab: false,
  runRightTabMethod: undefined,
};

export default TabsComponent;
