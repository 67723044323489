const palette = {
  primary: {
    main: '#1976d2',
  },
  common: {
    black: '#000',
    white: '#fff',
    minBlack: 'rgba(0, 0, 0, 0.26)',
    gray: '#455a64',
    lightGray: '#a2a2a2',
    blue: '#00A7F3',
    lightTeal: '#00b5c0',
    teal: '#008080',
    green: '#1fb06d',
    orange: '#fe9700',
    red: '#e61a63',
    lightGreen: '#8ac249',
    yellow: '#fec006',
    purple: '#933f8f',
    darkBlue: '#004373',
    blue1: '#205e8e',
    blue2: '#327ea8',
    blue3: '#42dec5',
    blue4: '#016879',
  },
};
export default palette;
