import { combineReducers } from 'redux';
import User from './UserReducer';
import Client from './ClientReducer';
import Currency from './CurrencyReducer';
import AssetClass from './AssetClassReducer';
import AssetClassSet from './AssetClassSetReducer';
import RiskClass from './RiskClassReducer';
import AssetManager from './AssetManagerReducer';
import AccountType from './AccountTypeReducer';
import AccountCode from './AccountCodeReducer';
import Region from './RegionReducer';
import Security from './SecurityReducer';
import Benchmark from './BenchmarkReducer';
import Account from './AccountReducer';
import AssetTable from './AssetTableReducer';
import Correlation from './CorrelationReducer';
import Drawer from './DrawerReducers';
import Dialog from './DialogReducers';
import DatePicker from './DatePickerReducers';
import Report from './ReportReducer';
import Overview from './OverviewReducer';
import Environment from './EnvironmentReducer';
import AssetAllocation from './AssetAllocationResucer';
import Tour from './TourReducer';
import ClientType from './ClientTypeReducer';
import version from '../constants/Config';
import SelectionProfile from './SelectionProfileReducer';
import * as types from '../constants/User';

const appReducer = combineReducers({
  User,
  Client,
  Currency,
  AssetClass,
  AssetClassSet,
  RiskClass,
  AssetManager,
  AccountType,
  AccountCode,
  Region,
  Security,
  Benchmark,
  Account,
  AssetTable,
  Correlation,
  Drawer,
  Dialog,
  DatePicker,
  Report,
  Overview,
  Environment,
  AssetAllocation,
  Tour,
  ClientType,
  SelectionProfile,
});

const RootReducer = (state: any, action: any): any => {
  let newState = { ...state };
  if (action.type === types.USER_LOGOUT_SUCCESS)
    if (state.User.version !== version) {
      localStorage.clear();
      newState = undefined;
    }

  return appReducer(newState, action);
};

export default RootReducer;
