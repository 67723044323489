import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const assets = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>
): any =>
  Request(`assettable/assets`, 'POST', {
    year,
    month,
    id: clientId,
    disabledColumns,
    enabledRows,
  })
    .then(ErrorHandler)
    .then((asset: any): any => asset);

const AssetsService = {
  assets,
};

export default AssetsService;
