import React, { useEffect, useState, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import FullCheckboxIcon from '@material-ui/icons/Done';
import Dialog from '../../containers/Dialog';
import DataTable from '../../containers/DataTable';
import DialogForm from './DialogForm';
import styles from './styles';
import { EnvironmentType, UserType, MutateEnvironmentType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  getEnvironmentsList: () => void;
  createEnvironment: (obj: MutateEnvironmentType) => void;
  updateEnvironment: (id: number, obj: MutateEnvironmentType) => void;
  removeEnvironment: (id: number) => void;
  openDialog: () => void;
  closeDialog: () => void;
  getUserItem: (id: number) => Promise<UserType>;
  loading: boolean;
  environments: Array<EnvironmentType>;
  success: boolean;
  deleted: boolean;
  error: any;
}

const Environments = (props: Props): ReactElement => {
  const {
    getEnvironmentsList,
    createEnvironment,
    updateEnvironment,
    removeEnvironment,
    openDialog,
    closeDialog,
    getUserItem,
    environments,
    success,
    error,
    deleted,
    loading,
  } = props;
  const [openDialogForm, setOpenDialogForm] = useState<boolean>(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState<
    EnvironmentType | undefined
  >(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [rows, setRows] = useState<Array<any>>([]);
  const classes = useStyles();

  useEffect(() => {
    if (environments) {
      const newRows = environments.map((each: EnvironmentType): any => ({
        ...each,
        globalBenchmarksValue: each.globalBenchmarks ? (
          <FullCheckboxIcon style={{ marginBottom: '-10px' }} />
        ) : (
          ''
        ),
        simulationTabValue: each.simulationTab ? (
          <FullCheckboxIcon style={{ marginBottom: '-10px' }} />
        ) : (
          ''
        ),
      }));
      setRows(newRows);
    }
  }, [environments]);

  useEffect(() => {
    getEnvironmentsList();
  }, [getEnvironmentsList]);

  const handleOpenAdd = (): void => {
    openDialog();
    setSelectedEnvironment(undefined);
    setOpenDialogForm(true);
  };
  const handleOpenEdit = (event: any, rowData: EnvironmentType): void => {
    openDialog();
    setSelectedEnvironment(rowData);
    setOpenDialogForm(true);
  };
  const handleOpenDelete = (event: any, rowData: EnvironmentType): void => {
    openDialog();
    setOpenDelete(true);
    setSelectedEnvironment(rowData);
  };
  const handleCloseDialogForm = (): void => {
    closeDialog();
    setOpenDialogForm(false);
  };
  const handleCloseDelete = (): void => {
    closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };
  const handleDelete = (): void => {
    if (selectedEnvironment) removeEnvironment(selectedEnvironment.id);
    setIsRemoving(true);
  };

  const columns = [
    {
      title: 'Name',
      name: 'name',
      sortable: true,
      type: 'string',
      sortFiled: 'name',
    },
    {
      title: 'Type',
      name: 'type',
      sortable: true,
      type: 'string',
      sortFiled: 'type',
    },
    { title: 'URL', name: 'url' },
    {
      title: 'Global Benchmarks',
      name: 'globalBenchmarksValue',
      style: { textAlign: 'center', justifyContent: 'center' },
      rowStyle: { textAlign: 'center', justifyContent: 'center' },
      sortable: true,
      sortFiled: 'globalBenchmarks',
      type: 'boolean',
    },
    {
      title: 'Simulation Tab',
      name: 'simulationTabValue',
      style: { textAlign: 'center', justifyContent: 'center' },
      rowStyle: { textAlign: 'center', justifyContent: 'center' },
      sortable: true,
      sortFiled: 'simulationTab',
      type: 'boolean',
    },
  ];

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          columns={columns}
          noMargin
          rows={rows}
          onAdd={handleOpenAdd}
          onEdit={handleOpenEdit}
          onDelete={handleOpenDelete}
          entity="Environment"
          entities="Environments"
          hasAddButton
          hasHighlightBackground
          hasEditAndDelete
        />
        <DialogForm
          model={selectedEnvironment}
          open={openDialogForm}
          onClose={handleCloseDialogForm}
          update={updateEnvironment}
          create={createEnvironment}
          loading={loading}
          success={success}
          error={error}
          getUserItem={getUserItem}
        />
        <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          title="Warning"
          actions={
            isRemoving
              ? [
                  {
                    label: 'OK',
                    onClick: handleCloseDelete,
                  },
                ]
              : [
                  {
                    label: 'cancel',
                    onClick: handleCloseDelete,
                    type: 'secondary',
                  },
                  {
                    label: 'Delete',
                    onClick: handleDelete,
                  },
                ]
          }
        >
          {!isRemoving && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                Are you sure?
              </Typography>
            </div>
          )}
          {isRemoving && loading && (
            <div className={classes.container}>
              <LinearProgress className={classes.progress} />
            </div>
          )}
          {isRemoving && !loading && deleted && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                Environment removed successfully.
              </Typography>
            </div>
          )}
          {isRemoving && !loading && !deleted && (
            <div className={classes.container}>
              <Typography variant="subtitle2" className={classes.text}>
                Error: {error && error.messages && error.messages[0]}
              </Typography>
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
};

Environments.defaultProps = {
  loading: false,
  environments: [],
  success: false,
  deleted: false,
  error: undefined,
};

export default Environments;
