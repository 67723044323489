import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: any): any =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    highlightBackground: {
      backgroundColor: '#eff0f1',
    },
    table: { backgroundColor: 'white', height: 'calc(100% - 60px)' },
    row: {
      height: '55px',
    },
    AddbuttonContainer: {
      justifyContent: 'flex-end',
    },
    addButton: {},
    column: {},
    deleteIcon: {
      color: theme.palette.common.red,
    },
    cell: {
      whiteSpace: 'nowrap',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    title: {
      display: 'inline-block',
      paddingTop: '2px',
    },
    tableCount: {
      margin: 12,
      marginTop: 14,
      height: 26,
      minWidth: 26,
      textAlign: 'center',
      borderRadius: '50%',
      lineHeight: '26px',
      fontSize: 13,
      color: '#1976d2',
      backgroundColor: '#0000000d',
      display: 'inline-block',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    headerCell: {
      fontSize: '0.875rem',
      color: 'rgb(0,0,0,0.7)',
      whiteSpace: 'nowrap',
      textTransform: 'uppercase',
      position: 'sticky',
      top: 0,
      background: 'white',
      boxShadow: '0 1px 0 0 #e0e0e0',
      fontWeight: 500,
      zIndex: 1,
    },
    toolbar: {
      justifyContent: 'space-between',
      marginTop: '30px',
      marginBottom: '20px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    actionCell: {
      minWidth: '300px',
      maxWidth: '300px',
    },
    divider: {
      borderStyle: 'inset',
    },
    rowContainer: { height: 60 },
    hasDetails: {
      '&:hover': { cursor: 'pointer' },
    },
    link: {
      '&:hover': { cursor: 'pointer' },
    },
    serachableHeader: {
      display: 'inline-block',
      '&:hover': { cursor: 'pointer', color: '#1976d2' },
    },
    sortableHeader: {
      '&:hover': { cursor: 'pointer', color: '#1976d2' },
    },
    cancelSerachButton: {
      position: 'absolute',
      top: 19,
      right: '-6px',
      height: 26,
      padding: 4,
    },
    titleWithButton: {
      lineHeight: '48px',
    },
    cellRoot: {
      padding: '4px 15px 4px 24px',
    },
    backRotButton: {
      height: '50px',
      marginLeft: '-18px',
    },
    noDataRow: {
      height: 557,
    },
    actionCellRoot: {
      padding: 0,
      paddingRight: 12,
    },

    actionCellRootInDialog: {
      padding: 0,
      paddingRight: 24,
    },
    sortIcon: {
      marginTop: '6px',
      marginLeft: '4px',
      fontSize: 13,
    },
    tableInDialog: {
      height: '100%',
    },
  });

export default styles;
