import React, { useState, useEffect, ReactElement } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import DialogForm from './DialogForm';
import styles from './styles';
import {
  AccountTypeType,
  ExportType,
  UserType,
  MutateAccountTypeType,
} from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

interface Props {
  getAccountTypesList: () => void;
  removeAccountType: (id: number) => void;
  createAccountType: (obj: MutateAccountTypeType) => void;
  updateAccountType: (obj: MutateAccountTypeType, id: number) => void;
  openDialog: () => void;
  closeDialog: () => void;
  accountTypes: { content: Array<AccountTypeType> };
  deleted: boolean;
  error: any;
  success: boolean;
  loading: boolean;
  getUserItem: (id: number) => Promise<UserType>;
  exportAccountTypesTable: () => Promise<ExportType>;
}

const AccountType = (props: Props): ReactElement => {
  const {
    getAccountTypesList,
    openDialog,
    closeDialog,
    removeAccountType,
    exportAccountTypesTable,
    accountTypes,
    updateAccountType,
    createAccountType,
    deleted,
    loading,
    error,
    success,
    getUserItem,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<AccountTypeType | undefined>(
    undefined
  );
  const [model, setModel] = useState<AccountTypeType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    getAccountTypesList();
  }, [getAccountTypesList]);

  const openAdd = (): void => {
    openDialog();
    setOpen(true);
    setModel(undefined);
  };

  const openEdit = (event: any, row: AccountTypeType): void => {
    openDialog();
    setOpen(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    closeDialog();
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: AccountTypeType): void => {
    openDialog();
    setOpenDelete(true);
    setSelectedRow(row);
  };

  const handleClose = (): void => {
    closeDialog();
    setOpen(false);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) removeAccountType(selectedRow.id);
  };

  const exportTable = (): void => {
    exportAccountTypesTable().then((res: ExportType): void => {
      const csvData = new Blob([res.export], {
        type: 'text/csv;charset=utf-8;',
      });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', res.fileName || 'AccountTypes.csv');
      tempLink.click();
    });
  };

  const columns = [
    {
      title: 'Name',
      name: 'name',
      type: 'string',
      sortable: true,
      sortField: 'name',
    },
  ];

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          hasEditAndDelete
          onEdit={openEdit}
          onDelete={handleDeleteOpen}
          onAdd={openAdd}
          columns={columns}
          noMargin
          hasExportButton
          onExport={exportTable}
          rows={accountTypes && accountTypes.content}
          hasHighlightBackground
          entity="Account Type"
          entities="Account Types"
        />
      </div>
      <DialogForm
        model={model}
        open={open}
        onClose={handleClose}
        update={updateAccountType}
        create={createAccountType}
        loading={loading}
        success={success}
        error={error}
        getUserItem={getUserItem}
      />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {isRemoving && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Account Type removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error:{' '}
              {(error && error.messages && error.messages[0]) || 'Unknown'}
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};

AccountType.defaultProps = {
  error: undefined,
  success: false,
  deleted: false,
  accountTypes: undefined,
};

export default AccountType;
