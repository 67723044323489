import SecurityService from '../services/SecurityService';
import * as types from '../constants/Security';
import { SecurityType, SecurityValuesType } from '../Types';

const getSecuritiesList = (): any => (dispatch: any): void => {
  dispatch({ type: types.SECURITIES_LIST_REQUEST });
  SecurityService.list()
    .then((securities: { content: Array<SecurityType> }): void => {
      dispatch({ type: types.SECURITIES_LIST_SUCCESS, securities });
    })
    .catch((error: any): void => {
      dispatch({ type: types.SECURITIES_LIST_FAILURE, error });
    });
};

const saveSecurityValues = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.SECURITY_ITEM_VALUES_SAVE_REQUEST });
  SecurityService.saveValues(data)
    .then((): void => {
      dispatch({ type: types.SECURITY_ITEM_VALUES_SAVE_SUCCESS });
    })
    .catch((error: any): void => {
      dispatch({ type: types.SECURITY_ITEM_VALUES_SAVE_FAILURE, error });
    });
};

const getSecurityItem = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.SECURITY_ITEM_REQUEST });
  SecurityService.item(id)
    .then((security: SecurityType): void => {
      dispatch({ type: types.SECURITY_ITEM_SUCCESS, security });
    })
    .catch((error: any): void => {
      dispatch({ type: types.SECURITY_ITEM_FAILURE, error });
    });
};

const getSecurityValues = (id: number, year: number, month: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SECURITY_ITEM_VALUES_REQUEST });
  SecurityService.values(id, year, month)
    .then((securityValues: SecurityValuesType): void => {
      dispatch({ type: types.SECURITY_ITEM_VALUES_SUCCESS, securityValues });
    })
    .catch((error: any): void => {
      dispatch({ type: types.SECURITY_ITEM_VALUES_FAILURE, error });
    });
};

const createSecurity = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.SECURITY_CREATE_REQUEST });
  SecurityService.create(data)
    .then((security: any): void => {
      dispatch({ type: types.SECURITY_CREATE_SUCCESS, security });
      dispatch(getSecuritiesList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.SECURITY_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.SECURITY_CREATE_FAILURE, error });
      }
    });
};

const updateSecurity = (data: any, id: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SECURITY_UPDATE_REQUEST });
  SecurityService.update(data, id)
    .then((security: any): void => {
      dispatch({ type: types.SECURITY_UPDATE_SUCCESS, security });
      dispatch(getSecuritiesList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.SECURITY_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.SECURITY_UPDATE_FAILURE, error });
      }
    });
};

const removeSecurity = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.SECURITY_REMOVE_REQUEST });
  SecurityService.remove(id)
    .then((security: any): void => {
      dispatch({ type: types.SECURITY_REMOVE_SUCCESS, security, id });
    })
    .catch((error: any): void => {
      dispatch({ type: types.SECURITY_REMOVE_FAILURE, error });
    });
};

const getSecurityStartDate = (securityId: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.SECURITY_START_DATE_REQUEST });
  return SecurityService.getSecurityStartDate(securityId)
    .then((date: any): any => {
      dispatch({ type: types.SECURITY_START_DATE_SUCCESS, date });
      return Promise.resolve(date);
    })
    .catch((error: any): void => {
      dispatch({ type: types.SECURITY_START_DATE_FAILURE, error });
    });
};

const SecurityActions = {
  getSecuritiesList,
  getSecurityItem,
  getSecurityValues,
  createSecurity,
  updateSecurity,
  saveSecurityValues,
  removeSecurity,
  getSecurityStartDate,
};

export default SecurityActions;
