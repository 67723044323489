import AssetClassSetService from '../services/AssetClassSetService';
import * as types from '../constants/AssetClassSet';
import { AssetClassSetType } from '../Types';

const getAssetClassSetsList = (): any => (dispatch: any): void => {
  dispatch({ type: types.ASSET_CLASS_SET_LIST_REQUEST });
  AssetClassSetService.list()
    .then((assetClassSets: { content: Array<AssetClassSetType> }): void => {
      dispatch({ type: types.ASSET_CLASS_SET_LIST_SUCCESS, assetClassSets });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_CLASS_SET_LIST_FAILURE, error });
    });
};

const getAssetClassSetItem = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ASSET_CLASS_SET_ITEM_REQUEST });
  AssetClassSetService.item(id)
    .then((assetClassSet: AssetClassSetType): void => {
      dispatch({ type: types.ASSET_CLASS_SET_ITEM_SUCCESS, assetClassSet });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_CLASS_SET_ITEM_FAILURE, error });
    });
};

const createAssetClassSet = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.ASSET_CLASS_SET_CREATE_REQUEST });
  AssetClassSetService.create(data)
    .then((assetClassSet: any): void => {
      dispatch({ type: types.ASSET_CLASS_SET_CREATE_SUCCESS, assetClassSet });
      dispatch(getAssetClassSetsList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ASSET_CLASS_SET_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ASSET_CLASS_SET_CREATE_FAILURE, error });
      }
    });
};

const updateAssetClassSet = (data: any, id: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.ASSET_CLASS_SET_UPDATE_REQUEST });
  AssetClassSetService.update(data, id)
    .then((assetClassSet: any): void => {
      dispatch({ type: types.ASSET_CLASS_SET_UPDATE_SUCCESS, assetClassSet });
      dispatch(getAssetClassSetsList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ASSET_CLASS_SET_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ASSET_CLASS_SET_UPDATE_FAILURE, error });
      }
    });
};

const removeAssetClassSet = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.ASSET_CLASS_SET_REMOVE_REQUEST });
  AssetClassSetService.remove(id)
    .then((assetClassSet: any): void => {
      dispatch({
        type: types.ASSET_CLASS_SET_REMOVE_SUCCESS,
        assetClassSet,
        id,
      });
    })
    .catch((error: any): void => {
      dispatch({ type: types.ASSET_CLASS_SET_REMOVE_FAILURE, error });
    });
};

const checkToRemoveAssetClassSet = (id: number): any => (
  dispatch: any
): any => {
  dispatch({ type: types.ASSET_CLASS_SET_CHECK_TO_REMOVE_REQUEST });
  return AssetClassSetService.checkToRemoveAssetClassSet(id)
    .then((hasValueResponse: any): any => {
      dispatch({ type: types.ASSET_CLASS_SET_CHECK_TO_REMOVE_SUCCESS });
      return Promise.resolve(hasValueResponse);
    })
    .catch((error: any): any => {
      dispatch({ type: types.ASSET_CLASS_SET_CHECK_TO_REMOVE_FAILURE });
      return Promise.reject(error);
    });
};

const AssetClassSetActions = {
  getAssetClassSetsList,
  getAssetClassSetItem,
  createAssetClassSet,
  updateAssetClassSet,
  removeAssetClassSet,
  checkToRemoveAssetClassSet,
};

export default AssetClassSetActions;
