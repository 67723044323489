import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import AssetTableActions from '../../actions/AssetTableActions';
import ClientActions from '../../actions/ClientActions';
import ReportActions from '../../actions/ReportActions';
import UserActions from '../../actions/UserActions';
import DatePickerActions from '../../actions/DatePickerActions';
import Client from './Client';
import DialogActions from '../../actions/DialogActions';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...ClientActions,
      ...AssetTableActions,
      ...ReportActions,
      ...UserActions,
      ...DatePickerActions,
      ...DialogActions,
    },
    dispatch
  );

const getClientId = (state: any): string =>
  state.Client.selectedClient && state.Client.selectedClient.id;
const getClientData = (state: any): any => state.Client.clientData;
const getClientList = (state: any): any =>
  state.Client.clients && state.Client.clients.content;

const getFoundClientData = createSelector(
  [getClientData, getClientId],
  (data, id) => data && data.find((each: any): any => each.id === id)
);

const getSelectedDate = createSelector(
  [getFoundClientData],
  data => data && data.selectedDate
);

const getSelectedTab = createSelector(
  [getFoundClientData],
  data => data && data.selectedTab
);

const getSelectedRows = createSelector(
  [getFoundClientData],
  data => data && data.selectedRows
);

const getSelectedColumns = createSelector(
  [getFoundClientData],
  data => data && data.selectedColumns
);
const getAssetsChart = createSelector(
  [getFoundClientData],
  data => data && data.assetsChart
);

const getRegccyChart = createSelector(
  [getFoundClientData],
  data => data && data.regccyChart
);

const getSelectedStartYear = createSelector(
  [getFoundClientData],
  data => data && data.chartingStartYear
);

const getSelectedStartMonth = createSelector(
  [getFoundClientData],
  data => data && data.chartingStartMonth
);

const getSelectedEndYear = createSelector(
  [getFoundClientData],
  data => data && data.chartingEndYear
);

const getSelectedEndMonth = createSelector(
  [getFoundClientData],
  data => data && data.chartingEndMonth
);

const getSelectedRiskFree = createSelector(
  [getFoundClientData],
  data => data && data.chartingRiskFree
);

const getChartingShowingRows = createSelector(
  [getFoundClientData],
  data => data && data.chartingShowingRows
);

const getMonitorShowingRows = createSelector(
  [getFoundClientData],
  data => data && data.monitorShowingRows
);

const getSelectedSharp = createSelector(
  [getFoundClientData],
  data => data && data.performSharp
);

const getMonitorChart = createSelector(
  [getFoundClientData],
  data => data && data.monitorChart
);

const getFirstStatus = createSelector(
  [getFoundClientData],
  data => data && data.allocationFirstStatus
);

const getSecondStatus = createSelector(
  [getFoundClientData],
  data => data && data.allocationSecondStatus
);

const getRowsNumber = createSelector(
  [getFoundClientData],
  data => data && data.rowsNumber
);

const mapStateToProps = (state: any): any => ({
  client: state.Client.selectedClient,
  clients: getClientList(state) || [],
  startDate: state.AssetTable.startDate,
  enabledReport: state.User.enableReport,
  selectedDate: getSelectedDate(state),
  selectedTab: getSelectedTab(state),
  reportSuccess: state.Report.success,
  error: state.Client.error,
  success: state.Client.success,
  reportError: state.Report.error,
  profile: state.User.profile,
  selectedRows: getSelectedRows(state),
  selectedColumns: getSelectedColumns(state),
  assetsChart: getAssetsChart(state),
  regccyChart: getRegccyChart(state),
  chartingStartYear: getSelectedStartYear(state),
  chartingStartMonth: getSelectedStartMonth(state),
  chartingEndYear: getSelectedEndYear(state),
  chartingEndMonth: getSelectedEndMonth(state),
  chartingRiskFree: getSelectedRiskFree(state),
  chartingShowingRows: getChartingShowingRows(state),
  monitorShowingRows: getMonitorShowingRows(state),
  performSharp: getSelectedSharp(state),
  monitorChart: getMonitorChart(state),
  firstStatus: getFirstStatus(state),
  secondStatus: getSecondStatus(state),
  rowsNumber: getRowsNumber(state),
  currentEnvironment: state.Environment.currentEnvironment,
  loadingData:
    state.AssetTable.loadingValue ||
    state.AssetAllocation.loading ||
    state.Client.loadingData ||
    state.Benchmark.loading ||
    (state.SelectionProfile.loading && !state.Dialog.open),
  loading: state.Client.loading && !state.Dialog.open,
  selectionProfileSuccess: state.SelectionProfile.success,
});

export default connect(mapStateToProps, mapDispatchToProps)(Client);
