import React, { useState, useEffect, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import ClientTypeActions from '../../actions/ClientTypeActions';
import DialogActions from '../../actions/DialogActions';
import ExportActions from '../../actions/ExportActions';
import DataTable from '../../containers/DataTable';
import Dialog from '../../containers/Dialog';
import DialogForm from './DialogForm';
import styles from './styles';
import { ClientTypeType, ExportType } from '../../Types';

const useStyles = makeStyles<typeof styles>(styles);

const ClientType = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<ClientTypeType | undefined>(
    undefined
  );
  const [model, setModel] = useState<ClientTypeType | undefined>(undefined);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getClientTypesList, removeClientType }: any = ClientTypeActions;
  const { openDialog, closeDialog } = DialogActions;
  const { exportClientTypesTable } = ExportActions;
  const { clientTypes, error, loading, deleted } = useSelector(
    (state: any): any => ({
      clientTypes: state.ClientType.clientTypes,
      error: state.ClientType.error,
      loading: state.ClientType.loading,
      deleted: state.ClientType.deleted,
    })
  );

  useEffect(() => {
    dispatch(getClientTypesList());
  }, []);

  const openAdd = (): void => {
    dispatch(openDialog());
    setOpen(true);
    setModel(undefined);
  };

  const openEdit = (event: any, row: ClientTypeType): void => {
    dispatch(openDialog());
    setOpen(true);
    setModel(row);
  };

  const handleDeleteClose = (): void => {
    dispatch(closeDialog());
    setOpenDelete(false);
    setIsRemoving(false);
  };

  const handleDeleteOpen = (event: any, row: ClientTypeType): void => {
    dispatch(openDialog());
    setOpenDelete(true);
    setSelectedRow(row);
  };

  const handleClose = (): void => {
    dispatch(closeDialog());
    setOpen(false);
  };

  const remove = (): void => {
    setIsRemoving(true);
    if (selectedRow) dispatch(removeClientType(selectedRow.id));
  };

  const exportTable = (): void => {
    dispatch(exportClientTypesTable()).then((res: ExportType): void => {
      const csvData = new Blob([res.export], {
        type: 'text/csv;charset=utf-8;',
      });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', res.fileName || 'ClientGroups.csv');
      tempLink.click();
    });
  };

  const columns = [
    {
      title: 'Name',
      name: 'name',
      type: 'string',
      sortable: true,
      sortField: 'name',
    },
  ];

  return (
    <div style={{ height: '100%' }}>
      <div style={{ height: '100%' }}>
        <DataTable
          hasAddButton
          hasEditAndDelete
          onEdit={openEdit}
          onDelete={handleDeleteOpen}
          onAdd={openAdd}
          columns={columns}
          noMargin
          rows={clientTypes && clientTypes.content}
          hasHighlightBackground
          hasExportButton
          onExport={exportTable}
          entity="Client Group"
          entities="Client Groups"
        />
      </div>
      <DialogForm model={model} open={open} onClose={handleClose} />
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        title="Warning"
        actions={
          isRemoving
            ? [
                {
                  label: 'OK',
                  onClick: handleDeleteClose,
                },
              ]
            : [
                {
                  label: 'cancel',
                  onClick: handleDeleteClose,
                  type: 'secondary',
                },
                {
                  label: 'Delete',
                  onClick: remove,
                },
              ]
        }
      >
        {!isRemoving && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Are you sure?
            </Typography>
          </div>
        )}
        {isRemoving && loading && (
          <div className={classes.container}>
            <LinearProgress className={classes.progress} />
          </div>
        )}
        {isRemoving && !loading && deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Client Group removed successfully.
            </Typography>
          </div>
        )}
        {isRemoving && !loading && !deleted && (
          <div className={classes.container}>
            <Typography variant="subtitle2" className={classes.text}>
              Error:{' '}
              {(error && error.messages && error.messages[0]) || 'Unknown'}
            </Typography>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default ClientType;
