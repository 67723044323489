import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OverviewActions from '../../actions/OverviewActions';
import ClientsOverview from './ClientsOverview';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ ...OverviewActions }, dispatch);

const mapStateToProps = (state: any): any => ({
  loading: state.Overview.loading,
  clientsOverview: state.Overview.clientsOverview,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsOverview);
