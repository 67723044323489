import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const getAllocation = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>
): any =>
  Request(`allocation/`, 'POST', {
    year,
    month,
    id: clientId,
    disabledColumns,
    enabledRows,
  })
    .then(ErrorHandler)
    .then((allocation: any): any => allocation);

const createAllocationWeightValue = (data: any): any =>
  Request('allocation/weight', 'POST', data)
    .then(ErrorHandler)
    .then((value: any): any => value);

const updateAllocationWeightValue = (data: any): any =>
  Request('allocation/weight', 'PUT', data)
    .then(ErrorHandler)
    .then((value: any): any => value);

const removeAllocationWeightValue = (data: any): any =>
  Request('allocation/weight/', 'DELETE', data)
    .then(ErrorHandler)
    .then((value: any): any => value);

const createAssetAllocationValue = (data: any): any =>
  Request('allocation/value', 'POST', data)
    .then(ErrorHandler)
    .then((value: any): any => value);

const updateAssetAllocationValue = (data: any): any =>
  Request('allocation/value', 'PUT', data)
    .then(ErrorHandler)
    .then((value: any): any => value);

const removeAssetAllocationValue = (data: any): any =>
  Request('allocation/value', 'DELETE', data)
    .then(ErrorHandler)
    .then((value: any): any => value);

const AllocationService = {
  getAllocation,
  createAllocationWeightValue,
  updateAllocationWeightValue,
  createAssetAllocationValue,
  updateAssetAllocationValue,
  removeAllocationWeightValue,
  removeAssetAllocationValue,
};

export default AllocationService;
