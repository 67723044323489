import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CurrencyActions from '../../actions/CurrencyActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import ExportActions from '../../actions/ExportActions';
import Currency from './Currency';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...CurrencyActions,
      ...DialogActions,
      ...UserActions,
      ...ExportActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  currencies: state.Currency.currencies,
  deleted: state.Currency.deleted,
  currency: state.Currency.currency,
  success: state.Currency.success,
  error: state.Currency.error,
  loading: state.Currency.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Currency);
