import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssetClassSetActions from '../../actions/AssetClassSetActions';
import DialogActions from '../../actions/DialogActions';
import UserActions from '../../actions/UserActions';
import ExportActions from '../../actions/ExportActions';
import AssetClassSet from './AssetClassSet';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      ...AssetClassSetActions,
      ...DialogActions,
      ...UserActions,
      ...ExportActions,
    },
    dispatch
  );

const mapStateToProps = (state: any): any => ({
  deleted: state.AssetClassSet.deleted,
  assetClassSets: state.AssetClassSet.assetClassSets,
  assetClassSet: state.AssetClassSet.assetClassSet,
  success: state.AssetClassSet.success,
  error: state.AssetClassSet.error,
  loading: state.AssetClassSet.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetClassSet);
