import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Dialog from '../../containers/Dialog';
import SelectionProfileActions from '../../actions/SelectionProfileActions';
import styles from './styles';

const useStyles = makeStyles<typeof styles>(styles);

interface SelectionProfileCreateFormProps {
  open: boolean;
  onClose: (isSubmitted?: boolean) => void;
  clientId: number;
}

const SelectionProfileForm = (props: SelectionProfileCreateFormProps): any => {
  const { open, onClose, clientId } = props;
  const [name, setName] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const { createSelectionProfile }: any = SelectionProfileActions;
  const dispatch = useDispatch();
  const { enabledRows, disabledColumns } = useSelector((state: any): any => {
    const { clientData } = state.Client;
    const foundClientData =
      clientData && clientData.find((each: any): any => each.id === clientId);
    return {
      disabledColumns: foundClientData?.selectedColumns,
      enabledRows: foundClientData?.selectedRows,
    };
  });

  const classes = useStyles();

  const submit = (): void => {
    if (name !== '') {
      const data = {
        disabledColumns,
        enabledRows,
        refClientId: clientId,
        name,
      };
      dispatch(createSelectionProfile(data));
      onClose(true);
    } else {
      setShowError(true);
    }
  };

  const actions = [
    {
      label: 'cancel',
      onClick: (): void => onClose(false),
      type: 'secondary',
    },
    {
      label: 'save',
      onClick: submit,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={(): void => onClose(false)}
      actions={actions}
      title="New Selection"
    >
      <div className={classes.formContainer}>
        <TextField
          error={showError}
          id="outlined-code"
          label="Name"
          value={name}
          onChange={(e: any): void => setName(e.target.value)}
          margin="normal"
          variant="outlined"
          className={classes.fullField}
          helperText={showError && 'Required'}
        />
      </div>
    </Dialog>
  );
};

export default SelectionProfileForm;
