import React, { ReactElement } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

const styles = (theme: any): any =>
  createStyles({
    accountButton: {
      // marginLeft: '10px',
      width: '95px',
      [theme.breakpoints.down('md')]: {
        width: '80px',
      },
    },
    buttonLabel: {
      padding: '1px 3px',
      fontSize: '9px',
      height: '20px !important',
    },
    asb: {
      padding: '0px 5px',
      fontSize: '9px',
    },
    test: {
      padding: '1px 5px',
      fontSize: '9px',
      marginTop: 8,
    },
    input: {
      fontSize: '14px',
      lineHeight: '40px',
      padding: '0 31px 0 20px',
      [theme.breakpoints.down('md')]: {
        fontSize: '13px',
        padding: '0 20px',
      },
      color: '#039be5',
    },
    backdropclass: {
      margin: '8px',
    },
    borderClass: {
      borderColor: '#1976d280 !important',
      height: 43,
    },
  });

const useStyles = makeStyles<typeof styles>(styles);

const Dropdown = (props: any): ReactElement => {
  const {
    suggestions,
    onSelect,
    initValue,
    accountButtonClass,
    rootClass,
    inputClass,
    textRootClass,
    notchedOutlineClass,
  } = props;

  const classes = useStyles();

  return (
    <TextField
      id="outlined-select"
      select
      className={classnames(classes.accountButton, accountButtonClass)}
      value={initValue.label}
      onChange={(event: any): void => onSelect(event.target.value)}
      SelectProps={{
        MenuProps: {},
      }}
      InputProps={{
        classes: {
          root: rootClass || classes.asb,
          input: inputClass || classes.input,
          notchedOutline: notchedOutlineClass || classes.borderClass,
        },
      }}
      classes={{
        root: textRootClass || classes.test,
      }}
      margin="normal"
      variant="outlined"
    >
      {suggestions.map(
        (option: any): ReactElement => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={{
              fontSize: '12px',
              height: '14px',
              padding: '2px 4px',
              color: '#039be5',
            }}
          >
            {option.label}
          </MenuItem>
        )
      )}
    </TextField>
  );
};

Dropdown.defaultProps = {
  selected: null,
  suggestions: [],
  initValue: {},
  accountButtonClass: '',
  rootClass: undefined,
  inputClass: undefined,
  notchedOutlineClass: undefined,
  textRootClass: undefined,
};

export default Dropdown;
