import { ErrorHandler } from '../helpers/ErrorHandler';
import Request from '../helpers/Request';

const getSelectionProfileList = (id: number): any =>
  Request(`selectionProfile/client/${id}`, 'GET')
    .then(ErrorHandler)
    .then((selectionProfileList: any): any => selectionProfileList);

const createSelectionProfile = (data: any): any =>
  Request(`selectionProfile`, 'POST', data)
    .then(ErrorHandler)
    .then((selectionProfile: any): any => selectionProfile);

const removeSelectionProfile = (id: number): any =>
  Request(`selectionProfile/${id}`, 'DELETE')
    .then(ErrorHandler)
    .then((selectionProfile: any): any => selectionProfile);

const SelectionProfileService = {
  getSelectionProfileList,
  createSelectionProfile,
  removeSelectionProfile,
};

export default SelectionProfileService;
