import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OverviewActions from '../../actions/OverviewActions';
import SecuritiesOverview from './SecuritiesOverview';

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators({ ...OverviewActions }, dispatch);

const mapStateToProps = (state: any): any => ({
  loading: state.Overview.loading,
  securitiesOverview: state.Overview.securitiesOverview,
});

export default connect(mapStateToProps, mapDispatchToProps)(SecuritiesOverview);
