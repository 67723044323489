import createStyles from '@material-ui/core/styles/createStyles';

const styles = (): any =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
      marginLeft: '10px',
      width: 'calc(100% - 10px)',
      WebkitAnimation: 'fadein 2s',
    },
    root1: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      marginLeft: '10px',
      width: 'calc(100% - 10px)',
    },
    reportButtonContainer: {
      justifyContent: 'flex-end',
    },
    table: {
      flex: '1 0 30%',
    },
    chart: {
      flex: '1 0 32%',
      padding: '0 20px',
    },
    titleContainer: {
      width: '100%',
      textAlign: 'center',
    },
    title: {
      marginLeft: '8px',
    },
    toolbar: {
      marginTop: '38px',
      marginBottom: '20px',
      padding: 0,
      textAlign: 'center',
    },
    chartContainer: {
      position: 'relative',
      height: '75%',
      width: '100%',
      minHeight: 300,
      maxHeight: 700,
    },
  });

export default styles;
