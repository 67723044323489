import ClientService from '../services/ClientService';
import AssetsService from '../services/AssetsService';
import ChartingService from '../services/ChartingService';
import MonitorService from '../services/MonitorService';
import RegccyService from '../services/RegccyService';
import PerformanceService from '../services/PerformanceService';
import HistoryService from '../services/HistoryService';
import SimulationService from '../services/SimulationService';
import AllocationService from '../services/AllocationService';
import * as types from '../constants/Client';
import {
  ClientType,
  AssetsType,
  ChartingType,
  MonitorType,
  ChartingRowType,
  PerformanceType,
  RegccyType,
  HistoryType,
  SimulationType,
  AssetAllocationType,
} from '../Types';

const getClientsList = (): any => (dispatch: any): void => {
  dispatch({ type: types.CLIENTS_LIST_REQUEST });
  ClientService.list()
    .then((clients: { content: Array<ClientType> }): void => {
      dispatch({ type: types.CLIENTS_LIST_SUCCESS, clients });
    })
    .catch((error: any): void => {
      dispatch({ type: types.CLIENTS_LIST_FAILURE, error });
    });
};

const getClientItem = (id: number): any => (dispatch: any): void => {
  dispatch({ type: types.CLIENT_ITEM_REQUEST });
  ClientService.item(id)
    .then((client: ClientType): void => {
      dispatch({ type: types.CLIENT_ITEM_SUCCESS, client });
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.CLIENT_ITEM_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.CLIENT_ITEM_FAILURE, error });
      }
    });
};

const createClient = (data: any): any => (dispatch: any): void => {
  dispatch({ type: types.CLIENT_CREATE_REQUEST });
  ClientService.create(data)
    .then((client: any): void => {
      dispatch({ type: types.CLIENT_CREATE_SUCCESS, client });
      dispatch(getClientsList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.CLIENT_CREATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.CLIENT_CREATE_FAILURE, error });
      }
    });
};

const updateClient = (data: any, id: number): any => (dispatch: any): void => {
  dispatch({ type: types.CLIENT_UPDATE_REQUEST });
  ClientService.update(data, id)
    .then((client: any): void => {
      dispatch({ type: types.CLIENT_UPDATE_SUCCESS, client });
      dispatch(getClientsList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.CLIENT_UPDATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.CLIENT_UPDATE_FAILURE, error });
      }
    });
};

const duplicateClient = (
  data: { newName: string; month: number; year: number },
  id: number
): any => (dispatch: any): void => {
  dispatch({ type: types.CLIENT_DUPLICATE_REQUEST });
  ClientService.duplicateClient(data, id)
    .then((client: any): void => {
      dispatch({ type: types.CLIENT_DUPLICATE_SUCCESS, client });
      dispatch(getClientsList());
    })
    .catch((error: any): void => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.CLIENT_DUPLICATE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.CLIENT_DUPLICATE_FAILURE, error });
      }
    });
};

const selectClient = (client: ClientType): any => (dispatch: any): void => {
  dispatch({ type: types.SELECT_CLIENT, client });
};

const setDate = (date: any): any => (dispatch: any): void => {
  dispatch({ type: types.SET_DATE, date });
};

const setSelectedRows = (
  clientId: number,
  selectedRows: Array<number>
): any => (dispatch: any): void => {
  dispatch({
    type: types.SET_SELECTED_ROWS,
    clientId,
    selectedRows,
    meta: { throttle: 500 },
  });
};

const setRowsNumber = (clientId: number, rowsNumber: number): any => (
  dispatch: any
): any => {
  dispatch({
    type: types.SET_ROWS_NUMBER,
    clientId,
    rowsNumber,
  });
};

const setSelectedColumns = (
  clientId: number,
  selectedColumns: Array<number>
): any => (dispatch: any): void => {
  dispatch({
    type: types.SET_SELECTED_COLUMNS,
    clientId,
    selectedColumns,
    meta: { throttle: 500 },
  });
};

const setSelectedSelection = (
  clientId: number,
  selectedRows: Array<number>,
  selectedColumns: Array<number>
): any => (dispatch: any): void => {
  dispatch({
    type: types.SET_SELECTED_SELECTION,
    clientId,
    selectedRows,
    selectedColumns,
  });
};

const setSelectedHistory = (
  clientId: number,
  selectedHistory: Array<number>
): any => (dispatch: any): void => {
  dispatch({ type: types.SET_SELECTED_HISTORY, clientId, selectedHistory });
};

const setDateToClient = (clientId: number, date: any): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SET_DATE_TO_CLIENT, clientId, date });
};

const setNewClient = (clientId: number): any => (dispatch: any): void => {
  dispatch({ type: types.SET_NEW_CLIENT, clientId });
};

const setActiveHistory = (clientId: number, historyActive: boolean): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SET_ACTIVE_HISTORY, clientId, historyActive });
};

const setExpandedRows = (
  clientId: number,
  expandedRows: Array<number>
): any => (dispatch: any): void => {
  dispatch({ type: types.SET_EXPANDED_ROWS, clientId, expandedRows });
};

const setAssetsChart = (clientId: number, chart: string): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SET_ASSETS_CHART, clientId, chart });
};

const setRegccyChart = (clientId: number, chart: string): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SET_REGCCY_CHART, clientId, chart });
};

const setPerformSharp = (clientId: number, sharp: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SET_PERFORM_SHARPE, clientId, sharp });
};

const setChartingRiskFree = (clientId: number, riskFree: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SET_CHARTING_RSIK_FREE, clientId, riskFree });
};

const setChartingStartYear = (clientId: number, startYear: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SET_CHARTING_START_YEAR, clientId, startYear });
};

const setChartingStartMonth = (clientId: number, startMonth: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SET_CHARTING_START_MONTH, clientId, startMonth });
};

const setChartingEndYear = (clientId: number, endYear: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SET_CHARTING_END_YEAR, clientId, endYear });
};

const setChartingEndMonth = (clientId: number, endMonth: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SET_CHARTING_END_MONTH, clientId, endMonth });
};

const setChartingShowingRows = (
  clientId: number,
  showingRows: Array<number | string>
): any => (dispatch: any): void => {
  dispatch({ type: types.SET_CHARTING_SHOWING_ROWS, clientId, showingRows });
};

const setMonitorShowingRows = (
  clientId: number,
  showingRows: Array<string | number>
): any => (dispatch: any): void => {
  dispatch({ type: types.SET_MONITOR_SHOWING_ROWS, clientId, showingRows });
};

const setMonitorChart = (clientId: number, chart: string): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SET_MONITOR_CHART, clientId, chart });
};

const setSelectedTab = (clientId: number, tab: number): any => (
  dispatch: any
): void => {
  dispatch({ type: types.SET_SELECTED_TAB, clientId, tab });
};

const setAllocationFirstStatus = (
  clientId: number,
  allocationFirstStatus: string
): any => (dispatch: any): void => {
  dispatch({
    type: types.SET_ALLOCATION_FIRST_STATUS,
    clientId,
    allocationFirstStatus,
  });
};

const setAllocationSecondStatus = (
  clientId: number,
  allocationSecondStatus: string
): any => (dispatch: any): void => {
  dispatch({
    type: types.SET_ALLOCATION_SECOND_STATUS,
    clientId,
    allocationSecondStatus,
  });
};

const removeClient = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.CLIENT_REMOVE_REQUEST });
  return ClientService.remove(id)
    .then((client: any): any => {
      dispatch({ type: types.CLIENT_REMOVE_SUCCESS, client, id });
      dispatch(getClientsList());
      return Promise.resolve();
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.CLIENT_REMOVE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.CLIENT_REMOVE_FAILURE, error });
      }
      return Promise.reject();
    });
};

const checkToRemoveClient = (id: number): any => (dispatch: any): any => {
  dispatch({ type: types.CLIENT_CHECK_TO_REMOVE_REQUEST });
  return ClientService.checkToRemoveClient(id)
    .then((hasValueResponse: any): any => {
      dispatch({ type: types.CLIENT_CHECK_TO_REMOVE_SUCCESS });
      return Promise.resolve(hasValueResponse);
    })
    .catch((error: any): any => {
      dispatch({ type: types.CLIENT_CHECK_TO_REMOVE_FAILURE });
      return Promise.reject(error);
    });
};

const getAssets = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>
): any => (dispatch: any): any => {
  dispatch({ type: types.ASSETS_REQUEST });
  return AssetsService.assets(
    clientId,
    year,
    month,
    enabledRows,
    disabledColumns
  )
    .then((asset: AssetsType): any => {
      dispatch({ type: types.ASSETS_SUCCESS, assets: asset });
      return Promise.resolve(asset);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.ASSETS_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.ASSETS_FAILURE, error });
      }

      return Promise.reject(error);
    });
};

const charting = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.CHARTING_REQUEST });
  return ChartingService.charting(data)
    .then((chartingData: ChartingType): any => {
      dispatch({ type: types.CHARTING_SUCCESS, chartingData });
      return Promise.resolve(chartingData);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.CHARTING_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.CHARTING_FAILURE, error });
      }

      return Promise.reject(error);
    });
};

const monitor = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>,
  benchmarkIds: Array<number>
): any => (dispatch: any): Promise<any> => {
  dispatch({ type: types.MONITOR_REQUEST });
  return MonitorService.monitor(
    clientId,
    year,
    month,
    enabledRows,
    disabledColumns,
    benchmarkIds
  )
    .then((monitorData: MonitorType): any => {
      dispatch({ type: types.MONITOR_SUCCESS, monitorData });
      return Promise.resolve(monitorData);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.MONITOR_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.MONITOR_FAILURE, error });
      }

      return Promise.reject(error);
    });
};

const getMonitorRow = (
  year: number,
  month: number,
  benchmarkId: number,
  monthCount: number
): any => (dispatch: any): any => {
  dispatch({ type: types.MONITOR_BENCHMARK_REQUEST });
  return MonitorService.getMonitorRow(year, month, benchmarkId, monthCount)
    .then((monitorRow: ChartingRowType): any => {
      dispatch({ type: types.MONITOR_BENCHMARK_SUCCESS, monitorRow });
      return Promise.resolve(monitorRow);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.MONITOR_BENCHMARK_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.MONITOR_BENCHMARK_FAILURE, error });
      }

      return Promise.reject(error);
    });
};

const getChartingRow = (data: any): any => (dispatch: any): any => {
  dispatch({ type: types.CHARTING_BENCHMARK_REQUEST });
  return ChartingService.getChartingRow(data)
    .then((chartingRow: ChartingRowType): any => {
      dispatch({ type: types.CHARTING_BENCHMARK_SUCCESS, chartingRow });
      return Promise.resolve(chartingRow);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.CHARTING_BENCHMARK_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.CHARTING_BENCHMARK_FAILURE, error });
      }

      return Promise.reject(error);
    });
};

const getPerformanceData = (
  clientId: number,
  sharp: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>
): any => (dispatch: any): any => {
  dispatch({ type: types.PERFORMANCE_REQUEST });
  return PerformanceService.performance(
    clientId,
    sharp,
    year,
    month,
    enabledRows,
    disabledColumns
  )
    .then((performanceData: PerformanceType): any => {
      dispatch({ type: types.PERFORMANCE_SUCCESS, performanceData });
      return Promise.resolve(performanceData);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.PERFORMANCE_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.PERFORMANCE_FAILURE, error });
      }

      return Promise.reject(error);
    });
};

const getRegccy = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>
): any => (dispatch: any): any => {
  dispatch({ type: types.REGCCY_REQUEST });
  return RegccyService.regccy(
    clientId,
    year,
    month,
    enabledRows,
    disabledColumns
  )
    .then((regccys: RegccyType): any => {
      dispatch({ type: types.REGCCY_SUCCESS, regccys });
      return Promise.resolve(regccys);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.REGCCY_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.REGCCY_FAILURE, error });
      }

      return Promise.reject(error);
    });
};

const getHistory = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>,
  historyType: string
): any => (dispatch: any): any => {
  dispatch({ type: types.HISTORY_REQUEST });
  return HistoryService.getHistory(
    clientId,
    year,
    month,
    enabledRows,
    disabledColumns,
    historyType
  )
    .then((history: HistoryType): any => {
      dispatch({ type: types.HISTORY_SUCCESS, history });
      return Promise.resolve(history);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.HISTORY_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.HISTORY_FAILURE, error });
      }

      return Promise.reject(error);
    });
};

const getSimulation = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>
): any => (dispatch: any): any => {
  dispatch({ type: types.GET_SIMULATION_REQUEST });
  return SimulationService.getSimulation(
    clientId,
    year,
    month,
    enabledRows,
    disabledColumns
  )
    .then((simulation: SimulationType): any => {
      dispatch({ type: types.GET_SIMULATION_SUCCESS, simulation });
      return Promise.resolve(simulation);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.GET_SIMULATION_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.GET_SIMULATION_FAILURE, error });
      }

      return Promise.reject(error);
    });
};

const getAllocation = (
  clientId: number,
  year: number,
  month: number,
  enabledRows: Array<number>,
  disabledColumns: Array<number>
): any => (dispatch: any): any => {
  dispatch({ type: types.GET_ALLOCATION_REQUEST });
  return AllocationService.getAllocation(
    clientId,
    year,
    month,
    enabledRows,
    disabledColumns
  )
    .then((allocation: AssetAllocationType): any => {
      dispatch({ type: types.GET_ALLOCATION_SUCCESS, allocation });
      return Promise.resolve(allocation);
    })
    .catch((error: any): any => {
      if (typeof error.then === 'function') {
        error.then((e: any): void =>
          dispatch({ type: types.GET_ALLOCATION_FAILURE, error: e })
        );
      } else {
        dispatch({ type: types.GET_ALLOCATION_FAILURE, error });
      }

      return Promise.reject(error);
    });
};

const setSelectionChanged = (): any => (dispatch: any): any => {
  dispatch({ type: types.SET_SELECTION_CHANGED });
};

const ClientActions = {
  getClientsList,
  getClientItem,
  createClient,
  updateClient,
  removeClient,
  selectClient,
  setDate,
  getAssets,
  charting,
  monitor,
  getPerformanceData,
  getRegccy,
  setSelectedColumns,
  setSelectedRows,
  setSelectedHistory,
  setDateToClient,
  setNewClient,
  setActiveHistory,
  setExpandedRows,
  setAssetsChart,
  setRegccyChart,
  setChartingStartYear,
  setChartingStartMonth,
  setChartingEndYear,
  setChartingEndMonth,
  setChartingRiskFree,
  setChartingShowingRows,
  setMonitorShowingRows,
  setPerformSharp,
  setMonitorChart,
  setSelectedTab,
  setAllocationFirstStatus,
  setAllocationSecondStatus,
  getHistory,
  getMonitorRow,
  getChartingRow,
  getSimulation,
  getAllocation,
  setRowsNumber,
  checkToRemoveClient,
  setSelectedSelection,
  setSelectionChanged,
  duplicateClient,
};

export default ClientActions;
