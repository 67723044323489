export const CLIENTS_LIST_REQUEST = 'CLIENTS_LIST_REQUEST';
export const CLIENTS_LIST_SUCCESS = 'CLIENTS_LIST_SUCCESS';
export const CLIENTS_LIST_FAILURE = 'CLIENTS_LIST_FAILURE';
export const CLIENT_ITEM_REQUEST = 'CLIENT_ITEM_REQUEST';
export const CLIENT_ITEM_SUCCESS = 'CLIENT_ITEM_SUCCESS';
export const CLIENT_ITEM_FAILURE = 'CLIENT_ITEM_FAILURE';
export const CLIENT_CREATE_REQUEST = 'CLIENT_CREATE_REQUEST';
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS';
export const CLIENT_CREATE_FAILURE = 'CLIENT_CREATE_FAILURE';
export const CLIENT_UPDATE_REQUEST = 'CLIENT_UPDATE_REQUEST';
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS';
export const CLIENT_UPDATE_FAILURE = 'CLIENT_UPDATE_FAILURE';
export const CLIENT_REMOVE_REQUEST = 'CLIENT_REMOVE_REQUEST';
export const CLIENT_REMOVE_SUCCESS = 'CLIENT_REMOVE_SUCCESS';
export const CLIENT_REMOVE_FAILURE = 'CLIENT_REMOVE_FAILURE';
export const CLIENT_DUPLICATE_REQUEST = 'CLIENT_DUPLICATE_REQUEST';
export const CLIENT_DUPLICATE_SUCCESS = 'CLIENT_DUPLICATE_SUCCESS';
export const CLIENT_DUPLICATE_FAILURE = 'CLIENT_DUPLICATE_FAILURE';
export const CLIENT_CHECK_TO_REMOVE_REQUEST = 'CLIENT_CHECK_TO_REMOVE_REQUEST';
export const CLIENT_CHECK_TO_REMOVE_SUCCESS = 'CLIENT_CHECK_TO_REMOVE_SUCCESS';
export const CLIENT_CHECK_TO_REMOVE_FAILURE = 'CLIENT_CHECK_TO_REMOVE_FAILURE';
export const SELECT_CLIENT = 'SELECT_CLIENT';
export const SET_DATE = 'SET_DATE';
export const ASSETS_REQUEST = 'ASSETS_REQUEST';
export const ASSETS_SUCCESS = 'ASSETS_SUCCESS';
export const ASSETS_FAILURE = 'ASSETS_FAILURE';
export const MONITOR_REQUEST = 'MONITOR_REQUEST';
export const MONITOR_SUCCESS = 'MONITOR_SUCCESS';
export const MONITOR_FAILURE = 'MONITOR_FAILURE';
export const CHARTING_REQUEST = 'CHARTING_REQUEST';
export const CHARTING_SUCCESS = 'CHARTING_SUCCESS';
export const CHARTING_FAILURE = 'CHARTING_FAILURE';
export const PERFORMANCE_REQUEST = 'PERFORMANCE_REQUEST';
export const PERFORMANCE_SUCCESS = 'PERFORMANCE_SUCCESS';
export const PERFORMANCE_FAILURE = 'PERFORMANCE_FAILURE';
export const REGCCY_REQUEST = 'REGCCY_REQUEST';
export const REGCCY_SUCCESS = 'REGCCY_SUCCESS';
export const REGCCY_FAILURE = 'REGCCY_FAILURE';
export const ASSET_TABLE_REQUEST = 'ASSET_TABLE_REQUEST';
export const ASSET_TABLE_SUCCESS = 'ASSET_TABLE_SUCCESS';
export const ASSET_TABLE_FAILURE = 'ASSET_TABLE_FAILURE';
export const HISTORY_REQUEST = 'HISTORY_REQUEST';
export const HISTORY_SUCCESS = 'HISTORY_SUCCESS';
export const HISTORY_FAILURE = 'HISTORY_FAILURE';
export const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS';
export const SET_ROWS_NUMBER = 'SET_ROWS_NUMBER';
export const SET_SELECTED_COLUMNS = 'SET_SELECTED_COLUMNS';
export const SET_SELECTED_SELECTION = 'SET_SELECTED_SELECTION';
export const SET_SELECTED_HISTORY = 'SET_SELECTED_HISTORY';
export const SET_DATE_TO_CLIENT = 'SET_DATE_TO_CLIENT';
export const SET_NEW_CLIENT = 'SET_NEW_CLIENT';
export const SET_ACTIVE_HISTORY = 'SET_ACTIVE_HISTORY';
export const SET_EXPANDED_ROWS = 'SET_EXPANDED_ROWS';
export const SET_ASSETS_CHART = 'SET_ASSETS_CHART';
export const SET_REGCCY_CHART = 'SET_REGCCY_CHART';
export const SET_PERFORM_SHARPE = 'SET_PERFORM_SHARPE';
export const SET_CHARTING_RSIK_FREE = 'SET_CHARTING_RISK_FREE';
export const SET_CHARTING_START_YEAR = 'SET_CHARTING_START_YEAR';
export const SET_CHARTING_START_MONTH = 'SET_CHARTING_START_MONTH';
export const SET_CHARTING_END_YEAR = 'SET_CHARTING_END_YEAR';
export const SET_CHARTING_END_MONTH = 'SET_CHARTING_END_MONTH';
export const SET_CHARTING_SHOWING_ROWS = 'SET_CHARTING_SHOWING_ROWS';
export const SET_MONITOR_SHOWING_ROWS = 'SET_MONITOR_SHOWING_ROWS';
export const SET_MONITOR_CHART = 'SET_MONITOR_CHART';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_ALLOCATION_FIRST_STATUS = 'SET_ALLOCATION_FIRST_STATUS';
export const SET_ALLOCATION_SECOND_STATUS = 'SET_ALLOCATION_SECOND_STATUS';
export const MONITOR_BENCHMARK_REQUEST = 'MONITOR_BENCHMARK_REQUEST';
export const MONITOR_BENCHMARK_SUCCESS = 'MONITOR_BENCHMARK_SUCCESS';
export const MONITOR_BENCHMARK_FAILURE = 'MONITOR_BENCHMARK_FAILURE';
export const CHARTING_BENCHMARK_REQUEST = 'CHARTING_BENCHMARK_REQUEST';
export const CHARTING_BENCHMARK_SUCCESS = 'CHARTING_BENCHMARK_SUCCESS';
export const CHARTING_BENCHMARK_FAILURE = 'CHARTING_BENCHMARK_FAILURE';
export const GET_SIMULATION_REQUEST = 'GET_SIMULATION_REQUEST';
export const GET_SIMULATION_SUCCESS = 'GET_SIMULATION_SUCCESS';
export const GET_SIMULATION_FAILURE = 'GET_SIMULATION_FAILURE';
export const GET_ALLOCATION_REQUEST = 'GET_ALLOCATION_REQUEST';
export const GET_ALLOCATION_SUCCESS = 'GET_ALLOCATION_SUCCESS';
export const GET_ALLOCATION_FAILURE = 'GET_ALLOCATION_FAILURE';
export const SET_SELECTION_CHANGED = 'SET_SELECTION_CHANGED';
