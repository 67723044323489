export const ASSET_MANAGERS_LIST_REQUEST = 'ASSET_MANAGERS_LIST_REQUEST';
export const ASSET_MANAGERS_LIST_SUCCESS = 'ASSET_MANAGERS_LIST_SUCCESS';
export const ASSET_MANAGERS_LIST_FAILURE = 'ASSET_MANAGERS_LIST_FAILURE';
export const ASSET_MANAGER_ITEM_REQUEST = 'ASSET_MANAGER_ITEM_REQUEST';
export const ASSET_MANAGER_ITEM_SUCCESS = 'ASSET_MANAGER_ITEM_SUCCESS';
export const ASSET_MANAGER_ITEM_FAILURE = 'ASSET_MANAGER_ITEM_FAILURE';
export const ASSET_MANAGER_CREATE_REQUEST = 'ASSET_MANAGER_CREATE_REQUEST';
export const ASSET_MANAGER_CREATE_SUCCESS = 'ASSET_MANAGER_CREATE_SUCCESS';
export const ASSET_MANAGER_CREATE_FAILURE = 'ASSET_MANAGER_CREATE_FAILURE';
export const ASSET_MANAGER_UPDATE_REQUEST = 'ASSET_MANAGER_UPDATE_REQUEST';
export const ASSET_MANAGER_UPDATE_SUCCESS = 'ASSET_MANAGER_UPDATE_SUCCESS';
export const ASSET_MANAGER_UPDATE_FAILURE = 'ASSET_MANAGER_UPDATE_FAILURE';
export const ASSET_MANAGER_REMOVE_REQUEST = 'ASSET_MANAGER_REMOVE_REQUEST';
export const ASSET_MANAGER_REMOVE_SUCCESS = 'ASSET_MANAGER_REMOVE_SUCCESS';
export const ASSET_MANAGER_REMOVE_FAILURE = 'ASSET_MANAGER_REMOVE_FAILURE';
